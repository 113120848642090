import React, { ChangeEvent, useEffect, ReactElement, useState } from "react";

import { isEmpty } from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Grid, SelectChangeEvent, TableBody, Toolbar } from "@mui/material";

import {
  TableFooter,
  TableHeader,
  DynamicFilter,
  TableRowEmpty,
  ClearAll,
  ConfirmationDialog,
} from "core";

import {
  useFetch,
  useQuery,
  FetchMethodEnum,
  useProfileGetter,
} from "shared/hooks";

import { generateUrl } from "shared/utils";
import { EMPTY_STRING } from "shared/constants";
import {
  DELETE_MAPPING,
  DELETE_MAPPING_CONTENT,
  DELETE_MAPPING_TITLE,
  GET_MAPPING_OVERVIEW,
} from "access-manager/constants";
import {
  MappingTableRow,
  MappingsExtensionSearch,
} from "access-manager/components";
import { useStateMachine } from "little-state-machine";
import { setIsOpenDeleteMappingDialog } from "store/actions";
import { Divider } from "shared/styles";
import { MappingOverviewResponse, MappingRow, Mapping } from "./types";

import {
  defaultRowsPerPageOptions,
  sortOptions,
  dynamicFilterOptions,
} from "./config";
import { classes, Table } from "./styles";

const { GET, DELETE } = FetchMethodEnum;

export const MappingOverviewPage = (): ReactElement => {
  const {
    push,
    location: { search },
  } = useHistory();
  const [selectedMapping, setSelectedMapping] = useState<Mapping>({
    applicationId: EMPTY_STRING,
    userGroupId: null,
    debtorGroupId: null,
  });
  const { url: base } = useRouteMatch();
  const query = useQuery();
  const queryObject = Object.fromEntries(query);

  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsOpenDeleteMappingDialog,
  });

  const { isOpenDeleteMappingDialog } = appState;

  const {
    page: qPage,
    pageSize: qPageSize,
    sortByColumn,
    isascending,
    filterExtensions,
    ...filters
  } = queryObject;

  const apiUrl = generateUrl(GET_MAPPING_OVERVIEW);
  const { data, updateParams, executeFetch } =
    useFetch<MappingOverviewResponse>(apiUrl, {
      method: GET,
      initialParams: { ...queryObject },
    });

  const { get: getData, post: postData } = data ?? {};

  const { items = [], page = 1, pageSize = 10, totalItems = 0 } = getData ?? {};

  const isDisabledClearAllButton = filterExtensions == null && isEmpty(filters);
  useProfileGetter(0);

  // #region Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    const queryPageSize = query.get("pageSize");
    const queryParams = {
      ...queryObject,
      page: newPage + 1,
      pageSize: queryPageSize,
    }; // * newPage +1 reason is TablePagination page is zeroBased
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      pageSize: event.target.value,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Pagination

  const handleClearAll = () => {
    const queryParams = {
      page: 1,
      pageSize: 10,
      sortByColumn: queryObject.sortByColumn,
      isascending: queryObject.isascending,
    };
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl);
  };

  const handleDelete = (
    applicationId: string,
    userGroupId: number,
    debtorGroupId: number
  ) => {
    setSelectedMapping({ applicationId, userGroupId, debtorGroupId });
  };

  const handleDeleteMapping = () => {
    const { applicationId, userGroupId, debtorGroupId } = selectedMapping;
    const apiDeleteMappingUrl = generateUrl(DELETE_MAPPING, {
      applicationId,
      userGroupId,
      debtorGroupId,
    });
    executeFetch(apiDeleteMappingUrl, {
      method: DELETE,
      skip: false,
    });
  };

  // #region Filters
  const handleFilterExtensionsSearch = (extensionId: string) => {
    const currentExtensions = filterExtensions
      ? filterExtensions.split("_")
      : [];
    const nextExtensions = currentExtensions.includes(extensionId)
      ? currentExtensions.filter((s) => s !== extensionId)
      : currentExtensions.concat(extensionId);
    const queryParams = {
      ...queryObject,
      page: 1,
      filterExtensions: isEmpty(nextExtensions)
        ? undefined
        : nextExtensions.join("_"),
    };

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl);
  };

  const handleChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as NullableString | undefined;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      page: 1,
      sortByColumn: targetVal ?? EMPTY_STRING,
      isascending: isAsc,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      page: 1,
      [key]: dynFilters[key],
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Filters

  // #region Effects
  useEffect(() => {
    // ! it is not possible to use existing queryObject due to refrence change on every render.
    const newQuery = new URLSearchParams(search);
    const newQueryObj = Object.fromEntries(newQuery);
    updateParams(newQueryObj);
  }, [search, updateParams, isOpenDeleteMappingDialog]);
  // #endregion Effects

  useEffect(() => {
    if (postData) {
      actions.setIsOpenDeleteMappingDialog(false);
    }
  }, [actions, postData]);

  return (
    <>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <MappingsExtensionSearch
                  onExtensionClick={handleFilterExtensionsSearch}
                  selected={filterExtensions}
                />
              </Grid>
              <Grid item>
                <Grid container alignContent="flex-start" alignItems="center">
                  <Grid item>
                    <DynamicFilter
                      updateUrl={handleDynamicFilter}
                      queryFilters={filters}
                      filterOptions={dynamicFilterOptions}
                      limit={2}
                    />
                  </Grid>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Grid item>
                    <ClearAll
                      onClear={handleClearAll}
                      disabled={isDisabledClearAllButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs={12}>
        <Table size="small" className={classes.table}>
          <TableHeader
            sortOptions={sortOptions}
            sortValue={queryObject.sortByColumn}
            onSortChange={handleChangeSort}
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <TableBody>
            {isEmpty(items) ? (
              <TableRowEmpty />
            ) : (
              items.map((mappingRow: MappingRow) => (
                <MappingTableRow
                  key={`${mappingRow.applicationId}_${mappingRow.userGroupId}_${mappingRow.debtorGroupId}`}
                  {...mappingRow}
                  onDelete={handleDelete}
                />
              ))
            )}
          </TableBody>
          <TableFooter
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </Grid>
      {isOpenDeleteMappingDialog && (
        <ConfirmationDialog
          open={isOpenDeleteMappingDialog}
          title={DELETE_MAPPING_TITLE}
          message={DELETE_MAPPING_CONTENT}
          isDisableAction={Boolean(false)}
          onConfirm={handleDeleteMapping}
          onCancel={() => actions.setIsOpenDeleteMappingDialog(false)}
        />
      )}
    </>
  );
};

export default MappingOverviewPage;
