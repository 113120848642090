import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import {
  setIsOpenAddSelectedUsersDialog,
  setIsOpenRemoveSelectedUsersDialog,
} from "store/actions";

import { classes, Grid } from "./styles";

export const ManageUserGroupsActions = (): ReactElement => {
  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsOpenAddSelectedUsersDialog,
    setIsOpenRemoveSelectedUsersDialog,
  });

  const { isSelectedAMUsers } = appState;

  const handleOpenAddSelectedUsersDialog = () => {
    actions.setIsOpenAddSelectedUsersDialog(true);
  };

  const handleOpenRemoveSelectedDialog = () => {
    actions.setIsOpenRemoveSelectedUsersDialog(true);
  };

  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item>
        <Button
          id="button-add"
          color="primary"
          variant="contained"
          className={classes.addButton}
          classes={{ disabled: classes.disabledButton }}
          disabled={!isSelectedAMUsers}
          onClick={handleOpenAddSelectedUsersDialog}
        >
          Add Selected
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="button-remove"
          color="primary"
          variant="contained"
          className={classes.removeButton}
          classes={{ disabled: classes.disabledButton }}
          disabled={!isSelectedAMUsers}
          onClick={handleOpenRemoveSelectedDialog}
        >
          Remove Selected
        </Button>
      </Grid>
    </Grid>
  );
};

export default ManageUserGroupsActions;
