import { useEffect, useState, useCallback } from "react";

import { useFetch, FetchMethodEnum } from "./useFetch";

export interface DocumentInfo {
  agencyId: number;
  documentId: string;
}

export const useFileDownloader = (): {
  downloadFile: (
    endpoint: string,
    body: DocumentInfo | null,
    fileName: string
  ) => Promise<void>;
} => {
  const [name, setName] = useState<NullableString>(null);

  const { data, executeFetch, resetPostData } = useFetch<undefined, Blob>(
    null,
    {
      skip: true,
    }
  );

  const { post: postData } = data ?? {};

  const clearState = useCallback(() => {
    setName(null);
    resetPostData();
  }, [resetPostData]);

  const downloadFile = async (
    endpoint: string,
    body: DocumentInfo | null,
    fileName: string
  ) => {
    setName(fileName);
    await executeFetch(endpoint, {
      method: FetchMethodEnum.POST,
      body,
      skip: false,
    });
  };

  useEffect(() => {
    if (postData && name) {
      let blob;
      if (postData instanceof Blob) {
        blob = postData;
      } else {
        const json = JSON.stringify(postData);
        blob = new Blob([json], { type: "application/json" });
      }
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
      clearState();
    }
  }, [postData, clearState, name]);

  return { downloadFile };
};

export default useFileDownloader;
