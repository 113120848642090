import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import { Control, FieldErrors } from "react-hook-form";

import { TextField, NumericField, Select } from "core";

import { EMPTY_STRING } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { Current, IRevisionEntriesInput } from "../Hours/types";

export interface FormHoursEntryProps {
  control: Control;
  current: Current | null;
  errors?: FieldErrors;
  isEdit: boolean;
  revisionValues?: IRevisionEntriesInput;
  /**
   * Fill form option type
   */
  fillOption?: FillFormOptionType;
  /**
   * setValues
   */
  setValue?: any;
  /**
   * resetFillOption
   */
  resetFillOption?: (state: FillFormOptionType | undefined) => void;
  /*
   * onDialogSave
   */
  onDialogSave?: () => void;
}

export const FormHoursEntry = ({
  control,
  current,
  errors = {},
  isEdit,
  revisionValues,
  onDialogSave,
  resetFillOption,
  setValue,
  fillOption,
}: FormHoursEntryProps): ReactElement => {
  const { rowIndex, levelId, role, hourlyRate } = current ?? {};

  const {
    state: { cacheData },
  } = useStateMachine();

  const { enumLevel } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue(`entries.${rowIndex}.role`, "Role Test");
      setValue(`entries.${rowIndex}.hourlyRate`, 5);
      if (enumLevel[0]) {
        setValue(`entries.${rowIndex}.levelId`, enumLevel[0].key);
      }
    }
    return () => {
      if (fillOption === FillFormOptionType.fillAllAndSave) {
        if (onDialogSave) {
          onDialogSave();
        }
      }
      if (resetFillOption) {
        resetFillOption(undefined);
      }
    };
  }, [
    fillOption,
    setValue,
    rowIndex,
    enumLevel,
    resetFillOption,
    onDialogSave,
  ]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Select
          id="levelId"
          name={`entries.${rowIndex}.levelId`}
          label="Level"
          variant="outlined"
          fullWidth
          defaultValue={levelId ?? EMPTY_STRING}
          control={control}
          options={enumLevel}
          error={!!errors.levelId?.message}
          helperText={errors.levelId?.message}
          revisionValue={revisionValues?.LevelId}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name={`entries.${rowIndex}.role`}
          control={control}
          defaultValue={role ?? EMPTY_STRING}
          id="role"
          label="Role"
          error={!!errors.role?.message}
          helperText={errors.role?.message}
          revisionValue={revisionValues?.Role}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={4}>
        <NumericField
          id="hourlyRate"
          name={`entries.${rowIndex}.hourlyRate`}
          label="Hourly Rate"
          fullWidth
          defaultValue={hourlyRate ?? EMPTY_STRING}
          error={!!errors.hourlyRate?.message}
          helperText={errors.hourlyRate?.message}
          revisionValue={revisionValues?.HourlyRate}
          control={control}
          disabled={!isEdit}
        />
      </Grid>
    </Grid>
  );
};

export default FormHoursEntry;
