import React, { ReactElement } from "react";

import { styled } from "@mui/material";

import {
  Edit,
  MoreHoriz as MuiMoreHoriz,
  PermIdentity,
  DoneAll as DoneAllIcon,
  Warning as WarningIcon,
  PendingActions as PendingActionsIcon,
  DonutLarge as DonutLargeIcon,
  CancelPresentation as CancelPresentationIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  FileDownload as DownloadIcon,
  FileCopy,
} from "@mui/icons-material";

import { IconWrapper } from "core";

import { ProductStatusEnum } from "product/enums";
import { ProductStatusType } from "product/types";

import {
  azureRadiance,
  jordyBlue,
  supernova,
  white,
  darkDeYork,
} from "shared/assets/colors";

import {
  GroupM,
  Kinetic,
  EssenceMediacom,
  Mindshare,
  Wavemaker,
  Blossom,
  GreenhouseOne,
  GreenhouseTech,
  ChoreographCreate,
} from "shared/assets/images";

const PREFIX = "iconStyles";
export const classes = {
  circle: `${PREFIX}-circle`,
};

export const MoreHoriz = styled(MuiMoreHoriz)(() => ({
  [`&.${classes.circle}`]: {
    borderRadius: "50%",
  },
}));

interface IconType {
  color: string;
}

export interface AgencyProps {
  agency: number;
}

export const WorkInProgressIcon = (): ReactElement => {
  return (
    <IconWrapper
      width={20}
      height={20}
      color={white}
      icon={MoreHoriz}
      className={classes.circle}
      backgroundColor={jordyBlue}
    />
  );
};

export const ReadyForProcessingIcon = (): ReactElement => {
  return <IconWrapper icon={PendingActionsIcon} color={azureRadiance} />;
};

export const ProcessingIcon = (): ReactElement => {
  return <IconWrapper icon={DonutLargeIcon} color={azureRadiance} />;
};

export const FinalizedIcon = (): ReactElement => {
  return <IconWrapper icon={DoneAllIcon} color={darkDeYork} />;
};

export const CancelRectangularIcon = ({ color }: IconType): ReactElement => {
  return <IconWrapper icon={CancelPresentationIcon} color={color} />;
};

export const WarningSignIcon = ({ color }: IconType): ReactElement => {
  return <IconWrapper icon={WarningIcon} color={color} />;
};

export const CheckOutlineIcon = ({ color }: IconType): ReactElement => {
  return <IconWrapper icon={CheckCircleOutlineIcon} color={color} />;
};

export const ProfileIcon = (): ReactElement => {
  return <IconWrapper icon={PermIdentity} color={jordyBlue} />;
};

export const EditIcon = (): ReactElement => {
  return <IconWrapper icon={Edit} color={supernova} />;
};

export const DownloadStatusIcon = (): ReactElement => {
  return <IconWrapper icon={DownloadIcon} color={jordyBlue} />;
};

export const CopyIcon = (): ReactElement => {
  return <IconWrapper icon={FileCopy} color={jordyBlue} />;
};

export const productStatusIcons: {
  [key in ProductStatusType]: JSX.Element;
} = {
  [ProductStatusEnum["Work in Progress"]]: <WorkInProgressIcon />,
  [ProductStatusEnum["Ready for Processing"]]: <ReadyForProcessingIcon />,
  [ProductStatusEnum.Processing]: <ProcessingIcon />,
  [ProductStatusEnum.Finalized]: <FinalizedIcon />,
};

export const AgencyIcons = ({ agency }: AgencyProps) => {
  switch (agency) {
    case 1:
      return { image: <img src={GroupM} alt="groupMLogo" />, name: "GroupM" };
    case 2:
      return {
        image: <img src={Mindshare} alt="mindshareLogo" />,
        name: "Mindshare",
      };
    case 5:
      return {
        image: <img src={Kinetic} alt="kineticLogo" />,
        name: "Kinetic",
      };
    case 6:
      return {
        image: <img src={EssenceMediacom} alt="essenceMediacomLogo" />,
        name: "EssenceMediacom",
      };

    case 7:
      return {
        image: <img src={Wavemaker} alt="wavemakerLogo" />,
        name: "Wavemaker",
      };
    case 8:
      return {
        image: <img src={Blossom} alt="blossomLogo" />,
        name: "Blossom",
      };

    case 9:
      return {
        image: <img src={GreenhouseOne} alt="greenHouseOneLogo" />,
        name: "Greenhouse One",
      };
    case 10:
      return {
        image: <img src={GreenhouseTech} alt="greenHouseTechLogo" />,
        name: "Greenhouse Tech",
      };
    case 11:
      return {
        image: (
          <img
            src={ChoreographCreate}
            alt="choreographCreateLogo"
            height={20}
          />
        ),
        name: "Choreograph Create",
      };

    default:
      return {
        image: <img alt="noLogo" />,
        name: "-",
      };
  }
};
