import { string, object, number } from "yup";

import {
  MAX_NUMBER_FOR_INPUTS,
  MIN_NUMBER_FOR_INPUTS,
  MAX_NUMBER_VALIDATION_MESSAGE,
  MIN_NUMBER_VALIDATION_MESSAGE,
  NUMBER_FIELD_VALIDATION_MESSAGE,
  MAX_LENGTH_50_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  creditInsurance: number()
    .transform(nanToNull)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .nullable(),
  alternativeCreditInsuranceId: number()
    .nullable()
    .transform(nanToNull)
    .when("creditInsurance", {
      is: 0,
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
    }),
  creditInsuranceNumber: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE),
  creditInsuranceAmount: number()
    .nullable()
    .transform(nanToNull)
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MIN_NUMBER_VALIDATION_MESSAGE),
  shareLimit: number().nullable().transform(nanToNull),
});
