import React from "react";
import { array, number, object, string } from "yup";
import { MenuItem, Select as MuiSelect } from "@mui/material";

import { InputCell, ColumnType, RenderCellProps, CellTypeEnum } from "core";

import {
  EMPTY_STRING,
  EMAIL_FORM_VALIDATION_MESSAGE,
  MAX_LENGTH_50_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const getAgencyTypeName = (
  agencyTypeId?: number
): string | undefined => {
  const agencyTypes = [
    { id: 1, name: "Creative" },
    { id: 2, name: "PR" },
    { id: 3, name: "Digital" },
    { id: 4, name: "Other" },
    { id: null, name: "N/A" },
  ];
  return agencyTypes.find((at) => at.id === agencyTypeId)?.name;
};

// #region columns definition
export const columns: ColumnType[] = [
  {
    field: "id",
    headerName: "Id",
    hide: true,
    cellType: CellTypeEnum.Input,
    renderCell: () => <InputCell />,
  },
  {
    field: "agencyTypeId",
    headerName: "Agency Type",
    cellType: CellTypeEnum.Select,
    renderCell: ({ field, options = [] }: RenderCellProps) => (
      <MuiSelect
        disabled
        {...field}
        IconComponent={() => <></>}
        SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
      >
        <MenuItem value={EMPTY_STRING}>
          <em>None</em>
        </MenuItem>
        {options.map(({ key, value }: OptionType) => {
          return (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          );
        })}
      </MuiSelect>
    ),
  },
  {
    field: "contactPerson",
    headerName: "Contact Person",
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "companyName",
    headerName: "Company Name",
    cellType: CellTypeEnum.Input,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "jobTitle",
    headerName: "Job Title",
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "email",
    headerName: "Email",
    cellType: CellTypeEnum.Input,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "phone",
    headerName: "Phone",
    cellType: CellTypeEnum.Input,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
];
// #endregion columns definition

const formSchema = {
  id: number().transform(nanToNull).nullable(),
  agencyTypeId: number()
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .transform(nanToNull),
  contactPerson: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  companyName: string().trim().max(50, MAX_LENGTH_50_VALIDATION_MESSAGE),
  jobTitle: string().trim().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  email: string()
    .trim()
    .email(EMAIL_FORM_VALIDATION_MESSAGE)
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  phone: string().trim().max(50, MAX_LENGTH_50_VALIDATION_MESSAGE),
};

export const validationSchema = object().shape({
  entries: array().of(object().shape(formSchema)),
});
