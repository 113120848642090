import React, { FC, useEffect, ReactElement } from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from "@hookform/devtools";
import { Prompt } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import {
  KEY_ENTER,
  TAG_TEXTAREA,
  UNSAVED_DATA_MESSAGE,
} from "shared/constants";
import { StandardFormType } from "../../types";

import {
  FormsProps,
  IFormsInput,
  IFormsResponseGet,
  IFormsResponsePost,
} from "./types";

import { FormUserGroup } from "./UserGroup";
import { FormDebtorGroup } from "./DebtorGroup";

import { defaults } from "./constants";
import { schemas } from "./config";

import { FormStandardFieldProps } from "./props";

import { classes, Root } from "./styles";

const standard: {
  [key in StandardFormType]: FC<FormsProps[key]>;
} = {
  userGroup: FormUserGroup,
  debtorGroup: FormDebtorGroup,
};

const handler = {
  get(target: any, name: string) {
    return Object.prototype.hasOwnProperty.call(target, name)
      ? target[name]
      : () => <></>;
  },
};

const standardForms = new Proxy<{
  [key in StandardFormType]: FC<FormsProps[key]>;
}>(standard, handler);

export interface FormStandardProps {
  /**
   * Accordion type of Standard Form
   * @type StandardFormType
   */
  type: StandardFormType;
  /**
   * Flag indicating if form is in edit mode
   */
  isEdit: boolean;
  /**
   * Callback fired when form is submitted
   */
  onSave: (id: number) => void;
  /**
   * Callback fired when delete group button needs to be disabled
   */
  disableDeleteGroupButton?: () => void;
  formParams: any;
}

export const FormStandard = ({
  type,
  isEdit,
  onSave,
  disableDeleteGroupButton,
  formParams,
}: FormStandardProps): ReactElement => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isDirty },
  } = useForm<IFormsInput[typeof type]>({
    defaultValues: defaults[type],
    resolver: yupResolver(schemas[type]),
  });

  const { GET, POST, PUT } = FetchMethodEnum;

  const { errorMessage, data, executeFetch, resetPostData } = useFetch<
    IFormsResponseGet[typeof type],
    IFormsResponsePost[typeof type]
  >(formParams.getUrl, {
    skip: formParams.isNew,
    method: GET,
  });

  const { get: getData, post: postData } = data ?? {};

  // #region Methods
  const onSubmit = (submittedData: IFormsInput[typeof type]) => {
    const method = formParams.isNew ? POST : PUT;
    const submitUrl = formParams.isNew
      ? formParams.createUrl
      : formParams.editUrl;
    executeFetch(submitUrl, { method, body: submittedData });
  };

  const onKeyPressInField = (event: React.KeyboardEvent<HTMLElement>) => {
    const tag = event.target as HTMLElement;
    if (event.key === KEY_ENTER && tag.tagName !== TAG_TEXTAREA) {
      event.preventDefault();
    }
  };
  // #endregion Methods

  // #region Effects
  useEffect(() => {
    if (!isEdit) {
      reset();
    }
  }, [isEdit, reset]);

  useEffect(() => {
    const { ...form } = (getData ?? {}) as any;
    if (form) {
      reset(form);
    }
  }, [getData, reset]);

  useEffect(() => {
    const { isSuccessful, ...restData } = (postData ?? {}) as any;
    if (isSubmitSuccessful && isSuccessful) {
      reset(restData);
      resetPostData();

      onSave(restData.id);
    }
  }, [postData, isSubmitSuccessful, reset, onSave, resetPostData]);

  useEffect(() => {
    if (errorMessage.length !== 0 && disableDeleteGroupButton) {
      disableDeleteGroupButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);
  // #endregion Effects

  // #region props
  const defaultFormProps: FormStandardFieldProps = {
    errors,
    isEdit,
    control,
  };

  const userGroupProps: FormStandardFieldProps = { ...defaultFormProps };
  const debtorGroupProps: FormStandardFieldProps = { ...defaultFormProps };

  const allFormProps: {
    [key in StandardFormType]: any;
  } = {
    userGroup: userGroupProps,
    debtorGroup: debtorGroupProps,
  };
  // #endregion props

  const Form = standardForms[type];
  const formProps = allFormProps[type];

  return (
    <>
      {process.env.NODE_ENV !== "production" && (
        <DevTool control={control} placement="top-left" />
      )}
      <Root
        id={`${type}Form`}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={onKeyPressInField}
        role="presentation"
        className={classes.form}
      >
        <Form {...formProps} />
      </Root>
      <Prompt
        when={isDirty && !isSubmitSuccessful}
        message={UNSAVED_DATA_MESSAGE}
      />
    </>
  );
};

export default FormStandard;
