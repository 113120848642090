import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { FieldErrors, Control } from "react-hook-form";

import { TextField, NumericField } from "core";

import { EMPTY_STRING } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import {
  Current,
  IRevisionEntriesInput,
} from "../SoxCompliantClientApproval/types";

export interface FormSoxCompliantClientApprovalEntryProps {
  isEdit: boolean;
  control: Control;
  current: Current;
  errors?: FieldErrors;
  revisionValues?: IRevisionEntriesInput;
  /**
   * Fill form option type
   */
  fillOption?: FillFormOptionType;
  /**
   * setValues
   */
  setValue: any;
  /**
   * resetFillOption
   */
  resetFillOption: (state: FillFormOptionType | undefined) => void;
  /*
   * onDialogSave
   */
  onDialogSave: () => void;
}

export const FormSoxCompliantClientApprovalEntry = ({
  control,
  current,
  errors = {},
  isEdit,
  revisionValues,
  onDialogSave,
  resetFillOption,
  setValue,
  fillOption,
}: FormSoxCompliantClientApprovalEntryProps): ReactElement => {
  const { rowIndex, thresholdUpTo, nameFunctions } = current ?? {};

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue(`entries.${rowIndex}.thresholdUpTo`, 10);
      setValue(`entries.${rowIndex}.nameFunctions`, "Name Functions - Test");
    }
    return () => {
      if (fillOption === FillFormOptionType.fillAllAndSave) {
        onDialogSave();
      }
      resetFillOption(undefined);
    };
  }, [fillOption, setValue, rowIndex, resetFillOption, onDialogSave]);
  // #endregion Effects

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <NumericField
          id="thresholdUpTo"
          name={`entries.${rowIndex}.thresholdUpTo`}
          label="Authorized to Sign - Threshold up to"
          fullWidth
          allowNegative
          defaultValue={thresholdUpTo ?? EMPTY_STRING}
          error={!!errors.thresholdUpTo?.message}
          helperText={errors.thresholdUpTo?.message}
          revisionValue={revisionValues?.ThresholdUpTo}
          control={control}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item>
        <TextField
          name={`entries.${rowIndex}.nameFunctions`}
          control={control}
          defaultValue={nameFunctions ?? EMPTY_STRING}
          id="nameFunctions"
          label="Authorized to Sign - Name or Function"
          error={!!errors.nameFunctions?.message}
          helperText={errors.nameFunctions?.message}
          revisionValue={revisionValues?.NameFunctions}
          disabled={!isEdit}
        />
      </Grid>
    </Grid>
  );
};

export default FormSoxCompliantClientApprovalEntry;
