import React, { useState, ChangeEvent, ReactElement, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import {
  Checkbox,
  Chip,
  Grid,
  TableBody,
  TableCell,
  CircularProgress,
} from "@mui/material";
import { isEmpty, isNumber } from "lodash";
import { useFetch, useProfileGetter, useQuery } from "shared/hooks";
import { ConfirmationDialog, TableFooter, TableRowEmpty } from "core";
import { MissingDebtorsTableRow } from "access-manager/components";
import { EMPTY_STRING } from "shared/constants";
import { generateUrl } from "shared/utils";
import {
  GET_MISSING_DEBTORS,
  IMPORT_MISSING_DEBTORS_DIALOG_CONTENT,
  IMPORT_MISSING_DEBTORS_DIALOG_TITLE,
  IMPORT_MISSING_DEBTORS,
} from "access-manager/constants";
import { FetchMethodEnum } from "shared/enums";
import {
  setIsSelectedMissingDebtors,
  setOpenImportSelectedMissingDebtorsDialog,
} from "store/actions";
import { TableRow } from "shared/styles";
import { defaultRowsPerPageOptions } from "./config";
import { classes, Table } from "./styles";
import {
  StateType,
  IPostResponse,
  MissingDebtorsResponse,
  MissingDebtor,
} from "./types";

const { GET, POST } = FetchMethodEnum;

export const MissingDebtorsPage = (): ReactElement => {
  const {
    push,
    location: { search, state: urlState },
  } = useHistory<StateType>();

  const { actionSelectedItems } = urlState ?? {};

  const { url: base } = useRouteMatch();
  const query = useQuery();
  const queryObject = Object.fromEntries(query);
  const [isSelectAll, setSelectAll] = useState<boolean | undefined>(false);
  const [checkedMissingDebtors, setCheckedMissingDebtors] = useState<
    MissingDebtor[]
  >([]);
  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsSelectedMissingDebtors,
    setOpenImportSelectedMissingDebtorsDialog,
  });

  useProfileGetter(0);

  const { isOpenImportSelectedMissingDebtorsDialog } = appState;

  const apiUrl = generateUrl(GET_MISSING_DEBTORS);

  const { data, updateParams, executeFetch, refetch, isLoading } = useFetch<
    MissingDebtorsResponse,
    IPostResponse
  >(apiUrl, {
    method: GET,
    initialParams: { ...queryObject },
  });

  const { get: getData, post: postData } = data ?? {};

  const {
    missingDebtors = [],
    page = 1,
    pageSize = 10,
    totalItems = 0,
  } = getData ?? {};

  // #region Users Actions
  const handleImportSelectedMissingDebtors = () => {
    actions.setOpenImportSelectedMissingDebtorsDialog(false);

    let isImportMissingDebtorDataValid = true;

    const missingAgency = checkedMissingDebtors.map((debtor) => {
      const errorAgencyMessage =
        debtor.agencyId != null ? "" : "Agency is mandatory";
      return {
        ...debtor,
        errorAgency: errorAgencyMessage,
      };
    });

    const missingConcern = missingAgency.map((debtor) => {
      const errorConcernMessage =
        debtor.concernId != null || debtor.concernName != null
          ? ""
          : "Concern is mandatory";
      return {
        ...debtor,
        errorConcern: errorConcernMessage,
      };
    });

    setCheckedMissingDebtors(missingConcern);
    isImportMissingDebtorDataValid = !missingConcern.some(
      (x) => x.errorAgency.length > 0 || x.errorConcern.length > 0
    );

    if (isImportMissingDebtorDataValid) {
      const importMissingDebtorsUrl = generateUrl(IMPORT_MISSING_DEBTORS);
      executeFetch(importMissingDebtorsUrl, {
        method: POST,
        body: { missingDebtors: checkedMissingDebtors },
      });
    }
  };

  const handleCloseImportSelectedMissingDebtorsDialog = () => {
    actions.setOpenImportSelectedMissingDebtorsDialog(false);
  };
  // #endregion

  // #region Select checkboxes
  const handleCheckboxClick = (
    marathonId: string,
    debtorName: string,
    isActive: boolean
  ) => {
    const newMissingDebtor: MissingDebtor = {
      marathonId,
      debtorName,
      isActive,
      agencyId: null,
      concernId: null,
      concernName: null,
      errorAgency: "",
      errorConcern: "",
    };
    const items = checkedMissingDebtors?.some(
      (ci) => ci.marathonId === marathonId
    )
      ? checkedMissingDebtors.filter((ci) => ci.marathonId !== marathonId)
      : [...checkedMissingDebtors, newMissingDebtor];

    setCheckedMissingDebtors(items);
    if (isEmpty(items)) {
      setSelectAll(false);
    }
    if (!isEmpty(items) && missingDebtors.length !== items.length) {
      setSelectAll(undefined);
    }
    if (missingDebtors.length === items.length) {
      setSelectAll(true);
    }
    actions.setIsSelectedMissingDebtors(items.length !== 0);
  };

  const handleMainCheckboxClick = () => {
    if (!isSelectAll) {
      setSelectAll(true);
      setCheckedMissingDebtors([...missingDebtors]);
      actions.setIsSelectedMissingDebtors(true);
    }

    if (isSelectAll) {
      setSelectAll(false);
      setCheckedMissingDebtors([]);
      actions.setIsSelectedMissingDebtors(false);
    }
  };

  const handleInvert = () => {
    const marathonIds = missingDebtors.map((u) => u.marathonId);
    const invertArray = checkedMissingDebtors.filter(
      (x) => !marathonIds.includes(x.marathonId)
    );

    setCheckedMissingDebtors(invertArray);

    if (isEmpty(invertArray)) {
      setSelectAll(false);
    }

    if (missingDebtors.length === invertArray.length) {
      setSelectAll(true);
    }

    actions.setIsSelectedMissingDebtors(invertArray.length !== 0);
  };
  // #endregion

  // #region Agency selection
  const handleAgencySelectChange = (
    marathonId: string,
    selectedAgencyId: number
  ) => {
    const agencyUpdatedDebtors = checkedMissingDebtors.map((obj) =>
      obj.marathonId === marathonId
        ? { ...obj, agencyId: selectedAgencyId }
        : obj
    );
    setCheckedMissingDebtors(agencyUpdatedDebtors);
  };
  // #endregion

  // #region Concern selection
  const handleConcernSelectChange = (
    marathonId: string,
    selectedConcern: any
  ) => {
    const selection =
      selectedConcern != null && selectedConcern.key != null
        ? selectedConcern.key
        : (selectedConcern as unknown as string);
    const concernUpdatedDebtors = isNumber(selection)
      ? checkedMissingDebtors.map((obj) =>
          obj.marathonId === marathonId
            ? { ...obj, concernId: selection, concernName: null }
            : obj
        )
      : checkedMissingDebtors.map((obj) =>
          obj.marathonId === marathonId
            ? { ...obj, concernName: selection, concernId: null }
            : obj
        );
    setCheckedMissingDebtors(concernUpdatedDebtors);
  };
  // #endregion

  // #region Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    const queryPageSize = query.get("pageSize");
    const queryParams = {
      ...queryObject,
      page: newPage + 1,
      pageSize: queryPageSize,
    }; // * newPage +1 reason is TablePagination page is zeroBased
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl, {
      actionSelectedItems: false,
    });
    setCheckedMissingDebtors([]);
    setSelectAll(false);
    actions.setIsSelectedMissingDebtors(false);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      pageSize: event.target.value,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
    setCheckedMissingDebtors([]);
    setSelectAll(false);
    actions.setIsSelectedMissingDebtors(false);
  };
  // #endregion Pagination

  // #region UseEffect
  useEffect(() => {
    const newQuery = new URLSearchParams(search);
    const newQueryObj = Object.fromEntries(newQuery);
    updateParams(newQueryObj);
    if (actionSelectedItems || postData?.isSuccessful) {
      actions.setOpenImportSelectedMissingDebtorsDialog(false);
      actions.setIsSelectedMissingDebtors(false);
      setCheckedMissingDebtors([]);
      setSelectAll(false);
      refetch();
    }
  }, [search, updateParams, actions, actionSelectedItems, postData, refetch]);
  // #endregion UseEffect

  return (
    <>
      {isLoading && (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress size={80} />
        </Grid>
      )}
      {!isLoading && (
        <Grid item>
          <Table size="small" className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell>
                  {!isEmpty(missingDebtors) ? (
                    <Checkbox
                      id="check-all"
                      indeterminate={isSelectAll === undefined}
                      inputProps={{ "aria-label": "main checkbox" }}
                      onChange={() => handleMainCheckboxClick()}
                      checked={isSelectAll}
                    />
                  ) : (
                    EMPTY_STRING
                  )}
                </TableCell>
                <TableCell>
                  {!isEmpty(missingDebtors) ? (
                    <Chip
                      id="button-invert"
                      color="primary"
                      onClick={handleInvert}
                      label="Invert Selection"
                      clickable
                      variant="outlined"
                      style={{ alignSelf: "flex-end" }}
                    />
                  ) : (
                    EMPTY_STRING
                  )}
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              {isEmpty(missingDebtors) ? (
                <TableRowEmpty />
              ) : (
                missingDebtors.map((missingDebtor: MissingDebtor) => (
                  <MissingDebtorsTableRow
                    key={missingDebtor.marathonId}
                    {...missingDebtor}
                    onCheckboxClick={handleCheckboxClick}
                    onAgencySelectChange={handleAgencySelectChange}
                    onConcernSelectChange={handleConcernSelectChange}
                    isChecked={checkedMissingDebtors.some(
                      (x) => x.marathonId === missingDebtor.marathonId
                    )}
                    errorAgency={
                      checkedMissingDebtors
                        .filter(
                          (x) => x.marathonId === missingDebtor.marathonId
                        )
                        .map((y) => y.errorAgency)[0]
                    }
                    errorConcern={
                      checkedMissingDebtors
                        .filter(
                          (x) => x.marathonId === missingDebtor.marathonId
                        )
                        .map((y) => y.errorConcern)[0]
                    }
                  />
                ))
              )}
            </TableBody>
            <TableFooter
              rowsPerPageOptions={defaultRowsPerPageOptions}
              count={totalItems}
              rowsPerPage={pageSize}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Table>
          {isOpenImportSelectedMissingDebtorsDialog && (
            <ConfirmationDialog
              open={isOpenImportSelectedMissingDebtorsDialog}
              title={IMPORT_MISSING_DEBTORS_DIALOG_TITLE}
              message={IMPORT_MISSING_DEBTORS_DIALOG_CONTENT}
              isDisableAction={isLoading}
              onConfirm={handleImportSelectedMissingDebtors}
              onCancel={handleCloseImportSelectedMissingDebtorsDialog}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default MissingDebtorsPage;
