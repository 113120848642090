import { styled, Box as MUIBox } from "@mui/material";

const PREFIX = "KeywordSearchStyles";
export const classes = {
  gridPadding: `${PREFIX}-gridPadding`,
  formMinWidth: `${PREFIX}-formMinWidth`,
  width: `${PREFIX}-width`,
  padding: `${PREFIX}-padding`,
  boxBorder: `${PREFIX}-boxBorder`,
};

export const Box = styled(MUIBox)(() => ({
  [`& .${classes.gridPadding}`]: {
    paddingTop: "3vh",
  },
  [`& .${classes.formMinWidth}`]: {
    minWidth: "8vw",
  },
  [`& .${classes.width}`]: {
    width: "35vw",
  },
  [`& .${classes.padding}`]: {
    padding: "0.8rem",
  },
  [`& .${classes.boxBorder}`]: {
    border: "0.05rem solid",
    borderRadius: "0.3rem",
  },
}));
