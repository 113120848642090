import React, { ReactElement } from "react";
import {
  Grid,
  TableCell,
  Tooltip,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";

import { DEFAULT, EMPTY_STRING, PLACEHOLDER } from "shared/constants";
import {
  INIT_OVERVIEW_PAGE,
  INIT_QUERY_DEBTORS,
  URL_MANAGE_DEBTOR_GROUP,
  URL_MAPPING_OVERVIEW,
} from "access-manager/constants";
import LaunchIcon from "@mui/icons-material/Launch";
import clsx from "clsx";
import { AgencyNameEnum } from "shared/enums";
import { classes, TableRow } from "./styles";

export interface DebtorGroupTableRowProps {
  debtorGroupId: number;
  name: string;
  description: string;
  agencyName: keyof typeof AgencyNameEnum | string;
  numberOfDebtors: number;
  isDefault: boolean;
}

export const DebtorGroupTableRow = ({
  debtorGroupId,
  name,
  description,
  agencyName,
  numberOfDebtors,
  isDefault,
}: DebtorGroupTableRowProps): ReactElement => {
  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item xs={4}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Debtor Group Name
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
            >
              <Link
                to={`/${URL_MANAGE_DEBTOR_GROUP}/${debtorGroupId}${INIT_QUERY_DEBTORS}`}
                className={classes.typographyLink}
              >
                <Tooltip
                  title={name || EMPTY_STRING}
                  enterDelay={250}
                  placement="bottom-start"
                >
                  <Typography
                    id={`button-debtorGroup-${debtorGroupId}`}
                    className={clsx(classes.typographyValue, classes.hoverText)}
                    variant="subtitle1"
                    noWrap
                  >
                    {name}
                  </Typography>
                </Tooltip>
              </Link>
              <Link
                target="_blank"
                to={`/${URL_MAPPING_OVERVIEW}${INIT_OVERVIEW_PAGE}&debtorGroupName=${name}`}
              >
                <IconButton
                  name={`button-MappingPage-${debtorGroupId}`}
                  id={`button-MappingPage-${debtorGroupId}`}
                  size="small"
                  className={classes.hoverText}
                >
                  <LaunchIcon className={classes.icon} />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={
                AgencyNameEnum[
                  agencyName.toLowerCase() as keyof typeof AgencyNameEnum
                ] || EMPTY_STRING
              }
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {AgencyNameEnum[
                  agencyName.toLowerCase() as keyof typeof AgencyNameEnum
                ] ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Agency
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={description || EMPTY_STRING}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {description ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Description
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={numberOfDebtors || EMPTY_STRING}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {numberOfDebtors ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Number Of Debtors
            </Typography>
          </Grid>
          {isDefault && (
            <Grid item xs={1}>
              <Chip label={DEFAULT} />
            </Grid>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default DebtorGroupTableRow;
