import React, { ReactElement } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import {
  CANCEL_LABEL,
  CREATE_LABEL,
  CREATE_PRODUCT_DIALOG_WARNING,
  OK_LABEL,
  RETRY_LABEL,
} from "shared/constants";
import {
  CREATE_MARATHON_PRODUCT_DIALOG_DESCRIPTION,
  CREATE_MARATHON_PRODUCT_DIALOG_INFO,
  CREATE_MARATHON_PRODUCT_DIALOG_TITLE,
} from "product/constants/client";
import { LoadingButton } from "@mui/lab";
import { classes, Grid, Typography } from "./styles";

export interface CreateMarathonProductDialogProps extends DialogProps {
  /**
   * Text represents Marathon Product Id
   */
  productId: string | null;
  /*
   * Callback for confirming dialog
   */
  onConfirm?: () => void;
  /*
   * Callback for canceling dialog
   */
  onCancel?: () => void;
  /*
   * Callback for creating Marathon Client
   */
  onCreate?: () => void;
  /**
   * Flag inidiacting if it is a first attempt to create a client
   */
  isFirstTry?: boolean;
  disabled: boolean;
  loading: boolean;
}

export const CreateMarathonProductDialog = ({
  open,
  productId,
  onConfirm,
  onCancel,
  onCreate,
  isFirstTry,
  disabled,
  loading,
}: CreateMarathonProductDialogProps): ReactElement => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle id="dialog-title">
        {CREATE_MARATHON_PRODUCT_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent id="dialog-content" dividers>
        <Typography variant="body1">
          {CREATE_MARATHON_PRODUCT_DIALOG_INFO}
          {CREATE_MARATHON_PRODUCT_DIALOG_DESCRIPTION}
          {disabled && (
            <Typography className={classes.warning}>
              {CREATE_PRODUCT_DIALOG_WARNING}
            </Typography>
          )}
        </Typography>
        {!disabled && (
          <Grid container className={classes.button}>
            <LoadingButton
              id="button-create"
              onClick={onCreate}
              color="primary"
              variant="outlined"
              loading={loading}
            >
              {isFirstTry ? CREATE_LABEL : RETRY_LABEL}
            </LoadingButton>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {productId ? (
          <Button id="button-ok" onClick={onConfirm} color="primary">
            {OK_LABEL}
          </Button>
        ) : (
          <Button id="button-cancel" onClick={onCancel} color="secondary">
            {CANCEL_LABEL}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateMarathonProductDialog;
