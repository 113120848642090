import { IDocumentFormInput } from "master-data/types";
import { EMPTY_STRING } from "shared/constants";

export const defaultValues: IDocumentFormInput = {
  concern: null,
  documentTypeId: EMPTY_STRING,
  documentName: EMPTY_STRING,
  agencyId: 0,
  marathonClientId: EMPTY_STRING,
  isLatestVersion: true,
  version: 0,
  selectedReviewerId: null,
  businessUnitId: null,
  hasDefaultProduct: false,
  isDefaultProductCreationConfirmed: false,
  isActive: false,
};
