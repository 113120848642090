import React, { ReactElement } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { generateUrl } from "shared/utils";
import { CLEAR_CACHE } from "back-office/constants";
import { ParamTypes } from "back-office/pages/UserManagement/types";

export interface IClearCacheResponse {
  isSuccessful: boolean;
}

const { DELETE } = FetchMethodEnum;

export const ClearCacheDialog = (): ReactElement => {
  const history = useHistory();
  const { dbType } = useParams<ParamTypes>();

  const { executeFetch } = useFetch<undefined, IClearCacheResponse>(null, {
    skip: true,
  });

  const clearCache = () => {
    const url = generateUrl(CLEAR_CACHE, { dbType });
    executeFetch(url, { method: DELETE, skip: false });
    history.goBack();
  };

  return (
    <Dialog open maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle id="status-dialog-title">Clear cache</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to clear {dbType} database cache?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => history.goBack()} color="secondary">
          CANCEL
        </Button>
        <Button onClick={clearCache} color="primary">
          CLEAR CACHE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClearCacheDialog;
