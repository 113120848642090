import { AnyObjectSchema } from "yup";

import { FormEntriesAccordionType } from "master-data/types";

import { validationSchema as clientContactsSchema } from "./ClientContacts/config";
import { validationSchema as hoursSchema } from "./Hours/config";
import { validationSchema as hoursSchemaV5 } from "./HoursV5/config";
import { validationSchema as relatedContactsSchema } from "./RelatedContacts/config";
import { validationSchema as remunerationContractLineSchema } from "./RemunerationContractLine/config";
import { validationSchema as soxCompliantClientApprovalSchema } from "./SoxCompliantClientApproval/config";
import { validationSchema as financialClientIncentiveSchema } from "./FinancialClientIncentive/config";

export const schemas: { [key in FormEntriesAccordionType]: AnyObjectSchema } = {
  clientContacts: clientContactsSchema,
  relatedContacts: relatedContactsSchema,
  hours: hoursSchema,
  hoursV5: hoursSchemaV5,
  remunerationContractLine: remunerationContractLineSchema,
  soxCompliantClientApproval: soxCompliantClientApprovalSchema,
  financialClientIncentive: financialClientIncentiveSchema,
};
