export const getCacheDb = (description: string): string => {
  const databaseType: {
    [key: string]: string;
  } = {
    "Clear Client Document Cache": "core",
    "Clear Security Cache": "security",
  };

  return databaseType[description];
};
