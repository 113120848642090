const lilac = "#c590c0";
const deYork = "#87cb9c";
const darkDeYork = "#009A00";
const jordyBlue = "#77b0dd";
const lochmara = "#3376aa";
const crusta = "#f47f64";
const supernova = "#ffb32c";
const darkSupernova = "#ff962e";
const dodgerBlue = "#0080ff";
const dodgerBlue20 = "rgba(0, 128, 255, 0.2)";
const sapphire = "#0a2756";
const matisse = "#3b5277";
const baliHai = "#8997ae";
const greyChateau = "#9DA2AB";
const linkWater = "#c4cbd6";
const solitude = "#ebeef2";
const azureRadiance = "#0080FF";
const softRed = "#FC5959";
const bittersweet = "#FF6161";
const white = "#ffffff";
const black = "#000000";
const lavender = "#DDEBF7";
const shipCove = "#7B83A3";
const darkIndigo = "#1a237e";
const freeSpeachBlue = "#3f51b5";
const gainsboro = "#e0e0e0";
const cancelRed = "#f50057";
const tableHeaderGray = "#fafafa";
const darkRed = "#FF0000";
const darkGreen = "#006600";

const colorsEnum = {
  lilac,
  deYork,
  jordyBlue,
  lochmara,
  crusta,
  supernova,
  darkSupernova,
  dodgerBlue,
  dodgerBlue20,
  sapphire,
  matisse,
  baliHai,
  greyChateau,
  linkWater,
  solitude,
  azureRadiance,
  softRed,
  bittersweet,
  white,
  black,
  lavender,
  shipCove,
  freeSpeachBlue,
  gainsboro,
  darkDeYork,
  cancelRed,
  tableHeaderGray,
  darkRed,
  darkGreen,
};

Object.freeze(colorsEnum);

export {
  lilac,
  deYork,
  jordyBlue,
  lochmara,
  crusta,
  supernova,
  darkSupernova,
  dodgerBlue,
  dodgerBlue20,
  sapphire,
  matisse,
  baliHai,
  greyChateau,
  linkWater,
  solitude,
  white,
  azureRadiance,
  softRed,
  bittersweet,
  black,
  lavender,
  shipCove,
  darkIndigo,
  freeSpeachBlue,
  gainsboro,
  darkDeYork,
  cancelRed,
  tableHeaderGray,
  darkRed,
  darkGreen,
};

export default colorsEnum;
