import { number, object, string } from "yup";

import { nanToNull } from "shared/utils";

import {
  MAX_LENGTH_50_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";

export const validationSchema = object().shape({
  intercompanyClient: number()
    .transform(nanToNull)
    .nullable()
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  clientIndustryId: number().nullable().transform(nanToNull),
  chamberOfCommerce: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  vatNumber: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  bankRelation: string().trim().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  bankAccountNumber: string().trim().max(50, MAX_LENGTH_50_VALIDATION_MESSAGE),
  mediaBuying: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  highestNetMedia: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  intercompanyCode: string().when("intercompanyClient", {
    is: 1,
    then: string()
      .trim()
      .nullable()
      .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
      .required(MANDATORY_FILED_VALIDATION_MESSAGE),
    otherwise: string()
      .trim()
      .nullable()
      .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE),
  }),
  localDesignatedCode: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE),
});
