import React, { useEffect, useState, ChangeEvent, ReactElement } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
  TableBody,
  TableCell,
  Toolbar,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useFetch, useProfileGetter, useQuery } from "shared/hooks";
import {
  TableHeader,
  TableFooter,
  TableRowEmpty,
  ClearAll,
  DynamicFilter,
  ConfirmationDialog,
} from "core";
import {
  ManageUserGroupsExtensionSearch,
  UserGroupForm,
} from "access-manager/components";
import { EMPTY_STRING } from "shared/constants";
import { generateUrl } from "shared/utils";
import {
  ADD_USERS_DIALOG_TITLE,
  ADD_USERS_TO_USER_GROUP_DIALOG_CONTENT,
  GET_USER_GROUPS,
  MANAGE_IMPORTED_USERS,
  REMOVE_USERS_DIALOG_TITLE,
  REMOVE_USERS_TO_USER_GROUP_DIALOG_CONTENT,
  USER_GROUP_IMPORTED_USERS,
} from "access-manager/constants";
import { FetchMethodEnum } from "shared/enums";
import {
  setIsSelectedAMUsers,
  setIsOpenAddSelectedUsersDialog,
  setIsOpenRemoveSelectedUsersDialog,
} from "store/actions";
import { Divider, TableRow } from "shared/styles";
import {
  defaultRowsPerPageOptions,
  dynamicFilterOptions,
  sortOptions,
} from "./config";
import { classes, Table, Select } from "./styles";
import {
  UsersResponse,
  User,
  State,
  IResponse,
  UserGroupsResponse,
  ParamTypes,
} from "./types";
import { UserGroupTableRow } from "../../components/UserGroupTableRow/UserGroupTableRow";

const { GET, POST, DELETE } = FetchMethodEnum;

export const ManageUserGroups = (): ReactElement => {
  const query = useQuery();
  const queryObject = Object.fromEntries(query);
  const {
    page: qPage,
    pageSize: qPageSize,
    sortByColumn,
    isascending,
    filterExtensions,
    selectedUserGroupId,
    ...filters
  } = queryObject;
  const intSelectedUserGroupId = selectedUserGroupId
    ? parseInt(selectedUserGroupId, 10)
    : 0;

  const { userGroupId } = useParams<ParamTypes>();

  const getUsersUrl = generateUrl(MANAGE_IMPORTED_USERS);
  const { data, updateParams, executeFetch, isLoading } = useFetch<
    UsersResponse,
    IResponse
  >(getUsersUrl, {
    method: GET,
    initialParams: { ...queryObject, userGroupId },
  });
  const { get: getData, post: postData } = data ?? {};
  const { users = [], page = 1, pageSize = 10, totalItems = 0 } = getData ?? {};

  const {
    push,
    location: { search },
  } = useHistory<State>();

  const { url: base } = useRouteMatch(); // TODO

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [isSelectAll, setSelectAll] = useState<boolean | undefined>(false);

  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsSelectedAMUsers, // TODO
    setIsOpenAddSelectedUsersDialog,
    setIsOpenRemoveSelectedUsersDialog,
  });

  useProfileGetter(0);

  const { isOpenAddSelectedUsersDialog, isOpenRemoveSelectedUsersDialog } =
    appState;

  const apiGetUserGroupsUrl = generateUrl(GET_USER_GROUPS);
  const { data: dataUserGroups } = useFetch<UserGroupsResponse>(
    apiGetUserGroupsUrl,
    {
      method: GET,
    }
  );
  const { get: userGroupsList } = dataUserGroups ?? {};
  const { userGroups = [] } = userGroupsList ?? {};

  const isDisabledClearAllButton =
    filterExtensions == null &&
    isEmpty(filters) &&
    intSelectedUserGroupId === 0;

  // #region Quick Filters
  const handleFilterExtensionsSearch = (extensionId: string) => {
    const currentExtensions = filterExtensions
      ? filterExtensions.split("_")
      : [];
    const nextExtensions = currentExtensions.includes(extensionId)
      ? currentExtensions.filter((s) => s !== extensionId)
      : currentExtensions.concat(extensionId);
    const queryParams = {
      ...queryObject,
      page: 1,
      filterExtensions: isEmpty(nextExtensions)
        ? undefined
        : nextExtensions.join("_"),
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };
  // #endregion

  const handleClearAll = () => {
    const queryParams = {
      page: 1,
      pageSize: 10,
      sortByColumn: queryObject.sortByColumn,
      isascending: queryObject.isascending,
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  const handleSelectUserGroup = (event: SelectChangeEvent<any>) => {
    const targetVal = event.target.value as unknown | number | undefined;

    const queryParams = {
      ...queryObject,
      selectedUserGroupId: targetVal,
      page: 1,
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  // #region Users Actions
  const handleRemoveSelectedUsers = () => {
    const removeUsersUrl = generateUrl(USER_GROUP_IMPORTED_USERS, {
      userGroupId,
    });

    executeFetch(removeUsersUrl, {
      method: DELETE,
      body: { usernames: checkedItems },
    });

    actions.setIsOpenRemoveSelectedUsersDialog(false);
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };

  const handleCloseRemoveSelectedUsersDialog = () => {
    actions.setIsOpenRemoveSelectedUsersDialog(false);
  };

  const handleAddSelectedUsers = () => {
    const addUsersUrl = generateUrl(USER_GROUP_IMPORTED_USERS, {
      userGroupId,
    });

    executeFetch(addUsersUrl, {
      method: POST,
      body: {
        usernames: checkedItems,
      },
    });

    actions.setIsOpenAddSelectedUsersDialog(false);
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };

  const handleCloseAddSelectedUsersDialog = () => {
    actions.setIsOpenAddSelectedUsersDialog(false);
  };
  // #endregion

  // #region Select checkboxes
  const handleCheckboxClick = (username: string) => {
    const items = checkedItems?.some((ci) => ci === username)
      ? checkedItems.filter((ci) => ci !== username)
      : [...checkedItems, username];

    setCheckedItems(items);

    if (isEmpty(items)) {
      setSelectAll(false);
    }

    if (!isEmpty(items) && users.length !== items.length) {
      setSelectAll(undefined);
    }

    if (users.length === items.length) {
      setSelectAll(true);
    }

    actions.setIsSelectedAMUsers(items.length !== 0);
  };

  const handleMainCheckboxClick = () => {
    const usernames = users.map((u) => u.username);

    if (isSelectAll) {
      setSelectAll(false);
      setCheckedItems([]);
      actions.setIsSelectedAMUsers(false);
    } else {
      setSelectAll(true);
      setCheckedItems(usernames);
      actions.setIsSelectedAMUsers(true);
    }
  };

  const handleInvert = () => {
    const usernames = users.map((u) => u.username);
    const invertArray = usernames.filter((x) => !checkedItems.includes(x));

    setCheckedItems(invertArray);

    if (isEmpty(invertArray)) {
      setSelectAll(false);
    }

    if (users.length === invertArray.length) {
      setSelectAll(true);
    }

    actions.setIsSelectedAMUsers(invertArray.length !== 0);
  };
  // #endregion

  // #region Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    const queryPageSize = query.get("pageSize");
    const queryParams = {
      ...queryObject,
      page: newPage + 1,
      pageSize: queryPageSize,
    }; // newPage +1 reason is TablePagination page is zeroBased
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl, {
      actionSelectedItems: false,
    });
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      pageSize: event.target.value,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };
  // #endregion Pagination

  // #region Dynamic filter
  const handleDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      page: 1,
      [key]: dynFilters[key],
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };
  // #endregion

  // #region Sort
  const handleChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as string | null | undefined;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      page: 1,
      sortByColumn: targetVal ?? EMPTY_STRING,
      isascending: isAsc,
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };
  // #endregion

  // #region useEffect
  useEffect(() => {
    const newQuery = new URLSearchParams(search);
    let newQueryObj = Object.fromEntries(newQuery);

    if (userGroupId) {
      newQueryObj = { ...newQueryObj, userGroupId };
    }

    updateParams(newQueryObj);
  }, [userGroupId, search, updateParams]);

  useEffect(() => {
    const { isSuccessful } = (postData ?? {}) as any;
    if (isSuccessful) {
      const newQuery = new URLSearchParams(search);
      let newQueryObj = Object.fromEntries(newQuery);

      if (userGroupId) {
        newQueryObj = { ...newQueryObj, userGroupId };
      }

      updateParams(newQueryObj);
    }
  }, [postData, userGroupId, search, updateParams]);

  useEffect(() => {
    if (checkedItems.length === 0) {
      actions.setIsSelectedAMUsers(false);
    }
  }, [checkedItems, actions]);
  // #endregion useEffect

  return (
    <>
      <UserGroupForm />
      <Toolbar>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Grid
                  container
                  alignContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <ManageUserGroupsExtensionSearch
                      onExtensionClick={handleFilterExtensionsSearch}
                      selected={filterExtensions}
                      isDisabled={!userGroupId}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <Select
                        id="userGroups"
                        name="userGroups"
                        value={intSelectedUserGroupId}
                        onChange={handleSelectUserGroup}
                        disabled={!userGroupId}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 150,
                            },
                          },
                        }}
                      >
                        <MenuItem key={0} id="userGroup-empty" value={0}>
                          None
                        </MenuItem>
                        {userGroups?.map(({ id, name }) => (
                          <MenuItem
                            key={id || undefined}
                            id={`userGroup-${id}`}
                            value={id || undefined}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <DynamicFilter
                      updateUrl={handleDynamicFilter}
                      queryFilters={filters}
                      filterOptions={dynamicFilterOptions}
                      limit={3}
                      isDisabled={!userGroupId}
                    />
                  </Grid>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Grid item>
                    <ClearAll
                      onClear={handleClearAll}
                      disabled={!userGroupId || isDisabledClearAllButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs={12}>
        <Table size="small" className={classes.table}>
          <>
            <TableHeader
              sortOptions={sortOptions}
              sortValue={queryObject.sortByColumn}
              onSortChange={handleChangeSort}
              rowsPerPageOptions={defaultRowsPerPageOptions}
              count={totalItems}
              rowsPerPage={pageSize}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              isDisabled={!userGroupId}
            />
            <TableBody>
              <TableRow>
                <TableCell>
                  {!isEmpty(users) ? (
                    <Checkbox
                      id="check-all"
                      indeterminate={isSelectAll === undefined}
                      inputProps={{ "aria-label": "main checkbox" }}
                      onChange={() => handleMainCheckboxClick()}
                      checked={isSelectAll}
                      disabled={!userGroupId}
                    />
                  ) : (
                    EMPTY_STRING
                  )}
                </TableCell>
                <TableCell>
                  {!isEmpty(users) ? (
                    <Chip
                      id="button-invert"
                      color="primary"
                      onClick={handleInvert}
                      label="Invert Selection"
                      clickable
                      variant="outlined"
                      disabled={!userGroupId}
                    />
                  ) : (
                    EMPTY_STRING
                  )}
                </TableCell>
              </TableRow>
              {isEmpty(users) ? (
                <TableRowEmpty />
              ) : (
                users.map((user: User) => (
                  <UserGroupTableRow
                    key={user.username}
                    {...user}
                    onCheckboxClick={handleCheckboxClick}
                    isChecked={checkedItems.includes(user.username)}
                    isDisabled={!userGroupId}
                  />
                ))
              )}
            </TableBody>
            {userGroupId && (
              <TableFooter
                rowsPerPageOptions={defaultRowsPerPageOptions}
                count={totalItems}
                rowsPerPage={pageSize}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </>
        </Table>
        {isOpenAddSelectedUsersDialog && (
          <ConfirmationDialog
            open={isOpenAddSelectedUsersDialog}
            title={ADD_USERS_DIALOG_TITLE}
            message={ADD_USERS_TO_USER_GROUP_DIALOG_CONTENT}
            isDisableAction={isLoading}
            onConfirm={handleAddSelectedUsers}
            onCancel={handleCloseAddSelectedUsersDialog}
          />
        )}
        {isOpenRemoveSelectedUsersDialog && (
          <ConfirmationDialog
            open={isOpenRemoveSelectedUsersDialog}
            title={REMOVE_USERS_DIALOG_TITLE}
            message={REMOVE_USERS_TO_USER_GROUP_DIALOG_CONTENT}
            isDisableAction={isLoading}
            onConfirm={handleRemoveSelectedUsers}
            onCancel={handleCloseRemoveSelectedUsersDialog}
          />
        )}
      </Grid>
    </>
  );
};

export default ManageUserGroups;
