import { number, object, string } from "yup";
import {
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  businessUnitId: number()
    .nullable()
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .transform(nanToNull),
  clientLead: string()
    .trim()
    .when("$isContract", {
      is: true,
      then: string()
        .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
        .required(MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: string().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
    }),
  processManager: string()
    .trim()
    .when("$isContract", {
      is: true,
      then: string()
        .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
        .required(MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: string().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
    }),
});
