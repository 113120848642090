import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  rightToFinanceAudit: null,
  accountants: EMPTY_STRING,
  rightToMediaAudit: null,
  auditPeriodId: null,
  otherAuditPeriod: EMPTY_STRING,
};
