import React, { ReactElement, useState } from "react";

import { Info as InfoIcon } from "@mui/icons-material";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";

export interface TouchTooltipProps {
  id: string;
  title: string;
}

const PopperProps = {
  disablePortal: true,
};
export const TouchTooltip = ({
  id,
  title,
}: TouchTooltipProps): ReactElement => {
  const [open, setOpen] = useState<NullableString>(null);

  const handleTooltipClose = () => {
    setOpen(null);
  };

  const handleTooltipOpen = () => {
    setOpen(id);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          color="primary"
          PopperProps={PopperProps}
          onClose={handleTooltipClose}
          open={id === open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
        >
          <IconButton onClick={handleTooltipOpen}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default TouchTooltip;
