import React, { useState, ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { useLocation, useRouteMatch } from "react-router-dom";

import {
  Home as HomeIcon,
  NoteAdd as NoteAddIcon,
  Dashboard as DashboardIcon,
  Inventory as InventoryIcon,
  DoneOutline as DoneOutlineIcon,
  ViewHeadline as ViewHeadlineIcon,
  Feed as ProductFeedIcon,
  Filter2 as Version2Icon,
  Filter3 as Version3Icon,
  Filter4 as Version4Icon,
  Filter5 as Version5Icon,
  Filter6 as Version6Icon,
  Handyman as HandymanIcon,
  CleaningServices as CleaningServicesIcon,
} from "@mui/icons-material";
import { List, Collapse } from "@mui/material";

import {
  PAGE_OVERVIEW,
  PAGE_OVERVIEW_ACTION,
  PAGE_DASHBOARD_CLIENT,
  PAGE_DASHBOARD_PRODUCT,
  APP_MASTERDATA,
} from "master-data/constants";
import { PAGE_HOME, INIT_QUERY, PAGE_PROFILE } from "shared/constants";
import {
  APP_BACKOFFICE,
  PAGE_AUDIT_TRAIL_SYNC,
  PAGE_DEBTOR_SYNC,
  PAGE_DOCUMENT_SCHEMA_MANAGEMENT,
  PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V2,
  PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V4,
  PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V5,
  PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V6,
  URL_AUDIT_TRAIL_SYNC,
  URL_DEBTOR_SYNC,
} from "back-office/constants";

import {
  APP_ACCESSMANAGER,
  INIT_OVERVIEW_PAGE,
  INIT_QUERY_DEBTORS,
  INIT_QUERY_IMPORT_USERS,
  PAGE_CREATE_MAPPINGS,
  URL_DEBTOR_GROUP_OVERVIEW,
  URL_USER_GROUP_OVERVIEW,
  URL_MAPPING_OVERVIEW,
  URL_CREATE_MAPPING,
  INIT_QUERY_MAPPINGS,
  PAGE_MANAGE_DEBTOR_GROUPS,
  URL_MANAGE_DEBTOR_GROUP,
  PAGE_MANAGE_USER_GROUPS,
  URL_MANAGE_USER_GROUP,
} from "access-manager/constants";
import {
  APP_PRODUCT,
  INIT_QUERY_PRODUCTS,
  INIT_QUERY_PRODUCTS_WITH_AGENCY,
} from "product/constants";
import { MenuItem, MenuNavItem } from "./components";

const HOME_ID = "menu-home";
const DASHBOARD_ID = "menu-dashboard";
const DASHBORAD_CLINET_ID = "menu-client";
const DASHBORAD_PRODUCT_ID = "menu-product";
const CLIENT_OVERVIEW_ID = "menu-client-overview";
const PRODUCT_OVERVIEW_ID = "menu-product-overview";
const ACTION_OVERVIEW_ID = "menu-action-overview";
const USER_GROUP_ID = "menu-user-group";
const USER_GROUP_OVERVIEW_ID = "menu-user-group-overview";
const DEBTOR_GROUP_ID = "menu-debtor-group";
const DEBTOR_GROUP_OVERVIEW_ID = "menu-debtor-group-overview";
const MAPPING_OVERVIEW_ID = "menu-mapping-overview";
const MAPPING_ID = "menu-mapping";
const SCHEMA_ID = "menu-schema";
const SCHEMA_V2_ID = "menu-schema-v2";
const SCHEMA_V4_ID = "menu-schema-v4";
const SCHEMA_V5_ID = "menu-schema-v5";
const SCHEMA_V6_ID = "menu-schema-v6";
const DEBTOR_SYNC_ID = "menu-debtor-sync";
const AUDIT_TRAIL_SYNC_ID = "menu-audit-trail-sync";

export const NavMenu = (): ReactElement => {
  const { url } = useRouteMatch();

  //  example url: /master-data/blossom
  //  split result: "", master-data, blossom
  //  agency name: urlSegments[2]
  const urlSegments = url.split("/");

  let madamUrl = url;
  if (urlSegments.length > 2) {
    madamUrl = `/${APP_MASTERDATA}/${urlSegments[2]}`;
  }

  let productUrl = url;
  if (urlSegments.length > 2) {
    productUrl = `/${APP_PRODUCT}/${urlSegments[2]}`;
  }

  const { pathname } = useLocation();

  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    [DASHBOARD_ID]: false,
  });

  const {
    state: { profile },
  } = useStateMachine();

  const toggleOpen = (id: string) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
  };

  const { claims } = profile;

  const isProfilePage = pathname.includes(PAGE_PROFILE);
  const isBackOffice = pathname.includes(APP_BACKOFFICE);
  const isDocumentSchemaPage = pathname.includes(
    PAGE_DOCUMENT_SCHEMA_MANAGEMENT
  );
  const isDebtorSyncPage = pathname.includes(URL_DEBTOR_SYNC);
  const isAuditTrailSyncPage = pathname.includes(URL_AUDIT_TRAIL_SYNC);
  const isAccessManagerPage = pathname.includes(APP_ACCESSMANAGER);
  const isUserGroupOverviewPage = pathname.includes(URL_USER_GROUP_OVERVIEW);
  const isCreateUserGroupPage = pathname.includes(URL_MANAGE_USER_GROUP);
  const isDebtorGroupOverviewPage = pathname.includes(
    URL_DEBTOR_GROUP_OVERVIEW
  );
  const isCreateDebtorGroupPage = pathname.includes(URL_MANAGE_DEBTOR_GROUP);
  const isMappingOverviewPage = pathname.includes(URL_MAPPING_OVERVIEW);
  const isCreateMappingPage = pathname.includes(URL_CREATE_MAPPING);
  const canCreateEditDocument = claims.includes("CanCreateEditDocument");
  const canViewAgencyFilter = claims.includes("CanViewAgencyFilter");

  return (
    <List component="nav">
      <MenuNavItem
        exact
        title="Home"
        id={HOME_ID}
        to={PAGE_HOME}
        icon={<HomeIcon />}
        rule="CanViewAgencySelectionPage"
      />
      {(isUserGroupOverviewPage || isCreateUserGroupPage) && (
        <MenuNavItem
          title="Create User Group"
          id={USER_GROUP_ID}
          icon={<NoteAddIcon />}
          to={{
            search: INIT_QUERY_IMPORT_USERS,
            pathname: `${madamUrl}/${PAGE_MANAGE_USER_GROUPS}`,
          }}
        />
      )}
      {(isCreateUserGroupPage || isUserGroupOverviewPage) && (
        <MenuNavItem
          title="User Group Overview"
          id={USER_GROUP_OVERVIEW_ID}
          icon={<ViewHeadlineIcon />}
          to={{
            search: INIT_OVERVIEW_PAGE,
            pathname: `${madamUrl}/${PAGE_OVERVIEW}/${PAGE_MANAGE_USER_GROUPS}`,
          }}
        />
      )}
      {(isDebtorGroupOverviewPage || isCreateDebtorGroupPage) && (
        <MenuNavItem
          title="Create Debtor Group"
          id={DEBTOR_GROUP_ID}
          icon={<NoteAddIcon />}
          to={{
            search: INIT_QUERY_DEBTORS,
            pathname: `${madamUrl}/${PAGE_MANAGE_DEBTOR_GROUPS}`,
          }}
        />
      )}
      {(isCreateDebtorGroupPage || isDebtorGroupOverviewPage) && (
        <MenuNavItem
          title="Debtor Group Overview"
          id={DEBTOR_GROUP_OVERVIEW_ID}
          icon={<ViewHeadlineIcon />}
          to={{
            search: INIT_OVERVIEW_PAGE,
            pathname: `${madamUrl}/${PAGE_OVERVIEW}/${PAGE_MANAGE_DEBTOR_GROUPS}`,
          }}
        />
      )}
      {(isMappingOverviewPage || isCreateMappingPage) && (
        <>
          <MenuNavItem
            title="Create Mappings"
            id={MAPPING_ID}
            icon={<NoteAddIcon />}
            to={{
              search: INIT_QUERY_MAPPINGS,
              pathname: `${madamUrl}/${PAGE_CREATE_MAPPINGS}`,
            }}
          />
          <MenuNavItem
            title="Mapping Overview"
            id={MAPPING_OVERVIEW_ID}
            icon={<ViewHeadlineIcon />}
            to={{
              search: INIT_OVERVIEW_PAGE,
              pathname: `${madamUrl}/${PAGE_OVERVIEW}/${PAGE_CREATE_MAPPINGS}`,
            }}
          />
        </>
      )}
      {isDocumentSchemaPage && (
        <div>
          <MenuNavItem
            title="Schema Version"
            id={SCHEMA_V2_ID}
            icon={<Version2Icon />}
            to={{
              search: INIT_OVERVIEW_PAGE,
              pathname: `${madamUrl}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V2}`,
            }}
          />
          <MenuNavItem
            title="Schema Version"
            id={SCHEMA_ID}
            icon={<Version3Icon />}
            to={{
              search: INIT_OVERVIEW_PAGE,
              pathname: `${madamUrl}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT}`,
            }}
          />
          <MenuNavItem
            title="Schema Version"
            id={SCHEMA_V4_ID}
            icon={<Version4Icon />}
            to={{
              search: INIT_OVERVIEW_PAGE,
              pathname: `${madamUrl}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V4}`,
            }}
          />
          <MenuNavItem
            title="Schema Version"
            id={SCHEMA_V5_ID}
            icon={<Version5Icon />}
            to={{
              search: INIT_OVERVIEW_PAGE,
              pathname: `${madamUrl}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V5}`,
            }}
          />
          <MenuNavItem
            title="Schema Version"
            id={SCHEMA_V6_ID}
            icon={<Version6Icon />}
            to={{
              search: INIT_OVERVIEW_PAGE,
              pathname: `${madamUrl}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V6}`,
            }}
          />
        </div>
      )}
      {isBackOffice && (isDebtorSyncPage || isAuditTrailSyncPage) && (
        <>
          <MenuNavItem
            title="Debtor Sync"
            id={DEBTOR_SYNC_ID}
            to={`${madamUrl}/${PAGE_DEBTOR_SYNC}`}
            icon={<HandymanIcon />}
          />
          <MenuNavItem
            title="Audit Trail Sync"
            id={AUDIT_TRAIL_SYNC_ID}
            to={`${madamUrl}/${PAGE_AUDIT_TRAIL_SYNC}`}
            icon={<CleaningServicesIcon />}
          />
        </>
      )}
      {!isBackOffice && !isAccessManagerPage && !isProfilePage && (
        <>
          {canCreateEditDocument && (
            <>
              <MenuItem
                title="Create"
                id={DASHBOARD_ID}
                onClick={toggleOpen}
                icon={<NoteAddIcon />}
              />
              <Collapse
                in={
                  open[DASHBOARD_ID] || pathname.includes(PAGE_DASHBOARD_CLIENT)
                }
                timeout="auto"
              >
                <List component="div" disablePadding>
                  <MenuNavItem
                    persist
                    isNested
                    title="Client"
                    id={DASHBORAD_CLINET_ID}
                    icon={<DashboardIcon />}
                    to={`${madamUrl}/${PAGE_DASHBOARD_CLIENT}`}
                  />
                  <MenuNavItem
                    persist
                    isNested
                    title="Product"
                    id={DASHBORAD_PRODUCT_ID}
                    icon={<InventoryIcon />}
                    to={`${productUrl}/${PAGE_DASHBOARD_PRODUCT}`}
                  />
                </List>
              </Collapse>
            </>
          )}
          <MenuNavItem
            title="Overview"
            id={CLIENT_OVERVIEW_ID}
            icon={<ViewHeadlineIcon />}
            to={{
              search: INIT_QUERY,
              pathname: `${madamUrl}/${PAGE_OVERVIEW}`,
            }}
            rule="CanViewClientDocumentOverviewPage"
          />
          <MenuNavItem
            title="Action Overview"
            id={ACTION_OVERVIEW_ID}
            icon={<DoneOutlineIcon />}
            to={{
              search: INIT_QUERY,
              pathname: `${madamUrl}/${PAGE_OVERVIEW_ACTION}`,
            }}
            rule="CanViewClientDocumentActionPage"
          />
          <MenuNavItem
            title="Product Overview"
            id={PRODUCT_OVERVIEW_ID}
            icon={<ProductFeedIcon />}
            to={{
              search: canViewAgencyFilter
                ? INIT_QUERY_PRODUCTS_WITH_AGENCY
                : INIT_QUERY_PRODUCTS,
              pathname: `${productUrl}/${PAGE_OVERVIEW}`,
            }}
            rule="CanViewProductOverviewPage"
          />
        </>
      )}
    </List>
  );
};

export default NavMenu;
