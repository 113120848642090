import { styled, Dialog as MuiDialog } from "@mui/material";

const PREFIX = "UserManagementDialogStyles";
export const classes = {
  title: `${PREFIX}-title`,
};

export const Dialog = styled(MuiDialog)(() => ({
  [`& .${classes.title}`]: {
    textTransform: "capitalize",
  },
}));
