import { createTheme } from "@mui/material";
import { cancelRed, freeSpeachBlue } from "shared/assets/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: freeSpeachBlue,
    },
    secondary: {
      main: cancelRed,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: "auto",
        },
        body: {
          backgroundColor: "#fafafa",
        },
      },
    },
  },
});
