import React, { useState, useEffect, ReactElement } from "react";
import {
  Button,
  Table,
  TableBody,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { TableRowEmpty, ConfirmationDialog } from "core";
import { isEmpty } from "lodash";
import { FetchMethodEnum, useFetch, useProfileGetter } from "shared/hooks";
import { generateUrl } from "shared/utils";
import {
  GET_DOCUMENT_SCHEMA_OVERVIEW,
  UPDATE_DOCUMENT_SCHEMA_OVERVIEW,
  UPDATE_VERSION_CONTENT,
  UPDATE_VERSION_TITLE,
} from "back-office/constants";
import { DocumentSchemaTableRow } from "back-office/components";
import SyncIcon from "@mui/icons-material/Sync";
import { useStateMachine } from "little-state-machine";
import { setIsOpenUpdateDialog } from "store/actions";
import { TableRow } from "shared/styles";
import {
  DocumentSchemaOverviewResponse,
  DocumentSchemaOverviewPostResponse,
  DocumentSchemaRow,
  DocumentSchemaIds,
  FailedDocument,
} from "./types";
import { classes, Grid, TableCell } from "./styles";

const { GET, PUT } = FetchMethodEnum;

export const DocumentSchemaManagementV4 = (): ReactElement => {
  const [isDisableButtons, setIsDisableButtons] = useState<boolean>(false);
  const [ids, setIds] = useState<DocumentSchemaIds>({
    versionId: null,
    id: null,
  });

  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsOpenUpdateDialog,
  });

  const { isOpenUpdateDialog } = appState;

  const apiUrl = generateUrl(GET_DOCUMENT_SCHEMA_OVERVIEW, { versionId: 4 });
  const { data, executeFetch, refetch } =
    useFetch<DocumentSchemaOverviewResponse>(apiUrl, {
      method: GET,
    });

  const { get: getData, post: postData } = data ?? {};

  const { version, items = [] } = getData ?? {};
  const { failedDocuments = [] } = (postData ??
    {}) as DocumentSchemaOverviewPostResponse;

  useProfileGetter(0);

  const handleUpdate = (versionId: number | undefined, id: number) => {
    setIds({ versionId, id });
  };

  const handleRefresh = () => {
    refetch();
  };

  const handleUpdateVersion = () => {
    const { versionId, id } = ids;
    const apiDeleteMappingUrl = generateUrl(UPDATE_DOCUMENT_SCHEMA_OVERVIEW, {
      versionId,
    });
    executeFetch(apiDeleteMappingUrl, {
      method: PUT,
      body: { versionId, documentGroupId: id },
      skip: false,
    });
    setIsDisableButtons(true);
    actions.setIsOpenUpdateDialog(false);
  };

  useEffect(() => {
    if (postData) {
      setIsDisableButtons(false);
      refetch();
    }
  }, [actions, postData, refetch]);

  return (
    <>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item>
                <Tooltip title="Refresh" placement="bottom-start">
                  <Button color="primary" onClick={handleRefresh}>
                    <SyncIcon />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs={12}>
        <Table size="small">
          <TableBody>
            {isEmpty(items) ? (
              <TableRowEmpty />
            ) : (
              items.map((documentRow: DocumentSchemaRow) => (
                <DocumentSchemaTableRow
                  key={documentRow.id}
                  {...documentRow}
                  version={version}
                  isDisabled={isDisableButtons}
                  onUpdate={handleUpdate}
                />
              ))
            )}
            <TableRow>
              <TableCell className={classes.versionText}>
                <Grid item xs={6} className={classes.header}>
                  <Typography
                    variant="h5"
                    className={`${classes.typography} ${classes.versionHeadline}`}
                  >
                    Version {version}
                  </Typography>
                </Grid>

                <Grid item className={classes.textItem}>
                  <Typography
                    className={classes.text}
                    component="span"
                    align="left"
                  >
                    <ul>
                      <li>
                        Added non mandatory fields in <b>Inventory Media</b>{" "}
                        form:
                        <ul>
                          <li>“Additional conditions in contract” checkbox</li>
                          <li>“Contract reference and/or text” text field</li>
                        </ul>
                      </li>
                      <li>
                        Added non mandatory fields to{" "}
                        <b>Commercial Agreements</b> form:
                        <ul>
                          <li>“Liquid Budget” checkbox</li>
                          <li>“Except” text field</li>
                          <li>
                            If “Liquid Budget” checkbox is set to “No”, “Except”
                            text field is set to NULL and disabled
                          </li>
                        </ul>
                      </li>
                      <li>
                        Added non mandatory field to in <b>Hours</b> form:
                        <ul>
                          <li>“Hours Yearly Inflation” checkbox</li>
                        </ul>
                      </li>
                      <li>
                        Added non mandatory fields to{" "}
                        <b>Invoice Requirements</b> form:
                        <ul>
                          <li>“Invoice per Insertion” checkbox</li>
                          <li>“Invoice per Collective Product” checkbox</li>
                          <li>“Invoice per Media” checkbox</li>
                          <li>“Invoice per Surcharge Code” checkbox</li>
                        </ul>
                      </li>
                    </ul>
                  </Typography>
                </Grid>
              </TableCell>

              <TableCell className={classes.failedDocuments}>
                <Grid item xs={6} className={classes.header}>
                  <Typography variant="h5" className={classes.typography}>
                    Failed Documents List
                  </Typography>
                </Grid>
                <Grid item container direction="column" spacing={3} xs={6}>
                  <Grid item>
                    {isEmpty(failedDocuments) ? (
                      <Typography align="center" variant="body1">
                        No item to display
                      </Typography>
                    ) : (
                      failedDocuments.map((documentRow: FailedDocument) => (
                        <Typography>{documentRow.name}</Typography>
                      ))
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      {isOpenUpdateDialog && (
        <ConfirmationDialog
          open={isOpenUpdateDialog}
          title={UPDATE_VERSION_TITLE}
          message={UPDATE_VERSION_CONTENT}
          isDisableAction={Boolean(false)}
          onConfirm={handleUpdateVersion}
          onCancel={() => actions.setIsOpenUpdateDialog(false)}
        />
      )}
    </>
  );
};

export default DocumentSchemaManagementV4;
