import { object, number, string } from "yup";

import { nanToNull } from "shared/utils";
import {
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_LENGTH_1000_VALIDATION_MESSAGE,
} from "shared/constants";

export const validationSchema = object().shape({
  feeBasedArrangement: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  revenuRecognizedOverTime: string().required(
    MANDATORY_FILED_VALIDATION_MESSAGE
  ),
  retainerEntitysEfforts: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  mediaCommissions: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  tradingIncome: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  clientIncentives: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  timeAndMaterial: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  shortTermFeeBasedArrangement: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  otherRetainer: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  other: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  remarks: string()
    .trim()
    .nullable()
    .max(1000, MAX_LENGTH_1000_VALIDATION_MESSAGE),
});
