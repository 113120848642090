import { EMPTY_STRING } from "../../../shared/constants/client";

export interface FilterOptions {
  id: number;
  property: string;
  text: string;
}

export const defaultRowsPerPageOptions = [5, 10, 25];

export const sortOptions = [
  { key: EMPTY_STRING, text: "None" },
  { key: "debtorGroupName_asc", text: "By Debtor Group Name asc" },
  { key: "debtorGroupName", text: "By Debtor Group Name dsc" },
  { key: "description_asc", text: "By Description asc" },
  { key: "description", text: "By Description dsc" },
];

export const dynamicFilterOptions: FilterOptions[] = Object([
  { id: 1, property: "debtorGroupName", text: "By Debtor Group Name" },
  { id: 2, property: "description", text: "By Description" },
  { id: 3, property: "debtorName", text: "By Debtor Name" },
]);
