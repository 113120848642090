import React, { ReactElement, useState, useCallback, useEffect } from "react";

import { useStateMachine } from "little-state-machine";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory, useParams } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Divider,
} from "@mui/material";
import {
  resetGeneralClientInfoMailing,
  resetProductData,
  resetProductStatus,
  setChangeStatusId,
  setOpenStatusDialog,
  setProductData,
  setProductStatus,
  setIsProductStatusChanged,
  setMarathonProductId,
} from "store/actions";
import { generateUrl } from "shared/utils";
import { FetchMethodEnum, useFetch } from "shared/hooks";

import { IFormsInput, IProductInput } from "product/components/Forms/types";
import ProductForm from "product/components/Forms/ProductForm";
import {
  APP_PRODUCT,
  CHANGE_PRODUCT_STATUS,
  CREATE_MARATHON_PRODUCT,
  INIT_QUERY_PRODUCTS,
  INIT_QUERY_PRODUCTS_WITH_AGENCY,
  MANAGE_PRODUCT,
  PAGE_OVERVIEW,
} from "product/constants";
import { AgencyEnum, ProductStatus } from "product/enums";
import StatusDialog from "product/components/StatusDialog";
import CreateMarathonProductDialog from "product/components/CreateMarathonProductDialog";

import { EMPTY_STRING } from "shared/constants";
import {
  CLAIM_EDIT_CREATOR_FIELD,
  CLAIM_EDIT_VALIDATOR_FIELD,
} from "master-data/constants";

import { classes, Button } from "./styles";
import { IResponseGet, IResponsePost, ParamTypes } from "./types";
import { AccordionSummary as Summary } from "../../../master-data/components/AccordionSummary";

const { GET, PUT, POST } = FetchMethodEnum;

export const ProductDashboard = (): ReactElement => {
  const [isInEditing, setIsInEditing] = useState<boolean>(false);
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [isFirstTry, setIsFirstTry] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isCreateMarathonProductDialogOpen,
    setIsCreateMarathonProductDialogOpen,
  ] = useState<boolean>(true);

  const {
    actions,
    state: { productData, productStatusData, profile },
  } = useStateMachine({
    setProductStatus,
    resetProductStatus,
    setProductData,
    resetProductData,
    resetGeneralClientInfoMailing,
    setOpenStatusDialog,
    setChangeStatusId,
    setIsProductStatusChanged,
    setMarathonProductId,
  });

  const {
    isProductStatusChanged,
    openStatusDialog,
    changeStatusId,
    isProductEditable,
  } = productData; // TODO: No use at the moment

  const { claims } = profile;
  const isCreator = claims.includes(CLAIM_EDIT_CREATOR_FIELD);
  const isValidator = claims.includes(CLAIM_EDIT_VALIDATOR_FIELD);
  const isAdmin = isValidator && isCreator;

  const history = useHistory();
  const { productId, agencyName } = useParams<ParamTypes>();

  const agencyId = AgencyEnum[agencyName];

  const productUrl = generateUrl(MANAGE_PRODUCT, {
    agencyId,
    productId,
  });

  const { data, executeFetch, hasError, isLoading } = useFetch<
    IResponseGet,
    IResponsePost
  >(productUrl, {
    method: GET,
  });

  const { get: getData, post: postData } = data ?? {};
  const { isSuccessful } = postData ?? {};

  const {
    productName,
    productReference,
    collectiveProduct,
    collectiveProductExist,
    attn,
    mailingAddress,
    postalCode,
    city,
    country,
    emailAccountancy,
    invoicingMethodId,
    invoiceMailingAddress,
    activationMBSRadio,
    activationMBSTV,
    division,
    businessLine,
    businessUnit,
  } =
    (postData?.isSuccessful && postData.productName ? postData : getData) ?? {};

  const formsData: IFormsInput = {
    productInfo: {
      productName: productName ?? "",
      productReference: productReference ?? null,
      collectiveProduct: collectiveProduct ?? null,
      collectiveProductExist: collectiveProductExist ?? null,
    },
    activationMBS: {
      activationMBSRadio: activationMBSRadio ?? false,
      activationMBSTV: activationMBSTV ?? false,
    },
    businessSection: {
      division: division ?? null,
      businessLine: businessLine ?? null,
      businessUnit: businessUnit ?? null,
    },
    invoicingAddressMethod: {
      attn: attn ?? null,
      city: city ?? null,
      country: country ?? null,
      postalCode: postalCode ?? null,
      emailAccountancy: emailAccountancy ?? null,
      mailingAddress: mailingAddress ?? null,
      invoiceMailingAddress: invoiceMailingAddress ?? [],
      invoicingMethodId: invoicingMethodId ?? null,
    },
  };

  const putDataInit: IProductInput = {
    ...formsData.activationMBS,
    ...formsData.businessSection,
    ...formsData.invoicingAddressMethod,
    ...formsData.productInfo,
  };
  const [putData, setPutData] = useState(putDataInit);

  // #region Methods
  const handleCleanUp = useCallback(() => {
    actions.resetProductData(undefined);
    actions.resetProductStatus(undefined);
    actions.resetGeneralClientInfoMailing(undefined);
  }, [actions]);

  const handleEdit = () => {
    setIsInEditing(true);
  };

  const handleCancel = () => {
    setIsInEditing(false);
  };

  const handleSave = useCallback(() => {
    setIsInEditing(false);
    setSaveTriggered(false);
    executeFetch(productUrl, { method: PUT, body: putData });
  }, [executeFetch, productUrl, putData]);

  const gatherData = (submittedData: IProductInput) => {
    setPutData((prevValue) => {
      return { ...prevValue, ...submittedData };
    });
    setSaveTriggered(true);
  };

  const handleChangeStatus = () => {
    const changeProductStatusUrl = generateUrl(CHANGE_PRODUCT_STATUS, {
      agencyId,
      productId,
    });
    executeFetch(changeProductStatusUrl, {
      method: PUT,
      body: {
        currentStatusId: productStatusData.statusId,
        statusId: changeStatusId,
      },
    });

    if (isSuccessful) {
      actions.setOpenStatusDialog(false);
      if (
        changeStatusId === ProductStatus.ReadyForProcessing ||
        changeStatusId === ProductStatus.Finalized
      ) {
        if (profile.agentForAgencies.length > 0) {
          history.push(
            `/${APP_PRODUCT}/${agencyName.toLowerCase()}/${PAGE_OVERVIEW}${INIT_QUERY_PRODUCTS_WITH_AGENCY}`
          );
        } else {
          history.push(
            `/${APP_PRODUCT}/${agencyName.toLowerCase()}/${PAGE_OVERVIEW}${INIT_QUERY_PRODUCTS}`
          );
        }
      }
    }
  };

  const handleCancelStatusDialog = () => {
    actions.setOpenStatusDialog(false);
    actions.setChangeStatusId(null);
  };

  const handleCancelCreateMarathonProductDialog = () => {
    setIsCreateMarathonProductDialogOpen(false);

    if (profile.agentForAgencies.length > 0) {
      history.push(
        `/${APP_PRODUCT}/${agencyName.toLowerCase()}/${PAGE_OVERVIEW}${INIT_QUERY_PRODUCTS_WITH_AGENCY}`
      );
    } else {
      history.push(
        `/${APP_PRODUCT}/${agencyName.toLowerCase()}/${PAGE_OVERVIEW}${INIT_QUERY_PRODUCTS}`
      );
    }
  };

  const handleMarathonProductCreation = () => {
    setLoading(true);
    const createMarathonProductUrl = generateUrl(CREATE_MARATHON_PRODUCT, {
      agencyId,
      productId,
    });
    executeFetch(createMarathonProductUrl, {
      method: POST,
      body: {
        marathonClientId: productData.marathonClientId,
        clientDocumentId: productData.documentId,
        productName: productData.productName,
      },
    });
  };

  // #endregion Methods

  // TODO: refactor useeffect section
  // #region Effects
  useEffect(() => {
    const { productName: name } =
      (postData?.isSuccessful && postData?.productName ? postData : getData) ??
      {};

    if (getData) {
      const {
        documentId,
        documentName,
        agencyId: agency,
        status,
        isProductEditable: isEditable,
        marathonClientId,
        marathonProductId,
      } = getData;
      actions.setProductData({
        productId: Number(productId),
        productName: name ?? EMPTY_STRING,
        documentId,
        documentName,
        agencyId: agency,
        isProductEditable: isEditable,
        isProductStatusChanged: false,
        marathonClientId,
        marathonProductId,
        openStatusDialog: false,
        changeStatusId: null,
        isFormDirty: false,
      });

      actions.setProductStatus(status);

      window.scrollTo(0, 0); // TODO: It works, but think about a better solution
    }

    if (postData?.isSuccessful) {
      const { status } = postData;
      if (status) {
        const { statusId, statusName, statusTransitions } = status;
        actions.setProductStatus({ statusId, statusName, statusTransitions });
      }

      const { marathonProductId } = postData;

      if (marathonProductId) {
        actions.setMarathonProductId(marathonProductId);
      }

      const { isProductStatusChanged: change } = postData;
      actions.setIsProductStatusChanged(change === undefined ? false : change);
    } else if (postData?.isSuccessful === false && !postData?.status) {
      setIsFirstTry(false);
    }
    setLoading(false);
  }, [postData, actions, productId, getData]);

  useEffect(() => {
    if (getData) {
      actions.setIsProductStatusChanged(false);
    }
  }, [getData, actions]);

  useEffect(() => {
    if (
      isProductStatusChanged ||
      !productData.marathonProductId ||
      productStatusData.statusId === ProductStatus.Finalized
    ) {
      executeFetch(productUrl, { method: GET });
    }
  }, [
    executeFetch,
    isProductStatusChanged,
    productUrl,
    productStatusData.statusId,
    productData.marathonProductId,
  ]);

  useEffect(() => {
    return () => {
      handleCleanUp();
    };
  }, [handleCleanUp]);

  useEffect(() => {
    if (saveTriggered) handleSave();
  }, [saveTriggered, handleSave]);

  // TODO: find better solution for this, after refactoring
  useEffect(() => {
    if (hasError) {
      setLoading(false);
    }
  }, [setLoading, hasError]);
  // #endregion Effects

  return (
    <Accordion expanded>
      <AccordionSummary aria-controls="product-content" id="product-header">
        <Summary
          title="Product"
          type="product"
          onEdit={handleEdit}
          isEdit={!isInEditing && isProductEditable}
        />
      </AccordionSummary>
      <AccordionDetails>
        <ProductForm
          formData={formsData}
          onSave={gatherData}
          isInEditing={isInEditing}
          isLoading={isLoading}
          getData={getData}
          putData={putData}
          hasError={hasError}
        />
      </AccordionDetails>
      <Divider />
      {isInEditing && (
        <AccordionActions>
          <Button
            id="button-reset"
            form="ProductForm"
            size="small"
            type="reset"
            onClick={handleCancel}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            id="button-submit"
            form="ProductForm"
            size="small"
            color="primary"
            type="submit"
          >
            Save
          </Button>
        </AccordionActions>
      )}
      <StatusDialog
        open={openStatusDialog}
        onChangeStatus={handleChangeStatus}
        onCancel={handleCancelStatusDialog}
      />
      <CreateMarathonProductDialog
        open={
          productStatusData.statusId === ProductStatus.Processing &&
          !productData.marathonProductId &&
          isCreateMarathonProductDialogOpen
        }
        isFirstTry={isFirstTry}
        productId={productData.marathonProductId}
        onCancel={handleCancelCreateMarathonProductDialog}
        onCreate={handleMarathonProductCreation}
        disabled={!isValidator && !isAdmin}
        loading={loading}
      />
    </Accordion>
  );
};

export default ProductDashboard;
