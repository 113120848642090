import React, { ReactElement } from "react";
import {
  Grid,
  TableCell,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";

import { EMPTY_STRING, PLACEHOLDER } from "shared/constants";
import {
  INIT_OVERVIEW_PAGE,
  INIT_QUERY_IMPORT_USERS,
  URL_MANAGE_USER_GROUP,
  URL_MAPPING_OVERVIEW,
} from "access-manager/constants";
import LaunchIcon from "@mui/icons-material/Launch";
import clsx from "clsx";
import { classes, TableRow } from "./styles";

export interface UserGroupOverviewTableRowProps {
  userGroupId: number;
  name: string;
  description: string;
  numberOfUsers: number;
}

export const UserGroupOverviewTableRow = ({
  userGroupId,
  name,
  description,
  numberOfUsers,
}: UserGroupOverviewTableRowProps): ReactElement => {
  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item xs={4}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              User Group Name
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
            >
              <Link
                to={`/${URL_MANAGE_USER_GROUP}/${userGroupId}${INIT_QUERY_IMPORT_USERS}`}
                className={classes.typographyLink}
              >
                <Tooltip
                  title={name || EMPTY_STRING}
                  enterDelay={250}
                  placement="bottom-start"
                >
                  <Typography
                    className={clsx(classes.typographyValue, classes.hoverText)}
                    variant="subtitle1"
                    noWrap
                  >
                    {name}
                  </Typography>
                </Tooltip>
              </Link>
              <Link
                target="_blank"
                to={`/${URL_MAPPING_OVERVIEW}${INIT_OVERVIEW_PAGE}&userGroupName=${name}`}
              >
                <IconButton
                  name={`button-MappingPage-${userGroupId}`}
                  id={`button-MappingPage-${userGroupId}`}
                  size="small"
                  className={classes.hoverText}
                >
                  <LaunchIcon className={classes.icon} />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={description || EMPTY_STRING}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {description ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Description
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={numberOfUsers || EMPTY_STRING}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {numberOfUsers ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Number Of Users
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default UserGroupOverviewTableRow;
