import React, { ReactElement } from "react";
import { Cancel } from "@mui/icons-material";

import { classes, Chip } from "../../styles";

export interface ChipViewProps {
  filterPropertyText: string;
  filterProperty: string;
  filterValue: string | undefined;
  onDelete: (arg0: string) => void;
  onClick: () => void;
}

export const ChipView = ({
  onDelete,
  filterPropertyText,
  filterProperty,
  filterValue,
  onClick,
}: ChipViewProps): ReactElement => {
  const handleDelete = () => onDelete(filterProperty);
  return (
    <Chip
      className={classes.filter}
      label={`${filterPropertyText} = ${filterValue}`}
      onDelete={handleDelete}
      variant="outlined"
      onClick={onClick}
      deleteIcon={<Cancel className={classes.icon} />}
    />
  );
};

export default ChipView;
