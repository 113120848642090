import { object, number, date, boolean, ref, string } from "yup";

import {
  INVALID_DATE_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  INVALID_END_DATE_BEFORE_VALIDATION_MESSAGE,
  MAX_LENGTH_1000_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  flexId: number().nullable().transform(nanToNull),
  startDateFlex: date()
    .when("flexId", {
      is: 2,
      then: date()
        .nullable()
        .typeError(INVALID_DATE_VALIDATION_MESSAGE)
        .required(MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: date().nullable().typeError(INVALID_DATE_VALIDATION_MESSAGE),
    })
    .nullable()
    .typeError(INVALID_DATE_VALIDATION_MESSAGE),
  xaxisInventory: boolean().nullable(),
  xaxisDateSigned: date().nullable().typeError(INVALID_DATE_VALIDATION_MESSAGE),
  otherInventory: boolean().nullable(),
  otherDateSigned: date().nullable().typeError(INVALID_DATE_VALIDATION_MESSAGE),
  xaxisContractEndDate: date()
    .nullable()
    .typeError(INVALID_DATE_VALIDATION_MESSAGE)
    .min(ref("xaxisDateSigned"), INVALID_END_DATE_BEFORE_VALIDATION_MESSAGE),
  otherContractEndDate: date()
    .nullable()
    .typeError(INVALID_DATE_VALIDATION_MESSAGE)
    .min(ref("otherDateSigned"), INVALID_END_DATE_BEFORE_VALIDATION_MESSAGE),
  xaxisInventoryAvailableId: number().when("xaxisInventory", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  otherInventoryAvailableId: number().when("otherInventory", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  additionalConditionsInContract: boolean().nullable(),
  contractReferenceAndOrText: string()
    .trim()
    .max(1000, MAX_LENGTH_1000_VALIDATION_MESSAGE)
    .nullable(),
});
