import { object, number, boolean } from "yup";

import { nanToNull } from "shared/utils";
import { MANDATORY_FILED_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  poMandatory: number()
    .transform(nanToNull)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .nullable(),
  onlySendInvoicesPo: number()
    .transform(nanToNull)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .nullable(),
  invoicingLayoutId: number()
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .nullable()
    .transform(nanToNull),
  invoicingVatId: number().nullable().transform(nanToNull),
  invoicePerInsertion: boolean().nullable(),
  invoicePerProduct: boolean().nullable(),
  invoicePerMediaType: boolean().nullable(),
  invoicePerPlan: boolean().nullable(),
  invoicePerCampaign: boolean().nullable(),
  invoicePerClient: boolean().nullable(),
  invoicePerReferenceNumber: boolean().nullable(),
  invoicePerInsertions: boolean().nullable(),
  invoicePerCollectiveProduct: boolean().nullable(),
  invoicePerMedia: boolean().nullable(),
  invoicePerSurchargeCode: boolean().nullable(),
});
