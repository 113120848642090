import { styled, Input as MuiInput } from "@mui/material";

const PREFIX = "InputCellStyles";
export const classes = {
  input: `${PREFIX}-input`,
};

export const Input = styled(MuiInput)(() => ({
  [`& .${classes.input}`]: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    paddingTop: 7,
  },
  "&::before": {
    borderBottom: "none",
    borderBottomStyle: "none !important",
  },
}));
