import { styled, TableRow as MuiTableRow } from "@mui/material";
import { sapphire, linkWater, gainsboro } from "../../../shared/assets/colors";

const PREFIX = "DocumentSchemaTableRowStyles";
export const classes = {
  cell: `${PREFIX}-cell`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyLink: `${PREFIX}-typographyLink`,
  typographyLabel: `${PREFIX}-typographyLabel`,
  button: `${PREFIX}-button`,
};

export const TableRow = styled(MuiTableRow)(() => ({
  [`& .${classes.cell}`]: {
    border: `1px solid ${gainsboro}`,
  },
  [`& .${classes.typographyValue}`]: {
    color: sapphire,
    textDecoration: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 300,
    whiteSpace: "nowrap",
  },
  [`& .${classes.typographyLink}`]: {
    textDecoration: "none",
  },
  [`& .${classes.typographyLabel}`]: {
    color: linkWater,
  },
  [`& .${classes.button}`]: {
    backgroundColor: "#3f51b5",
  },
}));
