import React, { ReactElement } from "react";

import clsx from "clsx";

import groupmIcon from "shared/assets/images/groupm.png";

import { NavMenu } from "./components/Menu/index";

import { classes, Drawer } from "./styles";

export interface SideBarProps {
  open?: boolean;
  onLogoClick?: () => void;
}

export const SideBar = ({
  open = false,
  onLogoClick,
}: SideBarProps): ReactElement => {
  const drawerClassName = clsx(classes.drawer, {
    [classes.drawerOpen]: open,
  });

  const drawerClasses = {
    paper: drawerClassName,
  };

  return (
    <Drawer
      variant="permanent"
      className={drawerClassName}
      classes={drawerClasses}
    >
      <div className={classes.toolbar}>
        <img
          src={groupmIcon}
          className={classes.logo}
          alt="logo"
          onClick={onLogoClick}
          role="presentation"
        />
      </div>
      <NavMenu />
    </Drawer>
  );
};

export default SideBar;
