import {
  APP_MASTERDATA,
  PAGE_OVERVIEW_ACTION,
  PAGE_DASHBOARD_CLIENT,
} from "master-data/constants";
import { INIT_QUERY } from "shared/constants";
import { ClientDocumentStatus } from "master-data/enums";

import { IStatusResponse } from "../StatusDialog/business/types";

export const validateableStatuses = [
  ClientDocumentStatus.WorkInProgress,
  ClientDocumentStatus.Changed,
  ClientDocumentStatus.FinalizedChanged,
];

export const approvalStatuses = [
  ClientDocumentStatus.MdtChecked,
  ClientDocumentStatus.PendingFinalizedChangeApprover,
];

export const deletableStatuses = [
  ClientDocumentStatus.WorkInProgress,
  ClientDocumentStatus.ReadyForReview,
  ClientDocumentStatus.Reviewed,
  ClientDocumentStatus.ToBeChanged,
  ClientDocumentStatus.Changed,
  ClientDocumentStatus.MdtInProgress,
  ClientDocumentStatus.MdtChecked,
  ClientDocumentStatus.Decline,
];

export const getRedirectUrlByStatusId = (
  statusId: number,
  agency: string,
  documentId: string
): string => {
  let url: NullableString;
  switch (statusId) {
    case ClientDocumentStatus.WorkInProgress:
    case ClientDocumentStatus.MdtInProgress:
    case ClientDocumentStatus.ToBeChanged:
    case ClientDocumentStatus.Processing:
      url = `/${APP_MASTERDATA}/${agency}/${PAGE_DASHBOARD_CLIENT}/${documentId}`;
      break;
    default:
      url = `/${APP_MASTERDATA}/${agency}/${PAGE_OVERVIEW_ACTION}${INIT_QUERY}`;
      break;
  }

  return url;
};

export function isInstanceOfIStatusResponse(
  object: any
): object is IStatusResponse {
  return "isDocumentEditable" in object;
}
