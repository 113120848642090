// #region Fields
export const EMPTY_STRING = "";
export const PLACEHOLDER = "-";
export const PIPESYMBOL = "|";
export const MANDATORY_LABEL = "*";
export const REVISION_INIT_VALUE = "N/A";
export const MAX_NUMBER_FOR_INPUTS = 1000000000;
export const MIN_NUMBER_FOR_INPUTS = -1000000000;
export const DEFAULT_DATE_FORMAT = "dd-MM-yyyy";
export const yesNoOptions: OptionType[] = [
  { key: 0, value: "No" },
  { key: 1, value: "Yes" },
];
// #endregion Fields

// #region KeyboaradKeys and HTML Tags
export const KEY_ENTER = "Enter";
export const TAG_TEXTAREA = "TEXTAREA";
// #endregion Fields

// #region URL
export const PAGE_HOME = "/";
export const PAGE_ERROR = "error";
export const PAGE_PROFILE = "user-profile";
export const SETTINGS = "settings";
export const INIT_QUERY = "?page=1&pageSize=10";
// #endregion URL

// #region Validation Messages
export const MAX_LENGTH_1000_VALIDATION_MESSAGE =
  "Maximum number of characters is 1000";
export const MAX_LENGTH_255_VALIDATION_MESSAGE =
  "Maximum number of characters is 255";
export const MAX_LENGTH_100_VALIDATION_MESSAGE =
  "Maximum number of characters is 100";
export const MAX_LENGTH_50_VALIDATION_MESSAGE =
  "Maximum number of characters is 50";
export const MAX_LENGTH_10_VALIDATION_MESSAGE =
  "Maximum number of characters is 10";
export const MANDATORY_FILED_VALIDATION_MESSAGE = "This field is required";
export const EMAIL_FORM_VALIDATION_MESSAGE = "Not valid email format";
export const EMAIL_EXISTS_VALIDATION_MESSAGE = "Email is already added";
export const EMAIL_FORMAT_MISSING_VALIDATION_MESSAGE =
  "Type again email adress and press Enter..";
export const NUMBER_FIELD_VALIDATION_MESSAGE = "Amount must be a number";
export const NUMBER_FIELD_WITH_TWO_DECIMALS_VALIDATION_MESSAGE =
  "Field must be number with max of two decimals";
export const INVALID_DATE_VALIDATION_MESSAGE = "Invalid date format";
export const INVALID_START_DATE_AFTER_VALIDATION_MESSAGE =
  "Start date can not be after End date";
export const INVALID_END_DATE_BEFORE_VALIDATION_MESSAGE =
  "End date can not be before Start date";
export const MAX_NUMBER_VALIDATION_MESSAGE = "Maximum number exceeded";
export const MIN_NUMBER_VALIDATION_MESSAGE = "Minimum number exceeded";
export const MAX_NUMBER_OF_5_ELEMENTS_VALIDATION_MESSAGE =
  "You can enter maximum of 5 emails";
export const ALL_EMAIL_ENTRIES_VALIDATION_MESSAGE =
  "All entries must be in the valid email format";
export const MANDATORY_ENTRY_VALIDATION_MESSAGE =
  "* This table needs to have at least one entry";
export const USER_PROFILE_DIALOG_TITLE = "User Settings dialog";
export const USER_PROFILE_DIALOG_CONTENT =
  "Are you sure you want to change settings?";
// #endregion Validation Messages

// #region application
export const drawerOpenWidth = 240;
export const FOUR_HOURS = 4 * 60 * 60 * 1000;
export const ALLOWED_EXTENSIONS_LIST = [
  "bmp",
  "gif",
  "jpeg",
  "png",
  "csv",
  "txt",
  "docx",
  "doc",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "pdf",
  "msg",
];
export const NOTIFICATION_MESSAGE_SUCCESS = "Successfully saved.";
export const NOTIFICATION_MESSAGE_DELETION_SUCCESS = "Successfully deleted.";
export const NOTIFICATION_MESSAGE_ARCHIVAL_SUCCESS = "Successfully archived.";
export const NOTIFICATION_MESSAGE_UNARCHIVAL_SUCCESS =
  "Successfully unarchived.";
export const UNSAVED_DATA_MESSAGE =
  "There are unsaved changes. Are you sure you want to discard them?";
export const yesNoLabel = (value: boolean): "Yes" | "No" =>
  value ? "Yes" : "No";
export const EMPTY_TABLE = "No items to display";
export const OK_LABEL = "OK";
export const CANCEL_LABEL = "CANCEL";
export const CREATE_LABEL = "CREATE";
export const RETRY_LABEL = "RETRY";
export const CREATE_DEFAULT_PRODUCT_DIALOG_TITLE =
  "Create Marathon Client and Default Product";
export const CREATE_DEFAULT_PRODUCT_DIALOG_STEP_ONE =
  "In this step, you need to create Marathon Client ID. For this action, please click the Create button.";
export const CREATE_DEFAULT_PRODUCT_DIALOG_STEP_TWO =
  "In this step, you need to create Default Product. For this action, please click the Create button.";
export const CREATE_DEFAULT_PRODUCT_DIALOG_STEP_THREE =
  "You have completed all steps successfully. For confirmation, please click the OK button.";
export const CREATE_DEFAULT_PRODUCT_DIALOG_WARNING =
  "*You are not allowed to create Marathon Client nor Default Product. Please try again later.";
export const CREATE_MARATHON_CLIENT_DIALOG_TITLE = "Create Marathon Client";
export const CREATE_MARATHON_CLIENT_DIALOG_DESCRIPTION =
  "Use the button below to send an API request, to create the client in Marathon. Marathon will generate the ClientID, which will be stored in Madam. This action will just create the client name and ID in Marathon. Detailed information for this client needs to be filled in Marathon.";
export const CREATE_MARATHON_CLIENT_DIALOG_SUCCESSFUL =
  "Marathon Client Id created successfully: ";
export const CREATE_PRODUCT_DIALOG_WARNING =
  "*You are not allowed to create Marathon Product.";
export const DEFAULT = "Default";
export const ARCHIVED = "ARCHIVED";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
// #endregion application

// #region API error messages
export const ERROR_MSG_INVALID_DATA =
  "Data was not sent properly, please check input data and try again";
export const ERROR_MSG_NOT_AUTHORIZED =
  "You are not authorized to see this content";
export const ERROR_MSG_NOT_FOUND = "Resource not found";
export const ERROR_MSG_500 =
  "Ooops! Something went wrong, please contact support.amsterdam@groupm.com";
export const ERROR_MSG_SERVICE = "Service is not available";
// #endregion
