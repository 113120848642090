import React, { useEffect, useState, forwardRef } from "react";

import { Link, useLocation, LinkProps } from "react-router-dom";

import { getNthIndex } from "shared/utils";

export interface AccordionLinkProps extends LinkProps {
  to: { pathname: string; search: string } | string; // TODO: use existing to prop from LinkProps
}

export const AccordionLink = forwardRef(
  ({ to, ...linkProps }: AccordionLinkProps, ref: any) => {
    const linkURL = typeof to === "string" ? to : to.pathname;
    const [linkTo, setLinkTo] = useState(linkURL);
    const { pathname, search } = useLocation();
    const linkQuery = typeof to === "string" ? undefined : to.search;

    useEffect(() => {
      if (pathname.includes(linkURL)) {
        setLinkTo(
          linkURL
            .substr(
              0,
              getNthIndex(linkURL, "/", (linkURL.match(/\//g) || []).length)
            )
            .concat(linkQuery ?? search)
        );
      } else {
        setLinkTo(linkURL.concat(linkQuery ?? search));
      }
    }, [pathname, to, search, linkQuery, linkURL]);

    return <Link innerRef={ref} to={linkTo} {...linkProps} />;
  }
);

export default AccordionLink;
