import { GlobalState } from "little-state-machine";

import { defaultAppState, defaultGlobalStore } from "../constants";
import { AppState } from "../types";

export function resetGlobalState(state: GlobalState): GlobalState {
  return {
    ...state,
    ...defaultGlobalStore,
  };
}

export function pushToRequestQueue(
  state: GlobalState,
  request: string
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      requestQueue: [...state.appState.requestQueue, request],
    },
  };
}

export function popFromRequestQueue(
  state: GlobalState,
  request: string
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      requestQueue: state.appState.requestQueue.filter(
        (i: string) => i !== request
      ),
    },
  };
}

export function setAppState(
  state: GlobalState,
  payload: AppState
): GlobalState {
  return {
    ...state,
    appState: payload,
  };
}

export function resetAppState(state: GlobalState): GlobalState {
  return {
    ...state,
    appState: defaultAppState,
  };
}

export function setShowAgencySelectionPage(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      showAgencySelectionPage: payload,
    },
  };
}

export function setShowUserManegementPage(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      showUserManegementPage: payload,
    },
  };
}

export function setShowImportUsersPage(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      showImportUsersPage: payload,
    },
  };
}

export function setShowDebtorGroupsPage(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      showDebtorGroupsPage: payload,
    },
  };
}

export function setCanViewRevision(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      canViewRevision: payload,
    },
  };
}

export function setIsSelectedAMUsers(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isSelectedAMUsers: payload,
    },
  };
}

export function setIsOpenImportSelectedDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenImportSelectedDialog: payload,
    },
  };
}
export function setOpenImportSelectedMissingDebtorsDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenImportSelectedMissingDebtorsDialog: payload,
    },
  };
}

export function setIsOpenRemoveSelectedDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenRemoveSelectedDialog: payload,
    },
  };
}

export function setIsOpenFetchUsersDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenFetchUsersDialog: payload,
    },
  };
}

export function setIsOpenAddSelectedUsersDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenAddSelectedUsersDialog: payload,
    },
  };
}

export function setIsOpenRemoveSelectedUsersDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenRemoveSelectedUsersDialog: payload,
    },
  };
}

export function setIsOpenAddSelectedDebtorsDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenAddSelectedDebtorsDialog: payload,
    },
  };
}

export function setIsOpenRemoveSelectedDebtorsDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenRemoveSelectedDebtorsDialog: payload,
    },
  };
}

export function setIsSelectedDebtors(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isSelectedDebtors: payload,
    },
  };
}

export function setIsSelectedMissingDebtors(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isSelectedMissingDebtors: payload,
    },
  };
}

export function setIsOpenAddSelectedMappingsDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenAddSelectedMappingsDialog: payload,
    },
  };
}

export function setIsOpenClearSelectedMappingsDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenClearSelectedMappingsDialog: payload,
    },
  };
}

export function setIsSelectedDebtorGroups(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isSelectedDebtorGroups: payload,
    },
  };
}

export function setIsSelectedUserGroups(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isSelectedUserGroups: payload,
    },
  };
}

export function setIsSelectedApplications(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isSelectedApplications: payload,
    },
  };
}

export function setIsOpenDeleteMappingDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenDeleteMappingDialog: payload,
    },
  };
}

export function setIsOpenUpdateDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenUpdateDialog: payload,
    },
  };
}

export function setIsOpenUserProfileDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenUserProfileDialog: payload,
    },
  };
}

export function setCanSaveUserProfileChanges(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      canSaveUserProfileChanges: payload,
    },
  };
}

export function setIsOpenDeleteClientDocumentDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    appState: {
      ...state.appState,
      isOpenDeleteClientDocumentDialog: payload,
    },
  };
}
