import { Backdrop as MuiBackdrop, styled } from "@mui/material";

const PREFIX = "UploaderStyles";
export const classes = {
  input: `${PREFIX}-input`,
  circularProgress: `${PREFIX}-circularProgress`,
  wrapper: `${PREFIX}-wrapper`,
  title: `${PREFIX}-title`,
  backdrop: `${PREFIX}-backdrop`,
};

export const Div = styled("div")(() => ({
  [`&.${classes.wrapper}`]: {
    position: "relative",
  },
  [`&.${classes.circularProgress}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  [`&.${classes.title}`]: {
    fontSize: 20,
    marginTop: 15,
  },
}));

export const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
  [`&.${classes.backdrop}`]: {
    color: "#fff",
    flexDirection: "column",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export const Input = styled("input")(() => ({
  [`&.${classes.input}`]: {
    display: "none",
  },
}));
