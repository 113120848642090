import React, { useState, useEffect, ReactElement } from "react";

import { useParams } from "react-router-dom";
import {
  Grid,
  Checkbox,
  TableCell,
  Typography,
  FormControlLabel,
} from "@mui/material";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { FileChip } from "master-data/components";
import { ConfirmationPopper, Uploader } from "core";

import {
  EDIT_MDT_CHECK,
  DELETE_MDT_CHECK_ATTACHMENT,
  UPLOAD_MDT_CHECK_ATTACHMENT,
  DOWNLOAD_MDT_CHECK_ATTACHMENT,
} from "master-data/constants";
import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";

import { CheckType } from "../../types";

import { classes, TableRow } from "./styles";

const { PUT } = FetchMethodEnum;

export interface TableCheckItemProps extends Omit<CheckType, "isChecked"> {
  isEditable: boolean;
  isInitChecked: boolean;
  refetch: () => void;
}

export const TableCheckItem = ({
  checkId,
  name,
  isInitChecked,
  isDisabled,
  isEditable,
  attachments,
  refetch,
}: TableCheckItemProps): ReactElement => {
  const [isChecked, toggleCheck] = useState<boolean>(isInitChecked);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const { agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];

  const { executeFetch, isSuccessful } = useFetch<null, null>(null, {
    skip: true,
  });

  // #region Methods
  const handleClickAway = () => {
    setConfirmationOpen(false);
  };

  const executeMicroCommand = async () => {
    const url = generateUrl(EDIT_MDT_CHECK, {
      agencyId,
      documentId,
      checkId,
    });

    await executeFetch(url, {
      method: PUT,
      body: { isChecked: !isChecked },
      skip: false,
    });
    handleClickAway();
  };

  const handleCheck = async () => {
    if (!isChecked) {
      await executeMicroCommand();
    } else {
      setConfirmationOpen(true);
    }
  };

  const uploadFileCallback = () => {
    refetch();
  };
  // #endregion

  useEffect(() => {
    if (isSuccessful) {
      toggleCheck((prevCheck) => !prevCheck);
    }
  }, [isSuccessful]);

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <FormControlLabel
                id={`check-${checkId}`}
                control={
                  <Checkbox
                    ref={anchorRef}
                    checked={isChecked}
                    onChange={handleCheck}
                    name={name}
                    disabled={!isEditable || isDisabled}
                    color="primary"
                  />
                }
                label={
                  <Typography className={classes.checkLabel}>{name}</Typography>
                }
              />
            </Grid>
            <Grid item xs={8}>
              {attachments.map((file) => (
                <FileChip
                  key={file.fileId}
                  {...file}
                  checkId={checkId}
                  isEditable={isEditable}
                  onDelete={refetch}
                  downloadUrl={generateUrl(DOWNLOAD_MDT_CHECK_ATTACHMENT, {
                    agencyId,
                    documentId,
                    checkId,
                    fileId: file.fileId,
                  })}
                  deleteUrl={generateUrl(DELETE_MDT_CHECK_ATTACHMENT, {
                    agencyId,
                    documentId,
                    checkId,
                    fileId: file.fileId,
                  })}
                />
              ))}
            </Grid>
            <Grid item xs={1}>
              {isEditable && (
                <Uploader
                  uploadUrl={generateUrl(UPLOAD_MDT_CHECK_ATTACHMENT, {
                    documentId,
                    agencyId: AgencyEnum[agencyName],
                    checkId,
                  })}
                  onUploadCallback={uploadFileCallback}
                  rowId={checkId}
                />
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <ConfirmationPopper
        open={confirmationOpen}
        anchorEl={anchorRef.current}
        message={`Are you sure you want to uncheck ${name}?`}
        primaryButtonLabel="Yes"
        secondaryButtonLabel="Cancel"
        onClickAway={handleClickAway}
        onConfirm={executeMicroCommand}
      />
    </>
  );
};

export default TableCheckItem;
