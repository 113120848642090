import React, { ReactElement, useEffect } from "react";

import { useSnackbar } from "notistack";
import { Controller } from "react-hook-form";
import { useStateMachine } from "little-state-machine";

import {
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Tooltip,
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams,
} from "@mui/material";
import { History as HistoryIcon } from "@mui/icons-material";

import { TextField, InfoPopper, Autocomplete } from "core";

import {
  EMPTY_STRING,
  EMAIL_EXISTS_VALIDATION_MESSAGE,
  EMAIL_FORMAT_MISSING_VALIDATION_MESSAGE,
} from "shared/constants";

import { getFieldErrorMessage, getFieldHasError } from "shared/utils";
import { FillFormOptionType } from "master-data/enums";
import { IFormInput } from "./types";
import { FormStandardFieldProps } from "../props";

export interface FormInvoicingAddressMethodProps
  extends FormStandardFieldProps {
  /**
   * formData
   */
  formData: IFormInput; // TODO: test useEffect if it's ok delete this prop
  /**
   * setValue
   */
  setValue: any;
  /**
   * True if form is not created
   */
  isNew: boolean;
  /**
   * getValues
   */
  getValues: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormInvoicingAddressMethod = ({
  isEdit,
  isNew,
  control,
  errors,
  setValue,
  getValues,
  revisionValues,
  fillOption,
}: FormInvoicingAddressMethodProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const {
    state: { cacheData, clientDocumentData, generalClientInfoData },
  } = useStateMachine();

  const { documentName } = clientDocumentData;

  const {
    enumTempDistributionMethod,
    enumLayoutLanguage,
    enumInvoicingMethod,
    enumInvoicingDays,
  } = cacheData;

  // #region Effects
  useEffect(() => {
    if (isNew && isEdit) {
      setValue("invoiceAddressClient", documentName);
      setValue("mailingAddress", generalClientInfoData.mailingAddress);
      setValue("city", generalClientInfoData.mailingCity);
      setValue("country", generalClientInfoData.mailingCountry);
      setValue("postalCode", generalClientInfoData.mailingPostalCode);
      setValue("attn", "Crediteuren Administratie");
    }
  }, [isEdit, isNew, setValue, documentName, generalClientInfoData]);

  useEffect(() => {
    if (fillOption) {
      setValue("invoiceAddressClient", documentName);
      setValue("attn", "Crediteuren Administratie");
      setValue(
        "mailingAddress",
        generalClientInfoData.mailingAddress === EMPTY_STRING
          ? "email@test.com"
          : generalClientInfoData.mailingAddress
      );
      setValue("emailAccountancy", "accountancy@test.com");
      setValue(
        "postalCode",
        generalClientInfoData.mailingPostalCode === EMPTY_STRING
          ? "1100"
          : generalClientInfoData.mailingPostalCode
      );
      setValue("phoneNumberAccountancy", "011 23233322");
      setValue(
        "city",
        generalClientInfoData.mailingCity === EMPTY_STRING
          ? "Belgrade"
          : generalClientInfoData.mailingCity
      );
      setValue(
        "country",
        generalClientInfoData.mailingCountry === EMPTY_STRING
          ? "Serbia"
          : generalClientInfoData.mailingCountry
      );
      setValue("invoiceMailingAddress", ["invoice@test.com"]);
      if (enumLayoutLanguage[0]) {
        setValue("layoutLanguageId", enumLayoutLanguage[0].key);
      }
      if (enumInvoicingMethod[0]) {
        setValue("invoicingMethodId", enumInvoicingMethod[0].key);
      }
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue(
          "thirdPartyInvoicingPortal",
          "Third Party Invoicing Portal - Test"
        );
        if (enumInvoicingDays[0]) {
          setValue("invoicingDaysId", enumInvoicingDays[0].key);
        }
        if (enumTempDistributionMethod[0]) {
          setValue(
            "tempDistributionMethodId",
            enumTempDistributionMethod[0].key
          );
        }
      }
    }
  }, [
    fillOption,
    setValue,
    documentName,
    generalClientInfoData,
    enumLayoutLanguage,
    enumInvoicingMethod,
    enumInvoicingDays,
    enumTempDistributionMethod,
  ]);
  // #endregion Effects

  // #region Methods
  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  // #endregion Methods

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextField
          name="invoiceAddressClient"
          id="invoiceAddressClient"
          label="Invoice Address to Client *"
          error={!!errors.invoiceAddressClient?.message}
          helperText={errors.invoiceAddressClient?.message}
          revisionValue={revisionValues?.InvoiceAddressClient}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="attn"
          id="attn"
          label="Attn. *"
          error={!!errors.attn?.message}
          helperText={errors.attn?.message}
          revisionValue={revisionValues?.Attn}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="mailingAddress"
          id="mailingAddress"
          label="Mailing Address *"
          error={!!errors.mailingAddress?.message}
          helperText={errors.mailingAddress?.message}
          revisionValue={revisionValues?.MailingAddress}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="emailAccountancy"
          id="emailAccountancy"
          label="E-mail Accountancy *"
          error={!!errors.emailAccountancy?.message}
          helperText={errors.emailAccountancy?.message}
          revisionValue={revisionValues?.EmailAccountancy}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="postalCode"
          id="postalCode"
          label="Postal Code *"
          error={!!errors.postalCode?.message}
          helperText={errors.postalCode?.message}
          revisionValue={revisionValues?.PostalCode}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="phoneNumberAccountancy"
          id="phoneNumberAccountancy"
          label="Phone Number Accountancy *"
          error={!!errors.phoneNumberAccountancy?.message}
          helperText={errors.phoneNumberAccountancy?.message}
          revisionValue={revisionValues?.PhoneNumberAccountancy}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="city"
          name="city"
          label="City *"
          error={!!errors.city?.message}
          helperText={errors.city?.message}
          revisionValue={revisionValues?.City}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="layoutLanguageId"
          name="layoutLanguageId"
          label="Layout Language *"
          options={enumLayoutLanguage}
          error={!!errors.layoutLanguageId?.message}
          helperText={errors.layoutLanguageId?.message}
          revisionValue={revisionValues?.LayoutLanguageId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="country"
          id="country"
          label="Country *"
          error={!!errors.country?.message}
          helperText={errors.country?.message}
          revisionValue={revisionValues?.Country}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="invoicingMethodId"
          name="invoicingMethodId"
          label="Invoicing Method *"
          options={enumInvoicingMethod}
          error={!!errors.invoicingMethodId?.message}
          helperText={errors.invoicingMethodId?.message}
          revisionValue={revisionValues?.InvoicingMethodId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          render={({ field }) => (
            <Tooltip
              title={
                !isEdit && getValues("invoiceMailingAddress")
                  ? getValues("invoiceMailingAddress").join(", ")
                  : EMPTY_STRING
              }
              placement="bottom"
            >
              <MuiAutocomplete
                {...field}
                autoHighlight
                freeSolo
                multiple
                id="invoiceMailingAddress"
                filterSelectedOptions
                options={[]}
                ChipProps={{ color: "primary" }}
                clearOnBlur
                renderInput={(params: AutocompleteRenderInputParams) => {
                  const revisionValue =
                    revisionValues?.InvoiceMailingAddress?.replace(
                      /(^.*\[|\].*$)/g,
                      EMPTY_STRING
                    ).replace(/['"]+/g, EMPTY_STRING);
                  return (
                    <>
                      <MuiTextField
                        {...params}
                        name="invoiceMailingAddress"
                        label="E-mail Address Invoice (Max.5) * (Type the email address and press enter...)"
                        variant="outlined"
                        error={getFieldHasError(errors.invoiceMailingAddress)}
                        helperText={getFieldErrorMessage(
                          errors.invoiceMailingAddress
                        )}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: revisionValue ? (
                            <>
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle change value visibility"
                                  onClick={handleClick}
                                  ref={anchorRef}
                                >
                                  <HistoryIcon color="primary" />
                                </IconButton>
                              </InputAdornment>
                              {params.InputProps.endAdornment}
                            </>
                          ) : (
                            params.InputProps.endAdornment
                          ),
                        }}
                      />
                      {revisionValue && (
                        <InfoPopper
                          text={revisionValue}
                          onClickAway={handleClickAway}
                          open={open}
                          anchorEl={anchorRef.current}
                        />
                      )}
                    </>
                  );
                }}
                onChange={(_, data, reason, detail) => {
                  if (
                    reason === "createOption" &&
                    field.value.includes(detail?.option.trim())
                  ) {
                    enqueueSnackbar(EMAIL_EXISTS_VALIDATION_MESSAGE, {
                      variant: "warning",
                    });
                  } else {
                    const newData = data.map((x) => x.trim());
                    field.onChange(newData);
                  }
                }}
                onClose={(event) => {
                  if (!event.defaultPrevented) {
                    enqueueSnackbar(EMAIL_FORMAT_MISSING_VALIDATION_MESSAGE, {
                      variant: "warning",
                    });
                  }
                }}
                disabled={!isEdit}
              />
            </Tooltip>
          )}
          name="invoiceMailingAddress"
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="thirdPartyInvoicingPortal"
          id="thirdPartyInvoicingPortal"
          label="Third Party Invoicing Portal"
          error={!!errors.thirdPartyInvoicingPortal?.message}
          helperText={errors.thirdPartyInvoicingPortal?.message}
          revisionValue={revisionValues?.ThirdPartyInvoicingPortal}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="invoicingDaysId"
          name="invoicingDaysId"
          label="Invoicing Days"
          options={enumInvoicingDays}
          error={!!errors.invoicingDaysId?.message}
          helperText={errors.invoicingDaysId?.message}
          revisionValue={revisionValues?.InvoicingDaysId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="tempDistributionMethodId"
          name="tempDistributionMethodId"
          label="Temporary Distribution Method"
          options={enumTempDistributionMethod}
          error={!!errors.tempDistributionMethodId?.message}
          helperText={errors.tempDistributionMethodId?.message}
          revisionValue={revisionValues?.TempDistributionMethodId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormInvoicingAddressMethod;
