import { object, string, number, array } from "yup";
import {
  EMAIL_FORM_VALIDATION_MESSAGE,
  MAX_LENGTH_50_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  ALL_EMAIL_ENTRIES_VALIDATION_MESSAGE,
  MAX_NUMBER_OF_5_ELEMENTS_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  invoiceAddressClient: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  mailingAddress: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  postalCode: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  city: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  country: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  invoicingMethodId: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  invoiceMailingAddress: array()
    .of(
      string()
        .trim()
        .email(ALL_EMAIL_ENTRIES_VALIDATION_MESSAGE)
        .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    )
    .max(5, MAX_NUMBER_OF_5_ELEMENTS_VALIDATION_MESSAGE)
    .min(1, MANDATORY_FILED_VALIDATION_MESSAGE),
  attn: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  emailAccountancy: string()
    .trim()
    .email(EMAIL_FORM_VALIDATION_MESSAGE)
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  phoneNumberAccountancy: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  layoutLanguageId: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  thirdPartyInvoicingPortal: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .notRequired()
    .when("invoicingMethodId", {
      is: 3, // TODO: enum
      then: string().required(MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: string().notRequired(),
    }),
  tempDistributionMethodId: number()
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .notRequired()
    .when("invoicingMethodId", {
      is: 3, // TODO: enum
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().notRequired().transform(nanToNull),
    }),
  invoicingDaysId: number().nullable().transform(nanToNull).notRequired(),
});
