import React, { ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { useHistory, Link } from "react-router-dom";
import { Typography, Tooltip, IconButton } from "@mui/material";

import { AgencyIcons, productStatusIcons } from "product/components/icons";

import { PLACEHOLDER } from "shared/constants";
import { classes, Grid } from "./styles";

export const ProductHeader = (): ReactElement => {
  const {
    state: { productStatusData, productData },
  } = useStateMachine();

  const {
    location: { pathname },
  } = useHistory();

  const {
    productName,
    documentName,
    agencyId,
    marathonClientId,
    marathonProductId,
  } = productData;
  const { statusName } = productStatusData;

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        <Tooltip
          title={productName ?? PLACEHOLDER}
          placement="bottom-start"
          enterDelay={500}
        >
          <Typography className={classes.typographyValue}>
            {productName ?? PLACEHOLDER}
          </Typography>
        </Tooltip>
        <Typography className={classes.typographyLabel}>
          Product Name
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={documentName ?? PLACEHOLDER}
          placement="bottom-start"
          enterDelay={500}
        >
          <Typography className={classes.typographyValue}>
            {documentName ?? PLACEHOLDER}
          </Typography>
        </Tooltip>
        <Typography className={classes.typographyLabel}>Client</Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={marathonClientId || PLACEHOLDER}
          placement="bottom-start"
          enterDelay={500}
        >
          <Typography className={classes.typographyValue}>
            {marathonClientId || PLACEHOLDER}
          </Typography>
        </Tooltip>
        <Typography className={classes.typographyLabel}>Client ID</Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={marathonProductId || PLACEHOLDER}
          placement="bottom-start"
          enterDelay={500}
        >
          <Typography className={classes.typographyValue}>
            {marathonProductId || PLACEHOLDER}
          </Typography>
        </Tooltip>
        <Typography className={classes.typographyLabel}>
          Marathon Product ID
        </Typography>
      </Grid>
      <Grid item>
        {agencyId && (
          <Grid container spacing={1} alignItems="center">
            <Grid item>{AgencyIcons({ agency: agencyId }).image}</Grid>
          </Grid>
        )}
        <Typography className={classes.typographyLabel}>Agency</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          {statusName && (
            <Grid item>
              <IconButton
                edge="start"
                size="small"
                component={Link}
                disabled
                to={`${pathname}/status`}
                className={classes.sizeIcon}
              >
                {productStatusIcons[statusName]}
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Tooltip
              title={statusName ?? PLACEHOLDER}
              placement="bottom-start"
              enterDelay={500}
            >
              <Typography className={classes.typographyValue}>
                {statusName ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <Typography className={classes.typographyLabel}>
          Product Status
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProductHeader;
