import React, { ReactElement, ChangeEvent } from "react";

import { Button, CircularProgress } from "@mui/material";

import { ALLOWED_EXTENSIONS_LIST, EMPTY_STRING } from "shared/constants";
import useFileUploader from "shared/hooks/useFileUploader";
import { Backdrop, classes, Div, Input } from "./styles";

export interface UploaderProps {
  /**
   * if true, button is disabled
   */
  disabled?: boolean;
  /**
   * function that will be called after successful upload of file
   */
  onUploadCallback?: () => void;
  /**
   * URL where to upload a file
   */
  uploadUrl: string;
  /**
   * When component is in table row, needed for html input id
   */
  rowId?: number;
}

export const Uploader = ({
  disabled = false,
  uploadUrl,
  rowId,
  onUploadCallback,
}: UploaderProps): ReactElement => {
  const { uploadFile, isLoading } = useFileUploader();
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const onFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      const fileName = file.name.substring(0, file.name.lastIndexOf("."));
      const blob = file.slice(0, file.size, file.type);
      const updatedExtensionFile = new File(
        [blob],
        `${fileName}.${fileExtension}`,
        { type: file.type }
      );
      uploadFile(updatedExtensionFile, uploadUrl, onUploadCallback).then(
        (isSuccessful) => {
          if (!isSuccessful && inputFileRef.current) {
            inputFileRef.current.value = EMPTY_STRING;
          }
        }
      );
    }
  };

  return (
    <>
      <label htmlFor={`upload-file-${rowId ?? EMPTY_STRING}`}>
        <Input
          className={classes.input}
          id={`upload-file-${rowId ?? EMPTY_STRING}`}
          name={`upload-file-${rowId ?? EMPTY_STRING}`}
          type="file"
          disabled={disabled}
          onChange={onFileUpload}
          accept={ALLOWED_EXTENSIONS_LIST.map((ext) => `.${ext}`).join(",")}
          ref={inputFileRef}
        />
        <Div className={classes.wrapper}>
          <Button
            disabled={disabled || isLoading}
            color="primary"
            variant="contained"
            component="span"
          >
            Upload
          </Button>
        </Div>
      </label>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress size={100} className={classes.circularProgress} />
        <Div className={classes.title}>Uploading file...</Div>
      </Backdrop>
    </>
  );
};

export default Uploader;
