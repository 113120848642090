import { styled, Grid as MuiGrid } from "@mui/material";
import { darkDeYork, darkSupernova } from "shared/assets/colors";

const PREFIX = "ImportUsersActionsStyles";
export const classes = {
  importButton: `${PREFIX}-importButton`,
  removeButton: `${PREFIX}-removeButton`,
  disabledButton: `${PREFIX}-disabledButton`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.importButton}`]: {
    backgroundColor: darkDeYork,
  },
  [`& .${classes.removeButton}`]: {
    backgroundColor: darkSupernova,
  },
  [`& .${classes.disabledButton}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
}));
