import {
  AccordionEnum,
  MAP_ACCORDION,
  MAP_SECTION,
  SectionEnum,
} from "master-data/enums";

export const REVISION_VIEW_TITLE =
  "Revision View: In this view you can only see sections/accordions that have changes";
export const COMPLETE_VIEW_TITLE =
  "Complete View: In this view you can see entire list of sections/accordions";
export const MDT_VIEW_TITLE = "MDT View";
export const APPROVER_VIEW_TITLE = "Approver View";
export const REVISION_VIEW_MESSAGE =
  "In this view you can only see sections/accordions that have changes";
export const COMPLETE_VIEW_MESSAGE =
  "In this view you can see entire list of sections/accordions";
export const APPROVERS_APPROVE_MESSAGE =
  "Document is already approved with your role. Approved by: ";
export const ADMIN_APPROVE_MESSAGE = "Approved by: ";

const clientDocumentSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientDocument
    )}`,
    value: "Client Document > Client Name",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientDocument
    )}`,
    value: "Client Document > Concern Name",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientDocument
    )}`,
    value: "Client Document > Client Document Type",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientDocument
    )}`,
    value: "Client Document > Client ID",
  },
];

const generalClientInfoSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Business - Address",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Business - Postal Code",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Business - City",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Business - Country",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Mailing - Address",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Mailing - Postal Code",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Mailing - City",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Mailing - Country",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.generalClientInfo
    )}`,
    value: "General Client Info > Internal Client Document Name",
  },
];

const additionalClientInfoSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Intercompany Client",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Client Industry",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Intercompany Code",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Local / Designated Code",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Chamber Of Commerce",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Vat Number",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Bank Relation / Location",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Bank Account Number",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Cost Client - Yearly Budget",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalClientInfo
    )}`,
    value: "Additional Client Info > Highest Cost Client per Month",
  },
];

const creditInsuranceSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.creditInsurance
    )}`,
    value: "Credit Insurance > Credit Insurance",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.creditInsurance
    )}`,
    value: "Credit Insurance > Alternative Credit Insurance",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.creditInsurance
    )}`,
    value: "Credit Insurance > Credit Insurance Number",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.creditInsurance
    )}`,
    value: "Credit Insurance > Credit Insurance Amount",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.creditInsurance
    )}`,
    value: "Credit Insurance > Shared Limit",
  },
];

const clientContactsSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientContacts
    )}`,
    value: "Client Contacts > Contact Person",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientContacts
    )}`,
    value: "Client Contacts > Job Title",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientContacts
    )}`,
    value: "Client Contacts > Email Address",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientContacts
    )}`,
    value: "Client Contacts > Phone Number",
  },
];

const relatedContactsSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.relatedContacts
    )}`,
    value: "Related Contacts > Agency Type",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.relatedContacts
    )}`,
    value: "Related Contacts > Contact Person",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.relatedContacts
    )}`,
    value: "Related Contacts > Job Title",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.relatedContacts
    )}`,
    value: "Related Contacts > Email Address",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.relatedContacts
    )}`,
    value: "Related Contacts > Phone Number",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.relatedContacts
    )}`,
    value: "Related Contacts > Company Name",
  },
];

const clientTeamSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientTeam
    )}`,
    value: "Client Team > Business Unit",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientTeam
    )}`,
    value: "Client Team > Client Lead",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.clientTeam
    )}`,
    value: "Client Team > Process Manager",
  },
];

const supplierAgreementsSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.supplierAgreements
    )}`,
    value: "Supplier Agreements > SupplierID",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.supplierAgreements
    )}`,
    value: "Supplier Agreements > AVB",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.supplierAgreements
    )}`,
    value: "Supplier Agreements > SBI",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.client)}/${MAP_ACCORDION.get(
      AccordionEnum.supplierAgreements
    )}`,
    value: "Supplier Agreements > MSI",
  },
];

const generalContractInfoSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Contract Type",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Red / Green Schedule",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Local Service Agreement",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Contract Term",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Start Date",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > End Date",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Status Contract",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Fiscal Year",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Contract Added",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Notice Period",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Competitive Clients",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Competition Clause",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Duty To Report Financial Interest",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Duty To Report Sub-Contracting",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > IRFS Added",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > GDPR Clause",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.generalContractInfo
    )}`,
    value: "General Contract Info > Additional Contract Info",
  },
];

const financialAvbsSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialAvbs
    )}`,
    value: "Financial - AVB's > AVB's Returned",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialAvbs
    )}`,
    value: "Financial - AVB's > Percentage Passed on",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialAvbs
    )}`,
    value: "Financial - AVB's > Reported (default September consecutive year)",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialAvbs
    )}`,
    value: "Financial - AVB's > Additional Reporting Month",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialAvbs
    )}`,
    value: "Financial - AVB's > Pass on",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialAvbs
    )}`,
    value: "Financial - AVB's > Additional Conditions In Contract",
  },
];

const financialEpdsSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialEpds
    )}`,
    value: "Financial - EPD's > Early Payment Discount",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialEpds
    )}`,
    value: "Financial - EPD's > Percentage Passed on",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialEpds
    )}`,
    value: "Financial - EPD's > Percentage - Paid Within Number of Days",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialEpds
    )}`,
    value: "Financial - EPD's > EPD Received to be Passed on",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialEpds
    )}`,
    value: "Financial - EPD's > EPD Received - Paid Within Number of Days",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialEpds
    )}`,
    value: "Financial - EPD's > Additional Conditions in Contract",
  },
];

const financialUnbilledMediaSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialUnbilledMedia
    )}`,
    value: "Financial - Unbilled Media > Unbilled Media Returned",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialUnbilledMedia
    )}`,
    value: "Financial - Unbilled Media > Percentage Passed on",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialUnbilledMedia
    )}`,
    value: "Financial - Unbilled Media > Including Admanagement / Tech Fee",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialUnbilledMedia
    )}`,
    value: "Financial - Unbilled Media > Additional Conditions in Contract",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialUnbilledMedia
    )}`,
    value: "Financial - Unbilled Media > Unbilled Media To Be Returned After",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialUnbilledMedia
    )}`,
    value: "Financial - Unbilled Media > Unbilled Media To Be Returned Per",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialUnbilledMedia
    )}`,
    value:
      "Financial - Unbilled Media > Other Unbilled Media To Be Returned After",
  },
];

const financialClientIncentiveSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialClientIncentive
    )}`,
    value: "Financial - Client Incentive > Client Incentive",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialClientIncentive
    )}`,
    value: "Financial - Client Incentive > Charge Cancellation Costs",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialClientIncentive
    )}`,
    value: "Financial - Client Incentive > KPI’s Applicable",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialClientIncentive
    )}`,
    value: "Financial - Client Incentive > Additional Conditions in Contract",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialClientIncentive
    )}`,
    value: "Financial - Client Incentive > Which KPI's",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialClientIncentive
    )}`,
    value: "Financial - Client Incentive > Percentage",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.financialClientIncentive
    )}`,
    value: "Financial - Client Incentive > Precondition",
  },
];

const inventoryMediaSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Flex",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Start Date Flex",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Xaxis Inventory",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Xaxis Inventory Available",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Xaxis Date Signed",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Xaxis Contract End Date",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Other Inventory",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Other Inventory Available",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Other Date Signed",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Other Contract End Date",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Additional Conditions In Contract",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.inventoryMedia
    )}`,
    value: "Inventory Media > Contract Reference and/or Text",
  },
];

const allowableDeducationsRebatesSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.allowableDeducationsRebates
    )}`,
    value: "Allowable Deductions Rebates > Rebates - Late Payment Interest",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.allowableDeducationsRebates
    )}`,
    value:
      "Allowable Deductions Rebates > Rebates - Client Pays On Time Forfeits",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.allowableDeducationsRebates
    )}`,
    value: "Allowable Deductions Rebates > Rebates - Admin / Lifting Costs",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.allowableDeducationsRebates
    )}`,
    value: "Allowable Deductions Rebates > Rebates - Against Oldest Invoices",
  },
];

const auditSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.audit
    )}`,
    value: "Audit > Right To Financial Audit",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.audit
    )}`,
    value: "Audit > Accountants",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.audit
    )}`,
    value: "Audit > Right To Media Audit",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.audit
    )}`,
    value: "Audit > Audit Period",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.audit
    )}`,
    value: "Audit > Other Audit Period",
  },
];

const interestSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.interest
    )}`,
    value: "Interest > Calculate Interest (Based on legal interest)",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.interest
    )}`,
    value: "Interest > Fixed Deviating",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.interest
    )}`,
    value: "Interest > Bank Name",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.interest
    )}`,
    value: "Interest > Other Bank",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.interest
    )}`,
    value: "Interest > Surcharge Bank Percentage",
  },
];

const admanagementSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.admanagement
    )}`,
    value: "Ad Management > Ad Management Tool(s)",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.admanagement
    )}`,
    value: "Ad Management > Ratecard",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.admanagement
    )}`,
    value: "Ad Management > Contract Reference and/or Text",
  },
];

const digitalAccountabilityBrandSafetySearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.digitalAccountabilityBrandSafety
    )}`,
    value: "Digital Accountability & Brand Safety > Terms in Contract",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.digitalAccountabilityBrandSafety
    )}`,
    value:
      "Digital Accountability & Brand Safety > Contract Reference and/or Text",
  },
];

const evouchingSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.evouching
    )}`,
    value: "E-Vouching > Terms in Contract",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.evouching
    )}`,
    value: "E-Vouching > Contract Reference and/or Text",
  },
];

const hoursSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Retainer / Staffplan",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Out of Scope Hours",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Hours Only",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Hours Yearly Inflation",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Hours Report",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Report Level",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Level",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Role",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.hours
    )}`,
    value: "Hours > Hourly Rate",
  },
];

const remunerationContractLineSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Commission on Media",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Sliding Scale",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Third Party Fee",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Agreement Name",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Fee Percentage",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Start Date",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > End Date",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Applicable Services",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Payment Term",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Invoicing Period",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Payment Term Number of Days",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Fee on Media Invoice",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Sliding Scale Minimum Spend",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Currency",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Sliding Scale Maximum Spend",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.remunerationContractLine
    )}`,
    value: "Remuneration Contract Line > Other Currency",
  },
];

const revenueRecognitionSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Fee based Arrangement",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Retainer entity's efforts",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Trading Income",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > If revenue is recognized as over time",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Media Commissions",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Client Incentives",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Time & Material",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Short term fee Based Arrangement",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Other Retainer",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Other",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.revenueRecognition
    )}`,
    value: "Revenue Recognition > Remarks",
  },
];

const scopeOfServicesSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Strategy",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Planning",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Trading",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Competitive Reports",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Third Party Data Delivery",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Dashboard",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Tagging & Tracking",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Strategy - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Planning - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Trading - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value:
      "Scope Of Services > Competitive Reports - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value:
      "Scope Of Services > Third Party Data Delivery - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Dashboard - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value:
      "Scope Of Services > Tagging & Tracking - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Creation / Production",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Influencer Marketing",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Branded Content",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Research",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Training",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Other",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value:
      "Scope Of Services > Creation / Production - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value:
      "Scope Of Services > Influencer Marketing - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Branded Content - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Research - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Training - To be budgeted additionally",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.scopeOfServices
    )}`,
    value: "Scope Of Services > Other - To be budgeted additionally",
  },
];

const outOfScopeServicesSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.contract)}/${MAP_ACCORDION.get(
      AccordionEnum.outOfScopeServices
    )}`,
    value: "Out Of Scope Services > Out of Scope Services",
  },
];

const invoicingAddressMethodSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Invoice Address to Client",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Mailing Address",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Postal Code",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > City",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Country",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > E-mail Address Invoice",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Invoicing Days",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Attn.",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > E-mail Accountancy",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Phone Number Accountancy",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Layout Language",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Invoicing Method",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Third Party Invoicing Portal",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingAddressMethod
    )}`,
    value: "Invoicing Address & Method > Temporary Distribution Method",
  },
];

const invoicingRequirementsSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > PO Mandatory",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Only Send invoices with PO Number",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > VAT on Invoice",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice per order",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice per product",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice per media type",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice per plan",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice per campaign",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice per client",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice per reference number",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice per Collective Product",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.invoicingRequirements
    )}`,
    value: "Invoicing Requirements > Invoice Lay-out",
  },
];

const additionalInvoicingRequirementsSearchOptions = [
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalInvoicingRequirements
    )}`,
    value: "Additional Invoicing Requirements > Aging analysis required",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalInvoicingRequirements
    )}`,
    value: "Additional Invoicing Requirements > Frequency",
  },
  {
    key: `${MAP_SECTION.get(SectionEnum.invoicing)}/${MAP_ACCORDION.get(
      AccordionEnum.additionalInvoicingRequirements
    )}`,
    value:
      "Additional Invoicing Requirements > Additional invoicing information",
  },
];

const hoursAccountabilitySearchOptions = [
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.hoursAccountability)}`,
    value: "Hours Accountability > Writing hours per",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.hoursAccountability)}`,
    value: "Hours Accountability > Explanation to written hours",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.hoursAccountability)}`,
    value: "Hours Accountability > Splitting hours In Scope vs OOS",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.hoursAccountability)}`,
    value: "Hours Accountability > Fixed items per function",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.hoursAccountability)}`,
    value: "Hours Accountability > Additional information related to hours",
  },
];

const commercialAgreementsSearchOptions = [
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.commercialAgreements)}`,
    value: "Commercial Agreements > Charge Change Costs: Hours",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.commercialAgreements)}`,
    value: "Commercial Agreements > Charge Change Costs: Media",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.commercialAgreements)}`,
    value: "Commercial Agreements > Liquid Budget",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.commercialAgreements)}`,
    value: "Commercial Agreements > Except",
  },
];

const soxCompliantClientApprovalSearchOptions = [
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.soxCompliantClientApproval)}`,
    value: "SOx Compliant Client Approval > Approval Through",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.soxCompliantClientApproval)}`,
    value: "SOx Compliant Client Approval > Additional Terms",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.soxCompliantClientApproval)}`,
    value:
      "SOx Compliant Client Approval > Authorized to Sign - Threshold up to",
  },
  {
    key: `${MAP_SECTION.get(
      SectionEnum.variousCommercialAgreements
    )}/${MAP_ACCORDION.get(AccordionEnum.soxCompliantClientApproval)}`,
    value:
      "SOx Compliant Client Approval > Authorized to Sign - Name or Function",
  },
];

export const generalSearchOptions = [
  ...clientDocumentSearchOptions,
  ...generalClientInfoSearchOptions,
  ...additionalClientInfoSearchOptions,
  ...creditInsuranceSearchOptions,
  ...clientContactsSearchOptions,
  ...relatedContactsSearchOptions,
  ...clientTeamSearchOptions,
  ...generalContractInfoSearchOptions,
  ...financialAvbsSearchOptions,
  ...financialEpdsSearchOptions,
  ...financialUnbilledMediaSearchOptions,
  ...financialClientIncentiveSearchOptions,
  ...inventoryMediaSearchOptions,
  ...allowableDeducationsRebatesSearchOptions,
  ...auditSearchOptions,
  ...interestSearchOptions,
  ...admanagementSearchOptions,
  ...digitalAccountabilityBrandSafetySearchOptions,
  ...evouchingSearchOptions,
  ...hoursSearchOptions,
  ...remunerationContractLineSearchOptions,
  ...revenueRecognitionSearchOptions,
  ...scopeOfServicesSearchOptions,
  ...outOfScopeServicesSearchOptions,
  ...invoicingAddressMethodSearchOptions,
  ...invoicingRequirementsSearchOptions,
  ...additionalInvoicingRequirementsSearchOptions,
  ...hoursAccountabilitySearchOptions,
  ...commercialAgreementsSearchOptions,
  ...soxCompliantClientApprovalSearchOptions,
];

export const supplierSearchOptions = [
  ...clientDocumentSearchOptions,
  ...generalClientInfoSearchOptions,
  ...additionalClientInfoSearchOptions,
  ...creditInsuranceSearchOptions,
  ...clientContactsSearchOptions,
  ...relatedContactsSearchOptions,
  ...clientTeamSearchOptions,
  ...supplierAgreementsSearchOptions,
  ...interestSearchOptions,
  ...remunerationContractLineSearchOptions,
  ...revenueRecognitionSearchOptions,
  ...invoicingAddressMethodSearchOptions,
  ...invoicingRequirementsSearchOptions,
  ...additionalInvoicingRequirementsSearchOptions,
];
