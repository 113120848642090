import React, { ReactElement, useEffect, useState } from "react";
import { useProfileGetter } from "shared/hooks";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, Grid, TextField, Toolbar, Typography } from "@mui/material";
import {
  DEBTOR_SYNCS_START,
  DEBTOR_SYNCS_STOP,
  GET_DEBTOR_SYNCS,
} from "back-office/constants";
import DebtorSyncTable from "back-office/components/DebtorSyncTable";
import DebtorSyncDialog from "back-office/components/DebtorSyncDialog";
import { useStateMachine } from "little-state-machine";
import { DEFAULT_DATE_FORMAT } from "shared/constants";
import { BackOfficeKeys } from "shared/react-query/types";
import { useGet, usePut } from "shared/react-query/hooks";
import { DebtorSyncDetailsSchema } from "./types";
import { logDateSchema } from "./config";

export const DebtorSyncPage = (): ReactElement => {
  const [logDate, setLogDate] = useState<Date | null>(new Date());

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isRetrieveBtnEnabled, setIsRetrieveBtnEnabled] =
    useState<boolean>(true);

  const {
    state: { profile },
  } = useStateMachine();
  const { agencyId, userId, userAgency } = profile;

  useProfileGetter(0);

  // Get debtorSyncData
  const { data: debtorSyncData, setInitialParams: setDebtorSyncDate } = useGet(
    GET_DEBTOR_SYNCS,
    BackOfficeKeys.DEBTOR_SYNC,
    {
      initialParams: { logDate: logDate?.toISOString() },
    }
  );
  const { items = [], isRunning } = debtorSyncData ?? {};

  // Get debtorSyncDataDetails
  const {
    data: debtorSyncDetailsData,
    setUrlParams: setDebtorSyncDetailsId,
    setIsEnabled: setIsGetDebtorSyncDetailsEnabled,
  } = useGet(GET_DEBTOR_SYNCS, BackOfficeKeys.DEBTOR_SYNC_DETAILS, {
    isEnabled: false,
  });
  const {
    debtorSyncDetails,
  }: { debtorSyncDetails: DebtorSyncDetailsSchema[] } =
    debtorSyncDetailsData ?? {};

  // Methods for starting and stoping background job
  const startJob = usePut(DEBTOR_SYNCS_START);
  const stopJob = usePut(DEBTOR_SYNCS_STOP);

  useEffect(() => {
    setIsRetrieveBtnEnabled(logDateSchema.isValidSync(logDate));
  }, [logDate, setIsRetrieveBtnEnabled]);

  const handleDisplayLogDialog = (displayedLogId: number) => {
    setDebtorSyncDetailsId(displayedLogId.toString());
    setIsGetDebtorSyncDetailsEnabled(true);
    setIsDialogOpen(true);
  };

  const changeIsBackgroundJobRunning = async () => {
    const body = { agencyId, userId, userAgency };
    if (!isRunning) {
      startJob.mutate(body);
    } else {
      stopJob.mutate(body);
    }

    setLogDate(new Date());
    setDebtorSyncDate({ logDate: new Date().toISOString() });
  };

  return (
    <>
      {isDialogOpen && (
        <DebtorSyncDialog
          logs={debtorSyncDetails || []}
          onCancel={setIsDialogOpen}
        />
      )}
      <Toolbar>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={2}>
            <Grid item>
              <Typography>
                Job status: {isRunning ? "Running" : "Not Running"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Due date"
                inputFormat={DEFAULT_DATE_FORMAT}
                mask="__-__-____"
                maxDate={new Date()}
                value={logDate}
                onChange={(e: Date | null) => {
                  if (e?.toString() !== "Invalid Date") {
                    e?.setTime(e?.getTime() + 2 * 60 * 60 * 1000); // TODO: temp solution requires refactorisation of handling dates.
                    setLogDate(e);
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <Button
              id="button-retrieveLogs"
              size="small"
              variant="contained"
              onClick={() =>
                setDebtorSyncDate({ logDate: logDate?.toISOString() })
              }
              disabled={!isRetrieveBtnEnabled}
            >
              Retrieve Logs
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  id="button-stopJob"
                  color="secondary"
                  variant="contained"
                  disabled={!isRunning}
                  onClick={changeIsBackgroundJobRunning}
                >
                  Stop Job
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id="button-startJob"
                  color="primary"
                  variant="contained"
                  disabled={isRunning}
                  onClick={changeIsBackgroundJobRunning}
                >
                  Start Job
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>

      <Grid spacing={1} container alignItems="center">
        <Grid item xs={8}>
          <DebtorSyncTable
            items={items}
            onDisplayLogDialog={handleDisplayLogDialog}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DebtorSyncPage;
