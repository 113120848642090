import { number, object, string } from "yup";

import {
  MAX_LENGTH_1000_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  admanagementTool: string().trim().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  ratecardId: number().nullable().transform(nanToNull),
  contractReference: string()
    .trim()
    .max(1000, MAX_LENGTH_1000_VALIDATION_MESSAGE),
});
