import React, { useEffect, useState, ChangeEvent, ReactElement } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
  TableBody,
  TableCell,
  Toolbar,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useFetch, useProfileGetter, useQuery } from "shared/hooks";
import {
  TableHeader,
  TableFooter,
  TableRowEmpty,
  ClearAll,
  DynamicFilter,
  ConfirmationDialog,
} from "core";
import {
  ManageDebtorGroupsExtensionSearch,
  DebtorTableRow,
  DebtorGroupForm,
} from "access-manager/components";
import { EMPTY_STRING } from "shared/constants";
import { generateUrl } from "shared/utils";
import {
  ADD_DEBTORS_DIALOG_TITLE,
  ADD_DEBTORS_TO_DEBTOR_GROUP_DIALOG_CONTENT,
  GET_DEBTOR_GROUPS,
  GET_DEBTORS_FOR_MAPPING,
  REMOVE_DEBTORS_DIALOG_TITLE,
  REMOVE_DEBTORS_FROM_DEBTOR_GROUP_DIALOG_CONTENT,
  DEBTOR_GROUP_DEBTORS,
} from "access-manager/constants";
import { AgencyEnum, AgencyNameEnum, FetchMethodEnum } from "shared/enums";
import {
  setIsSelectedDebtors,
  setIsOpenAddSelectedDebtorsDialog,
  setIsOpenRemoveSelectedDebtorsDialog,
} from "store/actions";
import { TableRow } from "shared/styles";
import {
  defaultRowsPerPageOptions,
  dynamicFilterOptions,
  sortOptions,
} from "./config";
import { classes, Table, Select, Div, Divider } from "./styles";
import {
  DebtorsResponse,
  Debtor,
  DebtorState,
  IDebtorResponse,
  DebtorGroupsResponse,
  DebtorParamTypes,
} from "./types";

const { GET, POST, DELETE } = FetchMethodEnum;

export const ManageDebtorGroups = (): ReactElement => {
  const query = useQuery();
  const queryObject = Object.fromEntries(query);
  const {
    page: qPage,
    pageSize: qPageSize,
    sortByColumn,
    isascending,
    filterExtensions,
    selectedDebtorGroupId,
    ...filters
  } = queryObject;

  const intSelectedDebtorGroupId = selectedDebtorGroupId
    ? parseInt(selectedDebtorGroupId, 10)
    : 0;

  const { debtorGroupId } = useParams<DebtorParamTypes>();

  const getUsersUrl = generateUrl(GET_DEBTORS_FOR_MAPPING);
  const { data, updateParams, executeFetch, isLoading } = useFetch<
    DebtorsResponse,
    IDebtorResponse
  >(getUsersUrl, {
    method: GET,
    initialParams: { ...queryObject, debtorGroupId },
  });
  const { get: getData, post: postData } = data ?? {};
  const {
    items: debtors = [],
    page = 1,
    pageSize = 10,
    totalItems = 0,
  } = getData ?? {};

  const {
    push,
    location: { search },
  } = useHistory<DebtorState>();

  const { url: base } = useRouteMatch(); // TODO

  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [isSelectAll, setSelectAll] = useState<boolean | undefined>(false);

  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsSelectedDebtors, // TODO
    setIsOpenAddSelectedDebtorsDialog,
    setIsOpenRemoveSelectedDebtorsDialog,
  });

  useProfileGetter(0);

  const { isOpenAddSelectedDebtorsDialog, isOpenRemoveSelectedDebtorsDialog } =
    appState;

  const apiGetDebtorGroupsUrl = generateUrl(GET_DEBTOR_GROUPS);
  const { data: dataDebtorGroups } = useFetch<DebtorGroupsResponse>(
    apiGetDebtorGroupsUrl,
    {
      method: GET,
    }
  );
  const { get: debtorGroupsList } = dataDebtorGroups ?? {};
  const { debtorGroups = [] } = debtorGroupsList ?? {};

  const isDisabledClearAllButton =
    filterExtensions == null &&
    isEmpty(filters) &&
    intSelectedDebtorGroupId === 0;

  // #region Quick Filters
  const handleFilterExtensionsSearch = (extensionId: string) => {
    const currentExtensions = filterExtensions
      ? filterExtensions.split("_")
      : [];
    const nextExtensions = currentExtensions.includes(extensionId)
      ? currentExtensions.filter((s) => s !== extensionId)
      : currentExtensions.concat(extensionId);
    const queryParams = {
      ...queryObject,
      page: 1,
      filterExtensions: isEmpty(nextExtensions)
        ? undefined
        : nextExtensions.join("_"),
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };
  // #endregion

  const handleClearAll = () => {
    const queryParams = {
      page: 1,
      pageSize: 10,
      sortByColumn: queryObject.sortByColumn,
      isascending: queryObject.isascending,
    };
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  const handleSelectDebtorGroup = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as unknown | number | undefined;

    const queryParams = {
      ...queryObject,
      selectedDebtorGroupId: targetVal === 0 ? undefined : targetVal,
      page: 1,
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  // #region Debtors Actions
  const handleRemoveSelectedDebtors = () => {
    const removeDebtorsUrl = generateUrl(DEBTOR_GROUP_DEBTORS, {
      debtorGroupId,
    });

    executeFetch(removeDebtorsUrl, {
      method: DELETE,
      body: { debtors: checkedItems },
    });

    actions.setIsOpenRemoveSelectedDebtorsDialog(false);
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);
  };

  const handleCloseRemoveSelectedDebtorsDialog = () => {
    actions.setIsOpenRemoveSelectedDebtorsDialog(false);
  };

  const handleAddSelectedDebtors = () => {
    const addDebtorsUrl = generateUrl(DEBTOR_GROUP_DEBTORS, {
      debtorGroupId,
    });

    executeFetch(addDebtorsUrl, {
      method: POST,
      body: {
        debtors: checkedItems,
      },
    });

    actions.setIsOpenAddSelectedDebtorsDialog(false);
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);
  };

  const handleCloseAddSelectedDebtorsDialog = () => {
    actions.setIsOpenAddSelectedDebtorsDialog(false);
  };
  // #endregion

  // #region Select checkboxes
  const handleCheckboxClick = (id: number) => {
    const items = checkedItems?.some((ci) => ci === id)
      ? checkedItems.filter((ci) => ci !== id)
      : [...checkedItems, id];

    setCheckedItems(items);

    if (isEmpty(items)) {
      setSelectAll(false);
    }

    if (!isEmpty(items) && debtors.length !== items.length) {
      setSelectAll(undefined);
    }

    if (debtors.length === items.length) {
      setSelectAll(true);
    }

    actions.setIsSelectedDebtors(items.length !== 0);
  };

  const handleMainCheckboxClick = () => {
    const ids = debtors.map((d) => d.id);

    if (isSelectAll) {
      setSelectAll(false);
      setCheckedItems([]);
      actions.setIsSelectedDebtors(false);
    } else {
      setSelectAll(true);
      setCheckedItems(ids);
      actions.setIsSelectedDebtors(true);
    }
  };

  const handleInvert = () => {
    const ids = debtors.map((d) => d.id);
    const invertArray = ids.filter((x) => !checkedItems.includes(x));

    setCheckedItems(invertArray);

    if (isEmpty(invertArray)) {
      setSelectAll(false);
    }

    if (debtors.length === invertArray.length) {
      setSelectAll(true);
    }

    actions.setIsSelectedDebtors(invertArray.length !== 0);
  };
  // #endregion

  // #region Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    const queryPageSize = query.get("pageSize");
    const queryParams = {
      ...queryObject,
      page: newPage + 1,
      pageSize: queryPageSize,
    }; // newPage +1 reason is TablePagination page is zeroBased
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl, {
      actionSelectedItems: false,
    });
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      pageSize: event.target.value,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);
  };
  // #endregion Pagination

  // #region Dynamic filter
  const handleDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      page: 1,
      [key]: dynFilters[key],
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);

    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl, {
      actionSelectedItems: false,
    });
  };
  // #endregion

  // #region Sort
  const handleChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as string | null | undefined;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      page: 1,
      sortByColumn: targetVal ?? EMPTY_STRING,
      isascending: isAsc,
    };

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedDebtors(false);

    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl, {
      actionSelectedItems: false,
    });
  };
  // #endregion

  // #region useEffect
  useEffect(() => {
    const newQuery = new URLSearchParams(search);
    let newQueryObj = Object.fromEntries(newQuery);

    if (debtorGroupId) {
      newQueryObj = { ...newQueryObj, debtorGroupId };
    }

    updateParams(newQueryObj);
  }, [debtorGroupId, search, updateParams]);

  useEffect(() => {
    const { isSuccessful } = (postData ?? {}) as any;
    if (isSuccessful) {
      const newQuery = new URLSearchParams(search);
      let newQueryObj = Object.fromEntries(newQuery);

      if (debtorGroupId) {
        newQueryObj = { ...newQueryObj, debtorGroupId };
      }

      updateParams(newQueryObj);
    }
  }, [postData, debtorGroupId, search, updateParams]);

  useEffect(() => {
    if (checkedItems.length === 0) {
      actions.setIsSelectedDebtors(false);
    }
  }, [checkedItems, actions]);
  // #endregion useEffect

  return (
    <>
      <DebtorGroupForm />
      <Toolbar>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Grid container alignContent="flex-start" alignItems="center">
              <Grid item>
                <Grid
                  container
                  alignContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <ManageDebtorGroupsExtensionSearch
                      onExtensionClick={handleFilterExtensionsSearch}
                      selected={filterExtensions}
                      isDisabled={!debtorGroupId}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <Select
                        id="debtorGroups"
                        name="debtorGroups"
                        value={intSelectedDebtorGroupId}
                        onChange={handleSelectDebtorGroup}
                        disabled={!debtorGroupId}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 150,
                            },
                          },
                        }}
                      >
                        <MenuItem key={0} id="debtorGroup-empty" value={0}>
                          None
                        </MenuItem>
                        {debtorGroups?.map(
                          ({ debtorGroupId: id, name, agencyId }) => (
                            <MenuItem
                              key={id || undefined}
                              id={`debtorGroup-${id}`}
                              value={id || undefined}
                            >
                              {name} -
                              {
                                AgencyNameEnum[
                                  AgencyEnum[
                                    agencyId
                                  ].toLowerCase() as keyof typeof AgencyNameEnum
                                ]
                              }
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <DynamicFilter
                      updateUrl={handleDynamicFilter}
                      queryFilters={filters}
                      filterOptions={dynamicFilterOptions}
                      limit={3}
                      isDisabled={!debtorGroupId}
                    />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    className={classes.divider}
                  />
                  <Div className={classes.div}>
                    <ClearAll
                      onClear={handleClearAll}
                      disabled={isDisabledClearAllButton}
                    />
                  </Div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs={12}>
        <Table size="small" className={classes.table}>
          <>
            <TableHeader
              sortOptions={sortOptions}
              sortValue={queryObject.sortByColumn}
              onSortChange={handleChangeSort}
              rowsPerPageOptions={defaultRowsPerPageOptions}
              count={totalItems}
              rowsPerPage={pageSize}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              isDisabled={!debtorGroupId}
            />
            <TableBody>
              <TableRow>
                <TableCell>
                  {!isEmpty(debtors) ? (
                    <Checkbox
                      id="check-all"
                      indeterminate={isSelectAll === undefined}
                      inputProps={{ "aria-label": "main checkbox" }}
                      onChange={() => handleMainCheckboxClick()}
                      checked={isSelectAll}
                      className={classes.checkbox}
                      disabled={!debtorGroupId}
                    />
                  ) : (
                    EMPTY_STRING
                  )}
                </TableCell>
                <TableCell>
                  {!isEmpty(debtors) ? (
                    <Chip
                      id="button-invert"
                      color="primary"
                      onClick={handleInvert}
                      label="Invert Selection"
                      clickable
                      variant="outlined"
                      disabled={!debtorGroupId}
                    />
                  ) : (
                    EMPTY_STRING
                  )}
                </TableCell>
              </TableRow>
              {isEmpty(debtors) ? (
                <TableRowEmpty />
              ) : (
                debtors.map((debtor: Debtor) => (
                  <DebtorTableRow
                    key={debtor.id}
                    {...debtor}
                    onCheckboxClick={handleCheckboxClick}
                    isChecked={checkedItems.includes(debtor.id)}
                    isDisabled={!debtorGroupId}
                  />
                ))
              )}
            </TableBody>
            {debtorGroupId && (
              <TableFooter
                rowsPerPageOptions={defaultRowsPerPageOptions}
                count={totalItems}
                rowsPerPage={pageSize}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </>
        </Table>
        {isOpenAddSelectedDebtorsDialog && (
          <ConfirmationDialog
            open={isOpenAddSelectedDebtorsDialog}
            title={ADD_DEBTORS_DIALOG_TITLE}
            message={ADD_DEBTORS_TO_DEBTOR_GROUP_DIALOG_CONTENT}
            isDisableAction={isLoading}
            onConfirm={handleAddSelectedDebtors}
            onCancel={handleCloseAddSelectedDebtorsDialog}
          />
        )}
        {isOpenRemoveSelectedDebtorsDialog && (
          <ConfirmationDialog
            open={isOpenRemoveSelectedDebtorsDialog}
            title={REMOVE_DEBTORS_DIALOG_TITLE}
            message={REMOVE_DEBTORS_FROM_DEBTOR_GROUP_DIALOG_CONTENT}
            isDisableAction={isLoading}
            onConfirm={handleRemoveSelectedDebtors}
            onCancel={handleCloseRemoveSelectedDebtorsDialog}
          />
        )}
      </Grid>
    </>
  );
};

export default ManageDebtorGroups;
