import React, { KeyboardEvent, useEffect, ReactElement } from "react";

import { Control, FieldValues, useForm } from "react-hook-form";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { Prompt, useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { TextField, CheckField, Autocomplete } from "core";

import {
  useFetch,
  useQuery,
  FetchMethodEnum,
  useRequestSniffer,
} from "shared/hooks";

import {
  KEY_ENTER,
  TAG_TEXTAREA,
  UNSAVED_DATA_MESSAGE,
} from "shared/constants";
import { generateUrl } from "shared/utils";
import { AgencyEnum } from "master-data/enums";
import { CREATE_USER, EDIT_USER } from "back-office/constants";
import { ParamTypes, UserType } from "back-office/pages/UserManagement/types";

import {
  schema,
  companyOptions,
  roleOptions,
  agentForIdsOptions,
} from "./config";
import {
  AgencyIdToFieldName,
  defaultAgencyRoleFields,
  defaultValues,
  FORM_ID,
  QUERY_PARAM_MODE,
} from "./constants";
import { ModeEnum } from "./enums";
import { AgencyRoleFieldsType, IFormInput, IResponsePost } from "./types";

import { classes, Dialog } from "./styles";

export interface UserManagementDialogProps {
  /*
   * user data
   */
  users?: UserType[];
  /**
   * onUsersUpdate callback
   */
  onUsersUpdate: (userData: UserType) => void;
}

export const UserManagementDialog = ({
  users,
  onUsersUpdate,
}: UserManagementDialogProps): ReactElement => {
  const history = useHistory();
  const { userId } = useParams<ParamTypes>();
  const query = useQuery();
  const { isRequestPending } = useRequestSniffer();

  const mode = query.get(QUERY_PARAM_MODE);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: {
      errors,
      isDirty,
      dirtyFields,
      touchedFields,
      isSubmitSuccessful,
    },
  } = useForm<IFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data, executeFetch, resetPostData } = useFetch<
    undefined,
    IResponsePost
  >(null, { skip: true });

  const { post: postData } = data ?? {};

  // #region Methods
  const handleReset = () => {
    history.goBack();
  };

  const onSubmit = (submittedData: IFormInput) => {
    const EDIT_USER_URL = generateUrl(EDIT_USER, {
      userId,
    });
    const method =
      mode === ModeEnum.edit ? FetchMethodEnum.PUT : FetchMethodEnum.POST;
    const url = mode === ModeEnum.edit ? EDIT_USER_URL : CREATE_USER;

    const body = {
      ...submittedData,
      userAgencyRoleList: [
        { agencyId: AgencyEnum.groupm, roleIds: submittedData.groupmRoles },
        {
          agencyId: AgencyEnum.mindshare,
          roleIds: submittedData.mindshareRoles,
        },
        { agencyId: AgencyEnum.kinetic, roleIds: submittedData.kineticRoles },
        {
          agencyId: AgencyEnum.essencemediacom,
          roleIds: submittedData.essencemediacomRoles,
        },
        {
          agencyId: AgencyEnum.wavemaker,
          roleIds: submittedData.wavemakerRoles,
        },
        {
          agencyId: AgencyEnum.blossom,
          roleIds: submittedData.blossomRoles,
        },
        {
          agencyId: AgencyEnum.greenhouseone,
          roleIds: submittedData.greenhouseoneRoles,
        },
        {
          agencyId: AgencyEnum.greenhousetech,
          roleIds: submittedData.greenhousetechRoles,
        },
        {
          agencyId: AgencyEnum.choreographcreate,
          roleIds: submittedData.choreographcreateRoles,
        },
      ],
    };

    executeFetch(url, { method, body, skip: false });
  };

  const onKeyPressInField = (event: KeyboardEvent<HTMLElement>) => {
    const tag = event.target as HTMLElement;
    if (event.key === KEY_ENTER && tag.tagName !== TAG_TEXTAREA) {
      event.preventDefault();
    }
  };
  // #endregion

  const [roleIds, isActive, isAdmin, isAgent, agentForIds, agencyId] = watch([
    "roleIds",
    "isActive",
    "isAdmin",
    "isAgent",
    "agentForIds",
    "agencyId",
  ]);

  // #region Effects
  useEffect(() => {
    if (
      isActive === false &&
      (dirtyFields.isActive || touchedFields.isActive)
    ) {
      setValue("isAdmin", false, { shouldDirty: true });
      setValue("isAgent", false, { shouldDirty: true });
      setValue("roleIds", [], { shouldDirty: true });
    }
  }, [setValue, isActive, dirtyFields.isActive, touchedFields.isActive]);

  useEffect(() => {
    if (agencyId && (dirtyFields.agencyId || touchedFields.agencyId)) {
      setValue("agentForIds", [], { shouldDirty: true });
      setValue("isAgent", false, { shouldDirty: true });
    }
  }, [setValue, agencyId, dirtyFields.agencyId, touchedFields.agencyId]);

  useEffect(() => {
    if (isAdmin && (dirtyFields.isAdmin || touchedFields.isAdmin)) {
      setValue("roleIds", [], { shouldDirty: true });
      setValue("isAgent", false, { shouldDirty: true });
    }
  }, [
    setValue,
    isAdmin,
    dirtyFields.isAgent,
    dirtyFields.isAdmin,
    touchedFields.isAgent,
    touchedFields.isAdmin,
  ]);

  useEffect(() => {
    if (!isAgent && (dirtyFields.isAgent || touchedFields.isAgent)) {
      setValue("agentForIds", [], { shouldDirty: true });
      setValue("groupmRoles", [], { shouldDirty: true });
      setValue("kineticRoles", [], { shouldDirty: true });
      setValue("essencemediacomRoles", [], { shouldDirty: true });
      setValue("mindshareRoles", [], { shouldDirty: true });
      setValue("wavemakerRoles", [], { shouldDirty: true });
      setValue("blossomRoles", [], { shouldDirty: true });
      setValue("greenhouseoneRoles", [], { shouldDirty: true });
      setValue("greenhousetechRoles", [], { shouldDirty: true });
      setValue("choreographcreateRoles", [], { shouldDirty: true });
    }
  }, [setValue, isAgent, dirtyFields.isAgent, touchedFields.isAgent]);

  useEffect(() => {
    const agencyRoles = agentForIds?.reduce(
      (result, item: keyof typeof AgencyIdToFieldName) => {
        const index = AgencyIdToFieldName[item];
        // eslint-disable-next-line no-param-reassign
        result[index] = roleIds;
        return result;
      },
      {} as { [key: string]: number[] }
    );

    if (
      agencyRoles &&
      (dirtyFields.roleIds ||
        touchedFields.roleIds ||
        dirtyFields.agentForIds ||
        touchedFields.agentForIds)
    ) {
      const roles = { ...defaultAgencyRoleFields, ...agencyRoles };
      Object.keys(roles).forEach((key) => {
        setValue(key as any, roles[key as AgencyRoleFieldsType], {
          shouldDirty: true,
        });
      });
    }
  }, [
    getValues,
    roleIds,
    setValue,
    agentForIds,
    dirtyFields.roleIds,
    touchedFields.roleIds,
    dirtyFields.agentForIds,
    touchedFields.agentForIds,
  ]);

  useEffect(() => {
    if (users && userId) {
      const activeUser = users.find(
        (user) => user.userId.toString() === userId
      );
      if (activeUser) {
        reset({
          agencyId: activeUser.agencyId,
          userId: activeUser.userId,
          lastName: activeUser.lastName,
          firstName: activeUser.firstName,
          username: activeUser.username,
          isAdmin: activeUser.isAdmin,
          isAgent: activeUser.isAgent,
          agentForIds: activeUser.agentForIds,
          roleIds: activeUser.roleIds,
          isActive: activeUser.isActive,
          groupmRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.groupm
            )?.roleIds ?? [],
          kineticRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.kinetic
            )?.roleIds ?? [],
          mindshareRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.mindshare
            )?.roleIds ?? [],
          essencemediacomRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.essencemediacom
            )?.roleIds ?? [],
          wavemakerRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.wavemaker
            )?.roleIds ?? [],
          blossomRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.blossom
            )?.roleIds ?? [],
          greenhouseoneRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.greenhouseone
            )?.roleIds ?? [],
          greenhousetechRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.greenhousetech
            )?.roleIds ?? [],
          choreographcreateRoles:
            activeUser.userAgencyRoleList.find(
              (li) => li.agencyId === AgencyEnum.choreographcreate
            )?.roleIds ?? [],
        });
      }
    }
  }, [users, reset, userId]);

  useEffect(() => {
    const { isSuccessful, ...userData } = postData ?? {};
    if (isSubmitSuccessful && isSuccessful) {
      onUsersUpdate(userData as UserType);
      resetPostData();
      history.goBack();
    }
  }, [history, isSubmitSuccessful, onUsersUpdate, postData, resetPostData]);
  // #endregion Effects

  const isDisabledIsAdmin = !isActive;
  const isDisabledIsAgent = !isActive || isAdmin;
  const isDisabledRoles = !isActive || isAdmin;
  const isDisabledAgentForIds = !isAgent;
  const isDisabledGroupmRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.groupm
  );
  const isDisabledKineticRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.kinetic
  );
  const isDisabledEssenceMediacomRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.essencemediacom
  );
  const isDisabledMindshareRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.mindshare
  );
  const isDisabledWavemakerRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.wavemaker
  );
  const isDisabledBlossomRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.blossom
  );
  const isDisabledGreenhouseOneRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.greenhouseone
  );
  const isDisabledGreenhouseTechRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.greenhousetech
  );
  const isDisabledChoreographCreateRoles = !agentForIds?.some(
    (agentId) => agentId === AgencyEnum.choreographcreate
  );

  return (
    <>
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle className={classes.title} id="user-dialog-title">
          {mode} user
        </DialogTitle>
        <DialogContent id="user-dialog-content" dividers>
          {process.env.NODE_ENV !== "production" && (
            <DevTool control={control} placement="top-left" />
          )}
          <form
            id={FORM_ID}
            onSubmit={handleSubmit(onSubmit)}
            onReset={handleReset}
            onKeyDown={onKeyPressInField}
            role="presentation"
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  control={control as unknown as Control<FieldValues>}
                  fullWidth
                  variant="outlined"
                  name="lastName"
                  id="lastName"
                  label="Last Name *"
                  error={!!errors.lastName?.message}
                  defaultValue={defaultValues.lastName}
                  helperText={errors.lastName?.message}
                />
              </Grid>
              <Grid item>
                <TextField
                  control={control as unknown as Control<FieldValues>}
                  fullWidth
                  variant="outlined"
                  name="firstName"
                  id="firstName"
                  label="First Name *"
                  error={!!errors.firstName?.message}
                  defaultValue={defaultValues.firstName}
                  helperText={errors.firstName?.message}
                />
              </Grid>
              <Grid item>
                <TextField
                  control={control as unknown as Control<FieldValues>}
                  fullWidth
                  variant="outlined"
                  name="username"
                  id="username"
                  label="Username *"
                  error={!!errors.username?.message}
                  defaultValue={defaultValues.username}
                  helperText={errors.username?.message}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="agencyId"
                  label="Company *"
                  id="agencyId"
                  options={companyOptions}
                  error={!!errors.agencyId?.message}
                  defaultValue={defaultValues.agencyId}
                  helperText={errors.agencyId?.message}
                />
              </Grid>
              <Grid item>
                <CheckField
                  control={control as unknown as Control<FieldValues>}
                  label="Active"
                  name="isActive"
                  id="isActive"
                  defaultValue={defaultValues.isActive}
                />
                <CheckField
                  control={control as unknown as Control<FieldValues>}
                  label="Admin"
                  name="isAdmin"
                  id="isAdmin"
                  disabled={isDisabledIsAdmin}
                  defaultValue={defaultValues.isAdmin}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="roleIds"
                  label="Roles *"
                  id="roleIds"
                  options={roleOptions}
                  multiple
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  error={!!(errors.roleIds as any)?.message} // TODO: needs any until fixed it's yup/typescript bug -> https://github.com/react-hook-form/react-hook-form/issues/987
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  helperText={(errors.roleIds as any)?.message}
                  defaultValue={defaultValues.roleIds}
                  disabled={isDisabledRoles}
                />
              </Grid>
              <Grid item>
                <CheckField
                  control={control as unknown as Control<FieldValues>}
                  label="Agent"
                  name="isAgent"
                  id="isAgent"
                  disabled={isDisabledIsAgent}
                  defaultValue={defaultValues.isAgent}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="agentForIds"
                  label="For Agencies"
                  id="agentForIds"
                  options={agentForIdsOptions}
                  getOptionDisabled={(option) => option.key === agencyId}
                  multiple
                  defaultValue={defaultValues.agentForIds}
                  disabled={isDisabledAgentForIds}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="groupmRoles"
                  label="GroupM Roles"
                  id="groupmRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.groupmRoles}
                  disabled={isDisabledGroupmRoles}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="kineticRoles"
                  label="Kinetic Roles"
                  id="kineticRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.kineticRoles}
                  disabled={isDisabledKineticRoles}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="mindshareRoles"
                  label="Mindshare Roles"
                  id="mindshareRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.mindshareRoles}
                  disabled={isDisabledMindshareRoles}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="essencemediacomRoles"
                  label="EssenceMediacom Roles"
                  id="essencemediacomRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.essencemediacomRoles}
                  disabled={isDisabledEssenceMediacomRoles}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="wavemakerRoles"
                  label="Wavemaker Roles"
                  id="wavemakerRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.wavemakerRoles}
                  disabled={isDisabledWavemakerRoles}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="blossomRoles"
                  label="Blossom Roles"
                  id="blossomRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.blossomRoles}
                  disabled={isDisabledBlossomRoles}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="greenhouseoneRoles"
                  label="Greenhouse One Roles"
                  id="greenhouseoneRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.greenhouseoneRoles}
                  disabled={isDisabledGreenhouseOneRoles}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="greenhousetechRoles"
                  label="Greenhouse Tech Roles"
                  id="greenhousetechRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.greenhousetechRoles}
                  disabled={isDisabledGreenhouseTechRoles}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  control={control as unknown as Control<FieldValues>}
                  name="choreographcreateRoles"
                  label="Choreograph Create Roles"
                  id="choreographcreateRoles"
                  options={roleOptions}
                  multiple
                  defaultValue={defaultValues.choreographcreateRoles}
                  disabled={isDisabledChoreographCreateRoles}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions id="project-dialog-action">
          <Button
            id="button-reset"
            form={FORM_ID}
            variant="contained"
            type="reset"
            onClick={(e) => e.stopPropagation()}
            disabled={isRequestPending}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            id="button-submit"
            form={FORM_ID}
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => e.stopPropagation()}
            disabled={isRequestPending}
          >
            {isRequestPending ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt
        when={isDirty && !isSubmitSuccessful}
        message={UNSAVED_DATA_MESSAGE}
      />
    </>
  );
};

export default UserManagementDialog;
