import React, { ReactElement } from "react";

import { array, date, number, object, ref, string } from "yup";
import { Select as MuiSelect, MenuItem } from "@mui/material";

import {
  DateCell,
  InputCell,
  ColumnType,
  NumericCell,
  CellTypeEnum,
  RenderCellProps,
} from "core";

import {
  EMPTY_STRING,
  MAX_NUMBER_FOR_INPUTS,
  MIN_NUMBER_FOR_INPUTS,
  MAX_NUMBER_VALIDATION_MESSAGE,
  INVALID_DATE_VALIDATION_MESSAGE,
  NUMBER_FIELD_VALIDATION_MESSAGE,
  MAX_LENGTH_50_VALIDATION_MESSAGE,
  MANDATORY_ENTRY_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  INVALID_END_DATE_BEFORE_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

// #region columns definition
export const columns: ColumnType[] = [
  {
    field: "id",
    headerName: "Id",
    hide: true,
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "agreementName",
    headerName: "Agreement Name",
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "feePercentage",
    headerName: "Fee Percentage",
    cellType: CellTypeEnum.NumberFormat,
    renderCell: ({ field }: RenderCellProps) => (
      <NumericCell suffix="%" {...field} />
    ),
  },
  {
    field: "startDate",
    headerName: "Start Date",
    cellType: CellTypeEnum.Date,
    renderCell: ({ field }: RenderCellProps) => <DateCell {...field} />,
  },
  {
    field: "endDate",
    headerName: "End Date",
    cellType: CellTypeEnum.Date,
    renderCell: ({ field }: RenderCellProps) => <DateCell {...field} />,
  },
  {
    field: "applicableServices",
    headerName: "Applicable Services",
    hide: true,
    isMultiple: true,
    cellType: CellTypeEnum.Select,
    renderCell: ({ field, options = [] }: RenderCellProps): ReactElement => {
      return (
        <MuiSelect
          multiple
          disabled
          {...field}
          IconComponent={() => <></>}
          SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
        >
          <MenuItem value={EMPTY_STRING}>
            <em>None</em>
          </MenuItem>
          {options.map(({ key, value }: OptionType) => {
            return (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </MuiSelect>
      );
    },
  },
  {
    field: "invoicingPeriodId",
    headerName: "Invoicing Period",
    cellType: CellTypeEnum.Select,
    hide: true,
    renderCell: ({ field, options = [] }: RenderCellProps): ReactElement => {
      return (
        <MuiSelect
          disabled
          {...field}
          IconComponent={() => <></>}
          SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
        >
          <MenuItem value={EMPTY_STRING}>
            <em>None</em>
          </MenuItem>
          {options.map(({ key, value }: OptionType) => {
            return (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </MuiSelect>
      );
    },
  },
  {
    field: "paymentTermId",
    headerName: "Payment Term",
    cellType: CellTypeEnum.Select,
    hide: true,
    renderCell: ({ field, options = [] }: RenderCellProps): ReactElement => {
      return (
        <MuiSelect
          disabled
          {...field}
          IconComponent={() => <></>}
          SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
        >
          <MenuItem value={EMPTY_STRING}>
            <em>None</em>
          </MenuItem>
          {options.map(({ key, value }: OptionType) => {
            return (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </MuiSelect>
      );
    },
  },
  {
    field: "feeOnMediaInvoiceId",
    headerName: "Fee on Media Invoice",
    cellType: CellTypeEnum.Select,
    hide: true,
    renderCell: ({ field, options = [] }: RenderCellProps): ReactElement => {
      return (
        <MuiSelect
          disabled
          {...field}
          IconComponent={() => <></>}
          SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
        >
          <MenuItem value={EMPTY_STRING}>
            <em>None</em>
          </MenuItem>
          {options.map(({ key, value }: OptionType) => {
            return (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </MuiSelect>
      );
    },
  },
  {
    field: "paymentTermNumberOfDays",
    headerName: "Payment Term number of days",
    cellType: CellTypeEnum.NumberFormat,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => (
      <NumericCell integer {...field} />
    ),
  },
  {
    field: "currencyId",
    headerName: "Currency",
    cellType: CellTypeEnum.Select,
    hide: true,
    renderCell: ({ field, options = [] }: RenderCellProps): ReactElement => {
      return (
        <MuiSelect
          disabled
          {...field}
          IconComponent={() => <></>}
          SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
        >
          <MenuItem value={EMPTY_STRING}>
            <em>None</em>
          </MenuItem>
          {options.map(({ key, value }: OptionType) => {
            return (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </MuiSelect>
      );
    },
  },
  {
    field: "slidingScaleMinimumSpend",
    headerName: "Sliding Scale Minimum Spend",
    cellType: CellTypeEnum.NumberFormat,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => <NumericCell {...field} />,
  },
  {
    field: "otherCurrency",
    headerName: "Other Currency",
    cellType: CellTypeEnum.Input,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "slidingScaleMaximumSpend",
    headerName: "Sliding Scale Maximum Spend",
    cellType: CellTypeEnum.NumberFormat,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => <NumericCell {...field} />,
  },
];
// #endregion columns definition

const formSchema = {
  id: number().transform(nanToNull).nullable(),
  agreementName: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  feePercentage: number()
    .transform(nanToNull)
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .nullable()
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  startDate: date()
    .nullable()
    .typeError(INVALID_DATE_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  endDate: date()
    .nullable()
    .typeError(INVALID_DATE_VALIDATION_MESSAGE)
    .min(ref("startDate"), INVALID_END_DATE_BEFORE_VALIDATION_MESSAGE),
  applicableServices: array()
    .of(number())
    .min(1, MANDATORY_FILED_VALIDATION_MESSAGE),
  invoicingPeriodId: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  paymentTermId: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  feeOnMediaInvoiceId: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  paymentTermNumberOfDays: number()
    .when("paymentTermId", {
      is: 2,
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().notRequired().transform(nanToNull),
    })
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE),
  currencyId: number()
    .nullable()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  slidingScaleMinimumSpend: number()
    .transform(nanToNull)
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .nullable()
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .notRequired(),
  otherCurrency: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
    .notRequired()
    .when("currencyId", {
      is: 5,
      then: string().nullable().required(MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: string().nullable().notRequired(),
    }),
  slidingScaleMaximumSpend: number()
    .notRequired()
    .transform(nanToNull)
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .nullable()
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE),
};

export const validationSchema = object().shape({
  commissionOnMedia: number()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE),
  slidingScale: number()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE),
  thirdPartyFee: number()
    .transform(nanToNull)
    .typeError(MANDATORY_FILED_VALIDATION_MESSAGE),
  entries: array()
    .of(object().shape(formSchema))
    .required(MANDATORY_ENTRY_VALIDATION_MESSAGE)
    .min(1, MANDATORY_ENTRY_VALIDATION_MESSAGE),
});
