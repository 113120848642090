import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  feeBasedArrangement: 0,
  revenuRecognizedOverTime: "Output",
  retainerEntitysEfforts: 0,
  mediaCommissions: 0,
  tradingIncome: 0,
  clientIncentives: 0,
  timeAndMaterial: 0,
  shortTermFeeBasedArrangement: 0,
  otherRetainer: 0,
  other: 0,
  remarks: EMPTY_STRING,
};
