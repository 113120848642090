import { SvgIconComponent } from "@mui/icons-material";
import { styled } from "@mui/material";

import { linkWater } from "shared/assets/colors";

export interface StyleProps {
  /**
   * isDisabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * color
   * @default undefined
   */
  color?: string;
  /**
   * backgroundColor
   * @default undefined
   */
  backgroundColor?: string;
  /**
   * width
   * @default undefined
   */
  width?: number;
  /**
   * height
   * @default undefined
   */
  height?: number;
  /**
   * fontSize
   * @default undefined
   */
  fontSize?: number;
}

const PREFIX = "IconWrapperStyles";
export const classes = {
  root: `${PREFIX}-root`,
};

export const getStyledIcon = (
  iconComponent: SvgIconComponent,
  props: StyleProps
): any =>
  styled(iconComponent)(() => ({
    [`&.${classes.root}`]: {
      color: props && props.isDisabled ? linkWater : props.color,
      backgroundColor: props && props.backgroundColor,
      width: props && props.width,
      height: props && props.height,
      fontSize: props && props.fontSize,
    },
  }));
