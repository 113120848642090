import React, { ChangeEvent, ReactElement } from "react";

import NumberFormat from "react-number-format";
import { Control, Controller } from "react-hook-form";
import { History as HistoryIcon } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import {
  EMPTY_STRING,
  REVISION_INIT_VALUE,
  MAX_NUMBER_FOR_INPUTS,
  MIN_NUMBER_FOR_INPUTS,
} from "shared/constants";

import { InfoPopper } from "../InfoPopper";

export interface NumericFieldProps {
  /**
   * Component Id
   */
  id: string;
  /**
   * control of useForm from react hook form.
   */
  control: Control;
  /**
   * Name attribute of the input element.
   */
  name: string;
  /**
   * The label content.
   */
  label: string;
  /**
   * The variant to use.
   */
  variant?: "outlined" | "filled" | "standard";
  /**
   * Default Value
   */
  defaultValue?: string | number;
  /**
   * If true, the label will be displayed in an error state.
   */
  error?: boolean;
  /**
   * The helper text content.
   */
  helperText?: string;
  /**
   * If true, the input element will be disabled.
   */
  disabled?: boolean;
  /**
   * Add thousand separators on number
   */
  thousandSeparator?: string | boolean;
  /**
   * Support decimal point on a number
   */
  decimalSeparator?: string;
  /**
   * If defined it limits to given decimal scale
   */
  decimalScale?: number;
  /**
   * If true it add 0s to match given decimalScale
   */
  fixedDecimalScale?: boolean;
  /**
   * Full width component.
   */
  fullWidth?: boolean;
  /**
   * Allow negative numbers (Only when format option is not provided)
   */
  allowNegative?: boolean;
  /**
   * Override css
   */
  className?: string;
  /**
   * Add a prefix before the number
   */
  prefix?: string;
  /**
   * Add a prefix before the number
   */
  suffix?: string;
  /**
   * Allow only integers
   */
  integer?: boolean;
  /**
   * Adjust to percentage view
   */
  percentage?: boolean;
  /**
   * Previous value of field
   */
  revisionValue?: NullableNumber;
}

export const NumericField = ({
  id,
  name,
  className,
  control,
  label,
  variant,
  defaultValue,
  error,
  helperText,
  disabled,
  thousandSeparator,
  decimalSeparator,
  decimalScale,
  fixedDecimalScale,
  fullWidth,
  allowNegative,
  prefix,
  suffix,
  integer,
  percentage,
  revisionValue,
  ...otherProps
}: NumericFieldProps): ReactElement => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const transform = {
    input: (value: unknown) => {
      return value == null || !Number.isFinite(value)
        ? EMPTY_STRING
        : (value as number).toString();
    },
    output: (e: ChangeEvent<HTMLInputElement>) => {
      const formatedStrVal = e.target.value
        .replaceAll(".", EMPTY_STRING)
        .replace(",", ".");
      const output = parseFloat(formatedStrVal);
      return Number.isNaN(output) ? null : output;
    },
  };

  let revisionVal: string | undefined;
  if (revisionValue === null) {
    revisionVal = REVISION_INIT_VALUE;
  } else {
    revisionVal = revisionValue?.toLocaleString("nl-NL", {
      minimumFractionDigits: integer ? 0 : decimalScale,
    });
  }

  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <NumberFormat
            {...otherProps}
            {...field}
            id={id}
            label={label}
            className={className}
            variant={variant}
            error={error}
            helperText={helperText}
            disabled={disabled}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={integer ? 0 : decimalScale}
            fixedDecimalScale={integer ? false : fixedDecimalScale}
            customInput={TextField}
            fullWidth={fullWidth}
            allowNegative={allowNegative}
            isNumericString
            prefix={prefix}
            suffix={percentage ? " %" : suffix}
            onChange={(e: any) => field.onChange(transform.output(e))}
            value={transform.input(field.value)}
            isAllowed={(values) => {
              const { formattedValue, floatValue: floatVal } = values;
              const inRange =
                floatVal || floatVal === 0
                  ? floatVal >= MIN_NUMBER_FOR_INPUTS &&
                    floatVal <= MAX_NUMBER_FOR_INPUTS
                  : false;
              return formattedValue === EMPTY_STRING || inRange;
            }}
            InputProps={{
              endAdornment: revisionVal ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle change value visibility"
                    onClick={handleClick}
                    ref={anchorRef}
                  >
                    <HistoryIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />
        )}
      />
      {revisionVal && (
        <InfoPopper
          text={revisionVal}
          onClickAway={handleClickAway}
          open={open}
          anchorEl={anchorRef.current}
        />
      )}
    </>
  );
};

NumericField.defaultProps = {
  className: null,
  variant: "outlined",
  error: false,
  defaultValue: null,
  disabled: false,
  helperText: null,
  thousandSeparator: ".",
  decimalSeparator: ",",
  decimalScale: 2,
  fixedDecimalScale: true,
  fullWidth: false,
  allowNegative: false,
  integer: false,
  prefix: undefined,
  suffix: undefined,
  percentage: false,
  revisionValue: undefined,
};

export default NumericField;
