import { FieldError } from "react-hook-form";
import { EMPTY_STRING } from "shared/constants";

// #region Validation
const numberRegex = /^(\d*\.)?\d+$/;

export function emptyStringToNull(
  value: string,
  originalValue: string
): NullableString {
  if (typeof originalValue === "string" && originalValue === EMPTY_STRING) {
    return null;
  }
  return value;
}

export const testIsNullableNumber = (value?: NullableNumber): boolean =>
  value == null ? true : !!(value + EMPTY_STRING).match(numberRegex);

export const getFieldHasError = (fieldError: unknown): boolean =>
  Array.isArray(fieldError)
    ? Number(fieldError?.length) > 0
    : !!(fieldError as FieldError)?.message;

export const getFieldErrorMessage = (fieldError: unknown): string =>
  Array.isArray(fieldError)
    ? fieldError.find((e) => !!e?.message)?.message
    : (fieldError as FieldError)?.message;

export function nanToNull(value: unknown): NullableNumber {
  if (Number.isFinite(value)) {
    return value as number;
  }
  return null;
}

export function valueToNull(): null {
  return null;
}
// #endregion Validation
