import React, { ReactElement } from "react";

import { Route, RouteProps } from "react-router-dom";
import { useStateMachine } from "little-state-machine";

export interface ClaimRouteProps extends RouteProps {
  rule?: string | string[];
}

export const ClaimRoute = ({
  path,
  children,
  rule,
  ...routeProps
}: ClaimRouteProps): ReactElement | null => {
  const {
    state: { profile },
  } = useStateMachine();

  const { claims } = profile;

  let isVisible = false;
  if (rule instanceof Array) {
    isVisible =
      rule.length !== 0 ? rule?.some((c: string) => claims.includes(c)) : true;
  } else {
    isVisible = rule ? claims.includes(rule) : true;
  }

  return isVisible ? (
    <Route path={path} {...routeProps}>
      {children}
    </Route>
  ) : null;
};

export default ClaimRoute;
