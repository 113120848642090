import React, { ReactElement, ReactNode } from "react";

import clsx from "clsx";
import { NavLinkProps } from "react-router-dom";

import { NavLinkTypeEnum } from "core";

import {
  classes,
  NavLink,
  ListItemText,
  ListItemIcon,
  ListItem,
} from "./styles";

export interface MenuNavItemProps extends NavLinkProps {
  id: string;
  icon: ReactNode;
  title: string;
  isNested?: boolean;
  /**
   * If true link will be clickable even when active
   */
  persist?: boolean;
  rule?: string;
}

export const MenuNavItem = ({
  id,
  icon,
  title,
  isNested = false,
  persist,
  ...linkProps
}: MenuNavItemProps): ReactElement => {
  return (
    <NavLink
      {...linkProps}
      className={classes.item}
      activeClassName={classes.selected}
      type={persist ? NavLinkTypeEnum.native : NavLinkTypeEnum.preventable}
    >
      {(isActive) => (
        <ListItem
          id={id}
          className={clsx(classes.item, {
            [classes.nested]: isNested,
          })}
          selected={isActive}
        >
          <ListItemIcon
            className={clsx(classes.item, {
              [classes.selectedIcon]: isActive,
            })}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            className={clsx({ [classes.selectedText]: isActive })}
            primary={title}
          />
        </ListItem>
      )}
    </NavLink>
  );
};

export default MenuNavItem;
