import React, { ChangeEvent, useEffect, memo, ReactElement } from "react";

import { isEmpty } from "lodash";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  TableBody,
  Toolbar,
} from "@mui/material";

import {
  ClearAll,
  TableFooter,
  TableHeader,
  DynamicFilter,
  TableRowEmpty,
} from "core";
import { ProductStatusSearch, ProductTableRow } from "product/components";

import {
  useFetch,
  useQuery,
  FetchMethodEnum,
  useProfileGetter,
} from "shared/hooks";

import { AgencyEnum } from "product/enums";
import { generateUrl } from "shared/utils";
import { EMPTY_STRING } from "shared/constants";
import { GET_PRODUCTS } from "product/constants";

import { AgencyFilter } from "product/components/AgencyFilter";
import { ProductOverview } from "product/types";
import { Divider } from "shared/styles";
import {
  sortOptions,
  dynamicFilterOptions,
  defaultRowsPerPageOptions,
} from "./config";
import { OverviewResponse, ParamTypes } from "./types";

import { classes, Table } from "./styles";

const { GET } = FetchMethodEnum;

export const ProductOverviewPage = (): ReactElement => {
  const { agencyName } = useParams<ParamTypes>();
  const {
    push,
    location: { search },
  } = useHistory();
  const { url: base } = useRouteMatch();
  const query = useQuery();
  const queryObject = Object.fromEntries(query);

  const {
    page: qPage,
    pageSize: qPageSize,
    sortByColumn,
    isascending,
    productStatus,
    showDefault,
    selectedAgencies,
    ...filters
  } = queryObject;

  const agencyId = AgencyEnum[agencyName];
  const { claims } = useProfileGetter(agencyId) ?? {};
  const canViewAgencyFilter = claims?.includes("CanViewAgencyFilter") ?? false;
  const boolShowDefault = showDefault === "true";

  const areFilterValuesDefault =
    productStatus == null && isEmpty(filters) && !boolShowDefault;
  const isDisabledClearAllButton = canViewAgencyFilter
    ? areFilterValuesDefault && selectedAgencies === "all"
    : areFilterValuesDefault;

  const apiUrl = generateUrl(GET_PRODUCTS, { agencyId });
  const { data, updateParams } = useFetch<OverviewResponse>(apiUrl, {
    method: GET,
    initialParams: { ...queryObject },
  });

  const {
    items = [],
    page = 1,
    pageSize = 10,
    totalItems = 0,
  } = data?.get ?? {};

  // #region Effects
  useEffect(() => {
    // ! it is not possible to use existing queryObject due to refrence change on every render.
    const newQuery = new URLSearchParams(search);
    const newQueryObj = Object.fromEntries(newQuery);
    updateParams(newQueryObj);
  }, [search, updateParams]);
  // #endregion Effects

  // #region Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    const queryPageSize = query.get("pageSize");
    const queryParams = {
      ...queryObject,
      page: newPage + 1,
      pageSize: queryPageSize,
    }; // * newPage +1 reason is TablePagination page is zeroBased
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      pageSize: event.target.value,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Pagination

  // #region Filters
  const handleChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as NullableString | undefined;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      page: 1,
      sortByColumn: targetVal ?? EMPTY_STRING,
      isascending: isAsc,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleProductStatusTypesSearch = (statusId: string) => {
    const currentStatuses = productStatus ? productStatus.split("_") : [];
    const nextStatuses = currentStatuses.includes(statusId)
      ? currentStatuses.filter((s) => s !== statusId)
      : currentStatuses.concat(statusId);
    const queryParams = {
      ...queryObject,
      page: 1,
      productStatus: isEmpty(nextStatuses) ? undefined : nextStatuses.join("_"),
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleClearAll = () => {
    const queryParams = {
      page: 1,
      pageSize: 10,
      sortByColumn: queryObject.sortByColumn,
      isascending: queryObject.isascending,
      showDefault: false,
      ...(canViewAgencyFilter && { selectedAgencies: "all" }),
    };
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl);
  };

  const handleDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      page: 1,
      [key]: dynFilters[key],
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleShowDefault = (checked: boolean) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      showDefault: checked,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleAgencyFilter = (selectedAgencyId: string) => {
    const currentAgencies = selectedAgencies ? selectedAgencies.split("_") : [];
    let nextAgencies = [];
    if (selectedAgencyId === "all") {
      nextAgencies = currentAgencies.includes(selectedAgencyId)
        ? []
        : [selectedAgencyId];
    } else {
      nextAgencies = currentAgencies.includes(selectedAgencyId)
        ? currentAgencies.filter((a) => a !== selectedAgencyId)
        : currentAgencies.filter((a) => a !== "all").concat(selectedAgencyId);
    }
    const queryParams = {
      ...queryObject,
      page: 1,
      selectedAgencies: isEmpty(nextAgencies)
        ? undefined
        : nextAgencies.join("_"),
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Filters

  return (
    <>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <ProductStatusSearch
                  onStatusClick={handleProductStatusTypesSearch}
                  selected={productStatus}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  id="check-showDefault"
                  control={
                    <Checkbox
                      checked={boolShowDefault}
                      onChange={(e) => handleShowDefault(e.target.checked)}
                    />
                  }
                  label="Show Default"
                />
              </Grid>
              <Grid item>
                <Grid container alignContent="flex-start" alignItems="center">
                  <Grid item>
                    <DynamicFilter
                      updateUrl={handleDynamicFilter}
                      queryFilters={filters}
                      filterOptions={dynamicFilterOptions}
                      limit={3}
                    />
                  </Grid>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Grid item>
                    <ClearAll
                      onClear={handleClearAll}
                      disabled={isDisabledClearAllButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignItems="center">
              <AgencyFilter
                selected={selectedAgencies}
                onAgencyClick={handleAgencyFilter}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs={12}>
        <Table size="small" className={classes.table}>
          <TableHeader
            sortOptions={sortOptions}
            sortValue={queryObject.sortByColumn}
            onSortChange={handleChangeSort}
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <TableBody>
            {isEmpty(items) ? (
              <TableRowEmpty />
            ) : (
              items.map((prod: ProductOverview) => (
                <ProductTableRow key={prod.productId} {...prod} />
              ))
            )}
          </TableBody>
          <TableFooter
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </Grid>
    </>
  );
};

export default memo(ProductOverviewPage);
