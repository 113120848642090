import React, { ReactElement, useEffect, useState, MouseEvent } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import { ConfirmationDialog } from "core";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { FetchMethodEnum, useFetch } from "shared/hooks";
import {
  INIT_QUERY_IMPORT_USERS,
  MANAGE_USER_GROUP,
  CREATE_USER_GROUP,
  DELETE_USER_GROUP,
  URL_USER_GROUP_OVERVIEW,
  INIT_OVERVIEW_PAGE,
  DELETE_USER_GROUP_TITLE,
  DELETE_USER_GROUP_CONTENT,
} from "access-manager/constants";
import { generateUrl } from "shared/utils";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { EMPTY_STRING } from "shared/constants";
import { ParamTypes } from "./types";
// import { classes, Root } from "./styles";
import { AccordionSummary as Summary } from "../../../master-data/components/AccordionSummary";
import { AccordionActions as Actions } from "../../../master-data/components/AccordionActions";
import { FormStandard } from "../FormStandard/FormStandard";

const { DELETE } = FetchMethodEnum;

export const UserGroupForm = (): ReactElement => {
  // const classes = useStyles();
  const { userGroupId } = useParams<ParamTypes>();
  const { url } = useRouteMatch();
  const { push, replace } = useHistory();

  const [isExtended, setIsExtended] = useState<boolean>(true);
  const [isInEditing, setIsInEditing] = useState<boolean>(false);
  const [isDeleteDialogOpen, SetIsDeleteDialogOpen] = useState<boolean>(false);
  const [isDeleteGroupButtonDisabled, SetIsDeleteGroupButtonDisabled] =
    useState<boolean>(false);
  const [formParams, setFormParams] = useState<unknown>({
    isNew: !userGroupId,
    getUrl: generateUrl(MANAGE_USER_GROUP, { userGroupId }),
    createUrl: generateUrl(CREATE_USER_GROUP),
    editUrl: generateUrl(MANAGE_USER_GROUP, { userGroupId }),
  });

  const { isSuccessful, executeFetch } = useFetch(null, {
    skip: true,
  });

  const handleAccordionExtending = () => {
    setIsExtended(!isExtended);
    setIsInEditing(false);
  };

  const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    if (isExtended) {
      event.stopPropagation();
      event.preventDefault();
    }

    setIsInEditing(true);
  };

  const handleSave = (id: number) => {
    setIsInEditing(false);

    if (!userGroupId) {
      replace(`${url}/${id}${INIT_QUERY_IMPORT_USERS}`);
    }
  };

  const handleCancel = () => {
    setIsInEditing(false);
  };

  const handleDelete = () => {
    setIsInEditing(false);

    const apiDeleteUserGroupUrl = generateUrl(DELETE_USER_GROUP, {
      userGroupId,
    });
    executeFetch(apiDeleteUserGroupUrl, {
      method: DELETE,
      skip: false,
    });
  };

  useEffect(() => {
    if (!isExtended) {
      setIsInEditing(false);
    }
  }, [isExtended]);

  useEffect(() => {
    if (isSuccessful) {
      const userGroupOverviewPagePath = `/${URL_USER_GROUP_OVERVIEW}${INIT_OVERVIEW_PAGE}`;
      push(userGroupOverviewPagePath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessful]);

  useEffect(() => {
    setFormParams({
      isNew: !userGroupId,
      getUrl: generateUrl(MANAGE_USER_GROUP, { userGroupId }),
      createUrl: generateUrl(CREATE_USER_GROUP),
      editUrl: generateUrl(MANAGE_USER_GROUP, { userGroupId }),
    });
  }, [userGroupId]);

  return (
    <Accordion expanded={isExtended}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="user-group-form-content"
        id="user-group-form-header"
        onClick={handleAccordionExtending}
      >
        <Summary
          title={userGroupId ? "Edit User Group" : "Create User Group"}
          type="user-group-form"
          onEdit={handleEdit}
        />
      </AccordionSummary>
      <AccordionDetails>
        {isExtended && (
          <FormStandard
            type="userGroup"
            isEdit={isInEditing}
            onSave={(id) => handleSave(id)}
            disableDeleteGroupButton={() =>
              SetIsDeleteGroupButtonDisabled(true)
            }
            formParams={formParams}
          />
        )}
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Actions
          type="userGroup"
          isEdit={isInEditing}
          onCancel={handleCancel}
          onDelete={() => SetIsDeleteDialogOpen(true)}
          isDeleteButtonDisabled={
            userGroupId == null ||
            userGroupId === undefined ||
            userGroupId === EMPTY_STRING ||
            isDeleteGroupButtonDisabled
          }
          showDeleteButton={!isInEditing && userGroupId !== undefined}
        />
      </AccordionActions>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          title={DELETE_USER_GROUP_TITLE}
          message={DELETE_USER_GROUP_CONTENT}
          isDisableAction={Boolean(false)}
          onConfirm={handleDelete}
          onCancel={() => SetIsDeleteDialogOpen(false)}
        />
      )}
    </Accordion>
  );
};

export default UserGroupForm;
