import { boolean, number, object, string } from "yup";

import { nanToNull } from "shared/utils";
import { MAX_LENGTH_100_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  hours: number().transform(nanToNull).nullable(),
  media: number().transform(nanToNull).nullable(),
  liquidBudget: boolean().nullable(),
  except: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .nullable(),
});
