import React, { ReactElement } from "react";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";

import { isEmpty } from "lodash";
import { EMPTY_TABLE, PLACEHOLDER } from "shared/constants";
import { documentStatusIcons } from "master-data/components/icons";
import { StatusLog } from "../ClientDocumentStatusLogsDialog/types";

import { classes, TableCell } from "./styles";

export interface StatusLogTableProps {
  /*
   * Status logs history
   */
  logs: StatusLog[];
}

export const StatusLogTable = ({ logs }: StatusLogTableProps): ReactElement => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>
              <Typography variant="subtitle2" align="left" noWrap>
                User
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="subtitle2" align="left" noWrap>
                Old Status
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="subtitle2" align="left" noWrap>
                New Status
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="subtitle2" align="left" noWrap>
                Comment
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="subtitle2" align="left" noWrap>
                Modified
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(logs) ? (
            logs.map((log) => (
              <TableRow key={log.statusLogId}>
                <TableCell align="left" size="small">
                  {log.username}
                </TableCell>
                <TableCell align="left" size="small">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {documentStatusIcons[log.prevStatus] ?? PLACEHOLDER}
                    </Grid>
                    <Grid item>
                      <Typography align="left" noWrap>
                        {log.prevStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" size="small">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {documentStatusIcons[log.status] ?? PLACEHOLDER}
                    </Grid>
                    <Grid item>
                      <Typography align="left" noWrap>
                        {log.status}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" size="small">
                  {log.comment !== null ? (
                    <Tooltip title={log.comment}>
                      <Typography variant="subtitle2">
                        Hover to see comment
                      </Typography>
                    </Tooltip>
                  ) : (
                    PLACEHOLDER
                  )}
                </TableCell>
                <TableCell align="left" size="small">
                  <Typography display="inline" variant="subtitle2">
                    {log.modified}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={17} align="center">
                <Typography align="center" noWrap>
                  {EMPTY_TABLE}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StatusLogTable;
