import { styled, TableRow as MuiTableRow } from "@mui/material";

const PREFIX = "TableRemarkItemStyles";
export const classes = {
  buttonGrid: `${PREFIX}-buttonGrid`,
  button: `${PREFIX}-button`,
};

export const TableRow = styled(MuiTableRow)(() => ({
  [`& .${classes.buttonGrid}`]: {
    textAlign: "right",
  },
  [`& .${classes.button}`]: {
    width: "100%",
    margin: 3,
  },
}));
