export const CHANGE_STATUS_DIALOG_TITLE = "Change Product Status";
export const CHANGE_STATUS_DIALOG_DESCRIPTION =
  "Are you sure you want to change Product status?";

export const CHANGE_STATUS_DIALOG_BUTTON_TEXT = "CHANGE STATUS";

export const CREATE_MARATHON_PRODUCT_DIALOG_DESCRIPTION =
  "In order to create the id please click the button below.";
export const CREATE_MARATHON_PRODUCT_DIALOG_INFO =
  "Marathon Product Id need to be created from MasterData application. ";
export const CREATE_MARATHON_PRODUCT_DIALOG_TITLE = "Create Marathon Product";

// #region URL
export const APP_PRODUCT = "product";
export const DASHBOARD = "dashboard";
export const PRODUCT = "product";
export const PAGE_OVERVIEW = "overview";
export const PAGE_DASHBOARD_PRODUCT = `${DASHBOARD}`;
export const INIT_QUERY_PRODUCTS = "?page=1&pageSize=10&showDefault=false";
export const INIT_QUERY_PRODUCTS_WITH_AGENCY = `${INIT_QUERY_PRODUCTS}&selectedAgencies=all`;
export const AGENCY_PAGES = ":agencyName";
// #endregion URL
