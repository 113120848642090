import React, { ReactElement } from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

import {
  CHANGE_STATUS_DIALOG_TITLE,
  CHANGE_STATUS_DIALOG_BUTTON_TEXT,
  CHANGE_STATUS_DIALOG_DESCRIPTION,
} from "product/constants";
import { CANCEL_LABEL } from "shared/constants/client";

import { Dialog } from "./styles";

export interface CreateDefaultProductDialogProps extends DialogProps {
  /*
   * Callback for canceling dialog
   */
  onCancel: () => void;
  /*
   * Callback for changing Product status
   */
  onChangeStatus: () => void;
  open: boolean;
}

export const StatusDialog = ({
  open,
  onChangeStatus,
  onCancel,
}: CreateDefaultProductDialogProps): ReactElement => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle id="status-dialog-title">
        {CHANGE_STATUS_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent dividers>{CHANGE_STATUS_DIALOG_DESCRIPTION}</DialogContent>
      <DialogActions>
        <Button id="change-status-cancel" onClick={onCancel} color="secondary">
          {CANCEL_LABEL}
        </Button>
        <Button
          onClick={onChangeStatus}
          color="primary"
          id="change-status-submit"
        >
          {CHANGE_STATUS_DIALOG_BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusDialog;
