import React, { ReactElement, useEffect } from "react";

import { useStateMachine } from "little-state-machine";

import { Grid } from "@mui/material";

import { Select, TextField, Autocomplete } from "core";

import {
  CLAIM_EDIT_CREATOR_FIELD,
  CLAIM_EDIT_VALIDATOR_FIELD,
} from "master-data/constants";
import { yesNoOptions } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormAdditionalClientInfoProps extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormAdditionalClientInfo = ({
  isEdit,
  control,
  errors,
  revisionValues,
  setValue,
  fillOption,
}: FormAdditionalClientInfoProps): ReactElement => {
  const {
    state: { profile, cacheData, clientDocumentData },
  } = useStateMachine();

  const { claims } = profile;
  const { isDocumentEditable } = clientDocumentData;
  const isValidatorFieldDisabled = !claims.includes(CLAIM_EDIT_VALIDATOR_FIELD);
  const isCreatorFieldDisabled = !(
    claims.includes(CLAIM_EDIT_CREATOR_FIELD) && isDocumentEditable
  );

  const { enumClientIndustryType } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("intercompanyClient", yesNoOptions[0].key);
      setValue("chamberOfCommerce", "test chamber of commerce");
      setValue("mediaBuying", 10000);
      setValue("vatNumber", 2);
      setValue("highestNetMedia", 150);
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("intercompanyCode", "123");
        setValue("bankRelation", "test bank relation");
        setValue("localDesignatedCode", "456");
        setValue("bankAccountNumber", "789");
        if (enumClientIndustryType[0]) {
          setValue("clientIndustryId", enumClientIndustryType[0].key);
        }
      }
    }
  }, [fillOption, setValue, enumClientIndustryType]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          id="intercompanyClient"
          name="intercompanyClient"
          label="Intercompany Client *"
          options={yesNoOptions}
          error={!!errors.intercompanyClient?.message}
          helperText={errors.intercompanyClient?.message}
          revisionValue={revisionValues?.IntercompanyClient}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="clientIndustryId"
          name="clientIndustryId"
          label="Client Industry"
          options={enumClientIndustryType}
          error={!!errors.clientIndustryId?.message}
          helperText={errors.clientIndustryId?.message}
          revisionValue={revisionValues?.ClientIndustryId}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="intercompanyCode"
          id="intercompanyCode"
          label="Intercompany Code"
          error={!!errors.intercompanyCode?.message}
          helperText={errors.intercompanyCode?.message}
          revisionValue={revisionValues?.IntercompanyCode}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="localDesignatedCode"
          id="localDesignatedCode"
          label="Local / Designated Code"
          error={!!errors.localDesignatedCode?.message}
          helperText={errors.localDesignatedCode?.message}
          revisionValue={revisionValues?.LocalDesignatedCode}
          disabled={!isEdit || isValidatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="chamberOfCommerce"
          id="chamberOfCommerce"
          label="Chamber of Commerce *"
          error={!!errors.chamberOfCommerce?.message}
          helperText={errors.chamberOfCommerce?.message}
          revisionValue={revisionValues?.ChamberOfCommerce}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="vatNumber"
          id="vatNumber"
          label="Vat Number *"
          error={!!errors.vatNumber?.message}
          helperText={errors.vatNumber?.message}
          revisionValue={revisionValues?.VatNumber}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bankRelation"
          id="bankRelation"
          label="Bank Relation / Location"
          error={!!errors.bankRelation?.message}
          helperText={errors.bankRelation?.message}
          revisionValue={revisionValues?.BankRelation}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bankAccountNumber"
          id="bankAccountNumber"
          label="Bank Account Number"
          error={!!errors.bankAccountNumber?.message}
          helperText={errors.bankAccountNumber?.message}
          revisionValue={revisionValues?.BankAccountNumber}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="mediaBuying"
          id="mediaBuying"
          label="Cost Client - Yearly Budget *"
          error={!!errors.mediaBuying?.message}
          helperText={errors.mediaBuying?.message}
          revisionValue={revisionValues?.MediaBuying}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="highestNetMedia"
          id="highestNetMedia"
          label="Highest Cost Client per Month *"
          error={!!errors.highestNetMedia?.message}
          helperText={errors.highestNetMedia?.message}
          revisionValue={revisionValues?.HighestNetMedia}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormAdditionalClientInfo;
