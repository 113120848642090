import { getEnumKeyValues } from "shared/utils";

export enum SchemaDocumentTypeEnum {
  finalizedDocuments = "Finalized Documents",
  editingPhase = "Document In Editing Phase",
  approvalPhase = "Document In Approval Phase",
  processingPhase = "Document In Processing Phase",
}

export enum SchemaDocumentStatusEnum {
  "Finalized Documents" = "Status: Finalized",
  "Document In Editing Phase" = "Statuses: Work In Progress, To Be Changed, Changed, Declined, Finalize Changed",
  "Document In Approval Phase" = "Statuses: Ready for Review, Reviewed, MDT in Progress, MDT checked, Proccessed, Pending Reviewer Approval, Pending Validator Approval, Pending Approver Approval",
  "Document In Processing Phase" = "Statuses: Processing, Approved, Processed Decline",
}

export const MAP_SCHEMA_DOCUMENTS = getEnumKeyValues(SchemaDocumentTypeEnum);
export const MAP_SCHEMA_STATUS_DOCUMENTS = getEnumKeyValues(
  SchemaDocumentStatusEnum
);
