import { INVALID_DATE_VALIDATION_MESSAGE } from "shared/constants";
import { date } from "yup";

const twelveHours = 12 * 60 * 60 * 1000;

const minDate = new Date(1900, 1, 1);
let maxDate = new Date();
maxDate = new Date(maxDate.getTime() + twelveHours);

export const logDateSchema = date()
  .required()
  .typeError(INVALID_DATE_VALIDATION_MESSAGE)
  .min(minDate)
  .max(maxDate);
