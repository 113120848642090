import React, { ReactElement } from "react";
import { Typography, Switch } from "@mui/material";

import { PLACEHOLDER } from "shared/constants";
import { classes, Grid } from "./styles";

export interface SettingProps {
  id: number;
  name: string;
  typeId: number;
  value: boolean;
  onChange: (idSetting: number) => void;
}

export const Setting = ({
  id,
  name,
  value,
  onChange,
}: SettingProps): ReactElement => {
  return (
    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
      <Grid item xs={1} className={classes.grid}>
        <Switch
          id="switchSetting"
          name="switchSetting"
          color="primary"
          onClick={() => onChange(id)}
          checked={value}
        />
      </Grid>
      <Grid item xs={4} className={classes.grid}>
        <Typography variant="subtitle2" noWrap>
          {name ?? PLACEHOLDER}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Setting;
