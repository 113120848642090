import React, { ReactElement } from "react";

import { Tooltip } from "@mui/material";

import { productStatusIcons } from "product/components/icons";

import { EMPTY_STRING } from "shared/constants";
import { classes, IconButton } from "./styles";
import { statuses } from "./config";

export interface ProductStatusSearchProps {
  onStatusClick: (selectedId: string) => void;
  selected: string;
}

export const ProductStatusSearch = ({
  selected,
  onStatusClick,
}: ProductStatusSearchProps): ReactElement => {
  const isSelected = (id: number) => {
    const selectedIds = selected ? selected.split("_") : [];
    return selectedIds.includes(id.toString());
  };

  return (
    <>
      {statuses?.map((status) => (
        <Tooltip key={status.statusId} title={status.description}>
          <IconButton
            className={`${classes.button} ${
              isSelected(status.statusId) ? classes.active : EMPTY_STRING
            }`}
            onClick={() => onStatusClick(status.statusId.toString())}
            id={`filter-productStatus-${status.statusId}`}
          >
            {productStatusIcons[status.description]}
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
};

export default ProductStatusSearch;
