import React, { ReactElement } from "react";

import {
  Accordion,
  StatusDialog,
  AccordionProps,
  ValidationDialog,
} from "master-data/components";

import { RouteProps } from "shared/types";

import { accordions } from "./config";

export const routes = accordions.map(
  ({ sectionType, type, title, rule }: AccordionProps) => {
    return {
      id: `${type}Route`,
      rule,
      type,
      path: `${sectionType}/:accordionType?`,
      component: () => (
        <Accordion sectionType={sectionType} type={type} title={title} />
      ),
    };
  }
);

export const dialogRoutes: RouteProps[] = [
  {
    id: "statusDialogRoute",
    rule: "CanViewDashboardClient",
    path: `status-change`,
    main: (): ReactElement => <StatusDialog />,
  },
  {
    id: "validationDialogRoute",
    rule: "CanViewDashboardClient",
    path: `validation`,
    main: (): ReactElement => <ValidationDialog />,
  },
];
