import { styled, Table as MuiTable } from "@mui/material";
import { white, jordyBlue, baliHai, solitude } from "shared/assets/colors";

const PREFIX = "ImportUsersStyles";
export const classes = {
  table: `${PREFIX}-table`,
  defaultChip: `${PREFIX}-defaultChip`,
  blueChip: `${PREFIX}-blueChip`,
};

export const Table = styled(MuiTable)(() => ({
  [`&.${classes.table}`]: {
    border: `1px solid ${solitude}`,
    borderCollapse: "separate",
    borderSpacing: "5px !important",
  },
  [`& .${classes.defaultChip}`]: {
    backgroundColor: baliHai,
    color: white,
  },
  [`& .${classes.blueChip}`]: {
    backgroundColor: jordyBlue,
    color: white,
  },
}));
