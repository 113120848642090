import { GlobalState } from "little-state-machine";

import { StatusData } from "master-data/types";
import { EMPTY_STRING } from "shared/constants";
import {
  AppState,
  CacheData,
  ProfileData,
  ClientDocumentData,
  GeneralClientInfoData,
  ProductData,
  ProductStatusData,
} from "./types";

export const defaultAppState: AppState = {
  requestQueue: [],
  isLoaded: false,
  showAgencySelectionPage: true,
  showUserManegementPage: false,
  reloadDocumentData: false,
  canViewRevision: false,
  showImportUsersPage: false,
  showDebtorGroupsPage: false,
  isSelectedAMUsers: false,
  isOpenImportSelectedDialog: false,
  isOpenRemoveSelectedDialog: false,
  isOpenFetchUsersDialog: false,
  isOpenAddSelectedUsersDialog: false,
  isOpenRemoveSelectedUsersDialog: false,
  isSelectedDebtors: false,
  isSelectedMissingDebtors: false,
  isOpenAddSelectedDebtorsDialog: false,
  isOpenRemoveSelectedDebtorsDialog: false,
  isSelectedDebtorGroups: false,
  isSelectedUserGroups: false,
  isSelectedApplications: false,
  isOpenAddSelectedMappingsDialog: false,
  isOpenClearSelectedMappingsDialog: false,
  isOpenDeleteMappingDialog: false,
  isOpenUpdateDialog: false,
  isOpenUserProfileDialog: false,
  canSaveUserProfileChanges: false,
  isOpenDeleteClientDocumentDialog: false,
  isOpenImportSelectedMissingDebtorsDialog: false,
};

export const defaultCacheData: CacheData = {
  enumAccordionType: [],
  enumActionType: [],
  enumAgencyType: [],
  enumAgencies: [],
  enumAlternativeCreditInsuranceType: [],
  enumBankName: [],
  enumBusinessUnitType: [],
  enumCalculateInterest: [],
  enumClientDocumentType: [],
  enumClientIndustryType: [],
  enumContractStatus: [],
  enumContractTerm: [],
  enumContractType: [],
  enumCurrency: [],
  enumDutyToReport: [],
  enumEarlyPaymentDiscount: [],
  enumEpdReceived: [],
  enumFiscalYear: [],
  enumFlex: [],
  enumInventoryAvailable: [],
  enumInvoicingPeriod: [],
  enumKpi: [],
  enumLevel: [],
  enumMonth: [],
  enumPassOn: [],
  enumPaymentTerm: [],
  enumRatecard: [],
  enumRedGreenSchedule: [],
  enumReported: [],
  enumReportType: [],
  enumSectionType: [],
  enumApprovalThought: [],
  enumWritingHoursPer: [],
  enumExplanationWrittenHours: [],
  enumFixedItemsFunction: [],
  enumFrequency: [],
  enumTempDistributionMethod: [],
  enumLayoutLanguage: [],
  enumInvoicingMethod: [],
  enumInvoicingDays: [],
  enumInvoicingVat: [],
  enumInvoicingLayout: [],
  enumFeeMediaInvoice: [],
  enumApplicableServices: [],
  enumAuditPeriod: [],
  enumUnbilledMediaReturnPer: [],
  enumUnbilledMediaReturnAfter: [],
};

export const defaultProfileData: ProfileData = {
  claims: [],
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  userAgency: EMPTY_STRING,
  agentForAgencies: [],
  agencyId: null,
  userId: null,
  username: EMPTY_STRING,
};

export const defaultStatusData: StatusData = {
  statusId: null,
  statusName: null,
  previousStatusId: null,
  statusTransitions: [],
  hasComment: false,
};

export const defaultClientDocumentData: ClientDocumentData = {
  documentName: EMPTY_STRING,
  isContract: false,
  isSupplierClients: false,
  isDocumentEditable: false,
  isDocumentStatusChanged: false,
  isVisibleApproveMessage: false,
  userInfo: null,
  changes: null,
  isLatestVersion: true,
  version: 0,
  selectedReviewerId: null,
  marathonClientId: null,
  businessUnitId: null,
  hasDefaultProduct: false,
  isDefaultProductCreationConfirmed: false,
  isActive: false,
};

export const defaultGeneralClientInfoData: GeneralClientInfoData = {
  mailingAddress: EMPTY_STRING,
  mailingCity: EMPTY_STRING,
  mailingCountry: EMPTY_STRING,
  mailingPostalCode: EMPTY_STRING,
  internalClientDocumentName: EMPTY_STRING,
};

export const defaultProductStatusData: ProductStatusData = {
  statusId: null,
  statusName: null,
  statusTransitions: [],
};

export const defaultProductData: ProductData = {
  productId: null,
  productName: EMPTY_STRING,
  documentId: 0,
  documentName: EMPTY_STRING,
  agencyId: 0,
  isProductEditable: false,
  isProductStatusChanged: false,
  marathonClientId: EMPTY_STRING,
  marathonProductId: EMPTY_STRING,
  openStatusDialog: false,
  changeStatusId: null,
  isFormDirty: false,
};

export const defaultGlobalStore: GlobalState = {
  appState: defaultAppState,
  cacheData: defaultCacheData,
  profile: defaultProfileData,
  statusData: defaultStatusData,
  validationStatus: null,
  clientDocumentData: defaultClientDocumentData,
  generalClientInfoData: defaultGeneralClientInfoData,
  productData: defaultProductData,
  productStatusData: defaultProductStatusData,
};
