import React, { ReactElement, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DebtorSyncDetailsSchema } from "back-office/pages/DebtorSync/types";
import { isEmpty } from "lodash";
import { DIALOG_HEIGHT } from "./constants";

interface DebtorSyncDialogProps {
  logs: DebtorSyncDetailsSchema[];
  onCancel: (isOpen: boolean) => void;
}

export const DebtorSyncDialog = ({
  logs = [],
  onCancel,
}: DebtorSyncDialogProps): ReactElement => {
  const [searchedDebtorId, setSearchedDebtorId] = useState<string>("");
  const [filteredLogs, setFilteredLogs] = useState<DebtorSyncDetailsSchema[]>(
    []
  );

  useEffect(() => {
    setFilteredLogs(logs);
  }, [logs]);

  const filterLogs = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    setFilteredLogs(
      logs.filter((log) => log.debtorId.includes(e.currentTarget.value))
    );
    setSearchedDebtorId(e.currentTarget.value);
  };

  return (
    <Dialog
      PaperProps={{ sx: { height: DIALOG_HEIGHT } }}
      open
      maxWidth="sm"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        <TextField
          id="searchByDebtorId"
          name="searchByDebtorId"
          label="Search By DebtorID"
          value={searchedDebtorId}
          onChange={(e) => filterLogs(e)}
        />
      </DialogTitle>
      <DialogContent dividers>
        {!isEmpty(filteredLogs) ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Debtor ID</TableCell>
                <TableCell align="center">Was set to</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLogs.map((log: DebtorSyncDetailsSchema) => {
                return (
                  <TableRow key={log.debtorId}>
                    <TableCell align="center">{log.debtorId}</TableCell>
                    <TableCell align="center">
                      {log.wasSetTo ? "Active" : "Inactive"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Alert severity="info">No Logs for selected sync</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="button-close"
          onClick={() => onCancel(false)}
          color="secondary"
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DebtorSyncDialog;
