import React, { ReactElement } from "react";

import { useStateMachine } from "little-state-machine";

import { Grid } from "@mui/material";
import { CheckField, Select, TextField } from "core";

import { useInitDataGetter } from "shared/hooks";
import { FormStandardFieldProps } from "../props";

export const FormDebtorGroup = ({
  isEdit,
  control,
  errors,
}: FormStandardFieldProps): ReactElement => {
  useInitDataGetter();

  const {
    state: { cacheData },
  } = useStateMachine();

  const { enumAgencies } = cacheData;

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextField
              name="name"
              id="name"
              label="Name *"
              error={!!errors.name?.message}
              helperText={errors.name?.message}
              disabled={!isEdit}
              control={control}
            />
          </Grid>
          <Grid item xs={5}>
            <Select
              excludeDefault
              id="agencyId"
              name="agencyId"
              variant="outlined"
              label="Agency *"
              fullWidth
              error={!!errors.agencyId?.message}
              helperText={errors.agencyId?.message}
              disabled={!isEdit}
              control={control}
              options={enumAgencies}
            />
          </Grid>
          <Grid item xs={2}>
            <CheckField
              control={control}
              disabled={!isEdit}
              name="isDefault"
              id="isDefault"
              label="Mark as Default"
              primary
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid item xs={12}>
          <TextField
            name="description"
            id="description"
            label="Description"
            multiline
            rows={4}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormDebtorGroup;
