import React, { ReactElement } from "react";

import { Info } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { EMPTY_STRING } from "shared/constants";
import { jordyBlue } from "shared/assets/colors";

export interface InfoIconProps {
  /**
   * Tooltip text
   */
  title: string;
}

export const InfoIcon = ({
  title = EMPTY_STRING,
}: InfoIconProps): ReactElement => {
  return (
    <Tooltip title={title}>
      <Info style={{ color: jordyBlue }} />
    </Tooltip>
  );
};

export default InfoIcon;
