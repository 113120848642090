import { styled } from "@mui/material";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "TablePaginationActionsStyles";
export const classes = {
  root: `${PREFIX}-root`,
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexShrink: 0,
    marginLeft: getThemeSpacing(theme, 2.5),
  },
}));
