import React, { ChangeEvent, ReactElement, MouseEvent } from "react";

import {
  Grid,
  TableRow,
  TableCell,
  TablePagination,
  SelectChangeEvent,
} from "@mui/material";

import { Sort, SortOptionType } from "../Sort";

import { TablePaginationActions } from "../TablePaginationActions";

import { classes, TableHead } from "./styles";

export interface TableHeaderProps {
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number
  ) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
  sortValue: string;
  onSortChange: (arg0: SelectChangeEvent<unknown>) => void;
  rowsPerPageOptions: number[];
  sortOptions: SortOptionType[];
  isDisabled?: boolean;
}

export const TableHeader = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  onSortChange,
  sortValue,
  sortOptions,
  isDisabled,
}: TableHeaderProps): ReactElement => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className={classes.sortHeader}>
              <Sort
                sortValue={sortValue}
                onSortChange={onSortChange}
                options={sortOptions}
                isDisabled={isDisabled}
              />
            </Grid>
            {!isDisabled && (
              <Grid item>
                <TablePagination
                  id="pagination"
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
