import { styled, Table as MuiTable, Select as MuiSelect } from "@mui/material";
import {
  white,
  jordyBlue,
  baliHai,
  solitude,
  black,
} from "shared/assets/colors";

const PREFIX = "ManageUserGroupsStyles";
export const classes = {
  defaultChip: `${PREFIX}-defaultChip`,
  blueChip: `${PREFIX}-blueChip`,
  table: `${PREFIX}-table`,
  dropDown: `${PREFIX}-dropDown`,
};

export const Table = styled(MuiTable)(() => ({
  [`&.${classes.table}`]: {
    border: `1px solid ${solitude}`,
    borderCollapse: "separate",
    borderSpacing: "5px !important",
  },
  [`& .${classes.defaultChip}`]: {
    backgroundColor: baliHai,
    color: white,
  },
  [`& .${classes.blueChip}`]: {
    backgroundColor: jordyBlue,
    color: white,
  },
}));

export const Select = styled(MuiSelect)(() => ({
  [`&.${classes.dropDown}`]: {
    color: black,
  },
}));
