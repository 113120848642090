import { GlobalState } from "little-state-machine";
import { ClientDocumentData, GeneralClientInfoData } from "../types";
import {
  defaultClientDocumentData,
  defaultGeneralClientInfoData,
} from "../constants";

export function setClientDocumentData(
  state: GlobalState,
  payload: ClientDocumentData
): GlobalState {
  return {
    ...state,
    clientDocumentData: { ...state.clientDocumentData, ...payload },
  };
}

export function resetClientDocumentData(state: GlobalState): GlobalState {
  return {
    ...state,
    clientDocumentData: defaultClientDocumentData,
  };
}

export function setMarathonClientId(
  state: GlobalState,
  payload: string
): GlobalState {
  return {
    ...state,
    clientDocumentData: {
      ...state.clientDocumentData,
      marathonClientId: payload,
    },
  };
}

export function setHasDefaultProduct(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    clientDocumentData: {
      ...state.clientDocumentData,
      hasDefaultProduct: payload,
    },
  };
}

export function setIsDefaultProductCreationConfirmed(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    clientDocumentData: {
      ...state.clientDocumentData,
      isDefaultProductCreationConfirmed: payload,
    },
  };
}

export function setIsDocumentEditable(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    clientDocumentData: {
      ...state.clientDocumentData,
      isDocumentEditable: payload,
    },
  };
}

export function setIsDocumentStatusChanged(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    clientDocumentData: {
      ...state.clientDocumentData,
      isDocumentStatusChanged: payload,
    },
  };
}

export function setIsContract(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    clientDocumentData: {
      ...state.clientDocumentData,
      isContract: payload,
    },
  };
}

export function setIsSupplierClients(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    clientDocumentData: {
      ...state.clientDocumentData,
      isSupplierClients: payload,
    },
  };
}

export function setGeneralClientInfoMailing(
  state: GlobalState,
  payload: GeneralClientInfoData
): GlobalState {
  return {
    ...state,
    generalClientInfoData: {
      ...payload,
    },
  };
}

export function resetGeneralClientInfoMailing(state: GlobalState): GlobalState {
  return {
    ...state,
    generalClientInfoData: defaultGeneralClientInfoData,
  };
}

export function setRevisionChanges(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    clientDocumentData: {
      ...state.clientDocumentData,
      changes: payload,
    },
  };
}
