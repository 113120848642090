import React, { ReactElement } from "react";
import { Divider, Grid, Typography } from "@mui/material";

import UserAccountIcon from "@mui/icons-material/AccountCircle";
import { toUpper } from "lodash";
import { Overview } from "../../pages/Profile/types";
import { classes, Paper } from "./styles";

export interface UserOverviewPanelProps {
  data: Overview | undefined;
}

export const UserOverviewPanel = ({
  data,
}: UserOverviewPanelProps): ReactElement => {
  const { name, email, agency, admin, agent, agentAgencies, roles } =
    data ?? {};
  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={4}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <UserAccountIcon className={classes.identityIcon} />
              </Grid>
              <Grid item>
                <Typography className={classes.typographyHeaderCaption}>
                  {toUpper(name)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Email:
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" className={classes.typographyCaption}>
              {email}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Agency:
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" className={classes.typographyCaption}>
              {agency}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Roles:
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" className={classes.typographyCaption}>
              {roles}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Admin:
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" className={classes.typographyCaption}>
              {admin}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Agent:
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" className={classes.typographyCaption}>
              {agent}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}>
            <Typography align="left" className={classes.typographyCaption}>
              Agent agencies:
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" className={classes.typographyCaption}>
              {agentAgencies || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default UserOverviewPanel;
