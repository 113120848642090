import React, { ReactElement } from "react";

import { Grid, TableCell, Tooltip, Typography, Checkbox } from "@mui/material";

import { PLACEHOLDER } from "shared/constants";
import { DEBTOR_GROUPS_PREFIX } from "access-manager/constants";
import { classes, TableRow } from "./styles";

export interface DebtorGroupMapTableRowProps {
  id: number;
  name: string;
  description: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onCheckboxClick: (selectedUserGroup: number) => void;
}

export const DebtorGroupMapTableRow = ({
  id,
  name,
  description,
  isChecked,
  isDisabled,
  onCheckboxClick,
}: DebtorGroupMapTableRowProps): ReactElement => {
  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={1}>
            <Checkbox
              id={`${DEBTOR_GROUPS_PREFIX}-check-${id}`}
              size="small"
              inputProps={{ "aria-label": "checkbox with small size" }}
              onChange={() => onCheckboxClick(id)}
              checked={isChecked}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title={name ?? PLACEHOLDER}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {name ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Tooltip
              title={description ?? PLACEHOLDER}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyLabel}
                variant="subtitle2"
              >
                {description}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default DebtorGroupMapTableRow;
