import { AgencyEnum } from "master-data/enums";
import { EMPTY_STRING } from "shared/constants";
import { AgencyRoleFieldsEnum } from "./enums";
import { AgencyRoleFieldsType, IFormInput } from "./types";

export const defaultValues: IFormInput = {
  userId: null,
  lastName: EMPTY_STRING,
  firstName: EMPTY_STRING,
  username: EMPTY_STRING,
  agencyId: null,
  isAdmin: false,
  isAgent: false,
  agentForIds: [],
  roleIds: [],
  isActive: false,
  groupmRoles: [],
  kineticRoles: [],
  mindshareRoles: [],
  essencemediacomRoles: [],
  wavemakerRoles: [],
  blossomRoles: [],
  greenhouseoneRoles: [],
  greenhousetechRoles: [],
  choreographcreateRoles: [],
  userAgencyRoleList: [],
};

export const defaultAgencyRoleFields: {
  [key in AgencyRoleFieldsType]: [];
} = {
  groupmRoles: [],
  kineticRoles: [],
  essencemediacomRoles: [],
  mindshareRoles: [],
  wavemakerRoles: [],
  blossomRoles: [],
  greenhouseoneRoles: [],
  greenhousetechRoles: [],
  choreographcreateRoles: [],
};

export const FORM_ID = "userForm";
export const QUERY_PARAM_MODE = "mode";

export const AgencyIdToFieldName = {
  [AgencyEnum.groupm]: AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.groupmRoles],
  [AgencyEnum.kinetic]: AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.kineticRoles],
  [AgencyEnum.essencemediacom]:
    AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.essencemediacomRoles],
  [AgencyEnum.mindshare]:
    AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.mindshareRoles],
  [AgencyEnum.wavemaker]:
    AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.wavemakerRoles],
  [AgencyEnum.blossom]: AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.blossomRoles],
  [AgencyEnum.greenhouseone]:
    AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.greenhouseoneRoles],
  [AgencyEnum.greenhousetech]:
    AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.greenhousetechRoles],
  [AgencyEnum.choreographcreate]:
    AgencyRoleFieldsEnum[AgencyRoleFieldsEnum.choreographcreateRoles],
};
