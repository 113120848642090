import {
  styled,
  Select as MuiSelect,
  Chip as MuiChip,
  MenuItem as MuiMenuItem,
} from "@mui/material";
import { dodgerBlue, crusta, baliHai } from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils/generalUtils";

const heightChildren = 15;
const paddingTop = 5;
const paddingBottom = 5;
const backgroundColor = dodgerBlue;

const PREFIX = "DynamicFilterStyles";
export const classes = {
  filter: `${PREFIX}-filter`,
  search: `${PREFIX}-search`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  dropDown: `${PREFIX}-dropDown`,
  cancelButton: `${PREFIX}-cancelButton`,
  outlined: `${PREFIX}-outlined`,
  icon: `${PREFIX}-icon`,
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.outlined}`]: {
    backgroundColor: "transparent",
    border: "1px solid ".concat(
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.23)"
        : "rgba(255, 255, 255, 0.23)"
    ),
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.getContrastText(backgroundColor),
    fontSize: theme.typography.pxToRem(3),
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.typography.pxToRem(
      (heightChildren + paddingTop + paddingBottom) / 2
    ),
    height: theme.typography.pxToRem(
      heightChildren + paddingTop + paddingBottom
    ),
    whiteSpace: "nowrap",
    transition: theme.transitions.create(["background-color", "box-shadow"]),
    cursor: "default",
    outline: "none",
    textDecoration: "none",
    paddingTop,
    paddingBottom,
    verticalAlign: "middle",
    boxSizing: "border-box",
  },
  [`& .${classes.search}`]: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
      width: "auto",
    },
    marginRight: theme.typography.pxToRem(10),
  },
  [`& .${classes.inputRoot}`]: {
    height: theme.typography.pxToRem(heightChildren),
    fontSize: theme.typography.pxToRem(12),
  },
  [`& .${classes.inputInput}`]: {
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 5)),
    paddingRight: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 5)),
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    transition: theme.transitions.create("width"),
    color: baliHai,
    [theme.breakpoints.up("sm")]: {
      width: theme.typography.pxToRem(50),
      "&:focus": {
        width: theme.typography.pxToRem(100),
      },
    },
  },
  [`& .${classes.dropDown}`]: {
    minWidth: theme.typography.pxToRem(95),
    marginLeft: theme.typography.pxToRem(15),
    height: theme.typography.pxToRem(heightChildren),
    fontSize: theme.typography.pxToRem(12),
  },
  [`& .${classes.cancelButton}`]: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(7),
    color: crusta,
  },
  [`& .${classes.icon}`]: {
    fontSize: theme.typography.pxToRem(getThemeSpacing(theme, 2)),
  },
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  [`&.${classes.filter}`]: {
    maxWidth: theme.typography.pxToRem(310),
    height: theme.typography.pxToRem(24),
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  [`& .${classes.icon}`]: {
    fontSize: theme.typography.pxToRem(getThemeSpacing(theme, 2)),
  },
}));

export const Select = styled(MuiSelect)(({ theme }) => ({
  [`&.${classes.dropDown}`]: {
    minWidth: theme.typography.pxToRem(95),
    marginLeft: theme.typography.pxToRem(15),
    height: theme.typography.pxToRem(heightChildren),
    fontSize: theme.typography.pxToRem(12),
  },
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
}));
