import { object, number, boolean, string } from "yup";
import {
  NUMBER_FIELD_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  unbilledMediaReturn: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().notRequired().transform(nanToNull),
  }),
  percetagePassedOn: number()
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .when("unbilledMediaReturn", {
      is: 1,
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().notRequired().transform(nanToNull),
    }),
  includingFee: number().when("unbilledMediaReturn", {
    is: 1,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().notRequired().transform(nanToNull),
  }),
  otherUnbilledMediaReturnAfter: string().when("unbilledMediaReturnAfterId", {
    is: 4,
    then: string()
      .trim()
      .nullable()
      .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
      .required(MANDATORY_FILED_VALIDATION_MESSAGE),
    otherwise: string().nullable().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  }),
  additionalConditionsInContract: boolean().nullable(),
});
