import { styled, TableHead as MuiTableHead } from "@mui/material";

const PREFIX = "TableHeaderStyles";
export const classes = {
  tableCell: `${PREFIX}-tableCell`,
  sortHeader: `${PREFIX}-sortHeader`,
};

export const TableHead = styled(MuiTableHead)(() => ({
  [`& .${classes.tableCell}`]: {
    border: "none",
  },
  [`& .${classes.sortHeader}`]: {
    margin: "0px 0px 0px 15px",
  },
}));
