import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  flexId: 1,
  startDateFlex: null,
  xaxisInventory: null,
  xaxisDateSigned: null,
  xaxisContractEndDate: null,
  otherInventory: null,
  otherDateSigned: null,
  otherContractEndDate: null,
  xaxisInventoryAvailableId: null,
  otherInventoryAvailableId: null,
  additionalConditionsInContract: null,
  contractReferenceAndOrText: null,
};
