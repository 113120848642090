import React, { ReactElement } from "react";

import { useStateMachine } from "little-state-machine";

import { Button } from "@mui/material";

import { setChangeStatusId, setOpenStatusDialog } from "store/actions";

import { classes, Grid } from "./styles";

export const ProductDashboardActions = (): ReactElement => {
  const {
    actions,
    state: {
      productStatusData,
      productData: { isFormDirty },
    },
  } = useStateMachine({
    setOpenStatusDialog,
    setChangeStatusId,
  });

  const { statusTransitions } = productStatusData;

  const handleChangeStatus = (statusId: NullableNumber) => {
    actions.setOpenStatusDialog(true);
    actions.setChangeStatusId(statusId);
  };

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item>
        {statusTransitions &&
          statusTransitions.length > 0 &&
          statusTransitions.map(({ statusId: sId, statusName }) => (
            <Button
              id={`approve_${sId}`}
              key={sId}
              color="primary"
              variant="contained"
              className={classes.approveButton}
              classes={{ disabled: classes.disabledButton }}
              onClick={() => handleChangeStatus(sId)}
              disabled={isFormDirty}
            >
              {statusName}
            </Button>
          ))}
      </Grid>
    </Grid>
  );
};

export default ProductDashboardActions;
