import React, { useEffect, ReactElement } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, TextField, Autocomplete } from "core";

import { EMPTY_STRING, MANDATORY_LABEL, yesNoOptions } from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormAuditProps extends FormStandardFieldProps {
  /**
   * watch
   */
  watch: any;
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormAudit = ({
  isEdit,
  control,
  errors,
  revisionValues,
  watch,
  setValue,
  fillOption,
}: FormAuditProps): ReactElement => {
  const {
    state: { clientDocumentData, cacheData },
  } = useStateMachine();

  const { isContract } = clientDocumentData;
  const { enumAuditPeriod } = cacheData;

  const auditPeriodWatch = watch("auditPeriodId");

  const isOtherAuditPeriodOptionDisabled =
    (typeof auditPeriodWatch === "number" && auditPeriodWatch !== 3) ||
    !auditPeriodWatch;

  // #region Effects
  useEffect(() => {
    if (isOtherAuditPeriodOptionDisabled) {
      setValue("otherAuditPeriod", EMPTY_STRING, { shouldValidate: true });
    }
  }, [setValue, isOtherAuditPeriodOptionDisabled]);

  useEffect(() => {
    if (fillOption) {
      if (
        isContract ||
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillAll
      ) {
        setValue("rightToFinanceAudit", yesNoOptions[0].key);
      }
      if (
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillAll
      ) {
        setValue("accountants", "Accountants - Test");
        setValue(
          "otherAuditPeriod",
          isOtherAuditPeriodOptionDisabled
            ? EMPTY_STRING
            : "Other Audit Period - Test"
        );
        setValue("rightToMediaAudit", yesNoOptions[0].key);
        if (enumAuditPeriod[2]) {
          setValue("auditPeriodId", enumAuditPeriod[2].key);
        }
      }
    }
  }, [
    fillOption,
    setValue,
    isContract,
    enumAuditPeriod,
    isOtherAuditPeriodOptionDisabled,
  ]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          id="rightToFinanceAudit"
          name="rightToFinanceAudit"
          label={`Right To Financial Audit
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.rightToFinanceAudit?.message}
          helperText={errors.rightToFinanceAudit?.message}
          revisionValue={revisionValues?.RightToFinanceAudit}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="auditPeriodId"
          name="auditPeriodId"
          label="Audit Period"
          options={enumAuditPeriod}
          error={!!errors.auditPeriodId?.message}
          helperText={errors.auditPeriodId?.message}
          revisionValue={revisionValues?.AuditPeriodId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Accountants"
          id="accountants"
          name="accountants"
          error={!!errors.accountants?.message}
          helperText={errors.accountants?.message}
          revisionValue={revisionValues?.Accountants}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="otherAuditPeriod"
          id="otherAuditPeriod"
          label="Other Audit Period"
          error={!!errors.otherAuditPeriod?.message}
          helperText={errors.otherAuditPeriod?.message}
          revisionValue={revisionValues?.OtherAuditPeriod}
          disabled={!isEdit || isOtherAuditPeriodOptionDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="rightToMediaAudit"
          name="rightToMediaAudit"
          label="Right To Media Audit"
          error={!!errors.rightToMediaAudit?.message}
          helperText={errors.rightToMediaAudit?.message}
          revisionValue={revisionValues?.RightToMediaAudit}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
    </Grid>
  );
};

export default FormAudit;
