import React, { ReactElement, useState } from "react";

import { Link } from "react-router-dom";
import {
  CardMedia,
  Typography,
  CardContent,
  CardActionArea,
  Grid,
} from "@mui/material";

import { classes, MuiCard } from "./styles";

export interface CardLinkProps {
  /**
   * Card identifier
   */
  id: string;
  /**
   * Used for navigation
   * @extends Link react-router-dom
   */
  to: string;
  /**
   * If given, card will show image instead of title text
   * @file needs to be .png format
   * @default undefined
   */
  image?: typeof import("*.png");
  /**
   * Title of card
   */
  title: string;
  /**
   * Subtitle of card
   * @default null
   */
  subtitle?: string;
  /**
   * If true, card is not be clickable
   * @default false
   */
  disabled?: boolean;
}

export const CardLink = ({
  to,
  id,
  image,
  title,
  disabled,
  subtitle,
}: CardLinkProps): ReactElement => {
  const [elevation, setElevation] = useState(disabled ? 0 : 1);

  const handleMouseEnter = () => {
    if (!disabled) {
      setElevation(8);
    }
  };
  const handleMouseLeave = () => {
    if (!disabled) {
      setElevation(1);
    }
  };

  return (
    <MuiCard
      id={id}
      elevation={elevation}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardActionArea
        to={to}
        component={Link}
        disabled={disabled}
        className={classes.actionArea}
      >
        {image ? (
          <CardMedia
            title={title}
            image={image.default}
            className={classes.media}
          />
        ) : (
          <CardContent
            container
            component={Grid}
            direction="column"
            className={classes.content}
          >
            <Grid item>
              <Typography noWrap align="center" variant="h6" component="h2">
                {title}
              </Typography>
              {subtitle && (
                <Typography noWrap variant="caption" color="textSecondary">
                  {subtitle}
                </Typography>
              )}
            </Grid>
          </CardContent>
        )}
      </CardActionArea>
    </MuiCard>
  );
};

CardLink.defaultProps = {
  subtitle: null,
  disabled: false,
  image: undefined,
};

export default CardLink;
