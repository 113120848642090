import React from "react";
import clsx from "clsx";
import { useStateMachine } from "little-state-machine";
import { Grid } from "@mui/material";
import { Divider } from "shared/styles";

import { AgencyType } from "product/types";
import { AgencyEnum, AgencyNameEnum } from "shared/enums";
import { classes, ChipButton } from "./styles";
import { Agency } from "./types";

export interface AgencyFilterProps {
  selected: string;
  onAgencyClick: (selectedId: string) => void;
}

export const AgencyFilter = ({
  onAgencyClick,
  selected,
}: AgencyFilterProps) => {
  const {
    state: { profile },
  } = useStateMachine();

  const { claims, agentForAgencies } = profile;

  const isVisible = claims.includes("CanViewAgencyFilter");
  const agencies = agentForAgencies.map((name: string): Agency => {
    const agencyType = name.toLowerCase() as AgencyType;
    return {
      agencyId: AgencyEnum[agencyType],
      agencyName: AgencyNameEnum[agencyType],
    };
  });

  const isSelected = (id: string) => {
    const selectedIds = selected ? selected.split("_") : [];
    return selectedIds.includes(id);
  };

  return isVisible ? (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <ChipButton
          id="button-agency-all"
          label="All Agencies"
          className={clsx(classes.button, {
            [classes.active]: isSelected("all"),
          })}
          onClick={() => onAgencyClick("all")}
        />
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem />
      {agencies.map(({ agencyName, agencyId }: Agency) => {
        return (
          <Grid item key={agencyId}>
            <ChipButton
              id={`button-agency-${agencyId}`}
              label={agencyName}
              className={clsx(classes.button, {
                [classes.active]: isSelected(agencyId.toString()),
              })}
              onClick={() => onAgencyClick(agencyId.toString())}
            />
          </Grid>
        );
      })}
    </Grid>
  ) : null;
};

export default AgencyFilter;
