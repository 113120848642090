import React, { ReactElement, useState } from "react";

import { Button } from "@mui/material";
import { Link, useRouteMatch } from "react-router-dom";

import { SplitButton } from "core";

import { getCacheDb } from "./config";

import { Grid } from "./styles";

export const UserManagementActions = (): ReactElement => {
  const { path, url } = useRouteMatch();
  const [cacheDb, setCacheDb] = useState("security");

  const handleCacheButtonClick = (option: string) => {
    setCacheDb(getCacheDb(option));
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <SplitButton
          to={`${url}/clear-cache/${cacheDb}`}
          options={["Clear Security Cache", "Clear Client Document Cache"]}
          onChange={handleCacheButtonClick}
        />
      </Grid>
      <Grid item>
        <Button
          id="button-add-user"
          to={`${path}/user-form?mode=create`}
          component={Link}
          color="primary"
          variant="contained"
        >
          ADD USER
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserManagementActions;
