import { EMPTY_STRING } from "shared/constants";

import { createFilterOptions } from "@mui/material";

import { IDocumentFormInput } from "master-data/types";

export const filter = createFilterOptions<CreatableOptionType>();

export const EDIT_MODE = "edit";
export const FORM_ID = "createClientForm";

export const defaultValues: IDocumentFormInput = {
  concern: null,
  documentTypeId: EMPTY_STRING,
  documentName: EMPTY_STRING,
  agencyId: 0,
  marathonClientId: EMPTY_STRING,
  isLatestVersion: true,
  version: 0,
  selectedReviewerId: null,
  businessUnitId: null,
  hasDefaultProduct: false,
  isDefaultProductCreationConfirmed: false,
  isActive: false,
};

export const SUPPLIER_CLIENT_WARNING_MESSAGE =
  'Please make sure that the Document Type "Supplier Client" is correct. After the client is created it\'s not possible to change the Document Type back to "Letter of Intent" or "Contract".';
