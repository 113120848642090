import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { TextField, Autocomplete } from "core";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormAdmanagementProps extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;

  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormAdmanagement = ({
  isEdit,
  control,
  errors,
  revisionValues,
  setValue,
  fillOption,
}: FormAdmanagementProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const { enumRatecard } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("admanagementTool", "AD Management Tool - Test");
      setValue("contractReference", "Contract Reference - Test");
      if (enumRatecard[0]) {
        setValue("ratecardId", enumRatecard[0].key);
      }
    }
  }, [fillOption, setValue, enumRatecard]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextField
          name="admanagementTool"
          id="admanagementTool"
          label="Ad Management Tool(s)"
          error={!!errors.admanagementTool?.message}
          helperText={errors.admanagementTool?.message}
          revisionValue={revisionValues?.AdmanagementTool}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="ratecardId"
          name="ratecardId"
          label="Ratecard"
          options={enumRatecard}
          error={!!errors.ratecardId?.message}
          helperText={errors.ratecardId?.message}
          revisionValue={revisionValues?.RatecardId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="contractReference"
          id="contractReference"
          label="Contract Reference and/or Text"
          multiline
          rows={4}
          error={!!errors.contractReference?.message}
          helperText={errors.contractReference?.message}
          revisionValue={revisionValues?.ContractReference}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormAdmanagement;
