import React, { useEffect, ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { Switch, useRouteMatch } from "react-router-dom";

import { AgencyRoute as Route } from "product/components";

import { getPath } from "shared/utils";
import { APP_MASTERDATA } from "master-data/constants";
import { resetProfile } from "store/actions";
import { routes } from "./routes";

export const ProductPages = (): ReactElement => {
  const { path: base } = useRouteMatch();

  const { actions } = useStateMachine({ resetProfile });

  useEffect(() => {
    return () => {
      // TODO: Need to change url (master-data/document; master-data/product)
      if (!base.search(APP_MASTERDATA)) actions.resetProfile(undefined);
    };
  }, [actions, base]);

  return (
    <>
      <Switch>
        {routes.map(({ path, main: Content, id, exact }) => {
          const routePath = getPath(base, path);
          return (
            <Route key={id} path={routePath} exact={exact}>
              <Content />
            </Route>
          );
        })}
      </Switch>
    </>
  );
};

export default ProductPages;
