import React, { ReactElement } from "react";

import {
  MenuItem,
  TextField,
  IconButton,
  TextFieldProps,
  InputAdornment,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { History as HistoryIcon } from "@mui/icons-material";

import { EMPTY_STRING, REVISION_INIT_VALUE } from "shared/constants";

import { InfoPopper } from "../InfoPopper";

export interface ISelect {
  /**
   * Array of options.
   */
  options?: OptionType[];
  /**
   * control.
   */
  control: Control;
  /**
   * Name attribute of the input element.
   */
  name: string;
  /**
   * If true "None" option from dropdown will be removed.
   */
  excludeDefault?: boolean;
  /**
   * Previous value of field
   */
  revisionValue?: number | null | boolean;
}

export type SelectProps = TextFieldProps & ISelect;

const getSelectProps = (selectProps: any) => {
  return {
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    },
    ...selectProps,
  };
};

export const Select = ({
  name,
  control,
  variant,
  fullWidth,
  options = [],
  defaultValue,
  revisionValue,
  excludeDefault,
  ...otherProps
}: SelectProps): ReactElement => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  let revisionVal: string | undefined;
  if (revisionValue === null) {
    revisionVal = REVISION_INIT_VALUE;
  }
  if (typeof revisionValue === "number") {
    revisionVal = options.find((o) => o.key === revisionValue)?.value;
  }

  if (typeof revisionValue === "boolean") {
    revisionVal = revisionValue ? "Yes" : "No";
  }

  return (
    <>
      <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            {...field}
            {...otherProps}
            variant={variant}
            fullWidth={fullWidth}
            InputProps={{
              ...otherProps.InputProps,
              endAdornment: revisionVal ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle change value visibility"
                    onClick={handleClick}
                    ref={anchorRef}
                  >
                    <HistoryIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
            value={field.value ?? EMPTY_STRING}
            select
            SelectProps={getSelectProps(otherProps.SelectProps)}
          >
            {!excludeDefault && (
              <MenuItem value={EMPTY_STRING}>
                <em>None</em>
              </MenuItem>
            )}
            {options.map(({ key, value }: OptionType) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        name={name}
        control={control}
      />
      {revisionVal && (
        <InfoPopper
          text={revisionVal}
          onClickAway={handleClickAway}
          open={open}
          anchorEl={anchorRef.current}
        />
      )}
    </>
  );
};

Select.defaultProps = {
  variant: "outlined",
  fullWidth: true,
  options: [],
  revisionValue: undefined,
  excludeDefault: false,
};

export default Select;
