import React, { ReactElement } from "react";
import { Grid, TableCell, TableRow, Typography } from "@mui/material";

export interface TableRowEmptyProps {
  title?: string;
}

export const TableRowEmpty = ({ title }: TableRowEmptyProps): ReactElement => {
  return (
    <TableRow hover>
      <TableCell>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography align="center" variant="subtitle2">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

TableRowEmpty.defaultProps = {
  title: "No item to display",
};

export default TableRowEmpty;
