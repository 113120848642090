import React, { useEffect, ReactElement } from "react";

import clsx from "clsx";
import { useStateMachine } from "little-state-machine";
import { Link, useParams, useRouteMatch, useHistory } from "react-router-dom";

import { Button } from "@mui/material";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import {
  VALIDATE_CLAIM,
  VALIDATE_DOCUMENT,
  DECLINE_SEARCH_TEXT,
  CEO_APPROVER_CLAIM,
  CLAIM_EDIT_CREATOR_FIELD,
  CLAIM_EDIT_VALIDATOR_FIELD,
  MANAGE_CLIENT_DOCUMENT,
  DELETE_CLIENT_DOCUMENT_DIALOG_TITLE,
  DELETE_CLIENT_DOCUMENT_DIALOG_CONTENT,
  APP_MASTERDATA,
  PAGE_OVERVIEW,
  DELETE_DOCUMENT_CLAIM,
} from "master-data/constants";
import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import {
  setValidationStatus,
  setIsOpenDeleteClientDocumentDialog,
} from "store/actions";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";

import { ConfirmationDialog } from "core";
import { INIT_QUERY } from "shared/constants";
import { IValidateResponsePost } from "../FooterBar/types";
import {
  approvalStatuses,
  validateableStatuses,
  deletableStatuses,
} from "../FooterBar/config";

import { classes, Grid } from "./styles";

const { POST, DELETE } = FetchMethodEnum;
const defaultBody = {};

export const ClientDocumentDashboardActions = (): ReactElement => {
  const { url } = useRouteMatch();
  const { agencyName, documentId } = useParams<ParamTypes>();
  const { push } = useHistory();

  const {
    actions,
    state: { appState, statusData, profile },
  } = useStateMachine({
    setIsOpenDeleteClientDocumentDialog,
    setValidationStatus,
  });

  const { isOpenDeleteClientDocumentDialog } = appState;

  const { data, executeFetch, resetPostData } = useFetch<
    undefined,
    IValidateResponsePost
  >(null, {
    skip: true,
  });

  const { claims } = profile;

  const { statusTransitions, statusId } = statusData;
  const { post: postData } = data ?? {};
  const { isSuccessful } = postData ?? {};

  const isCreator = claims.includes(CLAIM_EDIT_CREATOR_FIELD);
  const isValidator = claims.includes(CLAIM_EDIT_VALIDATOR_FIELD);
  const isAdmin = isValidator && isCreator;

  const isValidateable =
    (statusId ? validateableStatuses.includes(statusId) : false) &&
    claims?.includes(VALIDATE_CLAIM);
  const isDeleteable = statusId
    ? deletableStatuses.includes(statusId) &&
      claims?.includes(DELETE_DOCUMENT_CLAIM)
    : false;

  // TODO: This condition needs to be adjusted with the "Allow Agency Approval" setting (not currently used)
  const isAllowAgencyApproval =
    (statusId ? approvalStatuses.includes(statusId) : false) &&
    (!isAdmin ? claims?.includes(CEO_APPROVER_CLAIM) : false);

  const declinedAction =
    statusTransitions &&
    statusTransitions.filter((st) =>
      st.statusName.includes(DECLINE_SEARCH_TEXT)
    );

  const statusActions =
    statusTransitions &&
    statusTransitions.filter(
      (st) => !st.statusName.includes(DECLINE_SEARCH_TEXT)
    );

  const handleValidation = () => {
    const agencyId = AgencyEnum[agencyName];

    const validationUrl = generateUrl(VALIDATE_DOCUMENT, {
      agencyId,
      documentId,
    });
    executeFetch(validationUrl, {
      method: POST,
      body: defaultBody,
      notify: false,
      skip: false,
    });
  };

  const onDelete = () => {
    actions.setIsOpenDeleteClientDocumentDialog(true);
    resetPostData();
  };

  const handleDelete = () => {
    const agencyId = AgencyEnum[agencyName];

    const deleteClientDocumentUrl = generateUrl(MANAGE_CLIENT_DOCUMENT, {
      agencyId,
      documentId,
    });
    executeFetch(deleteClientDocumentUrl, {
      method: DELETE,
      skip: false,
    });
  };

  useEffect(() => {
    if (postData) {
      actions.setValidationStatus(postData.status);
    }
    return () => {
      actions.setValidationStatus(null);
    };
  }, [actions, postData]);

  useEffect(() => {
    if (isSuccessful && isOpenDeleteClientDocumentDialog) {
      push(`/${APP_MASTERDATA}/${agencyName}/${PAGE_OVERVIEW}${INIT_QUERY}`);
      actions.setIsOpenDeleteClientDocumentDialog(false);
    }
  }, [
    push,
    actions,
    isSuccessful,
    agencyName,
    isOpenDeleteClientDocumentDialog,
  ]);

  return (
    <>
      <Grid container justifyContent="flex-start">
        <Grid item>
          {isAdmin && isDeleteable && (
            <Button
              id="delete"
              onClick={onDelete}
              className={clsx(classes.saveButton, classes.deleteButton)}
            >
              DELETE
            </Button>
          )}
        </Grid>
        <Grid item>
          {isValidateable && (
            <Button
              id="validate"
              onClick={handleValidation}
              className={clsx(classes.saveButton, classes.validateButton)}
            >
              VALIDATE
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          {!isAllowAgencyApproval &&
            declinedAction &&
            declinedAction.length > 0 && (
              <Button
                id="decline"
                component={Link}
                className={clsx(classes.saveButton, classes.declineButton)}
                to={`${url}/status-change?statusId=${declinedAction[0].statusId}&declined=true`}
              >
                DECLINED
              </Button>
            )}
          {!isAllowAgencyApproval &&
            statusActions &&
            statusActions.length > 0 &&
            statusActions.map(({ statusId: sId, statusName }) => (
              <Button
                key={sId}
                component={Link}
                id={`approve_${sId}`}
                to={`${url}/status-change?statusId=${sId}&declined=false`}
                className={clsx(classes.saveButton, classes.approveButton)}
              >
                {statusName}
              </Button>
            ))}
        </Grid>
      </Grid>
      {isOpenDeleteClientDocumentDialog && (
        <ConfirmationDialog
          open={isOpenDeleteClientDocumentDialog}
          title={DELETE_CLIENT_DOCUMENT_DIALOG_TITLE}
          message={DELETE_CLIENT_DOCUMENT_DIALOG_CONTENT}
          isDisableAction={Boolean(false)}
          onConfirm={handleDelete}
          onCancel={() => actions.setIsOpenDeleteClientDocumentDialog(false)}
        />
      )}
    </>
  );
};

export default ClientDocumentDashboardActions;
