import { styled, Table as MuiTable } from "@mui/material";
import { solitude } from "shared/assets/colors";

const PREFIX = "ActionOverviewStyles";
export const classes = {
  table: `${PREFIX}-table`,
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`,
  tableOverviewRow: `${PREFIX}-tableOverviewRow`,
  sortHeader: `${PREFIX}-sortHeader`,
  footer: `${PREFIX}-footer`,
};

export const Table = styled(MuiTable)(({ theme }) => ({
  [`&.${classes.table}`]: {
    border: `1px solid ${solitude}`,
    borderCollapse: "separate",
    borderSpacing: "5px !important",
  },
  [`& .${classes.tableRow}`]: {
    height: theme.typography.pxToRem(70),
  },
  [`& .${classes.tableCell}`]: {
    minHeight: "2.25rem",
  },
  [`& .${classes.tableOverviewRow}`]: {
    minHeight: "2.25rem",
  },
  [`& .${classes.sortHeader}`]: {
    margin: "0px 0px 0px 15px",
  },
  [`& .${classes.footer}`]: {
    margin: "0px 0px 0px 1370px",
  },
}));
