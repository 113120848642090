import React, { ReactElement, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { PLACEHOLDER } from "shared/constants";
import { SectionType } from "master-data/types";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import { classes, Paper } from "./styles";

export interface SectionProps {
  /**
   * Text to be shown as main title
   */
  title?: string;
  /**
   * Optional text to be shown as subtitle
   */
  subtitle?: string;
  /**
   * type of section. It can be one of:
   * [client, contract, remuneration, scope, invoicing, report, various, redGreen]
   */
  type: SectionType;
}

export const Section = ({
  title = PLACEHOLDER,
  subtitle = PLACEHOLDER,
  type,
}: SectionProps): ReactElement => {
  const [elevation, setElevation] = useState(1);
  const { sectionType } = useParams<ParamTypes>();

  const isActive = type === sectionType;

  const handleMouseEnter = () => {
    if (isActive) return;
    setElevation(3);
  };
  const handleMouseLeave = () => {
    if (isActive) return;
    setElevation(1);
  };

  useEffect(() => {
    if (isActive) {
      setElevation(10);
    } else {
      setElevation(1);
    }
  }, [isActive]);

  return (
    <Paper
      elevation={elevation}
      className={classes.paper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Typography className={classes.typographyTitle} variant="body1">
            {title}
          </Typography>
          <Typography className={classes.typographySubtitle} variant="body2">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Section;
