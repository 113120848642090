import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "SettingStyles";
export const classes = {
  grid: `${PREFIX}-grid`,
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.grid}`]: {
    marginTop: theme.typography.pxToRem(10),
  },
}));
