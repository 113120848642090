import React, { ReactElement, useEffect, useState } from "react";

import { useStateMachine } from "little-state-machine";
import { FormHelperText, Grid } from "@mui/material";

import {
  ConfirmationPopper,
  FormDialog,
  TableEntries,
  Select,
  CheckField,
} from "core";
import {
  EMPTY_STRING,
  yesNoOptions,
  MANDATORY_LABEL,
  MANDATORY_ENTRY_VALIDATION_MESSAGE,
} from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormFinancialClientIncentiveEntry } from "../FinancialClientIncentiveEntry";

import { Current, IRevisionEntriesInput, IRevisionInput } from "./types";
import { FormEntriesFieldProps } from "../props";
import { columns } from "./config";

export interface FormFinancialClientIncentiveProps
  extends FormEntriesFieldProps {
  /**
   * revisionValues
   * */
  revisionValues: IRevisionInput;
  /**
   * active item
   */
  current: Current;
  /**
   * revisionValuesFormDialog
   */
  revisionValuesFormDialog: IRevisionEntriesInput;
  /**
   * errors
   * */
  errors: any;
  /**
   * revisionValueIds
   */
  revisionValueIds: string[];
  /**
   * onEdit
   */
  onEdit: (newCurrent: Current) => void;
  /**
   * watch
   */
  watch: any;
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormFinancialClientIncentive = ({
  isEdit,
  errors,
  errorsFormDialog,
  control,
  revisionValues,
  current,
  fields,
  onAdd,
  onEdit,
  onDelete,
  onDialogCancel,
  onDialogSave,
  clearErrors,
  isNewEntry,
  revisionValuesFormDialog,
  isDialogOpen,
  watch,
  setValue,
  revisionValueIds,
  fillOption,
}: FormFinancialClientIncentiveProps): ReactElement => {
  const [kpiApplyIsActive, setKpiApplyIsActive] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const {
    state: { clientDocumentData, cacheData },
  } = useStateMachine();

  const [dialogFillOption, setDialogFillOption] =
    useState<FillFormOptionType>();

  const { isContract } = clientDocumentData;

  const kpiApply = watch("kpiApply");
  const isKpiApplyEnabled = typeof kpiApply === "number" && kpiApply === 1;

  // #region Effects
  useEffect(() => {
    setKpiApplyIsActive(isEdit && isKpiApplyEnabled);
  }, [isEdit, isKpiApplyEnabled]);

  useEffect(() => {
    if (isEdit && kpiApplyIsActive && !isKpiApplyEnabled) {
      setConfirmationOpen(true);
    }
  }, [kpiApplyIsActive, isKpiApplyEnabled, isEdit]);

  useEffect(() => {
    if (!isEdit) {
      setConfirmationOpen(false);
    }
  }, [isEdit]);

  useEffect(() => {
    if (fillOption) {
      if (
        isContract ||
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillAll
      ) {
        setValue("clientIncetive", yesNoOptions[0].key);
      }
      if (fillOption === FillFormOptionType.fillAllAndSave) {
        setValue("kpiApply", yesNoOptions[0].key);
      }
      if (fillOption === FillFormOptionType.fillAll) {
        setValue("kpiApply", yesNoOptions[1].key);
      }
      if (
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillAll
      ) {
        setValue("chargeCancellationCosts", yesNoOptions[0].key);
        setValue("additionalConditionsInContract", true);
      }
    }
  }, [fillOption, setValue, isContract]);
  // #endregion Effects

  const handleClickAway = () => {
    setValue("kpiApply", 1);
    setConfirmationOpen(false);
  };

  const handleConfirm = async () => {
    setValue("entries", []);
    setKpiApplyIsActive(false);
    setConfirmationOpen(false);
  };

  const { enumKpi } = cacheData;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Select
            id="clientIncetive"
            name="clientIncetive"
            label={`
              Client Incentive ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
            error={!!errors.clientIncetive?.message}
            helperText={errors.clientIncetive?.message}
            revisionValue={revisionValues?.ClientIncetive}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={6} ref={anchorRef}>
          <Select
            id="kpiApply"
            name="kpiApply"
            label="KPI's Applicable"
            error={!!errors.kpiApply?.message}
            helperText={errors.kpiApply?.message}
            revisionValue={revisionValues?.KpiApply}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            id="chargeCancellationCosts"
            name="chargeCancellationCosts"
            label="Charge Cancellation Costs"
            error={!!errors.chargeCancellationCosts?.message}
            helperText={errors.chargeCancellationCosts?.message}
            revisionValue={revisionValues?.ChargeCancellationCosts}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckField
            control={control}
            disabled={!isEdit}
            name="additionalConditionsInContract"
            label="Additional Conditions In Contract"
            revisionValue={revisionValues?.AdditionalConditionsInContract}
          />
        </Grid>
        <Grid item xs={12}>
          <FormHelperText
            id="table-helper-text"
            error={!!errors.entries}
            hidden={!isKpiApplyEnabled}
          >
            {MANDATORY_ENTRY_VALIDATION_MESSAGE}
          </FormHelperText>
          <TableEntries
            onAdd={onAdd}
            onEdit={onEdit}
            onDelete={onDelete}
            rows={fields}
            isEdit={isEdit}
            isAddBttnDisabled={!isKpiApplyEnabled}
            control={control}
            columns={columns}
            revisionValueIds={revisionValueIds}
            fieldOptions={{
              kpiId: enumKpi,
            }}
          />
        </Grid>
      </Grid>
      <FormDialog
        isEdit={isEdit}
        isNew={isNewEntry}
        open={isDialogOpen}
        onCancel={onDialogCancel}
        onSave={onDialogSave}
        onClose={() => clearErrors("entries")}
        showFillButton={
          isEdit && process.env.REACT_APP_SHOW_FILL_BUTTON === "true"
        }
        showFillMandatoryOption
        onFill={setDialogFillOption}
      >
        <FormFinancialClientIncentiveEntry
          control={control}
          revisionValues={revisionValuesFormDialog}
          current={current}
          isEdit={isEdit}
          errors={errorsFormDialog}
          setValue={setValue}
          fillOption={dialogFillOption}
          resetFillOption={setDialogFillOption}
          onDialogSave={onDialogSave}
        />
      </FormDialog>
      <ConfirmationPopper
        open={confirmationOpen}
        anchorEl={anchorRef.current}
        message="Existing entries will be deleted. Proceed?"
        primaryButtonLabel="Yes"
        secondaryButtonLabel="Cancel"
        onClickAway={handleClickAway}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default FormFinancialClientIncentive;
