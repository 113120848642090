import React, { ReactElement } from "react";

import { useParams } from "react-router-dom";
import { Grid, TableCell, TableRow, Typography } from "@mui/material";

import { Uploader } from "core";
import { FileChip } from "master-data/components";

import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import {
  DELETE_LEGAL_DOCUMENTS_CHECK_ATTACHMENT,
  UPLOAD_LEGAL_DOCUMENTS_CHECK_ATTACHMENT,
  DOWNLOAD_LEGAL_DOCUMENTS_CHECK_ATTACHMENT,
} from "master-data/constants";
import { CheckType } from "../../types";

export interface TableCheckItemProps extends CheckType {
  isEditable: boolean;
  refetch: () => void;
}

export const TableCheckItem = ({
  name,
  checkId,
  refetch,
  isEditable,
  attachments,
}: TableCheckItemProps): ReactElement => {
  const { agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography>{name}</Typography>
            </Grid>
            <Grid item xs={8}>
              {attachments.map((file) => (
                <FileChip
                  key={file.fileId}
                  {...file}
                  checkId={checkId}
                  isEditable={isEditable}
                  onDelete={refetch}
                  downloadUrl={generateUrl(
                    DOWNLOAD_LEGAL_DOCUMENTS_CHECK_ATTACHMENT,
                    {
                      agencyId,
                      documentId,
                      checkId,
                      fileId: file.fileId,
                    }
                  )}
                  deleteUrl={generateUrl(
                    DELETE_LEGAL_DOCUMENTS_CHECK_ATTACHMENT,
                    {
                      agencyId,
                      documentId,
                      checkId,
                      fileId: file.fileId,
                    }
                  )}
                />
              ))}
            </Grid>
            <Grid item xs={1}>
              {isEditable && (
                <Uploader
                  uploadUrl={generateUrl(
                    UPLOAD_LEGAL_DOCUMENTS_CHECK_ATTACHMENT,
                    {
                      documentId,
                      agencyId: AgencyEnum[agencyName],
                      checkId,
                    }
                  )}
                  onUploadCallback={refetch}
                  rowId={checkId}
                />
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableCheckItem;
