import React, { ReactElement } from "react";
import {
  Grid,
  TableCell,
  Tooltip,
  Typography,
  Checkbox,
  Chip,
} from "@mui/material";

import { EMPTY_STRING, PLACEHOLDER } from "shared/constants";
import clsx from "clsx";
import { AgencyNameEnum, DebtorStatusEnum } from "shared/enums";
import { classes, TableRow } from "./styles";

export interface DebtorTableRowProps {
  id: number;
  name: string;
  type: string;
  concernName: string;
  agencyName: string;
  marathonId: string;
  isActive: boolean;
  status: number;
  isChecked: boolean;
  isDisabled?: boolean;
  onCheckboxClick: (selectedDebtor: number) => void;
}

export const DebtorTableRow = ({
  id,
  name,
  type,
  concernName,
  agencyName,
  marathonId,
  isActive,
  status,
  isChecked,
  isDisabled,
  onCheckboxClick,
}: DebtorTableRowProps): ReactElement => {
  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item xs={1}>
            <Checkbox
              id={`check-${id}`}
              size="small"
              inputProps={{ "aria-label": "checkbox with small size" }}
              onChange={() => onCheckboxClick(id)}
              checked={isChecked}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Name
            </Typography>
            <Tooltip title={name} enterDelay={250} placement="bottom-start">
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {name ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Type
            </Typography>
            <Tooltip title={type} enterDelay={250} placement="bottom-start">
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {type ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Concern Name
            </Typography>
            <Tooltip title={concernName} placement="bottom-start">
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {concernName ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Agency
            </Typography>
            <Tooltip
              title={
                AgencyNameEnum[
                  agencyName.toLowerCase() as keyof typeof AgencyNameEnum
                ] || EMPTY_STRING
              }
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {AgencyNameEnum[
                  agencyName.toLowerCase() as keyof typeof AgencyNameEnum
                ] ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Marathon Debtor Id
            </Typography>
            <Tooltip title={marathonId} placement="bottom-start">
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {marathonId ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Active/Inactive
            </Typography>
            <Tooltip
              title={isActive ? "Active" : "Inactive"}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {isActive ? "Active" : "Inactive" ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          {status !== 0 && (
            <Grid item xs={1}>
              <Chip
                label={DebtorStatusEnum[status]}
                className={clsx(classes.defaultChip, {
                  [classes.greenChip]: status === DebtorStatusEnum.added,
                })}
              />
            </Grid>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default DebtorTableRow;
