import { array, number, boolean, object, string } from "yup";

import {
  EMAIL_FORM_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";

export const schema = object().shape({
  userId: number().nullable(),
  lastName: string().trim().required(MANDATORY_FILED_VALIDATION_MESSAGE),
  firstName: string().trim().required(MANDATORY_FILED_VALIDATION_MESSAGE),
  username: string()
    .trim()
    .email(EMAIL_FORM_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  agencyId: number().nullable().required(MANDATORY_FILED_VALIDATION_MESSAGE),
  isAdmin: boolean(),
  isAgent: boolean(),
  agentForIds: array().of(number()),
  roleIds: array()
    .of(number())
    .default([])
    .when(["isActive", "isAdmin"], {
      is: (isActive: boolean, isAdmin: boolean) => isActive && !isAdmin,
      then: array().of(number()).min(1, MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: array().of(number()).notRequired(),
    }),
  isActive: boolean(),
  groupmRoles: array().of(number()),
  kineticRoles: array().of(number()),
  mindshareRoles: array().of(number()),
  essencemediacomRoles: array().of(number()),
  wavemakerRoles: array().of(number()),
  blossomRoles: array().of(number()),
  greenhouseoneRoles: array().of(number()),
  greenhousetechRoles: array().of(number()),
  choreographcreateRoles: array().of(number()),
});

export const companyOptions: OptionType[] = [
  { key: 1, value: "GroupM" },
  { key: 5, value: "Kinetic" },
  { key: 2, value: "Mindshare" },
  { key: 6, value: "EssenceMediacom" },
  { key: 7, value: "Wavemaker" },
  { key: 8, value: "Blossom" },
  { key: 9, value: "Greenhouse One" },
  { key: 10, value: "Greenhouse Tech" },
  { key: 11, value: "Choreograph Create" },
];

export const roleOptions: OptionType[] = [
  { key: 1, value: "Viewer" },
  { key: 2, value: "ViewerLimited" },
  { key: 3, value: "Creator" },
  { key: 4, value: "Validator" },
  { key: 5, value: "MarathonValidator" },
  { key: 6, value: "Approver" },
  { key: 7, value: "Reviewer" },
];

export const agentForIdsOptions: OptionType[] = [
  { key: 1, value: "GroupM" },
  { key: 5, value: "Kinetic" },
  { key: 2, value: "Mindshare" },
  { key: 6, value: "EssenceMediacom" },
  { key: 7, value: "Wavemaker" },
  { key: 8, value: "Blossom" },
  { key: 9, value: "Greenhouse One" },
  { key: 10, value: "Greenhouse Tech" },
  { key: 11, value: "Choreograph Create" },
];
