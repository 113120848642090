import React, { ReactElement } from "react";
import {
  Button,
  Grid,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { PLACEHOLDER } from "shared/constants";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { setIsOpenDeleteMappingDialog } from "store/actions";
import { useStateMachine } from "little-state-machine";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  INIT_QUERY_DEBTORS,
  INIT_QUERY_IMPORT_USERS,
  URL_MANAGE_DEBTOR_GROUP,
  URL_MANAGE_USER_GROUP,
} from "access-manager/constants";
import { AgencyNameEnum } from "shared/enums";
import clsx from "clsx";
import { classes, TableRow } from "./styles";

export interface MappingTableRowProps {
  debtorGroupId: number;
  debtorGroupName: string;
  debtorGroupDescription: string;
  userGroupId: number;
  userGroupName: string;
  userGroupDescription: string;
  applicationId: string;
  applicationName: string;
  agencyDebtorGroupName: string;
  onDelete: (
    applicationId: string,
    userGroupId: number,
    debtorGroup: number
  ) => void;
}

export const MappingTableRow = ({
  userGroupId,
  userGroupName,
  userGroupDescription,
  debtorGroupId,
  debtorGroupName,
  debtorGroupDescription,
  applicationId,
  applicationName,
  agencyDebtorGroupName,
  onDelete,
}: MappingTableRowProps): ReactElement => {
  const { actions } = useStateMachine({
    setIsOpenDeleteMappingDialog,
  });

  const rowId = `${applicationId}_${userGroupId}_${debtorGroupId}`;

  const handleDelete = () => {
    actions.setIsOpenDeleteMappingDialog(true);
    onDelete(applicationId, userGroupId, debtorGroupId);
  };

  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item xs={2}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Application
            </Typography>
            <Typography
              className={classes.typographyValue}
              variant="subtitle2"
              noWrap
            >
              {applicationName ?? PLACEHOLDER}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              className={clsx(
                classes.typographyLabel,
                classes.typographyLabelLink
              )}
              variant="subtitle2"
            >
              User Group
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
            >
              <Tooltip
                title={`Description: ${userGroupDescription ?? PLACEHOLDER}`}
                placement="bottom-start"
              >
                <Typography
                  className={classes.typographyValue}
                  variant="subtitle2"
                  noWrap
                >
                  {userGroupName ?? PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Link
                target="_blank"
                to={`/${URL_MANAGE_USER_GROUP}/${userGroupId}${INIT_QUERY_IMPORT_USERS}`}
              >
                <IconButton
                  name={`button-UserGroupPage-${rowId}`}
                  id={`button-UserGroupPage-${rowId}`}
                  size="small"
                  className={classes.hoverText}
                >
                  <LaunchIcon className={classes.icon} />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography
              className={clsx(
                classes.typographyLabel,
                classes.typographyLabelLink
              )}
              variant="subtitle2"
            >
              Debtor Group
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
            >
              <Tooltip
                title={`Description: ${debtorGroupDescription ?? PLACEHOLDER}`}
                placement="bottom-start"
              >
                <Typography
                  className={classes.typographyValue}
                  variant="subtitle2"
                  noWrap
                >
                  {debtorGroupName ?? PLACEHOLDER}
                </Typography>
              </Tooltip>
              <Link
                target="_blank"
                to={`/${URL_MANAGE_DEBTOR_GROUP}/${debtorGroupId}${INIT_QUERY_DEBTORS}`}
              >
                <IconButton
                  name={`button-DebtorGroupPage-${rowId}`}
                  id={`button-DebtorGroupPage-${rowId}`}
                  size="small"
                  className={classes.hoverText}
                >
                  <LaunchIcon className={classes.icon} />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Agency
            </Typography>
            <Tooltip title="Debtor Group Agency" placement="bottom-start">
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {AgencyNameEnum[
                  agencyDebtorGroupName.toLowerCase() as keyof typeof AgencyNameEnum
                ] ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Button
              id={`button-delete-${rowId}`}
              color="primary"
              onClick={handleDelete}
            >
              <DeleteForeverIcon />
            </Button>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default MappingTableRow;
