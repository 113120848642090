import { styled } from "@mui/material";

const PREFIX = "CheckFieldStyles";
export const classes = {
  label: `${PREFIX}-label`,
  checkbox: `${PREFIX}-checkbox`,
  checkboxDisabled: `${PREFIX}-checkboxDisabled`,
};

export const Div = styled("div")(() => ({
  [`&.${classes.label}`]: {
    color: "rgba(0, 0, 0, 0.54)",
    display: "inline",
  },
}));
