import React from "react";

import { array, number, object, string } from "yup";
import { Select as MuiSelect, MenuItem } from "@mui/material";

import {
  InputCell,
  ColumnType,
  NumericCell,
  CellTypeEnum,
  RenderCellProps,
} from "core";

import {
  EMPTY_STRING,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MAX_NUMBER_FOR_INPUTS,
  MAX_NUMBER_VALIDATION_MESSAGE,
  MIN_NUMBER_FOR_INPUTS,
  NUMBER_FIELD_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull, testIsNullableNumber } from "shared/utils";

// #region columns definition
export const columns: ColumnType[] = [
  {
    field: "id",
    headerName: "Id",
    hide: true,
    cellType: CellTypeEnum.Input,
    renderCell: () => <InputCell />,
  },
  {
    field: "levelId",
    headerName: "Level",
    cellType: CellTypeEnum.Select,
    renderCell: ({ field, options = [] }: RenderCellProps) => (
      <MuiSelect
        disabled
        {...field}
        IconComponent={() => <></>}
        SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
      >
        <MenuItem value={EMPTY_STRING}>
          <em>None</em>
        </MenuItem>
        {options.map(({ key, value }: OptionType) => {
          return (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          );
        })}
      </MuiSelect>
    ),
  },
  {
    field: "role",
    headerName: "Role",
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "hourlyRate",
    headerName: "Hourly Rate",
    cellType: CellTypeEnum.NumberFormat,
    renderCell: ({ field }: RenderCellProps) => <NumericCell {...field} />,
  },
];
// #endregion columns definition

const formSchema = {
  id: number().transform(nanToNull).nullable(),
  levelId: number().nullable().transform(nanToNull).notRequired(),
  hourlyRate: number()
    .transform(nanToNull)
    .test("is-number", NUMBER_FIELD_VALIDATION_MESSAGE, (value) =>
      testIsNullableNumber(value)
    )
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .nullable()
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .notRequired(),
  role: string()
    .trim()
    .nullable()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .notRequired(),
};

export const validationSchema = object().shape({
  staffplanRetainer: number()
    .nullable()
    .transform(nanToNull)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  outOfScopeHours: number()
    .nullable()
    .transform(nanToNull)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  hoursReportRequired: number().nullable().transform(nanToNull).notRequired(),
  reportTypeId: number().nullable().notRequired().transform(nanToNull),
  hoursOnly: number()
    .nullable()
    .transform(nanToNull)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  entries: array().of(object().shape(formSchema)),
  hoursYearlyInflation: number().nullable().transform(nanToNull).notRequired(),
});
