import React, { ReactElement } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";

import { useFetch, FetchMethodEnum, useQuery } from "shared/hooks";

import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { GET_DECLINE_COMMENTS } from "master-data/constants";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";

import { LastDeclineDetails, CommentHistoryTable } from "./components";

import { DeclineCommentResponse } from "./types";

export const DeclineCommentDialog = (): ReactElement => {
  const { goBack } = useHistory();
  const query = useQuery();

  const { GET } = FetchMethodEnum;
  const { agencyName, documentId: paramId } = useParams<ParamTypes>();

  const documentId = paramId ?? query.get("id");
  const agencyId = AgencyEnum[agencyName];

  const url = generateUrl(GET_DECLINE_COMMENTS, { agencyId, documentId });
  const { data, isLoading } = useFetch<DeclineCommentResponse>(url, {
    method: GET,
  });

  const { comments = [] } = data?.get ?? {};

  return (
    <Dialog open maxWidth="md" fullWidth scroll="paper">
      <DialogTitle id="validation-dialog-title">Declined Details</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <LastDeclineDetails comment={comments[0]} />
            <Typography variant="h6" gutterBottom>
              Declined comment history
            </Typography>
            <CommentHistoryTable comments={comments} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => goBack()} color="primary">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeclineCommentDialog;
