import React, { ReactElement } from "react";

import { Skeleton, IconButton, Typography } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, TextField, TouchTooltip } from "core";

import { yesNoOptions } from "shared/constants";

import { defaultValues } from "./constants";
import { FormStandardFieldProps } from "../props";

import { classes, Grid } from "./styles";

export const FormRevenueRecognition = ({
  isEdit,
  control,
  errors,
  revisionValues,
}: FormStandardFieldProps): ReactElement => {
  const InputLabelProps = { classes };

  const {
    state: { clientDocumentData },
  } = useStateMachine();

  const { isSupplierClients } = clientDocumentData;

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="feeBasedArrangement"
          name="feeBasedArrangement"
          variant="outlined"
          label="Fee based Arrangement (including capped T&M) *"
          fullWidth
          error={!!errors.feeBasedArrangement?.message}
          helperText={errors.feeBasedArrangement?.message}
          revisionValue={revisionValues?.FeeBasedArrangement}
          disabled={!isEdit || isSupplierClients}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption">Over time (POC) recognition</Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="feeBasedArrangement"
          title="Fixed Project Fee, as per an agreed Qoute, for deliverables defined in a scope of work i.e. DCO"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="revenuRecognizedOverTime"
          id="revenuRecognizedOverTime"
          label="If revenue is recognized as over time (POC), is satisfaction measured by an input or output method?"
          error={!!errors.revenuRecognizedOverTime?.message}
          helperText={errors.revenuRecognizedOverTime?.message}
          revisionValue={revisionValues?.RevenuRecognizedOverTime}
          disabled
          InputLabelProps={InputLabelProps}
          control={control}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h3">
          <Skeleton animation={false} />
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="revenuRecognizedOverTime"
          title="Output = measure of progress of transfer of asset to Client, % stage of completion of the total product/service Input = resources and materials used to date to create the asset being transfered to Client"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="retainerEntitysEfforts"
          name="retainerEntitysEfforts"
          variant="outlined"
          label="Retainer entitty's efforts or inputs expended evenly throughout the performance period or the entity has a stand-ready *"
          fullWidth
          error={!!errors.retainerEntitysEfforts?.message}
          helperText={errors.retainerEntitysEfforts?.message}
          revisionValue={revisionValues?.RetainerEntitysEfforts}
          disabled={!isEdit || isSupplierClients}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>

      <Grid item xs={1}>
        <Typography variant="caption">Straight line recognition</Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="retainerEntitysEfforts"
          title="Billed Monthly Fee, per agreed Resource Titles % of time, dedicated to the Account"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="mediaCommissions"
          name="mediaCommissions"
          variant="outlined"
          label="Media Commissions *"
          fullWidth
          error={!!errors.mediaCommissions?.message}
          helperText={errors.mediaCommissions?.message}
          revisionValue={revisionValues?.MediaCommissions}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption">Point in time recognition</Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="mediaCommissions"
          title="Commision earned on media purchased"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="tradingIncome"
          name="tradingIncome"
          variant="outlined"
          label="Trading Income *"
          fullWidth
          error={!!errors.tradingIncome?.message}
          helperText={errors.tradingIncome?.message}
          revisionValue={revisionValues?.TradingIncome}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption">Point in time recognition</Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="tradingIncome"
          title="Sales per Media Inventory, Content Buying & Selling, MVD, Adserving, Consultancy & Research from Suppliers, EPD"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="clientIncentives"
          name="clientIncentives"
          variant="outlined"
          label="Client Incentives *"
          fullWidth
          error={!!errors.clientIncentives?.message}
          helperText={errors.clientIncentives?.message}
          revisionValue={revisionValues?.ClientIncentives}
          disabled={!isEdit || isSupplierClients}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption">Point in time recognition</Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="clientIncentives"
          title="Revenue earned per performance, i.e. bonus fee per achieved criteria"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          If below revenue recognition methods might be applicable for this
          client contract please contact Finance for consultation before
          completion
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="timeAndMaterial"
          name="timeAndMaterial"
          variant="outlined"
          label="Time & Material (T&M) Un-Capped Arrangement *"
          fullWidth
          error={!!errors.timeAndMaterial?.message}
          helperText={errors.timeAndMaterial?.message}
          revisionValue={revisionValues?.TimeAndMaterial}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption">Point in time recognition</Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="timeAndMaterial"
          title="Non-Fixed Project Fee, as per an agreed Qoute, for Deliverables defined in a Scope of Work, i.e. charging all the hours and materials"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="shortTermFeeBasedArrangement"
          name="shortTermFeeBasedArrangement"
          variant="outlined"
          label="Short term fee Based Arrangement (including capped T&M) where the practical expedient is being selected *"
          fullWidth
          error={!!errors.shortTermFeeBasedArrangement?.message}
          helperText={errors.shortTermFeeBasedArrangement?.message}
          revisionValue={revisionValues?.ShortTermFeeBasedArrangement}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption">Point in time recognition</Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="shortTermFeeBasedArrangement"
          title="Rarely used if applicable. Relief for adoption of IFRS 16"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="otherRetainer"
          name="otherRetainer"
          variant="outlined"
          label="Other Retainer *"
          fullWidth
          error={!!errors.otherRetainer?.message}
          helperText={errors.otherRetainer?.message}
          revisionValue={revisionValues?.OtherRetainer}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption">Over time (POC) recognition</Typography>
      </Grid>
      <Grid item xs={1}>
        <TouchTooltip
          id="otherRetainer"
          title="If not per stand ready retainer above"
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          excludeDefault
          id="other"
          name="other"
          variant="outlined"
          label="Other *"
          fullWidth
          error={!!errors.other?.message}
          helperText={errors.other?.message}
          revisionValue={revisionValues?.Other}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
          InputLabelProps={InputLabelProps}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography variant="caption">Assess on a one off basis</Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton disabled>
          <Skeleton
            variant="circular"
            animation={false}
            width={24}
            height={24}
          />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="remarks"
          id="remarks"
          label="Remarks"
          multiline
          rows={4}
          error={!!errors.remarks?.message}
          helperText={errors.remarks?.message}
          defaultValue={defaultValues.remarks}
          revisionValue={revisionValues?.Remarks}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormRevenueRecognition;
