import React, { useEffect, ReactElement } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import {
  Select,
  CheckField,
  NumericField,
  Autocomplete,
  TextField,
} from "core";

import { EMPTY_STRING, MANDATORY_LABEL, yesNoOptions } from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormFinancialUnbilledMediaProps
  extends FormStandardFieldProps {
  /**
   * watch
   */
  watch: any;
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormFinancialUnbilledMedia = ({
  isEdit,
  control,
  errors,
  revisionValues,
  watch,
  setValue,
  fillOption,
}: FormFinancialUnbilledMediaProps): ReactElement => {
  const {
    state: { clientDocumentData, cacheData },
  } = useStateMachine();

  const { isContract } = clientDocumentData;

  const { enumUnbilledMediaReturnAfter, enumUnbilledMediaReturnPer } =
    cacheData;

  const unbilledMediaReturnAfterWatch = watch("unbilledMediaReturnAfterId");

  const isOtherUnbilledMediaReturnAfterOptionDisabled =
    (typeof unbilledMediaReturnAfterWatch === "number" &&
      unbilledMediaReturnAfterWatch !== 4) ||
    !unbilledMediaReturnAfterWatch;

  // #region Effects
  useEffect(() => {
    if (isOtherUnbilledMediaReturnAfterOptionDisabled) {
      setValue("otherUnbilledMediaReturnAfter", EMPTY_STRING, {
        shouldValidate: true,
      });
    }
  }, [setValue, isOtherUnbilledMediaReturnAfterOptionDisabled]);

  useEffect(() => {
    if (fillOption) {
      if (
        fillOption === FillFormOptionType.fillMandatory ||
        fillOption === FillFormOptionType.fillMandatoryAndSave
      ) {
        setValue("unbilledMediaReturn", yesNoOptions[0].key);
      } else {
        setValue("unbilledMediaReturn", yesNoOptions[1].key);
      }

      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("percetagePassedOn", 1);
        setValue(
          "otherUnbilledMediaReturnAfter",
          "Other Unbilled Media Return After - Test"
        );
        setValue("includingFee", yesNoOptions[0].key);
        setValue("additionalConditionsInContract", true);
        if (enumUnbilledMediaReturnAfter[3]) {
          setValue(
            "unbilledMediaReturnAfterId",
            enumUnbilledMediaReturnAfter[3].key
          );
        }
        if (enumUnbilledMediaReturnPer[0]) {
          setValue(
            "unbilledMediaReturnPerId",
            enumUnbilledMediaReturnPer[0].key
          );
        }
      }
    }
  }, [
    fillOption,
    setValue,
    isContract,
    enumUnbilledMediaReturnAfter,
    enumUnbilledMediaReturnPer,
  ]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          id="unbilledMediaReturn"
          name="unbilledMediaReturn"
          label={`Unbilled Media Returned
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.unbilledMediaReturn?.message}
          helperText={errors.unbilledMediaReturn?.message}
          revisionValue={revisionValues?.UnbilledMediaReturn}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="UnbilledMediaReturnAfterId"
          name="unbilledMediaReturnAfterId"
          label="Unbilled Media To Be Returned After"
          options={enumUnbilledMediaReturnAfter}
          error={!!errors.unbilledMediaReturnAfterId?.message}
          helperText={errors.unbilledMediaReturnAfterId?.message}
          revisionValue={revisionValues?.UnbilledMediaReturnAfterId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="percetagePassedOn"
          name="percetagePassedOn"
          label="Percentage Passed on"
          fullWidth
          disabled={!isEdit}
          error={!!errors.percetagePassedOn?.message}
          helperText={errors.percetagePassedOn?.message}
          revisionValue={revisionValues?.PercetagePassedOn}
          control={control}
          percentage
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="otherUnbilledMediaReturnAfter"
          id="otherUnbilledMediaReturnAfter"
          label="Other Unbilled Media To Be Returned After"
          error={!!errors.otherUnbilledMediaReturnAfter?.message}
          helperText={errors.otherUnbilledMediaReturnAfter?.message}
          revisionValue={revisionValues?.OtherUnbilledMediaReturnAfter}
          disabled={!isEdit || isOtherUnbilledMediaReturnAfterOptionDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="includingFee"
          name="includingFee"
          label="Including Admanagement / Tech Fee"
          error={!!errors.includingFee?.message}
          helperText={errors.includingFee?.message}
          revisionValue={revisionValues?.IncludingFee}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="unbilledMediaReturnPerId"
          name="unbilledMediaReturnPerId"
          label="Unbilled Media To Be Returned Per"
          options={enumUnbilledMediaReturnPer}
          error={!!errors.unbilledMediaReturnPerId?.message}
          helperText={errors.unbilledMediaReturnPerId?.message}
          revisionValue={revisionValues?.UnbilledMediaReturnPerId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <CheckField
          name="additionalConditionsInContract"
          label="Additional Conditions In Contract"
          revisionValue={revisionValues?.AdditionalConditionsInContract}
          control={control}
          disabled={!isEdit}
        />
      </Grid>
    </Grid>
  );
};

export default FormFinancialUnbilledMedia;
