import React, { useEffect, ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { Switch, useRouteMatch } from "react-router-dom";

import { AgencyRoute as Route } from "master-data/components";

import { APP_PRODUCT } from "product/constants";
import { getPath } from "shared/utils";
import { resetProfile } from "store/actions";
import { routes, dialogRoutes } from "./routes";

export const AgencyPages = (): ReactElement => {
  const { path: base } = useRouteMatch();

  const { actions } = useStateMachine({ resetProfile });

  useEffect(() => {
    return () => {
      if (!base.search(APP_PRODUCT)) actions.resetProfile(undefined);
    };
  }, [actions, base]);

  return (
    <>
      <Switch>
        {routes.map(({ path, main: Content, id, exact }) => {
          const routePath = getPath(base, path);
          return (
            <Route key={id} path={routePath} exact={exact}>
              <Content />
            </Route>
          );
        })}
      </Switch>
      {dialogRoutes.map(({ path, main: Content, id, exact }) => {
        const routePath = getPath(base, path);
        return (
          <Route key={id} path={routePath} exact={exact}>
            {({ match }) => {
              const isActive = Boolean(match);
              return isActive && <Content />;
            }}
          </Route>
        );
      })}
    </>
  );
};

export default AgencyPages;
