import { styled, Grid as MuiGrid } from "@mui/material";
import { dodgerBlue, jordyBlue } from "shared/assets/colors";

const PREFIX = "GeneralClientInfoStyles";
export const classes = {
  prefillButton: `${PREFIX}-prefillButton`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.prefillButton}`]: {
    color: jordyBlue,
    "&:hover": {
      color: dodgerBlue,
    },
  },
}));
