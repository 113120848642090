import { GlobalState } from "little-state-machine";

import { StatusData, ValidationStatusType } from "master-data/types";
import { ProductStatusData } from "store/types";

import { defaultProductStatusData, defaultStatusData } from "../constants";

export function setDocumentStatus(
  state: GlobalState,
  payload: StatusData
): GlobalState {
  return {
    ...state,
    statusData: payload,
  };
}

export function resetDocumentStatus(state: GlobalState): GlobalState {
  return {
    ...state,
    statusData: defaultStatusData,
  };
}

export function setProductStatus(
  state: GlobalState,
  payload: ProductStatusData
): GlobalState {
  return {
    ...state,
    productStatusData: payload,
  };
}

export function resetProductStatus(state: GlobalState): GlobalState {
  return {
    ...state,
    productStatusData: defaultProductStatusData,
  };
}

export function setValidationStatus(
  state: GlobalState,
  payload: ValidationStatusType | null
): GlobalState {
  return {
    ...state,
    validationStatus: payload,
  };
}
