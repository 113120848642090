import React, { ReactElement } from "react";
import {
  ClickAwayListener,
  Grow,
  Paper,
  PopperProps,
  Typography,
  Alert,
} from "@mui/material";

import { classes, Popper } from "./styles";

export interface InfoPopperProps extends PopperProps {
  text: string;
  onClickAway: () => void;
}

export const InfoPopper = ({
  open,
  text,
  anchorEl,
  onClickAway,
}: Omit<InfoPopperProps, "children">): ReactElement => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      className={classes.popper}
      nonce
      onResize
      onResizeCapture
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClickAway}>
              <Alert variant="outlined" severity="info">
                <Typography className={classes.typography}>{text}</Typography>
              </Alert>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default InfoPopper;
