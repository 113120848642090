import {
  styled,
  TableContainer as MuiTableContainer,
  TableCell as MuiTableCell,
} from "@mui/material";
import {
  darkSupernova,
  dodgerBlue,
  jordyBlue,
  supernova,
  tableHeaderGray,
} from "shared/assets/colors";

const PREFIX = "TableEntriesStyles";
export const classes = {
  cell: `${PREFIX}-cell`,
  cellActions: `${PREFIX}-cellActions`,
  tableContainer: `${PREFIX}-tableContainer`,
  toolTipDiv: `${PREFIX}-toolTipDiv`,
  toolTipSpan: `${PREFIX}-toolTipSpan`,
  hidden: `${PREFIX}-hidden`,
  blueButtonIcon: `${PREFIX}-blueButtonIcon`,
  yellowButtonIcon: `${PREFIX}-yellowButtonIcon`,
  cellColor: `${PREFIX}-cellColor`,
};

export const TableContainer = styled(MuiTableContainer)(() => ({
  [`&.${classes.tableContainer}`]: {
    height: 225,
    maxWidth: 1325,
  },
  [`& .${classes.cell}`]: {
    maxWidth: 130,
  },
  [`& .${classes.cellActions}`]: {
    minWidth: 100,
  },
  [`& .${classes.toolTipDiv}`]: {
    display: "inline",
  },
  [`& .${classes.toolTipSpan}`]: {
    whiteSpace: "pre-line",
  },
  [`& .${classes.hidden}`]: {
    display: "none",
  },
  [`& .${classes.blueButtonIcon}`]: {
    color: jordyBlue,
    "&:hover": {
      color: dodgerBlue,
    },
  },
  [`& .${classes.yellowButtonIcon}`]: {
    color: supernova,
    "&:hover": {
      color: darkSupernova,
    },
  },
}));

export const TableCell = styled(MuiTableCell)(() => ({
  [`&.${classes.cellColor}`]: {
    backgroundColor: tableHeaderGray,
  },
  [`& fieldset`]: {
    border: "none",
  },
}));
