import React, { ReactElement } from "react";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { classes, Chip } from "./styles";

export interface ChipAddProps {
  add: () => void;
  isDisabled?: boolean;
}

export const ChipAdd = ({ add, isDisabled }: ChipAddProps): ReactElement => {
  return (
    <Chip
      id="button-add-filter"
      color="primary"
      icon={<AddCircleIcon color="primary" className={classes.icon} />}
      onClick={add}
      label="Add filter"
      variant="outlined"
      className={classes.button}
      disabled={isDisabled}
    />
  );
};

export default ChipAdd;
