import { styled, Grid as MuiGrid } from "@mui/material";
import { darkDeYork, darkSupernova } from "../../../shared/assets/colors";

const PREFIX = "ManageMappingsActionsStyles";
export const classes = {
  addButton: `${PREFIX}-addButton`,
  clearButton: `${PREFIX}-clearButton`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.addButton}`]: {
    backgroundColor: darkDeYork,
  },
  [`& .${classes.clearButton}`]: {
    backgroundColor: darkSupernova,
  },
}));
