import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import { TextField } from "core";
import { FormStandardFieldProps } from "../props";

export const FormBusinessSection = ({
  isEdit,
  control,
  errors,
}: FormStandardFieldProps): ReactElement => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          name="division"
          id="division"
          label="Division"
          error={!!errors.division?.message}
          helperText={errors.division?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="businessUnit"
          id="businessUnit"
          label="Business Unit"
          error={!!errors.businessUnit?.message}
          helperText={errors.businessUnit?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="businessLine"
          id="businessLine"
          label="Business Line"
          error={!!errors.businessLine?.message}
          helperText={errors.businessLine?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormBusinessSection;
