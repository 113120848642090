import { styled, Paper as MuiPaper } from "@mui/material";
import { sapphire, greyChateau } from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "SectionStyles";
export const classes = {
  paper: `${PREFIX}-paper`,
  typographyTitle: `${PREFIX}-typographyTitle`,
  typographySubtitle: `${PREFIX}-typographySubtitle`,
};

export const Paper = styled(MuiPaper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    paddingTop: getThemeSpacing(theme, 2),
    paddingBottom: getThemeSpacing(theme, 2),
    paddingLeft: getThemeSpacing(theme, 4),
  },
  [`& .${classes.typographyTitle}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    color: sapphire,
  },
  [`& .${classes.typographySubtitle}`]: {
    color: greyChateau,
  },
}));
