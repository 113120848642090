import React, { ReactElement } from "react";

import clsx from "clsx";
import { format } from "date-fns";
import {
  Control,
  Controller,
  FieldValues,
  ControllerRenderProps,
} from "react-hook-form";

import {
  Table,
  Tooltip,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  History as HistoryIcon,
} from "@mui/icons-material";

import { DEFAULT_DATE_FORMAT, EMPTY_STRING } from "shared/constants";

import { useQuery } from "shared/hooks";
import { ViewModeEnum } from "shared/enums";
import { isNullOrUndefined } from "shared/utils";
import { classes, TableContainer, TableCell } from "./styles";

export enum CellTypeEnum {
  Input = "Input",
  Select = "Select",
  NumberFormat = "NumberFormat",
  Date = "Date",
}

declare type Alignement = "left" | "right" | "center";

declare type FieldOptions = {
  [key: string]: OptionType[];
};

export declare type RenderCellProps = {
  options?: OptionType[];
  field: Omit<
    ControllerRenderProps<FieldValues, `entries.${number}.${string}`>,
    "ref"
  >;
};

export interface ColumnType {
  /**
   * The column identifier.
   */
  field: string;
  /**
   * The title of the column rendered in the column header cell.
   */
  headerName?: string;
  /**
   * Set the width of the column.
   * @default 100
   */
  width?: number;
  /**
   * If `true`, hide the column.
   * @default false;
   */
  hide?: boolean;
  /**
   * Type allows to merge this object with a default definition [[ColumnType]].
   * @default string
   */
  // type?: ColType;
  /**
   * Type of render component in cell.
   */
  cellType: keyof typeof CellTypeEnum;
  /**
   * Allows to align the column values in cells.
   */
  align?: Alignement;
  /**
   * Allows to override the component rendered as cell for this column.
   * @param params
   */
  renderCell: (props: RenderCellProps) => ReactElement;
  /**
   * If `true`, value will be array type.
   */
  isMultiple?: boolean;
}

export interface TableEntriesProps {
  /**
   * columns
   */
  columns: ColumnType[];
  /**
   * rows
   */
  rows: any[];
  /**
   * isEdit
   */
  isEdit: boolean;
  /**
   * isAddBttnDisabled
   */
  isAddBttnDisabled?: boolean;
  /**
   * onAdd
   */
  onAdd: () => void;
  /**
   * onEdit
   */
  onEdit: (rowToUpdate: any) => void;
  /**
   * onDelete
   */
  onDelete: (rowIndex: number) => void;
  /**
   * control
   */
  control: Control;
  /**
   * fieldOptions
   */
  fieldOptions?: FieldOptions;
  /**
   * revisionValueIds
   */
  revisionValueIds: string[];
}

export const TableEntries = ({
  columns,
  rows,
  isEdit,
  onAdd,
  onEdit,
  onDelete,
  control,
  fieldOptions = {},
  isAddBttnDisabled = false,
  revisionValueIds,
}: TableEntriesProps): ReactElement => {
  const query = useQuery();
  const mode = query?.get("mode");
  const isRevisionMode = mode === ViewModeEnum.revisionMode;

  const getDefaultValue = (
    value: any,
    isMultiple?: boolean,
    inputType?: keyof typeof CellTypeEnum
  ): any => {
    if (value != null) return value;

    if (isMultiple) return [];

    switch (inputType) {
      case "Date":
        return null;
      default:
        return EMPTY_STRING;
    }
  };

  const getTooltipValue = (
    row: any,
    field: string,
    inputType: keyof typeof CellTypeEnum,
    isMultiple?: boolean
  ) => {
    const value = row[field] ?? EMPTY_STRING;

    switch (inputType) {
      case "Input":
        return value;
      case "NumberFormat":
        return value?.toLocaleString("nl");
      case "Select": {
        if (isMultiple) {
          return fieldOptions[field]
            .filter((o) => value.includes(o.key))
            ?.map((o) => o.value)
            .join("\n");
        }
        return fieldOptions[field].find((o) => o.key === value)?.value;
      }
      case "Date": {
        if (value instanceof Date) {
          return format(value, DEFAULT_DATE_FORMAT);
        }
        const dateVal = value?.substring(0, 10);
        return dateVal?.split("-").reverse().join("-");
      }
      default:
        return EMPTY_STRING;
    }
  };

  const disableDeleteButton = (id: any) => {
    if (isNullOrUndefined(id)) {
      return false;
    }
    if (rows.some((obj) => "isAgreementDeletable" in obj)) {
      return !rows.filter((obj) => obj.id === id)[0].isAgreementDeletable;
    }
    return false;
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              key="actions"
              className={`${classes.cellActions} ${classes.cellColor}`}
            >
              <Tooltip title="Add">
                <div className={classes.toolTipDiv}>
                  <IconButton
                    disabled={!isEdit || isAddBttnDisabled}
                    className={classes.blueButtonIcon}
                    onClick={onAdd}
                    size="small"
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip title="reset changes">
                <div className={classes.toolTipDiv} />
              </Tooltip>
            </TableCell>
            {columns.map((column) => (
              <TableCell
                key={column.headerName}
                align={column.align}
                className={`${clsx(classes.cell, {
                  [classes.hidden]: column.hide,
                })} ${classes.cellColor}`}
              >
                <Tooltip
                  title={column.headerName ?? EMPTY_STRING}
                  enterDelay={250}
                  placement="bottom-start"
                >
                  <Typography noWrap>{column.headerName}</Typography>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ id, ...row }: any, rowIndex: number) => (
            <Controller
              key={`${id}-row`}
              control={control}
              name={`${id}-row`}
              render={({ field }) => (
                <TableRow>
                  <TableCell
                    {...field}
                    key={`${id}-col`}
                    className={classes.cellActions}
                  >
                    {isEdit ? (
                      <>
                        <IconButton
                          className={classes.yellowButtonIcon}
                          onClick={() => onEdit({ id, ...row, rowIndex })}
                          size="small"
                        >
                          <CreateIcon />
                        </IconButton>
                        <IconButton
                          className={classes.blueButtonIcon}
                          onClick={() => onDelete(rowIndex)}
                          size="small"
                          disabled={disableDeleteButton(id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {isRevisionMode &&
                        revisionValueIds.includes(id.toString()) ? (
                          <IconButton
                            onClick={() => onEdit({ id, ...row, rowIndex })}
                            size="small"
                          >
                            <HistoryIcon color="primary" />
                          </IconButton>
                        ) : (
                          <IconButton
                            className={classes.blueButtonIcon}
                            onClick={() => onEdit({ id, ...row, rowIndex })}
                            size="small"
                          >
                            <VisibilityIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                  </TableCell>

                  {columns.map(
                    ({
                      field: columnField,
                      renderCell,
                      isMultiple,
                      hide,
                      cellType,
                    }) => (
                      <Tooltip
                        key={`${id}-${columnField}-tooltip`}
                        classes={{ tooltip: classes.toolTipSpan }}
                        title={getTooltipValue(
                          row,
                          columnField,
                          cellType,
                          isMultiple
                        )}
                        placement="bottom-start"
                        enterDelay={250}
                      >
                        <TableCell
                          key={`${id}-${columnField}`}
                          className={clsx(classes.cell, {
                            [classes.hidden]: hide,
                          })}
                        >
                          <Controller
                            render={({ field: { ref, ...otherField } }) =>
                              renderCell({
                                field: otherField,
                                options: fieldOptions[columnField],
                              })
                            }
                            control={control}
                            defaultValue={getDefaultValue(
                              row[columnField],
                              isMultiple,
                              cellType
                            )}
                            name={`entries.${rowIndex}.${columnField}`}
                          />
                        </TableCell>
                      </Tooltip>
                    )
                  )}
                </TableRow>
              )}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableEntries;
