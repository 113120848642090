import React, { ReactElement, useEffect } from "react";

import { TextField } from "core";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormOutOfScopeServicesProps extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormOutOfScopeServices = ({
  isEdit,
  control,
  errors,
  revisionValues,
  fillOption,
  setValue,
}: FormOutOfScopeServicesProps): ReactElement => {
  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("text", "Out of Scope Services - Test");
    }
  }, [fillOption, setValue]);
  // #endregion Effects

  return (
    <TextField
      name="text"
      id="contractRefetextrence"
      label="Out of Scope Services"
      multiline
      rows={4}
      error={!!errors.text?.message}
      helperText={errors.text?.message}
      revisionValue={revisionValues?.Text}
      disabled={!isEdit}
      control={control}
    />
  );
};

export default FormOutOfScopeServices;
