import React, { ReactElement } from "react";

import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  PopperProps,
} from "@mui/material";

import { classes, Popper } from "./styles";

export interface ConfirmationPopperProps extends PopperProps {
  onClickAway: () => void;
  onConfirm: () => void;
  message: string;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
}

export const ConfirmationPopper = ({
  open,
  message,
  anchorEl,
  onConfirm,
  onClickAway,
  primaryButtonLabel,
  secondaryButtonLabel,
}: Omit<ConfirmationPopperProps, "children">): ReactElement => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      className={classes.popper}
      nonce
      onResize
      onResizeCapture
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClickAway}>
              <span>
                <div className={classes.confirmation}>
                  <div className={classes.confirmationText}>{message}</div>
                  <div className={classes.confirmationButtons}>
                    <Button onClick={onClickAway} color="secondary">
                      {secondaryButtonLabel}
                    </Button>
                    <Button onClick={onConfirm} color="primary">
                      {primaryButtonLabel}
                    </Button>
                  </div>
                </div>
              </span>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default ConfirmationPopper;
