import { boolean, number, object } from "yup";

import {
  MAX_NUMBER_FOR_INPUTS,
  MIN_NUMBER_FOR_INPUTS,
  MAX_NUMBER_VALIDATION_MESSAGE,
  MIN_NUMBER_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  earlyPaymentDiscountId: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().notRequired().transform(nanToNull),
  }),
  percentagePassedOn: number()
    .when("earlyPaymentDiscountId", {
      is: 2, // TODO: Enums
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().notRequired().transform(nanToNull),
    })
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MIN_NUMBER_VALIDATION_MESSAGE),
  percentagePaidWithinNumberOfDays: number()
    .when("earlyPaymentDiscountId", {
      is: 2, // TODO: Enums
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().notRequired().transform(nanToNull),
    })
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MIN_NUMBER_VALIDATION_MESSAGE),
  epdReceivedId: number().when("earlyPaymentDiscountId", {
    is: 3, // TODO: Enums
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().notRequired().transform(nanToNull),
  }),
  epdReceivedPaidWithinNumberOfDays: number()
    .when("epdReceivedId", {
      is: 2, // TODO: Enums
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().notRequired().transform(nanToNull),
    })
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MIN_NUMBER_VALIDATION_MESSAGE),
  additionalConditionsInContract: boolean().nullable(),
});
