export enum ModeEnum {
  edit = "edit",
  create = "create",
}

export enum AgencyRoleFieldsEnum {
  "groupmRoles",
  "kineticRoles",
  "essencemediacomRoles",
  "mindshareRoles",
  "wavemakerRoles",
  "blossomRoles",
  "greenhouseoneRoles",
  "greenhousetechRoles",
  "choreographcreateRoles",
}
