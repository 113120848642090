import {
  styled,
  Table as MuiTable,
  Select as MuiSelect,
  Divider as MuiDivider,
} from "@mui/material";
import {
  white,
  jordyBlue,
  baliHai,
  solitude,
  black,
} from "shared/assets/colors";

const PREFIX = "ManageDebtorGroupsStyles";
export const classes = {
  table: `${PREFIX}-table`,
  defaultChip: `${PREFIX}-defaultChip`,
  blueChip: `${PREFIX}-blueChip`,
  checkbox: `${PREFIX}-checkbox`,
  dropDown: `${PREFIX}-dropDown`,
  div: `${PREFIX}-div`,
  divider: `${PREFIX}-divider`,
};

export const Table = styled(MuiTable)(() => ({
  [`& .${classes.table}`]: {
    border: `1px solid ${solitude}`,
    borderCollapse: "separate",
    borderSpacing: "5px !important",
  },
  [`& .${classes.defaultChip}`]: {
    backgroundColor: baliHai,
    color: white,
  },
  [`& .${classes.blueChip}`]: {
    backgroundColor: jordyBlue,
    color: white,
  },
  [`& .${classes.checkbox}`]: {
    marginLeft: 14,
  },
  [`& .${classes.dropDown}`]: {
    color: black,
  },
}));

export const Select = styled(MuiSelect)(() => ({}));

export const Div = styled("div")(() => ({
  [`&.${classes.div}`]: {
    marginTop: 16,
  },
}));

export const Divider = styled(MuiDivider)(() => ({
  [`&.${classes.divider}`]: {
    marginTop: 18,
    marginLeft: 16,
    marginBottom: 1,
  },
}));
