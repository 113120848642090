import { EMPTY_STRING } from "shared/constants";

export const defaultRowsPerPageOptions = [5, 10, 25];

export const sortOptions = [
  { key: EMPTY_STRING, text: "None" },
  { key: "clientName_asc", text: "By client name  asc" },
  { key: "clientName", text: "By client name dsc" },
  { key: "concernName_asc", text: "By concern name asc" },
  { key: "concernName", text: "By concern name dsc" },
];

export interface FilterOptions {
  id: number;
  property: string;
  text: string;
}

export interface FilterQuerys {
  [key: string]: string | undefined;
}

export const dynamicFilterOptions: FilterOptions[] = Object([
  { id: 1, property: "clientName", text: "By Client Name" },
  { id: 2, property: "marathonClientId", text: "By Client Id" },
  { id: 3, property: "concernName", text: "By Concern Name" },
  { id: 4, property: "clientLead", text: "By Client Lead" },
]);

export const DOCUMENT_STATUS_TYPE_NAME_WORK_IN_PROGRESS = "WorkInProgress";
export const DOCUMENT_STATUS_TYPE_NAME_REVIEWED = "Reviewed";
export const DOCUMENT_STATUS_TYPE_NAME_TO_BE_CHANGED = "ToBeChanged";
export const DOCUMENT_STATUS_TYPE_NAME_CHANGED = "Changed";
export const DOCUMENT_STATUS_TYPE_NAME_DECLINE = "Decline";
export const DOCUMENT_STATUS_TYPE_NAME_FINALIZED_CHANGED = "FinalizedChanged";
export const DOCUMENT_STATUS_TYPE_NAME_MDT_IN_PROGRESS = "MDT In Progress";
export const DOCUMENT_STATUS_TYPE_NAME_PROCESS_DECLINE = "ProcessDecline";
export const DOCUMENT_STATUS_TYPE_NAME_PROCESSING = "Processing";
export const DOCUMENT_STATUS_TYPE_NAME_APPROVED = "Approved";
export const DOCUMENT_STATUS_TYPE_NAME_PENDING_FINALIZED_CHANGE_APPROVER =
  "PendingFinalizedChangeApprover";
export const DOCUMENT_STATUS_TYPE_NAME_PROCESSED = "Processed";
export const DOCUMENT_STATUS_TYPE_NAME_MDT_CHECKED = "MDTChecked";
export const DOCUMENT_STATUS_TYPE_NAME_PENDING_FINALIZED_CHANGE_REVIEWER =
  "PendingFinalizedChangeReviewer";
export const DOCUMENT_STATUS_TYPE_NAME_PENDING_FINALIZED_CHANGE_VALIDATOR =
  "PendingFinalizedChangeValidator";
export const DOCUMENT_STATUS_TYPE_NAME_READY_FOR_REVIEW = "ReadyForReview";

const documentStatusTypesNameEnum = [
  DOCUMENT_STATUS_TYPE_NAME_WORK_IN_PROGRESS,
  DOCUMENT_STATUS_TYPE_NAME_REVIEWED,
  DOCUMENT_STATUS_TYPE_NAME_TO_BE_CHANGED,
  DOCUMENT_STATUS_TYPE_NAME_CHANGED,
  DOCUMENT_STATUS_TYPE_NAME_DECLINE,
  DOCUMENT_STATUS_TYPE_NAME_FINALIZED_CHANGED,
  DOCUMENT_STATUS_TYPE_NAME_MDT_IN_PROGRESS,
  DOCUMENT_STATUS_TYPE_NAME_PROCESS_DECLINE,
  DOCUMENT_STATUS_TYPE_NAME_PROCESSING,
  DOCUMENT_STATUS_TYPE_NAME_APPROVED,
  DOCUMENT_STATUS_TYPE_NAME_PROCESSED,
  DOCUMENT_STATUS_TYPE_NAME_MDT_CHECKED,
  DOCUMENT_STATUS_TYPE_NAME_PENDING_FINALIZED_CHANGE_REVIEWER,
  DOCUMENT_STATUS_TYPE_NAME_PENDING_FINALIZED_CHANGE_VALIDATOR,
  DOCUMENT_STATUS_TYPE_NAME_READY_FOR_REVIEW,
];

Object.freeze(documentStatusTypesNameEnum);

export { documentStatusTypesNameEnum };
