import { styled, Accordion as MuiAccordion } from "@mui/material";
import { getThemeSpacing } from "shared/utils/generalUtils";
import {
  supernova,
  dodgerBlue,
  linkWater,
} from "../../../shared/assets/colors";

const PREFIX = "DebtorGroupFormStyles";
export const classes = {
  editButton: `${PREFIX}-editButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  disabledButton: `${PREFIX}-disabledButton`,
};

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  [`& .${classes.editButton}`]: {
    textTransform: "none",
    fontSize: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    color: supernova,
  },
  [`& .${classes.deleteButton}`]: {
    textTransform: "none",
    fontSize: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    color: dodgerBlue,
  },
  [`& .${classes.disabledButton}`]: {
    color: linkWater,
  },
}));
