import React, { ReactElement } from "react";
import {
  Grid,
  TableCell,
  Tooltip,
  Typography,
  Checkbox,
  Chip,
} from "@mui/material";

import { EMPTY_STRING, PLACEHOLDER } from "shared/constants";
import clsx from "clsx";
import { UserStatusEnum } from "shared/enums";
import { classes, TableRow } from "./styles";

export interface ImportUsersTableRowProps {
  username: string;
  firstName: string;
  lastName: string;
  status: number;
  isChecked: boolean;
  isDisabled?: boolean;
  onCheckboxClick: (selectedUser: string) => void;
}

export const ImportUsersTableRow = ({
  username,
  firstName,
  lastName,
  status,
  isChecked,
  isDisabled,
  onCheckboxClick,
}: ImportUsersTableRowProps): ReactElement => {
  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <Checkbox
              id={`check-${username}`}
              size="small"
              inputProps={{ "aria-label": "checkbox with small size" }}
              onChange={() => onCheckboxClick(username)}
              checked={isChecked}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Username
            </Typography>
            <Tooltip title={username} enterDelay={250} placement="bottom-start">
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {username ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              First Name
            </Typography>
            <Tooltip
              title={firstName || EMPTY_STRING}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {firstName ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Last Name
            </Typography>
            <Tooltip title={lastName || EMPTY_STRING} placement="bottom-start">
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {lastName ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          {status !== 0 && (
            <Grid item>
              <Chip
                label={UserStatusEnum[status]}
                className={clsx(classes.defaultChip, {
                  [classes.blueChip]: status === UserStatusEnum.new,
                  [classes.greenChip]: status === UserStatusEnum.imported,
                  [classes.orangeChip]: status === UserStatusEnum.removed,
                })}
              />
            </Grid>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default ImportUsersTableRow;
