import React, { ReactElement } from "react";

import { Control, Controller } from "react-hook-form";
import { History as HistoryIcon } from "@mui/icons-material";
import { Checkbox, CheckboxProps, IconButton } from "@mui/material";

import { EMPTY_STRING, REVISION_INIT_VALUE } from "shared/constants";

import { InfoPopper } from "../InfoPopper";

import { classes, Div } from "./styles";

export interface CheckFieldProps extends Omit<CheckboxProps, "defaultValue"> {
  /**
   * Control
   */
  control: Control;
  /**
   * label
   */
  label: string;
  /**
   * name
   */
  name: string;
  /**
   * primary
   */
  primary?: boolean;
  /**
   * Previous value of field
   * @default undefined
   */
  revisionValue?: NullableBoolean;
  /**
   * Default value for Controller
   * @default null
   */
  defaultValue?: boolean;
}

export const CheckField = ({
  control,
  label,
  revisionValue,
  defaultValue,
  primary,
  ...otherProps
}: CheckFieldProps): ReactElement => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  let revisionVal: string | undefined;

  if (revisionValue === null) {
    revisionVal = REVISION_INIT_VALUE;
  } else if (revisionValue !== undefined) {
    revisionVal = revisionValue ? "Checked" : "Unchecked";
  }
  return (
    <>
      <Controller
        control={control}
        name={otherProps.name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Checkbox
            {...field}
            value={field.value || EMPTY_STRING}
            {...otherProps}
            onChange={(e) => field.onChange(e.target.checked)}
            checked={field.value ?? false}
            color={primary ? "primary" : "secondary"}
          />
        )}
      />
      <Div className={classes.label}>{label}</Div>
      {revisionVal && (
        <>
          <IconButton
            aria-label="toggle change value visibility"
            onClick={handleClick}
            ref={anchorRef}
          >
            <HistoryIcon color="primary" />
          </IconButton>

          <InfoPopper
            text={revisionVal}
            onClickAway={handleClickAway}
            open={open}
            anchorEl={anchorRef.current}
          />
        </>
      )}
    </>
  );
};

CheckField.defaultProps = {
  revisionValue: undefined,
  defaultValue: null,
};

export default CheckField;
