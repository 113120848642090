import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "UserManagementActionsStyles";
export const classes = {
  link: `${PREFIX}-link`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.link}`]: {
    textDecoration: "none",
  },
}));
