import React, { ReactElement, useState } from "react";
import { useStateMachine } from "little-state-machine";
import { useFetch } from "shared/hooks";
import { generateUrl } from "shared/utils";
import {
  Grid,
  TableCell,
  Tooltip,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import { EMPTY_STRING, PLACEHOLDER } from "shared/constants";
import { GET_CONCERNS } from "master-data/constants";
import { FetchMethodEnum } from "shared/enums";
import { classes, TableRow } from "./styles";
import { AMConcernResponse, Concern, IPostResponse } from "./types";
import { filter } from "./config";

export interface MissingDebtorsTableRowProps {
  marathonId: string;
  debtorName: string;
  isActive: boolean;
  isChecked: boolean;
  isDisabled?: boolean;
  agencyId?: NullableNumber;
  concernId?: NullableNumber;
  concern?: NullableString;
  errorAgency: string;
  errorConcern: string;
  onCheckboxClick: (
    selectedDebtor: string,
    debtorName: string,
    isActive: boolean
  ) => void;
  onAgencySelectChange: (marathonId: string, selectedAgencyId: number) => void;
  onConcernSelectChange: (
    marathonId: string,
    selection: Concern | string | null
  ) => void;
}

export const MissingDebtorsTableRow = ({
  marathonId,
  debtorName,
  isActive,
  isChecked,
  isDisabled,
  errorAgency,
  errorConcern,
  onCheckboxClick,
  onAgencySelectChange,
  onConcernSelectChange,
}: MissingDebtorsTableRowProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const status = isActive ? "Active" : "Inactive";
  const { enumAgencies } = cacheData;
  const { GET } = FetchMethodEnum;
  const [agencyId, setAgencyId] = useState(0);
  const [concernUrl, setConcernUrl] = useState(
    generateUrl(GET_CONCERNS, { agencyId })
  );

  const { data, executeFetch, updateUrl } = useFetch<
    AMConcernResponse,
    IPostResponse
  >(concernUrl, {
    method: GET,
  });

  const { items: concernOptions = [] } =
    data?.get ?? ({ items: [] } as AMConcernResponse);

  const onAgencyChange = (event: SelectChangeEvent<unknown>) => {
    const selectedAgencyId = event.target.value as unknown as number;
    const url = generateUrl(GET_CONCERNS, { agencyId: selectedAgencyId });
    setAgencyId(selectedAgencyId);
    setConcernUrl(url);

    updateUrl(url);
    executeFetch(url, {
      method: GET,
    });

    onAgencySelectChange(marathonId, selectedAgencyId);
  };

  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={0.6}>
            <Checkbox
              id={`check-${marathonId}`}
              size="small"
              inputProps={{ "aria-label": "checkbox with small size" }}
              onChange={() => onCheckboxClick(marathonId, debtorName, isActive)}
              checked={isChecked}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={1.25}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Marathon Id
            </Typography>
            <Tooltip
              title={marathonId}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {marathonId ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3.25}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Debtor Name
            </Typography>
            <Tooltip
              title={debtorName || EMPTY_STRING}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {debtorName ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2.25}>
            <FormControl error={errorAgency?.length > 0} fullWidth>
              <InputLabel id={`agencySelect-${marathonId}-label`}>
                Agency *
              </InputLabel>
              <Select
                defaultValue=""
                id={`agencySelect-${marathonId}`}
                labelId={`agencySelect-${marathonId}-label`}
                label="Agency *"
                onChange={(event) => onAgencyChange(event)}
                disabled={!isChecked}
              >
                {enumAgencies.map((x) => (
                  <MenuItem
                    key={`agencySelect-${x.key}-${marathonId}`}
                    value={x.key}
                  >
                    {x.value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errorAgency}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3.5}>
            <Autocomplete
              id={`concernSelect-${marathonId}`}
              disabled={agencyId < 1}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  onConcernSelectChange(marathonId, newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  onConcernSelectChange(marathonId, newValue.inputValue);
                } else {
                  onConcernSelectChange(marathonId, newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.value
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={concernOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.value;
              }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`concernOptions-${option.key}-${marathonId}`}
                >
                  {option.value}
                </li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errorConcern?.length > 0}
                  helperText={errorConcern}
                  label="Concern Name *"
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Status
            </Typography>
            <Tooltip title={status || EMPTY_STRING} placement="bottom-start">
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {status ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default MissingDebtorsTableRow;
