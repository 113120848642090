export const BASE_URL = "api/product/agency/:agencyId/product";

export const CREATE_PRODUCT = `${BASE_URL}`;
export const CREATE_MARATHON_PRODUCT =
  "api/product/agency/:agencyId/marathon-product/:productId";
export const MANAGE_PRODUCT = `${BASE_URL}/:productId`;
export const GET_PRODUCTS = `${BASE_URL}`;
export const CHANGE_PRODUCT_STATUS = `${BASE_URL}/:productId/status`;

export const MASTERDATA_BASE_URL = "api/master-data/agency/:agencyId";

export const CLIENT_DOCUMENT_ACCORDION = `${MASTERDATA_BASE_URL}/client-document/:clientDocumentId/accordion/:accordionId`;
export const CLIENT_DOCUMENT_LIST = `${MASTERDATA_BASE_URL}/client-document-list`;
