import React, { ReactElement } from "react";

import { Grid, TableRow, TableCell, TablePagination } from "@mui/material";

import { classes, MuiTableFooter } from "./styles";

import { TablePaginationActions } from "../TablePaginationActions";

export interface TableFooterProps {
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowsPerPageOptions: number[];
}

export const TableFooter = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}: TableFooterProps): ReactElement => {
  return (
    <MuiTableFooter>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Grid container justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={count}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </MuiTableFooter>
  );
};

export default TableFooter;
