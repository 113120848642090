import React from "react";

import { RouteProps } from "shared/types";
import {
  PAGE_DASHBOARD_CLIENT,
  PAGE_DASHBOARD_PRODUCT,
} from "master-data/constants";

import { ProductHeader } from "product/components/ProductHeader";
import { ClientDocumentHeader } from "../ClientDocumentHeader";

export const routes: RouteProps[] = [
  {
    id: "clientDocumentHeaderRoute",
    rule: "CanViewDashboardClient",
    path: `${PAGE_DASHBOARD_CLIENT}/:documentId`,
    main: () => <ClientDocumentHeader />,
  },
  {
    id: "ProductHeaderRoute",
    rule: "CanViewDashboardProduct",
    path: [`${PAGE_DASHBOARD_PRODUCT}/:productId`],
    main: () => <ProductHeader />,
  },
];
