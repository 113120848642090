import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  writingHoursPerId: null,
  explanationWrittenHoursId: null,
  splittingHoursInScope: null,
  fixedItemsFunctionId: null,
  additionalInformationHours: EMPTY_STRING,
};
