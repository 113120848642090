export const BASE_URL = "api/access-manager";
export const MANAGE_USERS = `${BASE_URL}/import/user`;
export const FETCH_USERS = `${BASE_URL}/import/fetch-from-aad`;
export const MANAGE_IMPORTED_USERS = `${BASE_URL}/user`;
export const USER_GROUP_IMPORTED_USERS = `${BASE_URL}/user-group/:userGroupId/user`;
export const CREATE_USER_GROUP = `${BASE_URL}/user-group`;
export const DELETE_USER_GROUP = `${BASE_URL}/user-group/:userGroupId`;
export const MANAGE_USER_GROUP = `${BASE_URL}/user-group/:userGroupId`;
export const GET_USER_GROUPS = `${BASE_URL}/user-group`;
export const GET_USER_GROUPS_OVERVIEW = `${BASE_URL}/user-group/overview`;
export const CREATE_DEBTOR_GROUP = `${BASE_URL}/debtor-group`;
export const MANAGE_DEBTOR_GROUP = `${BASE_URL}/debtor-group/:debtorGroupId`;
export const DEBTOR_GROUP_DEBTORS = `${BASE_URL}/debtor-group/:debtorGroupId/debtor`;
export const GET_DEBTOR_GROUPS = `${BASE_URL}/debtor-group`;
export const GET_DEBTOR_GROUPS_OVERVIEW = `${BASE_URL}/debtor-group/overview`;
export const GET_DEBTORS_FOR_MAPPING = `${BASE_URL}/debtor`;
export const DELETE_DEBTOR_GROUP = `${BASE_URL}/debtor-group/:debtorGroupId`;
export const GET_DATA_FOR_MAPPING = `${BASE_URL}/mapping`;
export const CREATE_MAPPINGS = `${BASE_URL}/mapping`;
export const GET_MAPPING_OVERVIEW = `${BASE_URL}/mapping/overview`;
export const DELETE_MAPPING = `${BASE_URL}/mapping`;
export const USER_SUGGESTION = `${BASE_URL}/usersuggestion`;
export const DEBTOR_SUGGESTION = `${BASE_URL}/debtorsuggestion`;
export const KEYWORD_SEARCH = `${BASE_URL}/keywordsearch`;
export const GET_MISSING_DEBTORS = `${BASE_URL}/missingdebtors`;
export const IMPORT_MISSING_DEBTORS = `${BASE_URL}/import/missingdebtors`;
