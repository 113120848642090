import { styled, IconButton as MuiIconButton } from "@mui/material";

import { dodgerBlue20 } from "shared/assets/colors";

const PREFIX = "QuickFiltersStyles";
export const classes = {
  button: `${PREFIX}-button`,
  active: `${PREFIX}-active`,
};

export const IconButton = styled(MuiIconButton)(() => ({
  [`&.${classes.button}`]: {
    textTransform: "none",
    fontWeight: 100,
    padding: 12,
  },
  [`&.${classes.active}`]: {
    backgroundColor: dodgerBlue20,
  },
}));
