import React, { ReactElement, useState } from "react";

import { Chip } from "@mui/material";

import { ConfirmationPopper } from "core";

import { useFetch, FetchMethodEnum, useFileDownloader } from "shared/hooks";

import { FileType } from "./types";

import { classes, Tooltip } from "./styles";

const { DELETE } = FetchMethodEnum;

export interface FileChipProps extends FileType {
  checkId: number;
  isEditable: boolean;
  onDelete: () => void;
  downloadUrl: string;
  deleteUrl: string;
}

export const FileChip = ({
  fileName,
  isEditable,
  onDelete,
  downloadUrl,
  deleteUrl,
}: FileChipProps): ReactElement => {
  const { downloadFile } = useFileDownloader();
  const anchorRef = React.useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { executeFetch } = useFetch<null, null>(null, { skip: true });

  // #region Methods
  const handleClick = () => {
    setConfirmationOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setConfirmationOpen(false);
  };

  const handleDownload = async () => {
    await downloadFile(downloadUrl, null, fileName);
  };

  const handleDelete = async () => {
    await executeFetch(deleteUrl, { method: DELETE, skip: false });
    handleClickAway();
    onDelete();
  };
  // #endregion

  return (
    <>
      {isEditable && (
        <Tooltip title={fileName} placement="bottom-start" enterDelay={250}>
          <Chip
            ref={anchorRef}
            color="primary"
            onClick={handleDownload}
            onDelete={handleClick}
            label={fileName}
            clickable
            variant="outlined"
            className={classes.chip}
          />
        </Tooltip>
      )}
      {!isEditable && (
        <Tooltip title={fileName} placement="bottom-start" enterDelay={250}>
          <Chip
            ref={anchorRef}
            color="primary"
            onClick={handleDownload}
            label={fileName}
            clickable
            variant="outlined"
            className={classes.chip}
          />
        </Tooltip>
      )}
      <ConfirmationPopper
        message={`Are you sure you want to delete ${fileName}?`}
        onClickAway={handleClickAway}
        onConfirm={handleDelete}
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        open={confirmationOpen}
        anchorEl={anchorRef.current}
      />
    </>
  );
};

export default FileChip;
