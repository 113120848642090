import { GlobalState } from "little-state-machine";

import { CacheData } from "../types";
import { defaultCacheData } from "../constants";

export function setCacheData(
  state: GlobalState,
  payload: CacheData
): GlobalState {
  return {
    ...state,
    cacheData: payload,
  };
}

export function resetCacheData(state: GlobalState): GlobalState {
  return {
    ...state,
    cacheData: defaultCacheData,
  };
}
