import React, { ReactElement } from "react";

import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

// import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
// import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { DEFAULT_DATE_FORMAT, EMPTY_STRING } from "shared/constants";

export interface DateCellProps {
  /*
   * Function required by DatePicker, dummy function in this case.
   */
  onChange?: (date: any) => void;
  /*
   * On blur callback
   */
  onBlur?: () => void;
  /*
   * Value of DatePicker
   */
  value?: any;
  /*
   * Name of the input, required by RHF Controller
   */
  name?: string;
  /*
   * Format string
   */
  format?: string;
}

export const DateCell = ({
  format = DEFAULT_DATE_FORMAT,
  value = EMPTY_STRING,
  onChange = () => {},
  ...otherProps
}: DateCellProps): ReactElement => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        {...otherProps}
        inputFormat={format}
        onChange={onChange}
        value={value}
        readOnly
        renderInput={({ InputProps, ...props }) => (
          <TextField
            {...props}
            disabled
            InputProps={{
              ...InputProps,
              placeholder: EMPTY_STRING,
              style: {
                height: 20,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateCell;
