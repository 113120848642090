import { styled, Popper as MuiPopper } from "@mui/material";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "ConfirmationPopperStyles";
export const classes = {
  popper: `${PREFIX}-popper`,
  confirmation: `${PREFIX}-confirmation`,
  confirmationText: `${PREFIX}-confirmationText`,
  confirmationButtons: `${PREFIX}-confirmationButtons`,
};

export const Popper = styled(MuiPopper)(({ theme }) => ({
  [`&.${classes.popper}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    zIndex: 1000,
  },
  [`& .${classes.confirmation}`]: {
    border: "1px solid red",
    borderRadius: 5,
    padding: getThemeSpacing(theme, 1),
    maxWidth: 400,
  },
  [`& .${classes.confirmationText}`]: {
    margin: 5,
    wordBreak: "break-word",
  },
  [`& .${classes.confirmationButtons}`]: {
    textAlign: "right",
    marginTop: 5,
  },
}));
