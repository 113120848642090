import { styled, AppBar } from "@mui/material";

import { getThemeSpacing } from "shared/utils/generalUtils";
import { drawerOpenWidth } from "shared/constants";
import { gainsboro } from "shared/assets/colors";

const PREFIX = "AppBarStyles";
export const classes = {
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  appBarFooter: `${PREFIX}-appBarFooter`,
};

export const MuiAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.appBar}`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${gainsboro}`,
    width: `calc(100% - ${getThemeSpacing(theme, 7) + 1}px)`,
    marginLeft: getThemeSpacing(theme, 7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${getThemeSpacing(theme, 9) + 1}px)`,
      marginLeft: getThemeSpacing(theme, 9) + 1,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`&.${classes.appBarShift}`]: {
    marginLeft: drawerOpenWidth,
    width: `calc(100% - ${drawerOpenWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`&.${classes.appBarFooter}`]: {
    top: "auto",
    bottom: 0,
  },
}));
