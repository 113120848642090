import React, { useState, useEffect, MouseEvent, ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import {
  Divider,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

import { isEditableForValidator } from "master-data/utils";
import { AccordionType, SectionType } from "master-data/types";
import {
  AccordionEnum,
  FillFormOptionType,
  MAP_ACCORDION,
} from "master-data/enums";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import {
  APP_MASTERDATA,
  PAGE_DASHBOARD_CLIENT,
  CLAIM_EDIT_CREATOR_FIELD,
  CLAIM_EDIT_VALIDATOR_FIELD,
} from "master-data/constants";

import { AccordionLink } from "../AccordionLink";
import { AccordionActions as Actions } from "../AccordionActions";
import { AccordionSummary as Summary } from "../AccordionSummary";

import { forms, formsWithVersions } from "./config";

export interface AccordionProps {
  /**
   * Title of accordion
   */
  title: string;
  /**
   * type of accordion
   * @type AccordionType
   */
  type: AccordionType;
  /**
   * type of Section that accordion belongs to
   * @type SectionType
   */
  sectionType: SectionType;
  /**
   * Claim
   */
  rule?: string; // TODO: make separation of this property for Accordion Component and Accordion Routes
}

export const Accordion = ({
  type,
  title,
  sectionType,
}: AccordionProps): ReactElement => {
  const [isEdit, setIsEdit] = useState(false);
  const [fillOption, setFillOption] = useState<FillFormOptionType>();
  const { accordionType, agencyName, documentId } = useParams<ParamTypes>();
  const { search, pathname } = useLocation();

  const {
    state: { profile, statusData, validationStatus, clientDocumentData },
  } = useStateMachine();

  let formType = type.concat(`V${clientDocumentData.version}`);
  if (
    clientDocumentData.isLatestVersion ||
    !formsWithVersions.find((e) => e === formType)
  ) {
    formType = type.toString();
  }

  const match = useRouteMatch(pathname);
  const isActiveRoute = Boolean(match?.isExact);
  const Form = forms[formType as AccordionType];
  const expanded = accordionType === type;
  const { isContract } = clientDocumentData;

  const showActions =
    type !== MAP_ACCORDION.get(AccordionEnum.mdtChecks) &&
    type !== MAP_ACCORDION.get(AccordionEnum.legalDocumentsChecks);

  const showFillButton = () => {
    if (
      type === MAP_ACCORDION.get(AccordionEnum.clientDocument) ||
      type === MAP_ACCORDION.get(AccordionEnum.revenueRecognition) ||
      type === MAP_ACCORDION.get(AccordionEnum.clientContacts) ||
      type === MAP_ACCORDION.get(AccordionEnum.relatedContacts)
    ) {
      return false;
    }
    return true;
  };

  const showFillMandatoryOption = () => {
    if (
      type === MAP_ACCORDION.get(AccordionEnum.clientContacts) ||
      type === MAP_ACCORDION.get(AccordionEnum.generalClientInfo) ||
      type === MAP_ACCORDION.get(AccordionEnum.relatedContacts) ||
      type === MAP_ACCORDION.get(AccordionEnum.inventoryMedia) ||
      type === MAP_ACCORDION.get(AccordionEnum.admanagement) ||
      type ===
        MAP_ACCORDION.get(AccordionEnum.digitalAccountabilityBrandSafety) ||
      type === MAP_ACCORDION.get(AccordionEnum.evouching) ||
      type === MAP_ACCORDION.get(AccordionEnum.remunerationContractLine) ||
      type === MAP_ACCORDION.get(AccordionEnum.scopeOfServices) ||
      type === MAP_ACCORDION.get(AccordionEnum.outOfScopeServices) ||
      type ===
        MAP_ACCORDION.get(AccordionEnum.additionalInvoicingRequirements) ||
      type === MAP_ACCORDION.get(AccordionEnum.hoursAccountability) ||
      type === MAP_ACCORDION.get(AccordionEnum.commercialAgreements) ||
      type === MAP_ACCORDION.get(AccordionEnum.supplierAgreements) ||
      type === MAP_ACCORDION.get(AccordionEnum.allowableDeducationsRebates)
    ) {
      return false;
    }
    if (isContract && type === MAP_ACCORDION.get(AccordionEnum.clientTeam)) {
      return false;
    }
    if (
      !isContract &&
      (type === MAP_ACCORDION.get(AccordionEnum.financialAvbs) ||
        type === MAP_ACCORDION.get(AccordionEnum.financialClientIncentive) ||
        type === MAP_ACCORDION.get(AccordionEnum.financialEpds) ||
        type === MAP_ACCORDION.get(AccordionEnum.audit))
    ) {
      return false;
    }
    return true;
  };

  const isEditable = () => {
    const { isDocumentEditable } = clientDocumentData;
    const { claims } = profile;
    const { statusId } = statusData;
    const isValidator = claims?.includes(CLAIM_EDIT_VALIDATOR_FIELD);
    const isCreator = claims?.includes(CLAIM_EDIT_CREATOR_FIELD);
    const isAdmin = isValidator && isCreator;

    let disabled = false;
    if (isAdmin) {
      disabled = isDocumentEditable || isEditableForValidator(statusId, type);
    } else {
      disabled = isValidator
        ? isEditableForValidator(statusId, type)
        : isDocumentEditable;
    }

    return disabled;
  };

  const editHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (expanded) {
      event.preventDefault();
    }
    setIsEdit(true);
  };

  const handleSave = () => {
    setIsEdit(false);
  };

  const handleCancel = () => {
    setFillOption(undefined);
    setIsEdit(false);
  };

  useEffect(() => {
    if (!expanded || !isActiveRoute) {
      setIsEdit(false);
    }
  }, [expanded, isActiveRoute]);

  const handleEntered = (element: HTMLElement) => {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <MuiAccordion
      expanded={expanded}
      TransitionProps={{ onEntered: handleEntered, appear: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${type}-content`}
        id={`${type}-header`}
        component={AccordionLink} // TODO: find a way to use Link from Core with LinkType toggle
        to={{
          search,
          pathname: `/${APP_MASTERDATA}/${agencyName}/${PAGE_DASHBOARD_CLIENT}/${documentId}/${sectionType}/${type}`,
        }}
      >
        <Summary
          title={title}
          type={type}
          onEdit={editHandler}
          isEdit={isEditable()}
          isButton={showActions}
          validationStatus={
            validationStatus ? validationStatus[type] : undefined
          }
        />
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {expanded && (
          <Form
            type={formType as AccordionType}
            isEdit={isEdit}
            onSave={handleSave}
            fillOption={fillOption}
          />
        )}
      </AccordionDetails>
      <Divider />
      {showActions && (
        <AccordionActions>
          <Actions
            type={formType as AccordionType}
            isEdit={isEdit}
            showFillButton={showFillButton()}
            showFillMandatoryOption={showFillMandatoryOption()}
            onCancel={handleCancel}
            onFill={setFillOption}
          />
        </AccordionActions>
      )}
    </MuiAccordion>
  );
};

export default Accordion;
