import React, { ReactElement } from "react";

import { InputProps } from "@mui/material";

import { classes, Input } from "./styles";

export type InputCellProps = InputProps;

export const InputCell = ({ ...otherProps }: InputCellProps): ReactElement => {
  return (
    <Input
      {...otherProps}
      disabled
      inputProps={{
        className: classes.input,
      }}
    />
  );
};

export default InputCell;
