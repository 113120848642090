import { useEffect } from "react";

import { useStateMachine } from "little-state-machine";

import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { ProfileData } from "store/types";
import { setProfile } from "store/actions";
import { GET_USER_PROFILE } from "master-data/constants";

import { useFetch, FetchMethodEnum, FetchOptions } from "./useFetch";

type NoAgencyType = 0;

export interface IResultProfile {
  username: string;
  userId: number;
  agencyId: number;
  firstName: string;
  lastName: string;
  claims: string[];
}

export const useProfileGetter = (
  agencyId: AgencyEnum | NoAgencyType,
  options?: FetchOptions<unknown>
): IResultProfile | null => {
  const { actions } = useStateMachine({ setProfile });

  const apiUrl = generateUrl(GET_USER_PROFILE, { agencyId });

  const { data: dataProfile } = useFetch<IResultProfile>(apiUrl, {
    method: FetchMethodEnum.GET,
    ...options,
  });

  useEffect(() => {
    if (dataProfile?.get) {
      actions.setProfile(dataProfile.get as ProfileData);
    }
  }, [actions, dataProfile]);

  return dataProfile?.get == null ? null : dataProfile.get;
};
