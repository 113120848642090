import React, { ReactElement } from "react";

import clsx from "clsx";
import { Link, useRouteMatch } from "react-router-dom";

import {
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";

import { EditIcon, ProfileIcon } from "master-data/components/icons";
import {
  PAGE_PROFILE,
  PLACEHOLDER,
  SETTINGS,
  yesNoLabel,
} from "shared/constants";
import { UserType } from "back-office/pages/UserManagement/types";
import { classes, Paper } from "./styles";

export interface UserManagementTableProps {
  /*
   * list of user objects
   */
  users?: UserType[];
}

export const UserManagementTable = ({
  users,
}: UserManagementTableProps): ReactElement => {
  const { url } = useRouteMatch();

  return (
    <Paper elevation={0}>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Last Name</TableCell>
              <TableCell align="left">First Name</TableCell>
              <TableCell align="left">Username</TableCell>
              <TableCell align="left">Company</TableCell>
              <TableCell align="left">Is Admin</TableCell>
              <TableCell align="left">Is Agent</TableCell>
              <TableCell align="left">Agent For</TableCell>
              <TableCell align="left">Role</TableCell>
              <TableCell align="left">Is Active</TableCell>
              <TableCell size="small" align="left" />
              <TableCell size="small" align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map(
              ({
                agencyName,
                agentFor,
                firstName,
                isActive,
                isAdmin,
                isAgent,
                lastName,
                roleNames,
                userId,
                username,
              }) => (
                <TableRow key={userId}>
                  <TableCell
                    className={clsx(classes.nowrapCell, classes.sizeMd)}
                    align="left"
                  >
                    {lastName ?? PLACEHOLDER}
                  </TableCell>
                  <TableCell
                    className={clsx(classes.nowrapCell, classes.sizeMd)}
                    align="left"
                  >
                    {firstName ?? PLACEHOLDER}
                  </TableCell>
                  <TableCell
                    className={clsx(classes.nowrapCell, classes.sizeLg)}
                    align="left"
                  >
                    {username ?? PLACEHOLDER}
                  </TableCell>
                  <TableCell align="left">
                    {agencyName ?? PLACEHOLDER}
                  </TableCell>
                  <TableCell align="left">{yesNoLabel(isAdmin)}</TableCell>
                  <TableCell align="left">{yesNoLabel(isAgent)}</TableCell>
                  <TableCell align="left">{agentFor ?? PLACEHOLDER}</TableCell>
                  <TableCell align="left">{roleNames ?? PLACEHOLDER}</TableCell>
                  <TableCell align="left">{yesNoLabel(isActive)}</TableCell>
                  <TableCell size="small" align="center">
                    <Link to={`${url}/user-form/${userId}?mode=edit`}>
                      <IconButton
                        name={`button-edit-${userId}`}
                        id={`button-edit-${userId}`}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <Link to={`/${PAGE_PROFILE}/${userId}/${SETTINGS}`}>
                      <IconButton
                        name={`button-userProfile-${userId}`}
                        id={`button-userProfile-${userId}`}
                        size="small"
                      >
                        <ProfileIcon />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default UserManagementTable;
