import { boolean, number, object } from "yup";

import {
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_NUMBER_FOR_INPUTS,
  MAX_NUMBER_VALIDATION_MESSAGE,
  MIN_NUMBER_FOR_INPUTS,
  MIN_NUMBER_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  avbs: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().notRequired().transform(nanToNull),
  }),
  percentagePassedOn: number()
    .when("avbs", {
      is: 1,
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().notRequired().transform(nanToNull),
    })
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MIN_NUMBER_VALIDATION_MESSAGE),
  reportedId: number().when("avbs", {
    is: 1,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().notRequired().transform(nanToNull),
  }),
  additionalReportingMonthId: number().when(["reportedId"], {
    is: 2,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().notRequired().transform(nanToNull),
  }),
  passOnId: number().when("avbs", {
    is: 1,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().notRequired().transform(nanToNull),
  }),
  additionalConditionsInContract: boolean().nullable(),
});
