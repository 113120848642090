import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  avbs: null,
  reportedId: null,
  additionalReportingMonthId: null,
  passOnId: null,
  percentagePassedOn: null,
  additionalConditionsInContract: null,
};
