import {
  styled,
  TableHead as MuiTableHead,
  Select as MuiSelect,
} from "@mui/material";

const PREFIX = "SorTableSortHeaderStylestStyle";
export const classes = {
  tableCell: `${PREFIX}-tableCell`,
  sortHeader: `${PREFIX}-sortHeader`,
  dropDown: `${PREFIX}-dropDown`,
  cssTypography: `${PREFIX}-cssTypography`,
  menuItem: `${PREFIX}-menuItem`,
};

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
  [`& .${classes.tableCell}`]: {
    border: "none",
  },
  [`& .${classes.sortHeader}`]: {
    margin: "0px 0px 0px 15px",
  },
  [`& .${classes.cssTypography}`]: {
    color: "#7B83A3",
    marginRight: theme.typography.pxToRem(15),
    fontSize: "15px",
  },
  [`& .${classes.menuItem}`]: {
    fontSize: theme.typography.pxToRem(10),
  },
}));

export const Select = styled(MuiSelect)(({ theme }) => ({
  [`&.${classes.dropDown}`]: {
    minWidth: theme.typography.pxToRem(125),
    height: theme.typography.pxToRem(22),
  },
}));
