import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "RevenueRecognitionStyles";
export const classes = {
  outlined: `${PREFIX}-outlined`,
  shrink: `${PREFIX}-shrink`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.outlined}`]: {
    "&$shrink": {
      transform: "translate(0, -6px)",
      fontSize: 12,
      margin: "0 14px",
    },
  },
  [`& .${classes.shrink}`]: {},
}));
