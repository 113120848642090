import { styled, Grid as MuiGrid } from "@mui/material";
import {
  dodgerBlue,
  jordyBlue,
  linkWater,
  sapphire,
} from "shared/assets/colors";

const PREFIX = "ClientDocumentHeaderStyles";
export const classes = {
  typographyValue: `${PREFIX}-typographyValue`,
  typographyLabel: `${PREFIX}-typographyLabel`,
  buttonEdit: `${PREFIX}-buttonEdit`,
  sizeIcon: `${PREFIX}-sizeIcon`,
  height: 20,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.typographyValue}`]: {
    color: sapphire,
    textDecoration: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 300,
    whiteSpace: "nowrap",
    minHeigth: 200,
  },
  [`& .${classes.typographyLabel}`]: {
    color: linkWater,
  },
  [`& .${classes.buttonEdit}`]: {
    color: jordyBlue,
    "&:hover": {
      color: dodgerBlue,
    },
  },
  [`& .${classes.sizeIcon}`]: {
    height: 15,
    width: 15,
  },
}));
