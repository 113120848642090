import { styled, TableRow as MuiTableRow } from "@mui/material";
import {
  sapphire,
  jordyBlue,
  dodgerBlue,
  linkWater,
  supernova,
  white,
  deYork,
  gainsboro,
} from "../../../shared/assets/colors";

const PREFIX = "DebtorTableRowStyles";
export const classes = {
  cell: `${PREFIX}-cell`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyLink: `${PREFIX}-typographyLink`,
  typographyLabel: `${PREFIX}-typographyLabel`,
  buttonIcon: `${PREFIX}-buttonIcon`,
  defaultChip: `${PREFIX}-defaultChip`,
  greenChip: `${PREFIX}-greenChip`,
  blueChip: `${PREFIX}-blueChip`,
  orangeChip: `${PREFIX}-orangeChip`,
};

export const TableRow = styled(MuiTableRow)(() => ({
  [`& .${classes.cell}`]: {
    border: `1px solid ${gainsboro}`,
  },
  [`& .${classes.typographyValue}`]: {
    color: sapphire,
  },
  [`& .${classes.typographyLink}`]: {
    textDecoration: "none",
  },
  [`& .${classes.typographyLabel}`]: {
    color: linkWater,
  },
  [`& .${classes.buttonIcon}`]: {
    color: jordyBlue,
    "&:hover": {
      color: dodgerBlue,
    },
  },
  [`& .${classes.defaultChip}`]: {
    maxWidth: 90,
    maxHeight: 20,
    fontSize: 10,
  },
  [`& .${classes.greenChip}`]: {
    backgroundColor: deYork,
    color: white,
  },
  [`& .${classes.blueChip}`]: {
    backgroundColor: dodgerBlue,
    color: white,
  },
  [`& .${classes.orangeChip}`]: {
    backgroundColor: supernova,
    color: white,
  },
}));
