import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  contractTypeId: null,
  redGreenScheduleId: null,
  contractTermId: null,
  fiscalYearId: null,
  contractStatusId: null,
  dutyToReporFinancialInteresttId: null,
  dutyToReportSubContractingId: null,
  nationalAddendum: null,
  startDate: null,
  endDate: null,
  contractAdded: null,
  noticePeriod: EMPTY_STRING,
  competitiveClients: null,
  competitionClause: null,
  irfsAdded: null,
  gdprClause: null,
  contractSection: EMPTY_STRING,
};
