import React, { ChangeEvent, ReactElement, useEffect } from "react";

import { isEmpty } from "lodash";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { Grid, SelectChangeEvent, TableBody, Toolbar } from "@mui/material";

import {
  TableRowEmpty,
  ClearAll,
  TableFooter,
  TableHeader,
  DynamicFilter,
} from "core";

import {
  ClientDocumentTableRow,
  ClientDocumentStatusSearch,
} from "master-data/components";

import {
  useQuery,
  useFetch,
  FetchMethodEnum,
  useProfileGetter,
} from "shared/hooks";

import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { EMPTY_STRING } from "shared/constants";
import { DocumentOverview } from "master-data/types";
import { GET_DOCUMENTS_ACTION_OVERVIEW } from "master-data/constants";

import { Divider } from "shared/styles";
import {
  dynamicFilterOptions,
  defaultRowsPerPageOptions,
  sortOptions,
} from "./config";
import { ActionOverviewResponse, ParamTypes } from "./types";

import { classes, Table } from "./styles";

const { GET } = FetchMethodEnum;

export const ClientDocumentActionOverviewPage = (): ReactElement => {
  const { agencyName } = useParams<ParamTypes>();
  const {
    push,
    location: { search },
  } = useHistory();
  const { url: base } = useRouteMatch();
  const query = useQuery();
  const queryObject = Object.fromEntries(query);

  const {
    page: qPage,
    pageSize: qPageSize,
    sortByColumn,
    isascending,
    documentStatus,
    ...filters
  } = queryObject ?? { page: 1, pageSize: 10 };
  const agencyId = AgencyEnum[agencyName];
  useProfileGetter(agencyId);

  const isDisabledClearAllButton = documentStatus == null && isEmpty(filters);

  const apiUrl = generateUrl(GET_DOCUMENTS_ACTION_OVERVIEW, { agencyId });
  const { data, updateParams } = useFetch<ActionOverviewResponse>(apiUrl, {
    method: GET,
    initialParams: { ...queryObject },
  });

  const {
    items = [],
    page = 1,
    pageSize = 10,
    totalItems = 0,
  } = data?.get ?? {};

  // #region Effects

  useEffect(() => {
    // ! it is not possible to use existing queryObject due to refrence change on every render.
    const newQuery = new URLSearchParams(search);
    const newQueryObj = Object.fromEntries(newQuery);
    updateParams(newQueryObj);
  }, [search, updateParams]);
  // #endregion Effects

  // #region Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    const queryPageSize = query.get("pageSize");
    const queryParams = {
      ...queryObject,
      page: newPage + 1,
      pageSize: queryPageSize,
    }; // * newPage +1 reason is TablePagination page is zeroBased
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      pageSize: event.target.value,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Pagination

  // #region Sort
  const handleChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as string;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      page: 1,
      sortByColumn: targetVal ?? EMPTY_STRING,
      isascending: isAsc,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Sort

  // #region Filters
  const handleDocumentStatusTypesSearch = (statusId: string) => {
    const currentStatuses = documentStatus ? documentStatus.split("_") : [];
    const nextStatuses = currentStatuses.includes(statusId)
      ? currentStatuses.filter((s) => s !== statusId)
      : currentStatuses.concat(statusId);
    const queryParams = {
      ...queryObject,
      page: 1,
      documentStatus: isEmpty(nextStatuses)
        ? undefined
        : nextStatuses.join("_"),
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleClearAll = () => {
    const queryParams = {
      page: 1,
      pageSize: 10,
      sortByColumn: queryObject.sortByColumn,
      isascending: queryObject.isascending,
    };
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl);
  };

  const handleDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      page: 1,
      [key]: dynFilters[key],
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Filters

  return (
    <>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <ClientDocumentStatusSearch
                  onStatusClick={handleDocumentStatusTypesSearch}
                  selected={documentStatus}
                />
              </Grid>
              <Grid item>
                <Grid container alignContent="flex-start" alignItems="center">
                  <Grid item>
                    <DynamicFilter
                      updateUrl={handleDynamicFilter}
                      queryFilters={filters}
                      filterOptions={dynamicFilterOptions}
                      limit={3}
                    />
                  </Grid>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Grid item>
                    <ClearAll
                      onClear={handleClearAll}
                      disabled={isDisabledClearAllButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs={12}>
        <Table size="small" className={classes.table}>
          <TableHeader
            sortOptions={sortOptions}
            sortValue={queryObject.sortByColumn}
            onSortChange={handleChangeSort}
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <TableBody>
            {isEmpty(items) ? (
              <TableRowEmpty />
            ) : (
              items.map((doc: DocumentOverview) => (
                <ClientDocumentTableRow key={doc.documentId} {...doc} />
              ))
            )}
          </TableBody>
          <TableFooter
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </Grid>
    </>
  );
};

export default ClientDocumentActionOverviewPage;
