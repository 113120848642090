import React, { ReactElement } from "react";

import {
  AutocompleteValue,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteRenderInputParams,
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  IconButton,
  InputAdornment,
  TextField,
  AutocompleteFreeSoloValueMapping,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { History as HistoryIcon } from "@mui/icons-material";

import { EMPTY_STRING, REVISION_INIT_VALUE } from "shared/constants";

import { InfoPopper } from "../InfoPopper";

export interface AutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<OptionType, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "defaultValue" | "onChange"
  > {
  /**
   * control of useForm from react hook form.
   */
  control: Control;
  /**
   * The variant to use.
   */
  variant?: "outlined" | "filled" | "standard";
  /**
   * Array of options.
   */
  options: OptionType[];
  /**
   * If true, the label will be displayed in an error state.
   */
  error?: boolean;
  /**
   * The helper text content.
   */
  helperText?: string;
  /**
   * The label content.
   */
  label: string;
  /**
   * Name attribute of the input element.
   */
  name: string;
  /**
   * This prop is used to help implement the accessibility logic.
   * If you don't provide this prop.
   * It falls back to a randomly generated id.
   */
  id: string;
  /**
   * Previous value of field
   */
  revisionValue?: number | NullableString;
  /**
   * Default value for Controller
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: AutocompleteValue<any, Multiple, DisableClearable, FreeSolo>;
  /**
   * Render the input.
   *
   * @param {object} params
   * @returns {ReactNode}
   */
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;

  /**
   * Callback fired when the value changes.
   *
   * @param {object} event The event source of the callback.
   * @param {OptionType|OptionType[]} value The new value of the component.
   * @param {string} reason One of "create-option", "select-option", "remove-option", "blur" or "clear".
   * @param {function} onChangeRHF callback of react hook form fired when the value changes
   */
  onChange?: (
    event: React.SyntheticEvent,
    value: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeRHF: (...e: any[]) => void,
    details?: AutocompleteChangeDetails<OptionType>
  ) => void;
}

export const Autocomplete = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  control,
  options,
  error,
  helperText,
  variant,
  disabled,
  label,
  name,
  revisionValue,
  multiple,
  defaultValue,
  onChange,
  getOptionLabel,
  isOptionEqualToValue,
  renderInput,
  ...otherProps
}: AutocompleteProps<Multiple, DisableClearable, FreeSolo>): ReactElement => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  let revisionVal: string | undefined;
  if (revisionValue === null) {
    revisionVal = REVISION_INIT_VALUE;
  }
  if (typeof revisionValue === "number") {
    revisionVal = options.find((o) => o.key === revisionValue)?.value;
  }

  if (typeof revisionValue === "boolean") {
    revisionVal = revisionValue ? "Yes" : "No";
  }

  if (typeof revisionValue === "string" && multiple) {
    revisionVal = revisionValue
      .replace(/(^.*\[|\].*$)/g, EMPTY_STRING)
      .split(",")
      .map((id) => options.find((o) => o.key === Number(id))?.value)
      .join(",\n");
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const getOpObj = (
    option: any,
    currentOptions: OptionType[],
    reason?: string
  ) => {
    let newOption = option;
    if (typeof option !== "object") {
      newOption = currentOptions.find((op) => op.key === option);
    }
    if (multiple && reason) {
      newOption = option.map((opt: OptionType | number) =>
        typeof opt === "object" ? opt.key : opt
      );
    }
    return newOption;
  };

  const onChangeDefault = (
    _: React.SyntheticEvent,
    data: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeRHF: (...event: any[]) => void
  ) => {
    const option = getOpObj(data, options, reason);
    let value = null;
    if (multiple) {
      value = option;
    } else {
      value = option ? option.key : null;
    }

    onChangeRHF(value);
  };

  const getOptionLabelDefault = (
    option: OptionType | AutocompleteFreeSoloValueMapping<FreeSolo>
  ) => {
    return getOpObj(option, options).value;
  };

  const getOptionSelectedDefault = (option: OptionType, value: OptionType) => {
    return option.key === getOpObj(value, options).key;
  };

  const renderInputDefault = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      variant={variant}
      label={label}
      error={error}
      name={name}
      id={otherProps.id}
      helperText={helperText}
      InputProps={{
        ...params.InputProps,
        endAdornment: revisionVal ? (
          <>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle change value visibility"
                onClick={handleClick}
                ref={anchorRef}
              >
                <HistoryIcon color="primary" />
              </IconButton>
            </InputAdornment>
            {params.InputProps.endAdornment}
          </>
        ) : (
          params.InputProps.endAdornment
        ),
      }}
    />
  );

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MuiAutocomplete
            {...field}
            {...otherProps}
            multiple={multiple}
            options={options}
            getOptionLabel={getOptionLabel ?? getOptionLabelDefault}
            isOptionEqualToValue={
              isOptionEqualToValue ?? getOptionSelectedDefault
            }
            renderInput={renderInput ?? renderInputDefault}
            onChange={(event, data, reason, details) =>
              onChange
                ? onChange(event, data, reason, field.onChange, details)
                : onChangeDefault(event, data, reason, field.onChange)
            }
            disabled={disabled}
          />
        )}
      />
      {revisionVal && (
        <InfoPopper
          text={revisionVal}
          onClickAway={handleClickAway}
          open={open}
          anchorEl={anchorRef.current}
        />
      )}
    </>
  );
};

Autocomplete.defaultProps = {
  variant: "outlined",
  error: false,
  helperText: null,
  revisionValue: undefined,
  defaultValue: null,
  renderInput: null,
  onChange: null,
};

export default Autocomplete;
