import { styled, Button as MuiButton } from "@mui/material";
import { jordyBlue, black } from "shared/assets/colors";

const PREFIX = "UserGroupFormStyles";
export const classes = {
  deleteButton: `${PREFIX}-deleteButton`,
  cancelButton: `${PREFIX}-cancelButton`,
};

export const Button = styled(MuiButton)(() => ({
  [`&.${classes.deleteButton}`]: {
    color: jordyBlue,
    marginRight: "auto",
  },
  [`&.${classes.cancelButton}`]: {
    color: black,
  },
}));
