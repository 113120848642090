import React, { ReactElement } from "react";

import { Grid, TableCell, Tooltip, Typography, Checkbox } from "@mui/material";

import { PLACEHOLDER } from "shared/constants";
import { APP_PREFIX } from "access-manager/constants";
import { classes, TableRow } from "./styles";

export interface ApplicationMapTableRowProps {
  id: string;
  name: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onCheckboxClick: (selectedApplications: string) => void;
}

export const ApplicationMapTableRow = ({
  id,
  name,
  isChecked,
  isDisabled,
  onCheckboxClick,
}: ApplicationMapTableRowProps): ReactElement => {
  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={1}>
            <Checkbox
              id={`${APP_PREFIX}-check-${name}`}
              size="small"
              inputProps={{ "aria-label": "checkbox with small size" }}
              onChange={() => onCheckboxClick(id)}
              checked={isChecked}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title={name ?? PLACEHOLDER}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {name ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default ApplicationMapTableRow;
