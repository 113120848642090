import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  businessAddress: EMPTY_STRING,
  businessPostalCode: EMPTY_STRING,
  businessCity: EMPTY_STRING,
  businessCountry: EMPTY_STRING,
  mailingAddress: EMPTY_STRING,
  mailingPostalCode: EMPTY_STRING,
  mailingCity: EMPTY_STRING,
  mailingCountry: EMPTY_STRING,
  internalClientDocumentName: EMPTY_STRING,
};
