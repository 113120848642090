import { styled, TableRow as MuiTableRow } from "@mui/material";
import { sapphire, linkWater, gainsboro } from "../../../shared/assets/colors";

const PREFIX = "ImportUsersTableRowStyles";
export const classes = {
  cell: `${PREFIX}-cell`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyLabel: `${PREFIX}-typographyLabel`,
};

export const TableRow = styled(MuiTableRow)(() => ({
  [`& .${classes.cell}`]: {
    border: `1px solid ${gainsboro}`,
  },
  [`& .${classes.typographyValue}`]: {
    color: sapphire,
  },
  [`& .${classes.typographyLabel}`]: {
    color: linkWater,
  },
}));
