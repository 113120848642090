import React, { ReactElement } from "react";

import {
  Radio,
  FormLabel,
  IconButton,
  FormControl,
  FormHelperText,
  FormControlLabel,
  RadioGroupProps as MuiRadioGroupProps,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { History as HistoryIcon } from "@mui/icons-material";

import { InfoPopper } from "../InfoPopper";

import { classes, MuiRadioGroup } from "./styles";

export interface RadioGroupProps extends MuiRadioGroupProps {
  control: Control;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  name: string;
  label: string;
  /**
   * Previous value of field
   */
  revisionValue?: string;
}
// TODO: make generic FormControlLabels
export const RadioGroup = ({
  control,
  error,
  helperText,
  disabled,
  name,
  label,
  revisionValue,
  defaultValue,
  ...otherProps
}: RadioGroupProps): ReactElement => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <>
      <FormControl component="fieldset" error={error}>
        <div>
          {revisionValue && (
            <IconButton
              aria-label="toggle change value visibility"
              onClick={handleClick}
              ref={anchorRef}
            >
              <HistoryIcon color="primary" />
            </IconButton>
          )}
          <FormLabel component="legend">{label}</FormLabel>
        </div>
        <Controller
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => {
            const current = parseInt(field.value, 10);
            return (
              <MuiRadioGroup
                {...field}
                {...otherProps}
                name={name}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label="Creative"
                  labelPlacement="bottom"
                  disabled={disabled}
                  checked={current === 1}
                />
                <FormControlLabel
                  value={3}
                  control={<Radio color="primary" />}
                  label="Digital"
                  labelPlacement="bottom"
                  disabled={disabled}
                  checked={current === 3}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label="PR"
                  labelPlacement="bottom"
                  disabled={disabled}
                  checked={current === 2}
                />
                <FormControlLabel
                  value={4}
                  control={<Radio color="primary" />}
                  label="Other"
                  labelPlacement="bottom"
                  disabled={disabled}
                  checked={current === 4}
                />
              </MuiRadioGroup>
            );
          }}
          control={control}
        />
      </FormControl>
      <FormHelperText error>{helperText}</FormHelperText>
      {revisionValue && (
        <InfoPopper
          text={revisionValue}
          onClickAway={handleClickAway}
          open={open}
          anchorEl={anchorRef.current}
        />
      )}
    </>
  );
};

RadioGroup.defaultProps = {
  error: null,
  helperText: null,
  disabled: false,
  revisionValue: undefined,
};

export default RadioGroup;
