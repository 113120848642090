export const CHANGE_STATUS_DIALOG_TITLE = "Change Client Document Status";
export const DECLINE_DIALOG_TITLE = "Decline Client Document";
export const DELETE_CLIENT_DOCUMENT_DIALOG_TITLE = "Delete Client Document";
export const CHANGE_STATUS_DIALOG_CONTENT =
  "Are you sure you want to change client document status?";
export const DECLINE_DIALOG_CONTENT =
  "Are you sure you want to decline client document?";
export const DELETE_CLIENT_DOCUMENT_DIALOG_CONTENT =
  "Are you sure you want to delete client document?";
export const REVIEWER_CREATE_WORKFLOW_DIALOG_CONTENT =
  "Please select a reviewer:";
export const REVIEWER_WORKFLOW_DIALOG_CONTENT_INFO =
  " (This reviewer will get an email to review this document)";
export const ARCHIVE_CLIENT_DIALOG_TITLE = "Archive Client Confirmation";
export const ARCHIVE_CLIENT_DIALOG_CONTENT =
  "Are you sure you want to archive client document?";
export const UNARCHIVE_CLIENT_DIALOG_TITLE = "Unarchive Client Confirmation";
export const UNARCHIVE_CLIENT_DIALOG_CONTENT =
  "Are you sure you want to unarchive client document?";

export const CHANGE_STATUS_DIALOG_BUTTON_TEXT = "CHANGE STATUS";
export const DECLINE_DIALOG_BUTTON_TEXT = "DECLINE";
export const DECLINE_SEARCH_TEXT = "Decline";
export const VALIDATE_CLAIM = "ValidateClientDocument";
export const DELETE_DOCUMENT_CLAIM = "DeleteClientDocument";
export const ARCHIVE_DOCUMENT_CLAIM = "ArchiveClientDocument";
export const UNARCHIVE_DOCUMENT_CLAIM = "UnarchiveClientDocument";
export const CEO_APPROVER_CLAIM = "IsCeoApprover";

// #region URL
export const DASHBOARD = "dashboard";
export const CLIENT = "client";
export const PRODUCT = "product";
export const PAGE_OVERVIEW = "overview";
export const PAGE_DASHBOARD_CLIENT = `${DASHBOARD}/${CLIENT}`;
export const PAGE_DASHBOARD_PRODUCT = `${DASHBOARD}`;
export const PAGE_OVERVIEW_ACTION = "action-overview";
export const AGENCY_PAGES = ":agencyName";
export const APP_MASTERDATA = "master-data";
// #endregion URL
