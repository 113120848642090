import React, { ReactElement, ReactNode } from "react";

import {
  NavLink as MuiNavLink,
  NavLinkProps as MuiNavLinkProps,
} from "react-router-dom";

import { ClaimRoute } from "../ClaimRoute";

export enum NavLinkTypeEnum {
  native = "native",
  preventable = "preventable",
}

export type NavLinkType = keyof typeof NavLinkTypeEnum;

export interface NavLinkProps extends MuiNavLinkProps {
  /**
   * @param isActive flag is true when url matches route path
   */
  children: ReactNode | ((isAcive: boolean) => ReactElement);
  /**
   * Type of link `native` or `preventable`.
   * If `preventable` type link will not be clickable when active
   */
  type?: NavLinkType;
  rule?: string;
}

export const NavLink = ({
  to,
  rule,
  exact,
  children,
  type = NavLinkTypeEnum.native,
  ...otherProps
}: NavLinkProps): ReactElement => {
  const path = (typeof to === "object" ? to.pathname : to) as string;

  const onClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    isActive: boolean
  ) => {
    if (isActive && type === NavLinkTypeEnum.preventable) {
      event.preventDefault();
    }
  };

  return (
    <ClaimRoute rule={rule} path={path} exact={exact}>
      {({ match }) => {
        const isActive = Boolean(match);
        return (
          <MuiNavLink
            {...otherProps}
            to={to}
            exact={exact}
            onClick={(e) => onClick(e, isActive)}
          >
            {typeof children === "function" ? children(isActive) : children}
          </MuiNavLink>
        );
      }}
    </ClaimRoute>
  );
};

export default NavLink;
