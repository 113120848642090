import React, { ReactElement, useEffect } from "react";
import { Grid } from "@mui/material";

import { FieldErrors, Control } from "react-hook-form";

import { TextField, RadioGroup } from "core";

import { EMPTY_STRING } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { getAgencyTypeName } from "../RelatedContacts/config";
import { Current, IRevisionEntriesInput } from "../RelatedContacts/types";

export interface RelatedContactsEntryProps {
  control: Control;
  current: Current;
  errors?: FieldErrors;
  isEdit: boolean;
  /**
   * Fill form option type
   */
  fillOption?: FillFormOptionType;
  /**
   * setValues
   */
  setValue: any;
  /**
   * resetFillOption
   */
  resetFillOption: (state: FillFormOptionType | undefined) => void;
  revisionValues?: IRevisionEntriesInput;
  /**
   * onDialogSave
   */
  onDialogSave: () => void;
}

export const RelatedContactsEntry = ({
  control,
  current,
  errors = {},
  isEdit,
  revisionValues,
  fillOption,
  setValue,
  onDialogSave,
  resetFillOption,
}: RelatedContactsEntryProps): ReactElement => {
  const {
    rowIndex,
    agencyTypeId,
    contactPerson,
    companyName,
    jobTitle,
    email,
    phone,
  } = current ?? {};

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue(`entries.${rowIndex}.contactPerson`, "Test Test");
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue(`entries.${rowIndex}.companyName`, "Levi9");
        setValue(`entries.${rowIndex}.jobTitle`, "Test Job Title");
        setValue(`entries.${rowIndex}.email`, "test@test.com");
        setValue(`entries.${rowIndex}.phone`, "011 2332333");
      }
    }
    return () => {
      if (
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillMandatoryAndSave
      ) {
        onDialogSave();
      }
      resetFillOption(undefined);
    };
  }, [fillOption, setValue, rowIndex, resetFillOption, onDialogSave]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <RadioGroup
          row
          name={`entries.${rowIndex}.agencyTypeId`}
          control={control}
          defaultValue={agencyTypeId?.toString()}
          id="agencyTypeId"
          label="Agency type *:"
          error={!!errors.agencyTypeId?.message}
          helperText={errors.agencyTypeId?.message}
          revisionValue={getAgencyTypeName(revisionValues?.AgencyTypeId)}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.contactPerson`}
          control={control}
          defaultValue={contactPerson ?? EMPTY_STRING}
          id="contactPerson"
          label="Contact Person *"
          error={!!errors.contactPerson?.message}
          helperText={errors.contactPerson?.message}
          revisionValue={revisionValues?.ContactPerson}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.companyName`}
          control={control}
          defaultValue={companyName ?? EMPTY_STRING}
          id="companyName"
          label="Company Name"
          error={!!errors.companyName?.message}
          helperText={errors.companyName?.message}
          revisionValue={revisionValues?.CompanyName}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.jobTitle`}
          control={control}
          defaultValue={jobTitle ?? EMPTY_STRING}
          id="jobTitle"
          label="Job Title"
          error={!!errors.jobTitle?.message}
          helperText={errors.jobTitle?.message}
          revisionValue={revisionValues?.JobTitle}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.email`}
          control={control}
          defaultValue={email ?? EMPTY_STRING}
          id="email"
          label="Email Address"
          error={!!errors.email?.message}
          helperText={errors.email?.message}
          revisionValue={revisionValues?.Email}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.phone`}
          control={control}
          defaultValue={phone ?? EMPTY_STRING}
          id="phone"
          label="Phone Number"
          error={!!errors.phone?.message}
          helperText={errors.phone?.message}
          revisionValue={revisionValues?.Phone}
          disabled={!isEdit}
        />
      </Grid>
    </Grid>
  );
};

export default RelatedContactsEntry;
