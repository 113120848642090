import { styled, ListItem as MuiListItem } from "@mui/material";
import { baliHai } from "shared/assets/colors";

const PREFIX = "MenuItemStyles";
export const classes = {
  item: `${PREFIX}-item`,
  itemIcon: `${PREFIX}-itemIcon`,
};

export const ListItem = styled(MuiListItem)(({ theme }) => ({
  [`&.${classes.item}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    color: baliHai,
    textDecoration: "none",
    cursor: "pointer",
  },
  [`& .${classes.itemIcon}`]: {
    color: baliHai,
    textDecoration: "none",
  },
}));
