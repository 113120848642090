import React, { ReactElement } from "react";

import { array, number, object, string, boolean } from "yup";
import { MenuItem, Select as MuiSelect } from "@mui/material";

import {
  InputCell,
  ColumnType,
  NumericCell,
  CellTypeEnum,
  RenderCellProps,
} from "core";

import {
  EMPTY_STRING,
  MAX_NUMBER_FOR_INPUTS,
  MIN_NUMBER_FOR_INPUTS,
  MAX_NUMBER_VALIDATION_MESSAGE,
  MIN_NUMBER_VALIDATION_MESSAGE,
  NUMBER_FIELD_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_ENTRY_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";
import { emptyStringToNull, nanToNull } from "shared/utils";

// #region columns definition
export const columns: ColumnType[] = [
  {
    field: "id",
    headerName: "Id",
    hide: true,
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "kpiId",
    headerName: "Which KPI's",
    cellType: CellTypeEnum.Select,
    renderCell: ({ field, options = [] }: RenderCellProps): ReactElement => {
      return (
        <MuiSelect
          disabled
          {...field}
          IconComponent={() => <></>}
          SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
        >
          <MenuItem value={EMPTY_STRING}>
            <em>None</em>
          </MenuItem>
          {options.map(({ key, value }: OptionType) => {
            return (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </MuiSelect>
      );
    },
  },
  {
    field: "percentage",
    headerName: "Percentage",
    cellType: CellTypeEnum.NumberFormat,
    renderCell: ({ field }: RenderCellProps) => (
      <NumericCell {...field} suffix="%" />
    ),
  },
  {
    field: "precondition",
    headerName: "Precondition",
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
];
// #endregion columns definition

const formSchema = {
  id: number().transform(nanToNull).nullable(),
  kpiId: number()
    .nullable()
    .transform(nanToNull)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  percentage: number()
    .nullable()
    .transform(nanToNull)
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MIN_NUMBER_VALIDATION_MESSAGE),
  precondition: string()
    .nullable()
    .transform(emptyStringToNull)
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
};

export const validationSchema = object().shape({
  clientIncetive: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  chargeCancellationCosts: number().nullable().transform(nanToNull),
  kpiApply: number().nullable().transform(nanToNull),
  additionalConditionsInContract: boolean().nullable(),
  entries: array()
    .of(object().shape(formSchema))
    .when("kpiApply", {
      is: (kpiApply: number) => kpiApply === 1,
      then: array()
        .required(MANDATORY_ENTRY_VALIDATION_MESSAGE)
        .min(1, MANDATORY_ENTRY_VALIDATION_MESSAGE),
      otherwise: array().nullable(),
    }),
});
