import React, { ChangeEvent, useState, useEffect, ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { useFetch, FetchMethodEnum, useQuery } from "shared/hooks";

import { StatusData } from "master-data/types";
import { AgencyEnum, ClientDocumentStatus } from "master-data/enums";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import { generateUrl, isNullOrUndefinedOrEmpty } from "shared/utils";
import { setDocumentStatus, setIsDocumentEditable } from "store/actions";
import {
  DECLINE_DIALOG_TITLE,
  DECLINE_DIALOG_CONTENT,
  DECLINE_DIALOG_BUTTON_TEXT,
  CHANGE_STATUS_DIALOG_TITLE,
  CHANGE_EDIT_DOCUMENT_STATUS,
  CHANGE_STATUS_DIALOG_CONTENT,
  CHANGE_CREATE_DOCUMENT_STATUS,
  CHANGE_STATUS_DIALOG_BUTTON_TEXT,
  REVIEWER_CREATE_WORKFLOW_DIALOG_CONTENT,
  GET_REVIEWERS,
  REVIEWER_WORKFLOW_DIALOG_CONTENT_INFO,
} from "master-data/constants";
import { Person as PersonIcon } from "@mui/icons-material";

import { classes, Dialog, Select } from "./styles";
import { IGetResponse, IStatusResponse } from "./business/types";
import { getRedirectUrlByStatusId } from "../FooterBar/config";

const { GET, PUT } = FetchMethodEnum;

export const StatusDialog = (): ReactElement => {
  const { goBack, push } = useHistory();
  const query = useQuery();
  const [comment, setComment] = useState<NullableString>(null);
  const [selectedReviewer, setSelectedReviewer] = useState<number>(0);

  const isDeclinedAction = query.get("declined")?.toLowerCase() === "true";
  const changeStatusId = parseInt(query.get("statusId") ?? "null", 10) || null;

  const { agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];

  const {
    state: { statusData, clientDocumentData },
    actions,
  } = useStateMachine({
    setIsDocumentEditable,
    setDocumentStatus,
  });
  const { statusId: currentStatusId, statusTransitions } =
    (statusData as StatusData) ?? {};
  const { selectedReviewerId } = clientDocumentData ?? {};
  const {
    data,
    executeFetch,
    isSuccessful,
    hasError,
    errorMessage,
    isLoading,
  } = useFetch<IGetResponse, IStatusResponse>(null, {
    skip: true,
  });
  const { get: getData, post: postData } = data ?? {};
  const { reviewers } = (getData as IGetResponse) ?? {};

  const isVisibileChangeStatusText =
    !reviewers || (reviewers && reviewers.length === 0);
  const isActionDisabled =
    (isDeclinedAction && isNullOrUndefinedOrEmpty(comment?.trim())) ||
    isLoading;

  // #region  Methods
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const resetState = () => {
    setComment(null);
  };

  const changeStatus = () => {
    const url = generateUrl(
      changeStatusId
        ? CHANGE_CREATE_DOCUMENT_STATUS
        : CHANGE_EDIT_DOCUMENT_STATUS,
      {
        agencyId,
        documentId,
      }
    );

    let body = {};
    if (isDeclinedAction) {
      body = changeStatusId
        ? {
            currentStatusId,
            statusId: changeStatusId,
            comment: comment?.trim(),
          }
        : { currentStatusId, isDeclined: true, comment: comment?.trim() };
    } else {
      body = changeStatusId
        ? {
            currentStatusId,
            statusId: changeStatusId,
            selectedReviewer: selectedReviewer ?? null,
          }
        : {
            currentStatusId,
            isDeclined: false,
            selectedReviewer: selectedReviewer ?? null,
          };
    }

    executeFetch(url, { method: PUT, body, skip: false });
    resetState();
  };

  const handleSelectReviewer = (event: SelectChangeEvent<any>) => {
    setSelectedReviewer(Number(event.target.value));
  };
  // #endregion Methods

  // #region Effects
  useEffect(() => {
    if (postData && isSuccessful) {
      const { isDocumentEditable, status } = postData ?? {
        isDocumentEditable: false,
        status: {
          statusId: null,
          statusName: null,
          statusTransitions: [],
          hasComment: false,
        },
      };
      const { statusId } = status ?? {};
      const redirectUrl =
        statusId && getRedirectUrlByStatusId(statusId, agencyName, documentId);

      actions.setIsDocumentEditable(isDocumentEditable);
      actions.setDocumentStatus(status);

      if (redirectUrl) {
        push(redirectUrl);
      }
    }
  }, [
    push,
    postData,
    actions,
    hasError,
    agencyName,
    documentId,
    errorMessage,
    isSuccessful,
  ]);

  useEffect(() => {
    if (
      statusTransitions[0]?.statusId === ClientDocumentStatus.ReadyForReview ||
      currentStatusId === ClientDocumentStatus.FinalizedChanged
    ) {
      const getReviewersUrl = generateUrl(GET_REVIEWERS, {
        documentId,
        agencyId,
      });

      executeFetch(getReviewersUrl, {
        method: GET,
        skip: false,
      });
    }
  }, [agencyId, documentId, executeFetch, statusTransitions, currentStatusId]);
  // #endregion Effects

  useEffect(() => {
    if (getData) {
      setSelectedReviewer(selectedReviewerId ?? 0);
    }
  }, [getData, selectedReviewerId]);

  return (
    <Dialog open maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle id="status-dialog-title">
        {isDeclinedAction ? DECLINE_DIALOG_TITLE : CHANGE_STATUS_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={3}>
          {isVisibileChangeStatusText && (
            <Grid item>
              <Typography variant="body1" gutterBottom>
                {isDeclinedAction
                  ? DECLINE_DIALOG_CONTENT
                  : CHANGE_STATUS_DIALOG_CONTENT}
                {isDeclinedAction && (
                  <TextField
                    id="status-dialog-comment"
                    label="Comment *"
                    className={classes.textArea}
                    placeholder="Please provide reason for declining"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={comment}
                    onChange={onChange}
                    inputProps={{ maxLength: 1000 }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Typography>
            </Grid>
          )}
          <Grid item>
            {!isVisibileChangeStatusText && (
              <>
                <Grid
                  container
                  spacing={2}
                  alignContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="body1" gutterBottom>
                      {REVIEWER_CREATE_WORKFLOW_DIALOG_CONTENT}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {REVIEWER_WORKFLOW_DIALOG_CONTENT_INFO}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <PersonIcon color="primary" />
                  </Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ minWidth: 80 }}>
                      <Select
                        id="reviewers"
                        name="reviewers"
                        value={selectedReviewer}
                        onChange={handleSelectReviewer}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 190,
                              marginLeft: 20,
                            },
                          },
                        }}
                      >
                        <MenuItem key={0} id="reviewer-empty" value={0}>
                          None
                        </MenuItem>
                        {reviewers?.map(({ reviewerId, reviewerName }) => (
                          <MenuItem
                            key={reviewerId || undefined}
                            id={`reviewer-${reviewerId}`}
                            value={reviewerId || undefined}
                          >
                            {reviewerName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id="change-status-cancel"
          onClick={() => goBack()}
          color="secondary"
          disabled={isLoading}
        >
          CANCEL
        </Button>
        <Button
          id="change-status-submit"
          onClick={changeStatus}
          disabled={isActionDisabled}
          color="primary"
        >
          {isDeclinedAction
            ? DECLINE_DIALOG_BUTTON_TEXT
            : CHANGE_STATUS_DIALOG_BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusDialog;
