import React, { FC, useEffect, useState, ReactElement } from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from "@hookform/devtools";
import { Prompt, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import {
  KEY_ENTER,
  TAG_TEXTAREA,
  UNSAVED_DATA_MESSAGE,
} from "shared/constants";
import { generateUrl } from "shared/utils";
import { setIsDocumentStatusChanged } from "store/actions";
import {
  AgencyEnum,
  AccordionEnum,
  FillFormOptionType,
} from "master-data/enums";
import { FormStandardAccordionType } from "master-data/types";
import { GET_FORM, MANAGE_STANDARD_FORM } from "master-data/constants";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";

import {
  FormsProps,
  IFormsInput,
  IFormsResponseGet,
  IFormsResponsePost,
  IFormsRevisionInput,
} from "./types";
import {
  FormGeneralClientInfo,
  FormGeneralClientInfoProps,
} from "./GeneralClientInfo";
import {
  FormGeneralContractInfo,
  FormGeneralContractInfoProps,
} from "./GeneralContractInfo";
import {
  FormInvoicingAddressMethod,
  FormInvoicingAddressMethodProps,
} from "./InvoicingAddressMethod";

import { FormAudit, FormAuditProps } from "./Audit";
import { FormInterest, FormInterestProps } from "./Interest";
import { FormEvouching, FormEvouchingProps } from "./Evouching";
import { FormClientTeam, FormClientTeamProps } from "./ClientTeam";
import { FormAdmanagement, FormAdmanagementProps } from "./Admanagement";
import { FormInventoryMedia, FormInventoryMediaProps } from "./InventoryMedia";

import {
  FormScopeOfServices,
  FormScopeOfServicesProps,
} from "./ScopeOfServices";
import {
  FormCreditInsurance,
  FormCreditInsuranceProps,
} from "./CreditInsurance";
import { FormRevenueRecognition } from "./RevenueRecogniton";
import {
  FormOutOfScopeServices,
  FormOutOfScopeServicesProps,
} from "./OutOfScopeServices";
import {
  FormSupplierAgreements,
  FormSupplierAgreementsProps,
} from "./SupplierAgreements";
import {
  FormHoursAccountability,
  FormHoursAccountabilityProps,
} from "./HoursAccountability";
import {
  FormAdditionalClientInfo,
  FormAdditionalClientInfoProps,
} from "./AdditionalClientInfo";
import {
  FormCommercialAgreementsProps,
  FormCommercialAgreements,
} from "./CommercialAgreements";
import {
  FormInvoicingRequirements,
  FormInvoicingRequirementsProps,
} from "./InvoicingRequirements";
import {
  FormFinancialUnbilledMedia,
  FormFinancialUnbilledMediaProps,
} from "./FinancialUnbilledMedia";
import { FormFinancialAvbs, FormFinancialAvbsProps } from "./FinancialAvbs";
import { FormFinancialEpds, FormFinancialEpdsProps } from "./FinancialEpds";
import {
  FormAllowableDeducationsRebates,
  FormAllowableDeducationsRebatesProps,
} from "./AllowableDeducationsRebates";
import {
  FormAdditionalInvoicingRequirements,
  FormAdditionalInvoicingRequirementsProps,
} from "./AdditionalInvoicingRequirements";
import {
  FormDigitalAccountabilityBrandSafety,
  FormDigitalAccountabilityBrandSafetyProps,
} from "./DigitalAccountabilityBrandSafety";

import { defaults } from "./constants";
import { mapToFormType, schemas } from "./config";
import { FormStandardFieldProps } from "./props";

import { classes, Root } from "./styles";

const standard: {
  [key in FormStandardAccordionType]: FC<FormsProps[key]>;
} = {
  generalClientInfo: FormGeneralClientInfo,
  additionalClientInfo: FormAdditionalClientInfo,
  creditInsurance: FormCreditInsurance,
  clientTeam: FormClientTeam,

  financialAvbs: FormFinancialAvbs,
  financialEpds: FormFinancialEpds,
  generalContractInfo: FormGeneralContractInfo,
  financialUnbilledMedia: FormFinancialUnbilledMedia,
  inventoryMedia: FormInventoryMedia,
  allowableDeducationsRebates: FormAllowableDeducationsRebates,
  audit: FormAudit,
  interest: FormInterest,
  admanagement: FormAdmanagement,
  digitalAccountabilityBrandSafety: FormDigitalAccountabilityBrandSafety,
  evouching: FormEvouching,
  revenueRecognition: FormRevenueRecognition,
  scopeOfServices: FormScopeOfServices,
  outOfScopeServices: FormOutOfScopeServices,
  supplierAgreements: FormSupplierAgreements,

  invoicingAddressMethod: FormInvoicingAddressMethod,
  invoicingRequirements: FormInvoicingRequirements,
  // invoicingRequirementsV3: FormInvoicingRequirementsV3,  // Left as a template for future work on old forms
  additionalInvoicingRequirements: FormAdditionalInvoicingRequirements,

  hoursAccountability: FormHoursAccountability,
  commercialAgreements: FormCommercialAgreements,
};

const handler = {
  get(target: any, name: string) {
    return Object.prototype.hasOwnProperty.call(target, name)
      ? target[name]
      : () => <></>;
  },
};

const standardForms = new Proxy<{
  [key in FormStandardAccordionType]: FC<FormsProps[key]>;
}>(standard, handler);

export interface FormStandardProps {
  /**
   * Accordion type of Standard Form
   * @type FormStandardAccordionType
   */
  type: FormStandardAccordionType;
  /**
   * Flag indicating if form is in edit mode
   */
  isEdit: boolean;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
  /**
   * Callback fired when form is submitted
   */
  onSave: () => void;
}

export const FormStandard = ({
  type,
  isEdit,
  onSave,
  fillOption,
}: FormStandardProps): ReactElement => {
  const [isNew, setIsNew] = useState(true);

  const {
    state: { clientDocumentData },
    actions,
  } = useStateMachine({ setIsDocumentStatusChanged });

  const { isContract, changes } = clientDocumentData;

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isDirty },
  } = useForm<IFormsInput[typeof type]>({
    defaultValues: defaults[type],
    resolver: yupResolver(schemas[type]),
    context: { isContract },
  });

  const { GET, POST, PUT } = FetchMethodEnum;
  const { accordionType, agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];
  const accordionId = AccordionEnum[accordionType];
  const url = generateUrl(GET_FORM, {
    agencyId,
    documentId,
    accordionId,
  });

  const { data, executeFetch, resetPostData } = useFetch<
    IFormsResponseGet[typeof type],
    IFormsResponsePost[typeof type]
  >(url, {
    method: GET,
  });

  const { get: getData, post: postData } = data ?? {};

  // #region Methods
  const onSubmit = (submittedData: IFormsInput[typeof type]) => {
    const submitUrl = generateUrl(MANAGE_STANDARD_FORM[type], {
      agencyId,
      documentId,
    });
    const method = isNew ? POST : PUT;

    executeFetch(submitUrl, { method, body: submittedData });
  };

  const onKeyPressInField = (event: React.KeyboardEvent<HTMLElement>) => {
    const tag = event.target as HTMLElement;
    if (event.key === KEY_ENTER && tag.tagName !== TAG_TEXTAREA) {
      event.preventDefault();
    }
  };
  // #endregion Methods

  // #region Effects
  useEffect(() => {
    if (!isEdit) {
      reset();
    }
  }, [isEdit, reset]);

  useEffect(() => {
    const { form } = (getData ?? {}) as any;
    if (form) {
      setIsNew(false);
      reset(form);
    }
  }, [getData, reset]);

  useEffect(() => {
    const { isSuccessful, isDocumentStatusChanged, ...restData } = (postData ??
      {}) as any;
    if (isSubmitSuccessful && isSuccessful) {
      setIsNew(false);
      reset(restData);
      actions.setIsDocumentStatusChanged(isDocumentStatusChanged);
      resetPostData();
      onSave();
    }
  }, [postData, isSubmitSuccessful, reset, onSave, resetPostData, actions]);
  // #endregion Effects

  const formType = mapToFormType(type);
  const revisionValues = changes
    ? (changes[formType] as IFormsRevisionInput[typeof type])
    : {};

  const { isSuccessful }: { isSuccessful: boolean } = (postData ?? {}) as any;

  // #region props
  const defaultFormProps: FormStandardFieldProps = {
    revisionValues,
    errors,
    isEdit,
    control,
  };

  const generalClientInfoProps: FormGeneralClientInfoProps = {
    ...defaultFormProps,
    formData: getData as any,
    getValues,
    isNew,
    setValue,
    isSubmitSuccessful: isSubmitSuccessful && isSuccessful,
    fillOption,
  };

  const generalAdditionalInfoProps: FormAdditionalClientInfoProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const creditInsuranceProps: FormCreditInsuranceProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const clientTeamProps: FormClientTeamProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const supplierAgreementsProps: FormSupplierAgreementsProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const financialAvbsProps: FormFinancialAvbsProps = {
    ...defaultFormProps,
    watch,
    setValue,
    fillOption,
  };

  const financialEpdsProps: FormFinancialEpdsProps = {
    ...defaultFormProps,
    watch,
    setValue,
    fillOption,
  };

  const financialUnbilledMediaProps: FormFinancialUnbilledMediaProps = {
    ...defaultFormProps,
    watch,
    setValue,
    fillOption,
  };

  const generalContractInfoProps: FormGeneralContractInfoProps = {
    ...defaultFormProps,
    setValue,
    isNew,
    fillOption,
  };

  const inventoryMediaProps: FormInventoryMediaProps = {
    ...defaultFormProps,
    watch,
    fillOption,
    setValue,
  };

  const allowableDeducationsRebatesProps: FormAllowableDeducationsRebatesProps =
    {
      ...defaultFormProps,
      setValue,
      fillOption,
    };

  const auditProps: FormAuditProps = {
    ...defaultFormProps,
    watch,
    setValue,
    fillOption,
  };

  const interestProps: FormInterestProps = {
    ...defaultFormProps,
    fillOption,
    setValue,
  };

  const admanagementProps: FormAdmanagementProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const digitalAccountabilityBrandSafetyProps: FormDigitalAccountabilityBrandSafetyProps =
    {
      ...defaultFormProps,
      setValue,
      fillOption,
    };

  const evouchingProps: FormEvouchingProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const scopeOfServicesProps: FormScopeOfServicesProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const outOfScopeOfServicesProps: FormOutOfScopeServicesProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const invoicingAddressMethodProps: FormInvoicingAddressMethodProps = {
    ...defaultFormProps,
    formData: getData as any,
    getValues,
    isNew,
    setValue,
    fillOption,
  };

  const invoicingRequirementsProps: FormInvoicingRequirementsProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const additionalInvoicingRequirementsProps: FormAdditionalInvoicingRequirementsProps =
    {
      ...defaultFormProps,
      setValue,
      fillOption,
    };

  const hoursAccountabilityProps: FormHoursAccountabilityProps = {
    ...defaultFormProps,
    setValue,
    fillOption,
  };

  const commercialAgreementsProps: FormCommercialAgreementsProps = {
    ...defaultFormProps,
    watch,
    setValue,
    fillOption,
  };

  const allFormProps: {
    [key in FormStandardAccordionType]: any; // TODO: any is workaround. FormsProps[key] does not work, try to find valid solution
  } = {
    generalClientInfo: generalClientInfoProps,
    additionalClientInfo: generalAdditionalInfoProps,
    creditInsurance: creditInsuranceProps,
    clientTeam: clientTeamProps,

    financialAvbs: financialAvbsProps,
    financialEpds: financialEpdsProps,
    generalContractInfo: generalContractInfoProps,
    financialUnbilledMedia: financialUnbilledMediaProps,
    inventoryMedia: inventoryMediaProps,
    allowableDeducationsRebates: allowableDeducationsRebatesProps,
    audit: auditProps,
    interest: interestProps,
    admanagement: admanagementProps,
    digitalAccountabilityBrandSafety: digitalAccountabilityBrandSafetyProps,
    evouching: evouchingProps,
    revenueRecognition: defaultFormProps,
    scopeOfServices: scopeOfServicesProps,
    outOfScopeServices: outOfScopeOfServicesProps,
    supplierAgreements: supplierAgreementsProps,

    invoicingAddressMethod: invoicingAddressMethodProps,
    invoicingRequirements: invoicingRequirementsProps,
    // invoicingRequirementsV3: defaultFormProps,  // Left as a template for future work on old forms
    additionalInvoicingRequirements: additionalInvoicingRequirementsProps,

    hoursAccountability: hoursAccountabilityProps,
    commercialAgreements: commercialAgreementsProps,
  };
  // #endregion props

  const Form = standardForms[type];
  const formProps = allFormProps[type];

  return (
    <>
      {process.env.NODE_ENV !== "production" && (
        <DevTool control={control} placement="top-left" />
      )}
      <Root
        id={`${type}Form`}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={onKeyPressInField}
        role="presentation"
        className={classes.form}
      >
        <Form {...formProps} />
      </Root>
      <Prompt when={isDirty} message={UNSAVED_DATA_MESSAGE} />
    </>
  );
};

export default FormStandard;
