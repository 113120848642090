import React, { ReactElement } from "react";

import { Tooltip } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { documentStatusIcons } from "master-data/components/icons";

import {
  GET_OVERVIEW_STATUSES,
  GET_ACTION_OVERVIEW_STATUSES,
} from "master-data/constants";
import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { DocumentStatusType } from "master-data/types";
import { ParamTypes } from "master-data/pages/ClientDocumentOverview/types";

import { EMPTY_STRING } from "shared/constants";
import { classes, IconButton } from "./styles";

const { GET } = FetchMethodEnum;
interface StatusesResponse {
  statuses: Array<Status>;
}

interface Status {
  statausId: number; // TODO: typo in API response
  description: DocumentStatusType;
}

export interface ClientDocumentStatusSearchProps {
  onStatusClick: (selectedId: string) => void;
  selected: string;
}

const getUrlApi = (loc: string): string => {
  const lastPartOfUrl = loc.substr(loc.lastIndexOf("/") + 1);
  if (lastPartOfUrl === "action-overview") {
    return GET_ACTION_OVERVIEW_STATUSES;
  }
  return GET_OVERVIEW_STATUSES;
};

export const ClientDocumentStatusSearch = ({
  selected,
  onStatusClick,
}: ClientDocumentStatusSearchProps): ReactElement => {
  const location = useLocation();
  const { agencyName } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];

  const apiUrl = getUrlApi(location.pathname);
  const endpoint = generateUrl(apiUrl, { agencyId });
  const { data } = useFetch<StatusesResponse>(endpoint, {
    method: GET,
  });
  const { statuses = [] } = data?.get ?? {};

  const isSelected = (id: number) => {
    const selectedIds = selected ? selected.split("_") : [];
    return selectedIds.includes(id.toString());
  };

  return (
    <>
      {statuses?.map((status) => (
        <Tooltip key={status.statausId} title={status.description}>
          <IconButton
            className={`${classes.button} ${
              isSelected(status.statausId) ? classes.active : EMPTY_STRING
            }`}
            onClick={() => onStatusClick(status.statausId.toString())}
            id={`filter-documentStatus-${status.statausId}`}
          >
            {documentStatusIcons[status.description]}
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
};

export default ClientDocumentStatusSearch;
