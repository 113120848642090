import { styled, Grid as MuiGrid } from "@mui/material";

const PREFIX = "LastDeclineStatusStyles";
export const classes = {
  container: `${PREFIX}-container`,
  caption: `${PREFIX}-caption`,
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.container}`]: {
    marginBottom: theme.typography.pxToRem(15),
  },
  [`& .${classes.caption}`]: {
    fontWeight: 600,
  },
}));
