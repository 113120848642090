import React, { ReactElement, useEffect } from "react";

import { useSnackbar } from "notistack";
import { Controller } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { FetchMethodEnum, useFetch } from "shared/hooks";

import {
  Grid,
  TextField as MuiTextField,
  Tooltip,
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams,
  Alert,
  AlertTitle,
} from "@mui/material";

import { TextField, InfoIcon, Autocomplete } from "core";

import {
  EMPTY_STRING,
  EMAIL_EXISTS_VALIDATION_MESSAGE,
  PLACEHOLDER,
  EMAIL_FORMAT_MISSING_VALIDATION_MESSAGE,
} from "shared/constants";
import { CLIENT_DOCUMENT_ACCORDION } from "product/constants";

import {
  generateUrl,
  getFieldErrorMessage,
  getFieldHasError,
} from "shared/utils";
import { AccordionEnum } from "shared/enums";
import { FormStandardFieldProps } from "../props";
import { IResponseDocumentInvoicingAddress } from "./types";

const { GET } = FetchMethodEnum;

export interface FormInvoicingAddressMethodProps
  extends FormStandardFieldProps {
  getValues: any;
}

export const FormInvoicingAddressMethod = ({
  isEdit,
  control,
  errors,
  getValues,
}: FormInvoicingAddressMethodProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { cacheData, productData },
  } = useStateMachine();

  const { agencyId, documentId } = productData;
  const getClientAddressUrl = generateUrl(CLIENT_DOCUMENT_ACCORDION, {
    agencyId,
    clientDocumentId: documentId,
    accordionId: AccordionEnum.invoicingAddressMethod,
  });

  const { data: addressData, executeFetch } =
    useFetch<IResponseDocumentInvoicingAddress>(null, {
      skip: true,
    });

  const {
    attn,
    mailingAddress,
    postalCode,
    city,
    country,
    emailAccountancy,
    invoicingMethodId,
    invoiceMailingAddress,
  } = addressData?.get?.form ?? {};

  const { enumInvoicingMethod } = cacheData;
  const invoicingMethodName = enumInvoicingMethod.find(
    (m) => m.key === invoicingMethodId
  )?.value;

  // #region Effects
  useEffect(() => {
    if (agencyId && documentId) {
      executeFetch(getClientAddressUrl, { method: GET });
    }
  }, [getClientAddressUrl, executeFetch, agencyId, documentId]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Alert severity="info">
          <AlertTitle>Only fill if it deviates from Client Address</AlertTitle>
        </Alert>
      </Grid>
      <Grid item xs={11}>
        <TextField
          name="attn"
          id="attn"
          label="Attn."
          error={!!errors.attn?.message}
          helperText={errors.attn?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon title={attn ?? PLACEHOLDER} />
      </Grid>
      <Grid item xs={11}>
        <TextField
          name="mailingAddress"
          id="mailingAddress"
          label="Mailing Address"
          error={!!errors.mailingAddress?.message}
          helperText={errors.mailingAddress?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon title={mailingAddress ?? PLACEHOLDER} />
      </Grid>
      <Grid item xs={11}>
        <TextField
          name="postalCode"
          id="postalCode"
          label="Postal Code"
          error={!!errors.postalCode?.message}
          helperText={errors.postalCode?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon title={postalCode ?? PLACEHOLDER} />
      </Grid>
      <Grid item xs={11}>
        <TextField
          id="city"
          name="city"
          label="City"
          error={!!errors.city?.message}
          helperText={errors.city?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon title={city ?? PLACEHOLDER} />
      </Grid>
      <Grid item xs={11}>
        <TextField
          name="country"
          id="country"
          label="Country"
          error={!!errors.country?.message}
          helperText={errors.country?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon title={country ?? PLACEHOLDER} />
      </Grid>
      <Grid item xs={11}>
        <TextField
          name="emailAccountancy"
          id="emailAccountancy"
          label="E-mail Accountancy"
          error={!!errors.emailAccountancy?.message}
          helperText={errors.emailAccountancy?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon title={emailAccountancy ?? PLACEHOLDER} />
      </Grid>
      <Grid item xs={11}>
        <Autocomplete
          autoHighlight
          id="invoicingMethodId"
          name="invoicingMethodId"
          label="Invoicing Method"
          options={enumInvoicingMethod}
          error={!!errors.invoicingMethodId?.message}
          helperText={errors.invoicingMethodId?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon title={invoicingMethodName ?? PLACEHOLDER} />
      </Grid>
      <Grid item xs={11}>
        <Controller
          render={({ field }) => (
            <Tooltip
              title={
                !isEdit && getValues("invoiceMailingAddress")
                  ? getValues("invoiceMailingAddress").join(", ")
                  : EMPTY_STRING
              }
              placement="bottom"
            >
              <MuiAutocomplete
                {...field}
                autoHighlight
                freeSolo
                multiple
                id="invoiceMailingAddress"
                filterSelectedOptions
                options={[]}
                ChipProps={{ color: "primary" }}
                clearOnBlur
                renderInput={(params: AutocompleteRenderInputParams) => {
                  return (
                    <>
                      <MuiTextField
                        {...params}
                        name="invoiceMailingAddress"
                        label="E-mail Address Invoice (Max.5) (Type the email address and press enter...)"
                        variant="outlined"
                        error={getFieldHasError(errors.invoiceMailingAddress)}
                        helperText={getFieldErrorMessage(
                          errors.invoiceMailingAddress
                        )}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: params.InputProps.endAdornment,
                        }}
                      />
                    </>
                  );
                }}
                onChange={(_, data, reason, detail) => {
                  if (
                    reason === "createOption" &&
                    field.value.includes(detail?.option.trim())
                  ) {
                    enqueueSnackbar(EMAIL_EXISTS_VALIDATION_MESSAGE, {
                      variant: "warning",
                    });
                  } else {
                    const newData = data.map((x) => x.trim());
                    field.onChange(newData);
                  }
                }}
                onClose={(event) => {
                  if (!event.defaultPrevented) {
                    enqueueSnackbar(EMAIL_FORMAT_MISSING_VALIDATION_MESSAGE, {
                      variant: "warning",
                    });
                  }
                }}
                disabled={!isEdit}
              />
            </Tooltip>
          )}
          name="invoiceMailingAddress"
          control={control}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon title={invoiceMailingAddress?.toString() ?? PLACEHOLDER} />
      </Grid>
    </Grid>
  );
};

export default FormInvoicingAddressMethod;
