import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  creditInsurance: null,
  alternativeCreditInsuranceId: null,
  creditInsuranceNumber: EMPTY_STRING,
  creditInsuranceAmount: null,
  shareLimit: null,
};
