export enum BackOfficeKeys {
  DEBTOR_SYNC = "debtorSync",
  DEBTOR_SYNC_DETAILS = "debtorSyncDetail",
  AUDIT_TRAIL_SYNC = "auditTrailSync",
}

export enum UserKeys {
  USER_TOKEN = "userToken",
}

export interface CommonRequestOptions {
  notify?: NullableBoolean;
  message?: NullableString;
}
