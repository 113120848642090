import React, { useEffect, ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { IconButton, Tooltip, Box } from "@mui/material";
import { DoubleArrow as DoubleArrowIcon } from "@mui/icons-material";

import { TextField } from "core";

import { EMPTY_STRING } from "shared/constants";
import { setGeneralClientInfoMailing } from "store/actions";
import { FillFormOptionType } from "master-data/enums";

import { IFormInput } from "./types";

import { classes, Grid } from "./styles";
import { FormStandardFieldProps } from "../props";

export interface FormGeneralClientInfoProps extends FormStandardFieldProps {
  /**
   * formData
   */
  formData: IFormInput;
  /**
   * getValues
   */
  getValues: any;
  /**
   * setValues
   */
  setValue: any;
  /**
   * True If form is not created
   */
  isNew: boolean;
  /**
   * Flag to check if form submitted successfully
   */
  isSubmitSuccessful: boolean;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormGeneralClientInfo = ({
  isEdit,
  isNew,
  control,
  errors,
  revisionValues,
  formData,
  getValues,
  setValue,
  isSubmitSuccessful,
  fillOption,
}: FormGeneralClientInfoProps): ReactElement => {
  const {
    state: { clientDocumentData },
    actions,
  } = useStateMachine({ setGeneralClientInfoMailing });

  // #region "Methods"
  const prefillMailingData = () => {
    const business = getValues([
      "businessAddress",
      "businessPostalCode",
      "businessCity",
      "businessCountry",
    ]);
    const businessAddress = business[0];
    const businessPostalCode = business[1];
    const businessCity = business[2];
    const businessCountry = business[3];

    setValue("mailingAddress", businessAddress, {
      shouldValidate: businessAddress !== EMPTY_STRING,
      shouldDirty: true,
    });
    setValue("mailingPostalCode", businessPostalCode, {
      shouldValidate: businessPostalCode !== EMPTY_STRING,
      shouldDirty: true,
    });
    setValue("mailingCity", businessCity, {
      shouldValidate: businessCity !== EMPTY_STRING,
      shouldDirty: true,
    });
    setValue("mailingCountry", businessCountry, {
      shouldValidate: businessCountry !== EMPTY_STRING,
      shouldDirty: true,
    });
  };
  // #endregion

  const { documentName } = clientDocumentData;

  // #region Effects
  useEffect(() => {
    const { internalClientDocumentName } = formData ?? {};
    if (isNew && isEdit && !internalClientDocumentName) {
      setValue("internalClientDocumentName", documentName);
    }
  }, [isEdit, documentName, formData, isNew, setValue]);

  useEffect(() => {
    if (isSubmitSuccessful && isNew) {
      const mailing = getValues([
        "mailingAddress",
        "mailingPostalCode",
        "mailingCity",
        "mailingCountry",
      ]);
      actions.setGeneralClientInfoMailing({
        mailingAddress: mailing[0],
        mailingPostalCode: mailing[1],
        mailingCity: mailing[2],
        mailingCountry: mailing[3],
        internalClientDocumentName: documentName,
      });
    }
  }, [actions, getValues, isNew, isSubmitSuccessful, documentName]);

  useEffect(() => {
    if (fillOption) {
      setValue("mailingAddress", "k-district@test.com");
      setValue("mailingPostalCode", "11000");
      setValue("mailingCity", "Belgrade");
      setValue("mailingCountry", "Serbia");
      setValue("businessAddress", "k-district@test.com");
      setValue("businessPostalCode", "11000");
      setValue("businessCity", "Belgrade");
      setValue("businessCountry", "Serbia");
    }
  }, [fillOption, setValue]);
  // #endregion Effects

  return (
    <Grid container alignItems="center" justifyContent="space-evenly">
      <Grid item container direction="column" spacing={1} xs={5}>
        <Grid item>
          <TextField
            name="internalClientDocumentName"
            label="Internal Client Document Name *"
            id="internalClientDocumentName"
            error={!!errors.internalClientDocumentName?.message}
            helperText={errors.internalClientDocumentName?.message}
            revisionValue={revisionValues?.InternalClientDocumentName}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Business Address *"
            id="businessAddress"
            name="businessAddress"
            error={!!errors.businessAddress?.message}
            helperText={errors.businessAddress?.message}
            revisionValue={revisionValues?.BusinessAddress}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Postal Code *"
            id="businessPostalCode"
            name="businessPostalCode"
            error={!!errors.businessPostalCode?.message}
            helperText={errors.businessPostalCode?.message}
            revisionValue={revisionValues?.BusinessPostalCode}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            label="City *"
            id="businessCity"
            name="businessCity"
            error={!!errors.businessCity?.message}
            helperText={errors.businessCity?.message}
            revisionValue={revisionValues?.BusinessCity}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label="Country *"
            fullWidth
            id="businessCountry"
            name="businessCountry"
            error={!!errors.businessCountry?.message}
            helperText={errors.businessCountry?.message}
            revisionValue={revisionValues?.BusinessCountry}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" xs={2}>
        <IconButton
          disabled={!isEdit}
          onClick={prefillMailingData}
          className={classes.prefillButton}
        >
          <Tooltip
            enterDelay={250}
            placement="bottom"
            title="Fill Mailing part with Business values"
          >
            <DoubleArrowIcon />
          </Tooltip>
        </IconButton>
      </Grid>
      <Grid item xs={5} container spacing={1} direction="column">
        <Grid item>
          <Box height={56} />
        </Grid>
        <Grid item>
          <TextField
            label="Mailing Address *"
            id="mailingAddress"
            name="mailingAddress"
            error={!!errors.mailingAddress?.message}
            helperText={errors.mailingAddress?.message}
            revisionValue={revisionValues?.MailingAddress}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Postal Code *"
            id="mailingPostalCode"
            name="mailingPostalCode"
            error={!!errors.mailingPostalCode?.message}
            helperText={errors.mailingPostalCode?.message}
            revisionValue={revisionValues?.MailingPostalCode}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            label="City *"
            id="mailingCity"
            name="mailingCity"
            error={!!errors.mailingCity?.message}
            helperText={errors.mailingCity?.message}
            revisionValue={revisionValues?.MailingCity}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Country *"
            id="mailingCountry"
            name="mailingCountry"
            error={!!errors.mailingCountry?.message}
            helperText={errors.mailingCountry?.message}
            revisionValue={revisionValues?.MailingCountry}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormGeneralClientInfo;
