import { EMPTY_STRING } from "shared/constants/client";

export interface FilterOptions {
  id: number;
  property: string;
  text: string;
}

export const USER_GROUP_PREFIX = "userGroup";
export const DEBTOR_GROUP_PREFIX = "debtorGroup";

export const userGroupSortOptions = [
  { key: EMPTY_STRING, text: "None" },
  { key: "userGroupName_asc", text: "By User Group Name asc" },
  { key: "userGroupName", text: "By User Group Name dsc" },
  { key: "userGroupDescription_asc", text: "By Description asc" },
  { key: "userGroupDescription", text: "By Description dsc" },
];

export const userGroupDynamicFilterOptions: FilterOptions[] = Object([
  { id: 1, property: "userGroupName", text: "By User Group Name" },
  { id: 2, property: "userGroupDescription", text: "By Description" },
]);

export const debtorGroupSortOptions = [
  { key: EMPTY_STRING, text: "None" },
  { key: "debtorGroupName_asc", text: "By Debtor Group Name asc" },
  { key: "debtorGroupName", text: "By Debtor Group Name dsc" },
  { key: "debtorGroupDescription_asc", text: "By Description asc" },
  { key: "debtorGroupDescription", text: "By Description dsc" },
];

export const debtorGroupDynamicFilterOptions: FilterOptions[] = Object([
  { id: 1, property: "debtorGroupName", text: "By Debtor Group Name" },
  { id: 2, property: "debtorGroupDescription", text: "By Description" },
]);
