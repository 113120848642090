import { ReactElement, useState } from "react";

import { useFetch, FetchMethodEnum } from "shared/hooks";

interface ChildrenProps {
  options: CreatableOptionType[];
  isLoading: boolean;
  toggleOpen: () => void;
  open: boolean;
}
interface FetcherResponse {
  items: CreatableOptionType[];
}
export interface OptionFetcherProps {
  url: string;
  children: (api: ChildrenProps) => ReactElement;
  loadOnMount: boolean;
}

const { GET } = FetchMethodEnum;

export const OptionFetcher = ({
  url,
  children,
  loadOnMount = false,
}: OptionFetcherProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useFetch<FetcherResponse>(url, {
    method: GET,
    skip: !open && !loadOnMount,
  });

  const { items = [] } = data?.get ?? ({ items: [] } as FetcherResponse);

  const toggleOpen = () => setOpen((prevOpen) => !prevOpen);

  return children({
    options: data == null ? [] : items,
    isLoading,
    toggleOpen,
    open,
  });
};

export default OptionFetcher;
