import { styled, Chip as MuiChip } from "@mui/material";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "ChipAddStyles";
export const classes = {
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

export const Chip = styled(MuiChip)(({ theme }) => ({
  [`&.${classes.button}`]: {
    maxWidth: 120,
    maxHeight: 25,
  },
  [`& .${classes.icon}`]: {
    fontSize: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
  },
}));
