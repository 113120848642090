import { styled, Grid as MuiGrid } from "@mui/material";
import { darkDeYork, darkSupernova } from "../../../shared/assets/colors";

const PREFIX = "ManageUserGroupsActionsStyles";
export const classes = {
  addButton: `${PREFIX}-addButton`,
  removeButton: `${PREFIX}-removeButton`,
  disabledButton: `${PREFIX}-disabledButton`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.addButton}`]: {
    backgroundColor: darkDeYork,
  },
  [`& .${classes.removeButton}`]: {
    backgroundColor: darkSupernova,
  },
  [`& .${classes.disabledButton}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
}));
