import React, { ReactElement } from "react";

import { Grid, Toolbar } from "@mui/material";
import { ClearAll, DynamicFilter } from "core";

import {
  FilterOptions,
  FilterQuerys,
} from "master-data/pages/ClientDocumentOverview/config";
import { Divider } from "shared/styles";

export interface TableMappingToolbarProps {
  updateUrl: (object: { [key: string]: string | undefined }) => void;
  queryFilters: FilterQuerys;
  filterOptions: FilterOptions[];
  limit: number;
  onClear: () => void;
  disabled?: boolean;
}

export const MappingToolbar = ({
  updateUrl,
  queryFilters,
  filterOptions,
  limit,
  onClear,
  disabled,
}: TableMappingToolbarProps): ReactElement => {
  return (
    <Toolbar>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Grid container alignContent="flex-start" alignItems="center">
            <Grid item>
              <DynamicFilter
                updateUrl={updateUrl}
                queryFilters={queryFilters}
                filterOptions={filterOptions}
                limit={limit}
              />
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item>
              <ClearAll onClear={onClear} disabled={disabled} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default MappingToolbar;
