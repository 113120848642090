import { FiltersImportUsersEnum } from "shared/enums";
import { FiltersImportUsersType } from "shared/types";

export interface Extension {
  extensionId: number;
  description: FiltersImportUsersType;
}

export const extensionsList: Extension[] = [
  {
    extensionId: 1,
    description: FiltersImportUsersEnum.groupmExtension,
  },
  {
    extensionId: 2,
    description: FiltersImportUsersEnum.kineticExtension,
  },
  {
    extensionId: 3,
    description: FiltersImportUsersEnum.mindshareExtension,
  },
  {
    extensionId: 4,
    description: FiltersImportUsersEnum.essenceMediacomExtension,
  },
  {
    extensionId: 5,
    description: FiltersImportUsersEnum.wavemakerExtension,
  },
  {
    extensionId: 9,
    description: FiltersImportUsersEnum.blossomExtension,
  },
  {
    extensionId: 6,
    description: FiltersImportUsersEnum.greenHouseExtension,
  },
  {
    extensionId: 10,
    description: FiltersImportUsersEnum.choreographExtension,
  },
  {
    extensionId: 7,
    description: FiltersImportUsersEnum.newUserStatus,
  },
];
