import { styled, TableRow as MuiTableRow } from "@mui/material";
import {
  sapphire,
  jordyBlue,
  dodgerBlue,
  linkWater,
  gainsboro,
} from "shared/assets/colors";

const PREFIX = "ProductTableRowStyles";
export const classes = {
  cell: `${PREFIX}-cell`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyLink: `${PREFIX}-typographyLink`,
  typographyLabel: `${PREFIX}-typographyLabel`,
  buttonIcon: `${PREFIX}-buttonIcon`,
};

export const TableRow = styled(MuiTableRow)(() => ({
  [`& .${classes.cell}`]: {
    border: `1px solid ${gainsboro}`,
  },
  [`& .${classes.typographyValue}`]: {
    color: sapphire,
  },
  [`& .${classes.typographyLink}`]: {
    textDecoration: "none",
  },
  [`& .${classes.typographyLabel}`]: {
    color: linkWater,
  },
  [`& .${classes.buttonIcon}`]: {
    color: jordyBlue,
    "&:hover": {
      color: dodgerBlue,
    },
  },
}));
