import React, { ReactElement } from "react";

import { Grid, TableCell, Tooltip, Typography, Button } from "@mui/material";
import {
  SchemaDocumentTypeEnum,
  SchemaDocumentStatusEnum,
} from "back-office/enums";
import { setIsOpenUpdateDialog } from "store/actions";
import { useStateMachine } from "little-state-machine";
import { SchemaDocumentStatusType } from "back-office/types";
import { classes, TableRow } from "./styles";

export interface DocumentSchemaTableRowProps {
  version: number | undefined;
  id: number;
  name: SchemaDocumentStatusType;
  total: number;
  updated: number;
  isDisabled: boolean;
  onUpdate: (versionId: number | undefined, id: number) => void;
}

export const DocumentSchemaTableRow = ({
  version,
  id,
  name,
  total,
  updated,
  isDisabled,
  onUpdate,
}: DocumentSchemaTableRowProps): ReactElement => {
  const { actions } = useStateMachine({
    setIsOpenUpdateDialog,
  });

  const isUpdatedButtonVisible =
    name === SchemaDocumentTypeEnum.finalizedDocuments ||
    name === SchemaDocumentTypeEnum.editingPhase;

  const handleUpdate = () => {
    actions.setIsOpenUpdateDialog(true);
    onUpdate(version, id);
  };

  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={2}>
            <Tooltip
              title={SchemaDocumentStatusEnum[name]}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {name}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Total
            </Typography>
            <Typography
              className={classes.typographyValue}
              variant="subtitle2"
              noWrap
            >
              {total}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <>
              <Typography
                className={classes.typographyLabel}
                variant="subtitle2"
              >
                Updated
              </Typography>
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {updated}
              </Typography>
            </>
          </Grid>
          <Grid item xs={2}>
            {isUpdatedButtonVisible && (
              <Button
                id={`v${version}-update-${id}`}
                size="small"
                variant="contained"
                onClick={handleUpdate}
                disabled={isDisabled}
                className={classes.button}
              >
                Update to version {version}
              </Button>
            )}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default DocumentSchemaTableRow;
