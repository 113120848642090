import { styled } from "@mui/material";
import { solitude } from "shared/assets/colors";

const PREFIX = "HomeStyles";
export const classes = {
  root: `${PREFIX}-root`,
  appContainer: `${PREFIX}-appContainer`,
  appCard: `${PREFIX}-appCard`,
  item: `${PREFIX}-item`,
};

export const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    margin: "5%",
  },
  [`& .${classes.appContainer}`]: {
    border: `1px solid ${solitude}`,
    margin: "-40px",
  },
  [`& .${classes.appCard}`]: {
    background: "transparent",
    border: `1px solid ${solitude}`,
  },
  [`& .${classes.item}`]: {
    padding: "40px !important",
  },
}));
