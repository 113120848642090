import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  earlyPaymentDiscountId: null,
  percentagePassedOn: null,
  percentagePaidWithinNumberOfDays: null,
  epdReceivedId: null,
  epdReceivedPaidWithinNumberOfDays: null,
  additionalConditionsInContract: null,
};
