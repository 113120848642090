import { number, object, string } from "yup";

import { nanToNull } from "shared/utils";
import { MAX_LENGTH_1000_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  agingAnalysisRequired: number().transform(nanToNull).nullable(),
  frequencyId: number().nullable().transform(nanToNull),
  additionalInvoicingInformation: string()
    .trim()
    .max(1000, MAX_LENGTH_1000_VALIDATION_MESSAGE),
});
