import { getEnumKeyValues } from "shared/utils";

export enum AgencyEnum {
  groupm = 1,
  mindshare = 2,
  kinetic = 5,
  essencemediacom = 6,
  wavemaker = 7,
  blossom = 8,
  greenhouseone = 9,
  greenhousetech = 10,
  choreographcreate = 11,
}

export enum ProductStatus {
  WorkInProgress = 1,
  ReadyForProcessing = 2,
  Processing = 3,
  Finalized = 4,
}

export enum ProductStatusEnum {
  "Work in Progress" = "Work in Progress",
  "Ready for Processing" = "Ready for Processing",
  Processing = "Processing",
  Finalized = "Finalized",
}

export enum AccordionEnum {
  productInfo = 1,
  invoicingAddressMethod = 2,
  activationMBS = 3,
  businessSection = 4,
}

// #region  MAPS
export const MAP_AGENCY = getEnumKeyValues(AgencyEnum);
export const MAP_ACCORDION = getEnumKeyValues(AccordionEnum);
// #endregion MAPS
