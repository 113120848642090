import { capitalize } from "lodash";
import { AgencyEnum } from "master-data/enums";
import { EMPTY_STRING } from "shared/constants/client";

export interface FilterOptions {
  id: number;
  property: string;
  text: string;
}

export const sortOptions = [
  { key: EMPTY_STRING, text: "None" },
  { key: "debtorName_asc", text: "By Debtor Name asc" },
  { key: "debtorName", text: "By Debtor Name dsc" },
  { key: "concernName_asc", text: "By Concern Name asc" },
  { key: "concernName", text: "By Concern Name dsc" },
  { key: "marathonDebtorId_asc", text: "By Marathon Debtor ID asc" },
  { key: "marathonDebtorId", text: "By Marathon Debtor ID dsc" },
];

export const dynamicFilterOptions: FilterOptions[] = Object([
  { id: 1, property: "debtorName", text: "By Debtor Name" },
  { id: 2, property: "concernName", text: "By Concern Name" },
  {
    id: 3,
    property: "marathonDebtorId",
    text: "By Marathon Debtor ID",
  },
]);

export const defaultRowsPerPageOptions = [10, 25, 50];

export function getAgencyName(index: number): string {
  const name = AgencyEnum[index];
  return name === "groupm" ? "GroupM" : capitalize(name);
}
