import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { FieldErrors, Control } from "react-hook-form";

import { TextField } from "core";

import { EMPTY_STRING } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { Current, IRevisionEntriesInput } from "../ClientContacts/types";

export interface FormClientContactsEntryProps {
  control: Control;
  current: Current | null;
  errors?: FieldErrors;
  isEdit: boolean;
  revisionValues?: IRevisionEntriesInput;
  /**
   * Fill form option type
   */
  fillOption?: FillFormOptionType;
  /**
   * setValues
   */
  setValue: any;
  /**
   * resetFillOption
   */
  resetFillOption: (state: FillFormOptionType | undefined) => void;
  /**
   * onDialogSave
   */
  onDialogSave: () => void;
}

export const FormClientContactsEntry = ({
  control,
  current,
  errors = {}, // TODO: check why defaultParams are not enough
  isEdit,
  revisionValues,
  fillOption,
  setValue,
  resetFillOption,
  onDialogSave,
}: FormClientContactsEntryProps): ReactElement => {
  const { rowIndex, contactPerson, jobTitle, email, phone } = current ?? {};

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue(`entries.${rowIndex}.contactPerson`, "Test Test");
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue(`entries.${rowIndex}.jobTitle`, "Test Job Title");
        setValue(`entries.${rowIndex}.email`, "test@test.com");
        setValue(`entries.${rowIndex}.phone`, "011 2332333");
      }
    }
    return () => {
      if (
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillMandatoryAndSave
      ) {
        onDialogSave();
      }
      resetFillOption(undefined);
    };
  }, [fillOption, setValue, rowIndex, resetFillOption, onDialogSave]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.contactPerson`}
          control={control}
          defaultValue={contactPerson ?? EMPTY_STRING}
          id="contactPerson"
          label="Contact Person *"
          error={!!errors.contactPerson?.message}
          helperText={errors.contactPerson?.message}
          revisionValue={revisionValues?.ContactPerson}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.jobTitle`}
          control={control}
          defaultValue={jobTitle ?? EMPTY_STRING}
          id="jobTitle"
          label="Job Title"
          error={!!errors.jobTitle?.message}
          helperText={errors.jobTitle?.message}
          revisionValue={revisionValues?.JobTitle}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.email`}
          control={control}
          defaultValue={email ?? EMPTY_STRING}
          id="email"
          label="Email Address"
          error={!!errors.email?.message}
          helperText={errors.email?.message}
          revisionValue={revisionValues?.Email}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={`entries.${rowIndex}.phone`}
          control={control}
          defaultValue={phone ?? EMPTY_STRING}
          id="phone"
          label="Phone Number"
          error={!!errors.phone?.message}
          helperText={errors.phone?.message}
          revisionValue={revisionValues?.Phone}
          disabled={!isEdit}
        />
      </Grid>
    </Grid>
  );
};

FormClientContactsEntry.defaultProps = {
  errors: {},
  revisionValues: null,
};

export default FormClientContactsEntry;
