import { EMPTY_STRING } from "shared/constants";

import { ICreateProductFormInput } from "product/types";

export const FORM_ID = "createProductForm";

export const defaultValues: ICreateProductFormInput = {
  productId: null,
  productName: EMPTY_STRING,
  documentId: null,
  agencyId: 0,
};
