import UrlAssembler from "url-assembler";

export function generateUrl(
  urlBase: string,
  params: Record<string, unknown> = {},
  query: Record<string, unknown> = {}
): string {
  return UrlAssembler().template(urlBase).param(params).query(query).toString();
}

export const getPath = (
  base: string,
  path: string | string[]
): string | string[] => {
  if (Array.isArray(path)) {
    return path.map((p) => `${base}/${p}`);
  }
  return `${base}/${path}`;
};

export const extractParamsFromUrl = (
  url: string
): { [key: string]: string } | null =>
  url
    .split("?")[1]
    ?.split("&")
    .reduce((pr, cr) => {
      const [key, value] = cr.split("=");

      return { ...pr, [key]: value };
    }, {});
