import React, { ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { Button } from "@mui/material";

import {
  setIsOpenAddSelectedMappingsDialog,
  setIsOpenClearSelectedMappingsDialog,
} from "store/actions";

import { classes, Grid } from "./styles";

export const ManageMappingsActions = (): ReactElement => {
  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsOpenAddSelectedMappingsDialog,
    setIsOpenClearSelectedMappingsDialog,
  });

  const {
    isSelectedDebtorGroups,
    isSelectedUserGroups,
    isSelectedApplications,
  } = appState;

  const isAddButtonDisabled = !(
    isSelectedDebtorGroups &&
    isSelectedUserGroups &&
    isSelectedApplications
  );
  const isClearButtonDisabled = !(
    isSelectedDebtorGroups ||
    isSelectedUserGroups ||
    isSelectedApplications
  );

  const handleOpenAddSelectedMappingsDialog = () => {
    actions.setIsOpenAddSelectedMappingsDialog(true);
  };

  const handleOpenClearSelectedMappingDialog = () => {
    actions.setIsOpenClearSelectedMappingsDialog(true);
  };

  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item>
        <Button
          id="button-add"
          color="primary"
          variant="contained"
          className={classes.addButton}
          disabled={isAddButtonDisabled}
          onClick={handleOpenAddSelectedMappingsDialog}
        >
          Add Mappings
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="button-clear"
          color="primary"
          variant="contained"
          className={classes.clearButton}
          disabled={isClearButtonDisabled}
          onClick={handleOpenClearSelectedMappingDialog}
        >
          Clear Selected
        </Button>
      </Grid>
    </Grid>
  );
};

export default ManageMappingsActions;
