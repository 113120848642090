import { object, string, number } from "yup";

import { nanToNull } from "shared/utils";
import { MAX_LENGTH_1000_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  termsOfContract: number().nullable().transform(nanToNull),
  contractReference: string()
    .trim()
    .max(1000, MAX_LENGTH_1000_VALIDATION_MESSAGE),
});
