import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";

import { Select, TextField } from "core";

import { yesNoOptions } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormEvouchingProps extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;

  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormEvouching = ({
  isEdit,
  control,
  errors,
  revisionValues,
  fillOption,
  setValue,
}: FormEvouchingProps): ReactElement => {
  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("termsOfContract", yesNoOptions[0].key);
      setValue("contractReference", "Contract Reference - Test");
    }
  }, [fillOption, setValue]);
  // #endregion Effects

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Select
          id="termsOfContract"
          name="termsOfContract"
          label="Terms in Contract"
          options={yesNoOptions}
          error={!!errors.termsOfContract?.message}
          helperText={errors.termsOfContract?.message}
          revisionValue={revisionValues?.TermsOfContract}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item>
        <TextField
          name="contractReference"
          id="contractReference"
          label="Contract Reference and/or Text"
          multiline
          rows={4}
          error={!!errors.contractReference?.message}
          helperText={errors.contractReference?.message}
          revisionValue={revisionValues?.ContractReference}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormEvouching;
