import React, { ReactElement, useEffect, useState } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  useFetch,
  useInitDataGetter,
  useProfileGetter,
  useQuery,
} from "shared/hooks";
import { generateUrl } from "shared/utils";

import {
  Checkbox,
  Chip,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import { ConfirmationDialog, TableRowEmpty } from "core";
import UserGroupMapTableRow from "access-manager/components/UserGroupMapTableRow";

import { EMPTY_STRING } from "shared/constants/client";
import {
  ADD_MAPPINGS_DIALOG_CONTENT,
  ADD_MAPPINGS_DIALOG_LOADING_CONTENT,
  ADD_MAPPINGS_DIALOG_TITLE,
  APP_PREFIX,
  CLEAR_MAPPINGS_DIALOG_CONTENT,
  CLEAR_MAPPINGS_DIALOG_TITLE,
  CREATE_MAPPINGS,
  DEBTOR_GROUPS_PREFIX,
  GET_DATA_FOR_MAPPING,
  USER_GROUPS_PREFIX,
} from "access-manager/constants";

import ApplicationMapTableRow from "access-manager/components/ApplicationMapTableRow";
import { DebtorGroupMapTableRow } from "access-manager/components/DebtorGroupMapTableRow";

import { useStateMachine } from "little-state-machine";
import {
  setIsOpenAddSelectedMappingsDialog,
  setIsOpenClearSelectedMappingsDialog,
  setIsSelectedApplications,
  setIsSelectedDebtorGroups,
  setIsSelectedUserGroups,
} from "store/actions";
import { TableMappingHeader } from "access-manager/components";
import MappingToolbar from "access-manager/components/MappingToolbar/MappingToolbar";

import { FetchMethodEnum } from "shared/enums";
import { TableRow } from "shared/styles";
import { TableCell } from "core/TableEntries/styles";
import {
  debtorGroupDynamicFilterOptions,
  debtorGroupSortOptions,
  DEBTOR_GROUP_PREFIX,
  userGroupDynamicFilterOptions,
  userGroupSortOptions,
  USER_GROUP_PREFIX,
} from "./config";

import {
  Application,
  DebtorGroup,
  IMappingDataResponse,
  MappingDataResponse,
  MappingState,
  UserGroup,
} from "./types";

import { classes, Grid, TableContainer } from "./styles";

const { GET, POST } = FetchMethodEnum;

export const ManageMappings = (): ReactElement => {
  useInitDataGetter();
  useProfileGetter(0);

  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsSelectedDebtorGroups,
    setIsSelectedUserGroups,
    setIsSelectedApplications,
    setIsOpenAddSelectedMappingsDialog,
    setIsOpenClearSelectedMappingsDialog,
  });

  const { isOpenAddSelectedMappingsDialog, isOpenClearSelectedMappingsDialog } =
    appState;

  const query = useQuery();
  const queryObject = Object.fromEntries(query);
  const {
    ugSortByColumn,
    ugIsAscending,
    dgSortByColumn,
    dgIsAscending,
    agencyId,
    ...filters
  } = queryObject;

  const intAgencyId = agencyId ? parseInt(agencyId, 10) : 0;

  const getMappingUrl = generateUrl(GET_DATA_FOR_MAPPING);
  const { data, updateParams, executeFetch, isLoading } = useFetch<
    MappingDataResponse,
    IMappingDataResponse
  >(getMappingUrl, {
    method: GET,
    initialParams: { ...queryObject },
  });
  const { get: getData, post: postData } = data ?? {};
  const {
    userGroups = [],
    applications = [],
    debtorGroups = [],
  } = getData ?? {};

  const {
    push,
    location: { search },
  } = useHistory<MappingState>();

  const { url: base } = useRouteMatch();

  const userGroupFilters = Object.fromEntries(
    Object.entries(filters).filter(([key]) => key.startsWith(USER_GROUP_PREFIX))
  );

  const debtorGroupFilters = Object.fromEntries(
    Object.entries(filters).filter(([key]) =>
      key.startsWith(DEBTOR_GROUP_PREFIX)
    )
  );

  // #region userGroup

  const [checkedUserGroupItems, setCheckedUserGroupItems] = useState<number[]>(
    []
  );
  const [isSelectAllUserGroups, setSelectAllUserGroups] = useState<
    boolean | undefined
  >(false);

  const isDisabledUserGroupClearAllButton = isEmpty(userGroupFilters);
  const handleUserGroupDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      [key]: dynFilters[key],
    };
    setCheckedUserGroupItems([]);
    setSelectAllUserGroups(false);
    actions.setIsSelectedUserGroups(false);
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  const handleUserGroupClearAll = () => {
    const queryParams = {
      ugSortByColumn: queryObject.ugSortByColumn,
      ugIsAscending: queryObject.ugIsAscending,
      dgSortByColumn: queryObject.dgSortByColumn,
      dgIsAscending: queryObject.dgIsAscending,
      agencyId,
      ...debtorGroupFilters,
    };
    setCheckedUserGroupItems([]);
    setSelectAllUserGroups(false);
    actions.setIsSelectedUserGroups(false);
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  const handleUserGroupChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as string | null | undefined;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      ugSortByColumn: targetVal ?? EMPTY_STRING,
      ugIsAscending: isAsc,
    };
    setCheckedUserGroupItems([]);
    setSelectAllUserGroups(false);
    actions.setIsSelectedUserGroups(false);
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  const handleMainCheckboxUserGroupClick = () => {
    const ids = userGroups.map((d) => d.id);

    if (isSelectAllUserGroups) {
      setSelectAllUserGroups(false);
      setCheckedUserGroupItems([]);
      actions.setIsSelectedUserGroups(false);
    } else {
      setSelectAllUserGroups(true);
      setCheckedUserGroupItems(ids);
      actions.setIsSelectedUserGroups(true);
    }
  };

  const handleInvertUserGroup = () => {
    const ids = userGroups.map((d) => d.id);
    const invertArray = ids.filter((x) => !checkedUserGroupItems.includes(x));

    setCheckedUserGroupItems(invertArray);

    if (isEmpty(invertArray)) {
      setSelectAllUserGroups(false);
    }

    if (userGroups.length === invertArray.length) {
      setSelectAllUserGroups(true);
    }

    actions.setIsSelectedUserGroups(invertArray.length !== 0);
  };

  const handleUserGroupCheckboxClick = (id: number) => {
    const items = checkedUserGroupItems?.some((ci) => ci === id)
      ? checkedUserGroupItems.filter((ci) => ci !== id)
      : [...checkedUserGroupItems, id];

    setCheckedUserGroupItems(items);

    if (isEmpty(items)) {
      setSelectAllUserGroups(false);
    }

    if (!isEmpty(items) && userGroups.length !== items.length) {
      setSelectAllUserGroups(undefined);
    }

    if (userGroups.length === items.length) {
      setSelectAllUserGroups(true);
    }

    actions.setIsSelectedUserGroups(items.length !== 0);
  };
  // #endregion

  // #region applications
  const [checkedApplicationsItems, setCheckedApplicationsItems] = useState<
    string[]
  >([]);
  const [isSelectAllApplications, setSelectAllApplications] = useState<
    boolean | undefined
  >(false);

  const handleMainCheckboxApplicationsClick = () => {
    const ids = applications.map((d) => d.id);

    if (isSelectAllApplications) {
      setSelectAllApplications(false);
      setCheckedApplicationsItems([]);
      actions.setIsSelectedApplications(false);
    } else {
      setSelectAllApplications(true);
      setCheckedApplicationsItems(ids);
      actions.setIsSelectedApplications(true);
    }
  };

  const handleInvertApplications = () => {
    const ids = applications.map((d) => d.id);
    const invertArray = ids.filter(
      (x) => !checkedApplicationsItems.includes(x)
    );

    setCheckedApplicationsItems(invertArray);

    if (isEmpty(invertArray)) {
      setSelectAllApplications(false);
    }

    if (applications.length === invertArray.length) {
      setSelectAllApplications(true);
    }

    actions.setIsSelectedApplications(invertArray.length !== 0);
  };

  const handleApplicationsCheckboxClick = (id: string) => {
    const items = checkedApplicationsItems?.some((ci) => ci === id)
      ? checkedApplicationsItems.filter((ci) => ci !== id)
      : [...checkedApplicationsItems, id];

    setCheckedApplicationsItems(items);

    if (isEmpty(items)) {
      setSelectAllApplications(false);
    }

    if (!isEmpty(items) && applications.length !== items.length) {
      setSelectAllApplications(undefined);
    }

    if (applications.length === items.length) {
      setSelectAllApplications(true);
    }

    actions.setIsSelectedApplications(items.length !== 0);
  };
  // #endregion

  // #region debtorGroup
  const [checkedDebtorGroupItems, setCheckedDebtorGroupItems] = useState<
    number[]
  >([]);
  const [isSelectAllDebtorGroups, setSelectAllDebtorGroups] = useState<
    boolean | undefined
  >(false);

  const isDisabledDebtorGroupClearAllButton =
    isEmpty(debtorGroupFilters) && isEmpty(agencyId);
  const handleDebtorGroupDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      [key]: dynFilters[key],
    };
    setCheckedDebtorGroupItems([]);
    setSelectAllDebtorGroups(false);
    actions.setIsSelectedDebtorGroups(false);
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  const handleDebtorGroupClearAll = () => {
    const queryParams = {
      ugSortByColumn: queryObject.ugSortByColumn,
      ugIsAscending: queryObject.ugIsAscending,
      dgSortByColumn: queryObject.dgSortByColumn,
      dgIsAscending: queryObject.dgIsAscending,
      ...userGroupFilters,
    };
    setCheckedDebtorGroupItems([]);
    setSelectAllDebtorGroups(false);
    actions.setIsSelectedDebtorGroups(false);
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  const handleDebtorGroupChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as string | null | undefined;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      dgSortByColumn: targetVal ?? EMPTY_STRING,
      dgIsAscending: isAsc,
    };
    setCheckedDebtorGroupItems([]);
    setSelectAllDebtorGroups(false);
    actions.setIsSelectedDebtorGroups(false);
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  const handleMainCheckboxDebtorGroupClick = () => {
    const ids = debtorGroups.map((d) => d.id);

    if (isSelectAllDebtorGroups) {
      setSelectAllDebtorGroups(false);
      setCheckedDebtorGroupItems([]);
      actions.setIsSelectedDebtorGroups(false);
    } else {
      setSelectAllDebtorGroups(true);
      setCheckedDebtorGroupItems(ids);
      actions.setIsSelectedDebtorGroups(true);
    }
  };

  const handleInvertDebtorGroup = () => {
    const ids = debtorGroups.map((d) => d.id);
    const invertArray = ids.filter((x) => !checkedDebtorGroupItems.includes(x));

    setCheckedDebtorGroupItems(invertArray);

    if (isEmpty(invertArray)) {
      setSelectAllDebtorGroups(false);
    }

    if (debtorGroups.length === invertArray.length) {
      setSelectAllDebtorGroups(true);
    }

    actions.setIsSelectedDebtorGroups(invertArray.length !== 0);
  };

  const handleDebtorGroupCheckboxClick = (id: number) => {
    const items = checkedDebtorGroupItems?.some((ci) => ci === id)
      ? checkedDebtorGroupItems.filter((ci) => ci !== id)
      : [...checkedDebtorGroupItems, id];

    setCheckedDebtorGroupItems(items);

    if (isEmpty(items)) {
      setSelectAllDebtorGroups(false);
    }

    if (!isEmpty(items) && debtorGroups.length !== items.length) {
      setSelectAllDebtorGroups(undefined);
    }

    if (debtorGroups.length === items.length) {
      setSelectAllDebtorGroups(true);
    }

    actions.setIsSelectedDebtorGroups(items.length !== 0);
  };

  const handleDebtorGroupChangeSelect = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as unknown | number | undefined;

    const queryParams = {
      ...queryObject,
      agencyId: targetVal === 0 ? undefined : targetVal,
    };

    setCheckedDebtorGroupItems([]);
    setSelectAllDebtorGroups(false);

    actions.setIsSelectedDebtorGroups(false);

    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };
  // #endregion

  // #region Mappings Actions
  const resetState = () => {
    setCheckedUserGroupItems([]);
    setCheckedDebtorGroupItems([]);
    setCheckedApplicationsItems([]);
    setSelectAllUserGroups(false);
    setSelectAllApplications(false);
    setSelectAllDebtorGroups(false);

    actions.setIsSelectedUserGroups(false);
    actions.setIsSelectedApplications(false);
    actions.setIsSelectedDebtorGroups(false);
  };

  const handleClearSelectedMappings = () => {
    actions.setIsOpenClearSelectedMappingsDialog(false);
    resetState();
  };

  const handleCloseClearSelectedMappingsDialog = () => {
    actions.setIsOpenClearSelectedMappingsDialog(false);
  };

  const handleAddSelectedMappings = () => {
    const addMappingsUrl = generateUrl(CREATE_MAPPINGS);
    executeFetch(addMappingsUrl, {
      method: POST,
      body: {
        debtorGroupIds: checkedDebtorGroupItems,
        userGroupIds: checkedUserGroupItems,
        applicationIds: checkedApplicationsItems,
      },
    });
    actions.setIsOpenAddSelectedMappingsDialog(false);
    resetState();
  };

  const handleCloseSelectedMappingsDialog = () => {
    actions.setIsOpenAddSelectedMappingsDialog(false);
  };
  // #endregion

  // #region useEffect
  useEffect(() => {
    const newQuery = new URLSearchParams(search);
    const newQueryObj = Object.fromEntries(newQuery);

    updateParams(newQueryObj);
  }, [search, updateParams]);

  useEffect(() => {
    if (postData) {
      actions.setIsOpenAddSelectedMappingsDialog(false);
    }
  }, [actions, postData]);

  useEffect(() => {
    actions.setIsSelectedUserGroups(checkedUserGroupItems.length !== 0);
    actions.setIsSelectedDebtorGroups(checkedDebtorGroupItems.length !== 0);
    actions.setIsSelectedApplications(checkedApplicationsItems.length !== 0);
  }, [
    actions,
    checkedApplicationsItems.length,
    checkedDebtorGroupItems.length,
    checkedUserGroupItems.length,
  ]);
  // #endregion useEffect

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={4}>
          <MappingToolbar
            updateUrl={handleUserGroupDynamicFilter}
            queryFilters={userGroupFilters}
            filterOptions={userGroupDynamicFilterOptions}
            limit={2}
            onClear={handleUserGroupClearAll}
            disabled={isDisabledUserGroupClearAllButton}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <MappingToolbar
            updateUrl={handleDebtorGroupDynamicFilter}
            queryFilters={debtorGroupFilters}
            filterOptions={debtorGroupDynamicFilterOptions}
            limit={2}
            onClear={handleDebtorGroupClearAll}
            disabled={isDisabledDebtorGroupClearAllButton}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={4} className={classes.header}>
          <Typography className={classes.typography}>USER GROUPS</Typography>
        </Grid>
        <Grid item xs={4} className={classes.header}>
          <Typography className={classes.typography}>APPLICATIONS</Typography>
        </Grid>
        <Grid item xs={4} className={classes.header}>
          <Typography className={classes.typography}>DEBTOR GROUPS</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Paper>
              <TableContainer className={classes.container}>
                <Table stickyHeader size="small" className={classes.table}>
                  <>
                    <TableMappingHeader
                      sortOptions={userGroupSortOptions}
                      sortValue={queryObject.ugSortByColumn}
                      onSortChange={handleUserGroupChangeSort}
                    />
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            id={`${USER_GROUPS_PREFIX}-check-all`}
                            indeterminate={isSelectAllUserGroups === undefined}
                            inputProps={{ "aria-label": "main checkbox" }}
                            onChange={() => handleMainCheckboxUserGroupClick()}
                            checked={isSelectAllUserGroups}
                            className={classes.checkbox}
                            disabled={isEmpty(userGroups)}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            id={`${USER_GROUPS_PREFIX}-button-invert`}
                            color="primary"
                            onClick={handleInvertUserGroup}
                            label="Invert Selection"
                            clickable
                            variant="outlined"
                            disabled={isEmpty(userGroups)}
                          />
                        </TableCell>
                      </TableRow>

                      {isEmpty(userGroups) ? (
                        <TableRowEmpty />
                      ) : (
                        userGroups.map((userGroup: UserGroup) => (
                          <UserGroupMapTableRow
                            key={userGroup.id}
                            {...userGroup}
                            onCheckboxClick={handleUserGroupCheckboxClick}
                            isChecked={checkedUserGroupItems.includes(
                              userGroup.id
                            )}
                          />
                        ))
                      )}
                    </TableBody>
                  </>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Paper>
            <TableContainer className={classes.container}>
              <Table stickyHeader size="small" className={classes.table}>
                <>
                  <TableBody>
                    <TableRow className={classes.selectAll}>
                      <TableCell>
                        {!isEmpty(applications) ? (
                          <Checkbox
                            id={`${APP_PREFIX}-check-all`}
                            indeterminate={
                              isSelectAllApplications === undefined
                            }
                            inputProps={{ "aria-label": "main checkbox" }}
                            onChange={() =>
                              handleMainCheckboxApplicationsClick()
                            }
                            checked={isSelectAllApplications}
                            className={classes.checkbox}
                          />
                        ) : (
                          EMPTY_STRING
                        )}
                      </TableCell>
                      <TableCell>
                        <Chip
                          id={`${APP_PREFIX}-button-invert`}
                          color="primary"
                          onClick={handleInvertApplications}
                          label="Invert Selection"
                          clickable
                          variant="outlined"
                        />
                      </TableCell>
                    </TableRow>

                    {isEmpty(applications) ? (
                      <TableRowEmpty />
                    ) : (
                      applications.map((application: Application) => (
                        <ApplicationMapTableRow
                          key={application.id}
                          {...application}
                          onCheckboxClick={handleApplicationsCheckboxClick}
                          isChecked={checkedApplicationsItems.includes(
                            application.id
                          )}
                        />
                      ))
                    )}
                  </TableBody>
                </>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Paper>
              <TableContainer className={classes.container}>
                <Table stickyHeader size="small" className={classes.table}>
                  <>
                    <TableMappingHeader
                      sortOptions={debtorGroupSortOptions}
                      sortValue={queryObject.dgSortByColumn}
                      onSortChange={handleDebtorGroupChangeSort}
                      onSelectChange={handleDebtorGroupChangeSelect}
                      selectValue={intAgencyId}
                    />
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            id={`${DEBTOR_GROUPS_PREFIX}-check-all`}
                            indeterminate={
                              isSelectAllDebtorGroups === undefined
                            }
                            inputProps={{ "aria-label": "main checkbox" }}
                            onChange={() =>
                              handleMainCheckboxDebtorGroupClick()
                            }
                            checked={isSelectAllDebtorGroups}
                            className={classes.checkbox}
                            disabled={isEmpty(debtorGroups)}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            id={`${DEBTOR_GROUPS_PREFIX}-button-invert`}
                            color="primary"
                            onClick={handleInvertDebtorGroup}
                            label="Invert Selection"
                            clickable
                            variant="outlined"
                            disabled={isEmpty(debtorGroups)}
                          />
                        </TableCell>
                      </TableRow>
                      {isEmpty(debtorGroups) ? (
                        <TableRowEmpty />
                      ) : (
                        debtorGroups.map((debtorGroup: DebtorGroup) => (
                          <DebtorGroupMapTableRow
                            key={debtorGroup.id}
                            {...debtorGroup}
                            onCheckboxClick={handleDebtorGroupCheckboxClick}
                            isChecked={checkedDebtorGroupItems.includes(
                              debtorGroup.id
                            )}
                          />
                        ))
                      )}
                    </TableBody>
                  </>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
        {isOpenAddSelectedMappingsDialog && (
          <ConfirmationDialog
            open={isOpenAddSelectedMappingsDialog}
            title={ADD_MAPPINGS_DIALOG_TITLE}
            message={
              isLoading
                ? ADD_MAPPINGS_DIALOG_LOADING_CONTENT
                : ADD_MAPPINGS_DIALOG_CONTENT
            }
            isDisableAction={isLoading}
            onConfirm={handleAddSelectedMappings}
            onCancel={handleCloseSelectedMappingsDialog}
          />
        )}
        {isOpenClearSelectedMappingsDialog && (
          <ConfirmationDialog
            open={isOpenClearSelectedMappingsDialog}
            title={CLEAR_MAPPINGS_DIALOG_TITLE}
            message={CLEAR_MAPPINGS_DIALOG_CONTENT}
            isDisableAction={isLoading}
            onConfirm={handleClearSelectedMappings}
            onCancel={handleCloseClearSelectedMappingsDialog}
          />
        )}
      </Grid>
    </>
  );
};

export default ManageMappings;
