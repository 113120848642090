import React, { ReactElement } from "react";
import clsx from "clsx";
import { classes, getStyledIcon, StyleProps } from "./styles";

export interface IconWrapperProps extends StyleProps {
  /**
   * Component to render
   */
  icon: any; // TODO(AM)
  /**
   * class styles
   * @default undefined
   */
  className?: string;
  /**
   * in-line style of icon component
   * @default undefined
   */
  style?: React.CSSProperties;
}

export const IconWrapper = ({
  icon: Icon,
  className,
  style,
  ...otherProps
}: IconWrapperProps): ReactElement => {
  const StyledIcon = getStyledIcon(Icon, otherProps);

  return <StyledIcon className={clsx(classes.root, className)} style={style} />;
};

IconWrapper.defaultProps = {
  color: undefined,
  width: undefined,
  style: undefined,
  isDisabled: false,
  height: undefined,
  fontSize: undefined,
  className: undefined,
  backgroundColor: undefined,
};

export default IconWrapper;
