import { AnyObjectSchema } from "yup";

import { StandardFormType } from "../../types";

import { validationSchema as userGroupSchema } from "./UserGroup/config";
import { validationSchema as debtorGroupSchema } from "./DebtorGroup/config";

export const schemas: { [key in StandardFormType]: AnyObjectSchema } = {
  userGroup: userGroupSchema,
  debtorGroup: debtorGroupSchema,
};
