import { QueryCache, QueryClient } from "@tanstack/react-query";

function queryErrorHandler(error: unknown): void {
  // error is type unknown because in js, anything can be an error (e.g. throw(5))
  const title =
    error instanceof Error ? error.message : "error connecting to server";

  // prevent duplicate toasts
}

// to satisfy typescript until this file has uncommented contents

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err: any) => queryErrorHandler(err),
  }),
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  },
});

export { queryClient };
