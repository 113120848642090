import React, { ReactElement, useState } from "react";

import { Grid } from "@mui/material";

import { FilterObj } from "core";
import { ChipEdit } from "../ChipEdit";
import { ChipView } from "../ChipView";

export interface FilterProps {
  id?: number;
  value: string | undefined;
  property: string;
  options: any[];
  optionText: string;
  addFilter: (filter: FilterObj) => void;
  removeFilter: (arg0: string) => void;
}

export const Filter = ({
  value,
  property,
  addFilter,
  removeFilter,
  options,
  optionText,
  id,
}: FilterProps): ReactElement => {
  const [edit, setEdit] = useState(false);

  const cancelEdit = () => setEdit(false);

  const startEdit = () => setEdit(true);

  return (
    <>
      {edit ? (
        <Grid item>
          <ChipEdit
            id={id}
            filterProperty={property}
            filterValue={value}
            commit={addFilter}
            options={options}
            cancelEdit={cancelEdit}
          />
        </Grid>
      ) : (
        <Grid item>
          <ChipView
            onClick={startEdit}
            filterPropertyText={optionText}
            filterProperty={property}
            filterValue={value}
            onDelete={removeFilter}
          />
        </Grid>
      )}
    </>
  );
};

export default Filter;
