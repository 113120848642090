import React, { ReactElement } from "react";

import { UserManagementActions } from "back-office/components";

import { RouteProps } from "shared/types";
import { PAGE_USER_MANAGEMENT } from "back-office/constants";
import {
  PAGE_DASHBOARD_CLIENT,
  PAGE_DASHBOARD_PRODUCT,
} from "master-data/constants";

import {
  PAGE_CREATE_MAPPINGS,
  PAGE_IMPORT_USERS,
  PAGE_MANAGE_DEBTOR_GROUPS,
  PAGE_MANAGE_USER_GROUPS,
  PAGE_MISSING_DEBTORS,
} from "access-manager/constants";
import {
  ImportUsersActions,
  ManageDebtorGroupsActions,
  ManageMappingsActions,
  ManageUserGroupsActions,
  MissingDebtorsActions,
} from "access-manager/components";
import { SETTINGS } from "shared/constants";
import { UserProfileActions } from "shared/pages";
import { ProductDashboardActions } from "product/components";
import { ClientDocumentDashboardActions } from "../ClientDocumentDashboardActions";

export const routes: RouteProps[] = [
  {
    id: "clientDocumentDashboardActionsRoute",
    path: `${PAGE_DASHBOARD_CLIENT}/:documentId/:sectionType?/:accordionType?`,
    main: (): ReactElement => <ClientDocumentDashboardActions />,
  },
  {
    id: "productDashboardActionsRoute",
    path: `${PAGE_DASHBOARD_PRODUCT}/:productId`,
    main: (): ReactElement => <ProductDashboardActions />,
  },
  {
    id: "userManagementActionsRoute",
    rule: "CreateUser",
    path: PAGE_USER_MANAGEMENT,
    main: (): ReactElement => <UserManagementActions />,
  },
  {
    id: "userProfileActionsRoute",
    path: `:userId/${SETTINGS}`,
    main: (): ReactElement => <UserProfileActions />,
  },
  {
    id: "importUsersActionsRoute",
    rule: "CanViewImportUsersPage",
    path: PAGE_IMPORT_USERS,
    main: (): ReactElement => <ImportUsersActions />,
  },
  {
    id: "manageUserGroupsActionsRoute",
    path: PAGE_MANAGE_USER_GROUPS,
    main: (): ReactElement => <ManageUserGroupsActions />,
  },
  {
    id: "manageDebtorGroupsActionsRoute",
    path: PAGE_MANAGE_DEBTOR_GROUPS,
    main: (): ReactElement => <ManageDebtorGroupsActions />,
  },
  {
    id: "manageMappingsActionsRoute",
    path: PAGE_CREATE_MAPPINGS,
    main: (): ReactElement => <ManageMappingsActions />,
  },
  {
    id: "missingDebtorsActionsRoute",
    path: PAGE_MISSING_DEBTORS,
    main: (): ReactElement => <MissingDebtorsActions />,
  },
];
