import React, { ReactElement } from "react";
import { Button, Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import { setIsOpenUserProfileDialog } from "store/actions";
import { useHistory } from "react-router-dom";

export const UserProfileActions = (): ReactElement => {
  const history = useHistory();

  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsOpenUserProfileDialog,
  });
  const { canSaveUserProfileChanges } = appState;

  const handleOpenUserProfiledDialog = () => {
    actions.setIsOpenUserProfileDialog(true);
  };

  const handleRedirect = () => {
    history.goBack();
  };

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={handleOpenUserProfiledDialog}
          disabled={!canSaveUserProfileChanges}
        >
          Save changes
        </Button>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={handleRedirect}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserProfileActions;
