import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  calculateInterestId: null,
  fixedDeviatingPercentage: null,
  bankNameId: null,
  otherBankName: EMPTY_STRING,
  bankStoragePercentage: EMPTY_STRING,
};
