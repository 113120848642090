import { useStateMachine } from "little-state-machine";
import { useEffect } from "react";
import { GET_INIT } from "../constants/api";
import { CacheData } from "../../store/types";
import {
  setCacheData,
  setAgentForAgencies,
  setUserAgency,
  setShowAgencySelectionPage,
  setShowUserManegementPage,
  setShowImportUsersPage,
  setShowDebtorGroupsPage,
} from "../../store/actions";

import { useFetch, FetchMethodEnum } from "./useFetch";

export interface IResultGet extends CacheData {
  [key: string]: any;
  agency: string;
  agentForAgencies: string[];
  showAgencySelectionPage: boolean;
  showUserManegementPage: boolean;
  showImportUsersPage: boolean;
  showDebtorGroupsPage: boolean;
}

export const useInitDataGetter = (): IResultGet | null | boolean => {
  const { actions } = useStateMachine({
    setCacheData,
    setAgentForAgencies,
    setUserAgency,
    setShowAgencySelectionPage,
    setShowUserManegementPage,
    setShowImportUsersPage,
    setShowDebtorGroupsPage,
  });

  const { data, isLoading } = useFetch<IResultGet>(GET_INIT, {
    method: FetchMethodEnum.GET,
  });

  useEffect(() => {
    if (data?.get) {
      const {
        agency: userAgencyData,
        agentForAgencies: agentForAgenciesData,
        showAgencySelectionPage: showAgencyPage,
        showUserManegementPage: showUserManagement,
        showImportUsersPage: showImportUsers,
        showDebtorGroupsPage: showDebtorGroups,
        ...cacheData
      } = data.get;
      actions.setCacheData(cacheData);
      actions.setUserAgency(userAgencyData);
      actions.setAgentForAgencies(agentForAgenciesData);
      actions.setShowAgencySelectionPage(showAgencyPage);
      actions.setShowUserManegementPage(showUserManagement);
      actions.setShowImportUsersPage(showImportUsers);
      actions.setShowDebtorGroupsPage(showDebtorGroups);
    }
  }, [data, actions]);

  if (isLoading) {
    return isLoading;
  }

  return data?.get == null ? null : data.get;
};

export default useInitDataGetter;
