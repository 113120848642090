import { styled, Table as MuiTable } from "@mui/material";
import { solitude } from "shared/assets/colors";

const PREFIX = "LegalDocumentsChecksStyles";
export const classes = {
  table: `${PREFIX}-table`,
};

export const Table = styled(MuiTable)(() => ({
  [`&.${classes.table}`]: {
    border: `1px solid ${solitude}`,
    borderCollapse: "separate",
    fontSize: 10,
    borderSpacing: "5px !important",
  },
}));
