import { FiltersMappingEnum } from "shared/enums";
import { FiltersMappingType } from "shared/types";

export interface Extension {
  extensionId: number;
  description: FiltersMappingType;
}

export const extensionsList: Extension[] = [
  {
    extensionId: 1,
    description: FiltersMappingEnum.groupm,
  },
  {
    extensionId: 5,
    description: FiltersMappingEnum.kinetic,
  },
  {
    extensionId: 2,
    description: FiltersMappingEnum.mindshare,
  },
  {
    extensionId: 6,
    description: FiltersMappingEnum.essencemediacom,
  },
  {
    extensionId: 7,
    description: FiltersMappingEnum.wavemaker,
  },
  {
    extensionId: 8,
    description: FiltersMappingEnum.blossom,
  },
  {
    extensionId: 9,
    description: FiltersMappingEnum.greenhouseone,
  },
  {
    extensionId: 10,
    description: FiltersMappingEnum.greenhousetech,
  },
  {
    extensionId: 11,
    description: FiltersMappingEnum.choreographcreate,
  },
  {
    extensionId: 12,
    description: FiltersMappingEnum.goExtension,
  },
  {
    extensionId: 13,
    description: FiltersMappingEnum.masterDataExtension,
  },
];
