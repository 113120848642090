import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  unbilledMediaReturn: null,
  percetagePassedOn: null,
  includingFee: null,
  additionalConditionsInContract: null,
  otherUnbilledMediaReturnAfter: EMPTY_STRING,
  unbilledMediaReturnAfterId: null,
  unbilledMediaReturnPerId: null,
};
