import { styled, Paper as MuiPaper } from "@mui/material";

const PREFIX = "UserManagementStyles";
export const classes = {
  nowrapCell: `${PREFIX}-nowrapCell`,
  sizeMd: `${PREFIX}-sizeMd`,
  sizeLg: `${PREFIX}-sizeLg`,
};

export const Paper = styled(MuiPaper)(() => ({
  [`& .${classes.nowrapCell}`]: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  [`& .${classes.sizeMd}`]: {
    maxWidth: 125,
  },
  [`& .${classes.sizeLg}`]: {
    maxWidth: 250,
  },
}));
