import React, { useState, MouseEvent, ReactElement } from "react";

import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
} from "@mui/material";
import { useStateMachine } from "little-state-machine";
import { Lock as LockIcon, Person as PersonIcon } from "@mui/icons-material";
import { logOutAndClearStorage } from "shared/utils";

import { Link } from "react-router-dom";
import { PAGE_PROFILE, SETTINGS } from "shared/constants";
import { classes, Grid } from "./styles";

export const Profile = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    state: { profile },
  } = useStateMachine();
  const { firstName, lastName, userId } = profile;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography color="primary" display="inline">
          {`${firstName} ${lastName}`}
        </Typography>
      </Grid>
      <Grid item>
        <Avatar
          aria-controls="profile-avatar"
          aria-haspopup="true"
          component="button"
          color="primary"
          onClick={handleClick}
          className={classes.avatar}
          id="user-icon"
        >
          <PersonIcon />
        </Avatar>
        <Menu
          disableAutoFocusItem
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            component={Link}
            to={`/${PAGE_PROFILE}/${userId}/${SETTINGS}`}
            onClick={handleClose}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Profile" />
          </MenuItem>
          <MenuItem
            id="button-logout"
            className={classes.menuItem}
            onClick={logOutAndClearStorage}
          >
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default Profile;
