import React, { useCallback, useEffect, ReactElement } from "react";

import { useStateMachine } from "little-state-machine";
import { useParams, useHistory, Link } from "react-router-dom";
import { Typography, Tooltip, IconButton } from "@mui/material";

import {
  AgencyIcons,
  documentStatusIcons,
  RemarkIcon,
} from "master-data/components/icons";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { generateUrl } from "shared/utils";
import { IDocumentFormInput } from "master-data/types";
import { MANAGE_CLIENT_DOCUMENT } from "master-data/constants";
import { ACTIVE, INACTIVE, PLACEHOLDER } from "shared/constants";
import { AgencyEnum, ClientDocumentEnum } from "master-data/enums";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import {
  setDocumentStatus,
  resetDocumentStatus,
  setClientDocumentData,
  resetClientDocumentData,
  setCanViewRevision,
  resetGeneralClientInfoMailing,
} from "store/actions";

import { classes, Grid } from "./styles";

const { GET } = FetchMethodEnum;

export const ClientDocumentHeader = (): ReactElement => {
  const {
    actions,
    state: { statusData, clientDocumentData },
  } = useStateMachine({
    setDocumentStatus,
    resetDocumentStatus,
    setClientDocumentData,
    resetClientDocumentData,
    setCanViewRevision,
    resetGeneralClientInfoMailing,
  });

  const {
    location: { pathname },
  } = useHistory();
  const { agencyName, documentId } = useParams<ParamTypes>();

  const agencyIdUrl = AgencyEnum[agencyName];

  const url = generateUrl(MANAGE_CLIENT_DOCUMENT, {
    documentId,
    agencyId: agencyIdUrl,
  });

  const { data, executeFetch, hasError } = useFetch<IDocumentFormInput>(url, {
    method: GET,
  });

  const { statusName, hasComment } = statusData;
  const { isDocumentStatusChanged } = clientDocumentData;

  // #region Methods
  const handleCleanUp = useCallback(() => {
    actions.resetClientDocumentData(undefined);
    actions.resetDocumentStatus(undefined);
    actions.setCanViewRevision(false);
    actions.resetGeneralClientInfoMailing(undefined);
  }, [actions]);
  // #endregion Methods

  // #region Effects
  useEffect(() => {
    if (data?.get) {
      const {
        documentName,
        documentTypeId,
        isDocumentEditable = false,
        status = {
          statusId: null,
          statusName: null,
          previousStatusId: null,
          statusTransitions: [],
          hasComment: false,
        },
        isInEditWorkflow = false,
        isVisibleApproveMessage = false,
        userInfo = null,
        isLatestVersion,
        version,
        selectedReviewerId,
        marathonClientId,
        hasDefaultProduct,
        isDefaultProductCreationConfirmed,
        businessUnitId,
        isActive,
      } = data.get;

      actions.setClientDocumentData({
        isContract: documentTypeId === ClientDocumentEnum.contract,
        isSupplierClients:
          documentTypeId === ClientDocumentEnum.supplierClients,
        documentName,
        isDocumentEditable,
        isDocumentStatusChanged: false,
        isVisibleApproveMessage,
        userInfo,
        changes: null,
        isLatestVersion,
        version,
        selectedReviewerId,
        marathonClientId,
        hasDefaultProduct,
        isDefaultProductCreationConfirmed,
        businessUnitId,
        isActive,
      });
      actions.setDocumentStatus(status);
      actions.setCanViewRevision(isInEditWorkflow);
    }
  }, [data, actions]);

  useEffect(() => {
    if (isDocumentStatusChanged && documentId) {
      executeFetch(url, { method: GET });
    }
  }, [isDocumentStatusChanged, executeFetch, url, documentId]);

  useEffect(() => {
    return () => {
      handleCleanUp();
    };
  }, [handleCleanUp]);

  useEffect(() => {
    if (hasError) {
      handleCleanUp();
    }
  }, [handleCleanUp, hasError]);
  // #endregion Effects

  const { documentName, concern, documentType, marathonClientId, agencyId } =
    data?.get ?? {};

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        <Tooltip
          title={documentName ?? PLACEHOLDER}
          placement="bottom-start"
          enterDelay={500}
        >
          <Typography className={classes.typographyValue}>
            {documentName ?? PLACEHOLDER}
          </Typography>
        </Tooltip>
        <Typography className={classes.typographyLabel}>Client Name</Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={concern?.value ?? PLACEHOLDER}
          placement="bottom-start"
          enterDelay={500}
        >
          <Typography className={classes.typographyValue}>
            {concern?.value ?? PLACEHOLDER}
          </Typography>
        </Tooltip>
        <Typography className={classes.typographyLabel}>
          Concern Name
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={documentType ?? PLACEHOLDER}
          placement="bottom-start"
          enterDelay={500}
        >
          <Typography className={classes.typographyValue}>
            {documentType ?? PLACEHOLDER}
          </Typography>
        </Tooltip>
        <Typography className={classes.typographyLabel}>
          Document Type
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          {statusName && (
            <Grid item>
              <IconButton
                edge="start"
                size="small"
                component={Link}
                to={`${pathname}/status`}
                className={classes.sizeIcon}
              >
                {documentStatusIcons[statusName]}
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Tooltip
              title={statusName ?? PLACEHOLDER}
              placement="bottom-start"
              enterDelay={500}
            >
              <Typography className={classes.typographyValue}>
                {statusName ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            {hasComment && (
              <IconButton
                edge="start"
                size="small"
                component={Link}
                to={`${pathname}/comment`}
              >
                <RemarkIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Typography className={classes.typographyLabel}>
          Document Status
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={marathonClientId || PLACEHOLDER}
          placement="bottom-start"
          enterDelay={500}
        >
          <Typography className={classes.typographyValue}>
            {clientDocumentData.marathonClientId || PLACEHOLDER}
          </Typography>
        </Tooltip>
        <Typography className={classes.typographyLabel}>Client ID</Typography>
      </Grid>
      <Grid item>
        {agencyId && (
          <Grid container spacing={1} alignItems="center">
            <Grid item>{AgencyIcons({ agency: agencyId }).image}</Grid>
          </Grid>
        )}
        <Typography className={classes.typographyLabel}>Agency</Typography>
      </Grid>
      <Grid item>
        <Typography
          className={
            clientDocumentData.isActive ? classes.greenText : classes.redText
          }
          variant="h6"
          display="block"
        >
          {clientDocumentData.isActive ? ACTIVE : INACTIVE}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ClientDocumentHeader;
