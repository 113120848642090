import React, { ChangeEvent, useEffect, ReactElement } from "react";

import { isEmpty } from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  Toolbar,
} from "@mui/material";

import {
  TableFooter,
  TableHeader,
  DynamicFilter,
  TableRowEmpty,
  ClearAll,
} from "core";

import {
  useFetch,
  useQuery,
  FetchMethodEnum,
  useProfileGetter,
} from "shared/hooks";

import { generateUrl } from "shared/utils";
import { EMPTY_STRING } from "shared/constants";
import { UserGroupOverviewTableRow } from "access-manager/components";
import { GET_USER_GROUPS_OVERVIEW } from "access-manager/constants";
import { Divider } from "shared/styles";
import { UserGroupOverviewResponse, UserGroupRow } from "./types";

import {
  defaultRowsPerPageOptions,
  sortOptions,
  dynamicFilterOptions,
} from "./config";
import { classes, Root } from "./styles";

const { GET } = FetchMethodEnum;

export const UserGroupOverviewPage = (): ReactElement => {
  const {
    push,
    location: { search },
  } = useHistory();
  const { url: base } = useRouteMatch();
  const query = useQuery();
  const queryObject = Object.fromEntries(query);

  const {
    page: qPage,
    pageSize: qPageSize,
    sortByColumn,
    isascending,
    ...filters
  } = queryObject;

  const apiUrl = generateUrl(GET_USER_GROUPS_OVERVIEW);
  const { data, updateParams } = useFetch<UserGroupOverviewResponse>(apiUrl, {
    method: GET,
    initialParams: { ...queryObject },
  });

  const {
    items = [],
    page = 1,
    pageSize = 10,
    totalItems = 0,
  } = data?.get ?? {};

  const isDisabledClearAllButton = isEmpty(filters);
  useProfileGetter(0);

  // #region Effects
  useEffect(() => {
    // ! it is not possible to use existing queryObject due to refrence change on every render.
    const newQuery = new URLSearchParams(search);
    const newQueryObj = Object.fromEntries(newQuery);
    updateParams(newQueryObj);
  }, [search, updateParams]);
  // #endregion Effects

  // #region Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    const queryPageSize = query.get("pageSize");
    const queryParams = {
      ...queryObject,
      page: newPage + 1,
      pageSize: queryPageSize,
    }; // * newPage +1 reason is TablePagination page is zeroBased
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      pageSize: event.target.value,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Pagination

  const handleClearAll = () => {
    const queryParams = {
      page: 1,
      pageSize: 10,
      sortByColumn: queryObject.sortByColumn,
      isascending: queryObject.isascending,
    };
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl);
  };

  // #region Filters
  const handleChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as NullableString | undefined;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      page: 1,
      sortByColumn: targetVal ?? EMPTY_STRING,
      isascending: isAsc,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };

  const handleDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      page: 1,
      [key]: dynFilters[key],
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
  };
  // #endregion Filters

  return (
    <Root>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Grid container alignContent="flex-start" alignItems="center">
                  <Grid item>
                    <DynamicFilter
                      updateUrl={handleDynamicFilter}
                      queryFilters={filters}
                      filterOptions={dynamicFilterOptions}
                      limit={3}
                    />
                  </Grid>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Grid item>
                    <ClearAll
                      onClear={handleClearAll}
                      disabled={isDisabledClearAllButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs={12}>
        <Table size="small" className={classes.table}>
          <TableHeader
            sortOptions={sortOptions}
            sortValue={queryObject.sortByColumn}
            onSortChange={handleChangeSort}
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <TableBody>
            {isEmpty(items) ? (
              <TableRowEmpty />
            ) : (
              items.map((userGroupRow: UserGroupRow) => (
                <UserGroupOverviewTableRow
                  key={userGroupRow.userGroupId}
                  {...userGroupRow}
                />
              ))
            )}
          </TableBody>
          <TableFooter
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </Grid>
    </Root>
  );
};

export default UserGroupOverviewPage;
