import {
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MAX_LENGTH_10_VALIDATION_MESSAGE,
} from "shared/constants";
import { object, string } from "yup";
import { createFilterOptions } from "@mui/material";

import { IRevisionChanges, IRevisionInput, IRevisionRawInput } from "./types";

export const filter = createFilterOptions<CreatableOptionType>();

export const validationSchema = object().shape({
  documentName: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  documentTypeId: string().trim().required(MANDATORY_FILED_VALIDATION_MESSAGE),
  concern: object().required(MANDATORY_FILED_VALIDATION_MESSAGE).nullable(),
  marathonClientId: string()
    .trim()
    .max(10, MAX_LENGTH_10_VALIDATION_MESSAGE)
    .nullable(),
});

export const getRevisionValues = (
  changes: IRevisionChanges
): IRevisionInput => {
  const values = (changes?.ClientDocumentForm as IRevisionRawInput) ?? {};
  const revisionValues: IRevisionInput = {
    DocumentName: values?.Name,
    DocumentTypeId: values?.ClientDocumentTypeId,
    MarathonClientId: values?.MarathonClientId,
    ConcernId: values?.ConcernId,
  };

  return revisionValues;
};
