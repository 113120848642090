import React, { ReactElement } from "react";

import { FormControl, SelectChangeEvent } from "@mui/material";

import { EMPTY_STRING } from "shared/constants";
import { classes, MenuItem, Select, Typography } from "./styles";

export type SortOptionType = { key: string; text: string };

export interface SortProps {
  sortValue: string;
  onSortChange: (arg0: SelectChangeEvent<unknown>) => void;
  options: SortOptionType[];
  isDisabled?: boolean;
}

export const Sort = ({
  sortValue = EMPTY_STRING,
  onSortChange,
  options,
  isDisabled,
}: SortProps): ReactElement => {
  return (
    <>
      <Typography display="inline" className={classes.cssTypography}>
        Sort:
      </Typography>
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <Select
          id="sort"
          name="sort"
          className={classes.dropDown}
          value={sortValue}
          onChange={onSortChange}
          disabled={isDisabled}
        >
          {options.map(({ key, text }) => (
            <MenuItem
              key={key}
              id={`sort-${key}`}
              value={key}
              className={classes.menuItem}
            >
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Sort;
