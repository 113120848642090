import React, { ReactElement, useState } from "react";

import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { isEmpty } from "lodash";
import clsx from "clsx";

import {
  Grid,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
  Chip,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  ArchiveIcon,
  UnarchiveIcon,
  documentStatusIcons,
  RemarkIcon,
} from "master-data/components/icons";

import { generateUrl } from "shared/utils";

import { ACTIVE, PLACEHOLDER } from "shared/constants";
import { DocumentOverview } from "master-data/types";
import { DocumentStatusEnum } from "master-data/enums";
import { APP_PRODUCT, PAGE_OVERVIEW } from "product/constants";
import {
  APP_MASTERDATA,
  ARCHIVE_CLIENT_DIALOG_CONTENT,
  ARCHIVE_CLIENT_DIALOG_TITLE,
  ARCHIVE_DOCUMENT_CLAIM,
  PAGE_DASHBOARD_CLIENT,
  UNARCHIVE_CLIENT_DIALOG_CONTENT,
  UNARCHIVE_CLIENT_DIALOG_TITLE,
  UNARCHIVE_DOCUMENT_CLAIM,
} from "master-data/constants";
import { ParamTypes } from "master-data/pages/ClientDocumentOverview/types";
import { ConfirmationDialog } from "core";
import { classes, TableRow } from "./styles";

export const ClientDocumentTableRow = ({
  concern,
  documentId,
  hasComment,
  isActiveCFO,
  isActiveCEO,
  documentName,
  documentType,
  documentStatus,
  marathonClientId,
  internalClientDocumentName,
  hasBeenFinalized,
  currentTab,
  handleArchiveUnarchive,
}: DocumentOverview): ReactElement => {
  const { agencyName } = useParams<ParamTypes>();
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const {
    state: { profile },
  } = useStateMachine();
  const [showRowActionConfirmationDialog, setShowRowActionConfirmationDialog] =
    useState(false);
  const [rowActionDocumentId, setRowActionDocumentId] = useState(0);
  // TODO: This condition needs to be adjusted with the "Allow Agency Approval" setting (not currently used)
  const isAllowAgencyApproval = false;
  const areVisibleApproversChips =
    isAllowAgencyApproval &&
    (documentStatus === DocumentStatusEnum["Pending Approver Approval"] ||
      documentStatus === DocumentStatusEnum["MDT Checked"]);

  const { claims } = profile;
  const canViewAgencyFilter = claims.includes("CanViewAgencyFilter");
  const canArchiveClient = claims.includes(ARCHIVE_DOCUMENT_CLAIM);
  const canUnarchiveClient = claims.includes(UNARCHIVE_DOCUMENT_CLAIM);
  const showArchiveUnarchiveImage =
    currentTab && hasBeenFinalized && canArchiveClient && canUnarchiveClient;

  const queryParams = {
    page: 1,
    pageSize: 10,
    ...(canViewAgencyFilter && { selectedAgencies: "all" }),
    showDefault: true,
    marathonClientId,
  };

  const viewProductsUrl = generateUrl(
    `/${APP_PRODUCT}/${agencyName}/${PAGE_OVERVIEW}`,
    undefined,
    queryParams
  );

  const handleRowActionConfirmation = () => {
    setShowRowActionConfirmationDialog(false);
    handleArchiveUnarchive(rowActionDocumentId);
  };

  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={0.5}>
            {showArchiveUnarchiveImage && (
              <Tooltip
                title={
                  currentTab === ACTIVE
                    ? "Click to Archive"
                    : "Click to Unarchive"
                }
                enterDelay={250}
                placement="bottom"
              >
                <IconButton
                  id={`button-archive-${documentId}`}
                  edge="start"
                  size="small"
                  onClick={() => {
                    setRowActionDocumentId(documentId);
                    setShowRowActionConfirmationDialog(true);
                  }}
                >
                  {currentTab === ACTIVE ? <ArchiveIcon /> : <UnarchiveIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={3}>
            <Tooltip title={concern} enterDelay={250} placement="bottom-start">
              <Typography
                className={classes.typographyLabel}
                noWrap
                variant="subtitle2"
              >
                {concern}
              </Typography>
            </Tooltip>
            <Link
              to={`/${APP_MASTERDATA}/${agencyName}/${PAGE_DASHBOARD_CLIENT}/${documentId}`}
              className={classes.typographyLink}
            >
              <Tooltip
                title={documentName}
                enterDelay={250}
                placement="bottom-start"
              >
                <Typography
                  id={`document-${documentId}`}
                  className={classes.typographyValue}
                  variant="subtitle1"
                  noWrap
                >
                  {documentName}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
          <Grid item xs={1.5}>
            <Typography
              className={classes.typographyValue}
              variant="subtitle2"
              noWrap
            >
              {isEmpty(marathonClientId) ? PLACEHOLDER : marathonClientId}
            </Typography>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Client ID
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={internalClientDocumentName ?? PLACEHOLDER}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {internalClientDocumentName ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Internal Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classes.typographyValue}
              variant="subtitle2"
              noWrap
            >
              {documentType}
            </Typography>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Document Type
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <IconButton
                  id={`button-documentStatus-${documentId}`}
                  edge="start"
                  size="small"
                  component={Link}
                  to={{
                    pathname: `${url}/status`,
                    search: search
                      ? `${search}&id=${documentId}`
                      : `?id=${documentId}`,
                  }}
                >
                  {documentStatusIcons[documentStatus]}
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.typographyValue}
                  variant="subtitle2"
                  noWrap
                >
                  {documentStatus}
                </Typography>
              </Grid>
              {hasComment && (
                <Grid item>
                  <IconButton
                    id={`button-documentComment-${documentId}`}
                    edge="start"
                    size="small"
                    component={Link}
                    to={`${url}/comment${search}&id=${documentId}`}
                  >
                    <RemarkIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item>
                <Typography
                  className={classes.typographyLabel}
                  variant="subtitle2"
                >
                  Document Status
                </Typography>
              </Grid>
              {areVisibleApproversChips && (
                <Grid item>
                  <Chip
                    label="CEO"
                    className={clsx(classes.chip, {
                      [classes.greenChip]: isActiveCEO,
                    })}
                  />
                  <Chip
                    label="CFO"
                    className={clsx(classes.chip, {
                      [classes.greenChip]: isActiveCFO,
                    })}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={1}>
            {hasBeenFinalized && (
              <>
                <Grid item className={classes.center}>
                  <Tooltip
                    title="View Products"
                    enterDelay={250}
                    placement="bottom"
                  >
                    <IconButton
                      id={`button-viewProducts-${documentId}`}
                      className={`${classes.buttonIcon}`}
                      component={Link}
                      to={viewProductsUrl}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item className={classes.center}>
                  <Typography
                    className={classes.typographyLabel}
                    variant="subtitle2"
                  >
                    View Products
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {showRowActionConfirmationDialog && (
          <ConfirmationDialog
            open={showRowActionConfirmationDialog}
            title={
              currentTab === ACTIVE
                ? ARCHIVE_CLIENT_DIALOG_TITLE
                : UNARCHIVE_CLIENT_DIALOG_TITLE
            }
            message={
              currentTab === ACTIVE
                ? ARCHIVE_CLIENT_DIALOG_CONTENT
                : UNARCHIVE_CLIENT_DIALOG_CONTENT
            }
            isDisableAction={Boolean(false)}
            onConfirm={handleRowActionConfirmation}
            onCancel={() => setShowRowActionConfirmationDialog(false)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default ClientDocumentTableRow;
