import React, { ReactElement, useEffect } from "react";

import { useStateMachine } from "little-state-machine";

import { Grid } from "@mui/material";

import { TextField, Autocomplete } from "core";

import { EMPTY_STRING, MANDATORY_LABEL } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormClientTeamProps extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormClientTeam = ({
  isEdit,
  control,
  errors,
  revisionValues,
  setValue,
  fillOption,
}: FormClientTeamProps): ReactElement => {
  const {
    state: { clientDocumentData, cacheData },
  } = useStateMachine();

  const { isContract } = clientDocumentData;

  const { enumBusinessUnitType } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      if (enumBusinessUnitType[0]) {
        setValue("businessUnitId", enumBusinessUnitType[0].key);
      }
      if (
        isContract ||
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("clientLead", "Test Client Lead");
        setValue("processManager", "Test Process Manager");
      }
    }
  }, [fillOption, setValue, enumBusinessUnitType, isContract]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        <Autocomplete
          autoHighlight
          id="businessUnitId"
          name="businessUnitId"
          label="Business Unit *"
          options={enumBusinessUnitType}
          error={!!errors.businessUnitId?.message}
          helperText={errors.businessUnitId?.message}
          revisionValue={revisionValues?.BusinessUnitId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          name="clientLead"
          id="clientLead"
          label={`Client Lead ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.clientLead?.message}
          helperText={errors.clientLead?.message}
          revisionValue={revisionValues?.ClientLead}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          name="processManager"
          id="processManager"
          label={`Process Manager 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.processManager?.message}
          helperText={errors.processManager?.message}
          revisionValue={revisionValues?.ProcessManager}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormClientTeam;
