import React, { ReactElement } from "react";
import { Accordion, AccordionDetails } from "@mui/material";

export interface AccordionProps {
  children: any;
}

export const AccordionWrapper = ({
  children,
}: AccordionProps): ReactElement => {
  const handleEntered = (element: HTMLElement) => {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <Accordion
      expanded
      TransitionProps={{ onEntered: handleEntered, appear: true }}
    >
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionWrapper;
