import {
  styled,
  Grid as MuiGrid,
  TableCell as MuiTableCell,
} from "@mui/material";
import { sapphire } from "shared/assets/colors";

const PREFIX = "DocumentSchemaManagementStyles";
export const classes = {
  header: `${PREFIX}-header`,
  typography: `${PREFIX}-typography`,
  text: `${PREFIX}-text`,
  textItem: `${PREFIX}-textItem`,
  versionText: `${PREFIX}-versionText`,
  versionHeadline: `${PREFIX}-versionHeadline`,
  failedDocuments: `${PREFIX}-failedDocuments`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.header}`]: {
    textAlign: "center",
    fontFamily: "'Lato', sans-serif",
    margin: "60px 0px 20px 0px",
  },
  [`& .${classes.typography}`]: {
    fontWeight: 500,
    color: sapphire,
    paddingLeft: "12px",
  },
  [`& .${classes.text}`]: {
    margin: "0px 0px 50px 0px",
  },
  [`& .${classes.textItem}`]: {
    paddingLeft: "40px !important",
  },
}));

export const TableCell = styled(MuiTableCell)(() => ({
  [`&.${classes.versionText}`]: {
    width: "50%",
  },
  [`&.${classes.failedDocuments}`]: {
    flexGrow: 2,
    paddingLeft: "10%",
  },
  [`& .${classes.versionHeadline}`]: {
    marginLeft: "60% !important",
  },
}));
