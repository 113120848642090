import { FormEntriesAccordionType } from "master-data/types";
import { IFormsInput } from "./types";

import { defaultValues as clientContactsDefaultValues } from "./ClientContacts/constants";
import { defaultValues as hoursDefaultValues } from "./Hours/constants";
import { defaultValues as hoursDefaultValuesV5 } from "./HoursV5/constants";
import { defaultValues as relatedContactsDefaultValues } from "./RelatedContacts/constants";
import { defaultValues as remunerationContractLineDefaultValues } from "./RemunerationContractLine/constants";
import { defaultValues as soxCompliantClientApprovalDefaultValues } from "./SoxCompliantClientApproval/constants";
import { defaultValues as financialClientIncentiveDefaultValues } from "./FinancialClientIncentive/constants";

export const defaults: {
  [key in FormEntriesAccordionType]: IFormsInput[key];
} = {
  clientContacts: clientContactsDefaultValues,
  hours: hoursDefaultValues,
  hoursV5: hoursDefaultValuesV5,
  relatedContacts: relatedContactsDefaultValues,
  remunerationContractLine: remunerationContractLineDefaultValues,
  soxCompliantClientApproval: soxCompliantClientApprovalDefaultValues,
  financialClientIncentive: financialClientIncentiveDefaultValues,
};
