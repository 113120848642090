import { styled, Grid as MuiGrid, Paper as MuiPaper } from "@mui/material";
import {
  deYork,
  white,
  lilac,
  supernova,
  jordyBlue,
  baliHai,
} from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "ClientDashboardStyles";
export const classes = {
  paper: `${PREFIX}-paper`,
  sections: `${PREFIX}-sections`,
  sectionItem: `${PREFIX}-sectionItem`,
  link: `${PREFIX}-link`,
  searchLink: `${PREFIX}-searchLink`,
  defaultChip: `${PREFIX}-defaultChip`,
  greenChip: `${PREFIX}-greenChip`,
  purpleChip: `${PREFIX}-purpleChip`,
  yellowChip: `${PREFIX}-yellowChip`,
  blueChip: `${PREFIX}-blueChip`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`&.${classes.sections}`]: {
    paddingTop: "0 !important",
    paddingLeft: "0 !important",
    paddingBottom: "0 !important",
  },
  [`&.${classes.sectionItem}`]: {
    width: "100%",
  },
  [`& .${classes.link}`]: {
    textDecoration: "none",
  },
  [`& .${classes.searchLink}`]: {
    textDecoration: "none",
    color: "inherit",
    width: "100%",
  },
  [`& .${classes.defaultChip}`]: {
    backgroundColor: baliHai,
    color: white,
  },
  [`& .${classes.greenChip}`]: {
    backgroundColor: deYork,
    color: white,
  },
  [`& .${classes.purpleChip}`]: {
    backgroundColor: lilac,
    color: white,
  },
  [`& .${classes.yellowChip}`]: {
    backgroundColor: supernova,
    color: white,
  },
  [`& .${classes.blueChip}`]: {
    backgroundColor: jordyBlue,
    color: white,
  },
}));

export const Paper = styled(MuiPaper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    padding: getThemeSpacing(theme, 3),
    height: "100%",
  },
}));
