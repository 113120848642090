import {
  styled,
  Grid as MuiGrid,
  TableContainer as MuiTableContainer,
} from "@mui/material";

import {
  white,
  jordyBlue,
  baliHai,
  solitude,
  black,
} from "shared/assets/colors";

const PREFIX = "ManageMappingsStyles";
export const classes = {
  table: `${PREFIX}-table`,
  defaultChip: `${PREFIX}-defaultChip`,
  blueChip: `${PREFIX}-blueChip`,
  checkbox: `${PREFIX}-checkbox`,
  dropDown: `${PREFIX}-dropDown`,
  selectAll: `${PREFIX}-selectAll`,
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  typography: `${PREFIX}-typography`,
};

export const TableContainer = styled(MuiTableContainer)(() => ({
  [`&.${classes.container}`]: {
    height: 680,
    overflowX: "hidden",
  },
  [`& .${classes.table}`]: {
    border: `1px solid ${solitude}`,
    borderCollapse: "separate",
    borderSpacing: "5px !important",
  },
  [`& .${classes.defaultChip}`]: {
    backgroundColor: baliHai,
    color: white,
  },
  [`& .${classes.blueChip}`]: {
    backgroundColor: jordyBlue,
    color: white,
  },
  [`& .${classes.checkbox}`]: {
    marginLeft: 14,
  },
  [`& .${classes.dropDown}`]: {
    color: black,
  },
  [`& .${classes.selectAll}`]: {
    marginTop: 32,
  },
}));

export const Grid = styled(MuiGrid)(() => ({
  [`&.${classes.header}`]: {
    textAlign: "center",
  },
  [`& .${classes.typography}`]: {
    fontWeight: 600,
  },
}));
