import React, { useEffect, ReactElement } from "react";
import { Grid } from "@mui/material";

import { Select, TextField } from "core";
import { EMPTY_STRING, yesNoOptions } from "shared/constants";
import { FormStandardFieldProps } from "../props";

export interface FormProductInfoProps extends FormStandardFieldProps {
  /**
   * watch
   */
  watch: any;
  /**
   * setValue
   */
  setValue: any;
}

export const FormProductInfo = ({
  isEdit,
  control,
  errors,
  watch,
  setValue,
}: FormProductInfoProps): ReactElement => {
  const collectiveProductWatch = watch("collectiveProduct");
  const isCollectiveProductExistDisabled =
    (typeof collectiveProductWatch === "string" &&
      collectiveProductWatch.trim().length === 0) ||
    collectiveProductWatch === null;

  useEffect(() => {
    if (isCollectiveProductExistDisabled) {
      setValue("collectiveProductExist", EMPTY_STRING, {
        shouldValidate: true,
      });
    }
  }, [setValue, isCollectiveProductExistDisabled]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextField
          name="productName"
          id="productName"
          label="Product Name *"
          error={!!errors.productName?.message}
          helperText={errors.productName?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="productReference"
          id="productReference"
          label="Product Reference"
          error={!!errors.productReference?.message}
          helperText={errors.productReference?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="collectiveProduct"
          id="collectiveProduct"
          label="Collective Product"
          error={!!errors.collectiveProduct?.message}
          helperText={errors.collectiveProduct?.message}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="collectiveProductExist"
          name="collectiveProductExist"
          label="Collective Product Exists"
          error={!!errors.collectiveProductExist?.message}
          helperText={errors.collectiveProductExist?.message}
          disabled={!isEdit || isCollectiveProductExistDisabled}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
    </Grid>
  );
};

export default FormProductInfo;
