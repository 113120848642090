import React, { useState, useEffect, ReactElement } from "react";

import clsx from "clsx";
import { useStateMachine } from "little-state-machine";
import { useParams, useRouteMatch, useHistory, Route } from "react-router-dom";

import {
  Alert,
  AlertTitle,
  Autocomplete,
  Chip,
  TextField,
  Typography,
} from "@mui/material";

import { ClaimRoute, ClaimLink, Link, LinkTypeEnum } from "core";
import { Section, GeneralClientInfoIResponseGet } from "master-data/components";

import {
  useQuery,
  useFetch,
  FetchMethodEnum,
  useProfileGetter,
} from "shared/hooks";

import {
  AgencyEnum,
  AccordionEnum,
  MAP_ACCORDION,
  ClientDocumentStatus,
} from "master-data/enums";
import { AccordionType } from "master-data/types";
import { EMPTY_STRING, INIT_QUERY } from "shared/constants";
import {
  setMarathonClientId,
  setHasDefaultProduct,
  setIsDefaultProductCreationConfirmed,
  setGeneralClientInfoMailing,
  setRevisionChanges,
} from "store/actions";
import { generateUrl, getNthIndex, getPath } from "shared/utils";
import {
  GET_FORM,
  GET_REVISION_CONTENT,
  CLAIM_EDIT_CREATOR_FIELD,
  CLAIM_EDIT_VALIDATOR_FIELD,
  CREATE_MARATHON_CLIENT,
  APP_MASTERDATA,
  PAGE_OVERVIEW,
  CREATE_DEFAULT_PRODUCT,
  EDIT_CLIENT_DOCUMENT_META_DATA,
} from "master-data/constants";

import CreateDefaultProductDialog from "master-data/components/CreateDefaultProductDialog/CreateDefaultProductDialog";

import { getMessageView } from "./utils";
import { dialogRoutes, routes } from "./routes";
import { IResponseGet, ParamTypes } from "./types";
import {
  ADMIN_APPROVE_MESSAGE,
  APPROVERS_APPROVE_MESSAGE,
  generalSearchOptions,
  supplierSearchOptions,
} from "./constants";
import {
  sections,
  sectionsForMDTView,
  accordionsForMDTView,
  sectionsForApproverView,
  sectionsForCompleteView,
  accordionsForApproverView,
  accordionsForCompleteView,
  sectionsSupplierClientsType,
  accordionsSupplierClientsType,
} from "./config";

import { classes, Grid, Paper } from "./styles";

enum ViewModeEnum {
  approverMode = "approver",
  mdtMode = "mdt",
  revisionMode = "revision",
}

const { POST, PUT } = FetchMethodEnum;

export const ClientDocumentDashboardPage = (): ReactElement => {
  const [accordionsView, setAccordions] = useState(accordionsForCompleteView);
  const [sectionsView, setSections] = useState(sectionsForCompleteView);
  const [
    isCreateDefaultProductDialogOpen,
    setIsCreateDefaultProductDialogOpen,
  ] = useState<boolean>(true);
  const [isFirstTry, setIsFirstTry] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<{
    key: string;
    value: string;
  } | null>(null);

  const history = useHistory();
  const { documentId, agencyName } = useParams<ParamTypes>();
  const { url, path } = useRouteMatch();
  const query = useQuery();

  const {
    state: {
      appState,
      profile,
      statusData,
      clientDocumentData,
      generalClientInfoData,
    },
    actions,
  } = useStateMachine({
    setGeneralClientInfoMailing,
    setRevisionChanges,
    setMarathonClientId,
    setHasDefaultProduct,
    setIsDefaultProductCreationConfirmed,
  });

  const { userInfo, isVisibleApproveMessage, isSupplierClients, changes } =
    clientDocumentData;

  const supplierAgreements = MAP_ACCORDION.get(
    AccordionEnum.supplierAgreements
  ) as AccordionType;

  const routesAccordionsSupplierClients = routes.filter(
    (r) =>
      accordionsSupplierClientsType.some((a) => r.type === a.type) ||
      r.type === supplierAgreements
  );

  const agencyId = AgencyEnum[agencyName];
  useProfileGetter(agencyId);
  const baseUrl = url.substr(
    0,
    getNthIndex(url, documentId, 1) + documentId.length
  );

  const urlGeneralClientInfo = generateUrl(GET_FORM, {
    agencyId,
    documentId,
    accordionId: AccordionEnum.generalClientInfo,
  });

  const { data: generalClientData } = useFetch<GeneralClientInfoIResponseGet>(
    urlGeneralClientInfo,
    {
      method: FetchMethodEnum.GET,
    }
  );

  const { executeFetch, data, hasError } = useFetch<IResponseGet>(null, {
    skip: true,
  });

  const { get: getData, post: postData } = data ?? {};

  const {
    accordions: rAccordions,
    sections: rSections,
    changeDate,
    changedBy,
    changes: rChanges,
  } = getData ?? {};

  const { claims } = profile;
  const isCreator = claims.includes(CLAIM_EDIT_CREATOR_FIELD);
  const isValidator = claims.includes(CLAIM_EDIT_VALIDATOR_FIELD);
  const isAdmin = isValidator && isCreator;
  const mode = query?.get("mode");

  const isValidStatus =
    statusData.statusId ===
      ClientDocumentStatus.PendingFinalizedChangeApprover ||
    statusData.statusId === ClientDocumentStatus.MdtChecked;

  const isVisibleMessage = isVisibleApproveMessage && isValidStatus;
  // TODO: This condition needs to be adjusted with the "Allow Agency Approval" setting (not currently used)
  const isAllowAgencyApproval = false;

  const routesList = isSupplierClients
    ? routesAccordionsSupplierClients
    : routes.filter((r) => r.type !== supplierAgreements);

  const sectionList = isSupplierClients
    ? sectionsSupplierClientsType
    : sections;

  const searchOptions = isSupplierClients
    ? supplierSearchOptions
    : generalSearchOptions;

  const { canViewRevision } = appState;

  useEffect(() => {
    if (!changes) {
      actions.setRevisionChanges(rChanges);
    }
  }, [actions, changes, rChanges]);

  useEffect(() => {
    if (mode === ViewModeEnum.revisionMode && canViewRevision) {
      const apiUrl = generateUrl(GET_REVISION_CONTENT, {
        documentId,
        agencyId,
      });
      executeFetch(apiUrl, { skip: false, method: FetchMethodEnum.GET });

      return;
    }

    if (mode === ViewModeEnum.approverMode) {
      setSections(sectionsForApproverView);
      setAccordions(accordionsForApproverView);
      return;
    }

    if (mode === ViewModeEnum.mdtMode) {
      setSections(sectionsForMDTView);
      setAccordions(accordionsForMDTView);
      return;
    }

    if (mode === null) {
      setSections(sectionsForCompleteView);
      setAccordions(accordionsForCompleteView);
    }
  }, [mode, agencyId, documentId, executeFetch, canViewRevision, actions]);

  useEffect(() => {
    if (rAccordions && rSections) {
      setSections(rSections);
      setAccordions(rAccordions);
    }
  }, [rAccordions, rSections]);

  useEffect(() => {
    if (generalClientData?.get?.form) {
      const gciData = generalClientData?.get?.form;
      actions.setGeneralClientInfoMailing({
        mailingCity: gciData.mailingCity,
        mailingAddress: gciData.mailingAddress,
        mailingCountry: gciData.mailingCountry,
        mailingPostalCode: gciData.mailingPostalCode,
        internalClientDocumentName: gciData.internalClientDocumentName,
      });
    }
  }, [generalClientData, actions]);

  useEffect(() => {
    if (postData) {
      const { clientId } = postData!;
      if (clientId) {
        actions.setMarathonClientId(clientId);
      }
      const { productId } = postData!;
      if (productId) {
        actions.setHasDefaultProduct(true);
      }
      const { isDefaultProductCreationConfirmed } = postData!;
      if (isDefaultProductCreationConfirmed) {
        actions.setIsDefaultProductCreationConfirmed(
          isDefaultProductCreationConfirmed
        );
      }
      setIsFirstTry(false);
      setLoading(false);
    }
  }, [postData, actions, data]);

  useEffect(() => {
    if (!url.includes(searchValue?.key ?? EMPTY_STRING)) setSearchValue(null);
  }, [url, searchValue]);

  // TODO: find better solution for this, after refactoring
  useEffect(() => {
    if (hasError) {
      setLoading(false);
    }
  }, [setLoading, hasError]);

  const handleSearchChange = (
    event: React.SyntheticEvent,
    newValue: {
      key: string;
      value: string;
    } | null
  ) => {
    if (newValue?.key) {
      setSearchValue(newValue);
      history.push(`${baseUrl}/${newValue.key}`);
    }
  };

  const handleDialogCancelation = () => {
    setIsCreateDefaultProductDialogOpen(false);
    history.push(
      `/${APP_MASTERDATA}/${agencyName.toLowerCase()}/${PAGE_OVERVIEW}${INIT_QUERY}`
    );
  };

  const handleDialogConfirmation = () => {
    setLoading(true);
    const editDocumentMetaData = generateUrl(EDIT_CLIENT_DOCUMENT_META_DATA, {
      agencyId,
      documentId,
    });
    executeFetch(editDocumentMetaData, {
      method: PUT,
      body: {
        IsDefaultProductCreationConfirmed: true,
      },
    });
  };

  const handleMarathonClientCreation = () => {
    setLoading(true);
    const createMarathonClientUrl = generateUrl(CREATE_MARATHON_CLIENT, {
      agencyId,
      documentId,
    });
    executeFetch(createMarathonClientUrl, {
      method: POST,
      body: {
        name: clientDocumentData.documentName,
        internalName: generalClientInfoData.internalClientDocumentName,
        businessUnitId: clientDocumentData.businessUnitId,
      },
    });
  };

  const handleDefaultProductCreation = () => {
    setLoading(true);
    const createDefaultProductUrl = generateUrl(CREATE_DEFAULT_PRODUCT, {
      agencyId,
      documentId,
    });
    executeFetch(createDefaultProductUrl, {
      method: POST,
      body: {
        marathonClientId: clientDocumentData.marathonClientId,
        clientDocumentId: documentId,
      },
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Autocomplete
            id="search-property"
            clearOnBlur
            options={searchOptions}
            getOptionLabel={(option) => option.value}
            onChange={handleSearchChange}
            value={searchValue}
            renderOption={(props, option) => (
              <span {...props}>{option.value}</span>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Search" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            direction="column"
          >
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Chip
                    id="complete-view"
                    label="Complete View"
                    component={Link}
                    to={{
                      pathname: `${url}`,
                      search: EMPTY_STRING,
                    }}
                    clickable
                    className={clsx(classes.defaultChip, {
                      [classes.blueChip]: mode === null,
                    })}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    id="mdt-view"
                    label="MDT View"
                    component={Link}
                    to={{
                      pathname: `${url}`,
                      search: `?mode=${ViewModeEnum.mdtMode}`,
                    }}
                    clickable
                    className={clsx(classes.defaultChip, {
                      [classes.purpleChip]: mode === ViewModeEnum.mdtMode,
                    })}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    id="approver-view"
                    label="Approver View"
                    component={Link}
                    to={{
                      pathname: `${url}`,
                      search: `?mode=${ViewModeEnum.approverMode}`,
                    }}
                    clickable
                    className={clsx(classes.defaultChip, {
                      [classes.greenChip]: mode === ViewModeEnum.approverMode,
                    })}
                  />
                </Grid>
                {canViewRevision && (
                  <Grid item>
                    <Chip
                      id="revision-view"
                      label="Revision View"
                      component={Link}
                      to={{
                        pathname: `${url}`,
                        search: `?mode=${ViewModeEnum.revisionMode}`,
                      }}
                      clickable
                      className={clsx(classes.defaultChip, {
                        [classes.yellowChip]:
                          mode === ViewModeEnum.revisionMode,
                      })}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Alert severity="info">
                <AlertTitle>{getMessageView(mode)}</AlertTitle>
                {mode === ViewModeEnum.revisionMode && (
                  <Typography>
                    Last change at:{" "}
                    {changeDate
                      ?.replace("Z", EMPTY_STRING)
                      .split("-")
                      .reverse()
                      .join("-")}{" "}
                    by {changedBy}
                  </Typography>
                )}
              </Alert>
              {isVisibleMessage && isAllowAgencyApproval && (
                <Alert severity="warning">
                  <AlertTitle>
                    <Typography>
                      {isAdmin
                        ? ADMIN_APPROVE_MESSAGE
                        : APPROVERS_APPROVE_MESSAGE}
                      {userInfo}
                    </Typography>
                  </AlertTitle>
                </Alert>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.sections}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              {sectionList
                .filter((s) => sectionsView?.some((rs) => s.type === rs))
                .map(({ type, title, rules }) => (
                  <Grid item key={type} className={classes.sectionItem}>
                    <ClaimLink
                      id={`section-${type}`}
                      to={{
                        pathname: `${baseUrl}/${type}`,
                        search: `${mode ? `?mode=${mode}` : EMPTY_STRING}`,
                      }}
                      key={`${baseUrl}/${type}`}
                      type={LinkTypeEnum.toggle}
                      rule={rules}
                    >
                      <Section type={type} title={title} />
                    </ClaimLink>
                  </Grid>
                ))}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2} direction="column">
            {routesList
              .filter((r) => accordionsView?.some((ra) => r.type === ra))
              .map(({ rule, path: accPath, component: Accordion, id }) => {
                const formatedPath = path.replace(
                  "/:sectionType?/:accordionType?",
                  `/${accPath}`
                );
                return (
                  <ClaimRoute path={formatedPath} key={id} rule={rule}>
                    <Grid item>
                      <Accordion />
                    </Grid>
                  </ClaimRoute>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
      {dialogRoutes.map(({ path: dialogPath, main: Component, exact, id }) => {
        const routePath = getPath(path, dialogPath);
        return (
          <Route exact={exact} key={id} path={routePath}>
            {({ match }) => {
              const isActive = Boolean(match);
              return isActive ? <Component /> : null;
            }}
          </Route>
        );
      })}
      <CreateDefaultProductDialog
        open={
          statusData.statusId === ClientDocumentStatus.Processing &&
          isCreateDefaultProductDialogOpen &&
          (!clientDocumentData.marathonClientId ||
            !clientDocumentData.hasDefaultProduct ||
            !clientDocumentData.isDefaultProductCreationConfirmed)
        }
        loading={loading}
        disabled={!isValidator && !isAdmin}
        isFirstTry={isFirstTry}
        onClientCreate={handleMarathonClientCreation}
        onProductCreate={handleDefaultProductCreation}
        onCloseDialog={handleDialogConfirmation}
        onCancel={handleDialogCancelation}
        updateFirstTry={setIsFirstTry}
        clientId={clientDocumentData.marathonClientId}
        hasDefaultProduct={clientDocumentData.hasDefaultProduct}
      />
    </>
  );
};

export default ClientDocumentDashboardPage;
