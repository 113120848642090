import { object, string } from "yup";
import { MAX_LENGTH_255_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  division: string()
    .trim()
    .max(255, MAX_LENGTH_255_VALIDATION_MESSAGE)
    .nullable(),
  businessLine: string()
    .trim()
    .max(255, MAX_LENGTH_255_VALIDATION_MESSAGE)
    .nullable(),
  businessUnit: string()
    .trim()
    .max(255, MAX_LENGTH_255_VALIDATION_MESSAGE)
    .nullable(),
});
