import { styled, TableCell as MuiTableCell } from "@mui/material";
import { tableHeaderGray } from "shared/assets/colors";

const PREFIX = "StatusLogTableStyles";
export const classes = {
  cell: `${PREFIX}-cell`,
};

export const TableCell = styled(MuiTableCell)(() => ({
  [`&.${classes.cell}`]: {
    backgroundColor: tableHeaderGray,
  },
}));
