import { AccordionEnum, ClientDocumentStatus } from "master-data/enums";

const isEditableStatusForValidator: ClientDocumentStatus[] = [
  ClientDocumentStatus.MdtInProgress,
  ClientDocumentStatus.Finalized,
  ClientDocumentStatus.FinalizedChanged,
];

const isEditableDocumentStatusForValidator: ClientDocumentStatus[] = [
  ClientDocumentStatus.Processing,
  ClientDocumentStatus.Finalized,
  ClientDocumentStatus.FinalizedChanged,
];

const isEditableAccordionForValidator: string[] = [
  AccordionEnum[AccordionEnum.additionalClientInfo],
  AccordionEnum[AccordionEnum.creditInsurance],
  AccordionEnum[AccordionEnum.supplierAgreements],
  AccordionEnum[AccordionEnum.invoicingRequirements],
];

export const isEditableForValidator = (
  statusId: NullableNumber,
  accordionType?: string
): boolean => {
  if (!statusId) return false;

  return accordionType
    ? (isEditableAccordionForValidator.includes(accordionType) &&
        isEditableStatusForValidator.includes(statusId)) ||
        (isEditableDocumentStatusForValidator.includes(statusId) &&
          accordionType === AccordionEnum[AccordionEnum.clientDocument])
    : isEditableDocumentStatusForValidator.includes(statusId);
};
