import React, { useEffect, ReactElement } from "react";

import { isEmpty } from "lodash";
import { useStateMachine } from "little-state-machine";
import { Route, RouteProps, useParams } from "react-router-dom";

import { useProfileGetter, useFetch, FetchMethodEnum } from "shared/hooks";

import {
  setCacheData,
  setAgentForAgencies,
  setUserAgency,
  setShowAgencySelectionPage,
} from "store/actions";

import { GET_INIT } from "shared/constants";
import { AgencyEnum } from "master-data/enums";
import { ParamTypes } from "master-data/pages/ClientDocumentOverview/types";

import { IResultCache } from "./types";

export const AgencyRoute = (props: RouteProps): ReactElement => {
  const { agencyName } = useParams<ParamTypes>();
  const { state, actions } = useStateMachine({
    setCacheData,
    setAgentForAgencies,
    setUserAgency,
    setShowAgencySelectionPage,
  });

  const { profile, cacheData } = state;
  const agencyId = AgencyEnum[agencyName];

  useProfileGetter(agencyId, { skip: !isEmpty(profile.claims) });

  const { data: newCacheData } = useFetch<IResultCache>(GET_INIT, {
    method: FetchMethodEnum.GET,
    skip: !Object.keys(cacheData).some((key: string) =>
      isEmpty(cacheData[key])
    ),
  });

  // #region Effects
  useEffect(() => {
    if (newCacheData?.get) {
      const {
        agency: userAgency,
        agentForAgencies: agentAgencies,
        showAgencySelectionPage: showAgencyPage,
        ...cache
      } = newCacheData.get;
      actions.setCacheData(cache);
      actions.setAgentForAgencies(agentAgencies);
      actions.setUserAgency(userAgency);
      actions.setShowAgencySelectionPage(showAgencyPage);
    }
  }, [newCacheData, actions]);
  // #endregion Effects

  return <Route {...props} />;
};

export default AgencyRoute;
