import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { queryClient } from "../react-query/queryClient";

export const handleSuccessfullMutation = (
  enqueueSnackbar: (
    message: SnackbarMessage,
    opt: OptionsObject | undefined
  ) => SnackbarKey,
  invalidateQueryDataKey?: string[],
  notify?: NullableBoolean,
  message?: NullableString
) => {
  queryClient.invalidateQueries(invalidateQueryDataKey);

  if (notify) {
    enqueueSnackbar(message, {
      variant: "success",
    });
  }
};
