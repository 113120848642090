import React, { ReactElement } from "react";

import { useStateMachine } from "little-state-machine";

import { LinkProps } from "../Link";

import { classes, Link } from "./styles";

export interface ClaimLinkProps extends LinkProps {
  rule?: string | string[];
}

export const ClaimLink = ({
  children,
  rule,
  ...linkProps
}: ClaimLinkProps): ReactElement | null => {
  const {
    state: { profile },
  } = useStateMachine();

  const { claims } = profile;

  let isVisible = false;
  if (rule instanceof Array) {
    isVisible =
      rule?.length !== 0 ? rule?.some((c: string) => claims.includes(c)) : true;
  } else {
    isVisible = rule ? claims.includes(rule) : true;
  }

  return isVisible ? (
    <Link {...linkProps} className={classes.link}>
      {children}
    </Link>
  ) : null;
};

export default ClaimLink;
