import React, { ReactElement, ReactNode } from "react";

import { ListItemText, ListItemIcon } from "@mui/material";

import { classes, ListItem } from "./styles";

export interface MenuItemProps {
  id: string;
  icon: ReactNode;
  title: string;
  onClick?: (id: string) => void;
}

export const MenuItem = ({
  id,
  icon,
  title,
  onClick,
}: MenuItemProps): ReactElement => {
  const handleListItemClick = () => {
    return typeof onClick === "function" ? onClick(id) : undefined;
  };

  return (
    <ListItem onClick={handleListItemClick} id={id} className={classes.item}>
      <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default MenuItem;
