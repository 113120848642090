import React, { forwardRef, useEffect, useState } from "react";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";

import { getNthIndex } from "shared/utils";

export enum LinkTypeEnum {
  native = "native",
  preventable = "preventable",
  toggle = "toggle",
}

export type LinkType = keyof typeof LinkTypeEnum;

export interface LinkProps extends RouterLinkProps {
  to: { pathname: string; search: string } | string;
  type?: LinkType;
}

export const Link = forwardRef(
  (
    { children, to, type = LinkTypeEnum.preventable, ...otherProps }: LinkProps,
    ref: any
  ) => {
    const { pathname, search } = useLocation();
    const linkURL = typeof to === "string" ? to : to.pathname;
    const [linkTo, setLinkTo] = useState(linkURL);
    const linkQuery = typeof to === "string" ? undefined : to.search;
    const isActive = pathname.includes(linkURL) && search === linkQuery;

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      if (isActive && type === LinkTypeEnum.preventable) {
        event.preventDefault();
      }
    };

    useEffect(() => {
      if (type !== LinkTypeEnum.toggle) {
        return;
      }

      if (pathname.includes(linkURL)) {
        setLinkTo(
          linkURL
            .substr(
              0,
              getNthIndex(linkURL, "/", (linkURL.match(/\//g) || []).length)
            )
            .concat(linkQuery ?? search)
        );
      } else {
        setLinkTo(linkURL.concat(linkQuery ?? search));
      }
    }, [pathname, to, search, linkQuery, linkURL, type]);

    return (
      <RouterLink
        {...otherProps}
        innerRef={ref}
        onClick={handleClick}
        to={type === LinkTypeEnum.toggle ? linkTo : to}
      >
        {children}
      </RouterLink>
    );
  }
);

export default Link;
