import React, { ReactElement, useState } from "react";

import { Button, Grid, TableCell, TextField } from "@mui/material";
import { Prompt, useParams } from "react-router-dom";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { generateUrl } from "shared/utils";
import { AgencyEnum } from "master-data/enums";
import { EDIT_MDT_CHECK_REMARK } from "master-data/constants";
import { EMPTY_STRING, UNSAVED_DATA_MESSAGE } from "shared/constants";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";

import { classes, TableRow } from "./styles";

const { PUT } = FetchMethodEnum;

export interface TableRemarkItemProps {
  remark?: string;
  isEditable: boolean;
  onRemarkSaved: () => void;
}

export const TableRemarkItem = ({
  remark = EMPTY_STRING,
  isEditable,
  onRemarkSaved,
}: TableRemarkItemProps): ReactElement => {
  const [value, setValue] = useState(remark ?? EMPTY_STRING);

  const { agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];
  const { executeFetch } = useFetch<null, null>(null, { skip: true });

  // #region Methods
  const handleChange = (event: any) => {
    setValue(event.target.value ?? EMPTY_STRING);
  };

  const cancelChange = () => {
    setValue(remark ?? EMPTY_STRING);
  };

  const handleSave = async () => {
    const url = generateUrl(EDIT_MDT_CHECK_REMARK, {
      agencyId,
      documentId,
    });
    await executeFetch(url, {
      method: PUT,
      body: { remark: value.trim() },
      skip: false,
    });
    onRemarkSaved();
  };
  // #endregion

  const isRemarkChanged = (remark ?? EMPTY_STRING) !== value;

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={11}>
              <TextField
                id="remark-input"
                label="Remarks"
                multiline
                rows={4}
                fullWidth
                value={value}
                disabled={!isEditable}
                onChange={handleChange}
                inputProps={{ maxLength: 1000 }}
                variant="outlined"
                color="primary"
                classes={{}}
              />
            </Grid>
            {isRemarkChanged && isEditable && (
              <Grid item className={classes.buttonGrid} xs={1}>
                <Button
                  color="primary"
                  className={classes.button}
                  variant="outlined"
                  onClick={handleSave}
                >
                  Save Remarks
                </Button>
                <Button
                  color="secondary"
                  className={classes.button}
                  variant="outlined"
                  onClick={cancelChange}
                >
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>
      <Prompt when={isRemarkChanged} message={UNSAVED_DATA_MESSAGE} />
    </>
  );
};

export default TableRemarkItem;
