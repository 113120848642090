import React, { ReactElement } from "react";

import { Grid } from "@mui/material";

import { TextField } from "core";

import { FormStandardFieldProps } from "../props";

export const FormUserGroup = ({
  isEdit,
  control,
  errors,
}: FormStandardFieldProps): ReactElement => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        <Grid item xs={6}>
          <TextField
            name="name"
            id="name"
            label="Name *"
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Grid item xs={12}>
          <TextField
            name="description"
            id="description"
            label="Description"
            multiline
            rows={4}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormUserGroup;
