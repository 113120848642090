import React, { ReactElement } from "react";

import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import { Grid } from "@mui/material";

import { AppBar } from "core";

import { getPath } from "shared/utils";

import { Profile } from "../Profile";
import { routes } from "./routes";
import { EDIT_MODE } from "../ClientDocumentDialog/config";

export interface HeaderBarProps {
  open?: boolean;
}

export const HeaderBar = ({ open = false }: HeaderBarProps): ReactElement => {
  const { path: base } = useRouteMatch();
  const { pathname: url } = useLocation();

  const inEditMode = url.includes(`/${EDIT_MODE}`);

  return (
    <AppBar open={open}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Switch>
            {routes.map(({ path, main: Component, exact, id }) => {
              const routePath = getPath(base, path);
              return (
                <Route exact={exact} key={id} path={routePath}>
                  {({ match }) => {
                    const isActive = Boolean(match);
                    return !inEditMode && isActive && <Component />;
                  }}
                </Route>
              );
            })}
          </Switch>
        </Grid>
        <Grid item>
          <Profile />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default HeaderBar;
