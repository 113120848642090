import React, { ReactElement } from "react";

import { Redirect } from "react-router-dom";

import { RouteProps } from "shared/types";

import { PAGE_ERROR } from "shared/constants";
import { PAGE_DASHBOARD_PRODUCT, PAGE_OVERVIEW } from "product/constants";
import { ProductDialog } from "product/components/ProductDialog";
import { ProductDashboard } from "../ProductDashboard";
import { ProductOverviewPage } from "../ProductOverview";

export const routes: RouteProps[] = [
  {
    id: "productDialogRoute",
    rule: "CanViewDashboardProduct",
    exact: true,
    path: [`${PAGE_DASHBOARD_PRODUCT}`],
    main: (): ReactElement => <ProductDialog />,
  },
  {
    id: "productDashboardRoute",
    rule: "CanViewDashboardProduct",
    exact: true,
    path: [`${PAGE_DASHBOARD_PRODUCT}/:productId`],
    main: (): ReactElement => <ProductDashboard />,
  },
  {
    id: "productOverviewPageRoute",
    rule: "CanViewOverviewProduct",
    path: PAGE_OVERVIEW,
    main: (): ReactElement => <ProductOverviewPage />,
  },
  {
    id: "errorPageRoute",
    rule: "CanViewActionOverview",
    path: "*",
    main: (): ReactElement => <Redirect to={`/${PAGE_ERROR}`} />,
  },
];
