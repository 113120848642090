import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";

import { TextField, CheckField } from "core";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormScopeOfServicesProps extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormScopeOfServices = ({
  isEdit,
  control,
  errors,
  revisionValues,
  fillOption,
  setValue,
}: FormScopeOfServicesProps): ReactElement => {
  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("strategy", true);
      setValue("strategyTbba", true);
      setValue("creationProduction", true);
      setValue("creationProductionTbba", true);
      setValue("planning", true);
      setValue("planningTbba", true);
      setValue("influencerMarketing", true);
      setValue("influencerMarketingTbba", true);
      setValue("trading", true);
      setValue("tradingTbba", true);
      setValue("brandedContent", true);
      setValue("brandedContentTbba", true);
      setValue("competitiveReports", true);
      setValue("competitiveReportsTbba", true);
      setValue("research", true);
      setValue("researchTbba", true);
      setValue("thirdPartyDataDelivery", true);
      setValue("thirdPartyTbba", true);
      setValue("training", true);
      setValue("trainingTbba", true);
      setValue("dashboard", true);
      setValue("dashboardTbba", true);
      setValue("other", true);
      setValue("otherTbba", true);
      setValue("taggingTracking", true);
      setValue("taggingTrackingTbba", true);
      setValue("otherText", "Other - Test");
    }
  }, [fillOption, setValue]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <CheckField
          control={control}
          disabled={!isEdit}
          name="strategy"
          label="Strategy"
          revisionValue={revisionValues?.Strategy}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="strategyTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.StrategyTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="creationProduction"
          label="Creation / Production"
          revisionValue={revisionValues?.CreationProduction}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="creationProductionTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.CreationProductionTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          control={control}
          disabled={!isEdit}
          name="planning"
          label="Planning"
          revisionValue={revisionValues?.Planning}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="planningTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.PlanningTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="influencerMarketing"
          label="Influencer Marketing"
          revisionValue={revisionValues?.InfluencerMarketing}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="influencerMarketingTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.InfluencerMarketingTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="trading"
          label="Trading"
          revisionValue={revisionValues?.Trading}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="tradingTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.TradingTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="brandedContent"
          label="Branded Content"
          revisionValue={revisionValues?.BrandedContent}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="brandedContentTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.BrandedContentTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="competitiveReports"
          label="Competitive Reports"
          revisionValue={revisionValues?.CompetitiveReports}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="competitiveReportsTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.CompetitiveReportsTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="research"
          label="Research"
          revisionValue={revisionValues?.Research}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="researchTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.ResearchTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="thirdPartyDataDelivery"
          label="Third Party Data Delivery"
          revisionValue={revisionValues?.ThirdPartyDataDelivery}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="thirdPartyTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.ThirdPartyTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="training"
          label="Training"
          revisionValue={revisionValues?.Training}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="trainingTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.TrainingTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="dashboard"
          label="Dashboard"
          revisionValue={revisionValues?.Dashboard}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="dashboardTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.DashboardTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="other"
          label="Other"
          revisionValue={revisionValues?.Other}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="otherTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.OtherTbba}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="taggingTracking"
          label="Tagging & Tracking"
          revisionValue={revisionValues?.TaggingTracking}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckField
          disabled={!isEdit}
          control={control}
          name="taggingTrackingTbba"
          label="To be budgeted additionally"
          revisionValue={revisionValues?.TaggingTrackingTbba}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Other"
          id="otherText"
          name="otherText"
          error={!!errors.otherText?.message}
          helperText={errors.otherText?.message}
          revisionValue={revisionValues?.OtherText}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormScopeOfServices;
