import { useStateMachine } from "little-state-machine";

export const useRequestSniffer = (): { isRequestPending: boolean } => {
  const {
    state: { appState },
  } = useStateMachine();
  const { requestQueue } = appState;
  const isRequestPending = requestQueue.length > 0;

  return { isRequestPending };
};

export default useRequestSniffer;
