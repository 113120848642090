import { styled } from "@mui/material";
import { Link as MuiLink } from "../Link";

const PREFIX = "ClaimLinkStyles";
export const classes = {
  link: `${PREFIX}-link`,
};

export const Link = styled(MuiLink)(() => ({
  [`&.${classes.link}`]: {
    textDecoration: "none",
  },
}));
