import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, TextField, Autocomplete } from "core";

import { yesNoOptions } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormAdditionalInvoicingRequirementsProps
  extends FormStandardFieldProps {
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormAdditionalInvoicingRequirements = ({
  isEdit,
  control,
  errors,
  revisionValues,
  fillOption,
  setValue,
}: FormAdditionalInvoicingRequirementsProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const { enumFrequency } = cacheData;
  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("agingAnalysisRequired", yesNoOptions[0].key);
      setValue(
        "additionalInvoicingInformation",
        "Additional Invoicing Information - Test"
      );
      if (enumFrequency[0]) {
        setValue("frequencyId", enumFrequency[0].key);
      }
    }
  }, [fillOption, setValue, enumFrequency]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          id="agingAnalysisRequired"
          name="agingAnalysisRequired"
          label="Aging analysis required"
          error={!!errors.agingAnalysisRequired?.message}
          helperText={errors.agingAnalysisRequired?.message}
          revisionValue={revisionValues?.AgingAnalysisRequired}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="frequencyId"
          name="frequencyId"
          options={enumFrequency}
          disabled={!isEdit}
          control={control}
          label="Frequency"
          error={!!errors.frequencyId?.message}
          helperText={errors.frequencyId?.message}
          revisionValue={revisionValues?.FrequencyId}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="additionalInvoicingInformation"
          id="additionalInvoicingInformation"
          label="Additional invoicing information"
          multiline
          rows={4}
          error={!!errors.additionalInvoicingInformation?.message}
          helperText={errors.additionalInvoicingInformation?.message}
          revisionValue={revisionValues?.AdditionalInvoicingInformation}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormAdditionalInvoicingRequirements;
