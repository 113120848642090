import React, { ReactElement } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import { DeclineComment } from "../../types";

export interface CommentHistoryTableProps {
  /*
   * Comments history
   */
  comments: DeclineComment[];
}

export const CommentHistoryTable = ({
  comments,
}: CommentHistoryTableProps): ReactElement => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">User</TableCell>
            <TableCell align="left">Declined Status</TableCell>
            <TableCell align="left">Declined Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comments.map((comment) => (
            <TableRow key={comment.commentId}>
              <TableCell component="th" scope="row" align="left">
                {comment.date}
              </TableCell>
              <TableCell align="left">{comment.declinedBy}</TableCell>
              <TableCell align="left">{comment.declineStatusName}</TableCell>
              <TableCell align="left">
                <Tooltip title={comment.comment}>
                  <Typography variant="subtitle2">
                    Hover to see comment
                  </Typography>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommentHistoryTable;
