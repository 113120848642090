import { string, object, number } from "yup";

import { nanToNull } from "shared/utils";
import { MAX_LENGTH_1000_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  writingHoursPerId: number().nullable().transform(nanToNull),
  explanationWrittenHoursId: number().nullable().transform(nanToNull),
  splittingHoursInScope: number().transform(nanToNull).nullable(),
  fixedItemsFunctionId: number().nullable().transform(nanToNull),
  additionalInformationHours: string()
    .trim()
    .max(1000, MAX_LENGTH_1000_VALIDATION_MESSAGE),
});
