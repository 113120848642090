import React, { ReactElement } from "react";

import { Redirect } from "react-router-dom";

import {
  ClientDocumentDialog,
  DeclineCommentDialog,
  ClientDocumentStatusLogsDialog,
} from "master-data/components";

import {
  PAGE_OVERVIEW,
  PAGE_OVERVIEW_ACTION,
  PAGE_DASHBOARD_CLIENT,
} from "master-data/constants";
import { RouteProps } from "shared/types";
import { PAGE_ERROR } from "shared/constants";

import { ClientDocumentOverviewPage } from "../ClientDocumentOverview";
import { ClientDocumentDashboardPage } from "../ClientDocumentDashboard";
import { ClientDocumentActionOverviewPage } from "../ClientDocumentActionOverview";

export const routes: RouteProps[] = [
  {
    id: "clientDocumentDialogRoute",
    rule: "CanViewDashboardClient",
    exact: true,
    path: [
      `${PAGE_DASHBOARD_CLIENT}`,
      `${PAGE_DASHBOARD_CLIENT}/:documentId/:sectionType?/:accordionType?/edit`,
    ],
    main: (): ReactElement => <ClientDocumentDialog />,
  },

  {
    id: "clientDocumentDashboardPageRoute",
    rule: "CanViewDashboardClient",
    path: `${PAGE_DASHBOARD_CLIENT}/:documentId/:sectionType?/:accordionType?`,
    main: (): ReactElement => <ClientDocumentDashboardPage />,
  },
  {
    id: "clientDocumentOverviewPageRoute",
    rule: "CanViewOverview",
    path: PAGE_OVERVIEW,
    main: (): ReactElement => <ClientDocumentOverviewPage />,
  },
  {
    id: "clientDocumentActionOverviewPageRoute",
    rule: "CanViewActionOverview",
    path: PAGE_OVERVIEW_ACTION,
    main: (): ReactElement => <ClientDocumentActionOverviewPage />,
  },
  {
    id: "errorPageRoute",
    rule: "CanViewActionOverview",
    path: "*",
    main: (): ReactElement => <Redirect to={`/${PAGE_ERROR}`} />,
  },
];

export const dialogRoutes: RouteProps[] = [
  {
    id: "DeclineCommentDialogRoute",
    rule: ["CanViewOverview", "CanViewDashboardClient"],
    path: [
      `${PAGE_OVERVIEW}/comment`,
      `${PAGE_OVERVIEW_ACTION}/comment`,
      `${PAGE_DASHBOARD_CLIENT}/:documentId/:sectionType?/:accordionType?/comment`,
    ],
    main: (): ReactElement => <DeclineCommentDialog />,
  },
  {
    id: "StatusLogDialogRoute",
    rule: ["CanViewOverview", "CanViewDashboardClient"],
    path: [
      `${PAGE_OVERVIEW}/status`,
      `${PAGE_OVERVIEW_ACTION}/status`,
      `${PAGE_DASHBOARD_CLIENT}/:documentId/:sectionType?/:accordionType?/status`,
    ],
    main: (): ReactElement => <ClientDocumentStatusLogsDialog />,
  },
];
