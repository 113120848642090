import React, { ReactElement } from "react";
import {
  Grid,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { CopyIcon } from "master-data/components/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { jordyBlue } from "shared/assets/colors";

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
});

export interface MultiEmailActionUserProps {
  documentId: string;
  role: string | undefined;
  emails: string[] | undefined;
}

export const MultiEmailActionUser = ({
  documentId,
  role,
  emails,
}: MultiEmailActionUserProps): ReactElement => {
  return (
    <Typography variant="subtitle1">
      {`[${role}] `}
      <NoMaxWidthTooltip
        title={
          <Grid container>
            {emails?.map((email, index) => (
              <Grid item xs={6} key={email}>
                <CopyToClipboard text={email}>
                  <IconButton
                    name={`copy_bttn_${documentId}_${index}`}
                    id={`copy_bttn_${documentId}_${index}`}
                    size="small"
                  >
                    <CopyIcon />
                  </IconButton>
                </CopyToClipboard>
                {` ${email}`}
              </Grid>
            ))}
          </Grid>
        }
      >
        <IconButton
          name={`copy_bttn_${documentId}`}
          id={`copy_bttn_${documentId}`}
          size="small"
        >
          <Info style={{ color: jordyBlue }} />
        </IconButton>
      </NoMaxWidthTooltip>
    </Typography>
  );
};

export default MultiEmailActionUser;
