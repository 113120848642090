import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  invoiceAddressClient: EMPTY_STRING,
  mailingAddress: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  city: EMPTY_STRING,
  country: EMPTY_STRING,
  invoicingMethodId: null,
  invoiceMailingAddress: [],
  attn: EMPTY_STRING,
  emailAccountancy: EMPTY_STRING,
  phoneNumberAccountancy: EMPTY_STRING,
  layoutLanguageId: null,
  thirdPartyInvoicingPortal: EMPTY_STRING,
  tempDistributionMethodId: null,
  invoicingDaysId: null,
};
