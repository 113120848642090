import { GlobalState } from "little-state-machine";
import { ProductData } from "../types";
import { defaultProductData } from "../constants";

export function setOpenStatusDialog(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    productData: {
      ...state.productData,
      openStatusDialog: payload,
    },
  };
}

export function setChangeStatusId(
  state: GlobalState,
  payload: NullableNumber
): GlobalState {
  return {
    ...state,
    productData: {
      ...state.productData,
      changeStatusId: payload,
    },
  };
}

export function setMarathonProductId(
  state: GlobalState,
  payload: NullableString
): GlobalState {
  return {
    ...state,
    productData: {
      ...state.productData,
      marathonProductId: payload,
    },
  };
}

export function setIsFormDirty(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    productData: {
      ...state.productData,
      isFormDirty: payload,
    },
  };
}

export function setProductData(
  state: GlobalState,
  payload: ProductData
): GlobalState {
  return {
    ...state,
    productData: { ...state.productData, ...payload },
  };
}

export function resetProductData(state: GlobalState): GlobalState {
  return {
    ...state,
    productData: defaultProductData,
  };
}

export function setIsProductEditable(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    productData: {
      ...state.productData,
      isProductEditable: payload,
    },
  };
}

export function setIsProductStatusChanged(
  state: GlobalState,
  payload: boolean
): GlobalState {
  return {
    ...state,
    productData: {
      ...state.productData,
      isProductStatusChanged: payload,
    },
  };
}
