import React, { useEffect, useState, ChangeEvent, ReactElement } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import {
  Button,
  Checkbox,
  Chip,
  Grid,
  SelectChangeEvent,
  TableBody,
  Typography,
  Toolbar,
  TableCell,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useFetch, useProfileGetter, useQuery } from "shared/hooks";
import {
  TableHeader,
  TableFooter,
  TableRowEmpty,
  ClearAll,
  DynamicFilter,
  ConfirmationDialog,
} from "core";
import {
  ImportUsersExtensionsSearch,
  ImportUsersTableRow,
} from "access-manager/components";
import { EMPTY_STRING } from "shared/constants";
import { generateUrl } from "shared/utils";
import {
  FETCH_USERS,
  FETCH_USERS_DIALOG_CONTENT,
  FETCH_USERS_DIALOG_TITLE,
  IMPORT_USERS_DIALOG_CONTENT,
  IMPORT_USERS_DIALOG_TITLE,
  INFO_FETCH_CONTENT,
  MANAGE_USERS,
  REMOVE_USERS_DIALOG_CONTENT,
  REMOVE_USERS_DIALOG_TITLE,
  VIEW_IMPORT_USERS_PAGE,
} from "access-manager/constants";
import { FetchMethodEnum } from "shared/enums";
import {
  setIsSelectedAMUsers,
  setIsOpenImportSelectedDialog,
  setIsOpenRemoveSelectedDialog,
  setIsOpenFetchUsersDialog,
} from "store/actions";
import { Divider, TableRow } from "shared/styles";
import {
  defaultRowsPerPageOptions,
  dynamicFilterOptions,
  sortOptions,
} from "./config";
import { classes, Table } from "./styles";
import { AMUsersResponse, AMUser, StateType, IPostResponse } from "./types";

const { GET, POST, DELETE } = FetchMethodEnum;

export const ImportUsersPage = (): ReactElement => {
  const {
    push,
    location: { search, state: urlState },
  } = useHistory<StateType>();

  const { actionSelectedItems } = urlState ?? {};

  const { url: base } = useRouteMatch();
  const query = useQuery();
  const queryObject = Object.fromEntries(query);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [isSelectAll, setSelectAll] = useState<boolean | undefined>(false);

  const {
    actions,
    state: { appState, profile },
  } = useStateMachine({
    setIsSelectedAMUsers,
    setIsOpenImportSelectedDialog,
    setIsOpenRemoveSelectedDialog,
    setIsOpenFetchUsersDialog,
  });

  const {
    page: qPage,
    pageSize: qPageSize,
    sortByColumn,
    isascending,
    filterExtensions,
    ...filters
  } = queryObject;

  useProfileGetter(0);

  const {
    isOpenImportSelectedDialog,
    isOpenRemoveSelectedDialog,
    isOpenFetchUsersDialog,
  } = appState;

  const apiUrl = generateUrl(MANAGE_USERS);

  const { data, updateParams, executeFetch, isLoading } = useFetch<
    AMUsersResponse,
    IPostResponse
  >(apiUrl, {
    method: GET,
    initialParams: { ...queryObject },
  });

  const { get: getData, post: postData } = data ?? {};
  const { claims } = profile;
  const canViewImportUsersPage = claims.includes(VIEW_IMPORT_USERS_PAGE);
  const isDisabledClearAllButton = filterExtensions == null && isEmpty(filters);

  const {
    users = [],
    page = 1,
    pageSize = 10,
    totalItems = 0,
    lastFetched = null,
  } = getData ?? {};

  // #region Quick Filters
  const handleFilterExtensionsSearch = (extensionId: string) => {
    const currentExtensions = filterExtensions
      ? filterExtensions.split("_")
      : [];
    const nextExtensions = currentExtensions.includes(extensionId)
      ? currentExtensions.filter((s) => s !== extensionId)
      : currentExtensions.concat(extensionId);
    const queryParams = {
      ...queryObject,
      page: 1,
      filterExtensions: isEmpty(nextExtensions)
        ? undefined
        : nextExtensions.join("_"),
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
    push(newUrl, {
      actionSelectedItems: false,
    });
  };

  // #endregion

  const handleClearAll = () => {
    const queryParams = {
      page: 1,
      pageSize: 10,
      sortByColumn: queryObject.sortByColumn,
      isascending: queryObject.isascending,
    };
    const newUrl = generateUrl(base, undefined, queryParams);
    push(newUrl, {
      actionSelectedItems: false,
    });
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };

  // #region Users Actions
  const handleRemoveSelectedUsers = () => {
    const removeUsersUrl = generateUrl(MANAGE_USERS);
    const removeUsers = users.filter((u) => checkedItems.includes(u.username));

    executeFetch(removeUsersUrl, {
      method: DELETE,
      body: { users: removeUsers },
    });
  };

  const handleCloseRemoveSelectedDialog = () => {
    actions.setIsOpenRemoveSelectedDialog(false);
  };

  const handleImportSelectedUsers = () => {
    const importUsersUrl = generateUrl(MANAGE_USERS);
    const importUsers = users.filter((u) => checkedItems.includes(u.username));
    executeFetch(importUsersUrl, {
      method: POST,
      body: { users: importUsers },
    });
  };

  const handleCloseImportSelectedDialog = () => {
    actions.setIsOpenImportSelectedDialog(false);
  };

  const handleOpenFetchUsersDialog = () => {
    actions.setIsOpenFetchUsersDialog(true);
  };

  const handleFetchUsers = () => {
    const fetchUsersUrl = generateUrl(FETCH_USERS);
    executeFetch(fetchUsersUrl, {
      method: POST,
    });
  };

  const handleCloseFetchUsersDialog = () => {
    actions.setIsOpenFetchUsersDialog(false);
  };
  // #endregion

  // #region Select checkboxes
  const handleCheckboxClick = (username: string) => {
    const items = checkedItems?.some((ci) => ci === username)
      ? checkedItems.filter((ci) => ci !== username)
      : [...checkedItems, username];

    setCheckedItems(items);
    if (isEmpty(items)) {
      setSelectAll(false);
    }
    if (!isEmpty(items) && users.length !== items.length) {
      setSelectAll(undefined);
    }
    if (users.length === items.length) {
      setSelectAll(true);
    }
    actions.setIsSelectedAMUsers(items.length !== 0);
  };

  const handleMainCheckboxClick = () => {
    const usernames = users.map((u) => u.username);

    if (!isSelectAll) {
      setSelectAll(true);
      setCheckedItems(usernames);
      actions.setIsSelectedAMUsers(true);
    }

    if (isSelectAll) {
      setSelectAll(false);
      setCheckedItems([]);
      actions.setIsSelectedAMUsers(false);
    }
  };

  const handleInvert = () => {
    const usernames = users.map((u) => u.username);
    const invertArray = usernames.filter((x) => !checkedItems.includes(x));

    setCheckedItems(invertArray);

    if (isEmpty(invertArray)) {
      setSelectAll(false);
    }

    if (users.length === invertArray.length) {
      setSelectAll(true);
    }

    actions.setIsSelectedAMUsers(invertArray.length !== 0);
  };
  // #endregion

  // #region Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    const queryPageSize = query.get("pageSize");
    const queryParams = {
      ...queryObject,
      page: newPage + 1,
      pageSize: queryPageSize,
    }; // * newPage +1 reason is TablePagination page is zeroBased
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl, {
      actionSelectedItems: false,
    });
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const queryParams = {
      ...queryObject,
      page: 1,
      pageSize: event.target.value,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl);
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };
  // #endregion Pagination

  // #region Dynamic filter

  const handleDynamicFilter = (dynFilters: {
    [key: string]: string | undefined;
  }) => {
    const key = Object.keys(dynFilters)[0];
    const queryParams = {
      ...queryObject,
      page: 1,
      [key]: dynFilters[key],
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl, {
      actionSelectedItems: false,
    });
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };
  // #endregion

  // #region Sort
  const handleChangeSort = (event: SelectChangeEvent<unknown>) => {
    const targetVal = event.target.value as string | null | undefined;
    const isAsc = targetVal ? targetVal.indexOf("_asc") !== -1 : false;
    const queryParams = {
      ...queryObject,
      page: 1,
      sortByColumn: targetVal ?? EMPTY_STRING,
      isascending: isAsc,
    };
    const newUrl = generateUrl(base, undefined, queryParams);

    push(newUrl, {
      actionSelectedItems: false,
    });
    setCheckedItems([]);
    setSelectAll(false);
    actions.setIsSelectedAMUsers(false);
  };
  // #endregion

  // #region Effects
  useEffect(() => {
    // ! it is not possible to use existing queryObject due to refrence change on every render.
    const newQuery = new URLSearchParams(search);
    const newQueryObj = Object.fromEntries(newQuery);
    updateParams(newQueryObj);
    if (actionSelectedItems || postData?.isSuccessful) {
      setCheckedItems([]);
      setSelectAll(false);
      actions.setIsSelectedAMUsers(false);
      actions.setIsOpenFetchUsersDialog(false);
      actions.setIsOpenImportSelectedDialog(false);
      actions.setIsOpenRemoveSelectedDialog(false);
    }
  }, [search, updateParams, actionSelectedItems, actions, postData]);

  return (
    <>
      {canViewImportUsersPage && (
        <Toolbar>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item xs={10}>
                  <Grid container alignItems="flex-start" spacing={1}>
                    <Grid item>
                      <Button
                        id="button-fetchUsers"
                        color="primary"
                        variant="contained"
                        onClick={handleOpenFetchUsersDialog}
                      >
                        Fetch from AAD
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1" gutterBottom align="right">
                    Last Fetch: {lastFetched}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                alignContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <ImportUsersExtensionsSearch
                    onExtensionClick={handleFilterExtensionsSearch}
                    selected={filterExtensions}
                  />
                </Grid>
                <Grid item>
                  <DynamicFilter
                    updateUrl={handleDynamicFilter}
                    queryFilters={filters}
                    filterOptions={dynamicFilterOptions}
                    limit={3}
                  />
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item marginLeft={-3}>
                  <ClearAll
                    onClear={handleClearAll}
                    disabled={isDisabledClearAllButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      )}
      <Grid item xs={12}>
        <Table size="small" className={classes.table}>
          {canViewImportUsersPage && (
            <>
              <TableHeader
                sortOptions={sortOptions}
                sortValue={queryObject.sortByColumn}
                onSortChange={handleChangeSort}
                rowsPerPageOptions={defaultRowsPerPageOptions}
                count={totalItems}
                rowsPerPage={pageSize}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <TableBody>
                <TableRow>
                  <TableCell>
                    {!isEmpty(users) ? (
                      <Checkbox
                        id="check-all"
                        indeterminate={isSelectAll === undefined}
                        inputProps={{ "aria-label": "main checkbox" }}
                        onChange={() => handleMainCheckboxClick()}
                        checked={isSelectAll}
                      />
                    ) : (
                      EMPTY_STRING
                    )}
                  </TableCell>
                  <TableCell>
                    {!isEmpty(users) ? (
                      <Chip
                        id="button-invert"
                        color="primary"
                        onClick={handleInvert}
                        label="Invert Selection"
                        clickable
                        variant="outlined"
                        style={{ alignSelf: "flex-end" }}
                      />
                    ) : (
                      EMPTY_STRING
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          )}
          <TableBody>
            {isEmpty(users) ? (
              <TableRowEmpty />
            ) : (
              users.map((user: AMUser) => (
                <ImportUsersTableRow
                  key={user.username}
                  {...user}
                  onCheckboxClick={handleCheckboxClick}
                  isChecked={checkedItems.includes(user.username)}
                />
              ))
            )}
          </TableBody>
          <TableFooter
            rowsPerPageOptions={defaultRowsPerPageOptions}
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
        {isOpenImportSelectedDialog && (
          <ConfirmationDialog
            open={isOpenImportSelectedDialog}
            title={IMPORT_USERS_DIALOG_TITLE}
            message={IMPORT_USERS_DIALOG_CONTENT}
            isDisableAction={isLoading}
            onConfirm={handleImportSelectedUsers}
            onCancel={handleCloseImportSelectedDialog}
          />
        )}
        {isOpenRemoveSelectedDialog && (
          <ConfirmationDialog
            open={isOpenRemoveSelectedDialog}
            title={REMOVE_USERS_DIALOG_TITLE}
            message={REMOVE_USERS_DIALOG_CONTENT}
            isDisableAction={isLoading}
            onConfirm={handleRemoveSelectedUsers}
            onCancel={handleCloseRemoveSelectedDialog}
          />
        )}
        {isOpenFetchUsersDialog && (
          <ConfirmationDialog
            open={isOpenFetchUsersDialog}
            title={FETCH_USERS_DIALOG_TITLE}
            message={`${INFO_FETCH_CONTENT}${FETCH_USERS_DIALOG_CONTENT}`}
            isDisableAction={isLoading}
            onConfirm={handleFetchUsers}
            onCancel={handleCloseFetchUsersDialog}
          />
        )}
      </Grid>
    </>
  );
};

export default ImportUsersPage;
