import React, { useEffect, ReactElement } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, TextField, DateField, Autocomplete } from "core";

import { EMPTY_STRING, MANDATORY_LABEL, yesNoOptions } from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormGeneralContractInfoProps extends FormStandardFieldProps {
  /**
   * setValue
   */
  setValue: any;
  /**
   * isNew flag
   */
  isNew: boolean;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormGeneralContractInfo = ({
  isEdit,
  control,
  errors,
  revisionValues,
  setValue,
  isNew,
  fillOption,
}: FormGeneralContractInfoProps): ReactElement => {
  const {
    state: { cacheData, clientDocumentData },
  } = useStateMachine();

  const { isContract } = clientDocumentData;

  const {
    enumContractType,
    enumRedGreenSchedule,
    enumContractTerm,
    enumFiscalYear,
    enumContractStatus,
    enumDutyToReport,
  } = cacheData;

  // #region Effects
  useEffect(() => {
    if (isNew && isEdit) {
      setValue("fiscalYearId", 1);
    }
  }, [isEdit, isNew, setValue]);

  useEffect(() => {
    if (fillOption) {
      if (enumContractStatus[0]) {
        setValue("contractStatusId", enumContractStatus[0].key);
      }
      if (
        isContract ||
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("contractAdded", yesNoOptions[0].key);
        setValue("noticePeriod", "Notice Period Test");
        setValue("competitiveClients", yesNoOptions[0].key);
        setValue("competitionClause", yesNoOptions[0].key);
        setValue("startDate", new Date());
        setValue("endDate", new Date());
        setValue("irfsAdded", yesNoOptions[0].key);
        if (enumContractType[0]) {
          setValue("contractTypeId", enumContractType[0].key);
        }
        if (enumRedGreenSchedule[0]) {
          setValue("redGreenScheduleId", enumRedGreenSchedule[0].key);
        }
        if (enumContractTerm[0]) {
          setValue("contractTermId", enumContractTerm[0].key);
        }
        if (enumDutyToReport[0]) {
          setValue("dutyToReporFinancialInteresttId", enumDutyToReport[0].key);
          setValue("dutyToReportSubContractingId", enumDutyToReport[0].key);
        }
      }
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        if (enumFiscalYear[0]) {
          setValue("fiscalYearId", enumFiscalYear[0].key);
        }
        setValue("nationalAddendum", yesNoOptions[0].key);
        setValue("gdprClause", yesNoOptions[0].key);
        setValue("contractSection", "Contract Section Test");
      }
    }
  }, [
    fillOption,
    setValue,
    isContract,
    enumContractStatus,
    enumContractType,
    enumRedGreenSchedule,
    enumContractTerm,
    enumDutyToReport,
    enumFiscalYear,
  ]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="contractTypeId"
          name="contractTypeId"
          label={`Type ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={enumContractType}
          error={!!errors.contractTypeId?.message}
          helperText={errors.contractTypeId?.message}
          revisionValue={revisionValues?.ContractTypeId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          id="contractAdded"
          name="contractAdded"
          label={`Contract Added 
              ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={yesNoOptions}
          error={!!errors.contractAdded?.message}
          helperText={errors.contractAdded?.message}
          revisionValue={revisionValues?.ContractAdded}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          name="noticePeriod"
          id="noticePeriod"
          label={`Notice Period ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.noticePeriod?.message}
          helperText={errors.noticePeriod?.message}
          revisionValue={revisionValues?.NoticePeriod}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          autoHighlight
          id="redGreenScheduleId"
          name="redGreenScheduleId"
          label={`Red/Green Schedule 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={enumRedGreenSchedule}
          error={!!errors.redGreenScheduleId?.message}
          helperText={errors.redGreenScheduleId?.message}
          revisionValue={revisionValues?.RedGreenScheduleId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          id="nationalAddendum"
          name="nationalAddendum"
          label="Local Service Agreement"
          options={yesNoOptions}
          error={!!errors.nationalAddendum?.message}
          helperText={errors.nationalAddendum?.message}
          revisionValue={revisionValues?.NationalAddendum}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          id="competitiveClients"
          name="competitiveClients"
          label={`Competitive Clients 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={yesNoOptions}
          error={!!errors.competitiveClients?.message}
          helperText={errors.competitiveClients?.message}
          revisionValue={revisionValues?.CompetitiveClients}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          id="competitionClause"
          name="competitionClause"
          label={`Competition Clause 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={yesNoOptions}
          error={!!errors.competitionClause?.message}
          helperText={errors.competitionClause?.message}
          revisionValue={revisionValues?.CompetitionClause}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="contractTermId"
          name="contractTermId"
          label={`Contract Term ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={enumContractTerm}
          error={!!errors.contractTermId?.message}
          helperText={errors.contractTermId?.message}
          revisionValue={revisionValues?.ContractTermId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          autoHighlight
          id="dutyToReporFinancialInteresttId"
          name="dutyToReporFinancialInteresttId"
          label={`Duty To Report Financial Interest 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={enumDutyToReport}
          error={!!errors.dutyToReporFinancialInteresttId?.message}
          helperText={errors.dutyToReporFinancialInteresttId?.message}
          revisionValue={revisionValues?.DutyToReporFinancialInteresttId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          autoHighlight
          id="dutyToReportSubContractingId"
          name="dutyToReportSubContractingId"
          label={`Duty To Report Sub-Contracting 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={enumDutyToReport}
          error={!!errors.dutyToReportSubContractingId?.message}
          helperText={errors.dutyToReportSubContractingId?.message}
          revisionValue={revisionValues?.DutyToReportSubContractingId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          fullWidth
          name="startDate"
          label={`Start Date ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.startDate?.message}
          helperText={errors.startDate?.message}
          revisionValue={revisionValues?.StartDate}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          fullWidth
          name="endDate"
          label="End Date"
          error={!!errors.endDate?.message}
          helperText={errors.endDate?.message}
          revisionValue={revisionValues?.EndDate}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          id="irfsAdded"
          name="irfsAdded"
          label={`IRFS Added ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={yesNoOptions}
          error={!!errors.irfsAdded?.message}
          helperText={errors.irfsAdded?.message}
          revisionValue={revisionValues?.IrfsAdded}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          id="gdprClause"
          name="gdprClause"
          label="GDPR Clause"
          options={yesNoOptions}
          error={!!errors.gdprClause?.message}
          helperText={errors.gdprClause?.message}
          revisionValue={revisionValues?.GdprClause}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              autoHighlight
              id="contractStatusId"
              name="contractStatusId"
              label="Status Contract *"
              options={enumContractStatus}
              error={!!errors.contractStatusId?.message}
              helperText={errors.contractStatusId?.message}
              revisionValue={revisionValues?.ContractStatusId}
              disabled={!isEdit}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              autoHighlight
              id="fiscalYearId"
              name="fiscalYearId"
              label="Fiscal Year"
              options={enumFiscalYear}
              error={!!errors.fiscalYearId?.message}
              helperText={errors.fiscalYearId?.message}
              revisionValue={revisionValues?.FiscalYearId}
              disabled={!isEdit}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="contractSection"
          id="contractSection"
          label="Additional Contract Info"
          error={!!errors.contractSection?.message}
          helperText={errors.contractSection?.message}
          revisionValue={revisionValues?.ContractSection}
          disabled={!isEdit}
          control={control}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default FormGeneralContractInfo;
