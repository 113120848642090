import { styled, Grid as MuiGrid } from "@mui/material";
import { gainsboro, black } from "shared/assets/colors";

const PREFIX = "SplitButtonStyles";
export const classes = {
  button: `${PREFIX}-root`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.button}`]: {
    backgroundColor: gainsboro,
    color: black,
    ":hover": {
      backgroundColor: `${gainsboro} !important`,
    },
  },
  [`& .Mui-selected`]: {
    backgroundColor: `${gainsboro} !important`,
  },
}));
