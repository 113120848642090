import React, { ReactElement } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@mui/material";

export interface ConfirmationDialogProps extends DialogProps {
  /**
   * Text represents the title of the dialog
   */
  title: string;
  /**
   * Text represents the content of the dialog
   */
  message: string;
  /*
   * Callback for confirming dialog
   */
  onConfirm: () => void;
  /*
   * Callback for canceling dialog
   */
  onCancel: () => void;
  /**
   * Flag indicating if dialog actions are disabled
   */
  isDisableAction: boolean;
}

export const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  isDisableAction,
}: ConfirmationDialogProps): ReactElement => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent id="dialog-content" dividers>
        <Typography variant="body1" gutterBottom>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          id="button-no"
          onClick={onCancel}
          disabled={isDisableAction}
          color="secondary"
        >
          CANCEL
        </Button>
        <Button
          id="button-yes"
          onClick={onConfirm}
          disabled={isDisableAction}
          color="primary"
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
