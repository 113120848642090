import { styled, Paper as MuiPaper } from "@mui/material";
import { azureRadiance } from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "EmailSettingsStyles";
export const classes = {
  paper: `${PREFIX}-paper`,
  typographyHeaderCaption: `${PREFIX}-typographyHeaderCaption`,
  typographyCaption: `${PREFIX}-typographyCaption`,
  typographySettingsHeaderCaption: `${PREFIX}-typographySettingsHeaderCaption`,
  typographySettingsText: `${PREFIX}-typographySettingsText`,
  switchAll: `${PREFIX}-switchAll`,
  emailIcon: `${PREFIX}-emailIcon`,
};

export const Paper = styled(MuiPaper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    paddingTop: getThemeSpacing(theme, 2),
    paddingBottom: getThemeSpacing(theme, 5),
    paddingLeft: getThemeSpacing(theme, 2),
    paddingRight: getThemeSpacing(theme, 2),
    elevation: 5,
    height: "100%",
  },
  [`& .${classes.typographyHeaderCaption}`]: {
    fontSize: 20,
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
  },
  [`& .${classes.typographyCaption}`]: {
    fontSize: 15,
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
  },
  [`& .${classes.typographySettingsHeaderCaption}`]: {
    fontSize: 15,
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
  },
  [`& .${classes.typographySettingsText}`]: {
    fontSize: 12,
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
  },
  [`& .${classes.switchAll}`]: {
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
  },
  [`& .${classes.emailIcon}`]: {
    color: azureRadiance,
  },
}));
