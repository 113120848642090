import { styled, Grid as MuiGrid } from "@mui/material";
import { dodgerBlue, jordyBlue, white } from "shared/assets/colors";

const PREFIX = "ProfileStyles";
export const classes = {
  avatar: `${PREFIX}-avatar`,
  menuItem: `${PREFIX}-menuItem`,
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    color: jordyBlue,
    "&:hover": {
      color: dodgerBlue,
    },
    backgroundColor: white,
    border: "none",
  },
  [`& .${classes.menuItem}`]: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}));
