import React, { ReactElement } from "react";

import { useIdleTimer } from "react-idle-timer";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import { HomePage, ErrorPage, ProfilePage } from "shared/pages";
import {
  DebtorSyncPage,
  DocumentSchemaManagement,
  DocumentSchemaManagementV2,
  DocumentSchemaManagementV4,
  DocumentSchemaManagementV5,
  DocumentSchemaManagementV6,
  UserManagementPage,
} from "back-office/pages";
import { AgencyPages, Layout } from "master-data/pages";

import {
  APP_ACCESSMANAGER,
  URL_DEBTOR_GROUP_OVERVIEW,
  URL_IMPORT_USERS,
  URL_MANAGE_DEBTOR_GROUP,
  URL_USER_GROUP_OVERVIEW,
  URL_MAPPING_OVERVIEW,
  URL_CREATE_MAPPING,
  URL_MANAGE_USER_GROUP,
  URL_KEYWORD_SEARCH_PAGE,
  URL_MISSING_DEBTORS_PAGE,
} from "access-manager/constants";
import { APP_MASTERDATA, AGENCY_PAGES } from "master-data/constants";
import {
  FOUR_HOURS,
  PAGE_HOME,
  PAGE_ERROR,
  PAGE_PROFILE,
  SETTINGS,
} from "shared/constants";
import {
  APP_BACKOFFICE,
  URL_AUDIT_TRAIL_SYNC,
  URL_DEBTOR_SYNC,
  URL_DOCUMENT_SCHEMA_MANAGEMENT,
  URL_DOCUMENT_SCHEMA_MANAGEMENT_V2,
  URL_DOCUMENT_SCHEMA_MANAGEMENT_V4,
  URL_DOCUMENT_SCHEMA_MANAGEMENT_V5,
  URL_DOCUMENT_SCHEMA_MANAGEMENT_V6,
  URL_USER_MANAGEMENT,
} from "back-office/constants";

import { AgencyEnum } from "shared/enums";
import { AgencyType } from "master-data/types";
import { ToArray, logOutAndClearStorage } from "shared/utils";
import {
  DebtorGroupOverviewPage,
  ImportUsersPage,
  ManageDebtorGroups,
  ManageUserGroups,
  UserGroupOverviewPage,
  MappingOverviewPage,
  ManageMappings,
} from "access-manager/pages";
import {
  APP_PRODUCT,
  AGENCY_PAGES as PRODUCT_AGENCY_PAGES,
} from "product/constants";
import { ProductPages } from "product/pages";
import { AuditTrailSyncPage } from "back-office/pages/AuditTrailSync";
import { KeywordSearchPage } from "access-manager/pages/KeywordSearch";
import { MissingDebtorsPage } from "access-manager/pages/MissingDebtors";

const App = (): ReactElement => {
  const { pathname: url } = useLocation();

  const getAgencyFromUrl = () => url.substring(1).split("/")[1];

  const isOfTypeAgency = (param: string): param is AgencyType => {
    return ToArray(AgencyEnum).includes(param);
  };

  const handleOnIdle = () => {
    logOutAndClearStorage();
  };

  useIdleTimer({
    timeout: FOUR_HOURS,
    onIdle: handleOnIdle,
    debounce: 250,
  });

  const isAgencyType = isOfTypeAgency(getAgencyFromUrl());

  return (
    <Switch>
      <Route exact path={PAGE_HOME} component={HomePage} />
      <Route path={`/${PAGE_ERROR}`} component={ErrorPage} />
      <Route path={`/${PAGE_PROFILE}`}>
        <Layout>
          <Route
            path={`/${PAGE_PROFILE}/:userId/${SETTINGS}`}
            component={ProfilePage}
          />
        </Layout>
      </Route>
      <Route path={`/${APP_MASTERDATA}/${AGENCY_PAGES}`}>
        <Layout>
          <Switch>
            {isAgencyType && (
              <Route
                path={`/${APP_MASTERDATA}/${AGENCY_PAGES}`}
                component={AgencyPages}
              />
            )}
            <Route
              path="*"
              component={() => <Redirect to={`/${PAGE_ERROR}`} />}
            />
          </Switch>
        </Layout>
      </Route>
      <Route path={`/${APP_PRODUCT}/${PRODUCT_AGENCY_PAGES}`}>
        <Layout>
          <Switch>
            {isAgencyType && (
              <Route
                path={`/${APP_PRODUCT}/${PRODUCT_AGENCY_PAGES}`}
                component={ProductPages}
              />
            )}
            <Route
              path="*"
              component={() => <Redirect to={`/${PAGE_ERROR}`} />}
            />
          </Switch>
        </Layout>
      </Route>
      <Route path={`/${APP_ACCESSMANAGER}`}>
        <Layout>
          <Switch>
            <Route path={`/${URL_IMPORT_USERS}`} component={ImportUsersPage} />
            <Route
              path={`/${URL_MANAGE_USER_GROUP}/:userGroupId?`}
              component={ManageUserGroups}
            />
            <Route
              path={`/${URL_MANAGE_DEBTOR_GROUP}/:debtorGroupId?`}
              component={ManageDebtorGroups}
            />
            <Route
              path={`/${URL_USER_GROUP_OVERVIEW}`}
              component={UserGroupOverviewPage}
            />
            <Route
              path={`/${URL_DEBTOR_GROUP_OVERVIEW}`}
              component={DebtorGroupOverviewPage}
            />
            <Route path={`/${URL_CREATE_MAPPING}`} component={ManageMappings} />
            <Route
              path={`/${URL_MAPPING_OVERVIEW}`}
              component={MappingOverviewPage}
            />
            <Route
              path={`/${URL_KEYWORD_SEARCH_PAGE}`}
              component={KeywordSearchPage}
            />
            <Route
              path={`/${URL_MISSING_DEBTORS_PAGE}`}
              component={MissingDebtorsPage}
            />
            <Route
              path="*"
              component={() => <Redirect to={`/${PAGE_ERROR}`} />}
            />
          </Switch>
        </Layout>
      </Route>
      <Route path={`/${APP_BACKOFFICE}`}>
        <Layout>
          <Switch>
            <Route
              path={`/${URL_DOCUMENT_SCHEMA_MANAGEMENT}`}
              component={DocumentSchemaManagement}
            />
            <Route
              path={`/${URL_DOCUMENT_SCHEMA_MANAGEMENT_V2}`}
              component={DocumentSchemaManagementV2}
            />
            <Route
              path={`/${URL_DOCUMENT_SCHEMA_MANAGEMENT_V4}`}
              component={DocumentSchemaManagementV4}
            />
            <Route path={`/${URL_DEBTOR_SYNC}`} component={DebtorSyncPage} />
            <Route
              path={`/${URL_DOCUMENT_SCHEMA_MANAGEMENT_V5}`}
              component={DocumentSchemaManagementV5}
            />
            <Route
              path={`/${URL_DOCUMENT_SCHEMA_MANAGEMENT_V6}`}
              component={DocumentSchemaManagementV6}
            />
            <Route
              path={`/${URL_USER_MANAGEMENT}`}
              component={UserManagementPage}
            />
            <Route
              path={`/${URL_AUDIT_TRAIL_SYNC}`}
              component={AuditTrailSyncPage}
            />
            <Route
              path="*"
              component={() => <Redirect to={`/${PAGE_ERROR}`} />}
            />
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
};

export default App;
