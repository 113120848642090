import { styled, Chip as MuiChip } from "@mui/material";

import { dodgerBlue20 } from "shared/assets/colors";

const PREFIX = "AgencyFilterStyles";
export const classes = {
  button: `${PREFIX}-button`,
  active: `${PREFIX}-active`,
};

export const ChipButton = styled(MuiChip)(() => ({
  [`&.${classes.button}`]: {
    textTransform: "none",
    fontWeight: 100,
    padding: 12,
    marginTop: 5,
    marginBottom: 5,
  },
  [`&.${classes.active}`]: {
    backgroundColor: dodgerBlue20,
  },
}));
