import React, { ReactElement } from "react";

import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { classes, Tooltip } from "./styles";

export interface ClearAllProps {
  onClear: () => void;
  disabled?: boolean;
}

export const ClearAll = ({
  onClear,
  disabled,
}: ClearAllProps): ReactElement => {
  return (
    <Tooltip title="Clear" aria-label="clear">
      <span>
        <IconButton
          name="clear_filters"
          id="clear_filters"
          className={classes.button}
          onClick={onClear}
          disabled={disabled}
        >
          <Cancel color={disabled ? "disabled" : "secondary"} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ClearAll;
