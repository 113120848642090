import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, TextField, Autocomplete } from "core";

import { yesNoOptions } from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormHoursAccountabilityProps extends FormStandardFieldProps {
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormHoursAccountability = ({
  isEdit,
  control,
  errors,
  revisionValues,
  fillOption,
  setValue,
}: FormHoursAccountabilityProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const {
    enumWritingHoursPer,
    enumExplanationWrittenHours,
    enumFixedItemsFunction,
  } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("splittingHoursInScope", yesNoOptions[0].key);
      setValue(
        "additionalInformationHours",
        "Additional Information Hours - Test"
      );
      if (enumWritingHoursPer[0]) {
        setValue("writingHoursPerId", enumWritingHoursPer[0].key);
      }
      if (enumExplanationWrittenHours[0]) {
        setValue(
          "explanationWrittenHoursId",
          enumExplanationWrittenHours[0].key
        );
      }
      if (enumFixedItemsFunction[0]) {
        setValue("fixedItemsFunctionId", enumFixedItemsFunction[0].key);
      }
    }
  }, [
    fillOption,
    setValue,
    enumWritingHoursPer,
    enumExplanationWrittenHours,
    enumFixedItemsFunction,
  ]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="writingHoursPerId"
          name="writingHoursPerId"
          label="Writing hours per"
          options={enumWritingHoursPer}
          error={!!errors.writingHoursPerId?.message}
          helperText={errors.writingHoursPerId?.message}
          revisionValue={revisionValues?.WritingHoursPerId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="splittingHoursInScope"
          name="splittingHoursInScope"
          label="Splitting hours In Scope vs OOS"
          error={!!errors.splittingHoursInScope?.message}
          helperText={errors.splittingHoursInScope?.message}
          revisionValue={revisionValues?.SplittingHoursInScope}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="explanationWrittenHoursId"
          name="explanationWrittenHoursId"
          label="Explanation to written hours"
          options={enumExplanationWrittenHours}
          error={!!errors.explanationWrittenHoursId?.message}
          helperText={errors.explanationWrittenHoursId?.message}
          revisionValue={revisionValues?.ExplanationWrittenHoursId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="fixedItemsFunctionId"
          name="fixedItemsFunctionId"
          label="Fixed items per function"
          options={enumFixedItemsFunction}
          disabled={!isEdit}
          error={!!errors.fixedItemsFunctionId?.message}
          helperText={errors.fixedItemsFunctionId?.message}
          revisionValue={revisionValues?.FixedItemsFunctionId}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="additionalInformationHours"
          id="additionalInformationHours"
          label="Additional information related to hours"
          multiline
          rows={4}
          error={!!errors.additionalInformationHours?.message}
          helperText={errors.additionalInformationHours?.message}
          revisionValue={revisionValues?.AdditionalInformationHours}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormHoursAccountability;
