import { number, object, string } from "yup";
import {
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_LENGTH_255_VALIDATION_MESSAGE,
  EMPTY_STRING,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  productName: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  productReference: string()
    .trim()
    .max(255, MAX_LENGTH_255_VALIDATION_MESSAGE)
    .nullable(),
  collectiveProduct: string()
    .trim()
    .max(255, MAX_LENGTH_255_VALIDATION_MESSAGE)
    .nullable(),
  collectiveProductExist: number().when("collectiveProduct", {
    is: (value: string) =>
      (typeof value === "string" && value === EMPTY_STRING) || value === null,
    then: number().transform(nanToNull).nullable().notRequired(),
    otherwise: number()
      .transform(nanToNull)
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  }),
});
