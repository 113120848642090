/* eslint-disable no-extra-boolean-cast */

import {
  useState,
  Dispatch,
  useEffect,
  useCallback,
  SetStateAction,
} from "react";

import { useSnackbar } from "notistack";
import { useStateMachine } from "little-state-machine";

import { QueryType } from "master-data/types";

import {
  getToken,
  checkVersion,
  generateQuery,
  generateOptions,
  getTokenRedirect,
} from "shared/utils";

import {
  EMPTY_STRING,
  ERROR_MSG_500,
  serviceConfig,
  ERROR_MSG_NOT_FOUND,
  ERROR_MSG_INVALID_DATA,
  ERROR_MSG_NOT_AUTHORIZED,
  NOTIFICATION_MESSAGE_SUCCESS as NOTIFY_SUCCESS,
  NOTIFICATION_MESSAGE_DELETION_SUCCESS,
  NOTIFICATION_MESSAGE_UNARCHIVAL_SUCCESS,
  NOTIFICATION_MESSAGE_ARCHIVAL_SUCCESS,
} from "shared/constants";
import { popFromRequestQueue, pushToRequestQueue } from "store/actions";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

export enum FetchStatusEnum {
  LOADING = "loading",
  EMPTY = "empty",
  ERROR = "error",
  SUCCESS = "success",
}

export enum FetchMethodEnum {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
}

const { GET, PUT, POST, DELETE } = FetchMethodEnum;

type RequestType = "form_submit" | "form_get" | "defaultRequest";

export interface FetchOptions<T> {
  body?: T;
  skip?: boolean;
  notify?: boolean;
  method?: FetchMethodEnum;
  requestName?: RequestType;
  initialParams?: QueryType;
}

type ResponsePropType<G, P> = { [key in FetchMethodEnum]?: G | P | undefined };
interface IResponse<G, P> extends ResponsePropType<G, P> {
  get?: G;
  post?: P;
}

const getFormatedErrorMsg = (
  type: number,
  validationMessages: string[],
  statusText: string
): string[] => {
  const errors: { [key: number]: string[] } = {
    400: [ERROR_MSG_INVALID_DATA],
    401: [ERROR_MSG_NOT_AUTHORIZED],
    403: [ERROR_MSG_NOT_AUTHORIZED],
    404: [ERROR_MSG_NOT_FOUND],
    422: validationMessages,
    500: [ERROR_MSG_500],
  };

  return errors[type] || [statusText];
};

export function useFetch<G = undefined, P = undefined>(
  initialUrl: NullableString,
  options: FetchOptions<unknown>
): {
  data: IResponse<G, P> | null;
  isLoading: boolean;
  hasError: boolean;
  isSuccessful: boolean;
  errorMessage: string[];
  updateUrl: Dispatch<SetStateAction<NullableString>>;
  updateParams: Dispatch<SetStateAction<QueryType | undefined>>;
  refetch: () => void;
  numberOfRefetchs: number;
  executeFetch: (
    endpoint?: NullableString | undefined,
    opt?: FetchOptions<unknown> | undefined
  ) => Promise<void>;
  resetPostData: () => void;
  resetGetData: () => void;
} {
  const { enqueueSnackbar } = useSnackbar();
  const [url, updateUrl] = useState(initialUrl);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refetchIndex, setRefetchIndex] = useState(0);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [params, updateParams] = useState(options.initialParams);
  const [data, setData] = useState<IResponse<G, P> | null>(null);

  const { instance, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  const { actions } = useStateMachine({
    pushToRequestQueue,
    popFromRequestQueue,
  });

  const refetch = useCallback(() => {
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);
  }, []);

  const resetPostData = useCallback(() => {
    setData(
      (prevData) => ({ ...prevData, [POST]: undefined } as IResponse<G, P>)
    );
  }, []);

  const resetGetData = useCallback(() => {
    setData(
      (prevData) => ({ ...prevData, [GET]: undefined } as IResponse<G, P>)
    );
  }, []);

  const notifySuccess = useCallback(
    (_method?: FetchMethodEnum, fetchUrl?: NullableString) => {
      if (_method === DELETE) {
        enqueueSnackbar(NOTIFICATION_MESSAGE_DELETION_SUCCESS, {
          variant: "success",
        });
      } else if (_method !== GET) {
        if (fetchUrl?.includes("archive")) {
          if (fetchUrl?.includes("unarchive")) {
            enqueueSnackbar(NOTIFICATION_MESSAGE_UNARCHIVAL_SUCCESS, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(NOTIFICATION_MESSAGE_ARCHIVAL_SUCCESS, {
              variant: "success",
            });
          }
        } else {
          enqueueSnackbar(NOTIFY_SUCCESS, {
            variant: "success",
          });
        }
      }
    },
    [enqueueSnackbar]
  );

  const executeFetch = useCallback(
    async (endpoint?: NullableString, opt?: FetchOptions<unknown>) => {
      const method = opt?.method;
      const fetchUrl = endpoint ?? EMPTY_STRING;
      const body = opt?.body;
      const fetchParams = opt?.initialParams;
      const skip = opt?.skip;
      const notify = opt?.notify ?? true;
      const requestName = opt?.requestName ?? "defaultRequest";

      // TODO: Find better solution for incomplete URLs
      if (skip || fetchUrl.indexOf("undefined") >= 0) return;
      const queryString = generateQuery(fetchParams);

      setIsLoading(true);
      setIsSuccessful(false);
      setHasError(false);
      setErrorMessage([]);

      try {
        actions.pushToRequestQueue(requestName);

        if (inProgress === InteractionStatus.None) {
          await getToken().then(async (token: any) => {
            if (!!token) {
              setAccessToken(token);

              const fetchOptions = generateOptions(method ?? GET, body, token);
              const { resourceBaseAddress: base } = serviceConfig;

              if (method === GET) {
                checkVersion();
              }

              const response = await fetch(
                `${base}/${fetchUrl}${
                  queryString ? `?${queryString}` : EMPTY_STRING
                }`,
                fetchOptions
              );

              let result: any = null;

              const contentType = response.headers.get("content-type");
              if (
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                result = await response.json();
              } else {
                result = await response.blob();
              }

              if (response.ok) {
                setIsSuccessful(true);

                if (notify) notifySuccess(method, fetchUrl);

                const key =
                  (method === PUT || method === DELETE) && method != null
                    ? POST
                    : method ?? GET;
                setData((prevData) => ({ ...prevData, [key]: result }));
              } else {
                setHasError(true);

                const errorMsg = getFormatedErrorMsg(
                  response.status,
                  result.validation_messages,
                  response.statusText
                );

                setErrorMessage(errorMsg);
              }
            } else {
              // await instance
              //   .acquireTokenRedirect({
              //     scopes: [
              //       "api://8386a02c-bc6c-4fe4-b165-c2ededce5de4/access_as_user",
              //     ],
              //   })
              //   .then((response: any) => {
              //     setAccessToken(response.accessToken);
              //   });

              await getTokenRedirect().then(async (tokenRedirect: any) => {
                setAccessToken(tokenRedirect);
              });
            }
          });
        }

        /*
        This is workaround.
        Issue is that new MSAL library is working with hooks and because of that it's not 100%
        compatible with our fetch method.
        1st time invoked, accessToken will always be null thus making any request to return 401.
        After token is retreived, and setAccessToken called, accessToken will be set to correct
        value and request will work.
        */
        if (!accessToken) {
          return;
        }
      } catch (err: any) {
        setHasError(true);
        setErrorMessage(
          Array.isArray(err.message) ? err.message : [err.message]
        );
      } finally {
        setIsLoading(false);
        actions.popFromRequestQueue(requestName);
      }
    },
    [notifySuccess, actions, accessToken, inProgress]
  );

  useEffect(() => {
    executeFetch(url, {
      body: options.body,
      method: options.method,
      notify: options.notify,
      requestName: options.requestName,
      skip: options.skip,
      initialParams: params,
    });
  }, [
    url,
    params,
    refetchIndex,
    executeFetch,
    options.body,
    options.skip,
    options.method,
    options.notify,
    options.requestName,
  ]);

  useEffect(() => {
    if (!hasError && !!errorMessage) {
      return;
    }

    errorMessage.forEach((msg) => {
      enqueueSnackbar(msg, {
        variant: "error",
      });
    });
  }, [enqueueSnackbar, errorMessage, hasError]);

  return {
    data,
    refetch,
    hasError,
    updateUrl,
    isLoading,
    errorMessage,
    updateParams,
    executeFetch,
    isSuccessful,
    resetGetData,
    resetPostData,
    numberOfRefetchs: refetchIndex,
  };
}

export default useFetch;
