import React, { ReactElement } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableRowEmpty } from "core";
import { isEmpty } from "lodash";
import { LogItem } from "back-office/pages/AuditTrailSync/types";

interface AuditTrailSyncTableProps {
  items: LogItem[];
}

export const AuditTrailSyncTable = ({
  items,
}: AuditTrailSyncTableProps): ReactElement => {
  return (
    <Table size="small" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell align="center">Date & time</TableCell>
          <TableCell align="center">Execution</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(items) ? (
          <TableRowEmpty />
        ) : (
          items.map((item: LogItem) => (
            <TableRow key={`row_${item.id}`}>
              <TableCell align="center">{item.logDateTimeStr}</TableCell>
              <TableCell align="center">
                {item.isSuccessful ? "Successful" : "Unsuccessful"}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default AuditTrailSyncTable;
