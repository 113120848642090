import { styled } from "@mui/material";

const PREFIX = "ErorPageStyles";
export const classes = {
  body: `${PREFIX}-body`,
  top: `${PREFIX}-top`,
  container: `${PREFIX}-container`,
  ghost: `${PREFIX}-ghost`,
  ghostCopy: `${PREFIX}-ghostCopy`,
  face: `${PREFIX}-face`,
  eyesBase: `${PREFIX}-eyesBase`,
  eye: `${PREFIX}-eye`,
  eyeRight: `${PREFIX}-eyeRight`,
  mouth: `${PREFIX}-mouth`,
  base: `${PREFIX}-base`,
  one: `${PREFIX}-one`,
  two: `${PREFIX}-two`,
  three: `${PREFIX}-three`,
  four: `${PREFIX}-four`,
  shadow: `${PREFIX}-shadow`,
  bottom: `${PREFIX}-bottom`,
  h1: `${PREFIX}-h1`,
  h3: `${PREFIX}-h3`,
  p: `${PREFIX}-p`,
};

export const Div = styled("div")(() => ({
  [`&.${classes.body}`]: {
    background: "#D3DEEA",
  },
  [`&.${classes.top}`]: {
    marginTop: 30,
  },
  [`&.${classes.container}`]: {
    margin: "0 auto",
    position: "relative",
    width: 250,
    height: 250,
    marginTop: -40,
  },
  [`&.${classes.ghost}`]: {
    margin: "0 auto",
    width: "50%",
    height: "53%",
    left: "25%",
    top: "10%",
    position: "absolute",
    borderRadius: "50% 50% 0 0",
    background: "#EDEDED",
    border: "1px solid #BFC0C0",
    borderBottom: "none",
    animation: "$float 2s ease-out infinite",
  },
  [`&.${classes.ghostCopy}`]: {
    width: "50%",
    height: "53%",
    left: "25%",
    top: "10%",
    position: "absolute",
    borderRadius: "50% 50% 0 0",
    background: "#EDEDED",
    border: "1px solid #BFC0C0",
    borderBottom: "none",
    animation: "$float 2s ease-out infinite",
    zIndex: 0,
  },
  [`&.${classes.face}`]: {
    position: "absolute",
    width: "100%",
    height: "60%",
    top: "20%",
  },
  [`&.${classes.eyesBase}`]: {
    position: "absolute",
    background: "#585959",
    width: 13,
    height: 13,
    borderRadius: "50%",
    top: "40%",
  },
  [`&.${classes.eye}`]: {
    left: "25%",
  },
  [`&.${classes.eyeRight}`]: {
    right: "25%",
  },
  [`&.${classes.mouth}`]: {
    position: "absolute",
    top: "50%",
    left: "45%",
    width: 10,
    height: 10,
    border: "3px solid",
    borderRadius: "50%",
    borderColor: "transparent #585959 #585959 transparent",
    transform: "rotate(45deg)",
  },
  [`&.${classes.base}`]: {
    position: "absolute",
    background: "#EDEDED",
    top: "85%",
    width: "25%",
    height: "23%",
    border: "1px solid #BFC0C0",
    zIndex: 0,
  },
  [`&.${classes.one}`]: {
    borderRadius: "0 0 100% 30%",
    left: -1,
  },
  [`&.${classes.two}`]: {
    left: "23%",
    borderRadius: "0 0 50% 50%",
  },
  [`&.${classes.three}`]: {
    left: "50%",
    borderRadius: "0 0 50% 50%",
  },
  [`&.${classes.four}`]: {
    left: "74.5%",
    borderRadius: "0 0 30% 100%",
  },
  [`&.${classes.shadow}`]: {
    position: "absolute",
    width: "30%",
    height: "7%",
    background: "#BFC0C0",
    left: "35%",
    top: "80%",
    borderRadius: "50%",
    animation: "$scale 2s infinite",
  },
  [`@keyframes scale`]: {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.1)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  [`@keyframes float`]: {
    "50%": {
      transform: "translateY(15px)",
    },
  },
  [`&.${classes.bottom}`]: {
    marginTop: 10,
  },
  [`& .${classes.h1}`]: {
    fontFamily: "'Abril Fatface', serif",
    color: "#EDEDED",
    textAlign: "center",
    fontSize: "9em",
    margin: 0,
    textShadow: "-1px 0 $gray, 0 1px $gray, 1px 0 $gray, 0 -1px $gray",
  },
  [`& .${classes.h3}`]: {
    fontFamily: "'Lato', sans-serif",
    fontSize: "2em",
    textTransform: "uppercase",
    textAlign: "center",
    color: "#BFC0C0",
    marginTop: -20,
    fontWeight: 900,
  },
  [`& .${classes.p}`]: {
    textAlign: "center",
    fontFamily: "'Lato', sans-serif",
    color: "#585959",
    fontSize: ".6em",
    marginTop: -20,
    textTransform: "uppercase",
  },
}));
