import { AnyObjectSchema } from "yup";

import { FormStandardAccordionType } from "master-data/types";

import { validationSchema as generalClientInfoSchema } from "./GeneralClientInfo/config";
import { validationSchema as additionalClientInfoSchema } from "./AdditionalClientInfo/config";
import { validationSchema as creditInsuranceSchema } from "./CreditInsurance/config";
import { validationSchema as clientTeamSchema } from "./ClientTeam/config";
import { validationSchema as generalContractInfoSchema } from "./GeneralContractInfo/config";
import { validationSchema as financialAvbsSchema } from "./FinancialAvbs/config";
import { validationSchema as financialEpdsSchema } from "./FinancialEpds/config";
import { validationSchema as financialUnbilledMediaSchema } from "./FinancialUnbilledMedia/config";
import { validationSchema as inventoryMediaSchema } from "./InventoryMedia/config";
import { validationSchema as allowableDeducationsRebatesSchema } from "./AllowableDeducationsRebates/config";
import { validationSchema as auditSchema } from "./Audit/config";
import { validationSchema as interestSchema } from "./Interest/config";
import { validationSchema as admanagementSchema } from "./Admanagement/config";
import { validationSchema as digitalAccountabilityBrandSafetySchema } from "./DigitalAccountabilityBrandSafety/config";
import { validationSchema as evouchingSchema } from "./Evouching/config";
import { validationSchema as scopeOfServicesSchema } from "./ScopeOfServices/config";
import { validationSchema as outOfScopeServicesSchema } from "./OutOfScopeServices/config";
import { validationSchema as invoicingAddressMethodSchema } from "./InvoicingAddressMethod/config";
import { validationSchema as invoicingRequirementsSchema } from "./InvoicingRequirements/config";
import { validationSchema as additionalInvoicingRequirementsSchema } from "./AdditionalInvoicingRequirements/config";
import { validationSchema as hoursAccountabilitySchema } from "./HoursAccountability/config";
import { validationSchema as commercialAgreementsSchema } from "./CommercialAgreements/config";
import { validationSchema as revenueRecognitionSchema } from "./RevenueRecogniton/config";
import { validationSchema as supplierAgreementsSchema } from "./SupplierAgreements/config";

export const schemas: { [key in FormStandardAccordionType]: AnyObjectSchema } =
  {
    generalClientInfo: generalClientInfoSchema,
    additionalClientInfo: additionalClientInfoSchema,
    creditInsurance: creditInsuranceSchema,
    clientTeam: clientTeamSchema,
    generalContractInfo: generalContractInfoSchema,
    financialAvbs: financialAvbsSchema,
    financialEpds: financialEpdsSchema,
    financialUnbilledMedia: financialUnbilledMediaSchema,
    inventoryMedia: inventoryMediaSchema,
    allowableDeducationsRebates: allowableDeducationsRebatesSchema,
    audit: auditSchema,
    interest: interestSchema,
    admanagement: admanagementSchema,
    digitalAccountabilityBrandSafety: digitalAccountabilityBrandSafetySchema,
    evouching: evouchingSchema,
    scopeOfServices: scopeOfServicesSchema,
    outOfScopeServices: outOfScopeServicesSchema,
    invoicingAddressMethod: invoicingAddressMethodSchema,
    invoicingRequirements: invoicingRequirementsSchema,
    // invoicingRequirementsV3: invoicingRequirementsSchemaV3,  // Left as a template for future work on old forms
    additionalInvoicingRequirements: additionalInvoicingRequirementsSchema,
    hoursAccountability: hoursAccountabilitySchema,
    commercialAgreements: commercialAgreementsSchema,
    revenueRecognition: revenueRecognitionSchema,
    supplierAgreements: supplierAgreementsSchema,
  };

export const mapToFormType = (type: string): string => {
  const forms: { [key: string]: string } = {
    generalClientInfo: "GeneralClientInfoForm",
    additionalClientInfo: "AdditionalClientInfoForm",
    creditInsurance: "CreditInsuranceForm",
    clientContacts: "ClientContactsForm",
    relatedContacts: "RelatedContactsForm",
    clientTeam: "ClientTeamForm",
    generalContractInfo: "GeneralContractInfoForm",
    financialAvbs: "FinancialAvbForm",
    financialEpds: "FinancialEpdForm",
    financialUnbilledMedia: "FinancialUnbilledMediaForm",
    financialClientIncentive: "FinancialClientIncentiveForm",
    inventoryMedia: "InventoryMediaForm",
    allowableDeducationsRebates: "AllowableDeductionsRebatesForm",
    audit: "AuditForm",
    interest: "InterestForm",
    admanagement: "AdmanagementForm",
    digitalAccountabilityBrandSafety: "DigitalAccountabilityBrandSafetyForm",
    evouching: "EvouchingForm",
    hours: "HoursForm",
    remunerationContractLine: "RemunerationContractLineForm",
    scopeOfServices: "ScopeOfServicesForm",
    outOfScopeServices: "OutOfScopeServicesForm",
    invoicingAddressMethod: "InvoicingAddressMethodForm",
    invoicingRequirements: "InvoicingRequirementsForm",
    // invoicingRequirementsV3: "InvoicingRequirementsForm",  // Left as a template for future work on old forms
    additionalInvoicingRequirements: "AdditionalInvoicingRequirementsForm",
    hoursAccountability: "HoursAccountabilityForm",
    commercialAgreements: "CommercialAgreementsForm",
    revenueRecognition: "RevenueRecognitionForm",
    supplierAgreements: "SupplierAgreementsForm",
    soxCompliantClientApproval: "SoxForm",
  };

  return forms[type];
};
