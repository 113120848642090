import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, CheckField, Autocomplete } from "core";

import { yesNoOptions } from "shared/constants";
import { CLAIM_EDIT_CREATOR_FIELD } from "master-data/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormInvoicingRequirementsProps extends FormStandardFieldProps {
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormInvoicingRequirements = ({
  isEdit,
  control,
  errors,
  revisionValues,
  fillOption,
  setValue,
}: FormInvoicingRequirementsProps): ReactElement => {
  const {
    state: { profile, cacheData, clientDocumentData },
  } = useStateMachine();

  const { claims } = profile;
  const { isDocumentEditable } = clientDocumentData;
  const { enumInvoicingVat, enumInvoicingLayout } = cacheData;
  const isCreatorFieldDisabled = !(
    claims.includes(CLAIM_EDIT_CREATOR_FIELD) && isDocumentEditable
  );

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("poMandatory", yesNoOptions[0].key);
      setValue("onlySendInvoicesPo", yesNoOptions[0].key);
      if (enumInvoicingLayout[0]) {
        setValue("invoicingLayoutId", enumInvoicingLayout[0].key);
      }
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("invoicePerInsertion", true);
        setValue("invoicePerCampaign", true);
        setValue("invoicePerInsertions", true);
        setValue("invoicePerProduct", true);
        setValue("invoicePerClient", true);
        setValue("invoicePerCollectiveProduct", true);
        setValue("invoicePerMediaType", true);
        setValue("invoicePerReferenceNumber", true);
        setValue("invoicePerMedia", true);
        setValue("invoicePerPlan", true);
        setValue("invoicePerSurchargeCode", true);
        if (enumInvoicingVat[0]) {
          setValue("invoicingVatId", enumInvoicingVat[0].key);
        }
      }
    }
  }, [fillOption, setValue, enumInvoicingLayout, enumInvoicingVat]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Select
          id="poMandatory"
          name="poMandatory"
          label="PO Mandatory *"
          error={!!errors.poMandatory?.message}
          helperText={errors.poMandatory?.message}
          revisionValue={revisionValues?.PoMandatory}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          id="onlySendInvoicesPo"
          name="onlySendInvoicesPo"
          label="Only Send invoices with PO Number *"
          error={!!errors.onlySendInvoicesPo?.message}
          helperText={errors.onlySendInvoicesPo?.message}
          revisionValue={revisionValues?.OnlySendInvoicesPo}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          id="invoicingVatId"
          name="invoicingVatId"
          label="VAT on Invoice"
          error={!!errors.invoicingVatId?.message}
          helperText={errors.invoicingVatId?.message}
          revisionValue={revisionValues?.InvoicingVatId}
          disabled={!isEdit}
          control={control}
          options={enumInvoicingVat}
        />
      </Grid>
      <Grid item xs={12}>
        <div>Invoicing Level: </div>
      </Grid>

      {/* 1. Row */}
      <Grid item xs={4}>
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerInsertion"
          label="Invoice per order"
          revisionValue={revisionValues?.InvoicePerInsertion}
        />
      </Grid>
      <Grid item xs={4}>
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerCampaign"
          label="Invoice per campaign"
          revisionValue={revisionValues?.InvoicePerCampaign}
        />
      </Grid>
      <Grid item xs={4}>
        {/* GOM-5441: Moved FROM first row */}
        <CheckField
          control={control}
          disabled={!isEdit}
          name="invoicePerCollectiveProduct"
          label="Invoice per Collective Product"
          revisionValue={revisionValues?.InvoicePerCollectiveProduct}
        />
        {/* GOM-5441: Property only hidden because of late change request
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerInsertions"
          label="Invoice per Insertion"
          revisionValue={revisionValues?.InvoicePerInsertions}
        /> */}
      </Grid>

      {/* 2. Row */}
      <Grid item xs={4}>
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerProduct"
          label="Invoice per product"
          revisionValue={revisionValues?.InvoicePerProduct}
        />
      </Grid>
      <Grid item xs={4}>
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerClient"
          label="Invoice per client"
          revisionValue={revisionValues?.InvoicePerClient}
        />
      </Grid>
      <Grid item xs={4}>
        {/* GOM-5441: Moved TO first row
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerCollectiveProduct"
          label="Invoice per Collective Product"
          revisionValue={revisionValues?.InvoicePerCollectiveProduct}
        /> */}
      </Grid>

      {/* 3. Row */}
      <Grid item xs={4}>
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerMediaType"
          label="Invoice per media type"
          revisionValue={revisionValues?.InvoicePerMediaType}
        />
      </Grid>
      <Grid item xs={4}>
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerReferenceNumber"
          label="Invoice per reference number"
          revisionValue={revisionValues?.InvoicePerReferenceNumber}
        />
      </Grid>
      <Grid item xs={4}>
        {/* GOM-5441: Property only hidden because of late change request
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerMedia"
          label="Invoice per Media"
          revisionValue={revisionValues?.InvoicePerMedia}
        /> */}
      </Grid>

      {/* 4. Row */}
      <Grid item xs={8}>
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerPlan"
          label="Invoice per plan"
          revisionValue={revisionValues?.InvoicePerPlan}
        />
      </Grid>
      <Grid item xs={4}>
        {/* GOM-5441: Property only hidden because of late change request
        <CheckField
          control={control}
          disabled={!isEdit || isCreatorFieldDisabled}
          name="invoicePerSurchargeCode"
          label="Invoice per Surcharge Code"
          revisionValue={revisionValues?.InvoicePerSurchargeCode}
        /> */}
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          autoHighlight
          id="invoicingLayoutId"
          name="invoicingLayoutId"
          label="Invoice Lay-out *"
          options={enumInvoicingLayout}
          error={!!errors.invoicingLayoutId?.message}
          helperText={errors.invoicingLayoutId?.message}
          revisionValue={revisionValues?.InvoicingLayoutId}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormInvoicingRequirements;
