import {
  styled,
  Typography as MuiTypography,
  Grid as MuiGrid,
} from "@mui/material";

const PREFIX = "DialogStyle";

export const classes = {
  warning: `${PREFIX}-warning`,
  description: `${PREFIX}-description`,
  button: `${PREFIX}-button`,
};

export const Typography = styled(MuiTypography)(() => ({
  [`&.${classes.warning}`]: {
    fontSize: 12,
    mt: 1,
  },
}));

export const Grid = styled(MuiGrid)(() => ({
  [`&.${classes.button}`]: {
    justifyContent: "flex-end",
  },
  [`&.${classes.description}`]: {
    marginTop: "16px",
    marginLeft: "16px",
  },
}));
