import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import { setOpenImportSelectedMissingDebtorsDialog } from "store/actions";

import { classes, Grid } from "./styles";

export const MissingDebtorsActions = (): ReactElement => {
  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setOpenImportSelectedMissingDebtorsDialog,
  });

  const { isSelectedMissingDebtors } = appState;

  const handleOpenImportSelectedDialog = () => {
    actions.setOpenImportSelectedMissingDebtorsDialog(true);
  };

  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item>
        <Button
          id="button-import"
          color="primary"
          variant="contained"
          className={classes.importButton}
          classes={{ disabled: classes.disabledButton }}
          disabled={!isSelectedMissingDebtors}
          onClick={handleOpenImportSelectedDialog}
        >
          Import Selected
        </Button>
      </Grid>
    </Grid>
  );
};

export default MissingDebtorsActions;
