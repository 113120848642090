import { StandardFormType } from "../../types";

import { IFormsInput } from "./types";

import { defaultValues as userGroupDefaultValues } from "./UserGroup/constants";
import { defaultValues as debtorGroupDefaultValues } from "./DebtorGroup/constants";

export const defaults: {
  [key in StandardFormType]: IFormsInput[key];
} = {
  userGroup: userGroupDefaultValues,
  debtorGroup: debtorGroupDefaultValues,
};
