import React, { ReactElement } from "react";

import { Control, Controller } from "react-hook-form";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import {
  DEFAULT_DATE_FORMAT,
  EMPTY_STRING,
  REVISION_INIT_VALUE,
} from "shared/constants";

import { History as HistoryIcon } from "@mui/icons-material";
import { InfoPopper } from "../InfoPopper";

export interface DateFieldProps
  extends Omit<
    DatePickerProps<Date | NullableString, Date>,
    "value" | "onChange" | "renderInput"
  > {
  /**
   * control of useForm from RHF.
   */
  control: Control;
  /**
   * Name attribute of the input element.
   */
  name: string;
  /**
   * If true, the label will be displayed in an error state.
   */
  error?: boolean;
  /**
   * The helper text content.
   */
  helperText?: string;
  /**
   * The default input element value. Use when the component is not controlled.
   */
  defaultValue?: Date | NullableString;
  /**
   * Previous value of field
   */
  revisionValue?: Date | NullableString;
  /**
   * If `true`, the label is displayed as required and the `input` element is required.
   * @default false
   */
  required?: boolean;
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean;

  keyboardButtonProps?: any;
}

export const DateField = ({
  control,
  error,
  helperText,
  defaultValue,
  revisionValue,
  required,
  inputFormat = DEFAULT_DATE_FORMAT,
  fullWidth,
  keyboardButtonProps,
  ...otherProps
}: DateFieldProps): ReactElement => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  let revisionVal: string | undefined;

  if (revisionValue === null) {
    revisionVal = REVISION_INIT_VALUE;
  } else if (revisionValue) {
    revisionVal =
      typeof revisionValue === "string"
        ? revisionValue
            .replace("Z", EMPTY_STRING)
            .split("-")
            .reverse()
            .join("-")
        : revisionValue.toISOString();
  }

  if (revisionValue === null) {
    revisionVal = REVISION_INIT_VALUE;
  } else if (revisionValue) {
    revisionVal =
      typeof revisionValue === "string"
        ? revisionValue.replace("Z", "").split("-").reverse().join("-")
        : revisionValue.toISOString();
  }

  return (
    <>
      <Controller
        name={otherProps.name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...otherProps}
              mask="__-__-____"
              autoFocus={!otherProps.disabled && otherProps.autoFocus}
              inputFormat={inputFormat}
              value={field.value}
              InputAdornmentProps={{
                position: "start",
                ...keyboardButtonProps,
              }}
              // clearable={clearable}
              onChange={(selected) => {
                selected?.setHours(8, 0, 0, 0);
                field.onChange(selected);
              }}
              InputProps={{
                ...otherProps.InputProps,
                placeholder: EMPTY_STRING,
                endAdornment: revisionVal ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle change value visibility"
                      onClick={handleClick}
                      ref={anchorRef}
                    >
                      <HistoryIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
              }}
              renderInput={({ inputProps, ...props }) => (
                <TextField
                  {...props}
                  required={required}
                  name={field.name}
                  helperText={helperText}
                  onBlur={field.onBlur}
                  error={error}
                  fullWidth={fullWidth}
                  variant="outlined"
                  inputProps={{
                    ...inputProps,
                    placeholder: EMPTY_STRING,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
      {revisionVal && (
        <InfoPopper
          text={revisionVal}
          onClickAway={handleClickAway}
          open={open}
          anchorEl={anchorRef.current}
        />
      )}
    </>
  );
};

DateField.defaultProps = {
  error: false,
  defaultValue: null,
  revisionValue: undefined,
  helperText: EMPTY_STRING,
  inputFormat: DEFAULT_DATE_FORMAT,
  required: false,
};

export default DateField;
