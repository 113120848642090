import React, { ReactElement } from "react";

import {
  Box,
  Typography,
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from "@mui/material";

export interface CircularProgressProps extends MuiCircularProgressProps {
  /**
   * Text inside circle
   * @default null
   */
  label?: string;
}

export const CircularProgress = ({
  label,
  ...otherProps
}: CircularProgressProps): ReactElement => {
  return (
    <Box position="relative" display="inline-flex">
      <MuiCircularProgress {...otherProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgress.defaultProps = {
  label: null,
};

export default CircularProgress;
