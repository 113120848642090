import React, { FC } from "react";

// TODO: Investigate WHY can't import this way (imports are undefined)
// import {
//   FormEntries,
//   FormStandard,
//   MdtChecks,
//   LegalDocumentsChecks,
// } from "master-data/components";

import {
  MdtChecks,
  LegalDocumentsChecks,
  ClientDocument,
} from "master-data/components/FormOther";
import { FormEntries } from "master-data/components/FormEntries";
import { FormStandard } from "master-data/components/FormStandard";
import { AccordionType } from "master-data/types";

const allForms: {
  [key in AccordionType]: FC<any>;
} = {
  clientDocument: ClientDocument,
  generalClientInfo: FormStandard,
  additionalClientInfo: FormStandard,
  creditInsurance: FormStandard,
  clientContacts: FormEntries,
  relatedContacts: FormEntries,
  clientTeam: FormStandard,
  supplierAgreements: FormStandard,

  financialAvbs: FormStandard,
  financialEpds: FormStandard,
  generalContractInfo: FormStandard,
  financialUnbilledMedia: FormStandard,
  financialClientIncentive: FormEntries,
  inventoryMedia: FormStandard,
  allowableDeducationsRebates: FormStandard,
  audit: FormStandard,
  interest: FormStandard,
  admanagement: FormStandard,
  digitalAccountabilityBrandSafety: FormStandard,
  evouching: FormStandard,
  hours: FormEntries,
  hoursV5: FormEntries,
  remunerationContractLine: FormEntries,
  revenueRecognition: FormStandard,
  scopeOfServices: FormStandard,
  outOfScopeServices: FormStandard,

  invoicingAddressMethod: FormStandard,
  invoicingRequirements: FormStandard,
  // invoicingRequirementsV3: FormStandard,  // Left as a template for future work on old forms
  additionalInvoicingRequirements: FormStandard,

  hoursAccountability: FormStandard,
  commercialAgreements: FormStandard,
  soxCompliantClientApproval: FormEntries,

  mdtChecks: MdtChecks,
  legalDocumentsChecks: LegalDocumentsChecks,
};

const handler = {
  get(target: any, name: string) {
    return Object.prototype.hasOwnProperty.call(target, name)
      ? target[name]
      : () => <></>;
  },
};

export const forms = new Proxy<{
  [key in AccordionType]: FC<any>;
}>(allForms, handler);

export const formsWithVersions = [
  // "invoicingRequirementsV3",  // Left as a template for future work on old forms
  "hoursV5",
];
