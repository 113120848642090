import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";

import { Select, CheckField, TextField } from "core";

import { yesNoOptions } from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormCommercialAgreementsProps extends FormStandardFieldProps {
  /**
   * watch
   */
  watch: any;
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormCommercialAgreements = ({
  watch,
  setValue,
  isEdit,
  control,
  errors,
  revisionValues,
  fillOption,
}: FormCommercialAgreementsProps): ReactElement => {
  const liquidBudgetWatch = watch("liquidBudget");
  const isExceptEnabled = !!liquidBudgetWatch;

  // #region Effects
  useEffect(() => {
    if (!isExceptEnabled) {
      setValue("except", null, { shouldValidate: true });
    }
  }, [setValue, isExceptEnabled]);

  useEffect(() => {
    if (fillOption) {
      setValue("hours", yesNoOptions[0].key);
      setValue("media", yesNoOptions[0].key);
      setValue("liquidBudget", true);
      setValue("except", "Except - Test");
    }
  }, [fillOption, setValue]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          id="hours"
          name="hours"
          label="Charge Change Costs: Hours"
          error={!!errors.hours?.message}
          helperText={errors.hours?.message}
          revisionValue={revisionValues?.Hours}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="media"
          name="media"
          label="Charge Change Costs: Media"
          error={!!errors.media?.message}
          helperText={errors.media?.message}
          revisionValue={revisionValues?.Media}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <CheckField
          control={control}
          disabled={!isEdit}
          id="liquidBudget"
          name="liquidBudget"
          label="Liquid Budget"
          revisionValue={revisionValues?.LiquidBudget}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          control={control}
          disabled={!isEdit || !isExceptEnabled}
          id="except"
          name="except"
          label="Except"
          error={!!errors.except?.message}
          helperText={errors.except?.message}
          revisionValue={revisionValues?.Except}
          multiline
          rows={3}
        />
      </Grid>
    </Grid>
  );
};

export default FormCommercialAgreements;
