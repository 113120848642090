import React, { ReactElement } from "react";
import { Tooltip, Chip } from "@mui/material";
import clsx from "clsx";
import { classes, Grid } from "./styles";
import { extensionsList, Extension } from "./config";

export interface ManageUserGroupsExtensionSearchProps {
  onExtensionClick: (selectedId: string) => void;
  selected: string;
  isDisabled?: boolean;
}

export const ManageUserGroupsExtensionSearch = ({
  selected,
  onExtensionClick,
  isDisabled,
}: ManageUserGroupsExtensionSearchProps): ReactElement => {
  const isSelected = (id: number) => {
    const selectedIds = selected ? selected.split("_") : [];
    return selectedIds.includes(id.toString());
  };

  return (
    <>
      <Grid container justifyContent="flex-start" spacing={2}>
        {extensionsList?.map(({ extensionId, description }: Extension) => (
          <Grid key={extensionId} item>
            <Tooltip title={description}>
              <Chip
                id={`button-extension-${extensionId}`}
                className={clsx(classes.button, {
                  [classes.active]: isSelected(extensionId),
                })}
                label={description}
                onClick={() => onExtensionClick(extensionId.toString())}
                disabled={isDisabled}
              />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ManageUserGroupsExtensionSearch;
