import React, { MouseEvent, ReactElement } from "react";

import { AccordionType } from "master-data/types";
import { useRequestSniffer } from "shared/hooks";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Menu, MenuItem } from "@mui/material";
import { FillFormOptionType } from "master-data/enums";
import { classes, Button } from "./styles";

export interface AccordionActionsProps {
  /**
   * Type of accordion
   * @type AccordionType
   */
  type: AccordionType | string;
  /**
   * Flag indicating if accordion is in edit mode
   */
  isEdit: boolean;
  /**
   * Flag indicating if component is called from user or debtor component
   */
  showDeleteButton?: boolean;
  /**
   * Flag indicating if Fill button should be displayed
   */
  showFillButton?: boolean;
  /**
   * Flag indicating if FillMandatory(& Save) option should be displayed
   */
  showFillMandatoryOption?: boolean;
  /**
   * Flag indicating if delete button is enabled or disabled
   */
  isDeleteButtonDisabled?: boolean;
  /**
   * Callback fired when Cancel button is clicked
   */
  onCancel?: () => void;
  /**
   * Callback fired when Delete button is clicked
   */
  onDelete?: () => void;
  /**
   * Callback fired when Fill menu option is clicked
   */
  onFill?: (fillOption: FillFormOptionType) => void;
}

export const AccordionActions = ({
  type,
  isEdit,
  isDeleteButtonDisabled,
  showDeleteButton,
  showFillButton,
  showFillMandatoryOption,
  onCancel,
  onDelete,
  onFill,
}: AccordionActionsProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isRequestPending } = useRequestSniffer();

  const handlePropagation = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
  };

  const handleDelete = () => {
    if (typeof onDelete === "function") {
      onDelete();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFillAll = () => {
    if (typeof onFill === "function") {
      onFill(FillFormOptionType.fillAll);
    }
    handleClose();
  };

  const handleFillAllAndSave = () => {
    if (typeof onFill === "function") {
      onFill(FillFormOptionType.fillAllAndSave);
    }
    handleClose();
  };

  const handleFillMandatory = () => {
    if (typeof onFill === "function") {
      onFill(FillFormOptionType.fillMandatory);
    }
    handleClose();
  };

  const handleFillMandatoryAndSave = () => {
    if (typeof onFill === "function") {
      onFill(FillFormOptionType.fillMandatoryAndSave);
    }
    handleClose();
  };

  return isEdit ? (
    <>
      {showFillButton && process.env.REACT_APP_SHOW_FILL_BUTTON === "true" && (
        <>
          <Button
            id="basic-button"
            form={`${type}Form`}
            size="small"
            onClick={handleClick}
            // className={classes.cancelButton}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            Fill
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleFillAll}>Fill All</MenuItem>
            <MenuItem>
              <Button
                onClick={handleFillAllAndSave}
                type="submit"
                form={`${type}Form`}
                className={classes.fillButton}
              >
                Fill All & Save
              </Button>
            </MenuItem>
            {showFillMandatoryOption && (
              <MenuItem onClick={handleFillMandatory}>Fill Mandatory</MenuItem>
            )}
            {showFillMandatoryOption && (
              <MenuItem onClick={handleFillMandatoryAndSave}>
                <Button
                  onClick={handleFillAllAndSave}
                  type="submit"
                  form={`${type}Form`}
                  className={classes.fillButton}
                >
                  Fill Mandatory & Save
                </Button>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
      <Button
        id={`${type}Form-reset`}
        form={`${type}Form`}
        size="small"
        type="reset"
        onClick={handleCancel}
        className={classes.cancelButton}
      >
        Cancel
      </Button>
      <Button
        id={`${type}Form-submit`}
        form={`${type}Form`}
        disabled={isRequestPending}
        size="small"
        color="primary"
        type="submit"
        onClick={handlePropagation}
      >
        {/* TODO: when fully implemented request functionality uncomment */}
        {/* {isRequestPending ? "Saving..." : "Save"} */}
        Save
      </Button>
    </>
  ) : (
    <>
      {showDeleteButton && (
        <Button
          id={`${type}Form-delete`}
          form={`${type}Form`}
          disabled={isDeleteButtonDisabled}
          size="small"
          className={classes.deleteButton}
          type="reset"
          onClick={handleDelete}
        >
          <DeleteForeverIcon />
        </Button>
      )}
    </>
  );
};

export default AccordionActions;
