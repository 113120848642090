import {
  groupM,
  kinetic,
  essencemediacom,
  mindshare,
  wavemaker,
  blossom,
  greenhouseone,
  greenhousetech,
  choreographcreate,
} from "shared/assets/images";

export const images: {
  [key: string]: typeof import("*.png");
} = {
  groupm: groupM,
  kinetic,
  essencemediacom,
  mindshare,
  wavemaker,
  blossom,
  greenhouseone,
  greenhousetech,
  choreographcreate,
};
