import React, { useEffect, ReactElement } from "react";

import { object, string } from "yup";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from "@hookform/devtools";
import { useForm, Controller, Control, FieldError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStateMachine } from "little-state-machine";
import { Prompt, useHistory, useParams, useRouteMatch } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
  AutocompleteRenderInputParams,
  Alert,
  AlertTitle,
} from "@mui/material";

import { Select, OptionFetcher } from "core";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import {
  KEY_ENTER,
  EMPTY_STRING,
  UNSAVED_DATA_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";
import { GET_CONCERNS, CREATE_CLIENT_DOCUMENT } from "master-data/constants";
import { generateUrl } from "shared/utils";
import { IDocumentFormInput } from "master-data/types";
import { AgencyEnum, ClientDocumentEnum } from "master-data/enums";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import {
  setIsContract,
  setIsSupplierClients,
  setValidationStatus,
} from "store/actions";

import { IResponseGet, IResponsePost } from "./types";
import {
  filter,
  FORM_ID,
  defaultValues,
  SUPPLIER_CLIENT_WARNING_MESSAGE,
} from "./config";

// #region "Validation"
const schema = object().shape({
  documentName: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  documentTypeId: string().trim().required(MANDATORY_FILED_VALIDATION_MESSAGE),
  concern: object().required(MANDATORY_FILED_VALIDATION_MESSAGE).nullable(),
});
// #endregion

const { POST } = FetchMethodEnum;

export const ClientDocumentDialog = (): ReactElement => {
  const { data, isLoading, executeFetch } = useFetch<
    IResponseGet,
    IResponsePost
  >(null, {
    skip: true,
  });
  const {
    state: { cacheData },
    actions,
  } = useStateMachine({
    setIsContract,
    setIsSupplierClients,
    setValidationStatus,
  });

  const { goBack, replace } = useHistory();
  const { url } = useRouteMatch();
  const { agencyName, documentId } = useParams<ParamTypes>();
  // const inEditMode = url.includes(EDIT_MODE);

  const agencyId = AgencyEnum[agencyName];
  const { get: getData, post: postData } = data ?? {};
  const { documentTypeId } = getData ?? {};

  const isSupplierClientsType =
    documentTypeId === ClientDocumentEnum.supplierClients;

  const CREATE_NEW_CLIENT_URL = generateUrl(CREATE_CLIENT_DOCUMENT, {
    agencyId,
  });

  const GET_CONCERNS_URL = generateUrl(GET_CONCERNS, { agencyId });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitSuccessful, isDirty, errors },
    watch,
  } = useForm<IDocumentFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const documentTypeIdWatch = watch("documentTypeId");
  const showDocumentTypeWarning =
    typeof documentTypeIdWatch === "number" && documentTypeIdWatch === 3;

  // #region Effects
  useEffect(() => {
    if (getData) {
      reset(getData);
    }
  }, [getData, reset]);

  useEffect(() => {
    const {
      isSuccessful,
      documentId: dId,
      documentTypeId: dtId,
    } = postData ?? {};
    const changeUrl = () => {
      replace(`${url}/${dId}`);
    };

    const setDocumentTypeState = () => {
      if (dtId === ClientDocumentEnum.supplierClients) {
        actions.setIsSupplierClients(
          dtId === ClientDocumentEnum.supplierClients
        );
      }

      if (dtId === ClientDocumentEnum.contract) {
        actions.setIsContract(dtId === ClientDocumentEnum.contract);
      }
    };

    const clearValidationStatusState = () => {
      actions.setValidationStatus(null);
    };

    if (isSuccessful) {
      setDocumentTypeState();
      clearValidationStatusState();
      changeUrl();
    }
  }, [actions, postData, replace, url]);
  // #region Methods

  const handleCancel = () => {
    reset();
    goBack();
  };

  const onSave = (formData: IDocumentFormInput) => {
    executeFetch(CREATE_NEW_CLIENT_URL, {
      method: POST,
      body: { ...formData, documentId },
      skip: false,
    });
  };

  const onKeyPressInField = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === KEY_ENTER) {
      event.preventDefault();
    }
  };

  // #endregion Methods

  const { enumClientDocumentType } = cacheData;

  return (
    <>
      <Dialog open aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">
          Create new Client document
        </DialogTitle>
        <DialogContent dividers>
          {process.env.NODE_ENV !== "production" && (
            <DevTool control={control} placement="top-left" />
          )}
          <Grid container direction="column" spacing={4}>
            {showDocumentTypeWarning && (
              <Grid item>
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  {SUPPLIER_CLIENT_WARNING_MESSAGE}
                </Alert>
              </Grid>
            )}
            <Grid item>
              <form
                id={FORM_ID}
                role="presentation"
                onKeyDown={onKeyPressInField}
                onSubmit={handleSubmit(onSave)}
              >
                <Grid container direction="column" spacing={4}>
                  <Grid item>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoFocus
                          id="documentName"
                          name="documentName"
                          label="Client Name *"
                          variant="outlined"
                          fullWidth
                          error={!!errors.documentName?.message}
                          helperText={errors.documentName?.message}
                        />
                      )}
                      name="documentName"
                      control={control}
                    />
                  </Grid>
                  <Grid item>
                    <OptionFetcher loadOnMount url={GET_CONCERNS_URL}>
                      {({ options }) => (
                        <Controller
                          render={({ field }) => (
                            <Autocomplete
                              id="concern"
                              {...field}
                              freeSolo
                              autoHighlight
                              clearOnBlur
                              options={options}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue.trim();
                                }
                                // Regular option
                                return option.value;
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.value}</li>
                              )}
                              filterOptions={(opt, params) => {
                                const newParams = {
                                  ...params,
                                  inputValue: params.inputValue.trim(),
                                };
                                const filtered = filter(opt, newParams);

                                // Suggest the creation of a new value
                                if (
                                  !filtered.some(
                                    (o) => o.value === newParams.inputValue
                                  ) &&
                                  newParams.inputValue !== EMPTY_STRING
                                ) {
                                  filtered.push({
                                    inputValue: newParams.inputValue,
                                    value: `Add "${newParams.inputValue}"`,
                                    key: null,
                                  });
                                }

                                return filtered;
                              }}
                              renderInput={(
                                params: AutocompleteRenderInputParams
                              ) => (
                                <TextField
                                  {...params}
                                  name="concern"
                                  label="Concern Name *"
                                  variant="outlined"
                                  error={
                                    !!(errors.concern as FieldError)?.message
                                  }
                                  helperText={
                                    (errors.concern as FieldError)?.message
                                  }
                                  autoComplete={EMPTY_STRING}
                                />
                              )}
                              onChange={(event, newValue) => {
                                if (typeof newValue === "string") {
                                  // eslint-disable-next-line react/prop-types
                                  field.onChange({
                                    value: newValue,
                                    key: null,
                                  });
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  // eslint-disable-next-line react/prop-types
                                  field.onChange({
                                    value: newValue.inputValue,
                                    key: null,
                                  });
                                } else {
                                  // eslint-disable-next-line react/prop-types
                                  field.onChange(newValue);
                                }
                              }}
                            />
                          )}
                          name="concern"
                          control={control}
                        />
                      )}
                    </OptionFetcher>
                  </Grid>
                  <Grid item>
                    <Select
                      id="documentTypeId"
                      name="documentTypeId"
                      label="Document Type *"
                      variant="outlined"
                      fullWidth
                      control={control as unknown as Control}
                      options={enumClientDocumentType}
                      error={!!errors.documentTypeId?.message}
                      helperText={errors.documentTypeId?.message}
                      disabled={isSupplierClientsType}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            id="button-reset"
            name="button-reset"
            onClick={handleCancel}
            color="secondary"
            form={FORM_ID}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            id="button-submit"
            name="button-submit"
            color="primary"
            form={FORM_ID}
            disabled={isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt
        when={isDirty && !isSubmitSuccessful}
        message={UNSAVED_DATA_MESSAGE}
      />
    </>
  );
};

export default ClientDocumentDialog;
