import React, { ReactElement } from "react";

import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";

import {
  Grid,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";

import { productStatusIcons } from "product/components/icons";

import { PLACEHOLDER } from "shared/constants";
import { ProductOverview } from "product/types";
import { APP_PRODUCT, PAGE_DASHBOARD_PRODUCT } from "product/constants";
import { ParamTypes } from "product/pages/ProductOverview/types";

import { isEmpty } from "lodash";
import { classes, TableRow } from "./styles";

export const ProductTableRow = ({
  productId,
  productName,
  productStatus,
  marathonProductId,
  clientName,
  marathonClientId,
}: ProductOverview): ReactElement => {
  const { agencyName } = useParams<ParamTypes>();
  const { url } = useRouteMatch();
  const { search } = useLocation();

  return (
    <TableRow hover>
      <TableCell className={classes.cell}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Typography
              className={classes.typographyLabel}
              noWrap
              variant="subtitle2"
            >
              Product Name
            </Typography>

            <Link
              to={`/${APP_PRODUCT}/${agencyName}/${PAGE_DASHBOARD_PRODUCT}/${productId}`}
              className={classes.typographyLink}
            >
              <Tooltip
                title={productName}
                enterDelay={250}
                placement="bottom-start"
              >
                <Typography
                  id={`product-${productId}`}
                  className={classes.typographyValue}
                  variant="subtitle1"
                  noWrap
                >
                  {productName}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classes.typographyValue}
              variant="subtitle2"
              noWrap
            >
              {isEmpty(marathonProductId) ? PLACEHOLDER : marathonProductId}
            </Typography>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Product ID
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={clientName ?? PLACEHOLDER}
              enterDelay={250}
              placement="bottom-start"
            >
              <Typography
                className={classes.typographyValue}
                variant="subtitle2"
                noWrap
              >
                {clientName ?? PLACEHOLDER}
              </Typography>
            </Tooltip>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Client Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classes.typographyValue}
              variant="subtitle2"
              noWrap
            >
              {isEmpty(marathonClientId) ? PLACEHOLDER : marathonClientId}
            </Typography>
            <Typography className={classes.typographyLabel} variant="subtitle2">
              Client Id
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <IconButton
                  edge="start"
                  size="small"
                  component={Link}
                  disabled
                  to={{
                    pathname: `${url}/status`,
                    search: search
                      ? `${search}&id=${productId}`
                      : `?id=${productId}`,
                  }}
                >
                  {productStatusIcons[productStatus]}
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.typographyValue}
                  variant="subtitle2"
                  noWrap
                >
                  {productStatus}
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item>
                <Typography
                  className={classes.typographyLabel}
                  variant="subtitle2"
                >
                  Product Status
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default ProductTableRow;
