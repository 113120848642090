import {
  MDT_VIEW_TITLE,
  APPROVER_VIEW_TITLE,
  COMPLETE_VIEW_TITLE,
  REVISION_VIEW_TITLE,
} from "./constants";
import { MessageView } from "./types";

export const getMessageView = (type: NullableString): MessageView => {
  switch (type) {
    case "mdt":
      return MDT_VIEW_TITLE;
    case "approver":
      return APPROVER_VIEW_TITLE;
    case "revision":
      return REVISION_VIEW_TITLE;
    default:
      return COMPLETE_VIEW_TITLE;
  }
};
