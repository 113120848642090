import { getEnumKeyValues } from "shared/utils";

export enum DocumentEnum {
  client = 1,
}

export enum ClientDocumentEnum {
  contract = 2,
  supplierClients = 3,
}

export enum AgencyEnum {
  groupm = 1,
  mindshare = 2,
  kinetic = 5,
  essencemediacom = 6,
  wavemaker = 7,
  blossom = 8,
  greenhouseone = 9,
  greenhousetech = 10,
  choreographcreate = 11,
}

export enum FillFormOptionType {
  fillAll = 1,
  fillAllAndSave = 2,
  fillMandatory = 3,
  fillMandatoryAndSave = 4,
}

export enum AccordionEnum {
  clientDocument = 33,
  generalClientInfo = 1,
  additionalClientInfo = 2,
  creditInsurance = 3,
  clientContacts = 4,
  relatedContacts = 5,
  clientTeam = 6,
  generalContractInfo = 7,
  financialAvbs = 8,
  financialEpds = 9,
  financialUnbilledMedia = 10,
  financialClientIncentive = 11,
  inventoryMedia = 12,
  allowableDeducationsRebates = 13,
  audit = 14,
  interest = 15,
  admanagement = 16,
  digitalAccountabilityBrandSafety = 17,
  evouching = 18,
  hours = 19,
  remunerationContractLine = 20,
  scopeOfServices = 21,
  outOfScopeServices = 22,
  invoicingAddressMethod = 23,
  invoicingRequirements = 24,
  additionalInvoicingRequirements = 25,
  hoursAccountability = 26,
  commercialAgreements = 27,
  soxCompliantClientApproval = 28,
  revenueRecognition = 29,
  mdtChecks = 30,
  legalDocumentsChecks = 31,
  supplierAgreements = 32,

  hoursV5 = 100,
  // invoicingRequirementsV3 = 117,  // Left as a template for future work on old forms
}

export enum SectionEnum {
  client = 1,
  contract = 2,
  invoicing = 3,
  variousCommercialAgreements = 4,
  checks = 5,
  attachments = 6,
}

export enum ClientDocumentStatus {
  WorkInProgress = 1,
  ReadyForReview = 2,
  Reviewed = 3,
  MdtInProgress = 4,
  MdtChecked = 5,
  Approved = 6,
  Processed = 7,
  Finalized = 8,
  Decline = 9,
  ProcessedDecline = 10,
  ToBeChanged = 11,
  Changed = 12,
  FinalizedChanged = 13,
  Processing = 14,
  PendingFinalizedChangeReviewer = 15,
  PendingFinalizedChangeValidator = 16,
  PendingFinalizedChangeApprover = 17,
}

export enum DocumentStatusEnum {
  "Work in Progress" = "Work in Progress",
  Reviewed = "Reviewed",
  Finalized = "Finalized",
  "To be Changed" = "To be Changed",
  Changed = "Changed",
  Declined = "Declined",
  "Finalized Changed" = "Finalized Changed",
  "MDT in Progress" = "MDT in Progress",
  "Processed Decline" = "Processed Decline",
  Processing = "Processing",
  Approved = "Approved",
  "Pending Approver Approval" = "Pending Approver Approval",
  Processed = "Processed",
  "MDT Checked" = "MDT Checked",
  "Pending Reviewer Approval" = "Pending Reviewer Approval",
  "Pending Validator Approval" = "Pending Validator Approval",
  "Ready for Review" = "Ready for Review",
}

export enum FiltersImportUsersEnum {
  groupmExtension = "@groupm.com",
  kineticExtension = "@kineticww.com",
  mindshareExtension = "@mindshareworld.com",
  mediacomExtension = "@mediacom.com",
  wavemakerExtension = "@wmglobal.com",
  blossomExtension = "@weareblossom.com",
  greenHouseExtension = "@greenhousegroup.com",
  choreographExtension = "@choreograph.com",
  newUserStatus = "new",
  removedUserStatus = "removed",
}

export enum ValidationStatusEnum {
  valid = 1,
  invalid = 2,
  invalidMandatory = 3,
  notCreated = 4,
  notUsed = 10,
}

export enum CellTypeEnum {
  Input = "Input",
  Select = "Select",
  NumberFormat = "NumberFormat",
  Date = "Date",
}

export enum UserStatusEnum {
  default = 0,
  new = 1,
  imported = 2,
  removed = 3,
}

// #region  MAPS
export const MAP_AGENCY = getEnumKeyValues(AgencyEnum);
export const MAP_DOCUMENT = getEnumKeyValues(DocumentEnum);
export const MAP_SECTION = getEnumKeyValues(SectionEnum);
export const MAP_ACCORDION = getEnumKeyValues(AccordionEnum);
// #endregion MAPS
