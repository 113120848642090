import React, { useState, ReactElement } from "react";

import clsx from "clsx";

import { CssBaseline } from "@mui/material";

import { HeaderBar, FooterBar, SideBar } from "master-data/components";

import { classes, Root } from "./styles";

export interface LayoutProps {
  children: ReactElement;
}

export const Layout = ({ children }: LayoutProps): ReactElement => {
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Root className={classes.root}>
      <CssBaseline />
      <HeaderBar open={open} />
      <SideBar onLogoClick={handleDrawerToggle} open={open} />
      <main className={clsx(classes.content, { [classes.contentShift]: open })}>
        <div className={classes.toolbar} />
        {children}
        <div className={classes.toolbar} />
      </main>
      <FooterBar open={open} />
    </Root>
  );
};

export default Layout;
