import { styled, RadioGroup } from "@mui/material";

const PREFIX = "RelatedContactFormStyles";
export const classes = {
  radioGroup: `${PREFIX}-radioGroup`,
};

export const MuiRadioGroup = styled(RadioGroup)(() => ({
  [`& .${classes.radioGroup}`]: {
    minWidth: 320,
  },
}));
