import React, { useState, useEffect, ChangeEvent, ReactElement } from "react";

import { Search as SearchIcon, Cancel } from "@mui/icons-material";
import { OutlinedInput, IconButton, SelectChangeEvent } from "@mui/material";

import { FilterObj } from "core";

import { EMPTY_STRING } from "shared/constants";
import { classes, Div, Select, MenuItem } from "../../styles";

const INPUT_ID = "input-search";
const SELECT_ID = "select-search";
const BUTTON_ID = "chip-cancel";
const KEY_ENTER = 13;

export interface ChipEditProps {
  id?: number;
  filterProperty: string;
  filterValue: string | undefined;
  options: any[];
  classes?: any;
  cancelEdit: () => void;
  commit: ({ id, property, value }: FilterObj) => void;
}

export const ChipEdit = ({
  filterProperty,
  filterValue,
  options,
  cancelEdit,
  commit,
  id,
}: ChipEditProps): ReactElement => {
  const [value, setValue] = useState(filterValue);
  const [property, setProperty] = useState(filterProperty);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  useEffect(() => {
    setProperty(filterProperty);
  }, [filterProperty]);

  const handleInputSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSearchBy = (event: SelectChangeEvent<any>) => {
    setProperty(event.target.value as string);
  };

  const areFilterValuesSet = () =>
    value !== null &&
    value !== undefined &&
    value.trim().length !== 0 &&
    options.some(({ property: prop }) => prop === property);

  const doesChipHaveFocusedElement = (elementId: string) =>
    elementId !== null &&
    elementId !== undefined &&
    (elementId === SELECT_ID ||
      elementId === INPUT_ID ||
      elementId === BUTTON_ID);

  const handleBlur = (e: any) => {
    const isFilterSet = areFilterValuesSet();
    const isFocused = doesChipHaveFocusedElement(e.relatedTarget?.id);

    if (isFocused) return;

    if (isFilterSet) commit({ property, value, id });

    cancelEdit();
  };

  const handleKeyPress = (e: any) => {
    const isFilterSet = areFilterValuesSet();
    if (e.keyCode === KEY_ENTER && isFilterSet) {
      commit({ property, value, id });
      cancelEdit();
    }
  };
  return (
    <Div className={classes.outlined} id={`chip-search_${id}`}>
      <Select
        name={SELECT_ID}
        id={SELECT_ID}
        autoFocus
        margin="dense"
        onBlur={handleBlur}
        className={classes.dropDown}
        value={property}
        onChange={handleSearchBy}
        // input={<OutlinedInput labelWidth={0} name="search" />} // TODO
        input={<OutlinedInput name="search" />}
      >
        {options.map(({ property: prop, text }) => (
          <MenuItem
            // style={{ fontSize: "0.625rem" }}
            key={prop}
            id={`filter-${prop}`}
            value={prop}
          >
            {text}
          </MenuItem>
        ))}
      </Select>
      <OutlinedInput
        startAdornment={<SearchIcon color="primary" className={classes.icon} />}
        id={INPUT_ID}
        name={INPUT_ID}
        onBlur={handleBlur}
        onKeyDown={handleKeyPress}
        // labelWidth={0} // TODO
        placeholder="search"
        className={classes.search}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={value}
        onChange={handleInputSearch}
      />
      <IconButton
        className={classes.cancelButton}
        onClick={cancelEdit}
        id={BUTTON_ID}
        name={BUTTON_ID}
      >
        <Cancel className={classes.icon} />
      </IconButton>
    </Div>
  );
};

export default ChipEdit;
