import React, { useEffect, ReactElement, useState, ChangeEvent } from "react";

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import {
  useFetch,
  useQuery,
  FetchMethodEnum,
  useProfileGetter,
} from "shared/hooks";

import { generateUrl } from "shared/utils";
import {
  EMPTY_STRING,
  EMPTY_TABLE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  PIPESYMBOL,
} from "shared/constants";
import {
  INIT_QUERY_DEBTORS,
  INIT_QUERY_IMPORT_USERS,
  KEYWORD_SEARCH,
  USER_SUGGESTION,
  DEBTOR_SUGGESTION,
  URL_MANAGE_DEBTOR_GROUP,
  URL_MANAGE_USER_GROUP,
} from "access-manager/constants";
import { Divider } from "shared/styles";
import { searchBy } from "./config";
import {
  AMKeywordSearchResponse,
  IPostResponse,
  AMKeywordSuggestionResponse,
} from "./types";
import { classes, Box } from "./styles";

const { GET } = FetchMethodEnum;

export const KeywordSearchPage = (): ReactElement => {
  useProfileGetter(0);
  const query = useQuery();
  const queryObject = Object.fromEntries(query);
  const apiUserSuggestionUrl = generateUrl(USER_SUGGESTION);
  const apiDebtorSuggestionUrl = generateUrl(DEBTOR_SUGGESTION);
  const apiUrlSearch = generateUrl(KEYWORD_SEARCH);
  const options: string[] = [];

  const [apiURL, setapiURL] = useState(apiUserSuggestionUrl);
  const [selectedAttributeId, setSelectedAttributeId] = useState(
    searchBy[0].key
  );
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const {
    data: suggestionResult,
    updateParams,
    updateUrl,
  } = useFetch<AMKeywordSuggestionResponse, IPostResponse>(apiURL, {
    method: GET,
    initialParams: { ...queryObject },
  });

  const { get: getSuggestionData } = suggestionResult ?? {};

  const { data: searchResult, executeFetch: executeSearchFetch } = useFetch<
    AMKeywordSearchResponse,
    IPostResponse
  >(apiUrlSearch, {
    method: GET,
    initialParams: { ...queryObject },
  });

  const { get: getSearchData } = searchResult ?? {};

  useEffect(() => {
    updateParams({
      keyword: searchValue.length < 1 ? null : searchValue,
    });
    updateUrl(apiURL);
  }, [searchValue, updateParams, updateUrl, apiURL]);

  const handleKeywordChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setError(false);
    setapiURL(
      selectedAttributeId === 1 ? apiUserSuggestionUrl : apiDebtorSuggestionUrl
    );

    setSearchValue(event.target.value);
  };

  const handleAttributeChange = (event: SelectChangeEvent<number>) => {
    setSelectedAttributeId(event.target.value as number);
    setSearchValue("");
    setSelectedValue("");
  };

  const KeywordSearchFilter = () => {
    return getSuggestionData?.suggestionResult.map((x) => {
      return x.value;
    }) as string[];
  };

  const handleSearchClick = () => {
    const selectedItem = selectedValue.includes(PIPESYMBOL)
      ? selectedValue.split(PIPESYMBOL)[1].trim()
      : EMPTY_STRING;

    if (selectedItem.length < 1) {
      setError(true);
      return;
    }

    const selectedItemId = getSuggestionData?.suggestionResult.filter(
      (x) => x.userName === selectedItem || x.marathonId === selectedItem
    )[0].id;

    const fetchUsersUrl = generateUrl(KEYWORD_SEARCH);
    executeSearchFetch(fetchUsersUrl, {
      method: GET,
      initialParams: {
        inputId: selectedItemId,
        attributeId: selectedAttributeId,
      },
    });
  };

  return (
    <Box>
      <Grid container className={classes.gridPadding}>
        <Grid container>
          <Grid item>
            <FormControl className={classes.formMinWidth}>
              <InputLabel id="demo-simple-select-label">Search By</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedAttributeId}
                label="Search By"
                onChange={(event) => handleAttributeChange(event)}
              >
                {searchBy.map(({ key, value }) => (
                  <MenuItem key={`select-${key}`} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid item>
            <Autocomplete
              id="concern"
              className={classes.width}
              freeSolo
              autoHighlight
              clearOnBlur
              options={options}
              filterOptions={() => KeywordSearchFilter()}
              inputValue={selectedValue}
              onInputChange={(event, newInputValue) => {
                setSelectedValue(newInputValue);
                setError(false);
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  name="concern"
                  label="Keyword Input"
                  variant="outlined"
                  onChange={(event) => handleKeywordChange(event)}
                  error={error}
                  helperText={error ? MANDATORY_FILED_VALIDATION_MESSAGE : ""}
                />
              )}
            />
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid item>
            <Button
              className={classes.padding}
              id="button-search"
              color="primary"
              variant="contained"
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.gridPadding}>
          <Grid item xs={12}>
            <Box className={classes.boxBorder}>
              <List component="nav" aria-label="mapping">
                <ListItem>
                  <ListItemText secondary="Mapping(s)" />
                </ListItem>
                <Divider />
                {getSearchData?.mapping && getSearchData.mapping.length > 0 ? (
                  getSearchData.mapping.map((x) => {
                    return (
                      <ListItemButton
                        key={`mapping-${x.id}-${x.applicationName}-${x.userGroupName}-${x.debtorGroupName}`}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Typography variant="caption" display="block">
                              Application
                            </Typography>
                            <ListItemText primary={x.applicationName} />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="caption" display="block">
                              User Group
                            </Typography>
                            <ListItemText primary={x.userGroupName} />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="caption" display="block">
                              Debtor Group
                            </Typography>
                            <ListItemText primary={x.debtorGroupName} />
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    );
                  })
                ) : (
                  <ListItem>
                    <ListItemText primary={EMPTY_TABLE} />
                  </ListItem>
                )}
              </List>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.gridPadding}>
          <Grid item xs={6}>
            <Box className={classes.boxBorder}>
              <List component="nav" aria-label="usergroup">
                <ListItem>
                  <ListItemText secondary="User Group(s)" />
                </ListItem>
                <Divider />
                {getSearchData?.userGroup &&
                getSearchData.userGroup.length > 0 ? (
                  getSearchData.userGroup.map((x) => {
                    return (
                      <ListItemButton
                        key={`${x.id}-${x.userGroupName}`}
                        href={`/${URL_MANAGE_USER_GROUP}/${x.id}${INIT_QUERY_IMPORT_USERS}`}
                      >
                        {x.userGroupName}
                      </ListItemButton>
                    );
                  })
                ) : (
                  <ListItem>
                    <ListItemText primary={EMPTY_TABLE} />
                  </ListItem>
                )}
              </List>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.boxBorder}>
              <List component="nav" aria-label="debtorgroup">
                <ListItem>
                  <ListItemText secondary="Debtor Group(s)" />
                </ListItem>
                <Divider />
                {getSearchData?.debtorGroup &&
                getSearchData.debtorGroup.length > 0 ? (
                  getSearchData.debtorGroup.map((x) => {
                    return (
                      <ListItemButton
                        key={`${x.id}-${x.debtorGroupName}`}
                        href={`/${URL_MANAGE_DEBTOR_GROUP}/${x.id}${INIT_QUERY_DEBTORS}`}
                      >
                        {x.debtorGroupName}
                      </ListItemButton>
                    );
                  })
                ) : (
                  <ListItem>
                    <ListItemText primary={EMPTY_TABLE} />
                  </ListItem>
                )}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeywordSearchPage;
