import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import {
  setIsOpenImportSelectedDialog,
  setIsOpenRemoveSelectedDialog,
} from "store/actions";

import { classes, Grid } from "./styles";

export const ImportUsersActions = (): ReactElement => {
  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsOpenImportSelectedDialog,
    setIsOpenRemoveSelectedDialog,
  });

  const { isSelectedAMUsers } = appState;

  const handleOpenImportSelectedDialog = () => {
    actions.setIsOpenImportSelectedDialog(true);
  };

  const handleOpenRemoveSelectedDialog = () => {
    actions.setIsOpenRemoveSelectedDialog(true);
  };

  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item>
        <Button
          id="button-import"
          color="primary"
          variant="contained"
          className={classes.importButton}
          classes={{ disabled: classes.disabledButton }}
          disabled={!isSelectedAMUsers}
          onClick={handleOpenImportSelectedDialog}
        >
          Import Selected
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="button-remove"
          color="primary"
          variant="contained"
          className={classes.removeButton}
          classes={{ disabled: classes.disabledButton }}
          disabled={!isSelectedAMUsers}
          onClick={handleOpenRemoveSelectedDialog}
        >
          Remove Selected
        </Button>
      </Grid>
    </Grid>
  );
};

export default ImportUsersActions;
