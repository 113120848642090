import React, { ReactElement } from "react";

import clsx from "clsx";
import {
  Tooltip,
  IconButton,
  InputAdornment,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { History as HistoryIcon } from "@mui/icons-material";

import { EMPTY_STRING, REVISION_INIT_VALUE } from "shared/constants";

import { InfoPopper } from "../InfoPopper";

import { classes, MuiTextField } from "./styles";

export type TextFieldProps = MuiTextFieldProps & {
  name: string;
  control: Control;
  /**
   * Previous value of field
   */
  revisionValue?: NullableString;
};

export const TextField = ({
  variant,
  fullWidth,
  revisionValue,
  defaultValue,
  ...otherProps
}: TextFieldProps): ReactElement => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [isTextOverflow, setIsTextOverflow] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const checkOverflow = () => {
    if (otherProps.disabled) {
      const element = document.getElementById(otherProps.name);
      if (element !== null && element.offsetWidth < element.scrollWidth) {
        setIsTextOverflow(true);
        return;
      }
    }
    setIsTextOverflow(false);
  };

  let revisionVal: string | undefined;
  if (revisionValue === null || revisionValue === EMPTY_STRING) {
    revisionVal = REVISION_INIT_VALUE;
  } else {
    revisionVal = revisionValue;
  }

  return (
    <>
      <Controller
        name={otherProps.name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Tooltip
            title={isTextOverflow ? field.value : EMPTY_STRING}
            placement="bottom"
          >
            <MuiTextField
              {...field}
              value={field.value || EMPTY_STRING}
              variant={variant}
              fullWidth={fullWidth}
              {...otherProps}
              id={otherProps.name}
              inputProps={{
                className: clsx({
                  [classes.noWrap]:
                    otherProps.disabled && !otherProps.multiline,
                }),
              }}
              onMouseEnter={checkOverflow}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...otherProps.InputProps,
                endAdornment: revisionVal ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle change value visibility"
                      onClick={handleClick}
                      ref={anchorRef}
                    >
                      <HistoryIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
              }}
            />
          </Tooltip>
        )}
        control={otherProps.control}
      />
      {revisionVal && (
        <InfoPopper
          text={revisionVal}
          onClickAway={handleClickAway}
          open={open}
          anchorEl={anchorRef.current}
        />
      )}
    </>
  );
};

TextField.defaultProps = {
  fullWidth: true,
  revisionValue: undefined,
  variant: "outlined",
};

export default TextField;
