import { number, object } from "yup";

import { nanToNull } from "shared/utils";
import { MANDATORY_FILED_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  latePaymentInterest: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  clientPaysOnTimeForfeits: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  adminLiftingCosts: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  againstOldersInvoices: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
});
