import React, { ReactElement } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";

import { FillFormOptionType } from "master-data/enums";
import { classes, Button } from "./styles";

export interface FormDialogProps extends DialogProps {
  /*
   * When true, form has labels for creating new form
   */
  isNew: boolean;
  /*
   * When true, form is in edit state with edit labels
   */
  isEdit: boolean;
  /**
   * Flag indicating if Fill button should be displayed
   */
  showFillButton?: boolean;
  /**
   * Flag indicating if FillMandatory(& Save) option should be displayed
   */
  showFillMandatoryOption?: boolean;
  /*
   * Callback for saving form
   */
  onSave: () => void;
  /*
   * Callback for canceling form
   */
  onCancel: () => void;
  /**
   * Callback fired when Fill menu option is clicked
   */
  onFill?: (fillOption: FillFormOptionType) => void;
}

export const FormDialog = ({
  open,
  isNew,
  isEdit,
  showFillButton,
  showFillMandatoryOption,
  onSave,
  onCancel,
  onClose,
  onFill,
  children,
  ...dialogProps
}: FormDialogProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openFillMenu = Boolean(anchorEl);

  // TODO: move displayLabel and reuse
  const displayLabel = () => {
    if (isEdit) {
      if (isNew) {
        return "Add new entry";
      }
      return "Edit entry";
    }
    return "View entry";
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFillAll = () => {
    if (typeof onFill === "function") {
      onFill(FillFormOptionType.fillAll);
    }
    handleClose();
  };

  const handleFillAllAndSave = () => {
    if (typeof onFill === "function") {
      onFill(FillFormOptionType.fillAllAndSave);
    }
    handleClose();
  };

  const handleFillMandatory = () => {
    if (typeof onFill === "function") {
      onFill(FillFormOptionType.fillMandatory);
    }
    handleClose();
  };

  const handleFillMandatoryAndSave = () => {
    if (typeof onFill === "function") {
      onFill(FillFormOptionType.fillMandatoryAndSave);
    }
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog"
      {...dialogProps}
    >
      <DialogTitle id="form-dialog-title">{displayLabel()}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button color="secondary" type="reset" onClick={onCancel}>
          Cancel
        </Button>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0}
        >
          {isEdit && (
            <Button color="primary" type="submit" onClick={onSave}>
              {isNew ? "Add" : "Edit"}
            </Button>
          )}
          {showFillButton && (
            <>
              <Button
                id="basic-button"
                color="primary"
                onClick={handleClick}
                aria-controls={openFillMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openFillMenu ? "true" : undefined}
              >
                Fill
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openFillMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleFillAll}>Fill All</MenuItem>
                <MenuItem>
                  <Button
                    onClick={handleFillAllAndSave}
                    type="submit"
                    className={classes.fillButton}
                  >
                    Fill All & Save
                  </Button>
                </MenuItem>
                {showFillMandatoryOption && (
                  <MenuItem onClick={handleFillMandatory}>
                    Fill Mandatory
                  </MenuItem>
                )}
                {showFillMandatoryOption && (
                  <MenuItem onClick={handleFillMandatoryAndSave}>
                    <Button
                      onClick={handleFillAllAndSave}
                      type="submit"
                      className={classes.fillButton}
                    >
                      Fill Mandatory & Save
                    </Button>
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
