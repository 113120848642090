import { number, object, string } from "yup";

import {
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
} from "shared/constants";
import { emptyStringToNull, nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  rightToFinanceAudit: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  accountants: string()
    .nullable()
    .transform(emptyStringToNull)
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  rightToMediaAudit: number().nullable().transform(nanToNull),
  otherAuditPeriod: string().when("auditPeriodId", {
    is: 3,
    then: string()
      .trim()
      .nullable()
      .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
      .required(MANDATORY_FILED_VALIDATION_MESSAGE),
    otherwise: string().nullable().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  }),
});
