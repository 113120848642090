import { number, object, string, date, ref } from "yup";

import { nanToNull } from "shared/utils";
import {
  INVALID_DATE_VALIDATION_MESSAGE,
  MAX_LENGTH_50_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  INVALID_END_DATE_BEFORE_VALIDATION_MESSAGE,
} from "shared/constants";

export const validationSchema = object().shape({
  contractTypeId: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  redGreenScheduleId: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  contractTermId: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  contractStatusId: number()
    .nullable()
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  dutyToReporFinancialInteresttId: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  dutyToReportSubContractingId: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  nationalAddendum: number().when("contractTypeId", {
    is: (contractTypeId: number) => contractTypeId === 2,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  startDate: date().when("$isContract", {
    is: true,
    then: date()
      .nullable()
      .typeError(INVALID_DATE_VALIDATION_MESSAGE)
      .required(MANDATORY_FILED_VALIDATION_MESSAGE),
    otherwise: date().nullable().typeError(INVALID_DATE_VALIDATION_MESSAGE),
  }),
  endDate: date()
    .min(ref("startDate"), INVALID_END_DATE_BEFORE_VALIDATION_MESSAGE)
    .when("contractTermId", {
      is: (contractTermId: number) => contractTermId === 1,
      then: date()
        .nullable()
        .typeError(INVALID_DATE_VALIDATION_MESSAGE)
        .required(MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: date().nullable().typeError(INVALID_DATE_VALIDATION_MESSAGE),
    }),
  contractAdded: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  noticePeriod: string()
    .trim()
    .when("$isContract", {
      is: true,
      then: string()
        .nullable()
        .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
        .required(MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: string().nullable().max(50, MAX_LENGTH_50_VALIDATION_MESSAGE),
    }),
  competitiveClients: number()
    .transform(nanToNull)
    .when("$isContract", {
      is: true,
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().transform(nanToNull),
    }),
  competitionClause: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  irfsAdded: number().when("$isContract", {
    is: true,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  gdprClause: number().nullable().transform(nanToNull),
  contractSection: string()
    .trim()
    .nullable()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
});
