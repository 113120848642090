import React, { ReactElement } from "react";

import clsx from "clsx";
import { Toolbar } from "@mui/material";

import { classes, MuiAppBar } from "./styles";

export interface AppBarProps {
  /*
   * children
   */
  children: React.ReactNode;
  /*
   * open
   */
  open?: boolean;
  /*
   * isFooter
   */
  isFooter?: boolean;
}

export const AppBar = ({
  children,
  open = false,
  isFooter = false,
}: AppBarProps): ReactElement => {
  return (
    <MuiAppBar
      elevation={0}
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
        [classes.appBarFooter]: isFooter,
      })}
    >
      <Toolbar>{children}</Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
