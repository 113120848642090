import { AccordionType } from "product/types";
import { IFormsInput } from "./types";

import { defaultValues as invoicingAddressMethodDefaultValues } from "./InvoicingAddressMethod/constants";
import { defaultValues as productInfoValues } from "./ProductInfo/constants";
import { defaultValues as activationMBSValues } from "./ActivationMBS/constants";
import { defaultValues as businessSectionValues } from "./BusinessSection/constants";

export const defaults: {
  [key in AccordionType]: IFormsInput[key];
} = {
  productInfo: productInfoValues,
  invoicingAddressMethod: invoicingAddressMethodDefaultValues,
  activationMBS: activationMBSValues,
  businessSection: businessSectionValues,
};
