import React, { ReactElement, useEffect, useState } from "react";

import { FormHelperText, Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, FormDialog, TableEntries } from "core";

import {
  yesNoOptions,
  MANDATORY_ENTRY_VALIDATION_MESSAGE,
} from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { columns } from "./config";
import { FormEntriesFieldProps } from "../props";
import { Current, IRevisionEntriesInput, IRevisionInput } from "./types";
import { FormRemunerationContractLineEntry } from "../RemunerationContractLineEntry";

export interface FormRemunerationContractLineProps
  extends FormEntriesFieldProps {
  /**
   * revisionValues
   * */
  revisionValues: IRevisionInput;
  /**
   * active item
   */
  current: Current;
  /**
   * revisionValuesFormDialog
   */
  revisionValuesFormDialog: IRevisionEntriesInput;
  /**
   * errors
   * */
  errors: any;
  /**
   * getValues
   * */
  getValues: any;
  /**
   * setValues
   */
  setValue: any;
  /**
   * revisionValueIds
   */
  revisionValueIds: string[];
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
  /**
   * onEdit
   */
  onEdit: (newCurrent: Current) => void;
}

export const FormRemunerationContractLine = ({
  isEdit,
  errors,
  errorsFormDialog,
  control,
  revisionValues,
  current,
  fields,
  onAdd,
  onEdit,
  onDelete,
  onDialogCancel,
  onDialogSave,
  clearErrors,
  isNewEntry,
  revisionValuesFormDialog,
  isDialogOpen,
  getValues,
  setValue,
  revisionValueIds,
  fillOption,
}: FormRemunerationContractLineProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const [dialogFillOption, setDialogFillOption] =
    useState<FillFormOptionType>();

  const {
    enumPaymentTerm,
    enumInvoicingPeriod,
    enumFeeMediaInvoice,
    enumCurrency,
    enumApplicableServices,
  } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("commissionOnMedia", yesNoOptions[0].key);
      setValue("slidingScale", yesNoOptions[0].key);
      setValue("thirdPartyFee", yesNoOptions[0].key);
    }
  }, [fillOption, setValue]);
  // #endregion Effects
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Select
            id="commissionOnMedia"
            name="commissionOnMedia"
            variant="outlined"
            label="Commission on Media *"
            fullWidth
            error={!!errors.commissionOnMedia?.message}
            helperText={errors.commissionOnMedia?.message}
            revisionValue={revisionValues?.CommissionOnMedia}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="slidingScale"
            name="slidingScale"
            variant="outlined"
            label="Sliding Scale *"
            fullWidth
            error={!!errors.slidingScale?.message}
            helperText={errors.slidingScale?.message}
            revisionValue={revisionValues?.SlidingScale}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="thirdPartyFee"
            name="thirdPartyFee"
            variant="outlined"
            label="Third Party Fee *"
            fullWidth
            error={!!errors.thirdPartyFee?.message}
            helperText={errors.thirdPartyFee?.message}
            revisionValue={revisionValues?.ThirdPartyFee}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormHelperText id="table-helper-text" error={!!errors.entries}>
            {MANDATORY_ENTRY_VALIDATION_MESSAGE}
          </FormHelperText>
          <TableEntries
            onAdd={onAdd}
            onEdit={onEdit}
            onDelete={onDelete}
            rows={fields}
            isEdit={isEdit}
            control={control}
            columns={columns}
            revisionValueIds={revisionValueIds}
            fieldOptions={{
              invoicingPeriodId: enumInvoicingPeriod,
              applicableServices: enumApplicableServices,
              paymentTermId: enumPaymentTerm,
              feeOnMediaInvoiceId: enumFeeMediaInvoice,
              currencyId: enumCurrency,
            }}
          />
        </Grid>
      </Grid>
      <FormDialog
        maxWidth="md"
        isEdit={isEdit}
        isNew={isNewEntry}
        open={isDialogOpen}
        onCancel={onDialogCancel}
        onSave={onDialogSave}
        onClose={() => clearErrors("entries")}
        showFillButton={
          isEdit && process.env.REACT_APP_SHOW_FILL_BUTTON === "true"
        }
        showFillMandatoryOption
        onFill={setDialogFillOption}
      >
        <FormRemunerationContractLineEntry
          control={control}
          revisionValues={revisionValuesFormDialog}
          current={current}
          getValues={getValues}
          setValue={setValue}
          isEdit={isEdit}
          errors={errorsFormDialog}
          fillOption={dialogFillOption}
          resetFillOption={setDialogFillOption}
          onDialogSave={onDialogSave}
        />
      </FormDialog>
    </>
  );
};

export default FormRemunerationContractLine;
