import { styled, TextField } from "@mui/material";

const PREFIX = "TextFieldStyles";
export const classes = {
  noWrap: `${PREFIX}-noWrap`,
};

export const MuiTextField = styled(TextField)(() => ({
  [`& .${classes.noWrap}`]: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
