import React, { ReactElement } from "react";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@mui/material";

import { PAGE_HOME } from "shared/constants";
import { classes, Div } from "./styles";

export const ErrorPage = (): ReactElement => {
  const { replace } = useHistory();

  const navigateToHome = () => replace(PAGE_HOME);

  return (
    <>
      <Div className={classes.top}>
        <h1 className={classes.h1}>404</h1>
        <h3 className={classes.h3}>page not found</h3>
      </Div>
      <Div className={classes.container}>
        <Div className={classes.ghostCopy}>
          <Div className={clsx(classes.base, classes.one)} />
          <Div className={clsx(classes.base, classes.two)} />
          <Div className={clsx(classes.base, classes.three)} />
          <Div className={clsx(classes.base, classes.four)} />
        </Div>
        <Div className={classes.ghost}>
          <Div className={classes.face}>
            <Div className={clsx(classes.eyesBase, classes.eye)} />
            <Div className={clsx(classes.eyesBase, classes.eyeRight)} />
            <Div className={classes.mouth} />
          </Div>
        </Div>
        <Div className={classes.shadow} />
      </Div>
      <Div className={classes.bottom}>
        <p className={classes.p}>
          Sorry but the page you are looking for does not exist, have been
          removed, name changed or is temporarily unavailable
        </p>
      </Div>
      <Grid container justifyContent="center">
        <Button onClick={navigateToHome}>Home</Button>
      </Grid>
    </>
  );
};

export default ErrorPage;
