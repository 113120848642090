import React from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LogItem } from "back-office/pages/DebtorSync/types";
import { TableRowEmpty } from "core";
import { isEmpty } from "lodash";

interface DebtorSyncTableProps {
  items: LogItem[];
  onDisplayLogDialog: (logId: number) => void;
}

export const DebtorSyncTable = ({
  items,
  onDisplayLogDialog,
}: DebtorSyncTableProps) => {
  return (
    <Table size="small" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell align="center">Date & time</TableCell>
          <TableCell align="center">Execution</TableCell>
          <TableCell align="center">Number of Updates</TableCell>
          <TableCell align="center">Log</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(items) ? (
          <TableRowEmpty />
        ) : (
          items.map((item: LogItem) => (
            <TableRow key={`row_${item.id}`}>
              <TableCell align="center">{item.logDateTimeStr}</TableCell>
              <TableCell align="center">
                {item.isSuccessful ? "Successful" : "Unsuccessful"}
              </TableCell>
              <TableCell align="center">{item.numberOfUpdates}</TableCell>
              <TableCell align="center">
                <Button
                  id={`button-viewLog-${item.id}`}
                  onClick={() => onDisplayLogDialog(item.id)}
                >
                  <VisibilityIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default DebtorSyncTable;
