import React, { ReactElement } from "react";
import { IconButton, Typography } from "@mui/material";
import { CopyIcon } from "master-data/components/icons";
import CopyToClipboard from "react-copy-to-clipboard";

export interface SingleEmailActionUserProps {
  documentId: string;
  role: string | undefined;
  email: string;
  showCopyIcon: boolean;
}

export const SingleEmailActionUser = ({
  documentId,
  role,
  email,
  showCopyIcon,
}: SingleEmailActionUserProps): ReactElement => {
  return (
    <Typography variant="subtitle1">
      {`[${role}] `}
      {showCopyIcon && (
        <CopyToClipboard text={email}>
          <IconButton
            name={`copy_bttn_${documentId}`}
            id={`copy_bttn_${documentId}`}
            size="small"
          >
            <CopyIcon />
          </IconButton>
        </CopyToClipboard>
      )}
      {` ${email}`}
    </Typography>
  );
};

export default SingleEmailActionUser;
