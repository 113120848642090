import {
  styled,
  Typography as MuiTypography,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from "@mui/material";

const PREFIX = "SortStyle";
export const classes = {
  dropDown: `${PREFIX}-dropDown`,
  cssTypography: `${PREFIX}-cssTypography`,
  menuItem: `${PREFIX}-menuItem`,
};

export const Typography = styled(MuiTypography)(({ theme }) => ({
  [`&.${classes.cssTypography}`]: {
    color: "#7B83A3",
    marginRight: theme.typography.pxToRem(15),
    fontSize: "15px",
  },
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  [`&.${classes.menuItem}`]: {
    fontSize: theme.typography.pxToRem(10),
  },
}));

export const Select = styled(MuiSelect)(({ theme }) => ({
  [`&.${classes.dropDown}`]: {
    minWidth: theme.typography.pxToRem(125),
    height: theme.typography.pxToRem(22),
  },
}));
