import React, { ReactElement, useEffect, useState, MouseEvent } from "react";
import {
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import { ConfirmationDialog } from "core";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  INIT_QUERY_DEBTORS,
  MANAGE_DEBTOR_GROUP,
  CREATE_DEBTOR_GROUP,
  DELETE_DEBTOR_GROUP,
  URL_DEBTOR_GROUP_OVERVIEW,
  DELETE_DEBTOR_GROUP_CONTENT,
  DELETE_DEBTOR_GROUP_TITLE,
} from "access-manager/constants";
import { FetchMethodEnum, useFetch } from "shared/hooks";
import { generateUrl } from "shared/utils";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { EMPTY_STRING } from "shared/constants";
import { ParamTypes } from "./types";
import { Accordion } from "./styles";
import { AccordionSummary as Summary } from "../../../master-data/components/AccordionSummary";
import { AccordionActions as Actions } from "../../../master-data/components/AccordionActions";
import { FormStandard } from "../FormStandard/FormStandard";

const { DELETE } = FetchMethodEnum;

export const DebtorGroupForm = (): ReactElement => {
  const { debtorGroupId } = useParams<ParamTypes>();
  const { url } = useRouteMatch();
  const { push, replace } = useHistory();

  const [isExtended, setIsExtended] = useState<boolean>(true);
  const [isInEditing, setIsInEditing] = useState<boolean>(false);
  const [isDeleteDialogOpen, SetIsDeleteDialogOpen] = useState<boolean>(false);
  const [isDeleteGroupButtonDisabled, SetIsDeleteGroupButtonDisabled] =
    useState<boolean>(false);
  const [formParams, setFormParams] = useState<unknown>({
    isNew: !debtorGroupId,
    getUrl: generateUrl(MANAGE_DEBTOR_GROUP, { debtorGroupId }),
    createUrl: generateUrl(CREATE_DEBTOR_GROUP),
    editUrl: generateUrl(MANAGE_DEBTOR_GROUP, { debtorGroupId }),
  });

  const { isSuccessful, executeFetch } = useFetch(null, {
    skip: true,
  });

  const handleAccordionExtending = () => {
    setIsExtended(!isExtended);
    setIsInEditing(false);
  };

  const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    if (isExtended) {
      event.stopPropagation();
      event.preventDefault();
    }

    setIsInEditing(true);
  };

  const handleSave = (id: number) => {
    setIsInEditing(false);

    if (!debtorGroupId) {
      replace(`${url}/${id}${INIT_QUERY_DEBTORS}`);
    }
  };

  const handleCancel = () => {
    setIsInEditing(false);
  };

  const handleDelete = () => {
    const apiDeleteUserGroupUrl = generateUrl(DELETE_DEBTOR_GROUP, {
      debtorGroupId,
    });
    executeFetch(apiDeleteUserGroupUrl, {
      method: DELETE,
      skip: false,
    });
  };

  useEffect(() => {
    if (!isExtended) {
      setIsInEditing(false);
    }
  }, [isExtended]);

  useEffect(() => {
    setFormParams({
      isNew: !debtorGroupId,
      getUrl: generateUrl(MANAGE_DEBTOR_GROUP, { debtorGroupId }),
      createUrl: generateUrl(CREATE_DEBTOR_GROUP),
      editUrl: generateUrl(MANAGE_DEBTOR_GROUP, { debtorGroupId }),
    });
  }, [debtorGroupId]);

  useEffect(() => {
    if (isSuccessful) {
      const debtorGroupOverviewPagePath = `/${URL_DEBTOR_GROUP_OVERVIEW}${INIT_QUERY_DEBTORS}`;
      push(debtorGroupOverviewPagePath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessful]);

  return (
    <Accordion expanded={isExtended}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="debtor-group-form-content"
        id="debtor-group-form-header"
        onClick={handleAccordionExtending}
      >
        <Summary
          title={debtorGroupId ? "Edit Debtor Group" : "Create Debtor Group"}
          type="debtor-group-form"
          onEdit={handleEdit}
        />
      </AccordionSummary>
      <AccordionDetails>
        {isExtended && (
          <FormStandard
            type="debtorGroup"
            isEdit={isInEditing}
            onSave={(id) => handleSave(id)}
            disableDeleteGroupButton={() =>
              SetIsDeleteGroupButtonDisabled(true)
            }
            formParams={formParams}
          />
        )}
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Actions
          type="debtorGroup"
          isEdit={isInEditing}
          onCancel={handleCancel}
          onDelete={() => SetIsDeleteDialogOpen(true)}
          isDeleteButtonDisabled={
            debtorGroupId == null ||
            debtorGroupId === undefined ||
            debtorGroupId === EMPTY_STRING ||
            isDeleteGroupButtonDisabled
          }
          showDeleteButton={!isInEditing && debtorGroupId !== undefined}
        />
      </AccordionActions>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          title={DELETE_DEBTOR_GROUP_TITLE}
          message={DELETE_DEBTOR_GROUP_CONTENT}
          isDisableAction={Boolean(false)}
          onConfirm={handleDelete}
          onCancel={() => SetIsDeleteDialogOpen(false)}
        />
      )}
    </Accordion>
  );
};

export default DebtorGroupForm;
