import React, { ReactElement } from "react";

import { Redirect } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
} from "@mui/material";

import { CardLink } from "core";

import { useInitDataGetter } from "shared/hooks";

import { APP_MASTERDATA, PAGE_OVERVIEW } from "master-data/constants";
import { INIT_QUERY } from "shared/constants";
import {
  URL_DEBTOR_SYNC,
  URL_DOCUMENT_SCHEMA_MANAGEMENT_V6,
  URL_USER_MANAGEMENT,
} from "back-office/constants";

import {
  INIT_OVERVIEW_PAGE,
  INIT_QUERY_IMPORT_USERS,
  URL_DEBTOR_GROUP_OVERVIEW,
  URL_IMPORT_USERS,
  URL_USER_GROUP_OVERVIEW,
  URL_MAPPING_OVERVIEW,
  URL_KEYWORD_SEARCH_PAGE,
  URL_MISSING_DEBTORS_PAGE,
} from "access-manager/constants";
import { images } from "./config";

import { classes, Root } from "./styles";

export const HomePage = (): ReactElement => {
  const res = useInitDataGetter();
  const { state } = useStateMachine();

  const {
    profile: { agentForAgencies: agencies, userAgency },
    appState: {
      showAgencySelectionPage,
      showUserManegementPage,
      showImportUsersPage,
      showDebtorGroupsPage,
    },
  } = state;

  if (typeof res === "boolean") {
    return (
      <Root className={classes.root}>
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress size={80} />
        </Grid>
      </Root>
    );
  }

  if (showAgencySelectionPage) {
    return (
      <Root className={classes.root}>
        <Grid
          container
          spacing={10}
          direction="column"
          className={classes.appContainer}
        >
          <Grid item className={classes.item}>
            <Card className={classes.appCard} elevation={0}>
              <CardHeader title="Master Data" />
              <CardContent>
                <Grid item container spacing={2} justifyContent="flex-start">
                  {agencies?.map((agency: string) => {
                    const agencyLowerCase = agency.toLowerCase();
                    return (
                      <Grid key={agency} item>
                        <CardLink
                          title={agency}
                          id={agencyLowerCase}
                          image={images[agencyLowerCase]}
                          to={`/${APP_MASTERDATA}/${agencyLowerCase}/${PAGE_OVERVIEW}${INIT_QUERY}`}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {(showImportUsersPage || showDebtorGroupsPage) && (
            <Grid item className={classes.item}>
              <Card className={classes.appCard} elevation={0}>
                <CardHeader title="Access Manager" />
                <CardContent>
                  <Grid
                    item
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {showImportUsersPage && (
                      <Grid key="AMusers" item>
                        <CardLink
                          id="importUsers"
                          title="Import Users"
                          to={`${URL_IMPORT_USERS}${INIT_QUERY_IMPORT_USERS}`}
                        />
                      </Grid>
                    )}
                    {showImportUsersPage && (
                      <Grid key="UserGroupOverviewPage" item>
                        <CardLink
                          id="userGroupOverviewPage"
                          title="User Groups"
                          to={`${URL_USER_GROUP_OVERVIEW}${INIT_OVERVIEW_PAGE}`}
                        />
                      </Grid>
                    )}
                    {showDebtorGroupsPage && (
                      <Grid key="DebtorGroupOverviewPage" item>
                        <CardLink
                          id="debtorGroupOverviewPage"
                          title="Debtor Groups"
                          to={`${URL_DEBTOR_GROUP_OVERVIEW}${INIT_OVERVIEW_PAGE}`}
                        />
                      </Grid>
                    )}
                    {showImportUsersPage && (
                      <Grid key="MappingOverviewPage" item>
                        <CardLink
                          id="mappingOverviewPage"
                          title="Mappings"
                          to={`${URL_MAPPING_OVERVIEW}${INIT_OVERVIEW_PAGE}`}
                        />
                      </Grid>
                    )}
                    {showUserManegementPage && (
                      <Grid key="KeywordSearchPage" item>
                        <CardLink
                          id="keywordSearchPage"
                          title="Search"
                          to={`${URL_KEYWORD_SEARCH_PAGE}`}
                        />
                      </Grid>
                    )}
                    {showUserManegementPage && (
                      <Grid key="missingDebtorsPage" item>
                        <CardLink
                          id="missingDebtorsPage"
                          title="Missing Debtors"
                          to={`${URL_MISSING_DEBTORS_PAGE}`}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {showUserManegementPage && (
            <Grid item className={classes.item}>
              <Card className={classes.appCard} elevation={0}>
                <CardHeader title="Back-office" />
                <CardContent>
                  <Grid
                    item
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid key="users" item>
                      <CardLink
                        id="userManagement"
                        title="User Management"
                        to={URL_USER_MANAGEMENT}
                      />
                    </Grid>
                    <Grid key="documents" item>
                      <CardLink
                        id="documentSchemaManagement"
                        title="Document Schema Management"
                        to={URL_DOCUMENT_SCHEMA_MANAGEMENT_V6}
                      />
                    </Grid>
                    <Grid key="jobs" item>
                      <CardLink
                        id="backgroundJobs"
                        title="Background Jobs"
                        to={URL_DEBTOR_SYNC}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Root>
    );
  }

  return (
    <Redirect
      to={`/${APP_MASTERDATA}/${userAgency?.toLowerCase()}/${PAGE_OVERVIEW}${INIT_QUERY}`}
    />
  );
};

export default HomePage;
