import { styled, alpha, Select as MuiSelect } from "@mui/material";
import { baliHai, dodgerBlue } from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "SearchStyles";
export const classes = {
  grow: `${PREFIX}-grow`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  dropDown: `${PREFIX}-dropDown`,
};

export const Div = styled("div")(({ theme }) => ({
  [`&.${classes.grow}`]: {
    flexGrow: 1,
    display: "inline-block",
  },
  [`&.${classes.search}`]: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
      width: "auto",
    },
  },
  [`&.${classes.searchIcon}`]: {
    width: theme.typography.pxToRem(getThemeSpacing(theme, 9)),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: dodgerBlue,
  },
  [`& .${classes.inputRoot}`]: {
    color: "inherit",
    width: "100%",
  },
  [`& .${classes.inputInput}`]: {
    paddingTop: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    paddingRight: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    paddingBottom: theme.typography.pxToRem(getThemeSpacing(theme, 1)),
    paddingLeft: theme.typography.pxToRem(getThemeSpacing(theme, 10)),
    transition: theme.transitions.create("width"),
    width: "100%",
    color: baliHai,
    [theme.breakpoints.up("sm")]: {
      width: theme.typography.pxToRem(120),
      "&:focus": {
        width: theme.typography.pxToRem(200),
      },
    },
  },
}));

export const Select = styled(MuiSelect)(({ theme }) => ({
  [`&.${classes.dropDown}`]: {
    minWidth: theme.typography.pxToRem(125),
  },
}));
