import {
  styled,
  Dialog as MuiDialog,
  Select as MuiSelect,
} from "@mui/material";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "StatusDialogStyles";
export const classes = {
  textArea: `${PREFIX}-textArea`,
};

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${classes.textArea}`]: {
    marginTop: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
  },
}));

export const Select = styled(MuiSelect)(() => ({}));
