import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import { CheckField } from "core";
import { FormStandardFieldProps } from "../props";

export const FormActivationMBS = ({
  isEdit,
  control,
}: FormStandardFieldProps): ReactElement => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CheckField
          control={control}
          disabled={!isEdit}
          name="activationMBSRadio"
          id="activationMBSRadio"
          label="Activation MBS Radio"
        />
      </Grid>
      <Grid item xs={12}>
        <CheckField
          control={control}
          disabled={!isEdit}
          name="activationMBSTV"
          id="activationMBSTV"
          label="Activation MBS TV"
        />
      </Grid>
    </Grid>
  );
};

export default FormActivationMBS;
