import React, { ReactElement, useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import { Control, FieldValues } from "react-hook-form";

import { DateField, CheckField, Autocomplete, TextField } from "core";

import { ClientDocumentStatus, FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormInventoryMediaProps extends FormStandardFieldProps {
  /**
   * watch
   */
  watch: any;
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormInventoryMedia = ({
  isEdit,
  control,
  errors,
  revisionValues,
  watch,
  fillOption,
  setValue,
}: FormInventoryMediaProps): ReactElement => {
  const {
    state: { cacheData, statusData },
  } = useStateMachine();

  const { enumInventoryAvailable, enumFlex } = cacheData;
  const { previousStatusId, statusId } = statusData;

  const [isActiveProcess] = watch(["isActiveProcess"]);

  const isValidStatusForEnablingFields =
    (statusId === ClientDocumentStatus.WorkInProgress &&
      previousStatusId === ClientDocumentStatus.Decline) ||
    (statusId === ClientDocumentStatus.FinalizedChanged &&
      previousStatusId === ClientDocumentStatus.PendingFinalizedChangeReviewer);

  const areDisabledXaxisOtherFields =
    !isValidStatusForEnablingFields ||
    (!isActiveProcess && isValidStatusForEnablingFields);

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      if (enumFlex[1]) {
        setValue("flexId", enumFlex[1].key);
      }
      setValue("startDateFlex", new Date());
      setValue("additionalConditionsInContract", true);
      setValue(
        "contractReferenceAndOrText",
        "Contract Reference and/or Text - Test"
      );
    }
  }, [fillOption, setValue, enumFlex]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item container direction="column" spacing={1} xs={6}>
          <Grid item xs={6}>
            <Autocomplete
              id="flexId"
              name="flexId"
              label="Flex"
              options={enumFlex}
              error={!!errors.flexId?.message}
              helperText={errors.flexId?.message}
              revisionValue={revisionValues?.FlexId}
              disabled={!isEdit}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              fullWidth
              name="startDateFlex"
              label="Start Date Flex"
              error={!!errors.startDateFlex?.message}
              helperText={errors.startDateFlex?.message}
              revisionValue={revisionValues?.StartDateFlex}
              disabled={!isEdit}
              control={control}
              keyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Flex addendum is effective for three years from start date
        </Typography>
      </Grid>
      {isValidStatusForEnablingFields && (
        <Grid item xs={12}>
          <CheckField
            control={control as unknown as Control<FieldValues>}
            name="isActiveProcess"
            label="Enable Xaxis and Other details"
          />
        </Grid>
      )}
      {isValidStatusForEnablingFields && (
        <Grid item xs={12}>
          <Typography>
            There is a possibility that this document has been declined due to
            one of the greyed-out fields. Please review these fields
            additionally.
          </Typography>
        </Grid>
      )}
      <Grid item xs={6}>
        <CheckField
          control={control}
          disabled={areDisabledXaxisOtherFields}
          name="xaxisInventory"
          label="Xaxis Inventory"
          revisionValue={revisionValues?.XaxisInventory}
        />
      </Grid>
      <Grid item xs={6}>
        <CheckField
          control={control}
          disabled={areDisabledXaxisOtherFields}
          name="otherInventory"
          label="Other Inventory"
          revisionValue={revisionValues?.OtherInventory}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="xaxisInventoryAvailableId"
          name="xaxisInventoryAvailableId"
          label="Xaxis Inventory Available"
          options={enumInventoryAvailable}
          error={!!errors.xaxisInventoryAvailableId?.message}
          helperText={errors.xaxisInventoryAvailableId?.message}
          revisionValue={revisionValues?.XaxisInventoryAvailableId}
          disabled={areDisabledXaxisOtherFields}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="otherInventoryAvailableId"
          name="otherInventoryAvailableId"
          label="Other Inventory Available"
          options={enumInventoryAvailable}
          error={!!errors.otherInventoryAvailableId?.message}
          helperText={errors.otherInventoryAvailableId?.message}
          revisionValue={revisionValues?.OtherInventoryAvailableId}
          disabled={areDisabledXaxisOtherFields}
          control={control}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          fullWidth
          name="xaxisDateSigned"
          label="Xaxis Date Signed"
          error={!!errors.xaxisDateSigned?.message}
          helperText={errors.xaxisDateSigned?.message}
          revisionValue={revisionValues?.XaxisDateSigned}
          disabled={areDisabledXaxisOtherFields}
          control={control}
          keyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          fullWidth
          name="xaxisContractEndDate"
          label="Xaxis Contract End Date"
          error={!!errors.xaxisContractEndDate?.message}
          helperText={errors.xaxisContractEndDate?.message}
          revisionValue={revisionValues?.XaxisContractEndDate}
          disabled={areDisabledXaxisOtherFields}
          control={control}
          keyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          fullWidth
          name="otherDateSigned"
          label="Other Date Signed"
          error={!!errors.otherDateSigned?.message}
          helperText={errors.otherDateSigned?.message}
          revisionValue={revisionValues?.OtherDateSigned}
          disabled={areDisabledXaxisOtherFields}
          control={control}
          keyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <DateField
          fullWidth
          name="otherContractEndDate"
          label="Other Contract End Date"
          error={!!errors.otherContractEndDate?.message}
          helperText={errors.otherContractEndDate?.message}
          revisionValue={revisionValues?.OtherContractEndDate}
          disabled={areDisabledXaxisOtherFields}
          control={control}
          keyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CheckField
          control={control}
          disabled={!isEdit}
          name="additionalConditionsInContract"
          label="Additional Conditions In Contract"
          revisionValue={revisionValues?.AdditionalConditionsInContract}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          control={control}
          disabled={!isEdit}
          name="contractReferenceAndOrText"
          label="Contract Reference and/or Text"
          error={!!errors.contractReferenceAndOrText?.message}
          helperText={errors.contractReferenceAndOrText?.message}
          revisionValue={revisionValues?.ContractReferenceAndOrText}
          multiline
          rows={3}
        />
      </Grid>
    </Grid>
  );
};

export default FormInventoryMedia;
