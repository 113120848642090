import React, { ChangeEvent, ReactElement } from "react";

import { Search as SearchIcon } from "@mui/icons-material";
import {
  FormControl,
  InputBase,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import { classes, Div, Select } from "./styles";

export type SearchOptionType = { key: string; text: string };

export interface SearchProps {
  /**
   * onInputChange callback
   */
  onInputChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  /**
   * onChange callback
   */
  onChange: (event: SelectChangeEvent<any>) => void;
  /**
   * value of search input
   */
  searchTerm: string;
  /**
   * one of search options
   */
  searchProperty: string;
  /**
   * options
   */
  options: SearchOptionType[];
}

export const Search = ({
  onInputChange,
  onChange,
  searchTerm,
  searchProperty,
  options,
}: SearchProps): ReactElement => {
  return (
    <>
      <Div className={classes.grow} />
      <Div className={classes.search}>
        <Div className={classes.searchIcon}>
          <SearchIcon />
        </Div>
        <InputBase
          id="input-search"
          name="input-search"
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchTerm}
          onChange={onInputChange}
        />
      </Div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          id="select-search"
          name="select-search"
          className={classes.dropDown}
          value={searchProperty}
          onChange={onChange}
          inputProps={{
            name: "select-input-search",
            id: "select-input-search",
          }}
        >
          {options.map(({ key, text }) => (
            <MenuItem key={key} id={`menuItem-${key}`} value={key}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Search;
