import { styled } from "@mui/material/styles";

import { drawerOpenWidth, EMPTY_STRING } from "shared/constants";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "LayoutStyles";
export const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
  marginLeft: `${PREFIX}-marginLeft`,
  contentShift: `${PREFIX}-contentShift`,
  appBar: `${PREFIX}-appBar`,
};

export const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },
  [`& .${classes.toolbar}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: Number(theme.spacing(0, 1).replace("px", EMPTY_STRING)),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: getThemeSpacing(theme, 1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: getThemeSpacing(theme, 7) + 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: getThemeSpacing(theme, 9) + 1,
    },
  },
  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerOpenWidth,
  },
  [`& .${classes.appBar}`]: {
    backgroundColor: "#fff",
  },
}));
