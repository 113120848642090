import { EMPTY_STRING } from "shared/constants/client";

export interface FilterOptions {
  id: number;
  property: string;
  text: string;
}

export const sortOptions = [
  { key: EMPTY_STRING, text: "None" },
  { key: "username_asc", text: "By Username  asc" },
  { key: "username", text: "By Username dsc" },
  { key: "firstName_asc", text: "By First Name asc" },
  { key: "firstName", text: "By First Name dsc" },
  { key: "lastName_asc", text: "By Last Name asc" },
  { key: "lastName", text: "By Last Name dsc" },
];

export const dynamicFilterOptions: FilterOptions[] = Object([
  { id: 1, property: "username", text: "By Username" },
  { id: 2, property: "firstName", text: "By First Name" },
  { id: 3, property: "lastName", text: "By Last Name" },
]);

export const defaultRowsPerPageOptions = [10, 25, 50];
