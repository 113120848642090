import React, { ReactElement, useEffect } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import {
  CANCEL_LABEL,
  CREATE_DEFAULT_PRODUCT_DIALOG_STEP_ONE,
  CREATE_DEFAULT_PRODUCT_DIALOG_STEP_THREE,
  CREATE_DEFAULT_PRODUCT_DIALOG_STEP_TWO,
  CREATE_DEFAULT_PRODUCT_DIALOG_TITLE,
  CREATE_DEFAULT_PRODUCT_DIALOG_WARNING,
  CREATE_LABEL,
  OK_LABEL,
  RETRY_LABEL,
} from "shared/constants";
import { LoadingButton } from "@mui/lab";
import { classes, Grid, Typography } from "./styles";

export interface CreateDefaultProductDialogProps extends DialogProps {
  /**
   * Text represents Marathon Client Id
   */
  clientId: string | null;
  /*
   * Callback for canceling dialog
   */
  onCancel: () => void;
  /*
   * Callback for creating Marathon Client
   */
  onClientCreate: () => void;
  /*
   * Callback for creating default product
   */
  onProductCreate: () => void;
  /*
   * Callback for closing dialog
   */
  onCloseDialog: () => void;
  /*
   * Callback for updating IsFirtTry property
   */
  updateFirstTry: (state: boolean) => void;
  /**
   * Flag inidiacting if it is a first attempt to create a client
   */
  isFirstTry: boolean;
  loading: boolean;
  disabled: boolean;
  hasDefaultProduct?: boolean;
}

const steps = ["Create Marathon Client", "Create Default Product", "Finish"];

export const CreateMarathonClientDialog = ({
  open,
  clientId,
  hasDefaultProduct,
  loading,
  disabled,
  isFirstTry,
  onClientCreate,
  onProductCreate,
  onCloseDialog,
  onCancel,
  updateFirstTry,
}: CreateDefaultProductDialogProps): ReactElement => {
  const [activeStep, setActiveStep] = React.useState<number>();
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  useEffect(() => {
    if (hasDefaultProduct) {
      setActiveStep(2);
    } else if (clientId) {
      updateFirstTry(true);
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
  }, [setActiveStep, updateFirstTry, clientId, hasDefaultProduct]);

  const handleNext = () => {
    switch (activeStep) {
      case 0: {
        onClientCreate();
        break;
      }
      case 1: {
        onProductCreate();
        break;
      }
      case 2: {
        onCloseDialog();
        break;
      }
      default:
        break;
    }
  };

  const getDescription = () => {
    switch (activeStep) {
      case 0:
        return CREATE_DEFAULT_PRODUCT_DIALOG_STEP_ONE;
      case 1:
        return CREATE_DEFAULT_PRODUCT_DIALOG_STEP_TWO;
      case 2:
        return CREATE_DEFAULT_PRODUCT_DIALOG_STEP_THREE;
      default:
        return "";
    }
  };

  const getButtonLabel = () => {
    switch (activeStep) {
      case 0:
      case 1:
        return isFirstTry ? CREATE_LABEL : RETRY_LABEL;
      case 2:
        return OK_LABEL;
      default:
        return "";
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle id="dialog-title">
        {CREATE_DEFAULT_PRODUCT_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent id="dialog-content" dividers>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" disabled>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Grid className={classes.description}>
          {getDescription()}
          {disabled && (
            <Typography className={classes.warning}>
              {CREATE_DEFAULT_PRODUCT_DIALOG_WARNING}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6}>
            {(activeStep !== 2 || disabled) && (
              <Button
                onClick={onCancel}
                sx={{ ml: 2 }}
                id="button-cancel-default-product"
              >
                {CANCEL_LABEL}
              </Button>
            )}
          </Grid>
          <Grid item xs={6} container className={classes.button}>
            {!disabled && (
              <LoadingButton
                id="button-next-step"
                onClick={handleNext}
                sx={{ mr: 2 }}
                loading={loading}
              >
                {getButtonLabel()}
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMarathonClientDialog;
