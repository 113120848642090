import { styled, Grid as MuiGrid } from "@mui/material";
import { jordyBlue } from "../../../shared/assets/colors";

const PREFIX = "QuickFiltersStyles";
export const classes = {
  button: `${PREFIX}-button`,
  active: `${PREFIX}-active`,
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.button}`]: {
    textTransform: "none",
    fontWeight: 100,
  },
  [`& .${classes.active}`]: {
    "&.MuiChip-root": {
      backgroundColor: jordyBlue,
    },
  },
}));
