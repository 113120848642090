import { styled, Tooltip as MuiTooltip } from "@mui/material";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "FileChipStyles";
export const classes = {
  chip: `${PREFIX}-chip`,
  confirmation: `${PREFIX}-confirmation`,
  confirmationText: `${PREFIX}-confirmationText`,
  confirmationButtons: `${PREFIX}-confirmationButtons`,
};

export const Tooltip = styled(MuiTooltip)(({ theme }) => ({
  [`& .${classes.chip}`]: {
    maxWidth: 200,
    maxHeight: 30,
    margin: 3,
  },
  [`& .${classes.confirmation}`]: {
    position: "relative",
    zIndex: 1,
    border: "1px solid red",
    borderRadius: 5,
    padding: getThemeSpacing(theme, 1),
    backgroundColor: theme.palette.background.paper,
    maxWidth: 400,
  },
  [`& .${classes.confirmationText}`]: {
    margin: 5,
  },
  [`& .${classes.confirmationButtons}`]: {
    textAlign: "right",
    marginTop: 5,
  },
}));
