import { styled, Grid as MuiGrid } from "@mui/material";
import { white, crusta, jordyBlue, dodgerBlue } from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "ProductDashboardActionsStyles";
export const classes = {
  saveButton: `${PREFIX}-saveButton`,
  approveButton: `${PREFIX}-approveButton`,
  declineButton: `${PREFIX}-declineButton`,
  link: `${PREFIX}-link`,
  disabledButton: `${PREFIX}-disabled`,
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.saveButton}`]: {
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
    color: white,
  },
  [`& .${classes.approveButton}`]: {
    backgroundColor: jordyBlue,
    "&:hover": {
      backgroundColor: dodgerBlue,
    },
  },
  [`& .${classes.declineButton}`]: {
    backgroundColor: crusta,
  },
  [`& .${classes.link}`]: {
    textDecoration: "none",
  },
  [`& .${classes.disabledButton}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
}));
