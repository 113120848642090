import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  attn: null,
  mailingAddress: null,
  postalCode: null,
  city: null,
  country: null,
  invoicingMethodId: null,
  invoiceMailingAddress: [],
  emailAccountancy: null,
};
