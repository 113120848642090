import { styled, TableRow as MuiTableRow } from "@mui/material";

const PREFIX = "TableCheckItemStyles";
export const classes = {
  checkLabel: `${PREFIX}-checkLabel`,
};

export const TableRow = styled(MuiTableRow)(() => ({
  [`& .${classes.checkLabel}`]: {
    lineHeight: 1,
  },
}));
