import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  staffplanRetainer: EMPTY_STRING,
  outOfScopeHours: EMPTY_STRING,
  hoursReportRequired: EMPTY_STRING,
  reportTypeId: null,
  hoursOnly: EMPTY_STRING,
  entries: [],
  hoursYearlyInflation: EMPTY_STRING,
};
