import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select } from "core";

import { MANDATORY_LABEL, EMPTY_STRING, yesNoOptions } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormAllowableDeducationsRebatesProps
  extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;

  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormAllowableDeducationsRebates = ({
  isEdit,
  control,
  errors,
  revisionValues,
  setValue,
  fillOption,
}: FormAllowableDeducationsRebatesProps): ReactElement => {
  const {
    state: { clientDocumentData },
  } = useStateMachine();

  const { isContract } = clientDocumentData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("latePaymentInterest", yesNoOptions[0].key);
      setValue("clientPaysOnTimeForfeits", yesNoOptions[0].key);
      setValue("adminLiftingCosts", yesNoOptions[0].key);
      setValue("againstOldersInvoices", yesNoOptions[0].key);
    }
  }, [fillOption, setValue]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        <Select
          id="latePaymentInterest"
          name="latePaymentInterest"
          label={`Rebates - Late Payment Interest 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.latePaymentInterest?.message}
          helperText={errors.latePaymentInterest?.message}
          revisionValue={revisionValues?.LatePaymentInterest}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={7}>
        <Select
          id="clientPaysOnTimeForfeits"
          name="clientPaysOnTimeForfeits"
          label={`Rebates - Client Pays On Time Forfeits 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.clientPaysOnTimeForfeits?.message}
          helperText={errors.clientPaysOnTimeForfeits?.message}
          revisionValue={revisionValues?.ClientPaysOnTimeForfeits}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={7}>
        <Select
          id="adminLiftingCosts"
          name="adminLiftingCosts"
          label={`Rebates - Admin / Lifting Costs
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.adminLiftingCosts?.message}
          helperText={errors.adminLiftingCosts?.message}
          revisionValue={revisionValues?.AdminLiftingCosts}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={7}>
        <Select
          id="againstOldersInvoices"
          name="againstOldersInvoices"
          label={`Rebates - Against Oldest Invoices
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.againstOldersInvoices?.message}
          helperText={errors.againstOldersInvoices?.message}
          revisionValue={revisionValues?.AgainstOldersInvoices}
          disabled={!isEdit}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
    </Grid>
  );
};

export default FormAllowableDeducationsRebates;
