import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";

import { useStateMachine } from "little-state-machine";
import { Grid, TableBody } from "@mui/material";

import { CircularProgress } from "core";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import { GET_MDT_CHECKS_AND_ATTACHMENTS } from "master-data/constants";

import { TableCheckItem, TableRemarkItem } from "./components";

import { IResponseGet } from "./types";
import { EDIT_MDT_CHECKS_CLAIM } from "./constants";

import { classes, Table } from "./styles";

const { GET } = FetchMethodEnum;

export const MdtChecks = (): ReactElement => {
  const { agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];

  const {
    state: { profile },
  } = useStateMachine();

  const { claims } = profile;
  const isEditable = claims.includes(EDIT_MDT_CHECKS_CLAIM);

  const url = generateUrl(GET_MDT_CHECKS_AND_ATTACHMENTS, {
    agencyId,
    documentId,
  });

  const { data, refetch, isLoading } = useFetch<IResponseGet, null>(url, {
    method: GET,
  });

  const { get: getData } = data ?? {};

  if (isLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress size={65} label="Loading" />
      </Grid>
    );
  }

  return (
    <Table size="small" className={classes.table}>
      <TableBody>
        {getData?.items.map(({ isChecked, ...checkProps }) => (
          <TableCheckItem
            key={checkProps.checkId}
            isInitChecked={isChecked}
            {...checkProps}
            refetch={refetch}
            isEditable={isEditable}
          />
        ))}
        <TableRemarkItem
          key={0}
          remark={getData?.remark}
          onRemarkSaved={refetch}
          isEditable={isEditable}
        />
      </TableBody>
    </Table>
  );
};

export default MdtChecks;
