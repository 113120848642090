import React, { useEffect, ReactElement } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, CheckField, NumericField, Autocomplete } from "core";

import { EMPTY_STRING, MANDATORY_LABEL, yesNoOptions } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormFinancialAvbsProps extends FormStandardFieldProps {
  /**
   * watch
   */
  watch: any;
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormFinancialAvbs = ({
  isEdit,
  control,
  errors,
  revisionValues,
  watch,
  setValue,
  fillOption,
}: FormFinancialAvbsProps): ReactElement => {
  const {
    state: { clientDocumentData, cacheData },
  } = useStateMachine();

  const { isContract } = clientDocumentData;

  const avbsWatch = watch("avbs");
  const isAvbsDisabled = typeof avbsWatch === "number" && avbsWatch === 0;

  const reportedWatch = watch("reportedId");
  const isReportedDisabled =
    typeof reportedWatch === "number" && reportedWatch === 1;
  const { enumReported, enumMonth, enumPassOn } = cacheData;

  // #region Effects
  useEffect(() => {
    if (isAvbsDisabled) {
      setValue("percentagePassedOn", null, { shouldValidate: true });
      setValue("reportedId", null, { shouldValidate: true });
      setValue("additionalReportingMonthId", null, { shouldValidate: true });
      setValue("passOnId", null, { shouldValidate: true });
      setValue("additionalConditionsInContract", false, {
        shouldValidate: true,
      });
    }

    if (isReportedDisabled) {
      setValue("additionalReportingMonthId", null, { shouldValidate: true });
    }
  }, [setValue, avbsWatch, isAvbsDisabled, isReportedDisabled]);

  useEffect(() => {
    if (fillOption) {
      if (isContract) {
        if (
          fillOption === FillFormOptionType.fillAll ||
          fillOption === FillFormOptionType.fillAllAndSave
        ) {
          setValue("avbs", yesNoOptions[1].key);
        } else {
          setValue("avbs", yesNoOptions[0].key);
        }
      } else {
        setValue("avbs", yesNoOptions[1].key);
      }
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("percentagePassedOn", 12);
        setValue("additionalConditionsInContract", true);
        if (enumMonth[0]) {
          setValue("additionalReportingMonthId", enumMonth[0].key);
        }
        if (enumPassOn[0]) {
          setValue("passOnId", enumPassOn[0].key);
        }
        if (enumReported[1]) {
          setValue("reportedId", enumReported[1].key);
        }
      }
    }
  }, [fillOption, setValue, isContract, enumMonth, enumPassOn, enumReported]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          id="avbs"
          name="avbs"
          label={`AVB's Returned 
            ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          error={!!errors.avbs?.message}
          helperText={errors.avbs?.message}
          revisionValue={revisionValues?.Avbs}
          options={yesNoOptions}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="additionalReportingMonthId"
          name="additionalReportingMonthId"
          label="Additional Reporting Month"
          options={enumMonth}
          error={!!errors.additionalReportingMonthId?.message}
          helperText={errors.additionalReportingMonthId?.message}
          revisionValue={revisionValues?.AdditionalReportingMonthId}
          disabled={!isEdit || isAvbsDisabled || isReportedDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="percentagePassedOn"
          name="percentagePassedOn"
          label="Percentage Passed on"
          fullWidth
          disabled={!isEdit || isAvbsDisabled}
          error={!!errors.percentagePassedOn?.message}
          helperText={errors.percentagePassedOn?.message}
          revisionValue={revisionValues?.PercentagePassedOn}
          control={control}
          percentage
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="passOnId"
          name="passOnId"
          label="Pass on"
          options={enumPassOn}
          error={!!errors.passOnId?.message}
          helperText={errors.passOnId?.message}
          revisionValue={revisionValues?.PassOnId}
          disabled={!isEdit || isAvbsDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="reportedId"
          name="reportedId"
          label="Reported (default September consequtive year)"
          error={!!errors.reportedId?.message}
          helperText={errors.reportedId?.message}
          revisionValue={revisionValues?.ReportedId}
          options={enumReported}
          disabled={!isEdit || isAvbsDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <CheckField
          control={control}
          disabled={!isEdit || isAvbsDisabled}
          name="additionalConditionsInContract"
          label="Additional Conditions In Contract"
          revisionValue={revisionValues?.AdditionalConditionsInContract}
        />
      </Grid>
    </Grid>
  );
};

export default FormFinancialAvbs;
