import { styled, Button as MuiButton } from "@mui/material";
import { freeSpeachBlue } from "shared/assets/colors";

const PREFIX = "ClientDocumentStatusLogsDialogStyles";
export const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

export const Button = styled(MuiButton)(() => ({
  [`&.${classes.closeButton}`]: {
    color: freeSpeachBlue,
  },
}));

export const Div = styled("div")(() => ({
  fontSize: "1.5rem",
  display: "flex",
  justifyContent: "center",
}));
