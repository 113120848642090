import React, { ReactElement, useState } from "react";

import { useStateMachine } from "little-state-machine";

import { FormDialog, TableEntries } from "core";

import { FillFormOptionType } from "master-data/enums";

import { RelatedContactsEntry } from "../RelatedContactsEntry";

import { columns } from "./config";
import { FormEntriesFieldProps } from "../props";
import { Current, IRevisionEntriesInput } from "./types";

export interface FormRelatedContactsProps extends FormEntriesFieldProps {
  /**
   * active item
   */
  current: Current;
  /**
   * revisionValuesFormDialog
   */
  revisionValuesFormDialog: IRevisionEntriesInput;
  /**
   * revisionValueIds
   */
  revisionValueIds: string[];
  /**
   * setValues
   */
  setValue: any;
  /**
   * onEdit
   */
  onEdit: (newCurrent: Current) => void;
}

export const FormRelatedContacts = ({
  isEdit,
  errorsFormDialog,
  control,
  current,
  fields,
  onAdd,
  onEdit,
  onDelete,
  onDialogCancel,
  onDialogSave,
  clearErrors,
  isNewEntry,
  revisionValuesFormDialog,
  isDialogOpen,
  revisionValueIds,
  setValue,
}: FormRelatedContactsProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();
  const [dialogFillOption, setDialogFillOption] =
    useState<FillFormOptionType>();
  const { enumAgencyType } = cacheData;

  return (
    <>
      <TableEntries
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        rows={fields}
        isEdit={isEdit}
        control={control}
        columns={columns}
        revisionValueIds={revisionValueIds}
        fieldOptions={{
          agencyTypeId: enumAgencyType,
        }}
      />
      <FormDialog
        isEdit={isEdit}
        isNew={isNewEntry}
        open={isDialogOpen}
        onCancel={onDialogCancel}
        onSave={onDialogSave}
        onClose={() => clearErrors("entries")}
        showFillButton={
          isEdit && process.env.REACT_APP_SHOW_FILL_BUTTON === "true"
        }
        showFillMandatoryOption
        onFill={setDialogFillOption}
      >
        <RelatedContactsEntry
          control={control}
          current={current}
          revisionValues={revisionValuesFormDialog}
          isEdit={isEdit}
          errors={errorsFormDialog}
          setValue={setValue}
          fillOption={dialogFillOption}
          resetFillOption={setDialogFillOption}
          onDialogSave={onDialogSave}
        />
      </FormDialog>
    </>
  );
};

export default FormRelatedContacts;
