import { EMPTY_STRING } from "shared/constants";

export const searchOptions = [
  { key: EMPTY_STRING, text: "None" },
  { key: "lastName", text: "By Last Name" },
  { key: "firstName", text: "By First Name" },
  { key: "username", text: "By Username" },
  { key: "agencyName", text: "By Company" },
  { key: "isAdmin", text: "Is Admin" },
  { key: "isAgent", text: "Is Agent" },
  { key: "roleNames", text: "By Role" },
];
