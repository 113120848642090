import { EMPTY_STRING } from "shared/constants";

export const defaultRowsPerPageOptions = [5, 10, 25];

export const sortOptions = [
  { key: EMPTY_STRING, text: "None" },
  { key: "productName_asc", text: "By product name asc" },
  { key: "productName", text: "By product name dsc" },
  { key: "clientName_asc", text: "By client name  asc" },
  { key: "clientName", text: "By client name dsc" },
  { key: "marathonClientId_asc", text: "By client id  asc" },
  { key: "marathonClientId", text: "By client id dsc" },
];

export interface FilterOptions {
  id: number;
  property: string;
  text: string;
}

export interface FilterQuerys {
  [key: string]: string | undefined;
}

export const dynamicFilterOptions: FilterOptions[] = Object([
  { id: 1, property: "productName", text: "By Product Name" },
  { id: 2, property: "clientName", text: "By Client Name" },
  { id: 3, property: "marathonClientId", text: "By Client Id" },
]);
