export const UPDATE_VERSION_TITLE = "Update version dialog";
export const UPDATE_VERSION_CONTENT =
  "Are you sure you want to update these documents to the new version?";

export const VERSION_2_TEXT_INFO =
  "Added non mandatory field, type checkbox to Financial - Client Incentive form- 'Additional Conditions in Contract'. Requires Reviewer and Approver approval in Edit workflow.";

export const VERSION_2_TEXT =
  "On form Financial-EPD`s, when 'Early Payment Discount' is set to 'No' , all other fields are set to NULL.";

export const VERSION_3_TEXT_INFO =
  "Added non mandatory dropdown fields 'Unbilled Media to be returned after' and 'Unbilled Media to be returned per' to Financial - Unbilled Media form. " +
  "Also added non mandatory dropdown field 'Audit Period' to Contract - Audit form. Requires Reviewer approval in Edit workflow.";

export const VERSION_3_TEXT =
  "For 'Unbilled Media to be returned after' and 'Audit Period' fields when 'Other' option is selected there is a 'Other' text field to fill";

// #region URL
export const APP_BACKOFFICE = "back-office";
export const PAGE_USER_MAPPING = "user-mapping";
export const PAGE_USER_MANAGEMENT = "user-management";
export const PAGE_DEBTOR_SYNC = "job-debtor-sync";
export const PAGE_AUDIT_TRAIL_SYNC = "job-audit-trail-sync";
export const PAGE_DOCUMENT_SCHEMA_MANAGEMENT = "document-schema-management";
export const PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V2 =
  "document-schema-management-v2";
export const PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V4 =
  "document-schema-management-v4";
export const PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V5 =
  "document-schema-management-v5";
export const PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V6 =
  "document-schema-management-v6";
export const URL_USER_MANAGEMENT = `${APP_BACKOFFICE}/${PAGE_USER_MANAGEMENT}`;
export const URL_DEBTOR_SYNC = `${APP_BACKOFFICE}/${PAGE_DEBTOR_SYNC}`;
export const URL_AUDIT_TRAIL_SYNC = `${APP_BACKOFFICE}/${PAGE_AUDIT_TRAIL_SYNC}`;
export const URL_DOCUMENT_SCHEMA_MANAGEMENT = `${APP_BACKOFFICE}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT}`;
export const URL_DOCUMENT_SCHEMA_MANAGEMENT_V2 = `${APP_BACKOFFICE}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V2}`;
export const URL_DOCUMENT_SCHEMA_MANAGEMENT_V4 = `${APP_BACKOFFICE}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V4}`;
export const URL_DOCUMENT_SCHEMA_MANAGEMENT_V5 = `${APP_BACKOFFICE}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V5}`;
export const URL_DOCUMENT_SCHEMA_MANAGEMENT_V6 = `${APP_BACKOFFICE}/${PAGE_DOCUMENT_SCHEMA_MANAGEMENT_V6}`;
// #endregion URL
