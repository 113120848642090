import { QueryType } from "master-data/types";
import { FetchMethodEnum } from "shared/hooks";
import { version } from "shared/version";
import { logOutAndClearStorage } from "./authUtils";

const serverVersionGreaterThan = (
  versionA: string,
  versionB: string
): boolean => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const { GET, POST, PUT, DELETE } = FetchMethodEnum;

const checkVersion = (): void => {
  fetch("/meta.json", { cache: "no-store" })
    .then((response) => response.json())
    .then((meta) => {
      const latestVersion = meta.version;
      const currentVersion = version;

      const shouldForceRefresh = serverVersionGreaterThan(
        latestVersion,
        currentVersion
      );

      if (shouldForceRefresh) {
        // eslint-disable-next-line no-alert
        alert(`We have a new version - ${latestVersion}. Please login again`);

        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches
            .keys()
            .then((names: any) =>
              Promise.all(names.forEach((name: string) => caches.delete(name)))
            );
        }

        // window.location.reload();
        // eslint-disable-next-line no-console
        logOutAndClearStorage();
      }
    });
};

const generateOptions = (
  method: FetchMethodEnum,
  body: unknown,
  token: unknown
): Record<string, unknown> => {
  const options = {
    [GET]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: GET,
    },
    [POST]: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: POST,
      body: JSON.stringify(body),
    },
    [PUT]: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: PUT,
      body: JSON.stringify(body),
    },
    [DELETE]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: DELETE,
      body: JSON.stringify(body),
    },
  };

  return options[method];
};

const generateQuery = (params?: QueryType): string | undefined =>
  params
    ? Object.keys(params)
        .map((key) => {
          const value = params[key];
          if (typeof value === "undefined" || value === null) return undefined;

          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&")
    : undefined;

export {
  checkVersion,
  generateQuery,
  generateOptions,
  serverVersionGreaterThan,
};
