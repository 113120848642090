import React, { ReactElement } from "react";

import { styled } from "@mui/material";

import {
  Edit,
  Cancel,
  MoreHoriz as MuiMoreHoriz,
  CheckCircle,
  PermIdentity,
  CancelOutlined,
  DoneAll as DoneAllIcon,
  Warning as WarningIcon,
  Comment as CommentIcon,
  Autorenew as AutorenewIcon,
  Assignment as AssignmentIcon,
  DonutLarge as DonutLargeIcon,
  RotateRight as RotateRightIcon,
  DoubleArrow as DoubleArrowIcon,
  BeenhereOutlined as BeenhereOutlinedIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  CancelPresentation as CancelPresentationIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  FileDownload as DownloadIcon,
  FileCopy,
  ArchiveOutlined as Archive,
  UnarchiveOutlined as Unarchive,
} from "@mui/icons-material";

import { IconWrapper } from "core";

import { DocumentStatusEnum } from "master-data/enums";
import { DocumentStatusType } from "master-data/types";

import {
  azureRadiance,
  crusta,
  deYork,
  jordyBlue,
  lilac,
  supernova,
  white,
  darkDeYork,
} from "shared/assets/colors";

import {
  GroupM,
  Kinetic,
  EssenceMediacom,
  Mindshare,
  Wavemaker,
  Blossom,
  GreenhouseOne,
  GreenhouseTech,
  ChoreographCreate,
} from "shared/assets/images";

const PREFIX = "iconStyles";
export const classes = {
  circle: `${PREFIX}-circle`,
};

export const MoreHoriz = styled(MuiMoreHoriz)(() => ({
  [`&.${classes.circle}`]: {
    borderRadius: "50%",
  },
}));

interface IconType {
  color: string;
}

export interface AgencyProps {
  agency: number;
}

export const WorkInProgressIcon = (): ReactElement => {
  return (
    <IconWrapper
      width={20}
      height={20}
      color={white}
      icon={MoreHoriz}
      className={classes.circle}
      backgroundColor={jordyBlue}
    />
  );
};

export const ToBeChangedIcon = (): ReactElement => {
  return <IconWrapper icon={RotateRightIcon} color={supernova} />;
};

export const ChangedIcon = (): ReactElement => {
  return <IconWrapper icon={AutorenewIcon} color={supernova} />;
};

export const ReviewedIcon = (): ReactElement => {
  return <IconWrapper icon={AssignmentTurnedInIcon} color={supernova} />;
};

export const FinalizedIcon = (): ReactElement => {
  return <IconWrapper icon={DoneAllIcon} color={darkDeYork} />;
};

export const ApprovedIcon = (): ReactElement => {
  return <IconWrapper icon={CheckCircle} color={deYork} />;
};

export const FinalizedChangedIcon = (): ReactElement => {
  return <IconWrapper icon={AutorenewIcon} color={deYork} />;
};

export const MDTInProgressIcon = (): ReactElement => {
  return <IconWrapper icon={DoubleArrowIcon} color={lilac} />;
};

export const DeclinedIcon = (): ReactElement => {
  return <IconWrapper icon={Cancel} color={crusta} />;
};

export const ProcessDeclineIcon = (): ReactElement => {
  return <IconWrapper icon={CancelOutlined} color={crusta} />;
};

export const ProcessingIcon = (): ReactElement => {
  return <IconWrapper icon={DonutLargeIcon} color={azureRadiance} />;
};

export const PendingApproverApprovalIcon = (): ReactElement => {
  return <IconWrapper icon={BeenhereOutlinedIcon} color={deYork} />;
};

export const ProcessedIcon = (): ReactElement => {
  return <IconWrapper icon={CheckCircle} color={azureRadiance} />;
};

export const MDTCheckedIcon = (): ReactElement => {
  return <IconWrapper icon={CheckCircle} color={lilac} />;
};

export const PendingReviewerApprovalIcon = (): ReactElement => {
  return <IconWrapper icon={BeenhereOutlinedIcon} color={supernova} />;
};

export const PendingValidatorApprovalIcon = (): ReactElement => {
  return <IconWrapper icon={BeenhereOutlinedIcon} color={lilac} />;
};

export const ReadyForReviewIcon = (): ReactElement => {
  return <IconWrapper icon={AssignmentIcon} color={supernova} />;
};

export const RemarkIcon = (): ReactElement => {
  return <IconWrapper icon={CommentIcon} color={supernova} />;
};

export const CancelRectangularIcon = ({ color }: IconType): ReactElement => {
  return <IconWrapper icon={CancelPresentationIcon} color={color} />;
};

export const WarningSignIcon = ({ color }: IconType): ReactElement => {
  return <IconWrapper icon={WarningIcon} color={color} />;
};

export const CheckOutlineIcon = ({ color }: IconType): ReactElement => {
  return <IconWrapper icon={CheckCircleOutlineIcon} color={color} />;
};

export const ProfileIcon = (): ReactElement => {
  return <IconWrapper icon={PermIdentity} color={jordyBlue} />;
};

export const EditIcon = (): ReactElement => {
  return <IconWrapper icon={Edit} color={supernova} />;
};

export const DownloadStatusIcon = (): ReactElement => {
  return <IconWrapper icon={DownloadIcon} color={jordyBlue} />;
};

export const CopyIcon = (): ReactElement => {
  return <IconWrapper icon={FileCopy} color={jordyBlue} />;
};

export const ArchiveIcon = (): ReactElement => {
  return <IconWrapper icon={Archive} color={jordyBlue} />;
};

export const UnarchiveIcon = (): ReactElement => {
  return <IconWrapper icon={Unarchive} color={jordyBlue} />;
};

export const documentStatusIcons: {
  [key in DocumentStatusType]: JSX.Element;
} = {
  [DocumentStatusEnum["Work in Progress"]]: <WorkInProgressIcon />,
  [DocumentStatusEnum.Reviewed]: <ReviewedIcon />,
  [DocumentStatusEnum.Finalized]: <FinalizedIcon />,
  [DocumentStatusEnum["To be Changed"]]: <ToBeChangedIcon />,
  [DocumentStatusEnum.Changed]: <ChangedIcon />,
  [DocumentStatusEnum.Declined]: <DeclinedIcon />,
  [DocumentStatusEnum["Finalized Changed"]]: <FinalizedChangedIcon />,
  [DocumentStatusEnum["MDT in Progress"]]: <MDTInProgressIcon />,
  [DocumentStatusEnum["Processed Decline"]]: <ProcessDeclineIcon />,
  [DocumentStatusEnum.Processing]: <ProcessingIcon />,
  [DocumentStatusEnum.Approved]: <ApprovedIcon />,
  [DocumentStatusEnum["Pending Approver Approval"]]: (
    <PendingApproverApprovalIcon />
  ),
  [DocumentStatusEnum.Processed]: <ProcessedIcon />,
  [DocumentStatusEnum["MDT Checked"]]: <MDTCheckedIcon />,
  [DocumentStatusEnum["Pending Reviewer Approval"]]: (
    <PendingReviewerApprovalIcon />
  ),
  [DocumentStatusEnum["Pending Validator Approval"]]: (
    <PendingValidatorApprovalIcon />
  ),
  [DocumentStatusEnum["Ready for Review"]]: <ReadyForReviewIcon />,
};

export const AgencyIcons = ({ agency }: AgencyProps) => {
  switch (agency) {
    case 1:
      return { image: <img src={GroupM} alt="groupMLogo" />, name: "GroupM" };
    case 2:
      return {
        image: <img src={Mindshare} alt="mindshareLogo" />,
        name: "Mindshare",
      };
    case 5:
      return {
        image: <img src={Kinetic} alt="kineticLogo" />,
        name: "Kinetic",
      };
    case 6:
      return {
        image: <img src={EssenceMediacom} alt="essenceMediacomLogo" />,
        name: "EssenceMediacom",
      };

    case 7:
      return {
        image: <img src={Wavemaker} alt="wavemakerLogo" />,
        name: "Wavemaker",
      };
    case 8:
      return {
        image: <img src={Blossom} alt="blossomLogo" />,
        name: "Blossom",
      };

    case 9:
      return {
        image: <img src={GreenhouseOne} alt="greenHouseOneLogo" />,
        name: "Greenhouse One",
      };
    case 10:
      return {
        image: <img src={GreenhouseTech} alt="greenHouseTechLogo" />,
        name: "Greenhouse Tech",
      };
    case 11:
      return {
        image: (
          <img
            src={ChoreographCreate}
            alt="choreographCreateLogo"
            height={20}
          />
        ),
        name: "Choreograph Create",
      };

    default:
      return {
        image: <img alt="noLogo" />,
        name: "-",
      };
  }
};
