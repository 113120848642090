import { styled, TableRow as MuiTableRow } from "@mui/material";
import {
  sapphire,
  linkWater,
  gainsboro,
  jordyBlue,
  azureRadiance,
} from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils";

const PREFIX = "MappingTableRowStyles";
export const classes = {
  cell: `${PREFIX}-cell`,
  typographyValue: `${PREFIX}-typographyValue`,
  typographyLink: `${PREFIX}-typographyLink`,
  typographyLabel: `${PREFIX}-typographyLabel`,
  typographyLabelLink: `${PREFIX}-typographyLabelLink`,
  hoverText: `${PREFIX}-hoverText`,
  icon: `${PREFIX}-icon`,
};

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  [`& .${classes.cell}`]: {
    border: `1px solid ${gainsboro}`,
  },
  [`& .${classes.typographyValue}`]: {
    color: sapphire,
    textDecoration: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 300,
    whiteSpace: "nowrap",
    marginLeft: 2,
  },
  [`& .${classes.typographyLink}`]: {
    textDecoration: "none",
    marginLeft: 14,
  },
  [`& .${classes.typographyLabelLink}`]: {
    marginBottom: 5,
    marginLeft: -10,
  },
  [`& .${classes.typographyLabel}`]: {
    color: linkWater,
  },
  [`& .${classes.hoverText}`]: {
    "&:hover": {
      color: jordyBlue,
    },
  },
  [`& .${classes.icon}`]: {
    color: azureRadiance,
    fontSize: theme.typography.pxToRem(getThemeSpacing(theme, 2)),
  },
}));
