import { FormStandardAccordionType } from "master-data/types";
import { IFormsInput } from "./types";

import { defaultValues as generalClientInfoDefaultValues } from "./GeneralClientInfo/constants";
import { defaultValues as additionalClientInfoDefaultValues } from "./AdditionalClientInfo/constants";
import { defaultValues as creditInsuranceDefaultValues } from "./CreditInsurance/constants";
import { defaultValues as clientTeamDefaultValues } from "./ClientTeam/constants";
import { defaultValues as generalContractInfoDefaultValues } from "./GeneralContractInfo/constants";
import { defaultValues as financialAvbsDefaultValues } from "./FinancialAvbs/constants";
import { defaultValues as financialEpdsDefaultValues } from "./FinancialEpds/constants";
import { defaultValues as financialUnbilledMediaDefaultValues } from "./FinancialUnbilledMedia/constants";
import { defaultValues as inventoryMediaDefaultValues } from "./InventoryMedia/constants";
import { defaultValues as allowableDeducationsRebatesDefaultValues } from "./AllowableDeducationsRebates/constants";
import { defaultValues as auditDefaultValues } from "./Audit/constants";
import { defaultValues as interestDefaultValues } from "./Interest/constants";
import { defaultValues as admanagementDefaultValues } from "./Admanagement/constants";
import { defaultValues as digitalAccountabilityBrandSafetyDefaultValues } from "./DigitalAccountabilityBrandSafety/constants";
import { defaultValues as evouchingDefaultValues } from "./Evouching/constants";
import { defaultValues as scopeOfServicesDefaultValues } from "./ScopeOfServices/constants";
import { defaultValues as outOfScopeServicesDefaultValues } from "./OutOfScopeServices/constants";
import { defaultValues as invoicingAddressMethodDefaultValues } from "./InvoicingAddressMethod/constants";
import { defaultValues as invoicingRequirementsDefaultValues } from "./InvoicingRequirements/constants";
import { defaultValues as additionalInvoicingRequirementsDefaultValues } from "./AdditionalInvoicingRequirements/constants";
import { defaultValues as hoursAccountabilityDefaultValues } from "./HoursAccountability/constants";
import { defaultValues as commercialAgreementsDefaultValues } from "./CommercialAgreements/constants";
import { defaultValues as revenueRecognitionDefaultValues } from "./RevenueRecogniton/constants";
import { defaultValues as supplierAgreementsDefaultValues } from "./SupplierAgreements/constants";

export const defaults: {
  [key in FormStandardAccordionType]: IFormsInput[key];
} = {
  generalClientInfo: generalClientInfoDefaultValues,
  additionalClientInfo: additionalClientInfoDefaultValues,
  creditInsurance: creditInsuranceDefaultValues,
  clientTeam: clientTeamDefaultValues,
  generalContractInfo: generalContractInfoDefaultValues,
  financialAvbs: financialAvbsDefaultValues,
  financialEpds: financialEpdsDefaultValues,
  financialUnbilledMedia: financialUnbilledMediaDefaultValues,
  inventoryMedia: inventoryMediaDefaultValues,
  allowableDeducationsRebates: allowableDeducationsRebatesDefaultValues,
  audit: auditDefaultValues,
  interest: interestDefaultValues,
  admanagement: admanagementDefaultValues,
  digitalAccountabilityBrandSafety:
    digitalAccountabilityBrandSafetyDefaultValues,
  evouching: evouchingDefaultValues,
  scopeOfServices: scopeOfServicesDefaultValues,
  outOfScopeServices: outOfScopeServicesDefaultValues,
  invoicingAddressMethod: invoicingAddressMethodDefaultValues,
  invoicingRequirements: invoicingRequirementsDefaultValues,
  // invoicingRequirementsV3: invoicingRequirementsDefaultValuesV3,  // Left as a template for future work on old forms
  additionalInvoicingRequirements: additionalInvoicingRequirementsDefaultValues,
  hoursAccountability: hoursAccountabilityDefaultValues,
  commercialAgreements: commercialAgreementsDefaultValues,
  revenueRecognition: revenueRecognitionDefaultValues,
  supplierAgreements: supplierAgreementsDefaultValues,
};
