import React, { ReactElement, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  KEY_ENTER,
  TAG_TEXTAREA,
  UNSAVED_DATA_MESSAGE,
} from "shared/constants";
import { Root, classes } from "product/components/Forms/styles";

import { useStateMachine } from "little-state-machine";
import { setIsFormDirty } from "store/actions";

import { AccordionWrapper } from "../AccordionWrapper";
import ProductInfo from "./ProductInfo";
import InvoicingAddressMethod from "./InvoicingAddressMethod";
import ActivationMBS from "./ActivationMBS";
import BusinessSection from "./BusinessSection";

import { defaults } from "./constants";
import { schemas } from "./config";
import { IFormsInput, IProductInput } from "./types";

export interface ProductFormProps {
  /**
   * Data to populate form.
   */
  formData: IFormsInput;
  /**
   * Is in edit mode
   */
  isInEditing: boolean;
  /**
   * Is request loading
   */
  isLoading: boolean;
  /**
   * Callback fired when form is submitted
   */
  onSave: (submittedData: any) => void;
  /**
   * Response from GET request
   */
  getData: any;
  /**
   * Form data
   */
  putData: any;
  /**
   * Has error
   */
  hasError: boolean;
}

export const ProductForm = ({
  isInEditing,
  formData,
  isLoading,
  onSave,
  getData,
  putData,
  hasError,
}: ProductFormProps): ReactElement => {
  const {
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    watch,
    setValue,
  } = useForm<IProductInput>({
    defaultValues: {
      ...defaults.productInfo,
      ...defaults.invoicingAddressMethod,
      ...defaults.activationMBS,
      ...defaults.businessSection,
    },
    resolver: yupResolver(
      schemas.productInfo
        .concat(schemas.invoicingAddressMethod)
        .concat(schemas.activationMBS)
        .concat(schemas.businessSection)
    ),
  });

  const { actions } = useStateMachine({
    setIsFormDirty,
  });

  // #region Methods
  const onKeyPressInField = (event: React.KeyboardEvent<HTMLElement>) => {
    const tag = event.target as HTMLElement;
    if (event.key === KEY_ENTER && tag.tagName !== TAG_TEXTAREA) {
      event.preventDefault();
    }
  };

  const onSubmit = (submittedData: IProductInput) => {
    onSave(submittedData);
  };
  // #endregion Methods

  // #region Effects
  useEffect(() => {
    if (!isInEditing && !isLoading) {
      reset();
    }
  }, [isInEditing, isLoading, reset]);

  useEffect(() => {
    if (getData || hasError) {
      reset(getData);
    }
  }, [getData, hasError, reset]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(putData);
    }
  }, [isSubmitSuccessful, reset, putData]);

  useEffect(() => {
    actions.setIsFormDirty(isDirty);
  }, [isDirty, actions]);
  // #endregion Effects

  return (
    <>
      <Root
        id="ProductForm"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={onKeyPressInField}
        role="presentation"
        className={classes.form}
      >
        <Grid container spacing={2}>
          <Grid item sm={6} xs={4}>
            <AccordionWrapper>
              <ProductInfo
                {...formData.productInfo}
                isEdit={isInEditing}
                control={control}
                watch={watch}
                setValue={setValue}
                errors={errors}
              />
            </AccordionWrapper>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6}>
            <AccordionWrapper>
              <InvoicingAddressMethod
                {...formData.invoicingAddressMethod}
                getValues={getValues}
                isEdit={isInEditing}
                control={control}
                errors={errors}
              />
            </AccordionWrapper>
          </Grid>
          <Grid item sm={6}>
            <AccordionWrapper>
              <ActivationMBS
                {...formData.activationMBS}
                isEdit={isInEditing}
                control={control}
                errors={errors}
              />
            </AccordionWrapper>
            <AccordionWrapper>
              <BusinessSection
                {...formData.businessSection}
                isEdit={isInEditing}
                control={control}
                errors={errors}
              />
            </AccordionWrapper>
          </Grid>
        </Grid>
      </Root>
      <Prompt when={isDirty} message={UNSAVED_DATA_MESSAGE} />
    </>
  );
};

export default ProductForm;
