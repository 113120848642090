import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { CheckField, NumericField, Autocomplete } from "core";

import { useStateMachine } from "little-state-machine";

import { EMPTY_STRING, MANDATORY_LABEL } from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormFinancialEpdsProps extends FormStandardFieldProps {
  /**
   * watch
   */
  watch: any;
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormFinancialEpds = ({
  isEdit,
  control,
  errors,
  revisionValues,
  watch,
  setValue,
  fillOption,
}: FormFinancialEpdsProps): ReactElement => {
  const {
    state: { clientDocumentData, cacheData },
  } = useStateMachine();

  const { isContract } = clientDocumentData;
  const { enumEarlyPaymentDiscount, enumEpdReceived } = cacheData;

  const epdWatch = watch("earlyPaymentDiscountId");
  const isEpdDisabled = typeof epdWatch === "number" && epdWatch === 1;

  // #region Effects
  useEffect(() => {
    if (isEpdDisabled) {
      setValue("epdReceivedId", null, { shouldValidate: true });
      setValue("percentagePassedOn", null, { shouldValidate: true });
      setValue("epdReceivedPaidWithinNumberOfDays", null, {
        shouldValidate: true,
      });
      setValue("percentagePaidWithinNumberOfDays", null, {
        shouldValidate: true,
      });
      setValue("additionalConditionsInContract", false, {
        shouldValidate: true,
      });
    }
  }, [setValue, epdWatch, isEpdDisabled]);

  useEffect(() => {
    if (fillOption) {
      if (
        fillOption === FillFormOptionType.fillMandatory ||
        fillOption === FillFormOptionType.fillMandatoryAndSave
      ) {
        if (enumEarlyPaymentDiscount[0]) {
          setValue("earlyPaymentDiscountId", enumEarlyPaymentDiscount[0].key);
        }
      } else if (enumEarlyPaymentDiscount[1]) {
        setValue("earlyPaymentDiscountId", enumEarlyPaymentDiscount[1].key);
      }

      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("percentagePassedOn", 10);
        setValue("epdReceivedPaidWithinNumberOfDays", 15);
        setValue("percentagePaidWithinNumberOfDays", 3);
        setValue("additionalConditionsInContract", true);
        if (enumEpdReceived[0]) {
          setValue("epdReceivedId", enumEpdReceived[0].key);
        }
      }
    }
  }, [
    fillOption,
    setValue,
    isContract,
    enumEarlyPaymentDiscount,
    enumEpdReceived,
  ]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="earlyPaymentDiscountId"
          name="earlyPaymentDiscountId"
          label={`
            Early Payment Discount 
              ${isContract ? MANDATORY_LABEL : EMPTY_STRING}`}
          options={enumEarlyPaymentDiscount}
          error={!!errors.earlyPaymentDiscountId?.message}
          helperText={errors.earlyPaymentDiscountId?.message}
          revisionValue={revisionValues?.EarlyPaymentDiscountId}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="epdReceivedId"
          name="epdReceivedId"
          label="EPD Received to be Passed on"
          options={enumEpdReceived}
          error={!!errors.epdReceivedId?.message}
          helperText={errors.epdReceivedId?.message}
          revisionValue={revisionValues?.EpdReceivedId}
          disabled={!isEdit || isEpdDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="percentagePassedOn"
          name="percentagePassedOn"
          label="Percentage Passed on"
          fullWidth
          disabled={!isEdit || isEpdDisabled}
          error={!!errors.percentagePassedOn?.message}
          helperText={errors.percentagePassedOn?.message}
          revisionValue={revisionValues?.PercentagePassedOn}
          control={control}
          percentage
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="epdReceivedPaidWithinNumberOfDays"
          name="epdReceivedPaidWithinNumberOfDays"
          label="Paid Within Number of Days"
          fullWidth
          disabled={!isEdit || isEpdDisabled}
          error={!!errors.epdReceivedPaidWithinNumberOfDays?.message}
          helperText={errors.epdReceivedPaidWithinNumberOfDays?.message}
          revisionValue={revisionValues?.EpdReceivedPaidWithinNumberOfDays}
          control={control}
          integer
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="percentagePaidWithinNumberOfDays"
          name="percentagePaidWithinNumberOfDays"
          label="Paid Within Number of Days"
          fullWidth
          disabled={!isEdit || isEpdDisabled}
          error={!!errors.percentagePaidWithinNumberOfDays?.message}
          helperText={errors.percentagePaidWithinNumberOfDays?.message}
          revisionValue={revisionValues?.PercentagePaidWithinNumberOfDays}
          control={control}
          integer
        />
      </Grid>
      <Grid item xs={12}>
        <CheckField
          control={control}
          disabled={!isEdit || isEpdDisabled}
          name="additionalConditionsInContract"
          label="Additional Conditions In Contract"
          revisionValue={revisionValues?.AdditionalConditionsInContract}
        />
      </Grid>
    </Grid>
  );
};

export default FormFinancialEpds;
