import { styled, Grid as MuiGrid } from "@mui/material";
import {
  white,
  crusta,
  supernova,
  jordyBlue,
  dodgerBlue,
  darkSupernova,
  softRed,
} from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "ClientDocumentDashboardActionsStyles";
export const classes = {
  saveButton: `${PREFIX}-saveButton`,
  approveButton: `${PREFIX}-approveButton`,
  declineButton: `${PREFIX}-declineButton`,
  validateButton: `${PREFIX}-validateButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  link: `${PREFIX}-link`,
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`& .${classes.saveButton}`]: {
    marginRight: theme.typography.pxToRem(getThemeSpacing(theme, 3)),
    color: white,
  },
  [`& .${classes.approveButton}`]: {
    backgroundColor: jordyBlue,
    "&:hover": {
      backgroundColor: dodgerBlue,
    },
  },
  [`& .${classes.declineButton}`]: {
    backgroundColor: crusta,
  },
  [`& .${classes.validateButton}`]: {
    backgroundColor: supernova,
    "&:hover": {
      backgroundColor: darkSupernova,
    },
  },
  [`& .${classes.deleteButton}`]: {
    backgroundColor: softRed,
    "&:hover": {
      backgroundColor: crusta,
    },
  },
  [`& .${classes.link}`]: {
    textDecoration: "none",
  },
}));
