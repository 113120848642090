import React, { MouseEventHandler, ReactElement } from "react";

import { Create as CreateIcon } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";

import {
  CancelRectangularIcon,
  CheckOutlineIcon,
  WarningSignIcon,
} from "master-data/components/icons";
import { ValidationStatusEnum } from "master-data/enums";
import { darkDeYork, softRed, supernova } from "shared/assets/colors";

import { classes, Grid } from "./styles";

export interface AccordionSummaryProps {
  title: string;
  type?: string;
  isEdit?: boolean;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  isButton?: boolean;
  validationStatus?: ValidationStatusEnum;
}

const validationStatusIcon = {
  [ValidationStatusEnum.valid]: <CheckOutlineIcon color={darkDeYork} />,
  [ValidationStatusEnum.invalidMandatory]: (
    <WarningSignIcon color={supernova} />
  ),
  [ValidationStatusEnum.invalid]: <CancelRectangularIcon color={softRed} />,
  [ValidationStatusEnum.notCreated]: null,
  [ValidationStatusEnum.notUsed]: null,
};

export const AccordionSummary = ({
  title,
  type,
  isEdit = true,
  onEdit,
  isButton = true,
  validationStatus = ValidationStatusEnum.notCreated,
}: AccordionSummaryProps): ReactElement => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        {isButton ? (
          <Button
            id={`${type}-edit`}
            disabled={!isEdit}
            variant="text"
            color="secondary"
            className={classes.buttonEdit}
            startIcon={<CreateIcon />}
            onClick={onEdit}
          >
            <Typography className={classes.heading}>{title}</Typography>
          </Button>
        ) : (
          <Typography className={classes.heading}>{title}</Typography>
        )}
      </Grid>
      <Grid item className={classes.item}>
        {validationStatusIcon[validationStatus]}
      </Grid>
    </Grid>
  );
};

export default AccordionSummary;
