import { GlobalState } from "little-state-machine";

import { ProfileData } from "../types";
import { defaultProfileData } from "../constants";

export function setProfile(
  state: GlobalState,
  payload: ProfileData
): GlobalState {
  return {
    ...state,
    profile: {
      ...state.profile,
      ...payload,
    },
  };
}

export function resetProfile(state: GlobalState): GlobalState {
  return {
    ...state,
    profile: defaultProfileData,
  };
}

export function setUserAgency(
  state: GlobalState,
  payload: string
): GlobalState {
  return {
    ...state,
    profile: {
      ...state.profile,
      userAgency: payload,
    },
  };
}

export function setAgentForAgencies(
  state: GlobalState,
  payload: string[]
): GlobalState {
  return {
    ...state,
    profile: {
      ...state.profile,
      agentForAgencies: payload,
    },
  };
}
