import { ProductStatusEnum } from "product/enums";
import { ProductStatusType } from "product/types";

export interface Status {
  statusId: number;
  description: ProductStatusType;
}

export const statuses: Status[] = [
  { statusId: 1, description: ProductStatusEnum["Work in Progress"] },
  { statusId: 2, description: ProductStatusEnum["Ready for Processing"] },
  { statusId: 3, description: ProductStatusEnum.Processing },
  { statusId: 4, description: ProductStatusEnum.Finalized },
];
