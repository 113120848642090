import { styled } from "@mui/material";

const PREFIX = "FormStandardStyles";
export const classes = {
  form: `${PREFIX}-form`,
};

export const Root = styled("form")(() => ({
  [`& .${classes.form}`]: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
}));
