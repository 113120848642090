import { styled, Grid as MuiGrid } from "@mui/material";
import { sapphire, jordyBlue, dodgerBlue } from "shared/assets/colors";

const PREFIX = "SummaryStyles";
export const classes = {
  heading: `${PREFIX}-heading`,
  buttonEdit: `${PREFIX}-buttonEdit`,
  item: `${PREFIX}-item`,
};

export const Grid = styled(MuiGrid)(({ theme }) => ({
  [`&.${classes.item}`]: {
    paddingRight: 10,
  },
  [`& .${classes.heading}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    color: sapphire,
  },
  [`& .${classes.buttonEdit}`]: {
    textTransform: "capitalize",
    color: jordyBlue,
    "&:hover": {
      color: dodgerBlue,
    },
  },
}));
