import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { Select, TextField, NumericField, Autocomplete } from "core";

import {
  CLAIM_EDIT_CREATOR_FIELD,
  CLAIM_EDIT_VALIDATOR_FIELD,
} from "master-data/constants";
import { yesNoOptions } from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormCreditInsuranceProps extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormCreditInsurance = ({
  isEdit,
  control,
  errors,
  revisionValues,
  setValue,
  fillOption,
}: FormCreditInsuranceProps): ReactElement => {
  const {
    state: { profile, cacheData, clientDocumentData },
  } = useStateMachine();

  const { claims } = profile;
  const { isDocumentEditable } = clientDocumentData;

  const isValidatorFieldDisabled = !claims?.includes(
    CLAIM_EDIT_VALIDATOR_FIELD
  );
  const isCreatorFieldDisabled = !(
    claims?.includes(CLAIM_EDIT_CREATOR_FIELD) && isDocumentEditable
  );

  const { enumAlternativeCreditInsuranceType } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("creditInsurance", yesNoOptions[1].key);
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("creditInsuranceAmount", 12000);
        setValue("shareLimit", yesNoOptions[0].key);
        setValue("creditInsuranceNumber", 155);
        if (enumAlternativeCreditInsuranceType[0]) {
          setValue(
            "alternativeCreditInsuranceId",
            enumAlternativeCreditInsuranceType[0].key
          );
        }
      }
    }
  }, [fillOption, setValue, enumAlternativeCreditInsuranceType]);
  // #endregion Effects

  return (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item xs={6}>
        <Select
          id="creditInsurance"
          name="creditInsurance"
          label="Credit Insurance *"
          error={!!errors.creditInsurance?.message}
          helperText={errors.creditInsurance?.message}
          revisionValue={revisionValues?.CreditInsurance}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="creditInsuranceAmount"
          name="creditInsuranceAmount"
          label="Credit Insurance Amount (€)"
          fullWidth
          disabled={!isEdit || isValidatorFieldDisabled}
          error={!!errors.creditInsuranceAmount?.message}
          helperText={errors.creditInsuranceAmount?.message}
          revisionValue={revisionValues?.CreditInsuranceAmount}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          options={enumAlternativeCreditInsuranceType}
          id="alternativeCreditInsuranceId"
          name="alternativeCreditInsuranceId"
          label="Alternative Credit Insurance"
          error={!!errors.alternativeCreditInsuranceId?.message}
          helperText={errors.alternativeCreditInsuranceId?.message}
          revisionValue={revisionValues?.AlternativeCreditInsuranceId}
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="shareLimit"
          name="shareLimit"
          label="Shared Limit"
          error={!!errors.shareLimit?.message}
          helperText={errors.shareLimit?.message}
          revisionValue={revisionValues?.ShareLimit}
          disabled={!isEdit || isValidatorFieldDisabled}
          control={control}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Credit Insurance Number"
          id="creditInsuranceNumber"
          name="creditInsuranceNumber"
          error={!!errors.creditInsuranceNumber?.message}
          helperText={errors.creditInsuranceNumber?.message}
          revisionValue={revisionValues?.CreditInsuranceNumber}
          disabled={!isEdit || isValidatorFieldDisabled}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default FormCreditInsurance;
