import {
  FormEntriesAccordionType,
  FormStandardAccordionType,
} from "master-data/types";

export const BASE_URL = `api/master-data/agency/:agencyId`;

export const GET_USER_PROFILE = `${BASE_URL}/user/profile`;
export const MANAGE_USER_PROFILE_SETTINGS = `${BASE_URL}/user/:userId/profile-settings`;

// #region Document
export const CREATE_CLIENT_DOCUMENT = `${BASE_URL}/client-document`;
export const EDIT_CLIENT_DOCUMENT_META_DATA = `${BASE_URL}/client-document/:documentId/meta-data`;
export const GET_CONCERNS = `${BASE_URL}/client-document/concern`;
export const GET_DOCUMENTS = `${BASE_URL}/client-document`;
export const GET_DOCUMENTS_ACTION_OVERVIEW = `${BASE_URL}/client-document/action-overview`;
export const MANAGE_CLIENT_DOCUMENT = `${BASE_URL}/client-document/:documentId`;
export const ARCHIVE_CLIENT_DOCUMENT = `${BASE_URL}/client-document/:documentId/archive`;
export const UNARCHIVE_CLIENT_DOCUMENT = `${BASE_URL}/client-document/:documentId/unarchive`;
export const VALIDATE_DOCUMENT = `${BASE_URL}/client-document/:documentId/validate`;
export const CHANGE_CREATE_DOCUMENT_STATUS = `${BASE_URL}/client-document/:documentId/create/change-status`;
export const CHANGE_EDIT_DOCUMENT_STATUS = `${BASE_URL}/client-document/:documentId/edit/change-status`;
export const GET_OVERVIEW_STATUSES = `${BASE_URL}/client-document/statuses/overview`;
export const GET_ACTION_OVERVIEW_STATUSES = `${BASE_URL}/client-document/statuses/action-overview`;
export const GET_DECLINE_COMMENTS = `${BASE_URL}/client-document/:documentId/comment`;
export const GET_REVISION_CONTENT = `${BASE_URL}/client-document/:documentId/changes`;
export const GET_STATUS_LOGS = `${BASE_URL}/client-document/:documentId/status`;
export const GET_REVIEWERS = `${BASE_URL}/client-document/:documentId/reviewers`;
// #endregion Document

// #region MarathonClient
export const CREATE_MARATHON_CLIENT = `${BASE_URL}/marathon-client/:documentId`;
// #endregion MarathonClient

export const GET_FORM = `${BASE_URL}/client-document/:documentId/accordion/:accordionId`;

// #region Client section accordions
export const MANAGE_GENERAL_CLIENT_INFO = `${BASE_URL}/client-document/:documentId/general-client-info`;
export const MANAGE_ADDITIONAL_CLIENT_INFO = `${BASE_URL}/client-document/:documentId/additional-client-info`;
export const MANAGE_CREDIT_INSURANCE = `${BASE_URL}/client-document/:documentId/credit-insurance`;
export const MANAGE_CLIENT_TEAM_INFO = `${BASE_URL}/client-document/:documentId/client-team`;
export const MANAGE_CLIENT_CONTACT = `${BASE_URL}/client-document/:documentId/client-contacts`;
export const MANAGE_RELATED_CONTACTS = `${BASE_URL}/client-document/:documentId/related-contacts`;
export const MANAGE_SUPPLIER_AGREEMENTS = `${BASE_URL}/client-document/:documentId/supplier-agreements`;
// #endregion Client section accordions

// #region Contract section accordions
export const MANAGE_GENERAL_CONTRACT_INFO = `${BASE_URL}/client-document/:documentId/general-contract-info`;
export const MANAGE_FINANCIAL_AVBS = `${BASE_URL}/client-document/:documentId/financial-avb`;
export const MANAGE_FINANCIAL_EPDS = `${BASE_URL}/client-document/:documentId/financial-epd`;
export const MANAGE_FINANCIAL_UNBILLED_MEDIA = `${BASE_URL}/client-document/:documentId/financial-unbilled-media`;
export const MANAGE_FINANCIAL_CLIENT_INCENTIVE = `${BASE_URL}/client-document/:documentId/financial-client-incentive`;
export const MANAGE_INVENTORY_MEDIA = `${BASE_URL}/client-document/:documentId/inventory-media`;
export const MANAGE_ALLOWABLE_DEDUCTIONS_REBATES = `${BASE_URL}/client-document/:documentId/allowable-deductions-rebates`;
export const MANAGE_AUDIT = `${BASE_URL}/client-document/:documentId/audit`;
export const MANAGE_INTEREST = `${BASE_URL}/client-document/:documentId/interest`;
export const MANAGE_ADMANAGEMENT = `${BASE_URL}/client-document/:documentId/admanagement`;
export const MANAGE_DABS = `${BASE_URL}/client-document/:documentId/digital-accountability-brand-safety`;
export const MANAGE_EVOUCHING = `${BASE_URL}/client-document/:documentId/evouching`;
export const MANAGE_HOURS = `${BASE_URL}/client-document/:documentId/hours`;
export const MANAGE_REMUNERATION_CONTRACT_LINE = `${BASE_URL}/client-document/:documentId/remuneration-contract-line`;
export const MANAGE_REVENUE_RECOGNITION = `${BASE_URL}/client-document/:documentId/revenue-recognition`;
export const MANAGE_SCOPE_OF_SERVICES = `${BASE_URL}/client-document/:documentId/scope-of-services`;
export const MANAGE_OUT_OF_SCOPE_SERVICES = `${BASE_URL}/client-document/:documentId/out-of-scope-services`;
// #endregion Contract section accordions

// #region Invoicing
export const MANAGE_INVOICING_ADDRESS_METHOD = `${BASE_URL}/client-document/:documentId/invoicing-address-method`;
export const MANAGE_INVOICING_REQUIREMENTS = `${BASE_URL}/client-document/:documentId/invoicing-requirements`;
export const MANAGE_ADDITIONAL_INVOICING_REQUIREMENTS = `${BASE_URL}/client-document/:documentId/additional-invoicing-requirements`;
// #endregion Invoicing

// #region Various Commercial Agreements
export const MANAGE_HOURS_ACCOUNTABILITY = `${BASE_URL}/client-document/:documentId/hours-accountability`;
export const MANAGE_COMMERCIAL_AGREEMENTS = `${BASE_URL}/client-document/:documentId/commercial-agreements`;
export const MANAGE_SOX_COMPLIANT_CLIENT_APPROVAL = `${BASE_URL}/client-document/:documentId/sox-compliant-client-approval`;
// #endregion Various Commercial Agreements

// #region Checks
export const GET_MDT_CHECKS_AND_ATTACHMENTS = `${BASE_URL}/client-document/:documentId/mdtcheck`;
export const EDIT_MDT_CHECK = `${BASE_URL}/client-document/:documentId/mdtcheck/:checkId`;
export const EDIT_MDT_CHECK_REMARK = `${BASE_URL}/client-document/:documentId/mdtcheck/remark`;
export const UPLOAD_MDT_CHECK_ATTACHMENT = `${BASE_URL}/client-document/:documentId/mdtcheck/:checkId/upload`;
export const DELETE_MDT_CHECK_ATTACHMENT = `${BASE_URL}/client-document/:documentId/mdtcheck/:checkId/delete/:fileId`;
export const DOWNLOAD_MDT_CHECK_ATTACHMENT = `${BASE_URL}/client-document/:documentId/mdtcheck/:checkId/download/:fileId`;
// #endregion Checks

// #region Attachments
export const GET_LEGAL_DOCUMENTS_CHECKS_AND_ATTACHMENTS = `${BASE_URL}/client-document/:documentId/legaldocumentscheck`;
export const UPLOAD_LEGAL_DOCUMENTS_CHECK_ATTACHMENT = `${BASE_URL}/client-document/:documentId/legaldocumentscheck/:checkId/upload`;
export const DELETE_LEGAL_DOCUMENTS_CHECK_ATTACHMENT = `${BASE_URL}/client-document/:documentId/legaldocumentscheck/:checkId/delete/:fileId`;
export const DOWNLOAD_LEGAL_DOCUMENTS_CHECK_ATTACHMENT = `${BASE_URL}/client-document/:documentId/legaldocumentscheck/:checkId/download/:fileId`;
// #endregion Attachments

// #region Product
export const CREATE_DEFAULT_PRODUCT = `api/product/agency/:agencyId/default-product`;
// #endregion Product

export const MANAGE_STANDARD_FORM: {
  [key in FormStandardAccordionType]: string;
} = {
  additionalClientInfo: MANAGE_ADDITIONAL_CLIENT_INFO,
  additionalInvoicingRequirements: MANAGE_ADDITIONAL_INVOICING_REQUIREMENTS,
  admanagement: MANAGE_ADMANAGEMENT,
  allowableDeducationsRebates: MANAGE_ALLOWABLE_DEDUCTIONS_REBATES,
  audit: MANAGE_AUDIT,
  clientTeam: MANAGE_CLIENT_TEAM_INFO,
  commercialAgreements: MANAGE_COMMERCIAL_AGREEMENTS,
  creditInsurance: MANAGE_CREDIT_INSURANCE,
  digitalAccountabilityBrandSafety: MANAGE_DABS,
  evouching: MANAGE_EVOUCHING,
  financialAvbs: MANAGE_FINANCIAL_AVBS,
  financialEpds: MANAGE_FINANCIAL_EPDS,
  financialUnbilledMedia: MANAGE_FINANCIAL_UNBILLED_MEDIA,
  generalClientInfo: MANAGE_GENERAL_CLIENT_INFO,
  generalContractInfo: MANAGE_GENERAL_CONTRACT_INFO,
  hoursAccountability: MANAGE_HOURS_ACCOUNTABILITY,
  interest: MANAGE_INTEREST,
  inventoryMedia: MANAGE_INVENTORY_MEDIA,
  invoicingAddressMethod: MANAGE_INVOICING_ADDRESS_METHOD,
  invoicingRequirements: MANAGE_INVOICING_REQUIREMENTS,
  outOfScopeServices: MANAGE_OUT_OF_SCOPE_SERVICES,
  revenueRecognition: MANAGE_REVENUE_RECOGNITION,
  scopeOfServices: MANAGE_SCOPE_OF_SERVICES,
  supplierAgreements: MANAGE_SUPPLIER_AGREEMENTS,
};

export const MANAGE_ENTRIES_FORM: {
  [key in FormEntriesAccordionType]: string;
} = {
  clientContacts: MANAGE_CLIENT_CONTACT,
  financialClientIncentive: MANAGE_FINANCIAL_CLIENT_INCENTIVE,
  hours: MANAGE_HOURS,
  hoursV5: MANAGE_HOURS,
  relatedContacts: MANAGE_RELATED_CONTACTS,
  remunerationContractLine: MANAGE_REMUNERATION_CONTRACT_LINE,
  soxCompliantClientApproval: MANAGE_SOX_COMPLIANT_CLIENT_APPROVAL,
};
