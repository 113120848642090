import { useSnackbar } from "notistack";
import { ALLOWED_EXTENSIONS_LIST } from "shared/constants";
import { FetchMethodEnum } from "shared/enums";
import { useFetch } from "./useFetch";

const { POST } = FetchMethodEnum;

export const useFileUploader = (): {
  uploadFile: (
    file: File,
    uploadUrl: string,
    onUploadCallback?: () => void,
    maxSizeMb?: number,
    allowedExtensions?: string[]
  ) => Promise<boolean | undefined>;
  isLoading: boolean;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const { executeFetch, isLoading } = useFetch(null, { skip: true });

  const fileTypeValidation = (allowedExtensions: string[], type?: string) => {
    return type && allowedExtensions.includes(type);
  };

  const toastInvalidTypeMessage = () => {
    enqueueSnackbar("Can not upload invalid file type", {
      variant: "error",
    });
  };

  const toastInvalidSizeMessage = (maxSizeMb: number) => {
    enqueueSnackbar(
      `File size must be less or equal to ${maxSizeMb} megabytes`,
      {
        variant: "error",
      }
    );
  };

  const uploadFile = async (
    file: File,
    uploadUrl: string,
    onUploadCallback?: () => void,
    maxSizeMb = 25,
    allowedExtensions = ALLOWED_EXTENSIONS_LIST
  ) => {
    const fileExtension = file.name.split(".").pop();
    const isValidType = fileTypeValidation(allowedExtensions, fileExtension);

    if (!isValidType) {
      toastInvalidTypeMessage();
      return Promise.resolve(false);
    }

    const fileSizeMb = file.size / 1024 / 1024;

    if (fileSizeMb > maxSizeMb) {
      toastInvalidSizeMessage(maxSizeMb);
      return Promise.resolve(false);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const fileInfo = {
        fileName: file.name,
        mimeType:
          fileExtension === "msg" ? "application/vnd.ms-outlook" : file.type,
        fileSize: fileSizeMb,
        fileBase64Content: (reader?.result as string)?.split(",")[1],
      };

      await executeFetch(uploadUrl, {
        method: POST,
        body: fileInfo,
        skip: false,
      });

      if (typeof onUploadCallback === "function") {
        onUploadCallback();
      }
    };

    return Promise.resolve(true);
  };

  return { uploadFile, isLoading };
};

export default useFileUploader;
