import { number, object, string } from "yup";

import { nanToNull } from "shared/utils";
import {
  MAX_NUMBER_FOR_INPUTS,
  MIN_NUMBER_FOR_INPUTS,
  MAX_NUMBER_VALIDATION_MESSAGE,
  MIN_NUMBER_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";

export const validationSchema = object().shape({
  calculateInterestId: number()
    .nullable()
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .transform(nanToNull),
  fixedDeviatingPercentage: number()
    .when("calculateInterestId", {
      is: 3,
      then: number()
        .nullable()
        .required(MANDATORY_FILED_VALIDATION_MESSAGE)
        .transform(nanToNull),
      otherwise: number().nullable().notRequired().transform(nanToNull),
    })
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MIN_NUMBER_VALIDATION_MESSAGE),
  bankNameId: number().when("calculateInterestId", {
    is: (value: number) => value === 1 || value === 3,
    then: number()
      .nullable()
      .required(MANDATORY_FILED_VALIDATION_MESSAGE)
      .transform(nanToNull),
    otherwise: number().nullable().transform(nanToNull),
  }),
  otherBankName: string()
    .trim()
    .when("bankNameId", {
      is: 5,
      then: string()
        .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
        .required(MANDATORY_FILED_VALIDATION_MESSAGE),
      otherwise: string()
        .nullable()
        .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
    }),
  bankStoragePercentage: string()
    .trim()
    .nullable()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
});
