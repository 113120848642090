import { styled, Button as MuiButton } from "@mui/material";
import { jordyBlue, black } from "shared/assets/colors";

const PREFIX = "UserGroupFormStyles";
export const classes = {
  fillButton: `${PREFIX}-fillButton`,
};

export const Button = styled(MuiButton)(() => ({
  [`&.${classes.fillButton}`]: {
    padding: 0,
    color: black,
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    textTransform: "none",
    fontSize: "1rem",
  },
}));
