import React, { ReactElement, useEffect } from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from "@hookform/devtools";
import { Prompt, useParams } from "react-router-dom";
import { useStateMachine } from "little-state-machine";

import {
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Autocomplete,
  AutocompleteRenderInputParams,
} from "@mui/material";
import { History as HistoryIcon } from "@mui/icons-material";

import { OptionFetcher, TextField, Select, InfoPopper } from "core";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { AgencyEnum, ClientDocumentEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import {
  useForm,
  Control,
  FieldValues,
  Controller,
  FieldError,
} from "react-hook-form";
import { FormOtherAccordionType, IDocumentFormInput } from "master-data/types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  setIsContract,
  setValidationStatus,
  setIsDocumentStatusChanged,
} from "store/actions";
import {
  CLAIM_EDIT_CREATOR_FIELD,
  MANAGE_CLIENT_DOCUMENT,
  GET_CONCERNS,
  CLAIM_EDIT_VALIDATOR_FIELD,
  CREATE_CLIENT_DOCUMENT,
} from "master-data/constants";
import {
  EMPTY_STRING,
  KEY_ENTER,
  UNSAVED_DATA_MESSAGE,
} from "shared/constants";
import { isEditableForValidator } from "master-data/utils";

import { filter, getRevisionValues, validationSchema } from "./config";
import { defaultValues } from "./constants";
import { IResponseGet, IResponsePost } from "./types";

import { classes, Root } from "./styles";

const { GET, PUT } = FetchMethodEnum;

export interface FormClientDocumentProps {
  /**
   * Accordion type of Other Form
   */
  type: FormOtherAccordionType;
  /**
   * Flag indicating if form is in edit mode
   */
  isEdit: boolean;
  /**
   * Callback fired when form is submitted
   */
  onSave: () => void;
}

export const ClientDocument = ({
  type,
  isEdit,
  onSave,
}: FormClientDocumentProps): ReactElement => {
  const [open, setOpen] = React.useState(false);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IDocumentFormInput>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    state: { profile, cacheData, clientDocumentData },
    actions,
  } = useStateMachine({
    setIsContract,
    setValidationStatus,
    setIsDocumentStatusChanged,
  });

  const anchorRef = React.useRef(null);

  const { changes, marathonClientId } = clientDocumentData;
  const revisionValues = getRevisionValues(changes);

  const { agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];
  const url = generateUrl(MANAGE_CLIENT_DOCUMENT, {
    agencyId,
    documentId,
  });

  const { data, executeFetch, resetPostData } = useFetch<
    IResponseGet,
    IResponsePost
  >(url, {
    method: GET,
  });

  const { get: getData, post: postData } = data ?? {};
  const { isDocumentEditable, status, documentTypeId } = getData ?? {};

  const { claims } = profile;

  const isCreator = claims.includes(CLAIM_EDIT_CREATOR_FIELD);
  const isCreatorFieldDisabled = !(isCreator && (isDocumentEditable ?? true));

  const isValidator = claims.includes(CLAIM_EDIT_VALIDATOR_FIELD);
  const isAdmin = isCreator && isValidator;
  const statusId = status?.statusId ?? null;
  const isValidatorFieldDisabled = isAdmin
    ? !(
        isValidator &&
        (isDocumentEditable || isEditableForValidator(statusId, undefined))
      )
    : !isValidator;

  const GET_CONCERNS_URL = generateUrl(GET_CONCERNS, { agencyId });

  const isSupplierClientsType =
    documentTypeId === ClientDocumentEnum.supplierClients;
  const { enumClientDocumentType } = cacheData;

  const EDIT_CLIENT_URL = generateUrl(MANAGE_CLIENT_DOCUMENT, {
    agencyId,
    documentId,
  });

  useEffect(() => {
    if (getData) {
      reset(getData);
    }
  }, [getData, reset]);

  useEffect(() => {
    if (!isEdit) {
      reset();
    }
  }, [isEdit, reset]);

  useEffect(() => {
    const { isSuccessful, ...restData } = postData ?? {};

    const setDocumentTypeState = () => {
      const { documentTypeId: dtId } = postData ?? {};
      if (dtId === ClientDocumentEnum.contract) {
        actions.setIsContract(dtId === ClientDocumentEnum.contract);
      }
    };

    const refreshHeader = () => {
      actions.setIsDocumentStatusChanged(true);
    };

    const clearValidationStatusState = () => {
      actions.setValidationStatus(null);
    };

    if (isSuccessful) {
      reset(restData);
      resetPostData();
      onSave();
      refreshHeader();
      setDocumentTypeState();
      clearValidationStatusState();
    }
  }, [actions, onSave, postData, reset, resetPostData]);

  const onSaveForm = (formData: IDocumentFormInput) => {
    executeFetch(EDIT_CLIENT_URL, {
      method: PUT,
      body: { ...formData, documentId },
      skip: false,
    });
  };

  const onKeyPressInField = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === KEY_ENTER) {
      event.preventDefault();
    }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      {process.env.NODE_ENV !== "production" && (
        <DevTool control={control} placement="top-left" />
      )}
      <Root
        id={`${type}Form`}
        role="presentation"
        onKeyDown={onKeyPressInField}
        onSubmit={handleSubmit(onSaveForm)}
        className={classes.form}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              label="Client Name *"
              id="documentName"
              name="documentName"
              error={!!errors.documentName?.message}
              helperText={errors.documentName?.message}
              revisionValue={revisionValues?.DocumentName}
              disabled={!isEdit || isCreatorFieldDisabled}
              control={control as unknown as Control<FieldValues>}
            />
          </Grid>
          <Grid item xs={6}>
            <OptionFetcher loadOnMount url={GET_CONCERNS_URL}>
              {({ options }) => (
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      freeSolo
                      autoHighlight
                      clearOnBlur
                      options={options}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue.trim();
                        }
                        // Regular option
                        return option.value;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.value}</li>
                      )}
                      filterOptions={(opt, params) => {
                        const newParams = {
                          ...params,
                          inputValue: params.inputValue.trim(),
                        };
                        const filtered = filter(opt, newParams);

                        // Suggest the creation of a new value
                        if (
                          !filtered.some(
                            (o) => o.value === newParams.inputValue
                          ) &&
                          newParams.inputValue !== EMPTY_STRING
                        ) {
                          filtered.push({
                            inputValue: newParams.inputValue,
                            value: `Add "${newParams.inputValue}"`,
                            key: null,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => {
                        const concernId = revisionValues?.ConcernId;
                        const revisionValue = concernId
                          ? options?.find((o) => o.key === concernId)?.value
                          : null;
                        return (
                          <>
                            <MuiTextField
                              {...params}
                              name="concern"
                              label="Concern Name *"
                              variant="outlined"
                              id="concern"
                              error={!!(errors.concern as FieldError)?.message}
                              helperText={
                                (errors.concern as FieldError)?.message
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: revisionValue ? (
                                  <>
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle change value visibility"
                                        onClick={handleClick}
                                        ref={anchorRef}
                                      >
                                        <HistoryIcon color="primary" />
                                      </IconButton>
                                    </InputAdornment>
                                    {params.InputProps.endAdornment}
                                  </>
                                ) : (
                                  params.InputProps.endAdornment
                                ),
                              }}
                            />
                            {revisionValue && (
                              <InfoPopper
                                text={revisionValue}
                                onClickAway={handleClickAway}
                                open={open}
                                anchorEl={anchorRef.current}
                              />
                            )}
                          </>
                        );
                      }}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          // eslint-disable-next-line react/prop-types
                          field.onChange({
                            value: newValue,
                            key: null,
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          // eslint-disable-next-line react/prop-types
                          field.onChange({
                            value: newValue.inputValue,
                            key: null,
                          });
                        } else {
                          // eslint-disable-next-line react/prop-types
                          field.onChange(newValue);
                        }
                      }}
                      disabled={!isEdit || isCreatorFieldDisabled}
                    />
                  )}
                  name="concern"
                  control={control}
                />
              )}
            </OptionFetcher>
          </Grid>
          <Grid item xs={6}>
            <Select
              id="documentTypeId"
              name="documentTypeId"
              label="Document Type *"
              variant="outlined"
              fullWidth
              control={control as unknown as Control}
              options={
                !isSupplierClientsType
                  ? enumClientDocumentType.filter(
                      (t) => t.key !== ClientDocumentEnum.supplierClients
                    )
                  : enumClientDocumentType
              }
              error={!!errors.documentTypeId?.message}
              helperText={errors.documentTypeId?.message}
              disabled={
                isCreatorFieldDisabled || isSupplierClientsType || !isEdit
              }
              revisionValue={revisionValues?.DocumentTypeId}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              label="Client ID"
              id="marathonClientId"
              name="marathonClientId"
              error={!!errors.marathonClientId?.message}
              helperText={errors.marathonClientId?.message}
              revisionValue={revisionValues?.MarathonClientId}
              disabled
              control={control as unknown as Control<FieldValues>}
            />
          </Grid>
        </Grid>
      </Root>
      <Prompt when={isDirty} message={UNSAVED_DATA_MESSAGE} />
    </>
  );
};

export default ClientDocument;
