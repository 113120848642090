import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { Control, FieldErrors } from "react-hook-form";
import { useStateMachine } from "little-state-machine";

import { Select, TextField, NumericField } from "core";

import { EMPTY_STRING } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import {
  Current,
  IRevisionEntriesInput,
} from "../FinancialClientIncentive/types";

export interface FormFinancialClientIncentiveEntryProps {
  control: Control;
  current: Current;
  errors?: FieldErrors;
  isEdit: boolean;
  revisionValues?: IRevisionEntriesInput;
  /**
   * Fill form option type
   */
  fillOption?: FillFormOptionType;
  /**
   * setValues
   */
  setValue?: any;
  /**
   * resetFillOption
   */
  resetFillOption?: (state: FillFormOptionType | undefined) => void;
  /*
   * onDialogSave
   */
  onDialogSave?: () => void;
}

export const FormFinancialClientIncentiveEntry = ({
  control,
  current,
  errors = {},
  isEdit,
  revisionValues,
  setValue,
  fillOption,
  onDialogSave,
  resetFillOption,
}: FormFinancialClientIncentiveEntryProps): ReactElement => {
  const {
    kpiId = null,
    percentage = null,
    precondition = EMPTY_STRING,
    rowIndex,
  } = current ?? {};

  const {
    state: { clientDocumentData },
  } = useStateMachine();

  const { isContract } = clientDocumentData;

  const {
    state: {
      cacheData: { enumKpi },
    },
  } = useStateMachine();

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      if (
        isContract ||
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillAll
      ) {
        if (enumKpi[0]) {
          setValue(`entries.${rowIndex}.kpiId`, enumKpi[0].key);
        }
      }
      if (
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillAll
      ) {
        setValue(`entries.${rowIndex}.percentage`, 10);
        setValue(`entries.${rowIndex}.precondition`, "Precondition Test");
      }
    }
    return () => {
      if (
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillMandatoryAndSave
      ) {
        if (onDialogSave) {
          onDialogSave();
        }
      }
      if (resetFillOption) {
        resetFillOption(undefined);
      }
    };
  }, [
    fillOption,
    setValue,
    rowIndex,
    enumKpi,
    resetFillOption,
    onDialogSave,
    isContract,
  ]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          id="kpiId"
          name={`entries.${rowIndex}.kpiId`}
          label="Which KPI's *"
          variant="outlined"
          fullWidth
          defaultValue={kpiId ?? EMPTY_STRING}
          control={control}
          options={enumKpi}
          error={!!errors.kpiId?.message}
          helperText={errors.kpiId?.message}
          revisionValue={revisionValues?.KpiId}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="percentage"
          name={`entries.${rowIndex}.percentage`}
          label="Percentage"
          fullWidth
          defaultValue={percentage ?? EMPTY_STRING}
          error={!!errors.percentage?.message}
          helperText={errors.percentage?.message}
          revisionValue={revisionValues?.Percentage}
          control={control}
          percentage
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          control={control}
          name={`entries.${rowIndex}.precondition`}
          defaultValue={precondition ?? EMPTY_STRING}
          id="precondition"
          label="Precondition"
          error={!!errors.precondition?.message}
          helperText={errors.precondition?.message}
          revisionValue={revisionValues?.Precondition}
          disabled={!isEdit}
        />
      </Grid>
    </Grid>
  );
};

export default FormFinancialClientIncentiveEntry;
