import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import {
  setIsOpenAddSelectedDebtorsDialog,
  setIsOpenRemoveSelectedDebtorsDialog,
} from "store/actions";

import { classes, Grid } from "./styles";

export const ManageDebtorGroupsActions = (): ReactElement => {
  const {
    actions,
    state: { appState },
  } = useStateMachine({
    setIsOpenAddSelectedDebtorsDialog,
    setIsOpenRemoveSelectedDebtorsDialog,
  });

  const { isSelectedDebtors } = appState;

  const handleOpenAddSelectedDebtorsDialog = () => {
    actions.setIsOpenAddSelectedDebtorsDialog(true);
  };

  const handleOpenRemoveSelectedDialog = () => {
    actions.setIsOpenRemoveSelectedDebtorsDialog(true);
  };

  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item>
        <Button
          id="button-add"
          color="primary"
          variant="contained"
          className={classes.addButton}
          classes={{ disabled: classes.disabledButton }}
          disabled={!isSelectedDebtors}
          onClick={handleOpenAddSelectedDebtorsDialog}
        >
          Add Selected
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="button-remove"
          color="primary"
          variant="contained"
          className={classes.removeButton}
          classes={{ disabled: classes.disabledButton }}
          disabled={!isSelectedDebtors}
          onClick={handleOpenRemoveSelectedDialog}
        >
          Remove Selected
        </Button>
      </Grid>
    </Grid>
  );
};

export default ManageDebtorGroupsActions;
