import { Theme } from "@mui/material/styles";
import { EMPTY_STRING } from "shared/constants";

/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

export const formatDate = (date: string | Date): string =>
  new Date(date).toLocaleDateString("es-CL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

// #region Helpers
const StringIsNumber = (value: unknown) =>
  Number.isNaN(Number(value)) === false;
// #endregion Helpers

export function getEnumKeyValues<E>(_enum: E): Map<any, string> {
  return new Map(
    Object.entries(_enum).map(([key, value]: [any, string]) => [value, key])
  );
}

export const isNullOrUndefined = (value: unknown): boolean =>
  value === null || value === undefined;
export const isNullOrUndefinedOrEmpty = (value: unknown): boolean =>
  value === null || value === undefined || value === EMPTY_STRING;
export const isNull = (value: unknown): boolean => value === null;

export function ToArray<E extends Enum<E>>(enumVal: E): string[] {
  return Object.keys(enumVal)
    .filter(StringIsNumber)
    .map((key: string | number) => enumVal[key as any]);
}

export function getNthIndex(
  str: string,
  substr: string,
  occurence: number
): number {
  const len = str.length;
  let i = -1;
  while (occurence-- && i++ < len) {
    i = str.indexOf(substr, i);
    if (i < 0) break;
  }
  return i;
}

export const getThemeSpacing = (theme: Theme, value: number): number =>
  Number(theme.spacing(value).replace("px", EMPTY_STRING));
