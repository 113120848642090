import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import {
  NOTIFICATION_MESSAGE_DELETION_SUCCESS,
  serviceConfig,
} from "shared/constants";
import { FetchMethodEnum } from "shared/enums";
import { handleGetToken } from "shared/utils";
import { handleSuccessfullMutation } from "shared/utils/react-queryUtils";
import { CommonRequestOptions } from "../types";

interface DeleteOptions<T> extends CommonRequestOptions {
  invalidateQueryDataKey?: string[];
}

const { DELETE } = FetchMethodEnum;
const { resourceBaseAddress: baseUrl } = serviceConfig;

async function modifyData(
  initUrl: string,
  body: any,
  inProgress: InteractionStatus
) {
  const token = await handleGetToken(inProgress);

  const completeUrl = `${baseUrl}/${initUrl}`;

  const response = await fetch(completeUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: DELETE,
    body: JSON.stringify(body),
  });

  let result = null;

  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    result = await response.json();
  } else {
    result = await response.blob();
  }

  return result;
}

export function useDelete<T>(initialUrl: string, opt?: DeleteOptions<T>) {
  const { inProgress } = useMsal();
  const { enqueueSnackbar } = useSnackbar();

  const mutate = useMutation(
    async (body: any) => modifyData(initialUrl, body ?? null, inProgress),
    {
      onSuccess: () =>
        handleSuccessfullMutation(
          enqueueSnackbar,
          opt?.invalidateQueryDataKey,
          opt?.notify,
          opt?.message ?? NOTIFICATION_MESSAGE_DELETION_SUCCESS
        ),
    }
  );

  return mutate;
}

export default useDelete;
