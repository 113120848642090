import React, { ReactElement } from "react";
import { generateUrl } from "shared/utils";
import { GET_STATUS_LOGS } from "master-data/constants";

import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  Grid,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  IconButton,
} from "@mui/material";

import {
  useFetch,
  FetchMethodEnum,
  useQuery,
  useFileDownloader,
} from "shared/hooks";

import { AgencyEnum } from "master-data/enums";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";

import { EMPTY_STRING } from "shared/constants";
import { StatusLogTable } from "../StatusLogTable";

import { StatusLogResponse } from "./types";

import { Div } from "./styles";
import { DownloadStatusIcon } from "../icons";
import { MultiEmailActionUser } from "./components/MultiEmailActionUser";
import { SingleEmailActionUser } from "./components/SingleEmailActionUser";

export const ClientDocumentStatusLogsDialog = (): ReactElement => {
  const { goBack } = useHistory();
  const { GET } = FetchMethodEnum;
  const query = useQuery();
  const { downloadFile } = useFileDownloader();

  const { agencyName, documentId: paramId } = useParams<ParamTypes>();

  const documentId = paramId ?? query.get("id");
  const agencyId = AgencyEnum[agencyName];

  const url = generateUrl(GET_STATUS_LOGS, { agencyId, documentId });
  const { data, isLoading } = useFetch<StatusLogResponse>(url, {
    method: GET,
  });

  const {
    logs = [],
    marathonDocumentId = EMPTY_STRING,
    actionUsers = null,
  } = data?.get ?? {};

  const { role, emails } = actionUsers ?? {};

  const handleDownload = () => {
    downloadFile(url, { agencyId, documentId }, "StatusLogExport.xlsx");
  };

  const showActionUsers = actionUsers !== null && emails?.length !== 0;
  const isSingleActionUsersEmail =
    showActionUsers && emails !== undefined ? emails?.length === 1 : false;
  const emailActionUser =
    showActionUsers && emails !== undefined ? emails[0] : EMPTY_STRING;
  const showCopyIcon = emailActionUser.toLowerCase() !== "no selected reviewer";

  return (
    <Dialog
      aria-labelledby="client-status-log-dialog"
      open
      maxWidth="lg"
      fullWidth
      scroll="paper"
    >
      <DialogTitle id="client-status-log-dialog-title">
        <Div>Status log dialog</Div>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Client ID:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      {marathonDocumentId}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      Client document ID:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">{documentId}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7}>
                {showActionUsers && (
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">
                        User to take action:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {isSingleActionUsersEmail ? (
                        <SingleEmailActionUser
                          documentId={documentId}
                          role={role}
                          email={emailActionUser}
                          showCopyIcon={showCopyIcon}
                        />
                      ) : (
                        <MultiEmailActionUser
                          documentId={documentId}
                          role={role}
                          emails={emails}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <StatusLogTable logs={logs} />
        )}
      </DialogContent>
      <DialogActions>
        <IconButton
          id="export-status-log"
          disabled={isLoading}
          onClick={() => handleDownload()}
        >
          <DownloadStatusIcon />
        </IconButton>
        <Button id="close-status-log" onClick={() => goBack()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientDocumentStatusLogsDialog;
