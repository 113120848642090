import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  intercompanyClient: null,
  clientIndustryId: null,
  chamberOfCommerce: EMPTY_STRING,
  vatNumber: EMPTY_STRING,
  bankRelation: EMPTY_STRING,
  bankAccountNumber: EMPTY_STRING,
  mediaBuying: EMPTY_STRING,
  highestNetMedia: EMPTY_STRING,
  intercompanyCode: EMPTY_STRING,
  localDesignatedCode: EMPTY_STRING,
};
