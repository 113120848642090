import { styled, Dialog as MuiDialog } from "@mui/material";
import { softRed, deYork } from "shared/assets/colors";

const PREFIX = "ValidationDialogStyles";
export const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
};

export const Dialog = styled(MuiDialog)(() => ({
  [`& .${classes.success}`]: {
    color: deYork,
  },
  [`& .${classes.error}`]: {
    color: softRed,
  },
}));
