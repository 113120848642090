import { number, object, string, boolean } from "yup";

import {
  MAX_LENGTH_1000_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";

export const validationSchema = object().shape({
  name: string()
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  description: string()
    .nullable()
    .trim()
    .max(1000, MAX_LENGTH_1000_VALIDATION_MESSAGE),
  agencyId: number().required(MANDATORY_FILED_VALIDATION_MESSAGE).nullable(),
  isDefault: boolean(),
});
