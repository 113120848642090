import React, { ReactElement } from "react";

import { Tooltip, Typography } from "@mui/material";

import { DeclineComment } from "../../types";
import { classes, Grid } from "./styles";

export interface LastDeclineDetailsProps {
  /*
   * Active comment
   */
  comment: DeclineComment;
}

export const LastDeclineDetails = ({
  comment: commentDetails,
}: LastDeclineDetailsProps): ReactElement => {
  const { declineStatusName, declinedBy, date, comment } = commentDetails ?? {};

  return (
    <Grid container className={classes.container}>
      <Grid container>
        <Grid item xs={3}>
          <Typography align="left" variant="body1" className={classes.caption}>
            Declined Status:
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="left" variant="body1">
            {declineStatusName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={3}>
          <Typography align="left" variant="body1" className={classes.caption}>
            Declined By:
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="left" variant="body1">
            {declinedBy}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={3}>
          <Typography align="left" variant="body1" className={classes.caption}>
            Declined Date:
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="left" variant="body1">
            {date}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={3}>
          <Typography align="left" variant="body1" className={classes.caption}>
            Declined Reason:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Tooltip title={comment} placement="bottom-start">
            <Typography align="left" variant="body1" noWrap>
              {comment}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LastDeclineDetails;
