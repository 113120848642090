import {
  styled,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { NavLink as MuiNavLink } from "core";
import { baliHai, dodgerBlue, solitude, white } from "shared/assets/colors";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "MenuNavItemStyles";
export const classes = {
  nested: `${PREFIX}-nested`,
  item: `${PREFIX}-item`,
  selected: `${PREFIX}-selected`,
  selectedIcon: `${PREFIX}-selectedIcon`,
  selectedText: `${PREFIX}-selectedText`,
  root: `${PREFIX}-root`,
};

export const NavLink = styled(MuiNavLink)(({ theme }) => ({
  [`& .${classes.nested}`]: {
    paddingLeft: getThemeSpacing(theme, 4),
  },
  [`&.${classes.item}`]: {
    color: `${baliHai} !important`,
    textDecoration: "none !important",
  },
  [`& .${classes.selected}`]: {
    color: solitude,
  },
  [`& .${classes.selectedIcon}`]: {
    color: dodgerBlue,
  },
  [`& .${classes.selectedText}`]: {
    color: white,
  },
  [`& .${classes.root}`]: {
    color: baliHai,
  },
}));

export const ListItem = styled(MuiListItem)(({ theme }) => ({
  [`&.${classes.nested}`]: {
    paddingLeft: getThemeSpacing(theme, 4),
  },
  [`&.${classes.item}`]: {
    color: `${baliHai} !important`,
    textDecoration: "none !important",
  },
}));

export const ListItemIcon = styled(MuiListItemIcon)(() => ({
  [`&.${classes.item}`]: {
    color: `${baliHai}`,
    textDecoration: "none",
  },
  [`&.${classes.selectedIcon}`]: {
    color: dodgerBlue,
  },
}));

export const ListItemText = styled(MuiListItemText)(() => ({
  [`&.${classes.selectedText}`]: {
    color: white,
  },
}));
