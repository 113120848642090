import React, { ReactElement } from "react";

import { Switch, useRouteMatch } from "react-router-dom";

import { getPath } from "shared/utils";
import { AppBar, ClaimRoute } from "core";

import { routes } from "./routes";

export interface FooterBarProps {
  open?: boolean;
}

export const FooterBar = ({ open }: FooterBarProps): ReactElement => {
  const { path: base } = useRouteMatch();

  return (
    <AppBar open={open} isFooter>
      <Switch>
        {routes.map(({ path, main: Component, exact, rule, id }) => {
          const routePath = getPath(base, path);
          return (
            <ClaimRoute exact={exact} key={id} rule={rule} path={routePath}>
              <Component />
            </ClaimRoute>
          );
        })}
      </Switch>
    </AppBar>
  );
};

FooterBar.defaultProps = {
  open: false,
};

export default FooterBar;
