import { styled, Popper as MuiPopper } from "@mui/material";

const PREFIX = "InfoPopperStyles";
export const classes = {
  popper: `${PREFIX}-popper`,
  typography: `${PREFIX}-typography`,
};

export const Popper = styled(MuiPopper)(() => ({
  [`&.${classes.popper}`]: {
    zIndex: 1500,
  },
  [`& .${classes.typography}`]: {
    overflowWrap: "anywhere",
  },
}));
