import React, { ReactElement } from "react";

import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import { useStateMachine } from "little-state-machine";
import { Sort, SortOptionType } from "../../../core/Sort";

import { classes, TableHead, Select } from "./styles";

export interface TableMappingHeaderProps {
  sortValue: string;
  onSortChange: (arg0: SelectChangeEvent<unknown>) => void;
  sortOptions: SortOptionType[];
  isDisabled?: boolean;
  onSelectChange?: (arg0: SelectChangeEvent<unknown>) => void;
  selectValue?: number;
}

export const TableMappingHeader = ({
  onSortChange,
  sortValue,
  sortOptions,
  isDisabled,
  onSelectChange,
  selectValue,
}: TableMappingHeaderProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const { enumAgencies } = cacheData;
  const isSelectVisible =
    onSelectChange !== undefined && selectValue !== undefined;

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className={classes.sortHeader}>
              <Sort
                sortValue={sortValue}
                onSortChange={onSortChange}
                options={sortOptions}
                isDisabled={isDisabled}
              />
            </Grid>
            {isSelectVisible && (
              <Grid item>
                <Typography display="inline" className={classes.cssTypography}>
                  Agency:
                </Typography>
                <Select
                  id="agencyId"
                  name="agencyId"
                  className={classes.dropDown}
                  value={selectValue}
                  onChange={onSelectChange}
                  disabled={isDisabled}
                >
                  <MenuItem
                    key={0}
                    id="select-empty"
                    value={0}
                    className={classes.menuItem}
                  >
                    <em>None</em>
                  </MenuItem>
                  {enumAgencies.map(({ key, value }) => (
                    <MenuItem
                      key={key}
                      id={`select-${key}`}
                      value={key}
                      className={classes.menuItem}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableMappingHeader;
