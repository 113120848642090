import React, { ReactElement, useEffect, useState } from "react";

import { useStateMachine } from "little-state-machine";

import { Grid } from "@mui/material";

import { TextField, Autocomplete, FormDialog, TableEntries } from "core";

import { FillFormOptionType } from "master-data/enums";

import { FormSoxCompliantClientApprovalEntry } from "../SoxCompliantClientApprovalEntry";

import { columns } from "./config";
import { FormEntriesFieldProps } from "../props";
import { Current, IRevisionInput, IRevisionEntriesInput } from "./types";

export interface FormSoxCompliantClientApprovalProps
  extends FormEntriesFieldProps {
  /**
   * revisionValues
   * */
  revisionValues: IRevisionInput;
  /**
   * active item
   */
  current: Current;
  /**
   * revisionValuesFormDialog
   */
  revisionValuesFormDialog: IRevisionEntriesInput;
  /**
   * errors
   * */
  errors: any;
  /**
   * revisionValueIds
   */
  revisionValueIds: string[];
  /**
   * setValues
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
  /**
   * onEdit
   */
  onEdit: (newCurrent: Current) => void;
}

export const FormSoxCompliantClientApproval = ({
  isEdit,
  errors,
  errorsFormDialog,
  control,
  revisionValues,
  current,
  fields,
  onAdd,
  onEdit,
  onDelete,
  onDialogCancel,
  onDialogSave,
  clearErrors,
  isNewEntry,
  revisionValuesFormDialog,
  isDialogOpen,
  revisionValueIds,
  fillOption,
  setValue,
}: FormSoxCompliantClientApprovalProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const [dialogFillOption, setDialogFillOption] =
    useState<FillFormOptionType>();

  const { enumApprovalThought } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      if (enumApprovalThought[0]) {
        setValue("approvalThroughId", enumApprovalThought[0].key);
      }
    }
    if (
      fillOption === FillFormOptionType.fillAll ||
      fillOption === FillFormOptionType.fillAllAndSave
    ) {
      setValue("additionalTerms", "Additional Terms - Test");
    }
  }, [fillOption, setValue, enumApprovalThought]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Autocomplete
          autoHighlight
          id="approvalThroughId"
          name="approvalThroughId"
          variant="outlined"
          label="Approval Through *"
          fullWidth
          error={!!errors.approvalThroughId?.message}
          helperText={errors.approvalThroughId?.message}
          revisionValue={revisionValues?.ApprovalThroughId}
          disabled={!isEdit}
          control={control}
          options={enumApprovalThought}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="additionalTerms"
          id="additionalTerms"
          label="Additional Terms"
          multiline
          rows={4}
          error={!!errors.additionalTerms?.message}
          helperText={errors.additionalTerms?.message}
          revisionValue={revisionValues?.AdditionalTerms}
          disabled={!isEdit}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TableEntries
          onAdd={onAdd}
          onEdit={onEdit}
          onDelete={onDelete}
          rows={fields}
          isEdit={isEdit}
          control={control}
          columns={columns}
          revisionValueIds={revisionValueIds}
        />
      </Grid>
      <FormDialog
        isEdit={isEdit}
        isNew={isNewEntry}
        open={isDialogOpen}
        onCancel={onDialogCancel}
        onSave={onDialogSave}
        onClose={() => clearErrors("entries")}
        showFillButton={
          isEdit && process.env.REACT_APP_SHOW_FILL_BUTTON === "true"
        }
        onFill={setDialogFillOption}
      >
        <FormSoxCompliantClientApprovalEntry
          isEdit={isEdit}
          control={control}
          current={current}
          errors={errorsFormDialog}
          revisionValues={revisionValuesFormDialog}
          setValue={setValue}
          fillOption={dialogFillOption}
          resetFillOption={setDialogFillOption}
          onDialogSave={onDialogSave}
        />
      </FormDialog>
    </Grid>
  );
};

export default FormSoxCompliantClientApproval;
