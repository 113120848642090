import React, { ReactElement } from "react";

import NumberFormat, { NumberFormatProps } from "react-number-format";

import { EMPTY_STRING } from "shared/constants";
import { Div } from "./styles";

// TODO: extend NumberFormatProps as well as TextFieldProps
export interface NumericCellProps extends NumberFormatProps {
  /*
   * Add a suffix after the number
   */
  suffix?: string;
  /*
   * If it is integer, decimal scale won't be shown
   */
  integer?: boolean;
  /*
   * 	If true, the input will not have an underline.
   */
  disableUnderline?: boolean;
}

export const NumericCell = ({
  suffix,
  integer = false,
  disableUnderline,
  ...otherProps
}: NumericCellProps): ReactElement => {
  return (
    <Div>
      <NumberFormat
        {...otherProps}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={integer ? 0 : 2}
        fixedDecimalScale={!integer}
        disabled
        suffix={suffix ?? EMPTY_STRING}
      />
    </Div>
  );
};

export default NumericCell;
