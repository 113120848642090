import { FiltersDebtorsEnum } from "shared/enums";
import { FiltersDebtorsType } from "shared/types";

export interface Extension {
  extensionId: number;
  description: FiltersDebtorsType;
}

export const extensionsList: Extension[] = [
  {
    extensionId: 1,
    description: FiltersDebtorsEnum.groupm,
  },
  {
    extensionId: 5,
    description: FiltersDebtorsEnum.kinetic,
  },
  {
    extensionId: 2,
    description: FiltersDebtorsEnum.mindshare,
  },
  {
    extensionId: 6,
    description: FiltersDebtorsEnum.essencemediacom,
  },
  {
    extensionId: 7,
    description: FiltersDebtorsEnum.wavemaker,
  },
  {
    extensionId: 8,
    description: FiltersDebtorsEnum.blossom,
  },
  {
    extensionId: 9,
    description: FiltersDebtorsEnum.greenhouseone,
  },
  {
    extensionId: 10,
    description: FiltersDebtorsEnum.greenhousetech,
  },
  {
    extensionId: 11,
    description: FiltersDebtorsEnum.choreographcreate,
  },
];
