import { AnyObjectSchema } from "yup";

import { AccordionType } from "product/types";

import { validationSchema as productInfoSchema } from "./ProductInfo/config";
import { validationSchema as invoicingAddressMethodSchema } from "./InvoicingAddressMethod/config";
import { validationSchema as activationMBSSchema } from "./ActivationMBS/config";
import { validationSchema as businessSectionSchema } from "./BusinessSection/config";

export const schemas: { [key in AccordionType]: AnyObjectSchema } = {
  productInfo: productInfoSchema,
  invoicingAddressMethod: invoicingAddressMethodSchema,
  activationMBS: activationMBSSchema,
  businessSection: businessSectionSchema,
};
