import React, { ReactElement, useEffect, useState } from "react";

import { FormDialog, TableEntries } from "core";
import { FillFormOptionType } from "master-data/enums";
import { FormClientContactsEntry } from "../ClientContactsEntry";
import { Current, IRevisionEntriesInput } from "./types";

import { FormEntriesFieldProps } from "../props";
import { columns } from "./config";

export interface FormClientContactsProps extends FormEntriesFieldProps {
  /**
   * active item
   */
  current: Current;
  /**
   * revisionValuesFormDialog
   */
  revisionValuesFormDialog: IRevisionEntriesInput;
  /**
   * revisionValueIds
   */
  revisionValueIds: string[];
  /**
   * setValues
   */
  setValue: any;
  /**
   * onEdit
   */
  onEdit: (newCurrent: Current) => void;
}

export const FormClientContacts = ({
  isEdit,
  control,
  current,
  fields,
  onAdd,
  onEdit,
  onDelete,
  isNewEntry,
  clearErrors,
  isDialogOpen,
  onDialogSave,
  onDialogCancel,
  errorsFormDialog,
  revisionValuesFormDialog,
  revisionValueIds,
  setValue,
}: FormClientContactsProps): ReactElement => {
  const [dialogFillOption, setDialogFillOption] =
    useState<FillFormOptionType>();

  return (
    <>
      <TableEntries
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        rows={fields}
        isEdit={isEdit}
        control={control}
        columns={columns}
        revisionValueIds={revisionValueIds}
      />
      <FormDialog
        open={isDialogOpen}
        onCancel={onDialogCancel}
        onSave={onDialogSave}
        isNew={isNewEntry}
        isEdit={isEdit}
        onClose={() => clearErrors("entries")}
        showFillButton={
          isEdit && process.env.REACT_APP_SHOW_FILL_BUTTON === "true"
        }
        showFillMandatoryOption
        onFill={setDialogFillOption}
      >
        <FormClientContactsEntry
          control={control}
          current={current}
          revisionValues={revisionValuesFormDialog}
          isEdit={isEdit}
          errors={errorsFormDialog}
          setValue={setValue}
          fillOption={dialogFillOption}
          resetFillOption={setDialogFillOption}
          onDialogSave={onDialogSave}
        />
      </FormDialog>
    </>
  );
};

export default FormClientContacts;
