import React, { ReactElement, useState } from "react";

import { Grid } from "@mui/material";

import { EMPTY_STRING } from "shared/constants";
import {
  FilterOptions,
  FilterQuerys,
} from "master-data/pages/ClientDocumentOverview/config";
import { ChipAdd, ChipEdit, Filter } from "./components";

interface FormatFilters {
  id: number;
  property: string;
  text: string;
  value: string | undefined;
}

export interface FilterProps {
  updateUrl: (object: { [key: string]: string | undefined }) => void;
  queryFilters: FilterQuerys;
  filterOptions: FilterOptions[];
  limit: number;
  isDisabled?: boolean;
}

export interface FilterObj {
  id?: number;
  property: string;
  value?: string;
}

export const DynamicFilter = ({
  updateUrl,
  queryFilters,
  filterOptions,
  limit,
  isDisabled,
}: FilterProps): ReactElement => {
  let filters = [{ id: 0, property: EMPTY_STRING, text: EMPTY_STRING }];
  if (Array.isArray(filterOptions) && filterOptions.length) {
    filters = filterOptions;
  }
  const cleanQuery: string[] = Object.keys(queryFilters).filter(
    (key) => queryFilters[key] !== undefined
  );

  function formatFiltersFromQuery(): FormatFilters[] {
    return cleanQuery.map((key: string) => ({
      ...(filters.find((o) => o.property === key) ?? {
        id: 0,
        property: EMPTY_STRING,
        text: EMPTY_STRING,
      }),
      value: queryFilters[key],
    }));
  }

  const queryIds: (number | undefined)[] = filters
    .filter((o) => cleanQuery?.some((q) => q === o.property))
    .map((i) => i.id);

  function getOptionTextByProperty(property: string): string {
    const optionText = filters.find(
      (option) => option.property === property
    )?.text;
    const result = optionText === undefined ? EMPTY_STRING : optionText;
    return result;
  }

  const getOptionPropertyById = (id: number | undefined) =>
    filters.find((option) => option.id === id)?.property;

  const limited = limit
    ? Object.keys(cleanQuery).length >= limit
    : Object.keys(cleanQuery).length >= filterOptions.length;

  const isOpenFilter = !limited && isDisabled;
  const [newFilter, setNewFilter] = useState(isOpenFilter);

  const showNewFilter = () => {
    if (!limited) setNewFilter(true);
  };
  const hideNewFilter = () => {
    setNewFilter(false);
  };

  const addFilter = (filter: FilterObj) => {
    // This is needed when updating existing filter property, remove old property from url and add new one
    let oldProperty = null;
    if (queryIds.includes(filter.id)) {
      oldProperty = getOptionPropertyById(filter.id);
    }
    updateUrl({
      ...(oldProperty && { [oldProperty]: undefined }),
      [filter.property]: filter.value,
    });
  };

  const removeFilter = (property: string) => {
    updateUrl({ [property]: undefined });
  };

  const getOptions = () =>
    filters.filter(
      (option) => !cleanQuery?.some((property) => option.property === property)
    );

  const currentOptions = getOptions();

  const appendSelectedOption = (selectedOption: {
    id: number;
    property: string;
    text: string;
  }) => currentOptions.concat(selectedOption);

  return (
    <Grid container alignItems="center" spacing={1}>
      {formatFiltersFromQuery().map(({ id, property, value }) => {
        const text = getOptionTextByProperty(property);
        const options = appendSelectedOption({ id, property, text });

        return (
          <Filter
            key={property}
            id={id}
            value={value}
            property={property}
            addFilter={addFilter}
            removeFilter={removeFilter}
            options={options}
            optionText={text}
          />
        );
      })}
      {newFilter && !isOpenFilter && (
        <Grid item>
          <ChipEdit
            id={0}
            filterProperty={EMPTY_STRING}
            filterValue={EMPTY_STRING}
            commit={addFilter}
            options={currentOptions}
            cancelEdit={hideNewFilter}
          />
        </Grid>
      )}
      {!limited && (
        <Grid item>
          <ChipAdd add={showNewFilter} isDisabled={isOpenFilter} />
        </Grid>
      )}
    </Grid>
  );
};

export default DynamicFilter;
