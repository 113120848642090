import { AccordionType, SectionType } from "master-data/types";
import {
  AccordionEnum,
  SectionEnum,
  MAP_ACCORDION,
  MAP_SECTION,
} from "master-data/enums";

// #region Client section accordions
const clientDocument = {
  type: MAP_ACCORDION.get(AccordionEnum.clientDocument) as AccordionType,
  title: "Client Document",
  sectionType: MAP_SECTION.get(SectionEnum.client) as SectionType,
  // rule: "GetClientDocumentLegalDocumentsChecks",
};

const accordionGeneralClientInfo = {
  type: MAP_ACCORDION.get(AccordionEnum.generalClientInfo) as AccordionType,
  title: "General Client Info",
  sectionType: MAP_SECTION.get(SectionEnum.client) as SectionType,
};

const accordionAdditionalClientInfo = {
  type: MAP_ACCORDION.get(AccordionEnum.additionalClientInfo) as AccordionType,
  title: "Additional Client Info",
  sectionType: MAP_SECTION.get(SectionEnum.client) as SectionType,
};

const accordionCreditInsurance = {
  type: MAP_ACCORDION.get(AccordionEnum.creditInsurance) as AccordionType,
  title: "Credit Insurance",
  sectionType: MAP_SECTION.get(SectionEnum.client) as SectionType,
};

const accordionClientContacts = {
  type: MAP_ACCORDION.get(AccordionEnum.clientContacts) as AccordionType,
  title: "Client Contacts",
  sectionType: MAP_SECTION.get(SectionEnum.client) as SectionType,
};

const accordionRelatedContacts = {
  type: MAP_ACCORDION.get(AccordionEnum.relatedContacts) as AccordionType,
  title: "Related Contacts",
  sectionType: MAP_SECTION.get(SectionEnum.client) as SectionType,
};

const accordionClientTeam = {
  type: MAP_ACCORDION.get(AccordionEnum.clientTeam) as AccordionType,
  title: "Client team",
  sectionType: MAP_SECTION.get(SectionEnum.client) as SectionType,
};

const accordionSupplierAgreements = {
  type: MAP_ACCORDION.get(AccordionEnum.supplierAgreements) as AccordionType,
  title: "Supplier Agreements",
  sectionType: MAP_SECTION.get(SectionEnum.client) as SectionType,
};
// #endregion Client section accordions

// #region Contract section accordions
const accordionGeneralContractInfo = {
  type: MAP_ACCORDION.get(AccordionEnum.generalContractInfo) as AccordionType,
  title: "General Contract Info",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};

const accordionFinancialAvbs = {
  type: MAP_ACCORDION.get(AccordionEnum.financialAvbs) as AccordionType,
  title: "Financial - AVB's",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  rule: "ViewCompleteClientDocument",
};

const accordionFinancialEpds = {
  type: MAP_ACCORDION.get(AccordionEnum.financialEpds) as AccordionType,
  title: "Financial - EPD's",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  rule: "ViewCompleteClientDocument",
};

const accordionFinancialUnbilledMedia = {
  type: MAP_ACCORDION.get(
    AccordionEnum.financialUnbilledMedia
  ) as AccordionType,
  title: "Financial - Unbilled Media",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  rule: "ViewCompleteClientDocument",
};

const accordionFinancialClientIncentive = {
  type: MAP_ACCORDION.get(
    AccordionEnum.financialClientIncentive
  ) as AccordionType,
  title: "Financial - Client Incentive",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  rule: "ViewCompleteClientDocument",
};

const accordionInventoryMedia = {
  type: MAP_ACCORDION.get(AccordionEnum.inventoryMedia) as AccordionType,
  title: "Inventory Media",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};

const accordionAllowableDeducationsRebates = {
  type: MAP_ACCORDION.get(
    AccordionEnum.allowableDeducationsRebates
  ) as AccordionType,
  title: "Allowable Deductions Rebates",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  rule: "ViewCompleteClientDocument",
};

const accordionAudit = {
  type: MAP_ACCORDION.get(AccordionEnum.audit) as AccordionType,
  title: "Audit",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  rule: "ViewCompleteClientDocument",
};

const accordionInterest = {
  type: MAP_ACCORDION.get(AccordionEnum.interest) as AccordionType,
  title: "Interest",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  rule: "ViewCompleteClientDocument",
};

const accordionAdmanagement = {
  type: MAP_ACCORDION.get(AccordionEnum.admanagement) as AccordionType,
  title: "Ad Management",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};

const accordionDigitalAccountabilityBrandSafety = {
  type: MAP_ACCORDION.get(
    AccordionEnum.digitalAccountabilityBrandSafety
  ) as AccordionType,
  title: "Digital Accountability & Brand Safety",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};

const accordionEvouching = {
  type: MAP_ACCORDION.get(AccordionEnum.evouching) as AccordionType,
  title: "E-Vouching",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};

const accordionHours = {
  type: MAP_ACCORDION.get(AccordionEnum.hours) as AccordionType,
  title: "Hours",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};

const accordionRemunerationContractLine = {
  type: MAP_ACCORDION.get(
    AccordionEnum.remunerationContractLine
  ) as AccordionType,
  title: "Remuneration Contract Line",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};

const accordionRevenueRecognition = {
  type: MAP_ACCORDION.get(AccordionEnum.revenueRecognition) as AccordionType,
  title: "Revenue Recognition",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  rule: "ViewCompleteClientDocument",
};

const accordionScopeOfServices = {
  type: MAP_ACCORDION.get(AccordionEnum.scopeOfServices) as AccordionType,
  title: "Scope of services",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};

const accordionOutOfScopeServices = {
  type: MAP_ACCORDION.get(AccordionEnum.outOfScopeServices) as AccordionType,
  title: "Out of Scope services",
  sectionType: MAP_SECTION.get(SectionEnum.contract) as SectionType,
};
// #endregion Contract Section Accordions

// #region "Invoicing section accordions"
const accordionInvoicingAddressMethod = {
  type: MAP_ACCORDION.get(
    AccordionEnum.invoicingAddressMethod
  ) as AccordionType,
  title: "Invoicing Address & Method",
  sectionType: MAP_SECTION.get(SectionEnum.invoicing) as SectionType,
};

const accordionInvoicingRequirements = {
  type: MAP_ACCORDION.get(AccordionEnum.invoicingRequirements) as AccordionType,
  title: "Invoicing Requirements",
  sectionType: MAP_SECTION.get(SectionEnum.invoicing) as SectionType,
};

const accordionAdditionalInvoicingRequirements = {
  type: MAP_ACCORDION.get(
    AccordionEnum.additionalInvoicingRequirements
  ) as AccordionType,
  title: "Additional Invoicing Requirements",
  sectionType: MAP_SECTION.get(SectionEnum.invoicing) as SectionType,
};
// #endregion

// #region "Various Commercial Agreements section accordions"
const accordionHoursAccountability = {
  type: MAP_ACCORDION.get(AccordionEnum.hoursAccountability) as AccordionType,
  title: "Hours Accountability",
  sectionType: MAP_SECTION.get(
    SectionEnum.variousCommercialAgreements
  ) as SectionType,
};

const accordionCommercialAgreements = {
  type: MAP_ACCORDION.get(AccordionEnum.commercialAgreements) as AccordionType,
  title: "Commercial Agreements",
  sectionType: MAP_SECTION.get(
    SectionEnum.variousCommercialAgreements
  ) as SectionType,
};

const accordionSoxCompliantClientApproval = {
  type: MAP_ACCORDION.get(
    AccordionEnum.soxCompliantClientApproval
  ) as AccordionType,
  title: "SOx Compliant Client Approval",
  sectionType: MAP_SECTION.get(
    SectionEnum.variousCommercialAgreements
  ) as SectionType,
};
// #endregion

// #region "Checks"
const accordionMdtChecks = {
  type: MAP_ACCORDION.get(AccordionEnum.mdtChecks) as AccordionType,
  title: "MDT Checks",
  sectionType: MAP_SECTION.get(SectionEnum.checks) as SectionType,
  rule: "GetClientDocumentMdtChecks",
};
// #endregion

// #region "Attachments"
const accordionLegalDocumentsChecks = {
  type: MAP_ACCORDION.get(AccordionEnum.legalDocumentsChecks) as AccordionType,
  title: "Legal Documents",
  sectionType: MAP_SECTION.get(SectionEnum.attachments) as SectionType,
  rule: "GetClientDocumentLegalDocumentsChecks",
};
// #endregion

export const accordions = [
  clientDocument,
  accordionGeneralClientInfo,
  accordionAdditionalClientInfo,
  accordionCreditInsurance,
  accordionClientContacts,
  accordionRelatedContacts,
  accordionClientTeam,
  accordionSupplierAgreements,
  accordionGeneralContractInfo,
  accordionFinancialAvbs,
  accordionFinancialEpds,
  accordionFinancialUnbilledMedia,
  accordionFinancialClientIncentive,
  accordionInventoryMedia,
  accordionAllowableDeducationsRebates,
  accordionAudit,
  accordionInterest,
  accordionAdmanagement,
  accordionDigitalAccountabilityBrandSafety,
  accordionEvouching,
  accordionHours,
  accordionRemunerationContractLine,
  accordionRevenueRecognition,
  accordionScopeOfServices,
  accordionOutOfScopeServices,
  accordionHoursAccountability,
  accordionCommercialAgreements,
  accordionInvoicingAddressMethod,
  accordionInvoicingRequirements,
  accordionAdditionalInvoicingRequirements,
  accordionSoxCompliantClientApproval,
  accordionMdtChecks,
  accordionLegalDocumentsChecks,
];

export const accordionsSupplierClientsType = [
  clientDocument,
  accordionGeneralClientInfo,
  accordionAdditionalClientInfo,
  accordionCreditInsurance,
  accordionClientContacts,
  accordionRelatedContacts,
  accordionClientTeam,
  accordionSupplierAgreements,
  accordionInterest,
  accordionRemunerationContractLine,
  accordionRevenueRecognition,
  accordionInvoicingAddressMethod,
  accordionInvoicingRequirements,
  accordionAdditionalInvoicingRequirements,
  accordionMdtChecks,
  accordionLegalDocumentsChecks,
];

const sectionClient = {
  type: MAP_SECTION.get(SectionEnum.client) as SectionType,
  title: "Client",
  subTitle: "Created",
  accordions: [
    MAP_ACCORDION.get(AccordionEnum.clientDocument) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.generalClientInfo) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.additionalClientInfo) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.creditInsurance) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.clientContacts) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.clientTeam) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.supplierAgreements) as AccordionType,
  ],
  rules: [],
};

const sectionContract = {
  type: MAP_SECTION.get(SectionEnum.contract) as SectionType,
  title: "Contract",
  subTitle: "Created",
  accordions: [
    MAP_ACCORDION.get(AccordionEnum.generalContractInfo) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.financialAvbs) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.financialEpds) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.financialUnbilledMedia) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.financialClientIncentive) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.inventoryMedia) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.financialClientIncentive) as AccordionType,
    MAP_ACCORDION.get(
      AccordionEnum.allowableDeducationsRebates
    ) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.audit) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.interest) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.admanagement) as AccordionType,
    MAP_ACCORDION.get(
      AccordionEnum.digitalAccountabilityBrandSafety
    ) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.evouching) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.hours) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.remunerationContractLine) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.revenueRecognition) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.scopeOfServices) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.outOfScopeServices) as AccordionType,
  ],
  rules: [],
};

const sectionInvoicing = {
  type: MAP_SECTION.get(SectionEnum.invoicing) as SectionType,
  title: "Invoicing",
  subTitle: "Created",
  accordions: [
    MAP_ACCORDION.get(AccordionEnum.invoicingAddressMethod) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.invoicingRequirements) as AccordionType,
    MAP_ACCORDION.get(
      AccordionEnum.additionalInvoicingRequirements
    ) as AccordionType,
  ],
  rules: [],
};

const sectionVariousCommercialAgreements = {
  type: MAP_SECTION.get(SectionEnum.variousCommercialAgreements) as SectionType,
  title: "Various Commercial Agreements",
  subTitle: "Created",
  accordions: [
    MAP_ACCORDION.get(AccordionEnum.hoursAccountability) as AccordionType,
    MAP_ACCORDION.get(AccordionEnum.commercialAgreements) as AccordionType,
    MAP_ACCORDION.get(
      AccordionEnum.soxCompliantClientApproval
    ) as AccordionType,
  ],
  rules: [],
};

const sectionChecks = {
  type: MAP_SECTION.get(SectionEnum.checks) as SectionType,
  title: "Checks",
  subTitle: "Created",
  accordions: [MAP_ACCORDION.get(AccordionEnum.mdtChecks) as AccordionType],
  rules: ["GetClientDocumentMdtChecks"],
};

const sectionAttachments = {
  type: MAP_SECTION.get(SectionEnum.attachments) as SectionType,
  title: "Attachments",
  subTitle: "Created",
  accordions: [
    MAP_ACCORDION.get(AccordionEnum.legalDocumentsChecks) as AccordionType,
  ],
  rules: ["GetClientDocumentLegalDocumentsChecks"],
};

export const sections = [
  sectionClient,
  sectionContract,
  sectionInvoicing,
  sectionVariousCommercialAgreements,
  sectionChecks,
  sectionAttachments,
];

export const sectionsSupplierClientsType = [
  sectionClient,
  sectionContract,
  sectionInvoicing,
  sectionChecks,
  sectionAttachments,
];

export const sectionsForCompleteView: SectionType[] = [
  "client",
  "contract",
  "invoicing",
  "variousCommercialAgreements",
  "checks",
  "attachments",
];

export const sectionsForMDTView: Exclude<
  SectionType,
  "variousCommercialAgreements"
>[] = ["client", "contract", "invoicing", "checks", "attachments"];

export const sectionsForApproverView: Exclude<
  SectionType,
  "invoicing" | "variousCommercialAgreements"
>[] = ["client", "contract", "attachments"];

export const accordionsForCompleteView: AccordionType[] = [
  "clientDocument",
  "generalClientInfo",
  "additionalClientInfo",
  "creditInsurance",
  "clientContacts",
  "relatedContacts",
  "clientTeam",
  "supplierAgreements",
  "generalContractInfo",
  "financialAvbs",
  "financialEpds",
  "financialUnbilledMedia",
  "financialClientIncentive",
  "inventoryMedia",
  "allowableDeducationsRebates",
  "audit",
  "interest",
  "admanagement",
  "digitalAccountabilityBrandSafety",
  "evouching",
  "hours",
  "remunerationContractLine",
  "revenueRecognition",
  "scopeOfServices",
  "outOfScopeServices",
  "invoicingAddressMethod",
  "invoicingRequirements",
  "additionalInvoicingRequirements",
  "hoursAccountability",
  "commercialAgreements",
  "soxCompliantClientApproval",
  "mdtChecks",
  "legalDocumentsChecks",
];

export const accordionsForMDTView: Extract<
  AccordionType,
  | "clientDocument"
  | "generalClientInfo"
  | "additionalClientInfo"
  | "creditInsurance"
  | "clientTeam"
  | "generalContractInfo"
  | "interest"
  | "remunerationContractLine"
  | "invoicingAddressMethod"
  | "invoicingRequirements"
  | "mdtChecks"
  | "legalDocumentsChecks"
  | "financialEpds"
>[] = [
  "clientDocument",
  "generalClientInfo",
  "additionalClientInfo",
  "creditInsurance",
  "clientTeam",
  "generalContractInfo",
  "interest",
  "remunerationContractLine",
  "invoicingAddressMethod",
  "invoicingRequirements",
  "mdtChecks",
  "legalDocumentsChecks",
  "financialEpds",
];
export const accordionsForApproverView: Extract<
  AccordionType,
  | "clientDocument"
  | "creditInsurance"
  | "financialAvbs"
  | "financialEpds"
  | "financialUnbilledMedia"
  | "financialClientIncentive"
  | "allowableDeducationsRebates"
  | "audit"
  | "interest"
  | "hours"
  | "remunerationContractLine"
  | "revenueRecognition"
  | "mdtChecks"
  | "legalDocumentsChecks"
>[] = [
  "clientDocument",
  "creditInsurance",
  "financialAvbs",
  "financialEpds",
  "financialUnbilledMedia",
  "financialClientIncentive",
  "allowableDeducationsRebates",
  "audit",
  "interest",
  "hours",
  "remunerationContractLine",
  "revenueRecognition",
  "mdtChecks",
  "legalDocumentsChecks",
];
