import { styled, Tooltip as MuiTooltip } from "@mui/material";

import { crusta, dodgerBlue20, solitude } from "shared/assets/colors";

const PREFIX = "ClearAllStyles";
export const classes = {
  button: `${PREFIX}-button`,
  active: `${PREFIX}-active`,
  clearAll: `${PREFIX}-clearAll`,
  cancelButton: `${PREFIX}-cancelButton`,
};

export const Tooltip = styled(MuiTooltip)(() => ({
  [`& .${classes.button}`]: {
    textTransform: "none",
    fontWeight: 100,
  },
  [`& .${classes.active}`]: {
    backgroundColor: dodgerBlue20,
  },
  [`& .${classes.clearAll}`]: {
    borderLeft: `0.1em solid ${solitude}`,
  },
  [`& .${classes.cancelButton}`]: {
    color: crusta,
  },
}));
