import { object, string, number, array } from "yup";
import {
  EMAIL_FORM_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  ALL_EMAIL_ENTRIES_VALIDATION_MESSAGE,
  MAX_NUMBER_OF_5_ELEMENTS_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

export const validationSchema = object().shape({
  mailingAddress: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .nullable(),
  postalCode: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .nullable(),
  city: string().trim().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE).nullable(),
  country: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .nullable(),
  invoicingMethodId: number().nullable().transform(nanToNull),
  invoiceMailingAddress: array()
    .of(
      string()
        .trim()
        .email(ALL_EMAIL_ENTRIES_VALIDATION_MESSAGE)
        .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    )
    .max(5, MAX_NUMBER_OF_5_ELEMENTS_VALIDATION_MESSAGE),
  attn: string().trim().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE).nullable(),
  emailAccountancy: string()
    .trim()
    .email(EMAIL_FORM_VALIDATION_MESSAGE)
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .nullable(),
});
