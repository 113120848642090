import { InteractionStatus } from "@azure/msal-browser";
import { authProvider } from "configuration/authProvider";

export const logOutAndClearStorage = (): void => {
  localStorage.clear();
  sessionStorage.clear();
  authProvider.logoutRedirect();
};

export const getToken = async (
  requestedScopes = [
    "api://8386a02c-bc6c-4fe4-b165-c2ededce5de4/access_as_user",
  ]
): Promise<any> => {
  await authProvider.handleRedirectPromise();

  const accounts = authProvider.getAllAccounts();

  if (accounts.length > 0) {
    const request = {
      scopes: requestedScopes,
      account: accounts[0],
    };

    const accessToken = await authProvider
      .acquireTokenSilent(request)
      .then((response) => {
        return response.accessToken;
      })
      .catch(() => {
        return null;
      });

    return accessToken;
  }

  return null;
};

export const getTokenRedirect = async (
  requestedScopes = [
    "api://8386a02c-bc6c-4fe4-b165-c2ededce5de4/access_as_user",
  ]
): Promise<any> => {
  await authProvider.handleRedirectPromise();

  await authProvider
    .acquireTokenRedirect({
      scopes: requestedScopes,
    })
    .then((response: any) => {
      return response.accessToken;
    });

  return null;
};

export const getTokenOrRedirect = async (
  requestedScopes = [
    "api://8386a02c-bc6c-4fe4-b165-c2ededce5de4/access_as_user",
  ]
): Promise<any> => {
  await authProvider.handleRedirectPromise();

  const accounts = authProvider.getAllAccounts();

  if (accounts.length > 0) {
    const request = {
      scopes: requestedScopes,
      account: accounts[0],
    };

    authProvider
      .acquireTokenSilent(request)
      .then((response) => {
        return response.accessToken;
      })
      .catch(() => {
        authProvider
          .acquireTokenRedirect({
            scopes: requestedScopes,
          })
          .then((response: any) => {
            return response.accessToken;
          })
          .catch(() => {
            throw new Error("Unable to retreive access token");
          });
      });
  }
};

export const handleGetToken = async (
  inProgress: InteractionStatus = InteractionStatus.None
) => {
  let token = null;
  if (inProgress === InteractionStatus.None) {
    token = await getToken();

    if (!token) {
      token = await getTokenRedirect();
    }
  }
  return token ?? new Error("Unable to retreive access token");
};
