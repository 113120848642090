import React, { StrictMode } from "react";
import ReactDOM from "react-dom";

import { MsalProvider } from "@azure/msal-react";

import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider, CssBaseline, IconButton } from "@mui/material";
import { Backspace as BackspaceIcon } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { StateMachineProvider, createStore } from "little-state-machine";
import { SnackbarKey, SnackbarProvider } from "notistack";

import App from "App";
import { theme } from "configuration/theme";
import * as serviceWorker from "serviceWorker";
import { defaultGlobalStore } from "store/constants";
import { authProvider } from "configuration/authProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "shared/react-query/queryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

createStore(defaultGlobalStore, {
  name: "masterdata_app_store",
  middleWares: [],
});

const notistackRef = React.createRef<any>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <StrictMode>
    <MsalProvider instance={authProvider}>
      <QueryClientProvider client={queryClient}>
        <StateMachineProvider>
          {/* {process.env.NODE_ENV !== "production" && <DevTool buttonTop={40} />} */}
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              autoHideDuration={2000}
              maxSnack={3}
              ref={notistackRef}
              action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                  <BackspaceIcon htmlColor="white" />
                </IconButton>
              )}
            >
              <Router>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CssBaseline />
                  <App />
                </LocalizationProvider>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </StateMachineProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </MsalProvider>
  </StrictMode>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
