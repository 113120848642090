import React from "react";

import { array, number, object, string } from "yup";

import { ColumnType, InputCell, RenderCellProps, CellTypeEnum } from "core";

import {
  EMAIL_FORM_VALIDATION_MESSAGE,
  MAX_LENGTH_50_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

// #region columns definition
export const columns: ColumnType[] = [
  {
    field: "id",
    headerName: "Id",
    hide: true,
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "contactPerson",
    headerName: "Contact Person",
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "jobTitle",
    headerName: "Job Title",
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "email",
    headerName: "Email",
    cellType: CellTypeEnum.Input,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "phone",
    headerName: "Phone",
    cellType: CellTypeEnum.Input,
    hide: true,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
];
// #endregion columns definition

const formSchema = {
  id: number().transform(nanToNull).nullable(),
  contactPerson: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  jobTitle: string().trim().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  email: string()
    .trim()
    .email(EMAIL_FORM_VALIDATION_MESSAGE)
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
  phone: string().trim().max(50, MAX_LENGTH_50_VALIDATION_MESSAGE),
};

export const validationSchema = object().shape({
  entries: array().of(object().shape(formSchema)),
});
