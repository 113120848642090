import {
  styled,
  Divider as MuiDivider,
  TableRow as MuiTableRow,
} from "@mui/material";

export const Divider = styled(MuiDivider)(() => ({
  marginLeft: 16,
  marginRight: 16,
}));

export const TableRow = styled(MuiTableRow)(() => ({
  display: "flex",
  [`& td`]: {
    borderBottom: "none",
  },
}));
