export const BASE_URL = "api/back-office";

const CACHE = "cache";
const USER_MANAGEMENT = "usermanagement";
const DOCUMENT_SCHEMA_MANAGEMENT = "schemamanagement";
const BACKGROUND_JOB = "backgroundjob";

export const CLEAR_CACHE = `${BASE_URL}/${CACHE}/:dbType`;
export const GET_USERS = `${BASE_URL}/${USER_MANAGEMENT}/user`;
export const CREATE_USER = `${BASE_URL}/${USER_MANAGEMENT}/user`;
export const EDIT_USER = `${BASE_URL}/${USER_MANAGEMENT}/user/:userId`;
export const GET_DOCUMENT_SCHEMA_OVERVIEW = `${BASE_URL}/${DOCUMENT_SCHEMA_MANAGEMENT}/overview/:versionId`;
export const UPDATE_DOCUMENT_SCHEMA_OVERVIEW = `${BASE_URL}/${DOCUMENT_SCHEMA_MANAGEMENT}/schema/:versionId`;
export const GET_DEBTOR_SYNCS = `${BASE_URL}/${BACKGROUND_JOB}/debtor-sync`;
export const DEBTOR_SYNCS_STOP = `${BASE_URL}/${BACKGROUND_JOB}/debtor-sync/stop`;
export const DEBTOR_SYNCS_START = `${BASE_URL}/${BACKGROUND_JOB}/debtor-sync/start`;
export const GET_AUDIT_TRAIL_SYNCS = `${BASE_URL}/${BACKGROUND_JOB}/audit-trail-sync`;
export const AUDIT_TRAIL_SYNCS_STOP = `${BASE_URL}/${BACKGROUND_JOB}/audit-trail-sync/stop`;
export const AUDIT_TRAIL_SYNCS_START = `${BASE_URL}/${BACKGROUND_JOB}/audit-trail-sync/start`;
