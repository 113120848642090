import React, { ReactElement, useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { TextField, CheckField } from "core";

import {
  CLAIM_EDIT_CREATOR_FIELD,
  CLAIM_EDIT_VALIDATOR_FIELD,
} from "master-data/constants";
import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormSupplierAgreementsProps extends FormStandardFieldProps {
  /**
   * setValues
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormSupplierAgreements = ({
  isEdit,
  control,
  errors,
  revisionValues,
  setValue,
  fillOption,
}: FormSupplierAgreementsProps): ReactElement => {
  const {
    state: { profile, clientDocumentData },
  } = useStateMachine();

  const { claims } = profile;
  const { isDocumentEditable } = clientDocumentData;
  const isValidatorFieldDisabled = !claims.includes(CLAIM_EDIT_VALIDATOR_FIELD);
  const isCreatorFieldDisabled = !(
    claims.includes(CLAIM_EDIT_CREATOR_FIELD) && isDocumentEditable
  );

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("supplierID", "Supplier ID Test");
      setValue("avb", true);
      setValue("sbi", true);
      setValue("msi", true);
    }
  }, [fillOption, setValue]);
  // #endregion Effects

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <TextField
          name="supplierID"
          id="supplierID"
          label="SupplierID"
          error={!!errors.supplierID?.message}
          helperText={errors.supplierID?.message}
          disabled={!isEdit || isValidatorFieldDisabled}
          control={control}
          revisionValue={revisionValues?.SupplierID}
        />
      </Grid>
      <Grid item>
        <Typography>Agreements under this contract:</Typography>
      </Grid>
      <Grid item>
        <CheckField
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
          name="avb"
          label="AVB"
          revisionValue={revisionValues?.AVB}
        />
      </Grid>
      <Grid item>
        <CheckField
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
          name="sbi"
          label="SBI"
          revisionValue={revisionValues?.SBI}
        />
      </Grid>
      <Grid item>
        <CheckField
          disabled={!isEdit || isCreatorFieldDisabled}
          control={control}
          name="msi"
          label="MSI"
          revisionValue={revisionValues?.MSI}
        />
      </Grid>
    </Grid>
  );
};

export default FormSupplierAgreements;
