import React from "react";

import { array, number, object, string } from "yup";

import {
  InputCell,
  ColumnType,
  NumericCell,
  CellTypeEnum,
  RenderCellProps,
} from "core";

import {
  MAX_NUMBER_FOR_INPUTS,
  MIN_NUMBER_FOR_INPUTS,
  MAX_NUMBER_VALIDATION_MESSAGE,
  NUMBER_FIELD_VALIDATION_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
  MAX_LENGTH_1000_VALIDATION_MESSAGE,
} from "shared/constants";
import { nanToNull } from "shared/utils";

// #region columns definition
export const columns: ColumnType[] = [
  {
    field: "id",
    headerName: "Id",
    hide: true,
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
  {
    field: "thresholdUpTo",
    headerName: "Authorized to Sign - Threshold up to",
    cellType: CellTypeEnum.NumberFormat,
    renderCell: ({ field }: RenderCellProps) => <NumericCell {...field} />,
  },
  {
    field: "nameFunctions",
    headerName: "Authorized to Sign - Name or Function",
    cellType: CellTypeEnum.Input,
    renderCell: ({ field }: RenderCellProps) => <InputCell {...field} />,
  },
];
// #endregion columns definition

const formSchema = {
  id: number().transform(nanToNull).nullable(),
  thresholdUpTo: number()
    .transform(nanToNull)
    .nullable()
    .typeError(NUMBER_FIELD_VALIDATION_MESSAGE)
    .max(MAX_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .min(MIN_NUMBER_FOR_INPUTS, MAX_NUMBER_VALIDATION_MESSAGE)
    .notRequired(),
  nameFunctions: string()
    .trim()
    .nullable()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .notRequired(),
};

export const validationSchema = object().shape({
  approvalThroughId: number()
    .nullable()
    .required(MANDATORY_FILED_VALIDATION_MESSAGE)
    .transform(nanToNull),
  additionalTerms: string()
    .trim()
    .nullable()
    .max(1000, MAX_LENGTH_1000_VALIDATION_MESSAGE)
    .notRequired(),
  entries: array().of(object().shape(formSchema)),
});
