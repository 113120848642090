import React, { ReactElement, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { useStateMachine } from "little-state-machine";

import {
  Select,
  Autocomplete,
  FormDialog,
  TableEntries,
  NumericField,
} from "core";

import { yesNoOptions } from "shared/constants";

import { FillFormOptionType } from "master-data/enums";
import { columns } from "./config";
import { FormHoursEntry } from "../HoursEntry";
import { FormEntriesFieldProps } from "../props";
import { Current, IRevisionInput, IRevisionEntriesInput } from "./types";

export interface FormHoursProps extends FormEntriesFieldProps {
  /**
   * revisionValues
   * */
  revisionValues: IRevisionInput;
  /**
   * active item
   */
  current: Current;
  /**
   * revisionValuesFormDialog
   */
  revisionValuesFormDialog: IRevisionEntriesInput;
  /**
   * errors
   * */
  errors: any;
  /**
   * revisionValueIds
   */
  revisionValueIds: string[];
  /**
   * onEdit
   */
  onEdit: (newCurrent: Current) => void;
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormHours = ({
  isEdit,
  errors,
  errorsFormDialog,
  control,
  revisionValues,
  current,
  fields,
  onAdd,
  onEdit,
  onDelete,
  onDialogCancel,
  onDialogSave,
  clearErrors,
  isNewEntry,
  revisionValuesFormDialog,
  isDialogOpen,
  revisionValueIds,
  fillOption,
  setValue,
}: FormHoursProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const [dialogFillOption, setDialogFillOption] =
    useState<FillFormOptionType>();

  const { enumReportType, enumLevel } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue("staffplanRetainer", yesNoOptions[0].key);
      setValue("outOfScopeHours", yesNoOptions[0].key);
      setValue("hoursOnly", yesNoOptions[0].key);
      if (
        fillOption === FillFormOptionType.fillAll ||
        fillOption === FillFormOptionType.fillAllAndSave
      ) {
        setValue("hoursReportRequired", yesNoOptions[0].key);
        setValue("hoursYearlyInflation", yesNoOptions[0].key);
        if (enumReportType[0]) {
          setValue("reportTypeId", enumReportType[0].key);
        }
      }
    }
  }, [fillOption, setValue, enumReportType]);
  // #endregion Effects

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Select
            id="staffplanRetainer"
            name="staffplanRetainer"
            variant="outlined"
            label="Retainer/Staffplan *"
            fullWidth
            error={!!errors.staffplanRetainer?.message}
            helperText={errors.staffplanRetainer?.message}
            revisionValue={revisionValues?.StaffplanRetainer}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            id="outOfScopeHours"
            name="outOfScopeHours"
            variant="outlined"
            label="Out of Scope Hours *"
            fullWidth
            error={!!errors.outOfScopeHours?.message}
            helperText={errors.outOfScopeHours?.message}
            revisionValue={revisionValues?.OutOfScopeHours}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            id="hoursOnly"
            name="hoursOnly"
            variant="outlined"
            label="Hours Only *"
            fullWidth
            error={!!errors.hoursOnly?.message}
            helperText={errors.hoursOnly?.message}
            revisionValue={revisionValues?.HoursOnly}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            id="hoursReportRequired"
            name="hoursReportRequired"
            variant="outlined"
            label="Hours Report"
            fullWidth
            error={!!errors.hoursReportRequired?.message}
            helperText={errors.hoursReportRequired?.message}
            revisionValue={revisionValues?.HoursReportRequired}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            autoHighlight
            id="reportTypeId"
            name="reportTypeId"
            variant="outlined"
            label="Report Level"
            fullWidth
            error={!!errors.reportTypeId?.message}
            helperText={errors.reportTypeId?.message}
            revisionValue={revisionValues?.ReportTypeId}
            disabled={!isEdit}
            control={control}
            options={enumReportType}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            id="hoursYearlyInflation"
            name="hoursYearlyInflation"
            variant="outlined"
            label="Hours Yearly Inflation"
            fullWidth
            error={!!errors.hoursYearlyInflation?.message}
            helperText={errors.hoursYearlyInflation?.message}
            revisionValue={revisionValues?.HoursYearlyInflation}
            disabled={!isEdit}
            control={control}
            options={yesNoOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericField
            id="retainerAmountPerYear"
            name="retainerAmountPerYear"
            label="Retainer Amount per Year (€)"
            fullWidth
            defaultValue={undefined}
            revisionValue={revisionValues?.RetainerAmountPerYear}
            control={control}
            disabled={!isEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <TableEntries
            onAdd={onAdd}
            onEdit={onEdit}
            onDelete={onDelete}
            rows={fields}
            isEdit={isEdit}
            control={control}
            columns={columns}
            revisionValueIds={revisionValueIds}
            fieldOptions={{
              levelId: enumLevel,
            }}
          />
        </Grid>
      </Grid>
      <FormDialog
        isEdit={isEdit}
        isNew={isNewEntry}
        open={isDialogOpen}
        onSave={onDialogSave}
        onCancel={onDialogCancel}
        onClose={() => clearErrors("entries")}
        showFillButton={
          isEdit && process.env.REACT_APP_SHOW_FILL_BUTTON === "true"
        }
        onFill={setDialogFillOption}
      >
        <FormHoursEntry
          isEdit={isEdit}
          control={control}
          current={current}
          errors={errorsFormDialog}
          revisionValues={revisionValuesFormDialog}
          fillOption={dialogFillOption}
          resetFillOption={setDialogFillOption}
          onDialogSave={onDialogSave}
          setValue={setValue}
        />
      </FormDialog>
    </>
  );
};

export default FormHours;
