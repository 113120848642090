import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useQuery } from "@tanstack/react-query";
import { QueryType } from "master-data/types";
import { useState } from "react";
import { EMPTY_STRING, serviceConfig } from "shared/constants";
import { FetchMethodEnum } from "shared/enums";
import { generateQuery, handleGetToken } from "shared/utils";
import { CommonRequestOptions, UserKeys } from "../types";

interface GetOptions extends CommonRequestOptions {
  initialParams?: QueryType;
  isEnabled?: boolean;
  urlParams?: NullableString;
}

const { GET } = FetchMethodEnum;
const { resourceBaseAddress: baseUrl } = serviceConfig;

const fetchData = async (
  initUrl: string,
  urlParams: NullableString,
  initialParams: QueryType | undefined,
  token: unknown
) => {
  const url = `${initUrl}${urlParams ? `/${urlParams}` : EMPTY_STRING}`;
  const queryString = generateQuery(initialParams);
  const completeUrl = `${baseUrl}/${url}${
    queryString ? `?${queryString}` : EMPTY_STRING
  }`;

  const response = await fetch(completeUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: GET,
  });

  let result = null;

  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    result = await response.json();
  } else {
    result = await response.blob();
  }

  return result;
};

export function useGet(
  initialUrl: string,
  queryKey: string,
  opt: GetOptions = {
    urlParams: EMPTY_STRING,
    initialParams: undefined,
    isEnabled: true,
  }
) {
  const [urlParams, setUrlParams] = useState<NullableString>(
    opt?.urlParams ?? EMPTY_STRING
  );

  const [initialParams, setInitialParams] = useState<QueryType | undefined>(
    opt.initialParams
  );

  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(
    opt.isEnabled
  );

  const { inProgress } = useMsal();

  // Fetch user token (won't execute if InteractionStatus is not None)
  const { data: token, isSuccess: isTokenFetched } = useQuery({
    queryKey: [UserKeys.USER_TOKEN],
    queryFn: async () => handleGetToken(),
    enabled: inProgress === InteractionStatus.None && isEnabled,
    staleTime: 0,
    cacheTime: 0,
  });

  // Fetch main data (won't execute if token doesn't exist)
  const queryResponse = useQuery({
    queryKey: [queryKey, urlParams, initialParams],
    queryFn: async () => fetchData(initialUrl, urlParams, initialParams, token),
    enabled: isTokenFetched && !!token,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    urlParams,
    setUrlParams,
    initialParams,
    setInitialParams,
    setIsEnabled,
    ...queryResponse,
  };
}

export default useGet;
