import React, { useState, useEffect, ReactElement } from "react";

import cuid from "cuid";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import {
  Error as ErrorIcon,
  CheckCircle as ValidIcon,
} from "@mui/icons-material";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { VALIDATE_DOCUMENT } from "master-data/constants";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";

import { classes, Dialog } from "./styles";

export interface IValidateResponse {
  isSuccessful: boolean;
  errorMessages: string[];
}

const { POST } = FetchMethodEnum;
const defaultBody = {};

export const ValidationDialog = (): ReactElement => {
  const { goBack } = useHistory();
  const { agencyName, documentId } = useParams<ParamTypes>();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const agencyId = AgencyEnum[agencyName];

  const url = generateUrl(VALIDATE_DOCUMENT, {
    agencyId,
    documentId,
  });

  const { data, isLoading, hasError } = useFetch<undefined, IValidateResponse>(
    url,
    {
      method: POST,
      body: defaultBody,
      notify: false,
    }
  );

  const { post: postData } = data ?? {};

  // #region Effects
  useEffect(() => {
    if (postData) {
      const { errorMessages: errorMsgs = [] } = postData ?? {};
      setErrorMessages(errorMsgs);
    }
  }, [postData]);
  // #endregion Effects

  const isError = errorMessages.length !== 0 || hasError;
  const content = isError ? errorMessages : ["Document is valid"];

  return (
    <Dialog open maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle id="validation-dialog-title">
        Validate Client Document
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <List dense>
            {content.map((message) => (
              <ListItem key={cuid()}>
                <ListItemIcon>
                  {isError ? (
                    <ErrorIcon className={classes.error} />
                  ) : (
                    <ValidIcon className={classes.success} />
                  )}
                </ListItemIcon>
                <ListItemText primary={message} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => goBack()} color="primary">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationDialog;
