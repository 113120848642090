import React, { ReactElement, useEffect } from "react";

import { Control, FieldErrors } from "react-hook-form";
import { useStateMachine } from "little-state-machine";

import {
  Grid,
  Checkbox,
  Popper,
  AutocompleteChangeReason,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material";
import { CheckBox as CheckBoxIcon, Rowing } from "@mui/icons-material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { Select, TextField, DateField, NumericField, Autocomplete } from "core";

import { EMPTY_STRING } from "shared/constants";
import { FillFormOptionType } from "master-data/enums";
import {
  Current,
  IRevisionEntriesInput,
} from "../RemunerationContractLine/types";

export interface FormRemunerationContractLineEntryProps {
  control: Control;
  current: Current;
  errors?: FieldErrors;
  isEdit: boolean;
  revisionValues?: IRevisionEntriesInput;
  /**
   * Fill form option type
   */
  fillOption?: FillFormOptionType;
  getValues: (attr?: any) => any;
  /**
   * setValues
   */
  setValue: any;
  /**
   * resetFillOption
   */
  resetFillOption: (state: FillFormOptionType | undefined) => void;
  /*
   * onDialogSave
   */
  onDialogSave: () => void;
}

export const FormRemunerationContractLineEntry = ({
  control,
  current,
  errors = {},
  isEdit,
  revisionValues,
  fillOption,
  getValues,
  setValue,
  resetFillOption,
  onDialogSave,
}: FormRemunerationContractLineEntryProps): ReactElement => {
  const {
    agreementName,
    feePercentage,
    startDate = null,
    endDate = null,
    applicableServices = [],
    invoicingPeriodId = EMPTY_STRING,
    paymentTermId = EMPTY_STRING,
    feeOnMediaInvoiceId = EMPTY_STRING,
    paymentTermNumberOfDays,
    currencyId = EMPTY_STRING,
    slidingScaleMinimumSpend,
    otherCurrency,
    rowIndex,
    slidingScaleMaximumSpend,
  } = current ?? {};

  const {
    state: { cacheData },
  } = useStateMachine();

  const {
    enumPaymentTerm,
    enumInvoicingPeriod,
    enumFeeMediaInvoice,
    enumCurrency,
    enumApplicableServices,
  } = cacheData;

  // #region Autocomplete Applicable Service Setup
  const filter = createFilterOptions<OptionType>();

  const PopperBottom = (props: any) => {
    return <Popper {...props} disablePortal placement="bottom" />;
  };

  const getOptionLabelAS = (option: any) => {
    return (
      option.value ??
      enumApplicableServices?.find((o) => o.key === option)?.value
    );
  };

  const renderOptionAS = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    { selected }: { selected: boolean }
  ) => {
    const { entries } = getValues();
    const currentApplicableServiceValues =
      entries && rowIndex != null && entries[rowIndex]?.applicableServices;

    const isAllSelected =
      enumApplicableServices.length === currentApplicableServiceValues?.length;

    const selectAllProps =
      option.key === 0 || option === 0
        ? {
            checked: isAllSelected,
          }
        : {};

    return (
      <li {...props}>
        <Checkbox
          color="secondary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabelAS(option)}
      </li>
    );
  };

  const getOptionSelectedAS = (option: OptionType, value: OptionType) => {
    if (typeof value === "object" && value.key === 0) {
      return true;
    }

    return typeof value === "number"
      ? option.key === value
      : option.key === value.key;
  };

  const filterOptionsAS = (
    options: OptionType[],
    params: FilterOptionsState<OptionType>
  ): OptionType[] => {
    const filtered = filter(options, params);
    return [{ key: 0, value: "All" }, ...filtered];
  };

  const onChangeAS = (
    event: React.SyntheticEvent,
    selectedOptions: any[],
    reason: AutocompleteChangeReason,
    onChange: (attr: any) => void
  ): void => {
    const { entries } = getValues();
    const currentApplicableServiceValues =
      entries && rowIndex != null && entries[rowIndex]?.applicableServices;

    const isAllSelected =
      enumApplicableServices.length === currentApplicableServiceValues?.length;

    const isSelectAllActive = selectedOptions.some(
      (option: any) => option.key === 0
    );

    const handleSelectAll = (isSelected: boolean) => {
      if (isSelected) {
        onChange(
          enumApplicableServices.map((v) => (typeof v === "number" ? v : v.key))
        );
      } else {
        onChange([]);
      }
    };

    if (reason === "selectOption" || reason === "removeOption") {
      if (isSelectAllActive) {
        handleSelectAll(!isAllSelected);
      } else {
        onChange(
          selectedOptions.map((v: any) => (typeof v === "number" ? v : v.key))
        );
      }
    } else if (reason === "clear") {
      onChange([]);
    }
  };
  // #endregion Autocomplete Applicable Service Setup

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      setValue(`entries.${rowIndex}.agreementName`, "Test 1");
      setValue(`entries.${rowIndex}.feePercentage`, 1);
      setValue(`entries.${rowIndex}.startDate`, new Date());
      if (enumApplicableServices[0]) {
        setValue(`entries.${rowIndex}.applicableServices`, [
          enumApplicableServices[0].key,
        ]);
      }
      if (enumPaymentTerm[0]) {
        setValue(`entries.${rowIndex}.paymentTermId`, enumPaymentTerm[0].key);
      }
      if (enumInvoicingPeriod[0]) {
        setValue(
          `entries.${rowIndex}.invoicingPeriodId`,
          enumInvoicingPeriod[0].key
        );
      }
      if (enumFeeMediaInvoice[0]) {
        setValue(
          `entries.${rowIndex}.feeOnMediaInvoiceId`,
          enumFeeMediaInvoice[0].key
        );
      }
      if (enumCurrency[0]) {
        setValue(`entries.${rowIndex}.currencyId`, enumCurrency[0].key);
      }

      if (
        fillOption !== FillFormOptionType.fillMandatory &&
        fillOption !== FillFormOptionType.fillMandatoryAndSave
      ) {
        setValue(`entries.${rowIndex}.endDate`, new Date());
        setValue(`entries.${rowIndex}.paymentTermNumberOfDays`, 5);
        setValue(`entries.${rowIndex}.slidingScaleMinimumSpend`, 6);
        setValue(`entries.${rowIndex}.slidingScaleMaximumSpend`, 7);
        setValue(`entries.${rowIndex}.otherCurrency`, "Test");
      }
    }
    return () => {
      if (
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillMandatoryAndSave
      ) {
        onDialogSave();
      }
      resetFillOption(undefined);
    };
  }, [
    fillOption,
    setValue,
    rowIndex,
    enumApplicableServices,
    enumPaymentTerm,
    enumInvoicingPeriod,
    enumFeeMediaInvoice,
    enumCurrency,
    resetFillOption,
    onDialogSave,
  ]);
  // #endregion Effects

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          control={control}
          name={`entries.${rowIndex}.agreementName`}
          defaultValue={agreementName ?? EMPTY_STRING}
          id="agreementName"
          label="Agreement Name *"
          error={!!errors.agreementName?.message}
          helperText={errors.agreementName?.message}
          revisionValue={revisionValues?.AgreementName}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="feePercentage"
          name={`entries.${rowIndex}.feePercentage`}
          label="Fee Percentage *"
          fullWidth
          defaultValue={feePercentage ?? EMPTY_STRING}
          error={!!errors.feePercentage?.message}
          helperText={errors.feePercentage?.message}
          revisionValue={revisionValues?.FeePercentage}
          control={control}
          percentage
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          required
          fullWidth
          name={`entries.${rowIndex}.startDate`}
          label="Start Date"
          defaultValue={startDate}
          error={!!errors.startDate?.message}
          helperText={errors.startDate?.message}
          revisionValue={revisionValues?.StartDate}
          control={control}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          fullWidth
          name={`entries.${rowIndex}.endDate`}
          label="End Date"
          defaultValue={endDate}
          error={!!errors.endDate?.message}
          helperText={errors.endDate?.message}
          revisionValue={revisionValues?.EndDate}
          control={control}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          multiple
          fullWidth
          control={control}
          disabled={!isEdit}
          disableCloseOnSelect
          limitTags={isEdit ? 5 : 50}
          label="Applicable Services *"
          options={enumApplicableServices}
          defaultValue={applicableServices}
          error={!!errors.applicableServices?.message}
          helperText={errors.applicableServices?.message}
          id={`entries.${rowIndex}.applicableServices`}
          name={`entries.${rowIndex}.applicableServices`}
          revisionValue={revisionValues?.ApplicableServices}
          renderOption={renderOptionAS}
          filterOptions={filterOptionsAS}
          PopperComponent={PopperBottom}
          getOptionLabel={getOptionLabelAS}
          isOptionEqualToValue={getOptionSelectedAS}
          onChange={onChangeAS}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="paymentTermId"
          name={`entries.${rowIndex}.paymentTermId`}
          label="Payment Term *"
          variant="outlined"
          fullWidth
          defaultValue={paymentTermId ?? EMPTY_STRING}
          control={control}
          options={enumPaymentTerm}
          error={!!errors.paymentTermId?.message}
          helperText={errors.paymentTermId?.message}
          revisionValue={revisionValues?.PaymentTermId}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="invoicingPeriodId"
          name={`entries.${rowIndex}.invoicingPeriodId`}
          label="Invoicing Period *"
          variant="outlined"
          fullWidth
          defaultValue={invoicingPeriodId ?? EMPTY_STRING}
          control={control}
          options={enumInvoicingPeriod}
          error={!!errors.invoicingPeriodId?.message}
          helperText={errors.invoicingPeriodId?.message}
          revisionValue={revisionValues?.InvoicingPeriodId}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="paymentTermNumberOfDays"
          name={`entries.${rowIndex}.paymentTermNumberOfDays`}
          label="Payment Term number of days"
          fullWidth
          defaultValue={paymentTermNumberOfDays ?? EMPTY_STRING}
          error={!!errors.paymentTermNumberOfDays?.message}
          helperText={errors.paymentTermNumberOfDays?.message}
          revisionValue={revisionValues?.PaymentTermNumberOfDays}
          control={control}
          integer
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="feeOnMediaInvoiceId"
          name={`entries.${rowIndex}.feeOnMediaInvoiceId`}
          label="Fee on Media Invoice *"
          variant="outlined"
          fullWidth
          defaultValue={feeOnMediaInvoiceId ?? EMPTY_STRING}
          control={control}
          options={enumFeeMediaInvoice}
          error={!!errors.feeOnMediaInvoiceId?.message}
          helperText={errors.feeOnMediaInvoiceId?.message}
          revisionValue={revisionValues?.FeeOnMediaInvoiceId}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="slidingScaleMinimumSpend"
          name={`entries.${rowIndex}.slidingScaleMinimumSpend`}
          label="Sliding Scale Minimum Spend"
          fullWidth
          defaultValue={slidingScaleMinimumSpend ?? EMPTY_STRING}
          error={!!errors.slidingScaleMinimumSpend?.message}
          helperText={errors.slidingScaleMinimumSpend?.message}
          revisionValue={revisionValues?.SlidingScaleMinimumSpend}
          control={control}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          id="currencyId"
          name={`entries.${rowIndex}.currencyId`}
          label="Currency *"
          variant="outlined"
          fullWidth
          defaultValue={currencyId ?? EMPTY_STRING}
          control={control}
          options={enumCurrency}
          error={!!errors.currencyId?.message}
          helperText={errors.currencyId?.message}
          revisionValue={revisionValues?.CurrencyId}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericField
          id="slidingScaleMaximumSpend"
          name={`entries.${rowIndex}.slidingScaleMaximumSpend`}
          label="Sliding Scale Maximum Spend"
          fullWidth
          defaultValue={slidingScaleMaximumSpend ?? EMPTY_STRING}
          error={!!errors.slidingScaleMaximumSpend?.message}
          helperText={errors.slidingScaleMaximumSpend?.message}
          revisionValue={revisionValues?.SlidingScaleMaximumSpend}
          control={control}
          disabled={!isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          control={control}
          name={`entries.${rowIndex}.otherCurrency`}
          defaultValue={otherCurrency ?? EMPTY_STRING}
          id="otherCurrency"
          label="Other Currency"
          error={!!errors.otherCurrency?.message}
          helperText={errors.otherCurrency?.message}
          revisionValue={revisionValues?.OtherCurrency}
          disabled={!isEdit}
        />
      </Grid>
    </Grid>
  );
};

export default FormRemunerationContractLineEntry;
