import React, { ReactElement } from "react";

import { useParams } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { Grid, TableBody } from "@mui/material";

import { CircularProgress } from "core";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import { AgencyEnum } from "master-data/enums";
import { generateUrl } from "shared/utils";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";
import { GET_LEGAL_DOCUMENTS_CHECKS_AND_ATTACHMENTS } from "master-data/constants";

import { IResponseGet } from "./types";
import { TableCheckItem } from "./components/TableCheckItem";
import { EDIT_LEGAL_DOCUMENTS_CHECKS_CLAIM } from "./constants";

import { classes, Table } from "./styles";

const { GET } = FetchMethodEnum;

export const LegalDocumentsChecks = (): ReactElement => {
  const { agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];

  const {
    state: { profile },
  } = useStateMachine();

  const { claims } = profile;
  const isEditable = claims.includes(EDIT_LEGAL_DOCUMENTS_CHECKS_CLAIM);

  const url = generateUrl(GET_LEGAL_DOCUMENTS_CHECKS_AND_ATTACHMENTS, {
    agencyId,
    documentId,
  });

  const { data, refetch, isLoading } = useFetch<IResponseGet, null>(url, {
    method: GET,
  });

  const { get: getData } = data ?? {};

  if (isLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress size={65} label="Loading" />
      </Grid>
    );
  }

  return (
    <Table size="small" className={classes.table}>
      <TableBody>
        {getData?.items.map((item) => (
          <TableCheckItem
            key={item.checkId}
            {...item}
            refetch={refetch}
            isEditable={isEditable}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default LegalDocumentsChecks;
