export const FETCH_USERS_DIALOG_TITLE = "Fetch users dialog";
export const IMPORT_USERS_DIALOG_TITLE = "Import users dialog";
export const REMOVE_USERS_DIALOG_TITLE = "Remove users dialog";
export const ADD_USERS_DIALOG_TITLE = "Add users dialog";
export const ADD_DEBTORS_DIALOG_TITLE = "Add debtors dialog";
export const REMOVE_DEBTORS_DIALOG_TITLE = "Remove debtors dialog";
export const DELETE_USER_GROUP_TITLE = "Delete user group dialog";
export const DELETE_DEBTOR_GROUP_TITLE = "Delete debtor group dialog";
export const ADD_MAPPINGS_DIALOG_TITLE = "Add mappings dialog";
export const CLEAR_MAPPINGS_DIALOG_TITLE = "Clear mapping selection dialog";
export const DELETE_MAPPING_TITLE = "Delete mapping dialog";
export const IMPORT_MISSING_DEBTORS_DIALOG_TITLE =
  "Import missing debtors dialog";

export const FETCH_USERS_DIALOG_CONTENT =
  "Are you sure you want to fetch users from AAD?";
export const IMPORT_USERS_DIALOG_CONTENT =
  "Are you sure you want to import selected users?";
export const REMOVE_USERS_DIALOG_CONTENT =
  "Are you sure you want to remove selected users?";
export const FETCHING_USERS_MESSAGE = "Fetching users...";
export const ADD_USERS_TO_USER_GROUP_DIALOG_CONTENT =
  "Are you sure you want to add selected users to group?";
export const REMOVE_USERS_TO_USER_GROUP_DIALOG_CONTENT =
  "Are you sure you want to remove selected users from group?";
export const ADD_DEBTORS_TO_DEBTOR_GROUP_DIALOG_CONTENT =
  "Are you sure you want to add selected debtors to group?";
export const REMOVE_DEBTORS_FROM_DEBTOR_GROUP_DIALOG_CONTENT =
  "Are you sure you want to remove selected debtors from group?";
export const DELETE_USER_GROUP_CONTENT =
  "Are you sure you want to delete user group?";
export const DELETE_DEBTOR_GROUP_CONTENT =
  "Are you sure you want to delete debtor group?";
export const ADD_MAPPINGS_DIALOG_CONTENT =
  "Are you sure you want to add selected mappings?";
export const CLEAR_MAPPINGS_DIALOG_CONTENT =
  "Are you sure you want to clear selected checkboxes?";
export const ADD_MAPPINGS_DIALOG_LOADING_CONTENT =
  "Mapping is in progress. This can take a while, please be patience...";
export const DELETE_MAPPING_CONTENT =
  "Are you sure you want to delete mapping?";
export const IMPORT_MISSING_DEBTORS_DIALOG_CONTENT =
  "Are you sure you want to import selected missing debtors?";

export const INFO_FETCH_CONTENT =
  "Fetch User list from AAD. Beside that, it will set point in time that is used to detect newly added users in AAD.";

export const USER_GROUPS_PREFIX = "userGroups";
export const DEBTOR_GROUPS_PREFIX = "debtorGroups";
export const APP_PREFIX = "app";

// #region URL
export const APP_ACCESSMANAGER = "access-manager";
export const PAGE_IMPORT_USERS = "import-users";
export const PAGE_MANAGE_USER_GROUPS = "user-group";
export const PAGE_MANAGE_DEBTOR_GROUPS = "debtor-group";
export const PAGE_CREATE_MAPPINGS = "mapping";
export const OVERVIEW_PAGE = "overview";
export const PAGE_KEYWORD_SEARCH = "keyword-search";
export const PAGE_MISSING_DEBTORS = "missing-debtors";
export const URL_DEBTOR_GROUP_OVERVIEW = `${APP_ACCESSMANAGER}/${OVERVIEW_PAGE}/${PAGE_MANAGE_DEBTOR_GROUPS}`;
export const URL_USER_GROUP_OVERVIEW = `${APP_ACCESSMANAGER}/${OVERVIEW_PAGE}/${PAGE_MANAGE_USER_GROUPS}`;
export const URL_IMPORT_USERS = `${APP_ACCESSMANAGER}/${PAGE_IMPORT_USERS}`;
export const URL_MANAGE_DEBTOR_GROUP = `${APP_ACCESSMANAGER}/${PAGE_MANAGE_DEBTOR_GROUPS}`;
export const URL_MANAGE_USER_GROUP = `${APP_ACCESSMANAGER}/${PAGE_MANAGE_USER_GROUPS}`;
export const URL_MAPPING_OVERVIEW = `${APP_ACCESSMANAGER}/${OVERVIEW_PAGE}/${PAGE_CREATE_MAPPINGS}`;
export const URL_CREATE_MAPPING = `${APP_ACCESSMANAGER}/${PAGE_CREATE_MAPPINGS}`;
export const INIT_QUERY_IMPORT_USERS =
  "?page=1&pageSize=10&sortByColumn=username_asc&isascending=true";
export const INIT_QUERY_DEBTORS =
  "?page=1&pageSize=10&sortByColumn=debtorName_asc&isascending=true";
export const INIT_OVERVIEW_PAGE = "?page=1&pageSize=10";
export const INIT_QUERY_MAPPINGS =
  "?ugSortByColumn=userGroupName_asc&ugIsAscending=true&dgSortByColumn=debtorGroupName_asc&dgIsAscending=true";
export const URL_KEYWORD_SEARCH_PAGE = `${APP_ACCESSMANAGER}/${PAGE_KEYWORD_SEARCH}`;
export const URL_MISSING_DEBTORS_PAGE = `${APP_ACCESSMANAGER}/${PAGE_MISSING_DEBTORS}`;
// #endregion URL
