import React, { useState, useEffect, ReactElement } from "react";
import { Grid } from "@mui/material";
import { FetchMethodEnum, useFetch, useProfileGetter } from "shared/hooks";
import UserOverviewPanel from "shared/components/UserOverviewPanel";
import { useStateMachine } from "little-state-machine";
import { generateUrl } from "shared/utils";
import { MANAGE_USER_PROFILE_SETTINGS } from "master-data/constants";
import EmailSettingsPanel from "shared/components/EmailSettingsPanel";
import { ConfirmationDialog } from "core";
import {
  setIsOpenUserProfileDialog,
  setCanSaveUserProfileChanges,
} from "store/actions";
import {
  USER_PROFILE_DIALOG_CONTENT,
  USER_PROFILE_DIALOG_TITLE,
} from "shared/constants";
import { useParams } from "react-router-dom";
import { GeneralSettingsPanel } from "shared/components";
import { SettingData, IResponseGet, IResponsePost, ParamTypes } from "./types";

const { GET, POST } = FetchMethodEnum;

export const ProfilePage = (): ReactElement => {
  const [checkedEmailSettings, setCheckedEmailSettings] = useState<
    SettingData[]
  >([]);
  const [checkedGeneralSettings, setCheckedGeneralSettings] = useState<
    SettingData[]
  >([]);
  const [isAllSettingsSwitch, setIsAllSettingsSwitch] =
    useState<boolean>(false);

  useProfileGetter(0);
  const {
    actions,
    state: { appState, profile },
  } = useStateMachine({
    setIsOpenUserProfileDialog,
    setCanSaveUserProfileChanges,
  });

  const { agencyId } = profile;
  const { isOpenUserProfileDialog } = appState;
  const { userId } = useParams<ParamTypes>();
  // TODO: This condition needs to be adjusted with the "Allow Agency Approval" setting (not currently used)
  const showGeneralSettings = false;

  const apiUrl = generateUrl(MANAGE_USER_PROFILE_SETTINGS, {
    agencyId,
    userId,
  });

  const { data, executeFetch } = useFetch<IResponseGet>(apiUrl, {
    method: GET,
  });

  const { get: getData, post: postData } = data ?? {};
  const { overview, emailSettings, generalSettings } =
    (getData as IResponseGet) ?? {};

  const handleAllToggleSwitch = () => {
    setIsAllSettingsSwitch((prevState) => !prevState);
    const items = checkedEmailSettings.map((item: SettingData) =>
      isAllSettingsSwitch ? { ...item, value: false } : { ...item, value: true }
    );
    setCheckedEmailSettings(items as SettingData[]);
  };

  const handleEmailSwitch = (idSetting: number) => {
    const items = checkedEmailSettings.map((item: SettingData) =>
      item.id === idSetting ? { ...item, value: !item.value } : item
    );
    setCheckedEmailSettings(items as SettingData[]);
  };

  const handleCloseUserProfileDialog = () => {
    actions.setIsOpenUserProfileDialog(false);
  };

  const handleSaveUserSettings = () => {
    const saveSettingsUrl = generateUrl(MANAGE_USER_PROFILE_SETTINGS, {
      agencyId,
      userId,
    });
    executeFetch(saveSettingsUrl, {
      method: POST,
      body: { settings: checkedEmailSettings },
    });
  };

  // #region "Use effects"
  useEffect(() => {
    if (emailSettings) {
      setCheckedEmailSettings(emailSettings);
    }

    if (generalSettings) {
      setCheckedGeneralSettings(generalSettings);
    }

    // TODO: This condition needs to be adjusted with the generalSettings (not currently used)
    if (emailSettings?.length === 0) {
      actions.setCanSaveUserProfileChanges(false);
    } else {
      actions.setCanSaveUserProfileChanges(true);
    }
  }, [actions, emailSettings, generalSettings]);

  useEffect(() => {
    const { isSuccessful } = (postData ?? {}) as IResponsePost;
    if (isSuccessful) {
      actions.setIsOpenUserProfileDialog(false);
    }
  }, [actions, postData, emailSettings]);

  useEffect(() => {
    if (
      Object.values(checkedEmailSettings).every((item) => item.value === true)
    ) {
      setIsAllSettingsSwitch(true);
    } else {
      setIsAllSettingsSwitch(false);
    }
  }, [checkedEmailSettings]);

  useEffect(() => {
    if (userId && agencyId) {
      executeFetch(apiUrl, { method: GET });
    }
  }, [executeFetch, apiUrl, userId, agencyId]);

  // #endregion

  return (
    <>
      <Grid
        container
        alignContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12}>
          <UserOverviewPanel data={overview} />
        </Grid>
        <Grid item xs={6}>
          {checkedEmailSettings.length !== 0 && (
            <EmailSettingsPanel
              data={checkedEmailSettings}
              handleChange={handleEmailSwitch}
              handleAllChange={handleAllToggleSwitch}
              allSwitched={isAllSettingsSwitch}
            />
          )}
        </Grid>
        {showGeneralSettings && (
          <Grid item xs={6}>
            {checkedGeneralSettings.length !== 0 && (
              <GeneralSettingsPanel
                data={checkedGeneralSettings}
                handleChange={handleEmailSwitch}
              />
            )}
          </Grid>
        )}
        {isOpenUserProfileDialog && (
          <ConfirmationDialog
            open={isOpenUserProfileDialog}
            title={USER_PROFILE_DIALOG_TITLE}
            message={USER_PROFILE_DIALOG_CONTENT}
            isDisableAction={Boolean(false)}
            onConfirm={handleSaveUserSettings}
            onCancel={handleCloseUserProfileDialog}
          />
        )}
      </Grid>
    </>
  );
};

export default ProfilePage;
