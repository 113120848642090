import React, { ReactElement } from "react";
import { Divider, Grid, Switch, Typography } from "@mui/material";
import { Mail as EmailIcon } from "@mui/icons-material";

import { SettingData } from "shared/pages/Profile/types";
import { Setting } from "../Setting";
import { classes, Paper } from "./styles";

export interface EmailSettingsPanelProps {
  data: SettingData[];
  handleChange: (idSetting: number) => void;
  handleAllChange: () => void;
  allSwitched: boolean;
}

export const EmailSettingsPanel = ({
  data,
  handleChange,
  handleAllChange,
  allSwitched,
}: EmailSettingsPanelProps): ReactElement => {
  return (
    <>
      <Paper className={classes.paper}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <EmailIcon className={classes.emailIcon} />
              </Grid>
              <Grid item>
                <Typography className={classes.typographySettingsHeaderCaption}>
                  Email Settings
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {data.length > 1 && (
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Switch
                    id="switchall"
                    name="switchall"
                    color="primary"
                    onClick={handleAllChange}
                    checked={allSwitched}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.typographySettingsText}>
                    Check/Uncheck all
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Divider />

        <Grid container spacing={1}>
          {data &&
            data.map((d) => (
              <Grid item xs={12} key={d.id}>
                <Setting
                  id={d.id}
                  name={d.name}
                  typeId={d.typeId}
                  value={d.value}
                  onChange={handleChange}
                />
              </Grid>
            ))}
        </Grid>
      </Paper>
    </>
  );
};

export default EmailSettingsPanel;
