import { styled, Drawer as MuiDrawer } from "@mui/material";
import { sapphire } from "shared/assets/colors";
import { drawerOpenWidth } from "shared/constants";
import { getThemeSpacing } from "shared/utils/generalUtils";

const PREFIX = "SideBar";
export const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  toolbar: `${PREFIX}-toolbar`,
  logo: `${PREFIX}-logo`,
};

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    backgroundColor: sapphire,
    flex: 0,
    whiteSpace: "nowrap",
    overflowX: "hidden",
    width: getThemeSpacing(theme, 7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: getThemeSpacing(theme, 9) + 1,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${classes.drawerOpen}`]: {
    width: drawerOpenWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${classes.toolbar}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: getThemeSpacing(theme, 1),
    paddingRight: getThemeSpacing(theme, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  [`& .${classes.logo}`]: {
    cursor: "pointer",
    width: getThemeSpacing(theme, 5),
    [theme.breakpoints.up("sm")]: {
      width: getThemeSpacing(theme, 7),
    },
  },
}));
