import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  poMandatory: null,
  invoicePerInsertion: null,
  invoicePerProduct: null,
  invoicePerMediaType: null,
  invoicePerPlan: null,
  invoicingLayoutId: null,

  onlySendInvoicesPo: null,

  invoicingVatId: null,
  invoicePerCampaign: null,
  invoicePerClient: null,
  invoicePerReferenceNumber: null,
  invoicePerInsertions: null,
  invoicePerCollectiveProduct: null,
  invoicePerMedia: null,
  invoicePerSurchargeCode: null,
};
