import { EMPTY_STRING } from "shared/constants";
import { IFormInput } from "./types";

export const defaultValues: IFormInput = {
  strategy: null,
  planning: null,
  trading: null,
  competitiveReports: null,
  thirdPartyDataDelivery: null,
  dashboard: null,
  taggingTracking: null,

  strategyTbba: null,
  planningTbba: null,
  tradingTbba: null,
  competitiveReportsTbba: null,
  thirdPartyTbba: null,
  dashboardTbba: null,
  taggingTrackingTbba: null,

  creationProduction: null,
  influencerMarketing: null,
  brandedContent: null,
  research: null,
  training: null,
  other: null,

  creationProductionTbba: null,
  influencerMarketingTbba: null,
  brandedContentTbba: null,
  researchTbba: null,
  trainingTbba: null,
  otherTbba: null,

  otherText: EMPTY_STRING,
};
