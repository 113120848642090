import React, { FC, useEffect, useState, ReactElement } from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from "@hookform/devtools";
import { Prompt, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useStateMachine } from "little-state-machine";
import { useFieldArray, useForm } from "react-hook-form";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import {
  KEY_ENTER,
  TAG_TEXTAREA,
  UNSAVED_DATA_MESSAGE,
} from "shared/constants";
import { generateUrl } from "shared/utils";
import { FormEntriesAccordionType } from "master-data/types";
import {
  AgencyEnum,
  AccordionEnum,
  FillFormOptionType,
} from "master-data/enums";
import { GET_FORM, MANAGE_ENTRIES_FORM } from "master-data/constants";
import { ParamTypes } from "master-data/pages/ClientDocumentDashboard/types";

import { setIsDocumentStatusChanged } from "store/actions";

import {
  IFormsInput,
  IFormsEntriesInput,
  IFormsResponseGet,
  IFormsResponsePost,
  IFormsRevisionInput,
  FormsCurrent,
  FormsProps,
} from "./types";
import { schemas } from "./config";
import { defaults } from "./constants";
import { FormHours, FormHoursProps } from "./Hours";
import { FormClientContacts, FormClientContactsProps } from "./ClientContacts";
import {
  FormRelatedContacts,
  FormRelatedContactsProps,
} from "./RelatedContacts";
import {
  FormRemunerationContractLine,
  FormRemunerationContractLineProps,
} from "./RemunerationContractLine";
import {
  FormSoxCompliantClientApproval,
  FormSoxCompliantClientApprovalProps,
} from "./SoxCompliantClientApproval";
import {
  Current as HoursCurrent,
  IRevisionEntriesInput as HoursIRevisionEntriesInput,
} from "./Hours/types";
import {
  Current as ClientContactsCurrent,
  IRevisionEntriesInput as ClientContactsIRevisionEntriesInput,
} from "./ClientContacts/types";
import {
  Current as RelatedContactsCurrent,
  IRevisionEntriesInput as RelatedContactsIRevisionEntriesInput,
} from "./RelatedContacts/types";
import {
  Current as RemunerationContractLineCurrent,
  IRevisionEntriesInput as RemunerationContractLineIRevisionEntriesInput,
} from "./RemunerationContractLine/types";
import {
  Current as SoxCompliantClientApprovalCurrent,
  IRevisionEntriesInput as SoxCompliantClientApprovalIRevisionEntriesInput,
} from "./SoxCompliantClientApproval/types";
import {
  FormFinancialClientIncentive,
  FormFinancialClientIncentiveProps,
} from "./FinancialClientIncentive";
import {
  Current as FinancialClientIncentiveCurrent,
  IRevisionEntriesInput as FinancialClientIncentiveIRevisionEntriesInput,
} from "./FinancialClientIncentive/types";

import { classes, Root } from "./styles";
import { mapToFormType } from "../FormStandard/config";
import { FormHoursV5 } from "./HoursV5";

const entries: {
  [key in FormEntriesAccordionType]: FC<FormsProps[key]>;
} = {
  clientContacts: FormClientContacts,
  relatedContacts: FormRelatedContacts,
  remunerationContractLine: FormRemunerationContractLine,
  soxCompliantClientApproval: FormSoxCompliantClientApproval,
  hours: FormHours,
  hoursV5: FormHoursV5,
  financialClientIncentive: FormFinancialClientIncentive,
};

const handler = {
  get(target: any, name: string) {
    return Object.prototype.hasOwnProperty.call(target, name)
      ? target[name]
      : () => <></>;
  },
};
const entriesForms = new Proxy<{
  [key in FormEntriesAccordionType]: FC<FormsProps[key]>;
}>(entries, handler);

export interface FormEntriesProps {
  /**
   * Accordion type of Entries Form
   * @type FormEntriesAccordionType
   */
  type: FormEntriesAccordionType;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
  /**
   * Flag indicating if form is in edit mode
   */
  isEdit: boolean;
  /**
   * Callback fired when form is submitted
   */
  onSave: () => void;
}

export const FormEntries = ({
  type,
  fillOption,
  isEdit,
  onSave,
}: FormEntriesProps): ReactElement => {
  const [isNew, setIsNew] = useState(true);
  const [isNewEntry, setIsNewEntry] = useState(false);
  const [current, setCurrent] = useState<null | FormsCurrent[typeof type]>(
    null
  );

  const {
    state: { clientDocumentData },
    actions,
  } = useStateMachine({ setIsDocumentStatusChanged });

  const { isContract, changes } = clientDocumentData;

  const {
    reset,
    watch,
    control,
    trigger,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isDirty, dirtyFields },
  } = useForm<IFormsInput[typeof type]>({
    defaultValues: defaults[type],
    resolver: yupResolver(schemas[type]),
    context: { isContract },
    mode: "onChange",
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "entries",
  });

  const { GET, POST, PUT } = FetchMethodEnum;
  const { accordionType, agencyName, documentId } = useParams<ParamTypes>();
  const agencyId = AgencyEnum[agencyName];
  const accordionId = AccordionEnum[accordionType];
  const url = generateUrl(GET_FORM, {
    agencyId,
    documentId,
    accordionId,
  });

  const { data, executeFetch, resetPostData } = useFetch<
    IFormsResponseGet[typeof type],
    IFormsResponsePost[typeof type]
  >(url, {
    method: GET,
  });

  const { get: getData, post: postData } = data ?? {};

  const formType = mapToFormType(type);
  const revisionValues = (
    changes ? changes[formType] : {}
  ) as IFormsRevisionInput[typeof type];

  let revisionValuesFormDialog;
  if (current?.id && revisionValues?.Entries) {
    revisionValuesFormDialog = revisionValues?.Entries[current.id];
  }

  let result: { [x: string]: any } | null = null;
  if (revisionValues?.Entries) {
    result = Object.fromEntries(
      Object.entries(revisionValues?.Entries).filter(
        ([key, value]) => Object.keys(value).length !== 1
      )
    );
  }
  const ids = result !== null ? Object.keys(result) : [];

  // #region Methods

  const onSubmit = (submittedData: IFormsInput[typeof type]) => {
    const submitUrl = generateUrl(MANAGE_ENTRIES_FORM[type], {
      agencyId,
      documentId,
    });
    const method = isNew ? POST : PUT;

    executeFetch(submitUrl, { method, body: submittedData });
  };

  const onKeyPressInField = (event: React.KeyboardEvent<HTMLElement>) => {
    const tag = event.target as HTMLElement;
    if (event.key === KEY_ENTER && tag.tagName !== TAG_TEXTAREA) {
      event.preventDefault();
    }
  };

  const handleAdd = () => {
    setIsNewEntry(true);
    setCurrent({
      rowIndex: fields.length,
    } as FormsCurrent[typeof type]);
  };

  const handleEdit = (newCurrent: FormsCurrent[typeof type]) => {
    setCurrent(newCurrent);
  };

  const handleDelete = (rowIndex: number) => {
    remove(rowIndex);
  };

  const handleDialogCancel = () => {
    const { rowIndex, ...currentData } = current as FormsCurrent[typeof type];
    const { entries: entryValues } = getValues();

    if (!isNewEntry && rowIndex != null) {
      entryValues[rowIndex] = currentData;
      setValue(`entries`, entryValues);
    } else {
      remove(rowIndex);
    }
    setCurrent(null);
    setIsNewEntry(false);
  };

  const handleDialogSave = async () => {
    const { rowIndex, ...currentData } = current as FormsCurrent[typeof type];
    const { entries: entryValues } = getValues();
    const newCurrent = entryValues[rowIndex];

    const isValid = await trigger("entries");

    if (!isValid) {
      return;
    }

    if (isNewEntry) {
      append([]);
    } else {
      const newFields = fields.map((f, i) => {
        if (i === rowIndex) {
          return {
            ...currentData,
            ...newCurrent,
          } as IFormsEntriesInput[typeof type];
        }

        return { ...f, id: f.id } as IFormsEntriesInput[typeof type];
      });

      setValue("entries", newFields as any);
    }
    setCurrent(null);
    setIsNewEntry(false);
  };

  const errorsFormDialog =
    errors.entries && current != null
      ? errors.entries[current.rowIndex]
      : undefined;

  const isDialogOpen = current != null;

  // #region Effects

  useEffect(() => {
    const { form } = getData ?? {};
    if (form) {
      setIsNew(false);
      reset(form);
    }
  }, [getData, reset]);

  useEffect(() => {
    if (!isEdit) {
      reset();
    }
  }, [isEdit, reset]);

  useEffect(() => {
    const {
      isSuccessful,
      isDocumentStatusChanged = false,
      ...restData
    } = postData ?? {};
    if (isSubmitSuccessful && isSuccessful) {
      setIsNew(false);
      reset(restData);
      resetPostData();
      actions.setIsDocumentStatusChanged(isDocumentStatusChanged);
      onSave();
    }
  }, [postData, isSubmitSuccessful, reset, onSave, resetPostData, actions]);

  // #region props
  const defaultFormProps = {
    fields,
    isEdit,
    control,
    isNewEntry,
    clearErrors,
    isDialogOpen,
    errorsFormDialog,
    onAdd: handleAdd,
    onRemove: remove,
    onEdit: handleEdit,
    onDelete: handleDelete,
    onDialogSave: handleDialogSave,
    onDialogCancel: handleDialogCancel,
  };
  const hoursProps: FormHoursProps = {
    ...defaultFormProps,
    errors,
    revisionValues: revisionValues as IFormsRevisionInput["hours"],
    current: current as HoursCurrent,
    revisionValuesFormDialog:
      revisionValuesFormDialog as HoursIRevisionEntriesInput,
    revisionValueIds: ids,
    fillOption,
    setValue,
  };
  const clientContactsProps: FormClientContactsProps = {
    ...defaultFormProps,
    current: current as ClientContactsCurrent,
    revisionValuesFormDialog:
      revisionValuesFormDialog as ClientContactsIRevisionEntriesInput,
    revisionValueIds: ids,
    setValue,
  };
  const relatedContactsProps: FormRelatedContactsProps = {
    ...defaultFormProps,
    current: { agencyTypeId: 1, ...current } as RelatedContactsCurrent,
    revisionValuesFormDialog:
      revisionValuesFormDialog as RelatedContactsIRevisionEntriesInput,
    setValue,
    revisionValueIds: ids,
  };
  const remunerationContractLineProps: FormRemunerationContractLineProps = {
    ...defaultFormProps,
    current: current as RemunerationContractLineCurrent,
    revisionValuesFormDialog:
      revisionValuesFormDialog as RemunerationContractLineIRevisionEntriesInput,
    revisionValues:
      revisionValues as IFormsRevisionInput["remunerationContractLine"],
    revisionValueIds: ids,
    errors,
    getValues,
    setValue,
    fillOption,
  };
  const soxCompliantClientApprovalProps: FormSoxCompliantClientApprovalProps = {
    ...defaultFormProps,
    current: current as SoxCompliantClientApprovalCurrent,
    revisionValuesFormDialog:
      revisionValuesFormDialog as SoxCompliantClientApprovalIRevisionEntriesInput,
    revisionValues:
      revisionValues as IFormsRevisionInput["soxCompliantClientApproval"],
    revisionValueIds: ids,
    errors,
    fillOption,
    setValue,
  };
  const financialClientIncentiveProps: FormFinancialClientIncentiveProps = {
    ...defaultFormProps,
    current: current as FinancialClientIncentiveCurrent,
    revisionValuesFormDialog:
      revisionValuesFormDialog as FinancialClientIncentiveIRevisionEntriesInput,
    revisionValues:
      revisionValues as IFormsRevisionInput["financialClientIncentive"],
    revisionValueIds: ids,
    errors,
    watch,
    setValue,
    fillOption,
  };

  const allFormProps: {
    [key in FormEntriesAccordionType]: any; // TODO: any is workaround. FormsProps[key] does not work, try to find valid solution
  } = {
    hours: hoursProps,
    hoursV5: hoursProps,
    clientContacts: clientContactsProps,
    relatedContacts: relatedContactsProps,
    remunerationContractLine: remunerationContractLineProps,
    soxCompliantClientApproval: soxCompliantClientApprovalProps,
    financialClientIncentive: financialClientIncentiveProps,
  };
  // #endregion props

  const Form = entriesForms[type];
  const formProps = allFormProps[type];

  return (
    <>
      {process.env.NODE_ENV !== "production" && (
        <DevTool placement="top-left" control={control} />
      )}
      <Root
        id={`${type}Form`}
        role="presentation"
        className={classes.form}
        onKeyDown={onKeyPressInField}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form {...formProps} />
      </Root>
      <Prompt
        when={Object.keys(dirtyFields).length > 0}
        message={UNSAVED_DATA_MESSAGE}
      />
    </>
    //  TODO: Find better solution for popup using isDirty property and keepDefaultValues
  );
};

export default FormEntries;
