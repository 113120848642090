import React, { ReactElement, useEffect } from "react";

import { Grid } from "@mui/material";
import { useStateMachine } from "little-state-machine";

import { TextField, NumericField, Autocomplete } from "core";

import { FillFormOptionType } from "master-data/enums";
import { FormStandardFieldProps } from "../props";

export interface FormInterestProps extends FormStandardFieldProps {
  /**
   * setValue
   */
  setValue: any;
  /**
   * Fill form option type
   */
  fillOption: FillFormOptionType;
}

export const FormInterest = ({
  isEdit,
  control,
  errors,
  revisionValues,
  fillOption,
  setValue,
}: FormInterestProps): ReactElement => {
  const {
    state: { cacheData },
  } = useStateMachine();

  const { enumCalculateInterest, enumBankName } = cacheData;

  // #region Effects
  useEffect(() => {
    if (fillOption) {
      if (
        fillOption === FillFormOptionType.fillMandatory ||
        fillOption === FillFormOptionType.fillMandatoryAndSave
      ) {
        if (enumCalculateInterest[1]) {
          setValue("calculateInterestId", enumCalculateInterest[1].key);
        }
      } else if (enumCalculateInterest[0]) {
        setValue("calculateInterestId", enumCalculateInterest[0].key);
      }
      if (
        fillOption === FillFormOptionType.fillAllAndSave ||
        fillOption === FillFormOptionType.fillAll
      ) {
        setValue("otherBankName", "Other Bank Name - Test");
        setValue("bankStoragePercentage", 3);
        setValue("fixedDeviatingPercentage", 7);
        if (enumBankName[0]) {
          setValue("bankNameId", enumBankName[0].key);
        }
      }
    }
  }, [fillOption, setValue, enumCalculateInterest, enumBankName]);
  // #endregion Effects

  return (
    <Grid container spacing={1}>
      <Grid item container direction="column" spacing={1} xs={6}>
        <Grid item>
          <Autocomplete
            autoHighlight
            id="calculateInterestId"
            name="calculateInterestId"
            label="Calculate Interest (Based on legal interest) *"
            options={enumCalculateInterest}
            error={!!errors.calculateInterestId?.message}
            helperText={errors.calculateInterestId?.message}
            revisionValue={revisionValues?.CalculateInterestId}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            autoHighlight
            id="bankNameId"
            name="bankNameId"
            label="Bank Name"
            options={enumBankName}
            error={!!errors.bankNameId?.message}
            helperText={errors.bankNameId?.message}
            revisionValue={revisionValues?.BankNameId}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            name="otherBankName"
            id="otherBankName"
            label="Other Bank"
            error={!!errors.otherBankName?.message}
            helperText={errors.otherBankName?.message}
            revisionValue={revisionValues?.OtherBankName}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
        <Grid item>
          <TextField
            name="bankStoragePercentage"
            id="bankStoragePercentage"
            label="Surcharge Bank Percentage"
            error={!!errors.bankStoragePercentage?.message}
            helperText={errors.bankStoragePercentage?.message}
            revisionValue={revisionValues?.BankStoragePercentage}
            disabled={!isEdit}
            control={control}
          />
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1} xs={6}>
        <Grid item>
          <NumericField
            id="fixedDeviatingPercentage"
            name="fixedDeviatingPercentage"
            label="Fixed deviating %"
            fullWidth
            disabled={!isEdit}
            error={!!errors.fixedDeviatingPercentage?.message}
            helperText={errors.fixedDeviatingPercentage?.message}
            revisionValue={revisionValues?.FixedDeviatingPercentage}
            control={control}
            percentage
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormInterest;
