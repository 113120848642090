import { boolean, object, string } from "yup";
import { MAX_LENGTH_50_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  supplierID: string()
    .trim()
    .max(50, MAX_LENGTH_50_VALIDATION_MESSAGE)
    .nullable(),
  avb: boolean().nullable(),
  sbi: boolean().nullable(),
  msi: boolean().nullable(),
});
