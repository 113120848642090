import { styled, TableFooter } from "@mui/material";

const PREFIX = "TableFooterStyles";
export const classes = {
  tableCell: `${PREFIX}-tableCell`,
};

export const MuiTableFooter = styled(TableFooter)(() => ({
  [`& .${classes.tableCell}`]: {
    border: "none",
  },
}));
