import { string, object, boolean } from "yup";

import { MAX_LENGTH_100_VALIDATION_MESSAGE } from "shared/constants";

export const validationSchema = object().shape({
  strategy: boolean().nullable(),
  planning: boolean().nullable(),
  trading: boolean().nullable(),
  competitiveReports: boolean().nullable(),
  thirdPartyDataDelivery: boolean().nullable(),
  dashboard: boolean().nullable(),
  taggingTracking: boolean().nullable(),

  strategyTbba: boolean().nullable(),
  planningTbba: boolean().nullable(),
  tradingTbba: boolean().nullable(),
  competitiveReportsTbba: boolean().nullable(),
  thirdPartyTbba: boolean().nullable(),
  dashboardTbba: boolean().nullable(),
  taggingTrackingTbba: boolean().nullable(),

  creationProduction: boolean().nullable(),
  influencerMarketing: boolean().nullable(),
  brandedContent: boolean().nullable(),
  research: boolean().nullable(),
  training: boolean().nullable(),
  other: boolean().nullable(),

  creationProductionTbba: boolean().nullable(),
  influencerMarketingTbba: boolean().nullable(),
  brandedContentTbba: boolean().nullable(),
  researchTbba: boolean().nullable(),
  trainingTbba: boolean().nullable(),
  otherTbba: boolean().nullable(),

  otherText: string().trim().max(100, MAX_LENGTH_100_VALIDATION_MESSAGE),
});
