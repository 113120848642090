import React, { useEffect, ReactElement } from "react";

import { object, string } from "yup";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from "@hookform/devtools";
import { useForm, Controller, Control } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStateMachine } from "little-state-machine";
import { Prompt, useHistory, useParams, useRouteMatch } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";

import { Select } from "core";

import { useFetch, FetchMethodEnum } from "shared/hooks";

import {
  KEY_ENTER,
  UNSAVED_DATA_MESSAGE,
  MAX_LENGTH_100_VALIDATION_MESSAGE,
  MANDATORY_FILED_VALIDATION_MESSAGE,
} from "shared/constants";
import { CLIENT_DOCUMENT_LIST } from "product/constants";
import { CREATE_PRODUCT } from "product/constants/api";
import { generateUrl } from "shared/utils";
import {
  GetDocumentsResponse,
  ICreateProductFormInput,
  ParamTypes,
} from "product/types";
import { AgencyEnum } from "product/enums";
import { setValidationStatus } from "store/actions";

import { IResponseGet, IResponsePost } from "./types";
import { FORM_ID, defaultValues } from "./config";

// #region "Validation"
const schema = object().shape({
  productName: string()
    .trim()
    .max(100, MAX_LENGTH_100_VALIDATION_MESSAGE)
    .required(MANDATORY_FILED_VALIDATION_MESSAGE),
  documentId: string().required(MANDATORY_FILED_VALIDATION_MESSAGE).nullable(),
});
// #endregion

const { GET, POST } = FetchMethodEnum;

export const ProductDialog = (): ReactElement => {
  const { data, isLoading, executeFetch } = useFetch<
    IResponseGet,
    IResponsePost
  >(null, {
    skip: true,
  });
  const { actions } = useStateMachine({
    setValidationStatus,
  });

  const { goBack, replace } = useHistory();
  const { url } = useRouteMatch();
  const { agencyName } = useParams<ParamTypes>();

  const agencyId = AgencyEnum[agencyName];
  const { get: getData, post: postData } = data ?? {};

  const CREATE_NEW_PRODUCT_URL = generateUrl(CREATE_PRODUCT, {
    agencyId,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitSuccessful, isDirty, errors },
  } = useForm<ICreateProductFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const ApiGetDocumentListUrl = generateUrl(CLIENT_DOCUMENT_LIST, {
    agencyId,
  });
  const { data: dataDocuments } = useFetch<GetDocumentsResponse>(
    ApiGetDocumentListUrl,
    {
      method: GET,
    }
  );
  const documents = dataDocuments?.get?.items.map(
    ({ documentId, documentName, marathonClientId }) => {
      return {
        key: documentId,
        value: `${documentName} - ${marathonClientId ?? "No ID"}`,
      };
    }
  ) as OptionType[];

  // #region Effects
  useEffect(() => {
    if (getData) {
      reset(getData);
    }
  }, [getData, reset]);

  useEffect(() => {
    const { isSuccessful, productId: dId } = postData ?? {};
    const changeUrl = () => {
      replace(`${url}/${dId}`);
    };

    const clearValidationStatusState = () => {
      actions.setValidationStatus(null);
    };

    if (isSuccessful) {
      clearValidationStatusState();
      changeUrl();
    }
  }, [actions, postData, replace, url]);

  // #region Methods
  const handleCancel = () => {
    reset();
    goBack();
  };

  const onSave = (formData: ICreateProductFormInput) => {
    executeFetch(CREATE_NEW_PRODUCT_URL, {
      method: POST,
      body: { ...formData },
      skip: false,
    });
  };

  const onKeyPressInField = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === KEY_ENTER) {
      event.preventDefault();
    }
  };
  // #endregion Methods

  return (
    <>
      <Dialog open aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Create new Product</DialogTitle>
        <DialogContent dividers>
          {process.env.NODE_ENV !== "production" && (
            <DevTool control={control} placement="top-left" />
          )}
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <form
                id={FORM_ID}
                role="presentation"
                onKeyDown={onKeyPressInField}
                onSubmit={handleSubmit(onSave)}
              >
                <Grid container direction="column" spacing={4}>
                  <Grid item>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoFocus
                          id="productName"
                          name="productName"
                          label="Product Name *"
                          variant="outlined"
                          fullWidth
                          error={!!errors.productName?.message}
                          helperText={errors.productName?.message}
                        />
                      )}
                      name="productName"
                      control={control}
                    />
                  </Grid>
                  <Grid item>
                    <Select
                      id="documentId"
                      name="documentId"
                      label="For Client *"
                      variant="outlined"
                      fullWidth
                      control={control as unknown as Control}
                      options={documents}
                      error={!!errors.documentId?.message}
                      helperText={errors.documentId?.message}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            id="button-reset"
            name="button-reset"
            onClick={handleCancel}
            color="secondary"
            form={FORM_ID}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            id="button-submit"
            name="button-submit"
            color="primary"
            form={FORM_ID}
            disabled={isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt
        when={isDirty && !isSubmitSuccessful}
        message={UNSAVED_DATA_MESSAGE}
      />
    </>
  );
};

export default ProductDialog;
